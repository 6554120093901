import { Fab, Grid, IconButton } from "@material-ui/core";
import Report from "Components/Report";
import Spinner from "Components/Spinner";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { MdAdd, MdEdit, MdStore, MdSwapHoriz } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	getBalanceAccountType,
	getBalanceAccountTypes,
	setValue
} from "store/actions/balanceAccount/report";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";

import Businesses from "./Businesses";
import BusinessTransferPolicies from "./BusinessTransferPolicies";
import useStyles from "./styles";

function BusinessCategoryReport({ history }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const [loadingSub, setLoadingSub] = useState({});

	const table = useRef(null);
	const debounced = useRef<(() => any) & _.Cancelable>(null);

	const { types, pages, loading } = useSelector<any, IBalanceAccountState>(
		s => s.balanceAccount
	);

	const load = useCallback(
		(skip, take, filter, sort = "creationDate", desc = true) => {
			dispatch(
				getBalanceAccountTypes({
					skip,
					take,
					filter,
					sort,
					desc
				})
			);
		},
		[dispatch]
	);

	const handleFetchData = useCallback(
		tableState => {
			let { page, pageSize, sorted } = tableState;
			if (debounced.current) {
				debounced.current.cancel();
			}
			debounced.current = _.debounce(
				() =>
					load(page * pageSize, pageSize, null, sorted[0]?.id, sorted[0]?.desc),
				500
			);
			debounced.current();
		},
		[load]
	);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = (id, item) => {
		setLoadingSub({ [id]: item.id });
		dispatch(
			getBalanceAccountType(item.id, (err, model) => {
				console.log(err, model);

				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					handleChange(id, Boolean(model));
				}

				setLoadingSub({ [id]: false });
			})
		);
	};

	const columns = [
		{
			Header: "Código",
			accessor: "externalId",
			width: 200
		},
		{
			Header: ls.name,
			accessor: "name"
		},
		{
			Header: ls.description,
			accessor: "description"
		},
		{
			Header: ls.actions,
			id: "actions",
			accessor: c => (
				<Grid container spacing={2} justify="center">
					<Grid item>
						{loadingSub["showBusinessesFormModal"] === c.id ? (
							<Spinner color="secondary" size={16} />
						) : (
							<IconButton
								style={{
									padding: 8,
									fontSize: "1.3rem"
								}}
								onClick={() => handleEdit("showBusinessesFormModal", c)}
							>
								<MdStore />
							</IconButton>
						)}

						{loadingSub["showBusinessesTransferFormModal"] === c.id ? (
							<Spinner color="secondary" size={16} />
						) : (
							<IconButton
								style={{
									padding: 8,
									fontSize: "1.3rem"
								}}
								onClick={() => handleEdit("showBusinessesTransferFormModal", c)}
							>
								<MdSwapHoriz />
							</IconButton>
						)}

						<IconButton
							style={{
								padding: 8,
								fontSize: "1.3rem"
							}}
						>
							<Link
								to={{
									pathname: `/BalanceAccountType/${c.id}/Edit`,
									state: { id: c.id, externalId: c.externalId }
								}}
							>
								<MdEdit />
							</Link>
						</IconButton>
					</Grid>
				</Grid>
			),
			width: 100,
			filterable: false
		}
	];

	return (
		<div>
			<Report
				onFetchData={handleFetchData}
				manual
				tableRef={table}
				title={ls.balanceAccountTypes}
				data={types}
				pages={pages}
				columns={columns}
				loading={loading.getAll}
				defaultSorted={[
					{
						id: "externalId",
						desc: true
					}
				]}
			/>
			<Fab
				color="primary"
				aria-label="Add"
				className={classes.fab}
				onClick={() => history.push("/BalanceAccount/Type/Create")}
			>
				<MdAdd size={24} />
			</Fab>

			<Businesses />

			<BusinessTransferPolicies />
		</div>
	);
}

export default BusinessCategoryReport;
