import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import { MONEY } from "Components/NumberFormat";
import { PERCENTAGE2 } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import RenewalCycle from "models/renewalCycle";
import valueType from "models/valueType";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	createAllowance,
	setValue
} from "store/actions/allowance";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceCreateForm: (() => void) & _.Cancelable = null;

function CreateForm({ load, value }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const {
		showFormModal,
		name,
		baseValue,
		limit,
		maxLimit,
		limitType,
		renewalCycle,
		startDate,
		endDate,
		errors,
		loading
	} = useSelector<any, any>(c => c.allowance);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceCreateForm) debounceCreateForm.cancel();

		debounceCreateForm = _.debounce(() => {
			dispatch(
				createAllowance((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						load(value);
						handleClose();
						dispatch(clearValues());
					}
				})
			);
		}, 1000);

		debounceCreateForm();
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={showFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.createAllowance}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="name"
										required
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="baseValue"
										required
										variant="outlined"
										name="baseValue"
										InputProps={{
											inputComponent: MONEY
										}}
										value={baseValue}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="limitType"
										name="limitType"
										value={limitType}
										onChange={handleChange}
										options={Object.keys(valueType).map(c => ({
											id: c,
											value: ls[valueType[c]]
										}))}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={+limitType ? 2 : 4}>
									<TextInput
										id="limit"
										required
										variant="outlined"
										name="limit"
										InputProps={{
											inputComponent: +limitType ? PERCENTAGE2 : MONEY
										}}
										value={limit}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								{+limitType ? (
									<Grid item xs={12} sm={6} md={6} lg={3}>
										<TextInput
											id="maxLimit"
											required
											variant="outlined"
											name="maxLimit"
											InputProps={{
												inputComponent: MONEY
											}}
											value={maxLimit}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={handleChange}
										/>
									</Grid>
								) : null}

								<Grid item xs={12} sm={6} md={6} lg={+limitType ? 3 : 4}>
									<Select
										errors={errors}
										id="renewalCycle"
										name="renewalCycle"
										value={renewalCycle}
										onChange={handleChange}
										options={Object.keys(RenewalCycle).map(c => ({
											id: c,
											value: ls[RenewalCycle[c]]
										}))}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<DateTimePicker
										fullWidth
										value={startDate}
										label={ls.start}
										onChange={date => handleChange("startDate", date)}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<DateTimePicker
										fullWidth
										value={endDate}
										label={ls.end}
										onChange={date => handleChange("endDate", date)}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
										clearable
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default CreateForm;
