export enum ActionTypes {
	GET_CARD_USER_REQUEST = "[CARD USER] GET_CARD_USER_REQUEST",
	GET_CARD_USER_SUCCESS = "[CARD USER] GET_CARD_USER_SUCCESS",
	GET_CARD_USER_FAILED = "[CARD USER] GET_CARD_USER_FAILED",

	CHANGE_STATUS_CARD_USER_REQUEST = "[CARD USER] CHANGE_STATUS_CARD_USER_REQUEST",
	CHANGE_STATUS_CARD_USER_SUCCESS = "[CARD USER] CHANGE_STATUS_CARD_USER_SUCCESS",
	CHANGE_STATUS_CARD_USER_FAILED = "[CARD USER] CHANGE_STATUS_CARD_USER_FAILED"
}

export interface ICardUser {
	userId: string;
}

export interface ICardUserResult {
	id: string;
	expirationMonth: string;
	maskedPan: string;
	userId: string;
	firstActivationDate: string;
	lastActivationDate: string;
	status: number;
	response_code?: number;
	message?: string;
	error?: string;
	errors?: any;
	isValid: boolean;
	isloading?: boolean;
}

export interface ICardUserState {
	items: ICardUserResult[];
	pages: number;
	loading: boolean;
}

export interface ActionPayload {
	type: string;
	payload?: {
		items?: ICardUserResult[];
	};
}
