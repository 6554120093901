import {
	Backdrop,
	Button,
	Fade,
	FormControlLabel,
	Grid,
	IconButton,
	Modal,
	Switch,
	Typography
} from "@material-ui/core";
import { modalConfirmation } from "Components/Modals/ModalConfirmation";
import { MONEY } from "Components/NumberFormat";
import { PERCENTAGE2 } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import TooltipLight from "Components/TooltipLight";
import { toastError, toastSuccess } from "helpers/toast";
import { AsYouType } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import typeContract from "models/contractType";
import RenewalCycle from "models/renewalCycle";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { editConfig } from "store/actions/business/detail";
import { createLimitClub } from "store/actions/business/limitClub";

import useStyles from "./styles";

let debounceEditConfig: (() => void) & _.Cancelable = null;
let debounceLimitClub: (() => void) & _.Cancelable = null;

function EditConfig({
	open,
	handleClose,
	id,
	hasHCM,
	hasWiipoClub,
	admin,
	isBusinessAdmin
}) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const { loading, item } = useSelector<any, any>(s => s.businessDetail);
	const { loading: limitLoading } = useSelector<any, any>(
		s => s.limitClubCreate
	);

	const {
		config,
		headquartersId,
		isCompany,
		isHeadquarters,
		isStore,
		hasWiipoFlex,
		headquarters
	} = item;

	const [fee, setFee] = useState(0);
	const [admFee, setAdmFee] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [paymentDay, setPaymentDay] = useState(20);
	const [color, setColor] = useState("#FFFFFF");
	const [maxInstallment, setMaxInstallment] = useState(12);
	const [enableDirectPayment, setEnableDirectPayment] = useState(true);
	const [accessionUrl, setAccessionUrl] = useState("");
	const [accessionEmail, setAccessionEmail] = useState("");
	const [whatsApp, setWhatsApp] = useState("");
	const [cancelTime, setCancelTime] = useState(60);
	const [quantityOfLivesOnContract, setQuantityOfLivesOnContract] = useState(0);
	const [
		quantityOfLivesOnHeadquartersContract,
		setQuantityOfLivesOnHeadquartersContract
	] = useState(0);

	const [useHeadquartersConfig, setUseHeadquartersConfig] = useState(false);
	const [limitType, setLimitType] = useState("0");
	const [usersCanAddCreditOnApp, setUsersCanAddCreditOnApp] = useState(false);
	const [useBusinessRenewal, setUseBusinessRenewal] = useState(true);
	const [purchasesWithPayroll, setPurchasesWithPayroll] = useState(true);
	const [includeInInvoicing, setIncludeInInvoicing] = useState(true);
	const [limitTraineeAllowed, setLimitTraineeAllowed] = useState(true);
	const [renewalCycle, setRenewalCycle] = useState("3");
	const [renewalDay, setRenewalDay] = useState(20);
	const [percentClubWiipo, setPercentClubWiipo] = useState(0);
	const [wiipoFlexAverageValuePerUser, setWiipoFlexAverageValuePerUser] =
		useState(0);
	const [errors, setErrors] = useState({});
	const [contractType, setContractType] = useState("0");

	useEffect(() => {
		if (open && config) {
			const configHead = headquarters;

			setFee(config.fee / 100);
			setAdmFee(config.admFee / 100);
			setDiscount(config.discount / 100);
			setPaymentDay(config.paymentDay);
			setColor(config.color);
			setMaxInstallment(config.maxInstallment);
			setEnableDirectPayment(config.enableDirectPayment);
			setAccessionEmail(config.accessionEmail);
			setAccessionUrl(config.accessionUrl);
			setUseHeadquartersConfig(config.useHeadquartersConfig);
			setLimitType(config.limitType);
			setWhatsApp(config.whatsApp);
			setCancelTime(config.cancelTime);
			setQuantityOfLivesOnContract(config.quantityOfLivesOnContract);
			setQuantityOfLivesOnHeadquartersContract(
				configHead?.config?.quantityOfLivesOnContract
			);
			setUsersCanAddCreditOnApp(config.usersCanAddCreditOnApp);
			setUseBusinessRenewal(config.useBusinessRenewal);
			setPurchasesWithPayroll(config.purchasesWithPayroll);
			setIncludeInInvoicing(config.includeInInvoicing);
			setLimitTraineeAllowed(config.limitTraineeAllowed);
			setRenewalCycle(config.renewalCycle);
			setRenewalDay(config.renewalDay);
			setPercentClubWiipo(config.percentClubWiipo / 100);
			setWiipoFlexAverageValuePerUser(
				config.wiipoFlexAverageValuePerUser / 100
			);
			setContractType(config.contractType);
		}
	}, [config, headquarters, open]);

	const livesOnContract = useMemo(() => {
		if (useHeadquartersConfig && !isHeadquarters && headquarters) {
			return quantityOfLivesOnHeadquartersContract;
		}

		return quantityOfLivesOnContract.toFixed();
	}, [
		useHeadquartersConfig,
		isHeadquarters,
		headquarters,
		quantityOfLivesOnContract,
		quantityOfLivesOnHeadquartersContract
	]);

	const handleEditLimitClube = useCallback(() => {
		if (debounceLimitClub) debounceLimitClub.cancel();

		debounceLimitClub = _.debounce(() => {
			dispatch(
				createLimitClub(percentClubWiipo, id, err => {
					if (err) {
						toastError(err);
					} else {
						toastSuccess("Limite atualizado com sucesso");
					}
				})
			);
		}, 1000);

		modalConfirmation({
			onClickNo: () => {},
			onClickYes: () => {
				debounceLimitClub();
			},
			title: ls.attention,
			body: (
				<>
					Você tem certeza que deseja atualizar o limite em {percentClubWiipo}%?
				</>
			)
		});
	}, [dispatch, id, percentClubWiipo]);

	const handleSubmit = () => {
		const newErrors: any = {};

		if (!maxInstallment) newErrors.maxInstallment = ls.required;
		else if (maxInstallment <= 0 || maxInstallment > 12)
			newErrors.maxInstallment = ls.invalid;

		setErrors(newErrors);

		if (Object.keys(newErrors).length > 0) return;

		if (debounceEditConfig) debounceEditConfig.cancel();

		debounceEditConfig = _.debounce(() => {
			dispatch(
				editConfig(
					{
						...(config || {}),
						maxInstallment,
						limitTraineeAllowed,
						percentClubWiipo: Math.round(percentClubWiipo * 100)
					},
					err => {
						if (err) {
							if (err.default) toastError(err.default);
							else if (typeof err === "string") toastError(err);
							else toastError(JSON.stringify(err));
						} else {
							handleClose();
						}
					}
				)
			);
		});

		debounceEditConfig();
	};

	const onSubmit = e => {
		e.preventDefault();

		const newErrors: any = {};

		if (!color) newErrors.color = ls.required;
		else if (
			!new RegExp(/(?:#)(?:[a-f0-9]{3}|[a-f0-9]{6})\b/, "gi").test(color)
		)
			newErrors.color = ls.invalid;

		if (!fee && fee !== 0) newErrors.fee = ls.required;
		if (!admFee && admFee !== 0) newErrors.admFee = ls.required;
		if (!discount && discount !== 0) newErrors.discount = ls.required;

		if (!paymentDay) newErrors.paymentDay = ls.required;
		else if (paymentDay <= 0 || paymentDay >= 31)
			newErrors.paymentDay = ls.invalid;

		if (!maxInstallment) newErrors.maxInstallment = ls.required;
		else if (maxInstallment <= 0 || maxInstallment > 12)
			newErrors.maxInstallment = ls.invalid;

		if (accessionUrl && !accessionEmail) {
			newErrors.accessionEmail = ls.required;
		}

		if (cancelTime < 0) newErrors.cancelTime = ls.invalid;

		if (useBusinessRenewal) {
			if (!renewalDay) newErrors.renewalDay = ls.required;
			else if (renewalDay <= 0 || renewalDay >= 31)
				newErrors.renewalDay = ls.invalid;
		}

		setErrors(newErrors);

		if (Object.keys(newErrors).length > 0) return;

		if (debounceEditConfig) debounceEditConfig.cancel();

		debounceEditConfig = _.debounce(() => {
			dispatch(
				editConfig(
					{
						...(config || {}),
						fee: Math.round(fee * 100),
						admFee: Math.round(admFee * 100),
						discount: Math.round(discount * 100),
						paymentDay,
						maxInstallment,
						color,
						enableDirectPayment,
						accessionUrl,
						accessionEmail,
						useHeadquartersConfig,
						limitType,
						whatsApp,
						cancelTime,
						quantityOfLivesOnContract,
						usersCanAddCreditOnApp,
						useBusinessRenewal,
						purchasesWithPayroll,
						includeInInvoicing,
						limitTraineeAllowed,
						renewalCycle,
						renewalDay,
						wiipoFlexAverageValuePerUser: Math.round(
							wiipoFlexAverageValuePerUser * 100
						),
						contractType,
						percentClubWiipo: Math.round(percentClubWiipo * 100)
					},
					err => {
						if (err) {
							if (err.default) toastError(err.default);
							else if (typeof err === "string") toastError(err);
							else toastError(JSON.stringify(err));
						} else {
							handleClose();
						}
					}
				)
			);
		}, 1000);

		debounceEditConfig();
	};

	const disableAllConfig = useMemo(
		() => useHeadquartersConfig && !isHeadquarters && headquarters,
		[headquarters, isHeadquarters, useHeadquartersConfig]
	);

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center"
						}}
					>
						<div>
							<Typography variant="h6">{ls.editBenefits}</Typography>
						</div>
						<div>
							{headquartersId && admin && (
								<FormControlLabel
									control={
										<Switch
											checked={useHeadquartersConfig}
											onChange={e => setUseHeadquartersConfig(e.target.checked)}
										/>
									}
									label="Usar configurações da matriz"
								/>
							)}

							<TooltipLight title="Fechar" placement="top">
								<IconButton onClick={() => handleClose()}>
									<AiOutlineClose size={20} />
								</IconButton>
							</TooltipLight>
						</div>
					</div>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								{admin && (
									<>
										{isStore && (
											<>
												<Grid item xs={12}>
													<Typography>Loja:</Typography>
												</Grid>

												<Grid item xs={12} sm={6} md={4} lg={2}>
													<TextInput
														id="discount"
														required
														variant="outlined"
														name="discount"
														InputProps={{
															inputComponent: PERCENTAGE2
														}}
														value={discount}
														errors={errors}
														onFocus={e => e.target.select()}
														onChange={(_, v) => setDiscount(+v)}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={4} lg={2}>
													<TextInput
														id="color"
														required
														variant="outlined"
														name="color"
														value={color}
														errors={errors}
														onFocus={e => e.target.select()}
														onChange={(_, v) => setColor(v)}
													/>
												</Grid>

												<Grid item xs={12} sm={6} md={6} lg={4}>
													<TextInput
														id="whatsApp"
														variant="outlined"
														name="whatsApp"
														value={whatsApp}
														errors={errors}
														onChange={(name, value) =>
															setWhatsApp(
																new AsYouType().input(
																	value[0] === "+" ? value : `+${value}`
																)
															)
														}
													/>
												</Grid>
												<Grid item xs={12} sm={6} md={4} lg={3}>
													<TextInput
														id="cancelTime"
														variant="outlined"
														name="cancelTime"
														value={cancelTime}
														errors={errors}
														InputProps={{
															inputProps: {
																step: 1,
																min: 1
															}
														}}
														type="number"
														onFocus={e => e.target.select()}
														onChange={(_, v) => setCancelTime(+v)}
													/>
												</Grid>
											</>
										)}

										<Grid item xs={12} sm={6} md={4} lg={2}>
											<TextInput
												id="fee"
												required
												variant="outlined"
												name="fee"
												InputProps={{
													inputComponent: PERCENTAGE2
												}}
												value={fee}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={(_, v) => setFee(+v)}
												disabled={disableAllConfig}
											/>
										</Grid>

										<>
											{hasWiipoFlex && (
												<Grid item xs={12} sm={6} md={4} lg={5}>
													<TextInput
														id="quantityOfLivesOnContract"
														disabled={disableAllConfig}
														variant="outlined"
														inputProps={{ min: 0 }}
														name="quantityOfLivesOnContract"
														value={livesOnContract}
														errors={errors}
														type="number"
														onFocus={e => e.target.select()}
														onChange={(_, v) => {
															if (Number(v) < 0) v = "0";
															setQuantityOfLivesOnContract(Number(v));
														}}
													/>
												</Grid>
											)}

											<Grid item xs={12}>
												<Typography>Empresa:</Typography>
											</Grid>

											{admin && (
												<>
													<Grid item xs={12} sm={6} md={4} lg={3}>
														<TextInput
															id="admFee"
															required
															variant="outlined"
															name="admFee"
															InputProps={{
																inputComponent: MONEY
															}}
															value={admFee}
															errors={errors}
															onFocus={e => e.target.select()}
															onChange={(_, v) => setAdmFee(+v)}
															disabled={disableAllConfig}
														/>
													</Grid>
													<Grid item xs={12} sm={6} md={4} lg={2}>
														<TextInput
															id="maxInstallment"
															required
															variant="outlined"
															name="maxInstallment"
															value={maxInstallment}
															errors={errors}
															InputProps={{
																inputProps: {
																	step: 1,
																	min: 1,
																	max: 12
																}
															}}
															type="number"
															onFocus={e => e.target.select()}
															onChange={(_, v) => setMaxInstallment(+v)}
															disabled={disableAllConfig}
														/>
													</Grid>
												</>
											)}

											{hasWiipoFlex && (
												<>
													<Grid item xs={12} sm={6} md={6} lg={4}>
														<TextInput
															id="wiipoFlexAverageValuePerUser"
															required
															variant="outlined"
															name="wiipoFlexAverageValuePerUser"
															disabled={disableAllConfig}
															InputProps={{
																inputComponent: MONEY
															}}
															value={wiipoFlexAverageValuePerUser}
															errors={errors}
															onFocus={e => e.target.select()}
															onChange={(_, v) =>
																setWiipoFlexAverageValuePerUser(+v)
															}
														/>
													</Grid>
													<Grid item xs={12} sm={6} md={6} lg={4}>
														<Select
															errors={errors}
															id="contractType"
															name="contractType"
															value={contractType}
															onChange={(_, v) => setContractType(v)}
															options={Object.keys(typeContract).map(c => ({
																id: c,
																value: ls[typeContract[c]]
															}))}
															disabled={disableAllConfig}
														/>
													</Grid>
												</>
											)}

											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextInput
													id="paymentDay"
													required
													variant="outlined"
													name="paymentDay"
													InputProps={{
														inputProps: {
															step: 1,
															min: 1,
															max: 30
														}
													}}
													type="number"
													value={paymentDay}
													errors={errors}
													onFocus={e => e.target.select()}
													onChange={(_, v) => setPaymentDay(+v)}
													disabled={disableAllConfig}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<Select
													errors={errors}
													id="limitType"
													name="limitType"
													value={limitType}
													required
													onChange={(_, v) => setLimitType(v)}
													options={[
														{ id: "0", value: "Período" },
														{ id: "1", value: "Total" }
													]}
													disabled={disableAllConfig}
												/>
											</Grid>
										</>
									</>
								)}
								{(isCompany || isHeadquarters) && (
									<>
										{admin && hasWiipoClub && (
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<TextInput
													id="maxInstallment"
													required
													variant="outlined"
													name="maxInstallment"
													value={maxInstallment}
													errors={errors}
													InputProps={{
														inputProps: {
															step: 1,
															min: 1,
															max: config.maxInstallment
														}
													}}
													type="number"
													onFocus={e => e.target.select()}
													onChange={(_, v) => setMaxInstallment(+v)}
													disabled={disableAllConfig}
												/>
											</Grid>
										)}
									</>
								)}
								{(admin || isBusinessAdmin) && hasHCM && hasWiipoClub && (
									<>
										<Grid item xs={12} sm={6} md={4} lg={3}>
											<TextInput
												id="percentClubWiipo"
												required
												variant="outlined"
												name="limitClubeWiipo"
												InputProps={{
													inputComponent: PERCENTAGE2
												}}
												value={percentClubWiipo}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={(_, v) => setPercentClubWiipo(+v)}
												disabled={disableAllConfig}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4} lg={4}>
											<Button
												color="primary"
												variant="contained"
												onClick={handleEditLimitClube}
												className={classes.button}
												disabled={limitLoading}
											>
												{limitLoading ? (
													<Spinner color="secondary" size={16} />
												) : (
													ls.refreshLimit
												)}
											</Button>
										</Grid>
									</>
								)}
								{(admin || isBusinessAdmin) && hasWiipoClub && hasHCM && (
									<Grid item xs={12}>
										<FormControlLabel
											control={
												<Switch
													checked={limitTraineeAllowed}
													onChange={e =>
														setLimitTraineeAllowed(e.target.checked)
													}
													disabled={disableAllConfig}
												/>
											}
											label="Permitir Clube Wiipo para Estagiários/Menor Aprendiz"
										/>
									</Grid>
								)}
								{admin && (
									<>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Switch
														checked={useBusinessRenewal}
														onChange={e =>
															setUseBusinessRenewal(e.target.checked)
														}
														disabled={disableAllConfig}
													/>
												}
												label="Usar configurações de renovação da empresa"
											/>
										</Grid>
										{admin && isStore && (
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Switch
															checked={includeInInvoicing}
															onChange={e =>
																setIncludeInInvoicing(e.target.checked)
															}
														/>
													}
													label="Habilitar Loja para o faturamento do Clube Wiipo"
												/>
											</Grid>
										)}
										{admin && hasWiipoClub && (
											<Grid item xs={12}>
												<FormControlLabel
													control={
														<Switch
															checked={purchasesWithPayroll}
															onChange={e =>
																setPurchasesWithPayroll(e.target.checked)
															}
															disabled={disableAllConfig}
														/>
													}
													label="Permitir compras no Clube Wiipo com folha de pagamento"
												/>
											</Grid>
										)}
										<>
											<Grid item xs={12} sm={6} md={4}>
												<Select
													errors={errors}
													id="renewalCycle"
													name="renewalCycle"
													value={renewalCycle}
													disabled={disableAllConfig}
													onChange={(_, v) => setRenewalCycle(v)}
													options={Object.keys(RenewalCycle).map(c => ({
														id: c,
														value: ls[RenewalCycle[c]]
													}))}
												/>
											</Grid>

											<Grid item xs={12} sm={6} md={4}>
												<TextInput
													id="renewalDay"
													required
													variant="outlined"
													name="renewalDayM"
													InputProps={{
														inputProps: {
															step: 1,
															min: 1,
															max: 30
														}
													}}
													type="number"
													value={renewalDay}
													errors={errors}
													onFocus={e => e.target.select()}
													onChange={(_, v) => setRenewalDay(+v)}
													disabled={disableAllConfig}
												/>
											</Grid>
										</>
									</>
								)}
								{isStore && (
									<>
										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Switch
														checked={enableDirectPayment}
														onChange={e =>
															setEnableDirectPayment(e.target.checked)
														}
													/>
												}
												label="Pagar direto"
											/>
										</Grid>

										<Grid item xs={12}>
											<FormControlLabel
												control={
													<Switch
														checked={usersCanAddCreditOnApp}
														onChange={e =>
															setUsersCanAddCreditOnApp(e.target.checked)
														}
													/>
												}
												label="Usuários podem adicionar crédito no app"
											/>
										</Grid>

										<Grid item xs={12} sm={12} md={6}>
											<TextInput
												id="accessionUrl"
												variant="outlined"
												name="accessionUrl"
												value={accessionUrl}
												errors={errors}
												type="url"
												onFocus={e => e.target.select()}
												onChange={(_, v) => setAccessionUrl(v)}
											/>
										</Grid>

										<Grid item xs={12} sm={12} md={6}>
											<TextInput
												id="accessionEmail"
												required={Boolean(accessionUrl)}
												variant="outlined"
												name="accessionEmail"
												value={accessionEmail}
												errors={errors}
												type="email"
												onFocus={e => e.target.select()}
												onChange={(_, v) => setAccessionEmail(v)}
											/>
										</Grid>
									</>
								)}
							</Grid>
							<div className={classes.footer}>
								{isBusinessAdmin && (
									<div>
										<Button
											color="primary"
											variant="contained"
											onClick={() => handleSubmit()}
											disabled={loading.config}
										>
											{ls.save}
											{loading.config && (
												<Spinner color="secondary" size={16} />
											)}
										</Button>
									</div>
								)}

								{admin && (
									<div>
										<Button variant="contained" onClick={() => handleClose()}>
											{ls.cancel}
										</Button>
										<Button
											color="primary"
											variant="contained"
											type="submit"
											disabled={loading.config}
										>
											{ls.save}
											{loading.config && (
												<Spinner color="secondary" size={16} />
											)}
										</Button>
									</div>
								)}
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditConfig;
