import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import { toastError, toastSuccess } from "helpers/toast";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAccountTransfer } from "store/actions/balanceAccount/transfer";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";
import { updateTaxBags } from "store/actions/tax";
import { Bags } from "views/Dashboard/Business/Detail/Tax";

import EditLine from "../EditLine";
import ModalSave from "../ModalSave";
import {
	BackgroundInput,
	Button,
	ColumnTax,
	Container,
	RowTax,
	TextTax,
	Title
} from "./styles";

interface PropsModalTax {
	open: boolean;
	disabledEdit: boolean;
	id: string;
	name: any;
	cnpj: any;
	handleClose: () => void;
}

let debounceEditPTax: (() => any) & _.Cancelable = null;

let debounceGetTransferBags: (() => void) & _.Cancelable = null;

const ModalTax: React.FC<PropsModalTax> = ({
	id,
	open,
	disabledEdit,
	handleClose,
	name,
	cnpj
}) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	const { types } = useSelector<any, IBalanceAccountState>(
		state => state.balanceAccount
	);

	const findClube = types?.find(i => i.externalId === "0");

	const [openModalSave, setOpenModalSave] = useState(false);
	const [bags, setBags] = useState<any[]>([]);

	const getTransferBags = useCallback(() => {
		if (debounceGetTransferBags) {
			debounceGetTransferBags.cancel();
		}

		setLoading(true);

		debounceGetTransferBags = _.debounce(
			() =>
				dispatch(
					getBalanceAccountTransfer({
						balanceAccountId: findClube?.id,
						businessId: id,
						callback: (err, model) => {
							if (err) {
								toastError(err);
								handleClose();
							} else {
								setBags(
									model
										.filter(item => item.status === 1)
										.map(i => {
											const limitRescueFee =
												i.toPrePaidBalanceAccountType?.prePaidBalanceFees[0]
													?.limitRescueFee;
											return {
												name: i.toPrePaidBalanceAccountType?.name,
												prePaidBalanceAccountTypeId:
													i.toPrePaidBalanceAccountType?.id,
												limitRescueFee: limitRescueFee / 100
											};
										})
								);
							}

							setLoading(false);
						}
					})
				),
			500
		);
		if (findClube?.id) {
			debounceGetTransferBags();
		}
	}, [dispatch, findClube?.id, handleClose, id]);

	useEffect(() => {
		if (findClube) {
			getTransferBags();
		}
	}, [findClube]);

	const handleOpenModalSave = useCallback(() => {
		setOpenModalSave(true);
	}, []);

	const handleCloseModalSave = useCallback(() => {
		handleClose();
		setOpenModalSave(false);
	}, [handleClose]);

	const saveData = useCallback(() => {
		if (debounceEditPTax) debounceEditPTax.cancel();
		debounceEditPTax = _.debounce(() => {
			dispatch(
				updateTaxBags(id, bags, err => {
					if (err) {
						toastError(err);
					} else {
						toastSuccess("Salvo com sucesso");
						handleCloseModalSave();
						handleClose();
					}
				})
			);
		}, 500);

		debounceEditPTax();
	}, [bags, dispatch, handleClose, handleCloseModalSave, id]);

	const editData = useCallback(
		(typeId: string, value: any) => {
			const newArray = [...bags];

			const findItem = newArray.find(
				i => i.prePaidBalanceAccountTypeId === typeId
			);

			if (findItem) {
				findItem.limitRescueFee = value.replace("%", "").replace(",", ".");
			}

			setBags(newArray);
		},
		[bags]
	);

	const bagsEmpty = useMemo(() => {
		if (bags.length > 0) {
			return bags.map((i, index) => (
				<ColumnTax>
					<TextTax>{i?.name}</TextTax>
					<BackgroundInput isPair={index % 2 === 0}>
						<EditLine
							disabledEdit={disabledEdit}
							cnpj={cnpj}
							isBag
							businessName={name}
							item={i}
							value={i?.limitRescueFee}
							key={i?.prePaidBalanceAccountTypeId}
							editItem={editData}
						/>
					</BackgroundInput>
				</ColumnTax>
			));
		}

		return (
			<div>
				Ative o bolso clube wiipo e defina o bolso destino para poder configurar
				as taxas
			</div>
		);
	}, [bags, cnpj, disabledEdit, editData, name]);

	return (
		<ModalGeneric
			open={open}
			handleClose={() => handleClose()}
			buttonClose
			stylePaper={{
				maxWidth: 667,
				maxHeight: 616,
				padding: 20,
				borderRadius: 16
			}}
			body={() => (
				<Container>
					<Title>Taxa de resgate de cada bolso</Title>
					<RowTax style={bags.length > 0 ? { overflow: "auto" } : {}}>
						{!loading ? (
							bagsEmpty
						) : (
							<>
								<Spinner color="secondary" size={32} />
							</>
						)}
					</RowTax>

					<RowTax
						style={{ width: "100%", margin: 20, justifyContent: "flex-end" }}
					>
						<Button onClick={handleClose}>Cancelar</Button>
						<Button isPurple onClick={handleOpenModalSave}>
							Salvar
						</Button>
					</RowTax>

					<ModalSave
						businessName={name}
						cnpj={cnpj}
						saveData={saveData}
						open={openModalSave}
						handleClose={handleCloseModalSave}
					/>
				</Container>
			)}
		/>
	);
};

export default ModalTax;
