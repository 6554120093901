import history from "AppHistory";
import Checkbox from "Components/Checkbox";
import ModalGeneric from "Components/Modals/ModalGeneric";
import TextInputGeneric from "Components/TextInputGeneric";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "store/actions/business/createBatch";

import {
	BackgroundInput,
	Body,
	Button,
	Container,
	Label,
	Line,
	RowButtons,
	RowCodCli,
	TextButton,
	Title
} from "./styles";

interface IPropsModalSelectImport {
	open: boolean;
	handleClose: () => void;
	historyPush: () => void;
	pageIdentifier: string;
}
const ModalSelectImport: React.FC<IPropsModalSelectImport> = ({
	open,
	handleClose,
	historyPush,
	pageIdentifier
}) => {
	const dispatch = useDispatch();
	const [manual, setManual] = useState(false);
	const [batch, setBatch] = useState(false);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const { codCli } = useSelector<any, any>(s => s.businessBatch);

	const changeValue = useCallback((isManual: boolean) => {
		if (isManual) {
			setManual(true);
			setBatch(false);
		} else {
			setManual(false);
			setBatch(true);
		}
	}, []);

	const onSelect = useCallback(() => {
		if (manual) {
			historyPush();
		} else {
			history.push(`/${pageIdentifier}/CreateBatch`);
		}
	}, [historyPush, manual, pageIdentifier]);

	const disableButton = useMemo(() => {
		if (!batch && !manual) return true;
		if (batch && !codCli) return true;

		return false;
	}, [batch, codCli, manual]);

	return (
		<ModalGeneric
			open={open}
			handleClose={handleClose}
			stylePaper={{ height: 379, width: 763, borderRadius: 16 }}
			body={() => (
				<Container>
					<Title>Cadastro de empresa</Title>
					<Line />
					<Body>
						<Checkbox
							id="manualRegistration"
							name="manualRegistration"
							style={{ color: "#000000" }}
							checked={manual}
							onChange={() => {
								changeValue(true);
							}}
						/>
						<RowCodCli>
							<Checkbox
								id="batchRegister"
								name="batchRegister"
								checked={batch}
								onChange={() => {
									changeValue(false);
								}}
							/>
							{batch && (
								<BackgroundInput>
									<Label>CODCLI:</Label>{" "}
									<TextInputGeneric
										id="codCli"
										variant="filled"
										placeholder="Digite o CodCli"
										InputProps={{
											disableUnderline: true,
											style: {
												padding: 8,
												height: 48,
												backgroundColor: "#fff",
												borderBlockColor: "#707070",
												borderStyle: "solid",
												borderWidth: 1,
												borderRadius: 8
											}
										}}
										isPadding={false}
										value={codCli}
										onChange={handleChange}
										disabled={false}
										size="small"
									/>
								</BackgroundInput>
							)}
						</RowCodCli>

						<RowButtons>
							<Button onClick={handleClose}>
								<TextButton>Cancelar</TextButton>
							</Button>
							<Button
								isButtonPurple
								onClick={onSelect}
								isDisable={disableButton}
								disabled={disableButton}
							>
								<TextButton isButtonPurple>Continuar</TextButton>
							</Button>
						</RowButtons>
					</Body>
				</Container>
			)}
		/>
	);
};

export default ModalSelectImport;
