import { Fab, IconButton } from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { cnpjMask } from "helpers/format";
import { storeIsViaVarejo } from "helpers/stores-via-varejo";
import { toStringCurrency, toStringPercentage } from "helpers/string";
import { toastError, toastSuccess, toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import businessStatus from "models/businessStatus";
import installmentPaymentType from "models/installmentPaymentType";
import saleStatus from "models/saleStatus";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FaFileImport } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { validateImportTransactions } from "store/actions/transaction/import";
import * as actions from "store/actions/transaction/report";
import ImportTransactions from "views/Dashboard/Business/importTransactions";
import XLSX from "xlsx";

import { getTransactionsWiipoClubReport } from "../../../../store/actions/transaction/report/index";
import AddTransaction from "../Add";
import {
	extractTransactionsFromJson,
	mergeTransactionsWithValidations
} from "./helpers";
import useStyles from "./styles";

dayjs.extend(customParseFormat);

let getTransactionsDebounced: (() => void) & _.Cancelable = null;
let getTransactionsWiipoClubReportDebounced: (() => void) & _.Cancelable = null;

function TransactionReport({
	state,
	login: { user },
	getTransactions,
	params
}: any) {
	const dispatch = useDispatch();
	const classes = useStyles({});
	const table = useRef(null);

	const [showAdd, setShowAdd] = useState(false);
	const [importTransactionData, setImportTransactionData] = useState(null);

	const inputRef = useRef<HTMLInputElement>(null);
	const handleImport = useCallback(() => {
		inputRef.current.click();
	}, []);

	const [isWiipoFlex, isWiipoClub] = useMemo(() => {
		const { wiipoFlex, wiipoClub } = params || {};

		return [wiipoFlex, wiipoClub];
	}, [params]);

	const handleFetchData = useCallback(
		tableState => {
			let {
				page,
				pageSize,
				sorted = [{ id: "creationDate", desc: true }],
				filtered,
				toExport,
				callback
			} = tableState;

			if (isWiipoClub && toExport) return;

			let installmentPaymentType = isWiipoFlex ? 5 : "";
			let storeCNPJ = isWiipoFlex ? "50111456000105" : "";

			if (getTransactionsDebounced) {
				getTransactionsDebounced.cancel();
			}

			if (!sorted) sorted = [];
			let filterFlex = "";

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: dayjs(creationDate.value.startDate).format("YYYY-MM-DD")
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: dayjs(creationDate.value.endDate).format("YYYY-MM-DD")
					});
			}

			if (isWiipoFlex) {
				filterFlex = `&filters[store]=""&filters[store.cnpj]=${storeCNPJ}&filters[installmentPaymentType]=${installmentPaymentType}`;
			}

			if (isWiipoClub) {
				filterFlex = "&filters[store.cnpj.not.in]=50111456000105";
			}

			const filterTable =
				filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, "") +
				filterFlex;

			getTransactionsDebounced = _.debounce(
				() =>
					getTransactions(
						page * pageSize,
						pageSize,
						filterTable,
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					),
				1000
			);

			getTransactionsDebounced();
		},
		[isWiipoClub, isWiipoFlex, getTransactions]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state),
		[handleFetchData]
	);

	const handleFilesChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files[0];

			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;

			reader.onload = e => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
				/* Get first worksheet */
				const wsName = wb.SheetNames[0];
				const sheet = wb.Sheets[wsName];

				const ref = XLSX.utils.decode_range(sheet["!ref"]);

				for (let C = ref.s.c; C <= ref.e.c; ++C) {
					const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
					if (cell && cell.t === "s") {
						cell.v = cell.v.trim();
						if (cell.w) cell.w = cell.w.trim();
					}
				}

				/* Update state */
				const transactions = extractTransactionsFromJson(
					XLSX.utils.sheet_to_json(sheet, {}),
					toastError
				);

				if (transactions.length > 0) {
					toastWarning("Validando informações no banco...");
					dispatch(
						validateImportTransactions(transactions, (err, model) => {
							if (err) {
								toastError("Erro nas validações");
							} else {
								const validations = model;
								const transactionsWithValidations =
									mergeTransactionsWithValidations(transactions, validations);

								const allRowsAreValid = validations.every(
									row => row.isValidRow === true
								);
								if (allRowsAreValid) {
									toastSuccess(
										"Verificação de CPF e CNPJ concluída com sucesso"
									);
								} else {
									const invalidRows = validations.filter(
										row => row.isValidRow === false
									).length;
									if (invalidRows === 1)
										toastError("Encontrado erro em 1 linha");
									else
										toastError(
											`Encontrado erro em ${invalidRows} das ${validations.length} linhas`
										);
								}

								setImportTransactionData(transactionsWithValidations);
							}
							inputRef.current.value = "";
						})
					);
				} else {
					inputRef.current.value = "";
				}
			};

			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		},
		[dispatch]
	);

	const isAdminBusiness = useMemo(
		() => isInRole(user, ["Admin", "Business Admin"]),
		[user]
	);

	const columns = useMemo(
		() =>
			[
				{
					Header: ls.creationDate,
					id: "creationDate",
					accessor: c =>
						dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
					width: 150,
					show: true,
					sortMethod: (a, b) => {
						let aD = dayjs(a, ls.dateFormatWithoutSeconds);
						let bD = dayjs(b, ls.dateFormatWithoutSeconds);

						return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
					},
					Filter: DateSelectFilter
				},
				{
					Header: ls.code,
					id: "code",
					accessor: c => c.code,
					show: true,
					width: 80
				},
				{
					Header: ls.client,
					id: "client",
					accessor: c => c.client?.fullName ?? ls.none,
					show: true
				},
				{
					Header: ls.store,
					id: "store",
					accessor: c => {
						if (!c.store?.name) return ls.none;

						let { name } = c.store;

						if (c.store.status !== 1)
							name += `(${ls[businessStatus[c.store.status]]})`;

						return name;
					},
					show: true,
					width: 180
				},
				{
					Header: ls.category,
					id: "category",
					accessor: c => c.store?.category?.name ?? ls.none,
					show: true
				},
				{
					Header: ls.company,
					id: "company",
					accessor: c => c.company?.name ?? ls.none,
					show: true,
					width: 180
				},
				{
					Header: ls.cnpj,
					id: "company.cnpj",
					accessor: c =>
						c.company?.cnpj ? cnpjMask(c.company.cnpj) : ls.notDefined,
					show: true,
					width: 160
				},
				{
					Header: ls.value,
					id: "value",
					format: c => c.value / 100,
					accessor: c => toStringCurrency(c.value / 100),
					Footer: () => <b>{toStringCurrency(state.value / 100)}</b>,
					width: 120
				},
				{
					Header: ls.discount,
					id: "discount",
					format: c => c.discount / 100,
					accessor: c =>
						`${toStringCurrency(c.discount / 100)}(${toStringPercentage(
							c.discount,
							c.value
						)})`,
					Footer: () => (
						<b>
							{toStringCurrency(state.discount / 100)}(
							{toStringPercentage(state.discount, state.value)})
						</b>
					),
					show: true,
					width: 150
				},
				{
					Header: ls.finalValue,
					id: "finalValue",
					format: c => {
						if (storeIsViaVarejo(c.storeId)) return c.value / 100;
						return (c.value - c.discount) / 100;
					},
					accessor: c => {
						if (storeIsViaVarejo(c.storeId))
							return toStringCurrency(c.value / 100);
						return toStringCurrency((c.value - c.discount) / 100);
					},
					Footer: () => (
						<b>{toStringCurrency((state.value - state.discount) / 100)}</b>
					),
					show: true,
					width: 120
				},
				isAdminBusiness
					? {
							Header: ls.fee,
							id: "fee",
							format: c => c.fee / 100,
							accessor: c =>
								`${toStringCurrency(c.fee / 100)}(${toStringPercentage(
									c.fee,
									c.value - c.discount
								)})`,
							Footer: () => (
								<b>
									{toStringCurrency(state.fee / 100)}(
									{toStringPercentage(state.fee, state.value - state.discount)})
								</b>
							),
							show: true,
							width: 130
					  }
					: null,
				{
					Header: ls.installments,
					id: "installmentAmount",
					accessor: c => c.installmentAmount,
					width: 120
				},
				{
					Header: ls.status,
					id: "status",
					sortable: false,
					show: true,
					accessor: c => ls[saleStatus[c.status]],
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter ? filter.value : "all"}
						>
							<option value="">Todos</option>
							{Object.keys(saleStatus).map(c => (
								<option key={c} value={c}>
									{ls[saleStatus[c]]}
								</option>
							))}
						</select>
					),
					width: 130
				},
				!isWiipoFlex && {
					Header: ls.paymentMethod,
					id: "paymentMethod",
					sortable: false,
					show: true,
					width: 300,
					accessor: c =>
						c.installments?.length
							? ls[installmentPaymentType[c?.installments[0]?.paymentType]]
							: ls.notDefined,

					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter ? filter.value : "all"}
						>
							<option value="">Todos</option>
							{Object.keys(installmentPaymentType).map(c => (
								<option key={c} value={c}>
									{ls[installmentPaymentType[c]]}
								</option>
							))}
						</select>
					)
				}
			].filter(c => c),
		[isWiipoFlex, state, isAdminBusiness]
	);

	const handleExportExternally = useCallback(
		tableState => {
			let { sorted = [{ id: "creationDate", desc: true }], filtered } =
				tableState;

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: dayjs(creationDate.value.startDate).format("YYYY-MM-DD")
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: dayjs(creationDate.value.endDate).format("YYYY-MM-DD")
					});
			}

			const filterTable = filtered.reduce(
				(p, c) => `${p}&filters[${c.id}]=${c.value}`,
				""
			);

			toastSuccess("O relatório de transações será enviado para o seu e-mail.");
			getTransactionsWiipoClubReportDebounced = _.debounce(
				() =>
					dispatch(
						getTransactionsWiipoClubReport(
							sorted[0]?.id,
							sorted[0]?.desc,
							filterTable
						)
					),
				1000
			);

			getTransactionsWiipoClubReportDebounced();
		},
		[dispatch]
	);

	const handleImportTransactionClose = useCallback(() => {
		inputRef.current.value = "";
		setImportTransactionData(null);
	}, []);

	const titleComplement = useMemo(() => {
		if (isWiipoFlex) return "Flex";

		if (isWiipoClub) return "Clube";

		return "Geral";
	}, [isWiipoFlex, isWiipoClub]);

	return (
		<div>
			<Helmet>
				<title>
					{ls.transactions} {titleComplement} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.transactions}
				data={state.items}
				pages={state.pages}
				onFetchData={handleFetchData}
				filterable
				showImport
				useQueryString
				handleExportExternally={handleExportExternally}
				headerRightComponent={
					<div>
						<a
							href="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ02MiaiCbHcPTLPvgW7CowsdpwLMoJXoQGF7WYLQNJOf2Oy7nA7KHla8UKeBgmm2SrEPuaNXY2UVCl/pub?output=xlsx"
							target="_blank"
							rel="noopener noreferrer"
							style={{ marginRight: 16 }}
						>
							Baixar modelo de importação
						</a>
						{state.loading.import ? (
							<Spinner color="secondary" size={16} />
						) : (
							<TooltipLight title={ls.importTransactions} placement="top">
								<IconButton onClick={handleImport}>
									<FaFileImport />
								</IconButton>
							</TooltipLight>
						)}
					</div>
				}
				showExport
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={state.loading}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
				onRowClicked={row => window.open(`/Transaction/${row.id}`, "_blank")}
			/>

			{isAdminBusiness && (
				<Fab
					color="primary"
					aria-label="Add"
					className={classes.fab}
					onClick={() => setShowAdd(true)}
				>
					<MdAdd size={24} />
				</Fab>
			)}

			{isAdminBusiness && (
				<AddTransaction
					open={showAdd}
					user={user}
					handleClose={() => {
						handleFetchData(table.current.state);
						setShowAdd(false);
					}}
				/>
			)}

			<ImportTransactions
				open={Boolean(importTransactionData)}
				items={importTransactionData}
				handleClose={handleImportTransactionClose}
				load={load}
			/>

			<input
				ref={inputRef}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFilesChange}
			/>
		</div>
	);
}

const mapStateToProps = ({ transactionReport: state, login }) => ({
	state,
	login
});
const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(TransactionReport);
