import dayjs from "dayjs";
import { toastError } from "helpers/toast";

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
	let [resource, config] = args;
	const response = await originalFetch(resource, config);

	const unauthorized = response.status === 401;

	if (unauthorized) {
		const result = await response.json();
		const errors = [
			"ORIGIN_NOT_ALLOWED_ERROR",
			"ACCESS_DENIED_ERROR",
			"PASSWORD_EXPIRED",
			"USER_BLOCKED_ATTEMPTS_CONFIRM_LOGIN",
			"CODE_INVALID_OR_EXPIRED",
			"CODE_INVALID",
			"ATTEMPT_LIMIT_MFA_EXCEEDED",
			"ACCOUNT_LOCKOUT_OUT",
			"EMAIL_OR_PASSWORD_INVALID"
		];

		if (errors.includes(result?.error) && result.message)
			toastError(result.message);
	}

	return response;
};

const mapLocalServices = {
	swap: "http://localhost:5010"
};

let api = "";

if (process.env.REACT_APP_API) {
	api = process.env.REACT_APP_API;
} else if (process.env.NODE_ENV === "development") {
	api = "https://wiipoflex-albnv-dev.wiipo.com";
	// api = "https://flex-api.wiipo.com";
	// api = "http://localhost:5000";
} else {
	api = window.location.protocol.indexOf("https") > -1 ? "" : "";
}

export const serverUrl = api;

export function createRequest(
	url: RequestInfo,
	method: RequestInit["method"],
	body?: any,
	headers: RequestInit["headers"] = {},
	service?: string
) {
	let options: RequestInit = {
		method,
		headers: {
			"content-type": "application/json",
			timezone: dayjs().format("Z"),
			"x-source": "dash",
			...headers
		}
	};

	if (body) options.body = JSON.stringify(body);

	if (api.indexOf("localhost") === -1 && service) url = `/${service}${url}`;

	if (api.indexOf("localhost") > -1 && service)
		url = `${mapLocalServices[service] || api}${url}`;
	else url = `${api}${url}`;

	return fetch(url, options);
}

export function sendPost(
	url: RequestInfo,
	body?: any,
	headers?: RequestInit["headers"],
	service?: string
) {
	return createRequest(url, "POST", body, headers, service);
}

export function sendPut(
	url: RequestInfo,
	body?: any,
	headers?: RequestInit["headers"],
	service?: string
) {
	return createRequest(url, "PUT", body, headers, service);
}

export function sendGet(
	url: RequestInfo,
	headers?: RequestInit["headers"],
	service?: string
) {
	return createRequest(url, "GET", null, headers, service);
}

export function sendDelete(
	url: RequestInfo,
	headers?: RequestInit["headers"],
	service?: string
) {
	return createRequest(url, "DELETE", null, headers, service);
}

export function sendPostFile(
	url: RequestInfo,
	file: any,
	headers?: RequestInit["headers"],
	service?: string
) {
	const formData = new FormData();

	if (file) formData.append("file", file);

	const options = {
		method: "POST",
		body: formData,
		headers: {
			// If you add this, upload won't work
			// "Content-Type": "multipart/form-data",
			...headers
		}
	};

	if (api.indexOf("localhost") === -1 && service) url = `/${service}${url}`;

	if (api.indexOf("localhost") > -1 && service)
		url = `${mapLocalServices[service] || api}${url}`;
	else url = `${api}${url}`;

	return fetch(url, options);
}

export function sendPostFiles(
	url: RequestInfo,
	files: any[],
	headers?: RequestInit["headers"],
	service?: string
) {
	const formData = new FormData();

	for (const file of files) {
		formData.append("files", file);
	}

	const options = {
		method: "POST",
		body: formData,
		headers: {
			// If you add this, upload won't work
			// "Content-Type": "multipart/form-data",
			...headers
		}
	};

	if (api.indexOf("localhost") === -1 && service) url = `/${service}${url}`;

	if (api.indexOf("localhost") > -1 && service)
		url = `${mapLocalServices[service] || api}${url}`;
	else url = `${api}${url}`;

	return fetch(url, options);
}
