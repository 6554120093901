import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_USERS = "[USER REPORT] GET_USERS";
export const GET_USERS_SUCCESS = "[USER REPORT] GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "[USER REPORT] GET_USERS_FAILED";

export const GET_USERS_TINY = "[USER REPORT] GET_USERS_TINY";
export const GET_USERS_TINY_SUCCESS = "[USER REPORT] GET_USERS_TINY_SUCCESS";
export const GET_USERS_TINY_FAILED = "[USER REPORT] GET_USERS_TINY_FAILED";

export const GET_ENABLED_USERS = "[USER REPORT] GET_ENABLED_USERS";
export const GET_ENABLED_USERS_SUCCESS =
	"[USER REPORT] GET_ENABLED_USERS_SUCCESS";
export const GET_ENABLED_USERS_FAILED =
	"[USER REPORT] GET_ENABLED_USERS_FAILED";

export const SEND_USERS_ONBOARDING = "[USER REPORT] SEND_USERS_ONBOARDING";
export const SEND_USERS_ONBOARDING_SUCCESS =
	"[USER REPORT] SEND_USERS_ONBOARDING_SUCCESS";
export const SEND_USERS_ONBOARDING_FAILED =
	"[USER REPORT] SEND_USERS_ONBOARDING_FAILED";

export const SET_VALUE = "[USER REPORT] SET_VALUE";
export const CLEAR_VALUES = "[USER REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getUsers(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_USERS });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/User?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();
			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_USERS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_USERS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_USERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getUsersTiny(filter?: any, callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_USERS_TINY });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/User/Tiny?${filter || "&filters=null"}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USERS_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_USERS_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_USERS_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getEnabledUsers(filter?: string, callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ENABLED_USERS });

		try {
			const { login } = getState();

			let response = await api.sendGet(`/v2/User/Enabled?filter=${filter}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ENABLED_USERS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ENABLED_USERS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_ENABLED_USERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function sendUsersOnboarding(ids: string[], callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: SEND_USERS_ONBOARDING });

		try {
			const { login } = getState();

			let response = await api.sendPost("/User/Onboarding", ids, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SEND_USERS_ONBOARDING_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: SEND_USERS_ONBOARDING_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: SEND_USERS_ONBOARDING_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
