import styled from "styled-components";

interface PropsContainer {
	isCursorPointer: boolean;
}

export const Container = styled.button<PropsContainer>`
	background: none;
	border: none;
	cursor: ${props => (props.isCursorPointer ? "pointer" : "default")};
`;
