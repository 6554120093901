import { toast, ToastOptions } from "react-toastify";

export const toastHTML = (
	msg: JSX.Element,
	callback?: () => void,
	autoClose?: number | false,
	closeButton?: boolean | false
) =>
	toast.error(msg, {
		theme: "colored",
		autoClose,
		closeButton,
		onClick: callback
	});

export const toastError = (msg: string, options?: ToastOptions) =>
	toast.error(`${msg}`, { ...options, theme: "colored" });

export const toastWarning = (msg: string, options?: ToastOptions) =>
	toast.warn(`${msg}`, { ...options, theme: "colored" });

export const toastSuccess = (msg: string, options?: ToastOptions) =>
	toast.success(`${msg}`, { ...options, theme: "colored" });
