const styles = theme => ({
	filterActive: {
		fontSize: "15px",
		display: "inline"
	},
	faCustom: {
		cursor: "pointer"
	}
});

export default styles;
