import _ from "lodash";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import * as actions from "store/actions/charge/report";

let getChargesDebounced: (() => void) & _.Cancelable = null;
const useChargeData = () => {
	const dispatch = useDispatch();
	const handleGetCharges = useCallback(
		(skip?, take?, filter?, sort?, desc?, toExport?, callback?) => {
			dispatch(
				actions.getCharges(skip, take, filter, sort, desc, toExport, callback)
			);
		},
		[dispatch]
	);
	const handleFetchData = useCallback(
		tableState => {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getChargesDebounced) {
				getChargesDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: creationDate.value.startDate
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: creationDate.value.endDate
					});
			}
			const filterTable = filtered.reduce(
				(p, c) => `${p}&filters[${c.id}]=${c.value}`,
				""
			);
			getChargesDebounced = _.debounce(() => {
				handleGetCharges(
					page * pageSize,
					pageSize,
					filterTable,
					sorted[0]?.id,
					sorted[0]?.desc,
					toExport,
					callback
				);
			}, 1000);

			getChargesDebounced();
		},
		[handleGetCharges]
	);
	return {
		handleFetchData
	};
};

export default useChargeData;
