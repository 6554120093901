import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fade,
	Grid,
	Modal,
	Paper,
	Typography
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "Components/Link";
import { MONEY } from "Components/NumberFormat";
import TextInput from "Components/TextInput";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { storeIsViaVarejo } from "helpers/stores-via-varejo";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import moderationCategory, {
	ModerationCategory
} from "models/moderationCategory";
import moderationStatus, { ModerationStatus } from "models/moderationStatus";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getModeration,
	moderationApproved,
	moderationDenied,
	setValue
} from "store/actions/moderation/detail";
import { setValue as setValueEdit } from "store/actions/moderation/edit";

import EditModeration from "../Edit";
import styles from "./styles";

const ModerationDetail = ({
	history,
	classes,
	match: {
		params: { id }
	}
}) => {
	const dispatch = useDispatch();

	const { user } = useSelector<any, any>(s => s.login);
	const { item, amount, errors } = useSelector<any, any>(
		c => c.moderationDetail
	);

	const isAdmin = isInRole(user, ["admin"]);

	const [loadingApproved, setLoadingApproved] = useState(false);
	const [loadingDeny, setLoadingDeny] = useState(false);

	const [openModalApprove, setOpenModalApprove] = useState(false);
	const [openModalDeny, setOpenModalDeny] = useState(false);
	const [showModerationOptions, setShowModerationOptions] = useState(false);

	const load = useCallback(() => {
		dispatch(getModeration(id));
	}, [dispatch, id]);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		if (item && item.status === ModerationStatus.Pending) {
			if (
				[
					ModerationCategory.ConsumptionLimit,
					ModerationCategory.Order
				].includes(item.category) &&
				isAdmin
			) {
				setShowModerationOptions(true);
			}

			if (item.category === ModerationCategory.Allowance)
				setShowModerationOptions(true);
		}
	}, [item, isAdmin]);

	if (!item) return null;

	const handleChange = (id, value) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleChangeEdit = (id, value) => {
		dispatch(setValueEdit({ [id]: value }));
	};

	const handleDeniedModeration = () => {
		setLoadingDeny(true);
		dispatch(
			moderationDenied(item.id, err => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
					setLoadingDeny(false);
				} else {
					setOpenModalDeny(false);
					setShowModerationOptions(false);
					setTimeout(() => {
						setLoadingDeny(false);
					}, 1000);
				}
			})
		);
	};

	const handleApprovedModeration = () => {
		setLoadingApproved(true);
		dispatch(
			moderationApproved(item.id, err => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
					setLoadingApproved(false);
				} else {
					setLoadingApproved(false);
					history.push("/moderation");
					setOpenModalApprove(!openModalApprove);
					setShowModerationOptions(false);
				}
			})
		);
	};

	const resolveFinalValue = () => {
		switch (item.category) {
			case ModerationCategory.Allowance: {
				return toStringCurrency(
					(item?.sale?.value - (item.amount || 0) - item?.sale?.discount) / 100
				);
			}
			case ModerationCategory.Order: {
				if (storeIsViaVarejo(item?.sale?.store?.id))
					return toStringCurrency(item?.sale.value / 100);

				return ls.notDefined;
			}
			case ModerationCategory.ConsumptionLimit: {
				return toStringCurrency(item.originalAmount / 100);
			}

			default:
				return ls.notDefined;
		}
	};

	const resolveRequester = () => {
		if (item.category === ModerationCategory.ConsumptionLimit) {
			return (
				<Link
					link={`/User/${item?.requesterBy?.id}`}
					label={item?.requesterBy?.fullName}
				/>
			);
		}

		return (
			<Link link={`/User/${item.client?.id}`} label={item.client.fullName} />
		);
	};

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.moderationData}</Typography>
						<div>
							{isInRole(user, ["admin"]) && item.status === 2 && (
								<Button
									color="primary"
									onClick={() => handleChangeEdit("showEditModal", true)}
								>
									{ls.edit}
								</Button>
							)}
						</div>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.code}</strong>:{" "}
							{item?.sale ? (
								<Link
									link={`/Transaction/${item?.sale?.id}`}
									label={item?.sale?.code}
								/>
							) : (
								ls.notInformed
							)}
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.creationDate}</strong>:{" "}
							{item.creationDate
								? dayjs(item.creationDate).format(ls.dateFormatWithoutSeconds)
								: ls.notInformed}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.requester}</strong>: {resolveRequester()}
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.client}</strong>:{" "}
							<Link
								link={`/User/${item?.client?.id}`}
								label={item?.client?.fullName}
							/>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.company}</strong>:{" "}
							{item.company?.displayName ? (
								<Link
									link={`/Company/${item.company?.id}`}
									label={item.company.displayName}
								/>
							) : (
								ls.notInformed
							)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.store}</strong>:{" "}
							{item?.sale?.store?.displayName
								? item?.sale.store.displayName
								: ls.notInformed}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.cpf}</strong>:{" "}
							{item.client && item.client.cpf
								? item.client.cpf
								: ls.notInformed}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.category}</strong>:{" "}
							{item && String(item.category)
								? ls[moderationCategory[item.category]]
								: ls.notInformed}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.status}</strong>:{" "}
							{item && String(item.status)
								? ls[moderationStatus[item.status]]
								: ls.notInformed}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.value}</strong>:{" "}
							{toStringCurrency((item?.sale?.value || 0) / 100)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.discount}</strong>:{" "}
							{toStringCurrency((item?.sale?.discount || 0) / 100)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.finalValue}</strong>: {resolveFinalValue()}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.fee}</strong>:{" "}
							{toStringCurrency(item?.sale?.fee / 100 || 0)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.approvedBy}</strong>:{" "}
							{item.approvedBy ? (
								<>
									<Link
										link={`/User/${item.approvedBy?.id}`}
										label={item.approvedBy?.fullName}
									/>
									{item?.approvedDate &&
										` - ${dayjs(item.approvedDate).format(
											ls.dateFormatWithoutSeconds
										)}`}
								</>
							) : (
								"-"
							)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.deniedBy}</strong>:{" "}
							{item.deniedBy ? (
								<>
									<Link
										link={`/User/${item.deniedBy?.id}`}
										label={item.deniedBy?.fullName}
									/>
									{item?.deniedDate &&
										` - ${dayjs(item.deniedDate).format(
											ls.dateFormatWithoutSeconds
										)}`}
								</>
							) : (
								"-"
							)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.canceledBy}</strong>:{" "}
							{item.canceledBy ? (
								<>
									<Link
										link={`/User/${item.canceledBy?.id}`}
										label={item.canceledBy?.fullName}
									/>
									{item?.canceledDate &&
										` - ${dayjs(item.canceledDate).format(
											ls.dateFormatWithoutSeconds
										)}`}
								</>
							) : (
								"-"
							)}
						</Grid>
					</Grid>
				</Paper>
			</Grid>

			<Grid item xs={11} lg={5} className={classes.form}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.attachments}</Typography>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12}>
							{item.files.length ? (
								item.files.map(c => (
									<a
										href={c.link}
										target="_blank"
										style={{
											display: "flex",
											flexDirection: "row"
										}}
										rel="noreferrer"
									>
										<Typography>{c.fileName}</Typography>
									</a>
								))
							) : (
								<Typography align="center">
									Solicitação de subsídio não possui anexos
								</Typography>
							)}
						</Grid>
					</Grid>
				</Paper>
			</Grid>

			<Grid item xs={11} lg={6} container direction="column">
				<Grid item xs={12} className={classes.form}>
					<Paper className={classes.root}>
						<div className={classes.header}>
							<Typography variant="h6">Observações do solicitante</Typography>
						</div>
						<hr className={classes.line} />

						<Grid container spacing={2} className={classes.form}>
							<Grid item xs={12}>
								{item.note ? item.note : ls.notInformed}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			<Grid xs={3}>
				{showModerationOptions && (
					<>
						<Button
							size="large"
							className={classes.margin}
							variant="contained"
							color="primary"
							onClick={() => setOpenModalApprove(!openModalApprove)}
						>
							Aprovar
						</Button>
						<Button
							disabled={item.status === 0 || item.status === 3}
							size="large"
							className={classes.margin}
							variant="contained"
							color="secondary"
							onClick={() => setOpenModalDeny(true)}
						>
							Negar
						</Button>
					</>
				)}
			</Grid>

			<Modal
				aria-labelledby="denied-moderation-modal-title"
				aria-describedby="denied-moderation-modal-description"
				className={classes.modal}
				open={openModalApprove}
				onClose={() => setOpenModalApprove(!openModalApprove)}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{ timeout: 500 }}
			>
				<Fade in={openModalApprove}>
					<Grid item xs={7}>
						<Paper className={classes.root}>
							<div className={classes.header}>
								<Typography>Aprovar Moderação</Typography>
							</div>
							<hr className={classes.line} />
							<Grid item container xs={12}>
								<Grid item className={classes.margin}>
									<Typography>Valor liquido do consumo</Typography>
									<TextInput
										id="value"
										variant="outlined"
										name="value"
										label={null}
										disabled
										value={
											item.category === ModerationCategory.Allowance
												? (item?.sale?.value - item?.sale?.discount) / 100
												: item?.originalAmount / 100
										}
										required
										InputProps={{ inputComponent: MONEY }}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								{item.category === ModerationCategory.Allowance && (
									<Grid item className={classes.margin}>
										<Typography>Valor a subsidiar</Typography>
										<TextInput
											id="amount"
											variant="outlined"
											name="amount"
											value={amount}
											required
											InputProps={{ inputComponent: MONEY }}
											errors={errors}
											onChange={handleChange}
										/>
									</Grid>
								)}
							</Grid>

							<Grid item container spacing={1} xs={12}>
								{loadingApproved ? (
									<Grid
										container
										justify="center"
										className={classes.gridLoading}
									>
										<CircularProgress />
									</Grid>
								) : (
									<>
										<Button
											variant="contained"
											size="large"
											className={classes.margin}
											color="primary"
											onClick={() => handleApprovedModeration()}
										>
											Confirmar
										</Button>
										<Button
											className={classes.margin}
											size="large"
											variant="contained"
											color="secondary"
											onClick={() => setOpenModalApprove(!openModalApprove)}
										>
											Cancelar
										</Button>
									</>
								)}
							</Grid>
						</Paper>
					</Grid>
				</Fade>
			</Modal>
			<EditModeration item={item} load={load} />
			<Dialog
				open={openModalDeny}
				onClose={() => setOpenModalDeny(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja contestar o{" "}
					{ls[moderationCategory[item.category]]}?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação da contestação não será possível recuperar os
						dados.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					{loadingDeny ? (
						<Grid container justify="center" className={classes.gridLoading}>
							<CircularProgress />
						</Grid>
					) : (
						<>
							<Button
								onClick={handleDeniedModeration}
								color="primary"
								autoFocus
							>
								Confirmar
							</Button>
							<Button onClick={() => setOpenModalDeny(false)} color="primary">
								Cancelar
							</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

export default withStyles(styles, { withTheme: true })(ModerationDetail);
