import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 29px;
`;

export const Title = styled.div`
	font-size: 18px;
	display: flex;
	font-weight: bold;
	font-family: "Exo";
	padding: 9px;
	align-items: flex-start;
	color: #707070;
`;

export const Text = styled.text`
	font-size: 15px;
	display: flex;
	align-self: flex-start;
	color: #707070;
	font-family: "Exo";
`;

export const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 11px;
	align-self: flex-start;
`;

export const ColumnsText = styled.div`
	display: flex;
	flex-direction: row;
	align-self: flex-start;
`;

export const BackgroundButton = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 11px;

	font: normal normal 700 27px Exo;
	padding: 10px;
	cursor: pointer;
`;
