import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import Spinner from "Components/Spinner";
import ModalBanners from "Components/views/Dashboard/Business/Detail/Product/ModalBanners";
import ModalClicks from "Components/views/Dashboard/Business/Detail/Product/ModalClicks";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaFileImport, FaPlus } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import {
	MdBusiness,
	MdDelete,
	MdEdit,
	MdLibraryAdd,
	MdPhoto
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteProduct,
	getProduct,
	getProducts,
	setValue
} from "store/actions/product";
import { setValue as changeValueClicks } from "store/actions/product/banners";

import ImportProducts from "../../../ImportProducts";
import CompaniesForm from "../Forms/Companies";
import CreateForm from "../Forms/Create";
import EditForm from "../Forms/Edit";
import ImportFormModal from "../Forms/Import";
import ItemsModal from "../Forms/Items";
import PhotoModal from "../Forms/Photos";
import ProductPromotion from "../Forms/ProductPromotion";
import styles from "./styles";

let getProductsDebounced: (() => void) & _.Cancelable = null;

function Products({ business, hide }) {
	const dispatch = useDispatch();

	const [loadingSub, setLoadingSub] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [alertModal, setAlertModal] = useState(null);

	const table = useRef(null);

	const state = useSelector<any, any>(c => c.product);

	const isHomeOffers = state?.homeOffers;

	const { user } = useSelector<any, any>(s => s.login);

	const isAdmin = isInRole(user, ["admin"]);

	const loadData = useCallback(() => {
		dispatch(
			getProducts(
				null,
				null,
				`&filters[businessId]=${business.id}&filters[status]=1`,
				null,
				null,
				null,
				null
			)
		);
		setIsLoaded(true);
	}, [dispatch, business.id]);

	const handleChangeClicks = useCallback(
		(id: string, value: any) => {
			dispatch(changeValueClicks({ [id]: value }));
		},
		[dispatch]
	);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(id, include, item) => {
			setLoadingSub({ [id]: item.id });
			dispatch(
				getProduct(item.id, include, (err, model) => {
					if (err) {
						console.log(err);
					} else {
						handleChange(id, Boolean(model));
					}
					setLoadingSub({ [id]: false });
				})
			);
		},
		[dispatch, handleChange]
	);

	const handleDeleteProduct = useCallback(
		(id: string) => {
			dispatch(
				deleteProduct(id, err => {
					if (err) {
						setAlertModal(null);

						console.log(err);

						alert(err.default);
					} else {
						setAlertModal(null);
					}
				})
			);
		},
		[dispatch]
	);

	const showConfirm = useCallback(
		itemId => {
			setAlertModal(
				<Dialog
					open
					onClose={() => setAlertModal(null)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Tem certeza que deseja confirmar a exclusão?
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Após a confirmação da exclusão não será possível recuperar os
							dados.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => handleDeleteProduct(itemId)}
							color="primary"
							autoFocus
						>
							Confirmar
						</Button>
						<Button onClick={() => setAlertModal(null)} color="primary">
							Cancelar
						</Button>
					</DialogActions>
				</Dialog>
			);
		},
		[handleDeleteProduct]
	);

	const handleFetchData = useCallback(
		(tableState, _instance) => {
			if (!isLoaded) {
				return;
			}

			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getProductsDebounced) {
				getProductsDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: creationDate.value.startDate
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: creationDate.value.endDate
					});
			}

			getProductsDebounced = _.debounce(
				() =>
					dispatch(
						getProducts(
							page * pageSize,
							pageSize,
							`&filters[businessId]=${
								business.id
							}&filters[status]=1${filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								""
							)}`,
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getProductsDebounced();
		},
		[business.id, dispatch, isLoaded]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state, null),
		[handleFetchData]
	);

	const iconShowItemsModal = useCallback(
		c => {
			if (c.digitalProvider === 1) {
				return loadingSub["showItemsModal"] === c.id ? (
					<Spinner color="secondary" size={16} />
				) : (
					<IconButton
						style={{
							padding: 5,
							fontSize: "1.3rem"
						}}
						onClick={() => handleEdit("showItemsModal", "items", c)}
					>
						<FiPackage />
					</IconButton>
				);
			}
			return null;
		},
		[handleEdit, loadingSub]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Id",
				id: "id",
				accessor: c => (c.id ? c.id : "Não informado"),
				filterable: false,
				width: 300
			},
			{
				Header: ls.name,
				id: "name",
				accessor: c => (c.name ? c.name : "Não informado")
			},
			{
				Header: ls.category,
				id: "category",
				accessor: c => (c.category ? c.category?.name : "Não informado"),
				width: 150
			},
			{
				Header: ls.price,
				id: "price",
				show: true,
				format: c => c.price / 100,
				accessor: c => toStringCurrency(c.price / 100),
				width: 120
			},
			{
				Header: ls.actions,
				id: "actions",
				filterable: false,
				accessor: c => (
					<Grid container spacing={2} justify="center">
						<Grid item>
							{loadingSub["showCompaniesFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 5,
										fontSize: "1.3rem"
									}}
									onClick={() =>
										handleEdit("showCompaniesFormModal", "companies", c)
									}
								>
									<MdBusiness />
								</IconButton>
							)}
							{loadingSub["showPhotosModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 5,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showPhotosModal", "photos", c)}
								>
									<MdPhoto />
								</IconButton>
							)}
							{iconShowItemsModal(c)}
							{loadingSub["showEditFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 5,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showEditFormModal", undefined, c)}
								>
									<MdEdit />
								</IconButton>
							)}
							{loadingSub["showPromotionsFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 5,
										fontSize: "1.3rem"
									}}
									onClick={() =>
										handleEdit("showPromotionsFormModal", "promotions", c)
									}
								>
									<MdLibraryAdd />
								</IconButton>
							)}
							<IconButton
								style={{
									padding: 5,
									fontSize: "1.3rem"
								}}
								onClick={() => showConfirm(c.id)}
							>
								<MdDelete />
							</IconButton>
							{loadingSub["showPromotionsFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								c.homeOffers &&
								isAdmin && (
									<IconButton
										style={{
											padding: 5,
											fontSize: "1.3rem"
										}}
										onClick={() => {
											handleChangeClicks(
												"eventName",
												`click-banner-open-link-${c.id}`
											);
											handleChange("showModalReportClicks", true);
										}}
									>
										<AiOutlineFileExcel />
									</IconButton>
								)
							)}
						</Grid>
					</Grid>
				),
				width: 200
			}
		],
		[
			handleChange,
			handleChangeClicks,
			handleEdit,
			iconShowItemsModal,
			isHomeOffers,
			loadingSub,
			showConfirm
		]
	);

	if (hide) return null;

	return (
		<LoadOnScroll load={loadData}>
			<Report
				manual
				tableRef={table}
				title={ls.products}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				headerRightComponent={
					<div>
						<a
							href="https://docs.google.com/spreadsheets/d/1h8o55J7Klg0ov-MQTMuAOIU-cyTIOz2TOjta90Ld8AI/edit?usp=sharing"
							target="_blank"
							rel="noopener noreferrer"
							style={{ marginRight: 16 }}
						>
							Baixar modelo de importação
						</a>
						<IconButton
							onClick={() => handleChange("showImportFormModal", true)}
						>
							<FaFileImport />
						</IconButton>
						<IconButton
							color="primary"
							onClick={() => handleChange("showFormModal", true)}
						>
							<FaPlus />
						</IconButton>
					</div>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			{alertModal}
			<ModalBanners />
			<ModalClicks />
			<ProductPromotion />
			<CreateForm load={load} />
			<EditForm />
			<CompaniesForm />
			<PhotoModal />
			<ItemsModal />
			<ImportFormModal load={load} />
			<ImportProducts />
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(Products);
