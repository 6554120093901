import styled from "styled-components";

export const Container = styled.div`
	padding: 32px;
`;

export const Title = styled.span`
	font: normal normal 600 19px Exo;
	letter-spacing: 0px;
	color: #484848;
	opacity: 1;
	margin-bottom: 10px;
`;

export const TextQuantity = styled.p`
	text-align: left;
	font: normal normal 600 19px Exo;
	letter-spacing: 0px;
	color: #484848;
	opacity: 1;
	margin: 10px 0;
`;

export const TextAdress = styled.p`
	span {
		font: normal normal 600 19px/25px Exo;
		letter-spacing: 0px;
		color: #484848;
	}
	font: normal normal normal 19px/25px Exo;
	letter-spacing: 0px;
	color: #484848;
	margin: 5px 0;
`;

export const TextDestiny = styled.p`
	span {
		font: normal normal 600 19px/25px Exo;
		letter-spacing: 0px;
		color: #484848;
	}
	font: normal normal normal 19px/25px Exo;
	letter-spacing: 0px;
	color: #484848;
`;

export const RowGray = styled.div`
	height: 8px;
	background: #dcdcdc;
`;
