import { modalAlert } from "Components/Modals/ModalAlert";
import { getErrors } from "helpers/error";
import { toastError } from "helpers/toast";
import ls from "Localization";
import * as api from "services/api";

import { ActionPayload, ActionTypes, ITreasuryAccountTransfer } from "../types";

export interface ITreasuryAccountTransferRequest {
	skip?: number;
	take?: number;
	filter?: any;
	sort?: string;
	desc?: boolean;
	toExport?: boolean;
	callback?: (err?: string, model?: any) => void;
}

interface IState {
	treasuryAccountTransactionCreate: ITreasuryAccountTransfer;
	[key: string]: any;
}

type Dispatch = (dispatch: any) => any;

type State = () => IState;

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: ActionTypes.SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: ActionTypes.CLEAR_VALUES };
}

export const getTransfers =
	({
		skip,
		take,
		filter,
		sort = "creationDate",
		desc = true,
		toExport,
		callback
	}: ITreasuryAccountTransferRequest) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_REQUEST });

		const queryString = new URLSearchParams({
			skip: String(skip),
			take: String(take),
			...filter,
			sort,
			desc: desc === true ? "true" : "false"
		}).toString();

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/TreasuryAccount/Transfer?${queryString}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);

				if (toExport) {
					return dispatch(setValue({ loading: false }));
				}

				if (result.params) {
					const { count = 0 } = result.params || { count: 0 };
					dispatch(
						setValue({
							pages: Math.ceil(count / take),
							...result.params
						})
					);
				}

				return dispatch({
					type: ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_SUCCESS,
					payload: { items: result.model }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};

export const getTransfersAll =
	({ filter, callback }: ITreasuryAccountTransferRequest) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_REQUEST });

		try {
			const { login } = getState();

			const queryString = new URLSearchParams(filter).toString();

			const response = await api.sendGet(
				`/Business/TreasuryAccount/Transfer/Export?${queryString}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.blob();

			if (response.status === 200) {
				const outputFilename = `${Date.now()}.xls`;

				const url = URL.createObjectURL(new Blob([result]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", outputFilename);
				document.body.appendChild(link);
				link.click();

				callback && callback();
				return dispatch({
					type: ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_SUCCESS,
					payload: { items: null }
				});
			}

			callback && callback(null);
			throw new Error(getErrors(null));
		} catch (error) {
			toastError(String(error));
			return dispatch({
				type: ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};
