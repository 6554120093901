import { getErrors } from "helpers/error";
import * as api from "services/api";

export const GET_CONFIGURATION = "[CONFIGURATION DETAIL] GET_CONFIGURATION";
export const GET_CONFIGURATION_SUCCESS =
	"[CONFIGURATION DETAIL] GET_CONFIGURATION_SUCCESS";
export const GET_CONFIGURATION_FAILED =
	"[CONFIGURATION DETAIL] GET_CONFIGURATION_FAILED";

export const SET_VALUE = "[CONFIGURATION DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CONFIGURATION DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getConfiguration(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONFIGURATION });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Configuration/", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONFIGURATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CONFIGURATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_CONFIGURATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
