import { getErrors } from "helpers/error";
import _ from "lodash";
import * as api from "services/api";

export const GET_MODERATION = "[MODERATION DETAIL] GET_MODERATION";
export const GET_MODERATION_SUCCESS =
	"[MODERATION DETAIL] GET_MODERATION_SUCCESS";
export const GET_MODERATION_FAILED =
	"[MODERATION DETAIL] GET_MODERATION_FAILED";

export const MODERATION_DENIED = "[MODERATION DENIED] MODERATION_DENIED";
export const MODERATION_DENIED_SUCCESS =
	"[MODERATION DENIED] MODERATION_DENIED_SUCCESS";
export const MODERATION_DENIED_FAILED =
	"[MODERATION DENIED] MODERATION_DENIED_FAILED";

export const MODERATION_APPROVE = "[MODERATION APPROVE] MODERATION_APPROVE";
export const MODERATION_APPROVE_SUCCESS =
	"[MODERATION APPROVE] MODERATION_APPROVE_SUCCESS";
export const MODERATION_APPROVE_FAILED =
	"[MODERATION APPROVE] MODERATION_APPROVE_FAILED";

export const SET_VALUE = "[MODERATION DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[MODERATION DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getModeration(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_MODERATION });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/Moderation/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_MODERATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_MODERATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_MODERATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function moderationDenied(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: MODERATION_DENIED });

		try {
			const { login } = getState();
			let response = await api.sendPut(`/Moderation/${id}/Denied`, null, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: MODERATION_DENIED_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: MODERATION_DENIED_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: MODERATION_DENIED_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function moderationApproved(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: MODERATION_APPROVE });

		try {
			const { login, moderationDetail } = getState();
			const errors: any = {};

			const { amount, item } = moderationDetail;

			const model = {
				amount: Math.round(amount * 100)
			};

			if (model.amount > item?.sale?.value)
				errors.amount =
					"O valor subsidiado não pode ser maior que o valor da compra";

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: MODERATION_APPROVE_FAILED,
					payload: errors
				});
			}

			let response = await api.sendPut(
				`/Moderation/${item.id}/Approve/${model.amount}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.clone().json();

			if (result.isValid) {
				dispatch({
					type: MODERATION_APPROVE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);
				dispatch({
					type: MODERATION_APPROVE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: MODERATION_APPROVE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
