import * as api from "services/api";
import { getErrors } from "helpers/error";

export const EDIT_CONFIGURATION = "[CONFIGURATION EDIT] EDIT_CONFIGURATION";
export const EDIT_CONFIGURATION_SUCCESS =
	"[CONFIGURATION EDIT] EDIT_CONFIGURATION_SUCCESS";
export const EDIT_CONFIGURATION_FAILED =
	"[CONFIGURATION EDIT] EDIT_CONFIGURATION_FAILED";

export const SET_VALUE = "[CONFIGURATION EDIT] SET_VALUE";
export const CLEAR_VALUES = "[CONFIGURATION EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function editConfiguration(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_CONFIGURATION });

		try {
			const { login, configurationEdit } = getState();

			const {
				item,
				newConsumptionTemplate,
				consumptionReportTemplate,
				checkoutTemplate
			} = configurationEdit;

			const model = {
				...item,
				consumptionReportTemplate,
				newConsumptionTemplate,
				checkoutTemplate,
				business: undefined
			};

			const response = await api.sendPut("/Configuration/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_CONFIGURATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_CONFIGURATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_CONFIGURATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
