import styled from "styled-components";

const styles = theme => ({
	root: {
		padding: theme.spacing(2)
	},
	form: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	alert: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		backgroundColor: "#cdeefd"
	},
	alertIcon: {
		marginRight: theme.spacing(2),
		color: "#02587f"
	},
	alertText: {
		color: "#02587f"
	},
	line: {
		marginLeft: -16,
		marginRight: -16
	},
	button: {
		margin: theme.spacing()
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	}
});

export const BackgroundButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font: normal normal 700 27px Exo;
	padding: 8px;
	cursor: pointer;
`;

export const ButtonSync = styled.button`
	background: #fe9d2b 0% 0% no-repeat padding-box;
	border-radius: 8px;
	width: 161px;
	opacity: 1;
	border: none;
	padding: 10px 20px;
	cursor: pointer;
`;

export const TextButton = styled.p`
	text-align: left;
	font: normal normal 600 15px/20px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	margin: 0;
	padding: 0;
`;

export const TypeSyncHCM = styled.div`
	background: #fe9d2b 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;
	padding: 5px 15px;
	font: normal bold medium 14px/19px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	margin-left: 5px; ;
`;

export default styles;
