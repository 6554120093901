import styled from "styled-components";

export const Container = styled.div`
	padding: 20px;
`;

export const Title = styled.h3`
	text-align: left;
	font: normal normal bold 25px/33px Exo;
	letter-spacing: 0px;
	color: #5734da;
	opacity: 1;
	padding: 0;
	margin: 0;
	margin-bottom: 10px;
`;
