import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_DELIVERY_ADDRESSES =
	"[DELIVERY_ADDRESS] GET_DELIVERY_ADDRESSES";
export const GET_DELIVERY_ADDRESSES_SUCCESS =
	"[DELIVERY_ADDRESS] GET_DELIVERY_ADDRESSES_SUCCESS";
export const GET_DELIVERY_ADDRESSES_FAILED =
	"[DELIVERY_ADDRESS] GET_DELIVERY_ADDRESSES_FAILED";

export const GET_DELIVERY_ADDRESS = "[DELIVERY_ADDRESS] GET_DELIVERY_ADDRESS";
export const GET_DELIVERY_ADDRESS_SUCCESS =
	"[DELIVERY_ADDRESS] GET_DELIVERY_ADDRESS_SUCCESS";
export const GET_DELIVERY_ADDRESS_FAILED =
	"[DELIVERY_ADDRESS] GET_DELIVERY_ADDRESS_FAILED";

export const CREATE_DELIVERY_ADDRESS =
	"[DELIVERY_ADDRESS] CREATE_DELIVERY_ADDRESS";
export const CREATE_DELIVERY_ADDRESS_SUCCESS =
	"[DELIVERY_ADDRESS] CREATE_DELIVERY_ADDRESS_SUCCESS";
export const CREATE_DELIVERY_ADDRESS_FAILED =
	"[DELIVERY_ADDRESS] CREATE_DELIVERY_ADDRESS_FAILED";

export const UPDATE_DELIVERY_ADDRESS =
	"[DELIVERY_ADDRESS] UPDATE_DELIVERY_ADDRESS";
export const UPDATE_DELIVERY_ADDRESS_SUCCESS =
	"[DELIVERY_ADDRESS] UPDATE_DELIVERY_ADDRESS_SUCCESS";
export const UPDATE_DELIVERY_ADDRESS_FAILED =
	"[DELIVERY_ADDRESS] UPDATE_DELIVERY_ADDRESS_FAILED";

export const DELETE_DELIVERY_ADDRESS =
	"[DELIVERY_ADDRESS] DELETE_DELIVERY_ADDRESS";
export const DELETE_DELIVERY_ADDRESS_SUCCESS =
	"[DELIVERY_ADDRESS] DELETE_DELIVERY_ADDRESS_SUCCESS";
export const DELETE_DELIVERY_ADDRESS_FAILED =
	"[DELIVERY_ADDRESS] DELETE_DELIVERY_ADDRESS_FAILED";

export const SET_VALUE = "[DELIVERY_ADDRESS] SET_VALUE";
export const CLEAR_VALUES = "[DELIVERY_ADDRESS] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getDeliveryAddresses(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_DELIVERY_ADDRESSES });

		try {
			const { login, businessDetail } = getState();

			const { id } = businessDetail.item;

			const response = await api.sendGet(
				`/DeliveryAddress/Business/${id}?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_DELIVERY_ADDRESSES_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_DELIVERY_ADDRESSES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_DELIVERY_ADDRESSES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getDeliveryAddress(id, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_DELIVERY_ADDRESS });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/DeliveryAddress/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_DELIVERY_ADDRESS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_DELIVERY_ADDRESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_DELIVERY_ADDRESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createDeliveryAddress(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_DELIVERY_ADDRESS });

		try {
			const { login, deliveryAddress } = getState();

			const {
				name,
				value,
				minPrice,
				freeShipping,
				time,
				citiesList,
				neighborhoodsList
			} = deliveryAddress;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!value && value !== 0) errors.value = ls.required;
			if (!time && time !== 0) errors.time = ls.required;
			else if (time <= 0) errors.time = "Mínimo 1 minuto";

			if (!citiesList.length && !neighborhoodsList.length)
				errors.addresses = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_DELIVERY_ADDRESS_FAILED,
					payload: errors
				});
			}

			const model = {
				name,
				value: Math.round(value * 100),
				minPrice: Math.round(minPrice * 100),
				freeShipping,
				time,
				cities: citiesList.map(c => ({ cityId: c.id })),
				neighborhoods: neighborhoodsList.map(c => ({ neighborhoodId: c.id })),
				storeId: id
			};

			const response = await api.sendPost("/DeliveryAddress", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_DELIVERY_ADDRESS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_DELIVERY_ADDRESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_DELIVERY_ADDRESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editDeliveryAddress(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_DELIVERY_ADDRESS });

		try {
			const { login, deliveryAddress } = getState();

			const {
				item,
				name,
				value,
				minPrice,
				freeShipping,
				time,
				citiesList,
				neighborhoodsList
			} = deliveryAddress;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!value && value !== 0) errors.value = ls.required;
			if (!time && time !== 0) errors.time = ls.required;
			else if (time <= 0) errors.time = "Mínimo 1 minuto";

			if (!citiesList.length && !neighborhoodsList.length)
				errors.addresses = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_DELIVERY_ADDRESS_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				name,
				minPrice: Math.round(minPrice * 100),
				value: Math.round(value * 100),
				freeShipping,
				time,
				cities: citiesList.map(c => ({ ...c, cityId: c.id })),
				neighborhoods: neighborhoodsList.map(c => ({
					...c,
					neighborhoodId: c.id
				}))
			};

			const response = await api.sendPut("/DeliveryAddress/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_DELIVERY_ADDRESS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_DELIVERY_ADDRESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_DELIVERY_ADDRESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteDeliveryAddress(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_DELIVERY_ADDRESS });

		try {
			const { login } = getState();

			const response = await api.sendDelete("/DeliveryAddress/" + id, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: DELETE_DELIVERY_ADDRESS_SUCCESS
				});

				callback && callback(null);
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_DELIVERY_ADDRESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: DELETE_DELIVERY_ADDRESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
