export enum ActionTypes {
	GET_BALANCE_ACCOUNT_TYPES_REQUEST = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPES_REQUEST",
	GET_BALANCE_ACCOUNT_TYPES_SUCCESS = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPES_SUCCESS",
	GET_BALANCE_ACCOUNT_TYPES_FAILED = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPES_FAILED",
	UPLOAD_BALANCE_ACCOUNT_TYPES_REQUEST = "[TREASURY ACCOUNT] UPLOAD_BALANCE_ACCOUNT_TYPES_REQUEST",
	UPLOAD_BALANCE_ACCOUNT_TYPES_SUCCESS = "[TREASURY ACCOUNT] UPLOAD_BALANCE_ACCOUNT_TYPES_SUCCESS",
	UPLOAD_BALANCE_ACCOUNT_TYPES_FAILED = "[TREASURY ACCOUNT] UPLOAD_BALANCE_ACCOUNT_TYPES_ERROR",
	GET_BALANCE_ACCOUNT_TYPE_REQUEST = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPE_REQUEST",
	GET_BALANCE_ACCOUNT_TYPE_SUCCESS = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPE_SUCCESS",
	GET_BALANCE_ACCOUNT_TYPE_FAILED = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPE_FAILED",
	GET_BUSINESS_TINY_REQUEST = "[TREASURY ACCOUNT] GET_BUSINESS_TINY_REQUEST",
	GET_BUSINESS_TINY_SUCCESS = "[TREASURY ACCOUNT] GET_BUSINESS_TINY_SUCCESS",
	GET_BUSINESS_TINY_FAILED = "[TREASURY ACCOUNT] GET_BUSINESS_TINY_FAILED",
	UPDATE_BUSINESS_REQUEST = "[TREASURY ACCOUNT] UPDATE_BUSINESS_REQUEST",
	UPDATE_BUSINESS_SUCCESS = "[TREASURY ACCOUNT] UPDATE_BUSINESS_SUCCESS",
	UPDATE_BUSINESS_FAILED = "[TREASURY ACCOUNT] UPDATE_BUSINESS_FAILED",
	UPDATE_BUSINESS_PERMISSION_REQUEST = "[TREASURY ACCOUNT] UPDATE_BUSINESS_PERMISSION_REQUES",
	UPDATE_BUSINESS_PERMISSION_SUCCESS = "[TREASURY ACCOUNT] UPDATE_BUSINESS_PERMISSION_SUCCESS",
	UPDATE_BUSINESS_PERMISSION_FAILED = "[TREASURY ACCOUNT] UPDATE_BUSINESS_PERMISSION_FAILED",
	SET_VALUE = "[TREASURY ACCOUNT] SET_VALUE",
	CLEAR_VALUES = "[TREASURY ACCOUNT] CLEAR_VALUES",
	GET_BALANCE_ACCOUNT_TYPES_BUSINESS_REQUEST = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPES_BUSINESS_REQUEST",
	GET_BALANCE_ACCOUNT_TYPES_BUSINESS_SUCCESS = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPES_BUSINESS_SUCCESS",
	GET_BALANCE_ACCOUNT_TYPES_BUSINESS_FAILED = "[TREASURY ACCOUNT] GET_BALANCE_ACCOUNT_TYPES_BUSINESS_FAILED"
}

interface ICompaniesTiny {
	displayName: string;
	id: string;
	name: string;
	socialName: string;
	status: number;
}

interface ICompanies {
	business: ICompaniesTiny;
	businessId: string;
	creationDate: Date;
	prePaidBalanceAccountTypeId?: string;
	id: string;
	status: number;
}

export interface IBalanceAccountType {
	id: string;
	createdById: string;
	externalId: string;
	name: string;
	description: string;
	banner?: string;
	icon?: string;
	businesses?: ICompanies[];
	businessTransferPolicies?: ICompanies[];
}

export interface IBalanceAccountState {
	item: IBalanceAccountType;
	types: IBalanceAccountType[];
	pages: number;
	loading: {
		getAll?: boolean;
		get?: boolean;
		updateBusinesses?: boolean;
		tiny?: boolean;
		updateBusinessesPermission?: boolean;
	};
	showBusinessesFormModal: boolean;
	showBusinessesTransferFormModal: boolean;
	businesses: ICompanies[];
	businessTransferPolicies: ICompanies[];
	businessesTiny: ICompaniesTiny[];
}

export interface ActionPayload {
	type: string;
	payload?: {
		item?: IBalanceAccountType;
		types?: IBalanceAccountType[];
		pages?: number;
		businessesTiny?: ICompaniesTiny[];
	};
}
