import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { toStringCurrency } from "helpers/string";
import { toastError, toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import saleStatus, { SaleStatus } from "models/saleStatus";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editTransaction,
	setValue
} from "store/actions/transaction/edit";
import { getUserLimit } from "store/actions/user/detail";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceEditTransaction: (() => void) & _.Cancelable = null;
const WIIPO_CLUB_RESCUE_IDS = [
	"91864012-f5c6-4158-63fc-08da6ff39fbf",
	"d1b3443f-1ff7-4da7-30d0-08da6b6a5b89",
	"0d33a698-3acb-4d95-5cda-08da6fcea721",
	"d069b37e-6bec-451b-5cd9-08da6fcea721"
];
function EditTransaction({ open, item, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (open && item) {
			dispatch(clearValues());

			dispatch(
				setValue({
					item,
					creationDate: item.creationDate,
					code: item.code,
					store: item.store,
					company: item.company,
					fee: item.fee / 100,
					value: item.value / 100,
					discount: item.discount / 100,
					client: item.client,
					clientId: item.clientId,
					type: item.type,
					installmentAmount: item.installmentAmount,
					status: item.status,
					description: item.description
				})
			);
		}
	}, [dispatch, open, item]);

	const {
		code,
		creationDate,
		clientId,
		value,
		discount,
		installmentAmount,
		status,
		description,
		loading,
		errors
	} = useSelector<any, any>(s => s.transactionEdit);

	const { limit } = useSelector<any, any>(s => s.userDetail);

	useEffect(() => {
		if (clientId && open) {
			dispatch(getUserLimit(clientId, null, creationDate));
		}
	}, [dispatch, creationDate, clientId, open]);

	const handleChange = (name, value) => {
		if (
			name === "status" &&
			[SaleStatus.Canceled, SaleStatus.Refunded, SaleStatus.Refused].includes(
				status
			) &&
			WIIPO_CLUB_RESCUE_IDS.includes(item.store.id)
		) {
			toastWarning("Você não pode realizar esta ação!");
			return;
		}
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditTransaction) debounceEditTransaction.cancel();

		debounceEditTransaction = _.debounce(() => {
			dispatch(
				editTransaction(err => {
					if (err) {
						if (err.addErrors) {
							toastError(err.addErrors);
							return;
						}
						if (err.default) toastError(err.default);
						else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
					} else {
						handleClose();
					}
				})
			);
		}, 1000);

		debounceEditTransaction();
	};

	return (
		<Modal
			aria-labelledby="edit-transaction-modal-title"
			aria-describedby="edit-transaction-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{`${ls.editTransaction} ${code}`}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<DateTimePicker
										value={creationDate}
										fullWidth
										label={ls.creationDate}
										onChange={date => handleChange("creationDate", date)}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="value"
										required
										variant="outlined"
										name="value"
										value={value}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputComponent: MONEY
										}}
										helperText={
											clientId
												? `Limite: ${toStringCurrency(
														limit / 100 - (value - discount)
												  )}`
												: undefined
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="discount"
										required
										variant="outlined"
										name="discount"
										value={discount}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputComponent: MONEY
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="installmentAmount"
										required
										variant="outlined"
										name="installments"
										value={installmentAmount}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputProps: {
												step: 1,
												min: 1,
												max: 12
											}
										}}
										type="number"
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											...Object.keys(saleStatus).map(c => ({
												id: c,
												value: ls[saleStatus[c]]
											}))
										]}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextInput
										id="description"
										name="description"
										value={description}
										errors={errors}
										multiline
										rows={3}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.edit}
								>
									{ls.save}
									{loading.edit && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditTransaction;
