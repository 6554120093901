import DatePicker from "react-datepicker";
import styled from "styled-components";

interface ButtonProps {
	isDisable: boolean;
}

interface PropsContainer {
	isLarge?: boolean;
}

export const DatePickerContainer = styled(DatePicker)<PropsContainer>`
	width: 182px;
	height: ${props => (props.isLarge ? "55px" : "40px")};
	padding: 10px;
	font-size: 14px;
`;

export const HeaderCalendar = styled.div``;

export const StyledHeader = styled.div`
	.react-datepicker-popper {
		z-index: 5;
	}

	.react-datepicker__header {
		background-color: #5734d9;
		color: #fff;
		border-radius: 0px;
		padding: 0px;
		font-weight: bold;
	}

	.react-datepicker__day-name {
		color: #fff;
		margin-bottom: 10px;
		font-weight: bold;
	}

	.react-datepicker-time__header {
		padding-top: 10px;
		padding-bottom: 10px;
		color: #fff;
	}

	.react-datepicker__time-list-item--disabled {
		display: none;
	}
`;

export const TextErrorDate = styled.p`
	margin: 0;
	padding: 0;
	color: #ff0000;
	font-weight: bold;
	font-size: 14px;
	text-align: center;
	margin-bottom: 10px;
`;

export const RowButtons = styled.div<ButtonProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
`;

export const ButtonCancel = styled.button`
	border: none;
	background: none;
	text-transform: uppercase;
	height: 20px;
	width: 50px;
	font-weight: bold;
`;

export const TextButton = styled.div`
	color: #5734d9;
`;

export const ButtonOk = styled.button`
	border: none;
	background: none;
	text-transform: uppercase;
	height: 20px;
	width: 50px;
	font-weight: bold;
`;

export const TextButtonOk = styled.div<ButtonProps>`
	color: ${props => (!props.isDisable ? "#5734d9" : "#ffffff")};
`;

export const IconTrash = styled.div`
	position: relative;
	right: 35px;
`;
