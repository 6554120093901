import { Button, Grid, Grow, Paper, Popper } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import dayjs from "dayjs";
import ls from "Localization";
import React, { useRef, useState } from "react";

function DateSelectFilter({ onChange, filter, column, ...props }) {
	const [open, setOpen] = useState(false);

	let startDateF = filter?.value?.startDate;

	if (typeof startDateF === "string") {
		startDateF = dayjs(startDateF);
	}

	let endDateF = filter?.value?.endDate;

	if (typeof endDateF === "string") {
		endDateF = dayjs(endDateF);
	}

	const [startDate, setStartDate] = useState<dayjs.Dayjs>(startDateF || null);
	const [endDate, setEndDate] = useState<dayjs.Dayjs>(endDateF || null);
	const anchorRef = useRef(null);
	const handleToggle = () => setOpen(p => !p);
	const handleClose = () => setOpen(false);

	const handleSubmit = () => {
		let startDateStr = startDate?.format();
		let endDateStr = endDate?.format();

		onChange({
			startDate: startDateStr,
			endDate: endDateStr
		});
		handleClose();
	};

	const handleClear = () => {
		setStartDate(null);
		setEndDate(null);
	};

	const renderTitle = () => {
		if (!startDate && !endDate) return "Selecionar";
		if (startDate && !endDate)
			return `De: ${startDate.format(ls.dateFormatShort)}`;

		if (!startDate && endDate)
			return `Até: ${endDate.format(ls.dateFormatShort)}`;

		return `${startDate.format(ls.dateFormatShort)} - ${endDate.format(
			ls.dateFormatShort
		)}`;
	};

	return (
		<div>
			<Button
				ref={anchorRef}
				aria-controls={open ? "menu-list-grow" : undefined}
				aria-haspopup="true"
				fullWidth
				onClick={handleToggle}
			>
				{renderTitle()}
			</Button>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				placement="bottom-start"
				transition
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom"
						}}
					>
						<Paper style={{ padding: 16, maxWidth: 150 }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<DatePicker
										disableFuture={!column.ignoreMaxDate}
										format={ls.dateFormatShort}
										label="De:"
										maxDate={endDate || undefined}
										value={startDate}
										onChange={setStartDate}
										inputVariant="outlined"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										disableFuture={!column.ignoreMaxDate}
										format={ls.dateFormatShort}
										label="Até:"
										minDate={startDate || undefined}
										value={endDate}
										onChange={setEndDate}
										inputVariant="outlined"
										fullWidth
									/>
								</Grid>

								<Grid item xs={12}>
									<Button
										fullWidth
										variant="contained"
										color="primary"
										onClick={handleSubmit}
									>
										Aplicar
									</Button>
								</Grid>
								<Grid item xs={12}>
									<Button
										fullWidth
										variant="contained"
										color="secondary"
										onClick={handleClear}
									>
										Limpar
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
}

export default DateSelectFilter;
