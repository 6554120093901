import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
	fab: {
		margin: theme.spacing(),
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	},
	headerRightContainer: {
		height: "100%"
	},
	textAlignCenter: {
		width: "100%",
		textAlign: "center"
	}
}));

export default useStyles;
