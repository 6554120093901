import { Grid } from "@material-ui/core";
import styled from "styled-components";

interface IPropsLabel {
	bold?: boolean;
}

export const BodyModal = styled.div`
	padding: 18px;
`;

export const TitleText = styled.div`
	color: #000000;
	font: normal normal 600 22px Exo;
	width: 100%;
	margin-bottom: 15px;
`;
export const Label = styled.div<IPropsLabel>`
	width: ${props => (props.bold ? "100%" : "70%")};
	color: #707070;
	font-family: Exo;
	font-size: ${props => (props.bold ? "18px" : "16px")};
	font-weight: ${props => (props.bold ? "bold" : "normal")};
	margin-left: ${props => (props.bold ? "0px" : "5px")};
	margin-top: ${props => (props.bold ? "0px" : "2px")};
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const RowTitle = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

export const LabelTitle = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-around;
	font: normal normal 600 20px Exo;
`;

export const GridsBody = styled(Grid)`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;

	@media (max-width: 1200px) {
		flex-direction: column;
		width: 100%;
	}
`;

export const GridRow = styled(Grid)`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 15px;

	@media (max-width: 1200px) {
		flex-direction: column;
		width: 100%;
	}
`;

export const ButtonAdd = styled.button`
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #5734d9;
	border: none;
	background: none;
	text-decoration: underline;
	font: normal normal normal 16px Exo;
`;

export const BackgroundButton = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 20px;
	font: normal normal 600 18px Exo;
	padding: 6px;
`;
