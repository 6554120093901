import ModalSelector from "Components/ModalSelector";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editProductCompanies,
	getCompaniesTiny,
	setValue
} from "store/actions/product";

let debounceCompaniesForm: (() => void) & _.Cancelable = null;

function CompaniesForm() {
	const dispatch = useDispatch();

	const { showCompaniesFormModal, companies, companiesTiny, loading } =
		useSelector<any, any>(c => c.product);

	useEffect(() => {
		if (showCompaniesFormModal) dispatch(getCompaniesTiny());
	}, [dispatch, showCompaniesFormModal]);

	const handleClose = () =>
		dispatch(setValue({ showCompaniesFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ companyId: u.id, status: u.status }));

		if (debounceCompaniesForm) debounceCompaniesForm.cancel();

		debounceCompaniesForm = _.debounce(() => {
			dispatch(
				editProductCompanies(data, (err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceCompaniesForm();
	};

	if (!showCompaniesFormModal) return null;

	return (
		<ModalSelector
			open={showCompaniesFormModal}
			id="store-form-modal"
			loading={loading}
			data={companiesTiny.map(c => ({
				id: c.id,
				name: c.name,
				status: companies.find(d => d.companyId === c.id && d.status) ? 1 : 0
			}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="companies"
		/>
	);
}

export default CompaniesForm;
