import {
	Button,
	ClickAwayListener,
	Grid,
	Grow,
	IconButton,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Report from "Components/Report";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import marketingStatus from "models/marketingStatus";
import marketingUserStatus from "models/marketingUserStatus";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { MdMoreVert } from "react-icons/md";
import { connect } from "react-redux";
import * as actions from "store/actions/marketing";

import styles from "./styles";

let getMarketingUsersDebounced: (() => void) & _.Cancelable = null;

export function MarketingDetail({
	classes,
	state: { item, users, pages, loading, ...state },
	getMarketing,
	getMarketingUsers,
	resendMarketing,
	clearValues,
	match: {
		params: { id }
	},
	history
}) {
	const [openMenu, setOpenMenu] = useState(false);

	const menuRef = useRef(null);
	const table = useRef(null);

	const marketingUsersColumns = useMemo(
		() => [
			{
				Header: ls.name,
				id: "name",
				accessor: c => c.user.fullName
			},
			{
				Header: "E-mail",
				id: "email",
				accessor: c => ls[marketingUserStatus[c.emailStatus]],
				Footer: () => (
					<b>
						{state.emailPending}/{state.emailDone}
					</b>
				)
			},
			{
				Header: "SMS",
				id: "sms",
				accessor: c => ls[marketingUserStatus[c.smsStatus]],
				Footer: () => (
					<b>
						{state.smsPending}/{state.smsDone}
					</b>
				)
			},
			{
				Header: "WhatsApp",
				id: "whatsApp",
				accessor: c => ls[marketingUserStatus[c.whatsAppStatus]],
				Footer: () => (
					<b>
						{state.whatsAppPending}/{state.whatsAppDone}
					</b>
				)
			},
			{
				Header: "Push",
				id: "push",
				accessor: c => ls[marketingUserStatus[c.pushStatus]],
				Footer: () => (
					<b>
						{state.pushPending}/{state.pushDone}
					</b>
				)
			}
		],
		[
			state.emailDone,
			state.emailPending,
			state.pushDone,
			state.pushPending,
			state.smsDone,
			state.smsPending,
			state.whatsAppDone,
			state.whatsAppPending
		]
	);

	useEffect(() => {
		clearValues();
		getMarketing(id);
	}, [clearValues, getMarketing, id]);

	const itemId = item?.id;

	const handleFetchData = useCallback(
		(tableState, instance) => {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getMarketingUsersDebounced) {
				getMarketingUsersDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");
				let date = null;

				if (creationDate?.value.startDate || creationDate?.value.endDate) {
					date = {
						startAt: creationDate.value.startDate,
						endAt: creationDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "creationDate",
						value: JSON.stringify(date)
					});
				}
			}

			getMarketingUsersDebounced = _.debounce(
				() =>
					getMarketingUsers(
						itemId,
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					),
				500
			);

			getMarketingUsersDebounced();
		},
		[getMarketingUsers, itemId]
	);

	const handleMenuClose = event => {
		if (menuRef.current && menuRef.current.contains(event.target)) {
			return;
		}

		setOpenMenu(false);
	};

	function handleMenuListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenMenu(false);
		}
	}

	const handleResend = () => {
		setOpenMenu(false);
		resendMarketing(id, err => {
			if (err) {
				console.log(err);
				if (err.default) alert(err.default);
				else if (typeof err === "string") alert(err);
				else alert(JSON.stringify(err));
			} else {
				alert("Campanha reenviada");
			}
		});
	};

	if (!item) return null;

	let { creationDate, name, status, startAt } = item;

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.userData}</Typography>

						<div>
							<Button
								color="primary"
								onClick={() => history.push(`/Marketing/${id}/Edit`)}
							>
								{ls.edit}
							</Button>

							<IconButton
								ref={menuRef}
								aria-controls={openMenu ? "menu-list-grow" : undefined}
								aria-haspopup="true"
								onClick={() => setOpenMenu(p => !p)}
							>
								<MdMoreVert />
							</IconButton>
							<Popper
								open={openMenu}
								anchorEl={menuRef.current}
								role={undefined}
								transition
								disablePortal
								placement="bottom-end"
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin:
												placement === "bottom" ? "right top" : "right bottom"
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={handleMenuClose}>
												<MenuList
													autoFocusItem={openMenu}
													id="menu-list-grow"
													onKeyDown={handleMenuListKeyDown}
												>
													<MenuItem onClick={handleResend}>Reenviar</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<strong>{ls.creationDate}</strong>:{" "}
							{dayjs(creationDate).format(ls.dateFormatWithoutSeconds)}
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<strong>{ls.name}</strong>: {name}
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={4}>
							<strong>{ls.startAt}</strong>:{" "}
							{dayjs(startAt).format(ls.dateFormatWithoutSeconds)}
						</Grid>

						<Grid item xs={12} sm={6} md={4}>
							<br />
							<strong>{ls.status}</strong>: {ls[marketingStatus[status]]}
						</Grid>
					</Grid>
				</Paper>
			</Grid>

			<Grid item xs={11}>
				<Report
					manual
					title={ls.users}
					data={users}
					tableRef={table}
					pages={pages}
					loading={loading.getUsers}
					onFetchData={handleFetchData}
					filterable
					showExport
					defaultFilterMethod={(filter, row) =>
						String(row[filter.id])
							.toLowerCase()
							.indexOf(filter.value.toLowerCase()) > -1
					}
					columns={marketingUsersColumns}
					defaultSorted={[
						{
							id: "name",
							desc: true
						}
					]}
				/>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = ({ marketing: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = {
	...actions
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(MarketingDetail));
