import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_INSTALLMENT = "[INSTALLMENT EDIT] GET_INSTALLMENT";
export const GET_INSTALLMENT_SUCCESS =
	"[INSTALLMENT EDIT] GET_INSTALLMENT_SUCCESS";
export const GET_INSTALLMENT_FAILED =
	"[INSTALLMENT EDIT] GET_INSTALLMENT_FAILED";

export const SET_VALUE = "[INSTALLMENT EDIT] SET_VALUE";
export const CLEAR_VALUES = "[INSTALLMENT EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getInstallment(id, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_INSTALLMENT });

		try {
			const { login } = getState();

			let response = await api.sendGet(`/Installment/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_INSTALLMENT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_INSTALLMENT_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_INSTALLMENT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const EDIT_INSTALLMENT = "[INSTALLMENT EDIT] EDIT_INSTALLMENT";
export const EDIT_INSTALLMENT_SUCCESS =
	"[INSTALLMENT EDIT] EDIT_INSTALLMENT_SUCCESS";
export const EDIT_INSTALLMENT_FAILED =
	"[INSTALLMENT EDIT] EDIT_INSTALLMENT_FAILED";

export function editInstallment(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_INSTALLMENT });

		try {
			const { login, installmentEdit } = getState();

			const {
				item,
				payDate,
				number,
				value,
				fee,
				paymentType,
				status,
				allowanceId,
				creditId
			} = installmentEdit;

			const errors: any = {};

			dispatch(setValue({ errors }));

			if (value < 0) errors.value = ls.invalid;

			if (fee < 0) errors.fee = ls.invalid;

			if (number < 1) errors.number = ls.invalid;

			if (!payDate) errors.payDate = ls.required;

			if (paymentType === "1" && !creditId) errors.creditId = ls.required;

			if (paymentType === "2" && !allowanceId) errors.allowanceId = ls.required;

			if (_.keys(errors).length > 0) return;

			const model = {
				...item,
				payDate,
				number: number - 1,
				value: Math.round(value * 100),
				fee: Math.round(fee * 100),
				paymentType,
				allowanceId,
				creditId,
				status,
				allowance: undefined,
				company: undefined,
				credit: undefined,
				sale: undefined,
				user: undefined,
				userCredit: undefined
			};

			const response = await api.sendPut("/Installment/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: EDIT_INSTALLMENT_SUCCESS
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_INSTALLMENT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_INSTALLMENT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
