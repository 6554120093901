import { getErrors } from "helpers/error";
import * as api from "services/api";

export const GET_ORDER = "[ORDER DETAIL] GET_ORDER";
export const GET_ORDER_SUCCESS = "[ORDER DETAIL] GET_ORDER_SUCCESS";
export const GET_ORDER_FAILED = "[ORDER DETAIL] GET_ORDER_FAILED";

export const SET_VALUE = "[ORDER DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[ORDER DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getOrder(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ORDER });

		try {
			const { login } = getState();
			let response = await api.sendGet("/Order/" + id, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ORDER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ORDER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_ORDER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
