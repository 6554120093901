import React from "react";
import NumberFormat from "react-number-format";

export default function NumberFormatCustom(props: any) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange &&
					onChange({
						currentTarget: {
							value: values.value
						},
						target: {
							value: values.value
						}
					});
			}}
		/>
	);
}

export function NumberFormatCustomFloat(props: any) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange &&
					onChange({
						currentTarget: {
							value: values.floatValue
						},
						target: {
							value: values.floatValue
						}
					});
			}}
		/>
	);
}

export const CPF = (props: any) => (
	<NumberFormatCustom {...props} format="###.###.###-##" />
);

export const CNPJ = (props: any) => (
	<NumberFormatCustom {...props} format="##.###.###/####-##" />
);

export const CPFCNPJ = (props: any) => (
	<NumberFormatCustom
		{...props}
		format={
			props.value && props.value.match(/\d/g).join("").length <= 11
				? "###.###.###-###"
				: "##.###.###/####-##"
		}
	/>
);

export function maskPhone(value: string) {
	value = value.replace(/\D/g, "");
	value = value.replace(/^(\d{2})(\d)/g, "($1) $2");
	return value.replace(/(\d)(\d{4})$/, "$1-$2");
}

export const IE = (props: any) => (
	<NumberFormatCustom {...props} format="###.###.###" />
);

export const CEP = (props: any) => (
	<NumberFormatCustom {...props} format="#####-###" />
);

export const PHONE = (props: any) => (
	<NumberFormatCustom {...props} format="(##) #####-####" />
);

export const MONEY = (props: any) => (
	<NumberFormatCustomFloat
		prefix="R$"
		decimalSeparator=","
		thousandSeparator="."
		decimalScale={2}
		fixedDecimalScale
		{...props}
	/>
);

export const PERCENTAGE = (props: any) => (
	<NumberFormatCustomFloat
		suffix="%"
		decimalSeparator=","
		thousandSeparator="."
		decimalScale={1}
		fixedDecimalScale
		{...props}
	/>
);

export const PERCENTAGE2 = (props: any) => (
	<NumberFormatCustomFloat
		suffix="%"
		decimalSeparator=","
		thousandSeparator="."
		decimalScale={2}
		fixedDecimalScale
		{...props}
	/>
);

export function maskCNPJ(cnpj: string): string {
	cnpj = cnpj.replace(/\D/g, "");
	cnpj = cnpj.replace(
		/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
		"$1.$2.$3/$4-$5"
	);
	return cnpj;
}

export const DATE = (props: any) => (
	<NumberFormatCustom {...props} format="##/##/####" />
);

export const CREDIT_CARD = (props: any) => (
	<NumberFormatCustom {...props} format="#### #### #### ####" />
);
