import { makeStyles, Slider, Theme, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(5)
	},
	detailsTrackingArea: {
		padding: theme.spacing(2),
		marginTop: theme.spacing(5),
		marginLeft: 10,
		width: "98%"
	},
	trackingArea: {
		padding: theme.spacing(5),
		height: 400
	},
	actionsArea: {
		padding: theme.spacing(1),
		height: 130
	},
	line: {
		marginLeft: -16,
		marginRight: -16
	},
	detailItemAction: {
		marginTop: 10
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	}
}));

export const CustomSlider = withStyles((theme: Theme) => ({
	thumb: {
		color: theme.palette.primary.main,
		width: "15px !important",
		height: "15px !important",
		marginLeft: "-6px !important"
	},
	track: {
		color: theme.palette.primary.main
	},
	markLabel: {
		marginLeft: "8px !important"
	},
	markActive: {
		backgroundColor: theme.palette.primary.main,
		width: "8px !important",
		height: "8px !important",
		marginLeft: "-3px !important",
		opacity: 1,
		borderRadius: 10
	}
}))(Slider);
