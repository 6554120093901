import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextInput from "Components/TextInput";
import { useFormik } from "formik";
import ls from "Localization";
import React, { useCallback } from "react";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 600,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

const schema = Yup.object().shape({
	email: Yup.string().email(ls.emailNotValid).required(ls.required)
});

const ModalClientMail = ({ open, handleClose, emailDefault, onSubmitMail }) => {
	const classes = useStyles({});

	const handleSubmit = useCallback(
		async ({ email }, { resetForm }) => {
			onSubmitMail({ email });
			resetForm({});
			handleClose();
		},
		[onSubmitMail, handleClose]
	);

	const formik = useFormik({
		initialValues: {
			email: emailDefault
		},
		validationSchema: schema,
		onSubmit: handleSubmit
	});

	return (
		<Modal
			aria-labelledby="add-client-mail-modal-title"
			aria-describedby="add-client-mail-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.sendEmail}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={formik.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextInput
										id="email"
										variant="outlined"
										name="email"
										value={formik.values.email}
										errors={formik.touched.email ? formik.errors : {}}
										onFocus={e => e.target.select()}
										onChange={(id, value) => formik.setFieldValue(id, value)}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button color="primary" variant="contained" type="submit">
									{ls.sendEmail}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default ModalClientMail;
