import {
	Button,
	CircularProgress,
	Container,
	Grid,
	Typography
} from "@material-ui/core";
import AutoComplete from "Components/AutoComplete";
import ModalGeneric from "Components/Modals/ModalGeneric";
import { CPFCNPJ, PHONE } from "Components/NumberFormat";
import Select from "Components/Select";
import TextInput from "Components/TextInput";
import { useFormik } from "formik";
import ls from "Localization";
import { Bank, DataBank, PixType } from "models/dataBank";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getBanks,
	getDataBank,
	setValue
} from "store/actions/business/dataBank/get";
import { updateOrCreateDataBank } from "store/actions/business/dataBank/update-create";
import * as Yup from "yup";

import { useStyles } from "./styles";

const schema = Yup.object().shape({
	bankId: Yup.string().required(ls.required),
	agency: Yup.number().positive(ls.invalid).required(ls.required),
	account: Yup.number().positive(ls.invalid).required(ls.required),
	accountDigit: Yup.number().positive(ls.invalid).required(ls.required),
	holderName: Yup.string().required(ls.required),
	cpfCnpj: Yup.string().required(ls.required)
});

export function ModalUpdateOrCreateDataBank() {
	const [disableButton, setDisableButton] = useState(false);
	const [pixType, setPixType] = useState<PixType>();
	const dispatch = useDispatch();
	const classes = useStyles();

	const {
		showModalUpdateOrCreate,
		item: dataBank,
		banks,
		loading
	} = useSelector<
		{
			dataDankBusiness: {
				showModalUpdateOrCreate: boolean;
				item: DataBank;
				banks: Bank[];
				loading: { getBanks: boolean };
			};
		},
		{
			showModalUpdateOrCreate: boolean;
			item: DataBank;
			banks: Bank[];
			loading: { getBanks: boolean };
		}
	>(c => c.dataDankBusiness);

	const { item: business } = useSelector<any, any>(c => c.businessDetail);

	const handleSubmit = useCallback((data: DataBank) => {
		if (data.pixType && !data.pixKey) {
			formik.setFieldError("pixKey", ls.required);
			formik.isValid = false;
		} else {
			formik.isValid = true;
		}

		if (formik.isValid) {
			setDisableButton(true);

			data.businessId = business.id;
			dispatch(updateOrCreateDataBank(data));

			setTimeout(() => handleClose(), 1500);
		}
	}, []);

	const handleClose = useCallback(() => {
		dispatch(setValue({ showModalUpdateOrCreate: false }));
		setDisableButton(false);
	}, [dispatch]);

	const formik = useFormik({
		initialValues: {
			bankId: "",
			agency: "",
			account: "",
			accountDigit: "",
			holderName: "",
			cpfCnpj: "",
			pixKey: null,
			pixType: null,
			businessId: null
		},
		validationSchema: schema,
		onSubmit: handleSubmit
	});

	useEffect(() => {
		if (dataBank) {
			Object.keys(dataBank).forEach(key => {
				formik.initialValues[key] = dataBank[key];
			});
		}

		if (dataBank && String(dataBank?.pixType)) setPixType(dataBank.pixType);
	}, [dataBank]);

	const banksSuggestions = useMemo(
		() =>
			banks?.map(c => ({
				value: c.id,
				label: `${c.code} - ${c.name}`,
				data: c.id
			})),
		[banks]
	);
	useEffect(() => {
		dispatch(getDataBank(business.id));
	}, []);

	return (
		<ModalGeneric
			open={showModalUpdateOrCreate}
			handleClose={handleClose}
			stylePaper={{ padding: 0, minWidth: 700, maxWidth: 700 }}
			styleModal={{ maxHeight: 800, overflow: "auto" }}
			body={() => (
				<Container className={classes.container}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.dataBankAccount}</Typography>
					</div>
					<hr className={classes.line} />

					<form noValidate onSubmit={formik.handleSubmit}>
						<Grid container>
							<Grid container item spacing={2} className={classes.gridForm}>
								<Grid item xs={4}>
									<AutoComplete
										id="bankId"
										name="bankId"
										placeholder={ls.bankName}
										variant="outlined"
										label={ls.bankName}
										isClearable
										isSearchable
										textFieldProps={{}}
										closeMenuOnSelect
										defaultInputValue={dataBank?.bank?.name}
										errors={formik.touched.bankId ? formik.errors : {}}
										onChange={(id, value) => {
											if (value?.data) formik.setFieldValue(id, value.data);
										}}
										onInputChange={text => {
											dispatch(getBanks({ term: text, skip: 0, take: 10 }));
										}}
										suggestions={banksSuggestions}
										loading={loading.getBanks}
										defaultOptions
										required
										className={classes.selectBank}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextInput
										id="agency"
										variant="outlined"
										name="agency"
										type="number"
										value={formik.values.agency}
										errors={formik.touched.agency ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
										required
									/>
								</Grid>
								<Grid item xs={3}>
									<TextInput
										id="account"
										variant="outlined"
										name="account"
										type="number"
										value={formik.values.account}
										errors={formik.touched.account ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
										required
									/>
								</Grid>
								<Grid item xs={2}>
									<TextInput
										id="accountDigit"
										variant="outlined"
										name="accountDigit"
										placeholder="Dígito *"
										type="number"
										value={formik.values.accountDigit}
										errors={formik.touched.accountDigit ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
										required
									/>
								</Grid>
							</Grid>

							<Grid container item spacing={2} className={classes.gridForm}>
								<Grid item xs={8}>
									<TextInput
										id="holderName"
										variant="outlined"
										name="holderName"
										value={formik.values.holderName}
										errors={formik.touched.holderName ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
										required
									/>
								</Grid>
								<Grid item xs={4}>
									<TextInput
										id="cpfCnpj"
										variant="outlined"
										name="cpfCnpj"
										placeholder="CPF ou CNPJ *"
										value={formik.values.cpfCnpj}
										errors={formik.touched.cpfCnpj ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
										InputProps={{ inputComponent: CPFCNPJ }}
										required
									/>
								</Grid>
							</Grid>

							<Grid container item spacing={2} className={classes.gridForm}>
								<Grid item xs={4}>
									<Select
										id="pixType"
										name="pixType"
										value={String(formik.values.pixType)}
										className={classes.pixTypeInput}
										onChange={(id, value) => {
											formik.setFieldValue(id, value);
											formik.setFieldValue("pixKey", "");
											formik.validateForm();
											setPixType(value);
										}}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: PixType.CpfCnpj, value: "CPF/CNPJ" },
											{ id: PixType.Email, value: "Email" },
											{ id: PixType.PhoneNumber, value: "Celular" },
											{ id: PixType.Random, value: "Aleatório" }
										]}
									/>
								</Grid>
								{[PixType.Email, PixType.Random].includes(pixType) && (
									<Grid item xs={8}>
										<TextInput
											id="pixKey"
											variant="outlined"
											name="pixKey"
											value={formik.values.pixKey}
											errors={formik.touched.pixKey ? formik.errors : {}}
											onChange={(id, value) => formik.setFieldValue(id, value)}
										/>
									</Grid>
								)}
								{String(pixType) === String(PixType.CpfCnpj) && (
									<Grid item xs={8}>
										<TextInput
											id="pixKey"
											variant="outlined"
											name="pixKey"
											value={formik.values.pixKey}
											errors={formik.touched.pixKey ? formik.errors : {}}
											onChange={(id, value) => formik.setFieldValue(id, value)}
											InputProps={{ inputComponent: CPFCNPJ }}
										/>
									</Grid>
								)}
								{pixType === PixType.PhoneNumber && (
									<Grid item xs={8}>
										<TextInput
											id="pixKey"
											variant="outlined"
											name="pixKey"
											value={formik.values.pixKey}
											errors={formik.touched.pixKey ? formik.errors : {}}
											onChange={(id, value) => formik.setFieldValue(id, value)}
											InputProps={{ inputComponent: PHONE }}
										/>
									</Grid>
								)}
							</Grid>

							<Grid container direction="row" justify="flex-end">
								<Grid item className={classes.footerButtons}>
									<Button
										variant="contained"
										onClick={handleClose}
										disabled={disableButton}
									>
										{ls.cancel}
									</Button>
									<Button
										startIcon={
											disableButton && (
												<CircularProgress
													style={{ color: "white" }}
													size={20}
												/>
											)
										}
										color="primary"
										variant="contained"
										type="submit"
										style={{ marginLeft: 10 }}
										disabled={disableButton}
									>
										{ls.save}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</Container>
			)}
		/>
	);
}
