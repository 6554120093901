/* eslint-disable react-hooks/exhaustive-deps */
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as actions from "store/actions/cashback/report";

dayjs.extend(customParseFormat);

let getCashbacksDebounced: (() => void) & _.Cancelable = null;

function CashbackReport({ state, getCashbacks, clearValues, login: { user } }) {
	const table = useRef(null);

	useEffect(() => {
		clearValues();
	}, []);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getCashbacksDebounced) {
			getCashbacksDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");
			let date = null;

			if (creationDate?.value.startDate || creationDate?.value.endDate) {
				date = {
					startAt: creationDate.value.startDate,
					endAt: creationDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "creationDate",
					value: JSON.stringify(date)
				});
			}
		}

		getCashbacksDebounced = _.debounce(
			() =>
				getCashbacks(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
					sorted[0]?.id,
					sorted[0]?.desc,
					toExport,
					callback
				),
			500
		);

		getCashbacksDebounced();
	};

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 150,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.cashbackPolicy,
				id: "policy",
				accessor: c => c.cashbackPolicy?.info ?? ls.none,
				show: true
			},
			{
				Header: ls.client,
				id: "client",
				accessor: c => c.client?.fullName ?? ls.none,
				show: true
			},
			{
				Header: ls.business,
				id: "store",
				accessor: c => c.store?.name ?? ls.none,
				show: true
			},
			{
				Header: ls.value,
				id: "value",
				show: true,
				format: c => c.value / 100,
				accessor: c => toStringCurrency(c.value / 100),
				Footer: () => <b>{toStringCurrency(state.totalValue / 100)}</b>,
				width: 120
			},
			{
				Header: ls.codeSale,
				id: "code",
				accessor: c => c.sale?.code ?? ls.none,
				show: true
			},
			{
				Header: ls.saleValue,
				id: "saleValue",
				show: true,
				format: c => c.sale.value / 100,
				accessor: c => toStringCurrency(c.sale.value / 100),
				width: 120,
				Footer: () => <b>{toStringCurrency(state.saleValue / 100)}</b>
			}
		],
		[]
	);

	return (
		<div>
			<Helmet>
				<title>
					{ls.cashback} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.cashback}
				data={state?.items || []}
				pages={state?.pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={state.loading}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
		</div>
	);
}

const mapStateToProps = ({ cashbackReport: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(CashbackReport);
