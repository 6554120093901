import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Link
} from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import checkoutStatus from "models/checkoutStatus";
import React, { useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as actions from "store/actions/checkout";

dayjs.extend(customParseFormat);

let getCheckoutsDebounced: (() => void) & _.Cancelable = null;

function Checkout({
	state,
	login: { user },
	getCheckouts,
	confirmCheckout,
	confirmPaid,
	history
}) {
	const table = useRef(null);
	const [alertModal, setAlertModal] = useState(null);

	const handleConfirm = item => {
		confirmCheckout(item.id, (err, model) => {
			if (err) alert(err);
			else {
				setAlertModal(false);

				if (model.status === 2) {
					setAlertModal(
						<Dialog
							open
							onClose={() => setAlertModal(null)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								Este checkout foi invalidado
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									O funcionário teve novas comprar após a criação do checkout,
									gere um checkout atualizado para confirmar o checkout
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => history.push(`/User/${item.userId}`)}
									color="primary"
									autoFocus
								>
									Ir para o funcionário
								</Button>
								<Button onClick={() => setAlertModal(null)} color="primary">
									Cancelar
								</Button>
							</DialogActions>
						</Dialog>
					);
				}
			}
		});
	};

	const handlePaid = item => {
		confirmPaid(item.id, (err, model) => {
			if (err) alert(err);
			else {
				setAlertModal(false);

				if (model.status === 2) {
					setAlertModal(
						<Dialog
							open
							onClose={() => setAlertModal(null)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								Este checkout foi invalidado
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									O funcionário teve novas comprar após a criação do checkout,
									gere um checkout atualizado para confirmar o checkout
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => history.push(`/User/${item.userId}`)}
									color="primary"
									autoFocus
								>
									Ir para o funcionário
								</Button>
								<Button onClick={() => setAlertModal(null)} color="primary">
									Cancelar
								</Button>
							</DialogActions>
						</Dialog>
					);
				}
			}
		});
	};

	const showConfirm = item => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Deseja confirmar o checkout?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Tem certeza de confirmar este checkout? O funcionário será
						desativado e não conseguira realizar novas compras após a
						confirmação
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleConfirm(item)} color="primary" autoFocus>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const showPaid = item => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Deseja confirmar o pagamento?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Tem certeza que deseja confirmar o pagamento? Esta ação é
						irreversível.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handlePaid(item)} color="primary" autoFocus>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const isAdmin = isInRole(user, ["admin"]);

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter,
				ignoreMaxDate: true
			},
			{
				Header: ls.employee,
				id: "user",
				accessor: c => c.user.fullName,
				show: true,
				miWidth: 200
			},
			{
				Header: ls.createdBy,
				id: "createdBy",
				accessor: c => c.createdBy.fullName,
				show: true,
				width: 200
			},
			{
				Header: ls.business,
				id: "business",
				accessor: c => c.business?.name ?? ls.notDefined,
				show: true,
				width: 200
			},
			{
				Header: ls.cnpj,
				id: "business.cnpj",
				accessor: c => c.business?.cnpj ?? ls.notDefined,
				show: true,
				width: 180
			},
			{
				Header: ls.lastPeriod,
				id: "lastPeriod",
				accessor: c => (c.includeLast ? "Sim" : "Não"),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter?.value}
					>
						<option value="">Todos</option>
						<option value="True">{ls.yes}</option>
						<option value="False">{ls.no}</option>
					</select>
				),
				show: true,
				width: 120
			},
			{
				Header: ls.lastValue,
				id: "lastValue",
				format: c => c.lastValue / 100 ?? 0,
				accessor: c => toStringCurrency(c.lastValue / 100 ?? 0),
				show: true,
				width: 100,
				filterable: false,
				sortable: false
			},
			{
				Header: ls.currentValue,
				id: "currentValue",
				format: c => c.currentValue / 100 ?? 0,
				accessor: c => toStringCurrency(c.currentValue / 100 ?? 0),
				show: true,
				width: 100,
				filterable: false,
				sortable: false
			},
			{
				Header: ls.futureValue,
				id: "futureValue",
				format: c => c.futureValue / 100 ?? 0,
				accessor: c => toStringCurrency(c.futureValue / 100 ?? 0),
				show: true,
				width: 100,
				filterable: false,
				sortable: false
			},
			{
				Header: ls.total,
				id: "total",
				format: c => c.value / 100 ?? 0,
				accessor: c => toStringCurrency(c.value / 100 ?? 0),
				show: true,
				width: 100,
				filterable: false,
				sortable: false
			},
			{
				Header: ls.status,
				id: "status",
				accessor: c => ls[checkoutStatus[c.status]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter?.value}
					>
						<option value="">Todos</option>
						{Object.keys(checkoutStatus).map(c => (
							<option key={c} value={c}>
								{ls[checkoutStatus[c]]}
							</option>
						))}
					</select>
				),
				show: true,
				width: 120
			},
			{
				Header: ls.actions,
				id: "actions",
				accessor: c =>
					c.status === 0 ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-evenly",
								flex: 1
							}}
						>
							<Link
								style={{ cursor: "pointer" }}
								onClick={() => showConfirm(c)}
							>
								Confirmar
							</Link>
						</div>
					) : c.status === 1 && isAdmin ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-evenly",
								flex: 1
							}}
						>
							<Link style={{ cursor: "pointer" }} onClick={() => showPaid(c)}>
								Marcar pago
							</Link>
						</div>
					) : null,
				width: 120,
				show: true,
				filterable: false
			}
		],
		[isAdmin]
	);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getCheckoutsDebounced) {
			getCheckoutsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let payDate = filtered.find(c => c.id === "creationDate");

		if (payDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (payDate?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: payDate.value.startDate
				});
			}

			if (payDate?.value.endDate)
				filtered.push({
					id: "endDate",
					value: payDate.value.endDate
				});
		}

		getCheckoutsDebounced = _.debounce(
			() =>
				getCheckouts(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
					sorted[0]?.id,
					sorted[0]?.desc,
					toExport,
					callback
				),
			500
		);

		getCheckoutsDebounced();
	};

	const { items, pages, loading } = state;

	return (
		<div>
			<Helmet>
				<title>
					{ls.checkouts} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.checkouts}
				data={items}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={loading.search}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			{alertModal}
		</div>
	);
}

const mapStateToProps = ({ checkout: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
