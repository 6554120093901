import ModalGeneric from "Components/Modals/ModalGeneric";
import ls from "Localization";
import React from "react";

import {
	ButtonCancel,
	ButtonExclude,
	Container,
	RowBodyButtons,
	TextAlertBody,
	TextBody
} from "./styles";

interface ModalTrashProps {
	open: boolean;
	handleClose: () => void;
	nameBusiness: string;
	length: number;
	handleExcludeUsers: () => void;
}

const ModalTrash: React.FC<ModalTrashProps> = ({
	open,
	handleClose,
	nameBusiness,
	length,
	handleExcludeUsers
}) => (
	<ModalGeneric
		open={open}
		handleClose={() => handleClose()}
		buttonClose
		stylePaper={{
			width: 667,
			height: 316,
			padding: 20,
			borderRadius: 16
		}}
		body={() => (
			<Container>
				<TextBody>
					Tem certeza que deseja excluir {length} usuário(s) da empresa{" "}
					{nameBusiness}?
				</TextBody>
				<TextAlertBody>Atenção, essa ação é irreversível.</TextAlertBody>
				<RowBodyButtons>
					<ButtonCancel onClick={() => handleClose()}>{ls.cancel}</ButtonCancel>
					<ButtonExclude onClick={() => handleExcludeUsers()}>
						{ls.excluded}
					</ButtonExclude>
				</RowBodyButtons>
			</Container>
		)}
	/>
);

export default ModalTrash;
