export const GET_REPORT_VALUES = "[REPORT_VALUES] GET_REPORT_VALUES";
export const GET_REPORT_VALUES_SUCCESS =
	"[REPORT_VALUES] GET_REPORT_VALUES_SUCCESS";
export const GET_REPORT_VALUES_FAILED =
	"[REPORT_VALUES] GET_REPORT_VALUES_FAILED";

export const SET_VALUE = "[REPORT_VALUES] SET_VALUE";
export const CLEAR_VALUES = "[REPORT_VALUES] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}
