export const DATA_BANK_BUSINESS = "[DATA_BANK_BUSINESS] DATA_BANK_BUSINESS";

export const GET_DATA_BANK_BUSINESS_SUCCESS =
	"[DATA_BANK_BUSINESS] GET_DATA_BANK_BUSINESS_SUCCESS";
export const GET_DATA_BANK_BUSINESS_FAILED =
	"[DATA_BANK_BUSINESS] GET_DATA_BANK_BUSINESS_FAILED";

export const UPDATE_OR_CREATE_DATA_BANK_BUSINESS_SUCCESS =
	"[DATA_BANK_BUSINESS] UPDATE_OR_CREATE_DATA_BANK_BUSINESS_SUCCESS";
export const UPDATE_OR_CREATE_DATA_BANK_BUSINESS_FAILED =
	"[DATA_BANK_BUSINESS] UPDATE_OR_CREATE_DATA_BANK_BUSINESS_FAILED";

export const SET_VALUE = "[DATA_BANK_BUSINESS] SET_VALUE";
export const CLEAR_VALUES = "[DATA_BANK_BUSINESS] CLEAR_VALUES";

export const GET_BANKS_DATA = "[BANK_DATA] GET_BANKS_DATA";
export const GET_BANKS_DATA_SUCCESS = "[BANK_DATA] GET_BANKS_DATA_SUCCESS";
export const GET_BANKS_DATA_FAILED = "[BANK_DATA] GET_BANKS_DATA_FAILED";
