/* eslint-disable consistent-return */
import { getErrors } from "helpers/error";
import _ from "lodash";
import { DataBank } from "models/dataBank";
import * as api from "services/api";
import { getDataBank } from "store/actions/business/dataBank/get";
import * as types from "store/actions/business/dataBank/types";

export function updateOrCreateDataBank(data: DataBank) {
	return async (dispatch, getState) => {
		try {
			const { login } = getState();

			const errors: any = {};
			if (_.keys(errors).length > 0) return;

			const response = await api.sendPut("/data-bank/business/", data, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				let result = await response.json();
				dispatch({
					type: types.UPDATE_OR_CREATE_DATA_BANK_BUSINESS_SUCCESS,
					payload: result.model
				});
				dispatch(getDataBank(data.businessId));
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: types.UPDATE_OR_CREATE_DATA_BANK_BUSINESS_FAILED,
					payload: errors
				});
			}
		} catch (error) {
			return dispatch({
				type: types.UPDATE_OR_CREATE_DATA_BANK_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
