import * as actions from "store/actions/moderation/create";

import { ICreateModeration } from "../types/create";

const initialState: ICreateModeration = {
	showEditModal: false,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_MODERATION: {
			return {
				...state,
				loading: {
					...state.loading,
					create: true
				},
				errors: initialState.errors
			};
		}

		case actions.CREATE_MODERATION_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					create: false
				},
				item: payload
			};
		}

		case actions.CREATE_MODERATION_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					create: false
				},
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			return {
				...initialState
			};

		default: {
			return state;
		}
	}
};

export default reducer;
