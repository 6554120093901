import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import ExtractPMATemplate from "Components/Report/TemplatesPDF/ExtractPMA";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import fundsPMAType from "models/fundsPMAType";
import fundsTypeReason from "models/fundsTypeReason";
import React, { useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/treasuryAccountWiipoFlex/report";

dayjs.extend(customParseFormat);

let getTransfersDebounced: (() => void) & _.Cancelable = null;

function TreasuryAccountWiipoFlexReportTransfer() {
	const dispatch = useDispatch();
	const table = useRef(null);

	const handleGetTransfersPMA = useCallback(
		(skip?, take?, filter?, sort?, desc?, toExport?, callback?) => {
			dispatch(
				actions.getPMATransfer({
					skip,
					take,
					filter,
					sort,
					desc,
					toExport,
					callback
				})
			);
		},
		[dispatch]
	);

	const state = useSelector<any, any>(
		state => state.treasuryAccountWiipoFlexTransfer
	);

	let columns = [
		{
			Header: ls.idTransaction,
			id: "code",
			accessor: c => c.code,
			show: true,
			width: 120
		},
		{
			Header: ls.externalId,
			id: "externalId",
			accessor: c => c.externalId,
			show: false,
			width: 260
		},
		{
			Header: ls.data,
			id: "creationDate",
			accessor: c => dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
			width: 140,
			show: true,
			sortMethod: (a, b) => {
				let aD = dayjs(a, ls.dateFormatWithoutSeconds);
				let bD = dayjs(b, ls.dateFormatWithoutSeconds);

				// eslint-disable-next-line no-nested-ternary
				return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
			},
			Filter: DateSelectFilter
		},
		{
			Header: ls.sourceAccount,
			id: "fromBusiness",
			width: 150,
			show: true,
			accessor: c => c.fromBusiness?.name || "-"
		},
		{
			Header: ls.sourceTreasuryAccount,
			id: "fromTreasuryAccount",
			width: 150,
			show: false,
			accessor: c => c.fromBusiness?.treasuryAccountHolderId || "-"
		},
		{
			Header: ls.economicGroup,
			id: "headquartersName",
			accessor: c => c.fromBusiness?.headquarters?.socialName || ls.none,
			show: true,
			width: 170
		},
		{
			Header: ls.cnpjSource,
			id: "fromCnpj",
			width: 150,
			show: false,
			accessor: c => c.fromBusiness?.cnpj || "-"
		},
		{
			Header: ls.destinyAccount,
			id: "toBusiness",
			accessor: c => c.toBusiness?.name,
			width: 150,
			show: true
		},
		{
			Header: ls.destinyTreasuryAccount,
			id: "toTreasuryAccount",
			width: 150,
			show: false,
			accessor: c => c.toBusiness?.treasuryAccountHolderId || "-"
		},
		{
			Header: ls.destinyCnpj,
			id: "toCnpj",
			width: 150,
			show: false,
			accessor: c => c.toBusiness?.cnpj || "-"
		},
		{
			Header: ls.type,
			id: "type",
			accessor: c => ls[fundsPMAType[c.type]],
			Filter: ({ filter, onChange }) => (
				<select
					onChange={event => onChange(event.target.value)}
					style={{ width: "100%" }}
					value={filter ? filter.value : "all"}
				>
					<option value="">Todos</option>
					{Object.keys(fundsPMAType).map(c => (
						<option key={c} value={c}>
							{ls[fundsPMAType[c]]}
						</option>
					))}
				</select>
			),
			show: true,
			width: 120
		},
		{
			Header: ls.value,
			id: "value",
			accessor: c => toStringCurrency(c.value / 100),
			width: 150,
			show: true,
			Footer: () => <b>{toStringCurrency(state.totalValue / 100)}</b>
		},
		{
			Header: ls.saleBefore,
			id: "pmaBalanceBefore",
			accessor: c => toStringCurrency(c.pmaBalanceBefore / 100),
			width: 160,
			show: true,
			filterable: false
		},
		{
			Header: ls.saleAfter,
			id: "saleAfter",
			accessor: c =>
				c.type === 1
					? toStringCurrency((c.pmaBalanceBefore - c.value) / 100)
					: toStringCurrency((c.pmaBalanceBefore + c.value) / 100),
			width: 160,
			show: true,
			filterable: false
		},
		{
			Header: ls.createdBy,
			id: "createdBy",
			filterable: false,
			accessor: c => {
				if (!c.createdBy?.fullName) return ls.none;

				if (c.createdBy?.fullName === "Resgate Limite Resgate Limite")
					return "Sistema";

				return c.createdBy?.fullName;
			},
			width: 120,
			show: true
		},
		{
			Header: ls.motive,
			id: "reason",
			accessor: c => ls[fundsTypeReason[c.reason]],
			Filter: ({ filter, onChange }) => (
				<select
					onChange={event => onChange(event.target.value)}
					style={{ width: "100%" }}
					value={filter ? filter.value : "all"}
				>
					<option value="">Todos</option>
					{Object.keys(fundsTypeReason).map(c => (
						<option key={c} value={c}>
							{ls[fundsTypeReason[c]]}
						</option>
					))}
				</select>
			),
			width: 200,
			show: true
		},
		{
			Header: ls.deposit,
			id: "funds",
			filterable: false,
			accessor: c => c.funds?.code || ls.none,
			width: 100,
			show: false
		},
		{
			Header: ls.description,
			id: "motive",
			accessor: c => c.description || ls.none,
			width: 150,
			show: false,
			filterable: false
		}
	];

	const handleFetchData = useCallback(
		tableState => {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getTransfersDebounced) {
				getTransfersDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: creationDate.value.startDate
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: creationDate.value.endDate
					});
			}

			const filterTable = filtered.reduce(
				(p, c) => `${p}&filters[${c.id}]=${c.value}`,
				""
			);

			getTransfersDebounced = _.debounce(
				() =>
					handleGetTransfersPMA(
						page * pageSize,
						pageSize,
						filterTable,
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					),
				1000
			);

			getTransfersDebounced();
		},
		[handleGetTransfersPMA]
	);

	return (
		<>
			<Helmet>
				<title>
					{ls.extract} | {ls.appName}
				</title>
			</Helmet>
			<Report
				showExportPDF
				manual
				tableRef={table}
				title={ls.extract}
				data={state.items}
				pages={state.pages}
				onFetchData={handleFetchData}
				templatePDF={<ExtractPMATemplate />}
				filterable
				showExport
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={state.loading}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
		</>
	);
}

export default TreasuryAccountWiipoFlexReportTransfer;
