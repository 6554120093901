import Logo from "assets/Logo-05.png";
import ImgBlob from "Components/ImgBlob";
import dayjs from "dayjs";
import ls from "Localization";
import requestCardsStatus from "models/requestCardsStatus";
import serviceStatus from "models/serviceStatus";
import React from "react";
import { requestItems } from "store/reducers/requestCards/types";

import {
	BackgroundButton,
	BackgroundPage,
	Body,
	ColumnTitle,
	Container,
	Header,
	LabelColumn,
	RowAddress,
	RowCPFDestiny,
	RowDestiny,
	RowGray,
	RowStatus,
	Section,
	SubTitleLabel,
	TextLabelColumn,
	Title
} from "./styles";

interface ItemProps {
	item: requestItems;
	id: string;
}

const TemplatePDF: React.FC<ItemProps> = ({ item, id }) => {
	const {
		creationDate,
		createdBy,
		business,
		quantityCard,
		status,
		statusDescription,
		trackingCode,
		street,
		number,
		neighborhood,
		city,
		state,
		name,
		document,
		zipcode,
		service
	} = item;

	return (
		<Container>
			<BackgroundPage>
				<Header>
					<ImgBlob
						imageUrl={Logo}
						style={{
							position: "relative",
							zIndex: 99,
							top: 10,
							left: 20,
							backgroundColor: "#fff",
							paddingTop: 15,
							paddingBottom: 15,
							paddingLeft: 5,
							paddingRight: 5,
							borderRadius: 9,
							width: 50,
							height: 20,
							boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)"
						}}
					/>
					<Title>Relatório de Pedido de Cartões</Title>
				</Header>
				<Body>
					<ColumnTitle>
						<LabelColumn>Solicitado por:</LabelColumn>
						<TextLabelColumn>
							{createdBy?.fullName || ls.notDefined}
						</TextLabelColumn>
						<SubTitleLabel>
							Data: {dayjs(creationDate).format("DD/MM/YYYY  HH:mm:ss")}
						</SubTitleLabel>
					</ColumnTitle>
					<ColumnTitle>
						<LabelColumn>Empresa</LabelColumn>
						<TextLabelColumn>
							{business?.displayName || ls.notDefined}
						</TextLabelColumn>
						<SubTitleLabel>
							CNPJ: {business?.cnpj || ls.notDefined}
						</SubTitleLabel>
					</ColumnTitle>
					<ColumnTitle>
						<LabelColumn>Quantidade</LabelColumn>
						<TextLabelColumn>{quantityCard} cartões</TextLabelColumn>
						<SubTitleLabel>Nº do Pedido: {id}</SubTitleLabel>
					</ColumnTitle>
				</Body>

				<Section>
					<RowGray>Sobre o Pedido</RowGray>
					<RowStatus>
						<p style={{ marginRight: 40 }}>
							Status: {ls[requestCardsStatus[status]]}
						</p>
						{statusDescription && (
							<p style={{ marginRight: 40 }}>
								Rastreamento: {statusDescription}
							</p>
						)}
					</RowStatus>

					<RowStatus>
						<strong style={{ marginRight: 40 }}>
							Código de rastreio:
							<BackgroundButton>{trackingCode}</BackgroundButton>
						</strong>
						<span style={{ marginRight: 40 }}>
							Tipo de entrega: {ls[serviceStatus[service]]}
							{/* <button type="button">Retificar endereço</button> */}
						</span>
					</RowStatus>
					<RowAddress>
						<p>
							Endereço de entrega: R. {street}, {number} - {neighborhood},{" "}
							{city}- {state}, CEP {zipcode}.{" "}
						</p>
					</RowAddress>
					<RowDestiny>Destinatário: {name}</RowDestiny>
					<RowCPFDestiny>CPF do destinatário: {document}</RowCPFDestiny>
				</Section>
			</BackgroundPage>
		</Container>
	);
};

export default TemplatePDF;
