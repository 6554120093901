import * as actions from "store/actions/productCategory";

import { IProductCategory } from "./types";

const initialState: IProductCategory = {
	items: [],
	item: null,
	name: "",
	count: 0,
	pages: 0,
	push: 0,
	showFormModal: false,
	showEditFormModal: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_PRODUCT_CATEGORY: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}

		case actions.GET_PRODUCT_CATEGORY_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.name = payload.name ?? initialState.name;
			} else {
				newState.item = null;
				newState.name = initialState.name;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}

		case actions.GET_PRODUCT_CATEGORY_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.GET_PRODUCT_CATEGORIES: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}

		case actions.GET_PRODUCT_CATEGORIES_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}

		case actions.GET_PRODUCT_CATEGORIES_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.CREATE_PRODUCT_CATEGORY: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}

		case actions.CREATE_PRODUCT_CATEGORY_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				name: initialState.name
			};
		}

		case actions.CREATE_PRODUCT_CATEGORY_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}

		case actions.UPDATE_PRODUCT_CATEGORY: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_PRODUCT_CATEGORY_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				name: initialState.name
			};
		}

		case actions.UPDATE_PRODUCT_CATEGORY_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}

		case actions.DELETE_PRODUCT_CATEGORY: {
			return {
				...state,
				loading: { deleteProduct: true },
				errors: initialState.errors
			};
		}

		case actions.DELETE_PRODUCT_CATEGORY_SUCCESS: {
			let { items } = state;

			items = items.filter(c => c.id !== payload);

			return {
				...state,
				items: [...items],
				loading: { deleteProduct: false }
			};
		}

		case actions.DELETE_PRODUCT_CATEGORY_FAILED: {
			return {
				...state,
				loading: { deleteProduct: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				name: initialState.name
			};

		default: {
			return state;
		}
	}
};

export default reducer;
