import * as actions from "store/actions/component/report";

const initialState = {
	items: []
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_REPORT_VALUES: {
			return {
				...state
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
