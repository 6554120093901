import { modalAlert } from "Components/Modals/ModalAlert";
import { toastError } from "helpers/toast";
import ls from "Localization";
import * as api from "services/api";
import { ITreasuryAccountTransfer } from "store/actions/treasuryAccountTransfer/types";
import { Callback } from "Types/Callback";

import { ActionPayload, ActionTypes } from "../types";

export interface ITreasuryAccountTransferRequestNF {
	id: string;
}

interface IState {
	treasuryAccountTransactionCreate: ITreasuryAccountTransfer;
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export const downloadNF =
	(
		{ id }: ITreasuryAccountTransferRequestNF,
		callback?: Callback<ITreasuryAccountTransfer>
	) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.CREATE_NF_FUNDS_IN_WIIPOFLEX_REQUEST });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Funds/${id}/Receipt`, {
				Authorization: `Bearer ${login.token}`
			});

			const result = await response.json();

			const dataUrl = atob(result.model.split("base64,").pop());

			const byteNumbers = new Array(dataUrl.length);
			for (let i = 0; i < dataUrl.length; i++) {
				byteNumbers[i] = dataUrl.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			const blob = new Blob([byteArray], { type: "application/pdf" });

			let data = window.URL.createObjectURL(blob);

			let link = document.createElement("a");
			link.href = data;
			link.download = `${Date.now()}.pdf`;
			setTimeout(() => {
				link.click();
				// Firefox, necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);

			if (!result.isValid) {
				toastError("Error generating / downloading pdf");
				callback && callback(result.errors);
				return dispatch({
					type: ActionTypes.CREATE_NF_FUNDS_IN_WIIPOFLEX_FAILED,
					payload: { id }
				});
			}

			callback && callback();
			return dispatch({
				type: ActionTypes.CREATE_NF_FUNDS_IN_WIIPOFLEX_SUCCESS,
				payload: { id }
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.CREATE_NF_FUNDS_IN_WIIPOFLEX_FAILED
			});
		}
	};
