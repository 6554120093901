import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@material-ui/core";
import ls from "Localization";
import React, { useCallback, useState } from "react";
import { render } from "react-dom";

interface IModalConfirmation {
	title: string;
	body: JSX.Element;
	children: JSX.Element;
	yesOrNot?: boolean;
	onClickYes: () => void;
	stylesYes?: {};
	onClickNo: () => void;
	stylesNo?: {};
}

const ModalConfirmation: React.FC<Omit<IModalConfirmation, "body">> = ({
	title,
	onClickYes,
	stylesYes,
	onClickNo,
	stylesNo,
	yesOrNot,
	children
}) => {
	const [show, setShow] = useState(true);

	const handleClickYes = useCallback(() => {
		onClickYes();
		setShow(false);
	}, [onClickYes]);

	const handleClickNo = useCallback(() => {
		onClickNo();
		setShow(false);
	}, [onClickNo]);
	return (
		<Dialog
			open={show}
			onClose={handleClickNo}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
				{title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{children}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="primary"
					onClick={handleClickYes}
					autoFocus
					style={stylesYes}
				>
					{yesOrNot ? ls.confirm : ls.yes}
				</Button>
				<Button onClick={handleClickNo} style={stylesNo}>
					{yesOrNot ? ls.cancel : ls.no}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ModalConfirmation;

export const modalConfirmation = ({
	title,
	onClickYes,
	onClickNo,
	stylesNo,
	stylesYes,
	body
}: Omit<IModalConfirmation, "children">): void => {
	const containerDomNode = document.createElement("div");
	document.body.appendChild(containerDomNode);
	render(
		<ModalConfirmation
			title={title}
			onClickYes={onClickYes}
			onClickNo={onClickNo}
			stylesYes={stylesYes}
			stylesNo={stylesNo}
		>
			{body}
		</ModalConfirmation>,
		containerDomNode
	);
};
