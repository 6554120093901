import * as actions from "store/actions/dashboard";

import { IDashboard } from "./types";

const initialState: IDashboard = {
	user: null,
	sale: null,
	business: null,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_SALE_INFO: {
			return {
				...state,
				loading: { ...state.loading, sale: true },
				errors: initialState.errors
			};
		}
		case actions.GET_SALE_INFO_SUCCESS: {
			return {
				...state,
				sale: payload,
				loading: { ...state.loading, sale: false },
				errors: initialState.errors
			};
		}
		case actions.GET_SALE_INFO_FAILED: {
			return {
				...state,
				loading: { ...state.loading, sale: false },
				errors: payload
			};
		}
		case actions.GET_USER_INFO: {
			return {
				...state,
				loading: { ...state.loading, user: true },
				errors: initialState.errors
			};
		}
		case actions.GET_USER_INFO_SUCCESS: {
			return {
				...state,
				user: payload,
				loading: { ...state.loading, user: false },
				errors: initialState.errors
			};
		}
		case actions.GET_USER_INFO_FAILED: {
			return {
				...state,
				loading: { ...state.loading, user: false },
				errors: payload
			};
		}
		case actions.GET_BUSINESS_INFO: {
			return {
				...state,
				loading: { ...state.loading, business: true },
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_INFO_SUCCESS: {
			return {
				...state,
				business: payload,
				loading: { ...state.loading, business: false },
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_INFO_FAILED: {
			return {
				...state,
				loading: { ...state.loading, business: false },
				errors: payload
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
