import React from "react";
import TableCellMd, { TableCellProps } from "@material-ui/core/TableCell";

import { Link } from "react-router-dom";

interface Props extends TableCellProps {
	to?: string;
}

function TableCell({ to, children, ...rest }: Props) {
	let el = children;

	if (to) {
		el = <Link to={to}>{children}</Link>;
	}
	return <TableCellMd {...rest}>{el}</TableCellMd>;
}

export default TableCell;
