import { copyTextToClipboard } from "helpers/string";
import React, { useCallback, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiCopy } from "react-icons/fi";
import { ToastOptions } from "react-toastify";

import { Container } from "./styles";

interface PropsCopyClipboard {
	valueCopy: string;
	textOkCopy?: string;
	config?: ToastOptions<{}>;
}

const ButtonCopyClipboard: React.FC<PropsCopyClipboard> = ({
	valueCopy,
	textOkCopy,
	config
}) => {
	const [iconCopy, setIconCopy] = useState(true);

	const handleEnableIconCopy = useCallback(() => {
		setIconCopy(false);
		setTimeout(() => setIconCopy(true), 2000);
	}, []);

	return (
		<Container
			type="button"
			isCursorPointer={iconCopy}
			disabled={!iconCopy}
			onClick={() => {
				copyTextToClipboard(
					handleEnableIconCopy,
					valueCopy,
					textOkCopy,
					config
				);
			}}
		>
			{iconCopy ? (
				<FiCopy color="#5734D9" size={18} style={{ margin: 8 }} />
			) : (
				<AiOutlineCheck color="#00B940" size={18} style={{ margin: 8 }} />
			)}
		</Container>
	);
};

export default ButtonCopyClipboard;
