import { getErrors } from "helpers/error";
import * as api from "services/api";

export const EDIT_USER_REMOVE_LOCKOUT = "[USER EDIT] EDIT_USER_REMOVE_LOCKOUT";
export const EDIT_USER_REMOVE_LOCKOUT_SUCCESS =
	"[USER EDIT] EDIT_USER_REMOVE_LOCKOUT_SUCCESS";
export const EDIT_USER_REMOVE_LOCKOUT_FAILED =
	"[USER EDIT] EDIT_USER_REMOVE_LOCKOUT_FAILED";

export const SET_VALUE = "[USER DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[USER DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function sendRemoveLockoutUser(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_USER_REMOVE_LOCKOUT });

		try {
			const { login } = getState();
			let response = await api.sendPut(`/User/RemoveLockout/${id}`, null, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_USER_REMOVE_LOCKOUT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_USER_REMOVE_LOCKOUT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: EDIT_USER_REMOVE_LOCKOUT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
