export default {
	"0": "notDefined",
	"1": "transferBetweenBusines",
	"2": "taxCashWithdrawal",
	"3": "limitRescueFee",
	"4": "taxTransferBagWithdraw",
	"5": "inactivityFee",
	"6": "automatedFundsIn",
	"7": "manualFundsIn",
	"8": "clubPurchase",
	"9": "fundsOut",
	"13": "automatedAndManualFundsIn"
};
