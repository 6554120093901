import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import FundsStatus from "models/fundsStatus";
import fundsType from "models/fundsType";
import React, { useCallback, useMemo } from "react";

import { Ball, Body, Container, Title } from "./styles";

interface IObjectProps {
	id: string;
	businessName: string;
	fromStatus: number;
	toStatus: number;
	cnpj: string;
	value: number;
	type: number;
	action: number;
}

interface IProps {
	object: IObjectProps;
}

const ButtonNotification: React.FC<IProps> = ({ object }) => {
	const { id, businessName, cnpj, value, type, action, fromStatus, toStatus } =
		object;
	const isDepositText = useMemo(
		() => (type === 1 ? "atrelado a recarga" : "de depósito avulso"),
		[type]
	);
	const resultText = useCallback(() => {
		switch (action) {
			case 0:
				return (
					<>
						<Title>
							<Ball /> #{id} Mudança de status no{" "}
							{ls[fundsType[type]].toLowerCase()}
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de
							<b> {toStringCurrency(value / 100)} foi cancelado com sucesso</b>
						</Body>
					</>
				);
			case 1:
				return (
					<>
						<Title>
							<Ball /> #{id} Solicitação de {ls[fundsType[type]].toLowerCase()}
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de
							<b>
								{" "}
								{toStringCurrency(value / 100)} foi solicitado com sucesso{" "}
							</b>
						</Body>
					</>
				);
			case 2:
				return (
					<>
						<Title>
							<Ball /> #{id} Criação de {ls[fundsType[type]].toLowerCase()}
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de
							<b>
								{toStringCurrency(value / 100)} foi criado com sucesso. Gerando
								sua nota, esse processo pode levar até 10 minutos.
							</b>
						</Body>
					</>
				);
			case 3:
				return (
					<>
						<Title>
							<Ball /> #{id} Error no {ls[fundsType[type]].toLowerCase()}
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de{" "}
							<b>{toStringCurrency(value / 100)} ocorreu uma falha ao criar </b>
						</Body>
					</>
				);
			case 4:
			case 5:
				return (
					<>
						<Title>
							<Ball /> #{id} Atualização do {ls[fundsType[type]].toLowerCase()}
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de{" "}
							<b>
								{toStringCurrency(value / 100)} ocorreu uma atualização no
								pedido
							</b>
						</Body>
					</>
				);
			case 6:
				return (
					<>
						<Title>
							<Ball /> #{id} Error no {ls[fundsType[type]].toLowerCase()}
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de{" "}
							<b>
								{toStringCurrency(value / 100)} ocorreu uma falha ao atualizar o
								pedido
							</b>
						</Body>
					</>
				);
			case 7:
				return (
					<>
						<Title>
							<Ball /> #{id} Atualização do pedido
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de{" "}
							<b>{toStringCurrency(value / 100)} foi deletado com sucesso</b>
						</Body>
					</>
				);

			case 8:
				return (
					<>
						<Title>
							<Ball /> #{id} Pagamento confirmado
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de{" "}
							<b>{toStringCurrency(value / 100)} foi confirmado o pagamento</b>
							aguarde o processamento do rh
						</Body>
					</>
				);
			case 9:
				return (
					<>
						<Title>
							<Ball /> #{id} Mudança de status
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de R$ <b>{toStringCurrency(value / 100)} </b> mudou do
							status <b>{ls[FundsStatus[fromStatus]]}</b> para o{" "}
							<b>{ls[FundsStatus[toStatus]]}</b>
						</Body>
					</>
				);
			case 10:
				return (
					<>
						<Title>
							<Ball /> #{id} Pedido finalizado
						</Title>
						<Body>
							Pedido {isDepositText} gerado da empresa {businessName} ({cnpj})
							no valor de R$ <b>{toStringCurrency(value / 100)} </b> finalizado
							seu dinheiro foi depositado na conta da tesouraria da empresa
						</Body>
					</>
				);

			default:
				return (
					<>
						<Title>
							<Ball /> #{id} Mudança de status
						</Title>
						<Body>
							#{id} Pedido {isDepositText} gerado da empresa {businessName} (
							{cnpj}) no valor de R$ {toStringCurrency(value / 100)}{" "}
						</Body>
					</>
				);
		}
	}, [
		action,
		businessName,
		cnpj,
		fromStatus,
		id,
		toStatus,
		type,
		value,
		isDepositText
	]);

	return <Container>{resultText()}</Container>;
};

export default ButtonNotification;
