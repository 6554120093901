import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "Components/Checkbox";
import { MONEY } from "Components/NumberFormat";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import {
	clearValues,
	editDeliveryAddress,
	getDeliveryAddress,
	setValue
} from "store/actions/deliveryAddress";
import {
	clearValues as clearValuesWorld,
	getCities,
	getNeighborhoods,
	getStates
} from "store/actions/world";

import useStyles from "./styles";

let debounceFindCities: (() => void) & _.Cancelable = null;
let debounceFindNeighborhoods: (() => void) & _.Cancelable = null;
let debounceDeliveryAddressEdit: (() => void) & _.Cancelable = null;

export function DeliveryAddressEdit({
	history,
	match: {
		params: { id, itemId }
	}
}) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [initializing, setInitializing] = useState(true);
	const [cityName, setCityName] = useState("");
	const [neighborhoodName, setNeighborhoodName] = useState("");

	const {
		name,
		value,
		time,
		state,
		minPrice,
		freeShipping,
		city,
		neighborhood,
		neighborhoodsList,
		citiesList,
		loading,
		errors
	} = useSelector<any, any>(s => s.deliveryAddress);

	const { states, cities, neighborhoods } = useSelector<any, any>(s => s.world);

	const findCities = useCallback(
		(state, name) => {
			if (debounceFindCities) debounceFindCities.cancel();

			debounceFindCities = _.debounce(() => {
				dispatch(getCities(state, name));
			}, 400);

			debounceFindCities();
		},
		[dispatch]
	);

	const findNeighborhoods = useCallback(
		(city, name) => {
			if (debounceFindNeighborhoods) debounceFindNeighborhoods.cancel();

			debounceFindNeighborhoods = _.debounce(() => {
				dispatch(getNeighborhoods(city, name));
			}, 400);

			debounceFindNeighborhoods();
		},
		[dispatch]
	);

	useEffect(() => {
		dispatch(clearValues());
		dispatch(clearValuesWorld());
		dispatch(getStates());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getDeliveryAddress(itemId));
		setInitializing(false);
	}, [dispatch, itemId]);

	useEffect(() => {
		if (state) {
			setCityName("");
			dispatch(setValue({ city: null, neighborhood: null }));
			findCities(state.id, cityName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	useEffect(() => {
		if (state) {
			findCities(state.id, cityName);
		}
	}, [cityName, findCities, state]);

	useEffect(() => {
		if (city) {
			dispatch(setValue({ neighborhood: null }));
			findNeighborhoods(city.id, "");
		}
	}, [city, dispatch, findNeighborhoods]);

	useEffect(() => {
		if (city) {
			findNeighborhoods(city.id, neighborhoodName);
		}
	}, [city, findNeighborhoods, neighborhoodName]);

	const handleChange = (
		name: string,
		value: string | number | boolean | any
	) => {
		const values = {
			[name]: value
		};

		if (name === "freeShipping" && !value) {
			values.minPrice = "0";
		}

		dispatch(setValue(values));
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (debounceDeliveryAddressEdit) debounceDeliveryAddressEdit.cancel();

		debounceDeliveryAddressEdit = _.debounce(() => {
			dispatch(
				editDeliveryAddress((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push(`/Store/${id}`);
					}
				})
			);
		}, 1000);

		debounceDeliveryAddressEdit();
	};

	const handleAdd = () => {
		if (
			neighborhood &&
			!neighborhoodsList.find(c => c.id === neighborhood.id) &&
			!citiesList.find(c => c.id === neighborhood.cityId)
		) {
			handleChange("neighborhoodsList", [neighborhood, ...neighborhoodsList]);
		} else if (city && !citiesList.find(c => c.id === city.id)) {
			handleChange("citiesList", [city, ...citiesList]);
		}
	};

	const handleRemove = item => {
		if (citiesList.includes(item))
			handleChange(
				"citiesList",
				citiesList.filter(c => c !== item)
			);
		else if (neighborhoodsList.includes(item))
			handleChange(
				"neighborhoodsList",
				neighborhoodsList.filter(c => c !== item)
			);
	};

	if (initializing) return <div>Carregando...</div>;

	let data = [...citiesList, ...neighborhoodsList];

	return (
		<form noValidate onSubmit={onSubmit}>
			<Grid container justify="center" spacing={5}>
				<Grid item xs={11}>
					<Paper className={classes.root}>
						<Typography variant="h6">Criar novo endereço de entrega</Typography>
						<hr className={classes.line} />

						<Grid container spacing={2} className={classes.form}>
							<Grid item xs={12} sm={8} md={6} lg={8}>
								<TextInput
									id="name"
									required
									variant="outlined"
									name="name"
									value={name}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={4} md={3} lg={2}>
								<TextInput
									id="value"
									required
									variant="outlined"
									name="deliveryValue"
									value={value}
									InputProps={{
										inputComponent: MONEY
									}}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>

							<Grid item xs={12} sm={4} md={3} lg={2}>
								<TextInput
									id="time"
									required
									variant="outlined"
									name="standardDeliveryTime"
									value={time}
									InputProps={{
										inputProps: {
											step: 1,
											min: 1
										},
										endAdornment: <div>min</div>
									}}
									type="number"
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>

							<Grid item xs={12}>
								<Checkbox
									id="freeShipping"
									name="freeShipping"
									checked={freeShipping}
									onChange={handleChange}
								/>
							</Grid>

							{freeShipping && (
								<Grid item xs={12} sm={4} md={3} lg={2}>
									<TextInput
										id="minPrice"
										required
										variant="outlined"
										name="minPrice"
										label="Preço min. p/ frete grátis"
										value={minPrice}
										InputProps={{
											inputComponent: MONEY
										}}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
							)}
							<Grid item xs={12}>
								<Typography>Endereços:</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Autocomplete
									id="state"
									options={states}
									value={state}
									getOptionLabel={c => `${c.shortName} - ${c.name}`}
									getOptionSelected={(o, v) => o === v}
									renderInput={params => (
										<TextField
											{...params}
											label="Estado"
											required
											variant="outlined"
										/>
									)}
									onChange={(e, v) => handleChange("state", v)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Autocomplete
									id="city"
									options={cities}
									value={city}
									getOptionLabel={c => c.name}
									getOptionSelected={(o, v) => o === v}
									renderInput={params => (
										<TextField
											{...params}
											label="Cidade"
											required
											variant="outlined"
											onChange={e => setCityName(e.target.value)}
										/>
									)}
									noOptionsText={
										state ? "Nenhuma opção encontrada" : "Selecione um estado"
									}
									onChange={(e, v) => handleChange("city", v)}
								/>
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Autocomplete
									id="neighborhood"
									options={neighborhoods}
									value={neighborhood}
									getOptionLabel={c => c.name}
									getOptionSelected={(o, v) => o === v}
									renderInput={params => (
										<TextField {...params} label="Bairro" variant="outlined" />
									)}
									noOptionsText={
										city ? "Nenhuma opção encontrada" : "Selecione uma cidade"
									}
									onChange={(e, v) => handleChange("neighborhood", v)}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								md={4}
								lg={3}
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{state && city && (
									<Button
										variant="contained"
										fullWidth
										color="primary"
										onClick={handleAdd}
									>
										{!neighborhood
											? "Adicionar cidade inteira"
											: "Adicionar bairro"}
									</Button>
								)}
							</Grid>

							<Grid item xs={12}>
								<ReactTable
									className="-striped -highlight"
									data={data}
									columns={[
										{
											Header: "Bairro",
											id: "neighborhood",
											accessor: c => (c.city ? c.name : "Todos os bairros")
										},
										{
											Header: "Cidade",
											id: "city",
											accessor: c => (c.city ? c.city.name : c.name)
										},
										{
											Header: "",
											id: "actions",
											accessor: c => (
												<div>
													<MdDelete
														style={{ cursor: "pointer" }}
														onClick={() => handleRemove(c)}
													/>
												</div>
											),
											width: 80
										}
									]}
									showFilters={false}
									showPagination={false}
									pageSize={data.length || 1}
									pageSizeOptions={[data.length || 1]}
									getTdProps={(state, rowInfo, column) => ({
										style: {
											display: "flex",
											alignItems: "center"
										}
									})}
								/>
								<Typography color="error">{errors["addresses"]}</Typography>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={11}>
					<Grid container justify="flex-end">
						<Button
							variant="contained"
							color="default"
							className={classes.button}
							onClick={() => dispatch(clearValues(true))}
						>
							{ls.clear}
						</Button>
						<Button
							variant="contained"
							disabled={loading.create}
							color="primary"
							className={classes.button}
							type="submit"
						>
							{ls.save}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
}

export default DeliveryAddressEdit;
