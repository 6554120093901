import styled from "styled-components";

export const Container = styled.div`
	background: #f8f8f8 0% 0% no-repeat padding-box;
	opacity: 1;
	height: 110px;
	padding: 5px;
	margin: 5px;
`;

export const Title = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-top: 10px;
	text-align: left;
	font: normal normal 600 14px Exo;
	letter-spacing: 0px;
	color: #494949;
	opacity: 1;
`;

export const Ball = styled.div`
	height: 7px;
	width: 7px;
	background: #5734d9 0% 0% no-repeat padding-box;
	opacity: 1;
	border-radius: 16px;
	margin-left: 15px;
	margin-right: 25px;
`;

export const Body = styled.div`
	padding: 10px 45px;
	text-align: left;
	font: normal normal normal 12px Exo;
	letter-spacing: 0px;
	color: #7b7b7b;
	opacity: 1;
`;
