import { Fab } from "@material-ui/core";
import DeleteChargeModal from "Components/DeleteChargeModal";
import Report from "Components/Report";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import React, { useCallback, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as deleteChargeAction from "store/actions/charge/delete";

import AddTransaction from "../Add";
import useChargeData from "./helpers/useChargeData";
import { useColumns } from "./helpers/useColumns";
import useStyles from "./styles";

dayjs.extend(customParseFormat);

function ChargeReport() {
	const dispatch = useDispatch();
	const state = useSelector<any, any>(state => state.chargeReport);
	const { handleFetchData } = useChargeData();
	const { user } = useSelector<any, any>(state => state.login);
	const classes = useStyles({});

	const table = useRef(null);
	const [showAdd, setShowAdd] = useState(false);
	const [modal, setModal] = useState<{ open: boolean; item?: any }>({
		open: false
	});
	const [deleteChargeLoading, setDeleteChargeLoading] = useState(false);
	const openAlertModal = useCallback((c: any) => {
		setModal({ open: true, item: c });
	}, []);
	let { columns } = useColumns(state?.value, openAlertModal, user);

	const closeModal = useCallback(() => {
		setModal({ open: false });
	}, []);

	const deleteCharge = useCallback(() => {
		if (modal?.item?.id) {
			setDeleteChargeLoading(true);
			dispatch(
				deleteChargeAction.deleteCharge(modal?.item?.id, error => {
					if (error) {
						toastError(
							`Não foi possível excluir o registro de cobrança ${modal?.item?.identifier}. Tente novamente`
						);
						setDeleteChargeLoading(false);
						return;
					}
					toastSuccess(
						`Registro de cobrança ${modal?.item?.identifier} foi excluido com sucesso`
					);
					handleFetchData(table.current.state);
					setDeleteChargeLoading(false);
					setModal({ open: false, item: undefined });
				})
			);
		}
	}, [dispatch, modal?.item?.identifier, modal?.item?.id, handleFetchData]);

	// Prod only
	if (!isInRole(user, ["Admin", "Business Admin"])) {
		columns = columns.filter(c => c.id !== "fee");
	}

	return (
		<div>
			<Helmet>
				<title>
					{ls.charges} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.charges}
				data={state.items}
				pages={state.pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				useQueryString
				loading={state.loading}
				defaultSorted={[
					{
						id: "id",
						desc: true
					}
				]}
			/>

			{isInRole(user, ["Admin", "Business Admin"]) && (
				<Fab
					color="primary"
					aria-label="Add"
					className={classes.fab}
					onClick={() => setShowAdd(true)}
				>
					<MdAdd size={24} />
				</Fab>
			)}

			{isInRole(user, ["Admin", "Business Admin"]) && (
				<AddTransaction
					open={showAdd}
					handleClose={() => {
						handleFetchData(table.current.state);
						setShowAdd(false);
					}}
				/>
			)}
			<DeleteChargeModal
				onPressDelete={deleteCharge}
				isOpen={modal.open}
				closeModal={closeModal}
				item={modal.item}
				loading={deleteChargeLoading}
			/>
		</div>
	);
}

export default ChargeReport;
