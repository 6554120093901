import { Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ModalGeneric from "Components/Modals/ModalGeneric";
import React from "react";

interface TransactionTypeModalProps {
	openTransferTypeModal: boolean;
	handleClose: (
		shouldOpenModalTransferTypeModal?: boolean,
		shouldOpenModalUser?: boolean,
		notOpenModals?: boolean,
		shouldOpenBusinessModal?: boolean
	) => void;
	onClickDeposit: () => void;
	onClickTreasury: () => void;
	loadingFile: boolean;
	shouldDisableTreasury?: boolean;
}
const TransactionTypeModal: React.FC<TransactionTypeModalProps> = ({
	handleClose,
	loadingFile,
	onClickDeposit,
	onClickTreasury,
	openTransferTypeModal,
	shouldDisableTreasury
}) => (
	<ModalGeneric
		open={openTransferTypeModal}
		handleClose={() => handleClose(false, false, true)}
		body={() => (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
					padding: 24
				}}
			>
				<h3 style={{ color: "#707070" }}>O que você deseja fazer?</h3>
				<section>
					{loadingFile ? (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							<Skeleton width={213} height={52} style={{ marginRight: 12 }} />
							<Skeleton width={213} height={52} />
						</div>
					) : (
						<>
							<Button
								color="primary"
								size="large"
								type="button"
								variant="contained"
								disabled={shouldDisableTreasury}
								style={{ marginRight: 12 }}
								onClick={onClickTreasury}
							>
								Usar saldo existente
							</Button>
							<Button
								color="primary"
								size="large"
								type="button"
								variant="contained"
								onClick={onClickDeposit}
							>
								Fazer novo depósito
							</Button>
						</>
					)}
				</section>
			</div>
		)}
	/>
);

export default TransactionTypeModal;
