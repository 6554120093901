import dayjs from "dayjs";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

export const CREATE_CREDIT = "[CREDIT CREATE] CREATE_CREDIT";
export const CREATE_CREDIT_SUCCESS = "[CREDIT CREATE] CREATE_CREDIT_SUCCESS";
export const CREATE_CREDIT_FAILED = "[CREDIT CREATE] CREATE_CREDIT_FAILED";

export const SET_VALUE = "[CREDIT CREATE] SET_VALUE";
export const CLEAR_VALUES = "[CREDIT CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createCredit(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_CREDIT });

		try {
			const { login, creditCreate } = getState();

			const {
				companyId,
				userId,
				expirationDate,
				value,
				users,
				stores,
				categories,
				multiplePeople,
				minValue,
				startDate,
				status,
				origin,
				description
			} = creditCreate;

			const errors: any = {};

			if (!multiplePeople && !userId) errors.userId = ls.required;
			if (multiplePeople && !users.length) errors.filter = ls.required;
			if (!value) errors.value = ls.required;
			if (!status) errors.status = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_CREDIT_FAILED,
					payload: errors
				});
			}

			const model = {
				companyId,
				userId: multiplePeople ? undefined : userId,
				userIds: multiplePeople ? users.map(c => c.userId) : undefined,
				startDate: startDate ? dayjs(startDate).startOf("day") : undefined,
				expirationDate: expirationDate
					? dayjs(expirationDate).endOf("day")
					: undefined,
				stores: stores || undefined,
				categories: categories || undefined,
				value: Math.round(value * 100),
				minValue: Math.round(minValue * 100),
				status,
				origin,
				description
			};

			const response = await api.sendPost("/Credit/Admin", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CREDIT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_CREDIT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_CREDIT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
