import { IconButton } from "@material-ui/core";
import TooltipLight from "Components/TooltipLight";
import React, { useCallback, useMemo, useState } from "react";
import { RxPencil2 } from "react-icons/rx";

import { BackgroundInput, Button, Container, Line } from "./styles";

interface Items {
	document: string;
	editable: boolean;
}
interface PropsEditTable {
	items: Items[];
}

const EditTable: React.FC<PropsEditTable> = ({ items }) => {
	const [data, setData] = useState<Items[]>();

	const dataItems = useMemo(() => {
		setData(items);
		return data || items;
	}, [data, items]);

	const editList = useCallback(
		(id, value, index) => {
			const newData = [...data];

			const objectValue = newData.find((i, ind) => ind === index);

			if (id === "cpf") {
				objectValue.document = value;
			}
			if (id === "edit") {
				objectValue.editable = value;
			}
			setData(newData);
		},
		[data]
	);

	return (
		<Container>
			{dataItems?.map((i, index) => (
				<BackgroundInput isPair={index % 2 === 0} key={index}>
					<Line
						isPair={index % 2 === 0}
						value={i.document}
						disabled={!i.editable}
						isDisable={!i.editable}
						onChange={e => editList("cpf", e.target.value, index)}
					/>
					<div style={{ width: "10%" }}>
						{i.editable ? (
							<Button
								onClick={() => {
									editList("edit", !i.editable, index);
								}}
							>
								OK
							</Button>
						) : (
							<TooltipLight title="Editar CPF" placement="top">
								<IconButton
									style={{ padding: 4 }}
									onClick={() => {
										editList("edit", !i.editable, index);
									}}
								>
									<RxPencil2 size={18} />
								</IconButton>
							</TooltipLight>
						)}
					</div>
				</BackgroundInput>
			))}
		</Container>
	);
};

export default EditTable;
