/* eslint-disable no-case-declarations */
import * as actions from "store/actions/business/edit";
import * as actions_user from "store/actions/user/removeLockout";

import { IBusinessEdit } from "../types/edit";

const initialState: IBusinessEdit = {
	item: null,
	name: "",
	socialName: "",
	cnpj: "",
	isHcmPartial: false,
	phoneNumber: "",
	category: "",
	street: "",
	number: "",
	complement: "",
	neighborhood: "",
	city: "",
	state: "",
	zipCode: "",
	country: "BR",
	status: "",
	type: "",
	teaser: "",
	about: "",
	highlight: false,
	national: false,
	hideContactInfo: false,
	hideLocationInfo: false,
	isStore: false,
	isCompany: false,
	isHeadquarters: false,
	isFranchise: false,
	loading: {},
	errors: {},
	hasWiipoRequest: false,
	hasWiipoClub: false,
	hasWiipoFlex: false,
	hasHCMIntegrator: false,
	treasuryAccountHolderId: "",
	link: "",
	linkType: "",
	percentClubWiipo: 0
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_BUSINESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_SUCCESS: {
			return {
				...state,
				item: payload,
				name: payload.name || initialState.name,
				socialName: payload.socialName || initialState.socialName,
				cnpj: payload.cnpj || initialState.cnpj,
				phoneNumber: payload.phoneNumber || initialState.phoneNumber,
				category: payload.categoryId || initialState.category,
				linkType: payload.linkType,
				link: payload.link,
				street:
					(payload.address && payload.address.street) || initialState.street,
				number:
					(payload.address && payload.address.number) || initialState.number,
				complement:
					(payload.address && payload.address.complement) ||
					initialState.complement,
				neighborhood:
					(payload.address && payload.address.neighborhood) ||
					initialState.neighborhood,
				city: (payload.address && payload.address.city) || initialState.city,
				state: (payload.address && payload.address.state) || initialState.state,
				zipCode:
					(payload.address && payload.address.zipCode) || initialState.zipCode,
				country:
					(payload.address && payload.address.country) || initialState.country,
				status: payload.status || initialState.status,
				type: payload.type || initialState.type,
				about: payload.about || initialState.about,
				highlight: payload.highlight || initialState.highlight,
				national: payload.national || initialState.national,
				hideContactInfo:
					payload.hideContactInfo || initialState.hideContactInfo,
				hideLocationInfo:
					payload.hideLocationInfo || initialState.hideLocationInfo,
				teaser: payload.teaser || initialState.teaser,
				isStore: payload.isStore || initialState.isStore,
				isCompany: payload.isCompany || initialState.isCompany,
				isHeadquarters: payload.isHeadquarters || initialState.isHeadquarters,
				isFranchise: payload.isFranchise || initialState.isFranchise,
				loading: {
					...state.loading,
					get: false
				},
				hasWiipoRequest:
					payload.hasWiipoRequest || initialState.hasWiipoRequest,
				hasWiipoClub: payload.hasWiipoClub || initialState.hasWiipoClub,
				hasWiipoFlex: payload.hasWiipoFlex || initialState.hasWiipoFlex,
				hasHCMIntegrator:
					payload.hasHCMIntegrator || initialState.hasHCMIntegrator,
				percentClubWiipo:
					payload.percentClubWiipo || initialState.percentClubWiipo,
				IsHcmPartial: payload.isHcmPartial || initialState.isHcmPartial,
				treasuryAccountHolderId:
					payload.treasuryAccountHolderId ||
					initialState.treasuryAccountHolderId
			};
		}
		case actions.GET_BUSINESS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.EDIT_BUSINESS: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}
		case actions.EDIT_BUSINESS_SUCCESS: {
			return {
				...initialState,
				loading: {
					...state.loading,
					edit: false
				}
			};
		}
		case actions.EDIT_BUSINESS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: false
				},
				errors: payload
			};
		}

		case actions_user.EDIT_USER_REMOVE_LOCKOUT: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}

		case actions_user.EDIT_USER_REMOVE_LOCKOUT_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}

		case actions_user.EDIT_USER_REMOVE_LOCKOUT_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			if (payload) {
				return { ...initialState };
			}

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let itemState: any = {
				item: state.item,
				loading: {}
			};

			if (state.item) {
				itemState.name = state.item.name || initialState.name;
				itemState.socialName = state.item.socialName || initialState.socialName;
				itemState.cnpj = state.item.cnpj || initialState.cnpj;
				itemState.phoneNumber =
					state.item.phoneNumber || initialState.phoneNumber;
				itemState.category = state.item.categoryId || initialState.category;
				itemState.street =
					(state.item.address && state.item.address.street) ||
					initialState.street;
				itemState.number =
					(state.item.address && state.item.address.number) ||
					initialState.number;
				itemState.complement =
					(state.item.address && state.item.address.complement) ||
					initialState.complement;
				itemState.neighborhood =
					(state.item.address && state.item.address.neighborhood) ||
					initialState.neighborhood;
				itemState.city =
					(state.item.address && state.item.address.city) || initialState.city;
				itemState.state =
					(state.item.address && state.item.address.state) ||
					initialState.state;
				itemState.zipCode =
					(state.item.address && state.item.address.zipCode) ||
					initialState.zipCode;
				itemState.country =
					(state.item.address && state.item.address.country) ||
					initialState.country;

				itemState.link = state.item.link || initialState.link;
				itemState.linkType = state.item.linkType || initialState.linkType;
				itemState.status = state.item.status || initialState.status;
				itemState.type = state.item.type || initialState.type;
				itemState.about = state.item.about || initialState.about;
				itemState.highlight = state.item.highlight || initialState.highlight;
				itemState.national = state.item.national || initialState.national;
				itemState.teaser = state.item.teaser || initialState.teaser;

				itemState.isStore = state.item.isStore || initialState.isStore;
				itemState.isCompany = state.item.isCompany || initialState.isCompany;
				itemState.isHeadquarters =
					state.item.isHeadquarters || initialState.isHeadquarters;
				itemState.isFranchise =
					state.item.isFranchise || initialState.isFranchise;
				itemState.treasuryAccountHolderId =
					state.treasuryAccountHolderId || initialState.treasuryAccountHolderId;
				itemState.hasHCMIntegrator =
					state.item.hasHCMIntegrator || initialState.hasHCMIntegrator;
			}

			return {
				...initialState,
				...itemState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
