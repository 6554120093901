import { verifyOpenRequestCards } from "helpers/openRequestCards";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Router, Switch } from "react-router-dom";

import { isInRole } from "../../../helpers/auth";
import BalanceAccountTypeReport from "../BalanceAccountType";
import BalanceAccountTypeForm from "../BalanceAccountType/Form";
import BusinessCategoryCreate from "../Business/Category/Create";
import BusinessCategoryEdit from "../Business/Category/Edit";
import BusinessCategoryReport from "../Business/Category/Report";
import BusinessCreate from "../Business/Create";
import BusinessCreateBatch from "../Business/CreateBatch";
import BusinessDetail from "../Business/Detail";
import DeliveryAddressesCreate from "../Business/Detail/DeliveryAddress/Create";
import DeliveryAddressesEdit from "../Business/Detail/DeliveryAddress/Edit";
import BusinessEdit from "../Business/Edit";
import BusinessReport from "../Business/Report";
import CashbackReport from "../Cashback/Report";
import ChargeReport from "../Charge/Report";
import CheckoutReport from "../Checkout/Report";
import ConfigurationDetail from "../Configuration/Detail";
import CreditAdd from "../Credit/Add";
import CreditDetail from "../Credit/Detail";
import CreditReport from "../Credit/Report";
import ExportReport from "../ExportReport/Report";
import Home from "../Home";
import InstallmentReport from "../Installment/Report";
import MarketingCreate from "../Marketing/Create";
import MarketingDetail from "../Marketing/Detail";
import MarketingEdit from "../Marketing/Edit";
import MarketingReport from "../Marketing/Report";
import ModerationDetail from "../Moderation/Detail";
import ModerationEdit from "../Moderation/Edit";
import ModerationReport from "../Moderation/Report";
import OrderDetail from "../Order/Detail";
import OrderReport from "../Order/Report";
import TransactionDetail from "../Transaction/Detail";
import TransactionReport from "../Transaction/Report";
import UserDetail from "../Users/Detail";
import UserReport from "../Users/Report";
import CreateEditCampaign from "../WiipoClub/Campaign/Create";
import CampaignReport from "../WiipoClub/Campaign/Report";
import InstallmentWiipoClub from "../WiipoClub/Installment";
import InvoicingClub from "../WiipoClub/Invoicing";
import TransactionWiipoClub from "../WiipoClub/Transaction";
import Invoice from "../WiipoFlex/Invoice";
import Add from "../WiipoFlex/RequestCards/Add";
import AddDetail from "../WiipoFlex/RequestCards/Add/Detail";
import Detail from "../WiipoFlex/RequestCards/Detail";
import ReportRequestCards from "../WiipoFlex/RequestCards/Report";
import TransactionWiipoFlex from "../WiipoFlex/Transaction";
import TreasuryAccountWiipoFlex from "../WiipoFlex/TreasuryAccount/Report";

const AuthRoute = ({ user, needRoles, ...rest }) => {
	/* 	const dispatch = useDispatch();

	const checkUserEmail = useCallback(
		user => {
			const isUserInAllowedTime = isUserInAvailableTime();
			if (process.env.ALLOW_USERS_WITH_NO_VALIDATION === "true") return;
			if (
				user &&
				user?.email &&
				user?.email?.includes("wiipo.com") &&
				isInRole(user, ["Admin"]) &&
				!isUserInAllowedTime
			) {
				toastError(
					"Usuário não autorizado a acessar o sistema fora do horário de expediente"
				);
				dispatch(logoutUser());
			}
		},
		[dispatch]
	);

	useEffect(() => {
		checkUserEmail(user);
	}, [user, checkUserEmail]); */

	if (isInRole(user, needRoles)) return <Route {...rest} />;

	return null;
};

const router = ({ history, login: { user } }: any) => {
	const isAdmin = isInRole(user, ["admin"]);

	return (
		<Router history={history}>
			<Switch>
				{isAdmin && (
					<AuthRoute
						exact
						path="/"
						component={Home}
						user={user}
						needRoles={["admin"]}
					/>
				)}
				<AuthRoute
					exact
					path={["/Store", "/Company", "/Headquarters", "/Franchise"]}
					component={BusinessReport}
					user={user}
					needRoles={["Admin"]}
				/>
				<AuthRoute
					exact
					path="/Configuration"
					component={ConfigurationDetail}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path="/BalanceAccountType"
					component={BalanceAccountTypeReport}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path="/BalanceAccount/Type/Create"
					component={BalanceAccountTypeForm}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path="/BalanceAccountType/:id/Edit"
					component={BalanceAccountTypeForm}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path="/Checkout"
					component={CheckoutReport}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>
				<AuthRoute
					exact
					path="/User"
					component={UserReport}
					user={user}
					needRoles={["Admin", "Business Admin", "Admin Wiipo Flex"]}
				/>
				<AuthRoute
					exact
					path="/Export"
					component={ExportReport}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>
				<AuthRoute
					exact
					path="/User/:id"
					component={UserDetail}
					user={user}
					needRoles={["Admin", "Business Admin", "Admin Wiipo Flex"]}
				/>
				<AuthRoute
					exact
					path="/Store/:id/DeliveryAddress/Create"
					component={DeliveryAddressesCreate}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path="/Store/:id/DeliveryAddress/:itemId/Edit"
					component={DeliveryAddressesEdit}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path={[
						"/Store/Create",
						"/Company/Create",
						"/Headquarters/Create",
						"/Franchise/Create"
					]}
					component={BusinessCreate}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path={[
						"/Store/CreateBatch",
						"/Company/CreateBatch",
						"/Headquarters/CreateBatch",
						"/Franchise/CreateBatch"
					]}
					component={BusinessCreateBatch}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path={["/Store/:id/Edit", "/Company/:id/Edit"]}
					component={BusinessEdit}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path="/Store/Category"
					component={BusinessCategoryReport}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path={[
						"/Store/:id",
						"/Company/:id",
						"/Headquarters/:id",
						"/Franchise/:id"
					]}
					component={BusinessDetail}
					user={user}
					needRoles={["Admin", "Business Admin", "Admin Wiipo Flex"]}
				/>

				<AuthRoute
					exact
					path="/Store/Category/Create"
					component={BusinessCategoryCreate}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Store/Category/:id/Edit"
					component={BusinessCategoryEdit}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Installment"
					component={InstallmentReport}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>

				<AuthRoute
					exact
					path="/InstallmentClub"
					component={InstallmentWiipoClub}
					user={user}
					needRoles={["admin", "Business Admin"]}
				/>

				<AuthRoute
					exact
					path="/TransactionClub"
					component={TransactionWiipoClub}
					user={user}
					needRoles={["admin", "Business Admin"]}
				/>

				<AuthRoute
					exact
					path="/InvoicingClub"
					component={InvoicingClub}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/CampaignClub"
					component={CampaignReport}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path={["/CampaignClub/Create", "/CampaignClub/Edit"]}
					component={CreateEditCampaign}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/TransactionFlex"
					component={TransactionWiipoFlex}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/TreasuryAccountFlex"
					component={TreasuryAccountWiipoFlex}
					user={user}
					needRoles={["Admin", "Admin Wiipo Flex", "Business Admin"]}
				/>

				{/* <AuthRoute
					exact
					path="/Invoice"
					component={Invoice}
					user={user}
					needRoles={["Admin", "Business Admin", "Admin Wiipo Flex"]}
				/> */}

				<AuthRoute
					exact
					path="/Transaction"
					component={TransactionReport}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>
				<AuthRoute
					exact
					path="/Transaction/:id"
					component={TransactionDetail}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>

				<AuthRoute
					exact
					path="/Charge"
					component={ChargeReport}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Order"
					component={OrderReport}
					user={user}
					needRoles={["admin"]}
				/>
				<AuthRoute
					exact
					path="/Order/:id"
					component={OrderDetail}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Marketing"
					component={MarketingReport}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Marketing/Create"
					component={MarketingCreate}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Marketing/:id"
					component={MarketingDetail}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Marketing/:id/Edit"
					component={MarketingEdit}
					user={user}
					needRoles={["admin"]}
				/>

				<AuthRoute
					exact
					path="/Credit"
					component={CreditReport}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>
				<AuthRoute
					exact
					path="/Credit/Add"
					component={CreditAdd}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>
				<AuthRoute
					exact
					path="/Credit/:id"
					component={CreditDetail}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>
				<AuthRoute
					exact
					path="/Cashback"
					component={CashbackReport}
					user={user}
					needRoles={["Admin", "Business Admin"]}
				/>
				{/*
					TODO: Remover condições unitarias de moderação e request cards
					// Correção de bug emergencial
				*/}
				{process.env.REACT_APP_MODERATION_ALLOW_USERS_ID?.split(",")?.includes(
					user.id
				) && (
					<AuthRoute
						exact
						path={["/Moderation"]}
						component={ModerationReport}
						user={user}
						needRoles={["Admin", "Business Admin"]}
					/>
				)}

				{process.env.REACT_APP_MODERATION_ALLOW_USERS_ID?.split(",")?.includes(
					user.id
				) && (
					<AuthRoute
						exact
						path="/Moderation/:id"
						component={ModerationDetail}
						user={user}
						needRoles={["Admin", "Business Admin"]}
					/>
				)}

				{process.env.REACT_APP_MODERATION_ALLOW_USERS_ID?.split(",")?.includes(
					user.id
				) && (
					<AuthRoute
						exact
						path="/Moderation/:id/edit"
						component={ModerationEdit}
						user={user}
						needRoles={["Admin", "Business Admin"]}
					/>
				)}

				{verifyOpenRequestCards(user?.id) && (
					<AuthRoute
						exact
						path="/RequestCards"
						component={ReportRequestCards}
						user={user}
						needRoles={["Admin"]}
					/>
				)}

				{verifyOpenRequestCards(user?.id) && (
					<AuthRoute
						exact
						path="/RequestCards/Add"
						component={Add}
						user={user}
						needRoles={["Admin"]}
					/>
				)}

				{verifyOpenRequestCards(user?.id) && (
					<AuthRoute
						exact
						path="/RequestCards/Add/Detail"
						component={AddDetail}
						user={user}
						needRoles={["Admin"]}
					/>
				)}

				{verifyOpenRequestCards(user?.id) && (
					<AuthRoute
						exact
						path="/RequestCards/:id/Detail"
						component={Detail}
						user={user}
						needRoles={["Admin"]}
					/>
				)}

				<Redirect to={isAdmin ? "/" : "/Installment"} />
			</Switch>
		</Router>
	);
};

const mapStateToProps = ({ login }) => ({ login });

export default connect(mapStateToProps, null)(router);
