import { getErrors } from "helpers/error";
import * as api from "services/api";

export const SET_VALUE = "[BUSINESS POLICY] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS POLICY] CLEAR_VALUES";

export const UPLOAD_BUSINESS_POLICY_REQUEST =
	"[BUSINESS POLICY] UPLOAD_BUSINESS_POLICY_REQUEST";
export const UPLOAD_BUSINESS_POLICY_SUCCESS =
	"[BUSINESS POLICY] UPLOAD_BUSINESS_POLICY_SUCCESS";
export const UPLOAD_BUSINESS_POLICY_FAILED =
	"[BUSINESS POLICY] UPLOAD_BUSINESS_POLICY_FAILED";

export const UPLOAD_BUSINESS_POLICY_TRANSFER_REQUEST =
	"[BUSINESS POLICY] UPLOAD_BUSINESS_POLICY_TRANSFER_REQUEST";
export const UPLOAD_BUSINESS_POLICY_TRANSFER_SUCCESS =
	"[BUSINESS POLICY] UPLOAD_BUSINESS_POLICY_TRANSFER_SUCCESS";
export const UPLOAD_BUSINESS_POLICY_TRANSFER_FAILED =
	"[BUSINESS POLICY] UPLOAD_BUSINESS_POLICY_TRANSFER_FAILED";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function updateBusinessPolicy(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPLOAD_BUSINESS_POLICY_REQUEST });
		try {
			const { login, businessDetail } = getState();
			const { item } = businessDetail;
			let response = await api.sendPut(
				`/Business/${item.id}/PrePaid/BalanceAccount/Type`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPLOAD_BUSINESS_POLICY_SUCCESS
				});
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPLOAD_BUSINESS_POLICY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			dispatch({
				type: UPLOAD_BUSINESS_POLICY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
			callback && callback(error);
		}
	};
}

export function updateBusinessPolicyTransfer(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPLOAD_BUSINESS_POLICY_TRANSFER_REQUEST });
		try {
			const { login, businessDetail } = getState();
			const { item } = businessDetail;

			let response = await api.sendPut(
				`/Business/${item.id}/PrePaid/BalanceAccount/Type/Transfer`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPLOAD_BUSINESS_POLICY_TRANSFER_SUCCESS
				});
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPLOAD_BUSINESS_POLICY_TRANSFER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			dispatch({
				type: UPLOAD_BUSINESS_POLICY_TRANSFER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
			callback && callback(error);
		}
	};
}
