import * as actions from "store/actions/requestCards/detail";

import { IInitialState } from "../types";

const initialState: IInitialState = {
	request: {
		businessId: "",
		quantityCard: 0,
		street: "",
		document: "",
		zipcode: "",
		name: "",
		number: null,
		neighborhood: "",
		complement: "",
		city: "",
		state: "",
		phone: "",
		email: ""
	},
	cnpj: "",
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_REQUEST_CARDS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_REQUEST_CARDS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				request: payload
			};
		}
		case actions.GET_REQUEST_CARDS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
