import { ToastOptions } from "react-toastify";

import { toastError, toastSuccess } from "./toast";

export function toStringCurrency(value: number) {
	return value.toLocaleString("pt-BR", {
		currency: "BRL",
		currencyDisplay: "symbol",
		style: "currency",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	});
}

export const copyTextToClipboard = (
	handleEnableIconCopy: () => void,
	text: string,
	msgToast = "Copiado com sucesso",
	config?: ToastOptions<{}>
) => {
	navigator.clipboard
		.writeText(text)
		.then(() => {
			handleEnableIconCopy();
			toastSuccess(msgToast, config);
		})
		.catch(err => {
			toastError("Error ao copiar ", err);
		});
};

export function toStringPercentage(
	value: number,
	value2: number,
	decimals = 2
) {
	let v = (value * 100) / value2;
	// eslint-disable-next-line no-restricted-globals
	if (isNaN(v)) v = 0;
	return `${v.toFixed(decimals)}%`;
}

export function stringSort(a: any, b: any, desc: boolean) {
	// force null and undefined to the bottom
	a = a === null || a === undefined ? "" : a;
	b = b === null || b === undefined ? "" : b;
	// force any string values to lowercase
	a = typeof a === "string" ? a.toLowerCase() : a;
	b = typeof b === "string" ? b.toLowerCase() : b;
	// Return either 1 or -1 to indicate a sort priority
	if (a > b) {
		return 1;
	}
	if (a < b) {
		return -1;
	}
	// returning 0, undefined or any falsey value will use subsequent sorts or
	// the index as a tiebreaker
	return 0;
}

export function getTimeString(time: number, fullText = false) {
	if (time >= 60 * 24) {
		let hours = time / 60;
		let dayFull = hours / 24;
		let day = Math.trunc(dayFull);
		let hour = Math.round(24 * (dayFull - day));

		return `${day} dia${day > 1 ? "s" : ""}${
			hour > 0
				? ` e ${hour} hora${hour > 1 ? "s" : ""}`
				: ` út${day > 1 ? "eis" : "il"}`
		}`;
	}

	if (time > 60) {
		let hourFull = time / 60;
		let hour = Math.trunc(hourFull);
		let min = Math.round(60 * (hourFull - hour));

		return `${hour} hora${hour > 1 ? "s" : ""}${
			min > 0
				? ` e ${min} ${fullText ? `minuto${min > 1 ? "s" : ""}` : "min"}`
				: ""
		}`;
	}

	return `${time} ${fullText ? `minuto${time > 1 ? "s" : ""}` : "min"}`;
}

export function removeDiacritics(string: string) {
	return String(string)
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "");
}
