import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { cnpjMask, cpfMask } from "helpers/format";
import { toStringCurrency } from "helpers/string";
import { CNPJValidation, CPFValidation } from "helpers/validators";
import {
	ITransactionImportSendRequest,
	IValidateTransactionImportRequest
} from "store/actions/transaction/import";

require("dayjs/locale/br");

dayjs.extend(customParseFormat);

export function getValue(c: string) {
	let value = String(c);

	if (value.indexOf("R$") !== -1) {
		value = value.replace("R$", "").trim();
	}

	if (value) {
		while (value.split(/[.,]/).length > 2) {
			value = value.replace(".", "");
		}

		while (value.indexOf(",") !== -1) {
			let index = value.indexOf(",");

			if (index >= value.length - 3) {
				value = value.replace(",", ".");
			} else {
				value = value.replace(",", "");
			}
		}
	}

	return Number(value);
}

export function extractTransactionsFromJson(
	json: any[],
	errorCallback: (msg: string) => void
): any[] {
	const transactions = [];
	for (const item of json) {
		let cpf = String(item["CPF do cliente"]).match(/\d/g)?.join("");
		const row = json.indexOf(item) + 2;

		if (!cpf) {
			errorCallback(`CPF da linha ${row} não preenchido, é obrigatório`);
			return [];
		}

		cpf = cpf.padStart(11, "0");

		if (!CPFValidation(cpf)) {
			errorCallback(`CPF ${cpfMask(cpf)} da linha ${row} não é válido`);
			return [];
		}

		let creationDate = item["Data da criação"].toString();

		dayjs.locale("br");

		const formattedCreationDate = dayjs(creationDate, "DD/MM/YYYY - HH:mm")
			.locale("br")
			.format("DD/MM/YYYY - HH:mm");

		if (creationDate !== formattedCreationDate) {
			errorCallback(
				`Data da criação ${creationDate} na linha ${row} é inválida: diferente de ${formattedCreationDate}`
			);
			creationDate = formattedCreationDate;
			return [];
		}

		let cnpj = String(item["CNPJ da loja"]).match(/\d/g)?.join("");

		if (!cnpj) {
			errorCallback(`CNPJ da linha ${row} não preenchido, é obrigatório`);
			return [];
		}

		cnpj = cnpj.padStart(14, "0");

		if (!CNPJValidation(cnpj)) {
			errorCallback(`CNPJ ${cnpjMask(cnpj)} da linha ${row} não é valido`);
			return [];
		}

		let installmentAmount = item["Parcelas (opcional)"];

		const notNumbersFound = installmentAmount?.match(/\D+/g)?.join("");

		if (notNumbersFound) {
			errorCallback(
				`A parcela '${notNumbersFound}' na linha ${row} não é valida. \n\nDica: Defina um número ou deixe em branco.`
			);
			return [];
		}

		const MAX_INSTALLMENT_AMOUNT = 12;
		if (installmentAmount > MAX_INSTALLMENT_AMOUNT) {
			errorCallback(
				`A parcela '${installmentAmount}' na linha ${row} é maior que o limite permitido: ${MAX_INSTALLMENT_AMOUNT}.`
			);
			return [];
		}

		let value = getValue(item["Valor"]);

		if (!value) {
			errorCallback(`O valor na linha ${row} não é valido`);
			return [];
		}

		if (value <= 0) {
			errorCallback(`O valor na linha ${row} deve ser maior que zero`);
			return [];
		}

		if ((value * 100) / installmentAmount < 1) {
			errorCallback(
				`O valor ${value} na linha ${row} deve ser igual ou maior que ${
					installmentAmount / 100
				}`
			);
			return [];
		}

		transactions.push({
			creationDate,
			cpf: cpfMask(cpf),
			storeCnpj: cnpjMask(cnpj),
			value: toStringCurrency(value),
			installmentAmount,
			description: item["Descrição (opcional)"]
		});
	}
	return transactions;
}

export interface ITransactionWithValidation {
	creationDate: string;
	value: number;
	installmentAmount: number;
	description: string;
	isValidRow: boolean;
	cpf: string;
	isValidCpf: boolean;
	storeCnpj: string;
	isValidCnpj: boolean;
}

export function mergeTransactionsWithValidations(
	transactions: ITransactionImportSendRequest[],
	validations: IValidateTransactionImportRequest[]
): ITransactionWithValidation[] {
	return transactions.map((t, i) => ({
		isValidRow: validations[i].isValidRow,
		creationDate: t.creationDate,
		cpf: t.cpf,
		isValidCpf: validations[i].isValidCpf,
		storeCnpj: t.storeCnpj,
		isValidCnpj: validations[i].isValidCnpj,
		value: t.value,
		installmentAmount: t.installmentAmount,
		description: t.description
	}));
}
