import { Button, Grid } from "@material-ui/core";
import ModalGeneric from "Components/Modals/ModalGeneric";
import { MONEY } from "Components/NumberFormat";
import Spinner from "Components/Spinner";
import TextInputGeneric from "Components/TextInputGeneric";
import { decimalValueTrunc } from "helpers/decimalsNumber";
import { cnpjMask } from "helpers/format";
import { toStringCurrency } from "helpers/string";
import { toastError, toastSuccess, toastWarning } from "helpers/toast";
import { CNPJValidation } from "helpers/validators";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "store/actions/business/report";
import { getPmaBalance } from "store/actions/productManagerAccount";
import { createTransaction } from "store/actions/treasuryAccountTransfer/create";

import ModalConfirmation from "./ModalConfirmation";
import {
	BackgroundButton,
	BoxCard,
	ButtonSelection,
	ColumnCard,
	Container,
	Footer,
	Label,
	MoneyCard,
	TitleCard,
	TitleContainer
} from "./styles";

interface IPropsModalCreateTransfer {
	open: boolean;
	defaultValue?: any;
	handleClose: (success?: boolean) => void;
}

let debouncedCreateTransfer: (() => void) & _.Cancelable = null;
let getBusinessDebounced: (() => void) & _.Cancelable = null;

const ModalCreateTransfer: React.FC<IPropsModalCreateTransfer> = ({
	open,
	handleClose,
	defaultValue
}) => {
	const dispatch = useDispatch();

	const { balance, loading: loadingBalance } = useSelector<any, any>(
		s => s.productManagerAccount
	);

	const { totalBalance } = useSelector<any, any>(
		s => s.treasuryAccountWiipoFlexDeposit
	);

	const { loading: businessLoading } = useSelector<any, any>(
		s => s.businessReport
	);

	const [cnpj, setCnpj] = useState("");
	const [business, setBusiness] = useState(null);

	const businessId = business?.id;

	useEffect(() => {
		if (open) {
			dispatch(getPmaBalance());

			if (defaultValue) {
				setBusiness(defaultValue);

				setCnpj(defaultValue.cnpj);
			}
		}
	}, [defaultValue, dispatch, open]);

	const onLoadCNPJ = useCallback(
		value => {
			if (getBusinessDebounced) {
				getBusinessDebounced.cancel();
			}

			getBusinessDebounced = _.debounce(
				() =>
					dispatch(
						getBusinesses(
							null,
							null,
							`&filters[cnpj]=${value}`,
							null,
							null,
							null,
							(err, models) => {
								if (err) {
									toastError(err);
								} else {
									if (models.length <= 0) {
										toastError("CNPJ não encontrado");
									} else {
										setBusiness(models[0]);
									}
									setLoading(false);
								}
							}
						)
					),
				500
			);

			if (CNPJValidation(value)) {
				setLoading(true);
				getBusinessDebounced();
			}

			setLoading(false);
		},
		[dispatch]
	);

	const [value, setValue] = useState(0);
	const [type, setType] = useState(0);
	const [description, setDescription] = useState(
		"Transferência da Wiipo para tesouraria"
	);
	const [loading, setLoading] = useState(false);
	const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

	const handleOpenModal = useCallback(() => {
		if (value) {
			setOpenModalConfirmation(true);
		} else {
			toastWarning("Valor terá que ser maior que 0");
		}
	}, [value]);

	const handleCloseModal = useCallback(() => {
		if (!loading) {
			setOpenModalConfirmation(false);
		}
	}, [loading]);

	const isCleanInput = useMemo(() => {
		if (
			description === "Transferência da Wiipo para tesouraria" ||
			description === "Transferência da tesouraria para Wiipo"
		) {
			return true;
		}

		return false;
	}, [description]);
	const shouldDisableTransference = useMemo(() => {
		if (
			type === 1 &&
			decimalValueTrunc(value) > business?.treasuryAccountBalance
		)
			return true;
		if (
			type === 0 &&
			(decimalValueTrunc(value) > balance ||
				decimalValueTrunc(value) > totalBalance)
		)
			return true;
		return false;
	}, [value, business?.treasuryAccountBalance, type, balance, totalBalance]);

	const onCreateTransfer = useCallback(() => {
		if (debouncedCreateTransfer) {
			debouncedCreateTransfer.cancel();
		}
		setLoading(true);
		debouncedCreateTransfer = _.debounce(
			() =>
				dispatch(
					createTransaction({ value, businessId, type, description }, err => {
						if (err) {
							toastError(err);
						} else {
							toastSuccess("Transferência realizada com sucesso");
							setValue(0);
							setType(0);
							setDescription("Transferência da Wiipo para tesouraria");
							setBusiness(null);
							setLoading(false);
							handleCloseModal();
							handleClose(true);
						}
					})
				),
			500
		);

		debouncedCreateTransfer();
	}, [
		businessId,
		description,
		dispatch,
		handleClose,
		handleCloseModal,
		type,
		value
	]);

	const handleCnpjErrorMessage = useCallback(cnpj => {
		if (cnpj.length < 14) {
			return (
				<p style={{ width: "100%", textAlign: "center", color: "red" }}>
					O CNPJ deve conter 14 dígitos
				</p>
			);
		}
		if (!CNPJValidation(cnpj)) {
			return (
				<p style={{ width: "100%", textAlign: "center", color: "red" }}>
					O CNPJ é inválido
				</p>
			);
		}
		return false;
	}, []);

	return (
		<ModalGeneric
			open={open}
			handleClose={handleClose}
			body={() => (
				<Container>
					<TitleContainer>{ls.newTransfer}</TitleContainer>
					<Grid container spacing={2}>
						<Grid item xs={4} sm={2} md={4} lg={4}>
							<Label style={{ marginRight: 10 }}>Tipo de transferência</Label>
						</Grid>

						<Grid item xs={12} sm={5} md={3} lg={3}>
							<ButtonSelection
								style={{ marginRight: 10 }}
								selected={type + 1 === 1}
								onClick={() => {
									setDescription("Transferência da Wiipo para tesouraria");
									setType(0);
								}}
							>
								{"Wiipo > Tesouraria"}
							</ButtonSelection>
						</Grid>

						<Grid item xs={12} sm={5} md={3} lg={3}>
							<ButtonSelection
								selected={type + 1 === 2}
								onClick={() => {
									setDescription("Transferência da tesouraria para Wiipo");
									setType(1);
								}}
							>
								{"Tesouraria > Wiipo"}
							</ButtonSelection>
						</Grid>
					</Grid>

					<Grid container>
						<Grid item xs={12}>
							{cnpj && handleCnpjErrorMessage(cnpj)}
						</Grid>
					</Grid>

					<Grid
						container
						spacing={2}
						style={{ marginTop: 10, marginBottom: -80 }}
					>
						<Grid item xs={4}>
							<Label style={{ marginRight: 15, marginLeft: 5 }}>
								{ls.cnpj}
							</Label>
						</Grid>
						<Grid item xs={6}>
							<TextInputGeneric
								id="cnpj"
								variant="filled"
								placeholder="Digite o CNPJ"
								InputProps={{
									disableUnderline: true,
									style: {
										paddingBottom: 13,
										height: 40,
										backgroundColor: "#fff",
										borderBlockColor: "#8A8A8A",
										borderStyle: "solid",
										borderWidth: 1,
										borderRadius: 6,
										marginBottom: 10
									}
								}}
								value={cnpjMask(cnpj)}
								onChange={(id, value) => {
									const onlyNumbersValue = value.replace(/[^\d]+/g, "");
									setCnpj(onlyNumbersValue);
									onLoadCNPJ(onlyNumbersValue);
								}}
								disabled={Boolean(defaultValue)}
								size="small"
							/>
						</Grid>

						<Grid item xs={4}>
							<Label style={{ marginRight: 15, marginLeft: 5 }}>
								{ls.business}
							</Label>
						</Grid>

						<Grid item xs={6}>
							<TextInputGeneric
								id="business"
								variant="filled"
								InputProps={{
									disableUnderline: true,
									style: {
										paddingBottom: 13,
										height: 40,
										backgroundColor: "#F6F6F6",
										borderBlockColor: "#707070",
										borderStyle: "solid",
										borderWidth: 1,
										borderRadius: 6,
										marginBottom: 10
									}
								}}
								value={business?.name || "-"}
								disabled
								size="small"
							/>
						</Grid>

						<Grid item xs={4}>
							<Label
								style={{ marginRight: 15, marginLeft: 5, marginBottom: 90 }}
							>
								{`${ls.id.toUpperCase()} ${ls.treasuryAccountPTBR.toLocaleLowerCase()}`}
							</Label>
						</Grid>

						<Grid item xs={6}>
							<TextInputGeneric
								id="treasury"
								variant="filled"
								InputProps={{
									disableUnderline: true,
									style: {
										paddingBottom: 13,
										height: 40,
										backgroundColor: "#F6F6F6",
										borderBlockColor: "#707070",
										borderStyle: "solid",
										borderWidth: 1,
										borderRadius: 6,
										marginBottom: 10
									}
								}}
								value={business?.treasuryAccountHolderId || "-"}
								disabled
								size="small"
							/>
						</Grid>
					</Grid>

					<Grid container spacing={2} style={{ justifyContent: "center" }}>
						<Grid item xs={12} sm={5} md={4} lg={4}>
							<BoxCard>
								<ColumnCard>
									<TitleCard>Saldo na Wiipo</TitleCard>
									<MoneyCard>
										{loadingBalance.balance ? (
											<Spinner size={16} />
										) : (
											toStringCurrency((balance || totalBalance) / 100)
										)}
									</MoneyCard>
								</ColumnCard>
							</BoxCard>
						</Grid>

						<Grid item xs={12} sm={5} md={4} lg={4}>
							<BoxCard>
								<ColumnCard>
									<TitleCard>Saldo atual tesouraria</TitleCard>
									<MoneyCard>
										{toStringCurrency(
											(business?.treasuryAccountBalance || 0) / 100
										)}
									</MoneyCard>
								</ColumnCard>
							</BoxCard>
						</Grid>
					</Grid>

					<Footer>
						<Grid container>
							<Grid item xs={4}>
								<Label style={{ marginRight: 15, marginLeft: 5 }}>
									{ls.value}
								</Label>
							</Grid>

							<Grid item xs={6}>
								<TextInputGeneric
									id="value"
									variant="filled"
									placeholder="R$00.00"
									InputProps={{
										inputComponent: MONEY,
										disableUnderline: true,
										style: {
											paddingBottom: 13,
											height: 40,
											backgroundColor: "#fff",
											borderBlockColor: "#707070",
											color: "#707070",
											borderStyle: "solid",
											borderWidth: 1,
											borderRadius: 6,
											marginBottom: 10
										}
									}}
									onFocus={e => e.target.select()}
									disabled={false}
									value={value}
									size="small"
									onChange={(id, value) => setValue(Number(value))}
								/>
							</Grid>

							<Grid item xs={4}>
								<Label style={{ marginRight: 15, marginLeft: 5 }}>
									{ls.description}
								</Label>
							</Grid>

							<Grid item xs={6}>
								<TextInputGeneric
									id="description"
									variant="filled"
									InputProps={{
										disableUnderline: true,
										style: {
											paddingBottom: 13,
											height: 40,
											backgroundColor: "#fff",
											borderBlockColor: "#707070",
											color: "#707070",
											borderStyle: "solid",
											borderWidth: 1,
											borderRadius: 6,
											marginBottom: 10
										}
									}}
									onFocus={() => {
										isCleanInput && setDescription("");
									}}
									value={description}
									onChange={(id, value) => setDescription(value)}
									disabled={false}
									size="small"
								/>
							</Grid>
						</Grid>
					</Footer>

					<BackgroundButton>
						<Button
							color="primary"
							size="small"
							variant="contained"
							type="submit"
							onClick={handleOpenModal}
							style={{
								fontSize: 16,
								borderRadius: 6,
								width: 160,
								marginTop: 20,
								marginBottom: 40,
								height: 35
							}}
							disabled={shouldDisableTransference || loading || !business}
						>
							{loading || businessLoading ? (
								<Spinner color="inherit" size={18} />
							) : (
								ls.transfer
							)}
						</Button>
					</BackgroundButton>
					<ModalConfirmation
						open={openModalConfirmation}
						loading={loading}
						handleClose={handleCloseModal}
						onCreateTransfer={onCreateTransfer}
						business={business?.socialName}
						businessId={businessId}
						cnpj={cnpj}
						type={type}
						value={value}
					/>
				</Container>
			)}
		/>
	);
};

export default ModalCreateTransfer;
