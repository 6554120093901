import { Grid, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import Spinner from "Components/Spinner";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteProductCategory,
	getProductCategories,
	getProductCategory,
	setValue
} from "store/actions/productCategory";

import CreateForm from "../Forms/Create";
import EditForm from "../Forms/Edit";
import styles from "./styles";

let getCategoriesDebounced: (() => void) & _.Cancelable = null;

function ProductCategories({ business }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const [loadingSub, setLoadingSub] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);

	const state = useSelector<any, any>(c => c.productCategory);

	const loadData = useCallback(() => {
		dispatch(
			getProductCategories(
				null,
				null,
				`&filters[businessId]=${business.id}`,
				null,
				null,
				null,
				null
			)
		);
		setIsLoaded(true);
	}, [dispatch, business.id]);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(id, include, item) => {
			setLoadingSub({ [id]: item.id });
			dispatch(
				getProductCategory(item.id, (err, model) => {
					if (err) {
						console.log(err);

						alert(err);
					} else {
						handleChange(id, Boolean(model));
					}
					setLoadingSub({ [id]: false });
				})
			);
		},
		[dispatch, handleChange]
	);

	const handleDelete = useCallback(
		(id: string) => {
			dispatch(
				deleteProductCategory(id, err => {
					if (err) {
						console.log(err);

						alert(err.default);
					}
				})
			);
		},
		[dispatch]
	);

	const handleFetchData = useCallback(
		(tableState, _instance) => {
			if (!isLoaded) {
				return;
			}
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getCategoriesDebounced) {
				getCategoriesDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: creationDate.value.startDate
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: creationDate.value.endDate
					});
			}

			getCategoriesDebounced = _.debounce(
				() =>
					dispatch(
						getProductCategories(
							page * pageSize,
							pageSize,
							`&filters[businessId]=${business.id}${filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								""
							)}`,
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getCategoriesDebounced();
		},
		[business.id, dispatch, isLoaded]
	);

	const columns = useMemo(
		() => [
			{
				Header: "Id",
				id: "id",
				accessor: c => (c.id ? c.id : "Não informado"),
				filterable: false,
				width: 300
			},
			{
				Header: ls.name,
				id: "name",
				accessor: c => (c.name ? c.name : "Não informado")
			},
			{
				Header: ls.actions,
				id: "actions",
				filterable: false,
				accessor: c => (
					<Grid container spacing={2} justify="center">
						<Grid item>
							{loadingSub["showEditFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showEditFormModal", undefined, c)}
								>
									<MdEdit />
								</IconButton>
							)}
							<IconButton
								style={{
									padding: 8,
									fontSize: "1.3rem"
								}}
								onClick={() => handleDelete(c.id)}
							>
								<MdDelete />
							</IconButton>
						</Grid>
					</Grid>
				),
				width: 200
			}
		],
		[handleDelete, handleEdit, loadingSub]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state, null),
		[handleFetchData]
	);

	return (
		<LoadOnScroll load={loadData}>
			<Report
				manual
				tableRef={table}
				title={ls.productCategories}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				headerRightComponent={
					<div>
						<IconButton
							color="primary"
							onClick={() => handleChange("showFormModal", true)}
						>
							<FaPlus />
						</IconButton>
					</div>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>

			<CreateForm load={load} />
			<EditForm load={load} />
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(ProductCategories);
