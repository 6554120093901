/* eslint-disable react-hooks/exhaustive-deps */
import {
	Backdrop,
	Button,
	Grid,
	IconButton,
	Modal,
	Typography
} from "@material-ui/core";
import { Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { stringSort } from "helpers/string";
import ls from "Localization";
import React, { useEffect, useState } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { FixedSizeList } from "react-window";

import { renderRow } from "./Item";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

interface IModalSelector {
	open: boolean;
	loading?: any;
	data?: any[];
	id: string;
	onSubmit?: any;
	handleClose?: any;
	title: string;
}

const handleFilter = (c, value) =>
	`${c.name.toLowerCase()}`.indexOf(value.toLowerCase()) !== -1;

export default function ModalSelector({
	open,
	loading,
	id,
	data,
	onSubmit,
	handleClose,
	title
}: IModalSelector) {
	const classes = useStyles({});

	const [filterIn, setFilterIn] = useState("");
	const [filterOut, setFilterOut] = useState("");
	const [options, setOptions] = useState([]);

	useEffect(() => {
		setOptions(data);
	}, [data]);

	const handleSubmit = e => {
		e.preventDefault();

		onSubmit(options);
	};

	const handleRight = () => {
		options.forEach(e => {
			e.status = 1;
		});

		setOptions([...options]);
	};

	const handleLeft = () => {
		options.forEach(e => {
			e.status = 0;
		});

		setOptions([...options]);
	};

	const handleChangeItems = (
		items: any[],
		index: number,
		id: string,
		value: any
	) => {
		let item = options.find(c => c.id === items[index].id);

		item[id] = value;

		setOptions([...options]);
	};

	let dataIn = (options || [])
		.sort((a, b) => stringSort(a.name, b.name, false))
		.filter(c => c.status === 1)
		.filter(c => handleFilter(c, filterIn));

	let dataNotIn = (options || [])
		.sort((a, b) => stringSort(a.name, b.name, false))
		.filter(c => c.status === 0)
		.filter(c => handleFilter(c, filterOut));

	return (
		<Modal
			aria-labelledby={`${id}-modal-title`}
			aria-describedby={`${id}-modal-description`}
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls[title] || title}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={5}>
									<Typography style={{ marginLeft: 16 }} variant="body2">
										Desativados
									</Typography>
									<TextInput
										id="filterOut"
										variant="standard"
										name="filterOut"
										hideLabel
										placeholder="Filtro"
										value={filterOut}
										onChange={(id, v) => setFilterOut(v)}
									/>
									<FixedSizeList
										height={400}
										itemSize={36}
										itemCount={dataNotIn.length}
									>
										{props => renderRow(handleChangeItems, dataNotIn, props)}
									</FixedSizeList>
								</Grid>
								<Grid
									item
									xs={2}
									container
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<Grid
										item
										xs={12}
										style={{ display: "flex", justifyContent: "center" }}
									>
										<IconButton
											disabled={!dataNotIn.length}
											onClick={handleRight}
										>
											<AiOutlineDoubleRight />
										</IconButton>
									</Grid>
									<Grid
										item
										xs={12}
										style={{ display: "flex", justifyContent: "center" }}
									>
										<IconButton disabled={!dataIn.length} onClick={handleLeft}>
											<AiOutlineDoubleLeft />
										</IconButton>
									</Grid>
								</Grid>
								<Grid item xs={5}>
									<Typography style={{ marginLeft: 16 }} variant="body2">
										Ativos
									</Typography>
									<TextInput
										id="filterIn"
										variant="standard"
										name="filterIn"
										hideLabel
										placeholder="Filtro"
										value={filterIn}
										onChange={(id, v) => setFilterIn(v)}
									/>
									<FixedSizeList
										height={400}
										itemSize={36}
										itemCount={dataIn.length}
									>
										{props => renderRow(handleChangeItems, dataIn, props)}
									</FixedSizeList>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={
										loading.getAll || loading.get || loading.updateBusinesses
									}
								>
									{ls.save}
									{loading.updateBusinesses ? (
										<Spinner color="secondary" size={16} />
									) : null}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}
