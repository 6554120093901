import { getErrors } from "helpers/error";
import * as api from "services/api";

export const GET_CAMPAIGN = "[CAMPAIGN DETAIL] GET_CAMPAIGN";
export const GET_CAMPAIGN_SUCCESS = "[CAMPAIGN DETAIL] GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAILED = "[CAMPAIGN DETAIL] GET_CAMPAIGN_FAILED";

export const DELETE_CAMPAIGN = "[CAMPAIGN DETAIL] DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCESS =
	"[CAMPAIGN DETAIL] DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILED =
	"[CAMPAIGN DETAIL] DELETE_CAMPAIGN_FAILED";

export const GET_CAMPAIGNS = "[CAMPAIGN DETAIL] GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "[CAMPAIGN DETAIL] GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILED = "[CAMPAIGN DETAIL] GET_CAMPAIGNS_FAILED";

export const SET_VALUE = "[CAMPAIGN DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CAMPAIGN DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCampaigns(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CAMPAIGNS });

		try {
			const { login } = getState();
			let response = await api.sendGet("/campaign", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CAMPAIGNS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CAMPAIGNS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_CAMPAIGNS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getCampaign(id: string, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CAMPAIGN });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/campaign/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CAMPAIGN_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CAMPAIGN_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_CAMPAIGN_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteCampaign(id: string, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_CAMPAIGN });

		try {
			const { login } = getState();
			await api.sendDelete(`/campaign/${id}`, {
				Authorization: "Bearer " + login.token
			});

			dispatch({
				type: DELETE_CAMPAIGN_SUCCESS
			});

			callback && callback(null);
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: DELETE_CAMPAIGN_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
