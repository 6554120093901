import { Button, Card, CardContent, Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import Spinner from "Components/Spinner";
import Tooltip from "Components/Tooltip";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { validateEmail } from "../../helpers/email";
import ls from "../../Localization";
import { handleChangePassword, setValue } from "../../store/actions/login";
import useStyles from "./styles";

export function ChangePassword() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const change = (name: string, value: string) => {
		const values: any = { [name]: value };

		if (name === "recoverEmail") {
			values.showCodeInput = false;
		}

		dispatch(setValue(values));
	};

	useEffect(() => {
		// dispatch(clearValues());
		dispatch(setValue({ loading: { recovery: false } }));
	}, [dispatch]);

	const form = useRef<HTMLFormElement>(null);

	const onSubmit = e => {
		e.preventDefault();
		if (!form.current.checkValidity()) {
			form.current.reportValidity();
		} else {
			dispatch(handleChangePassword());
		}
	};

	const { recoverEmail, password, confirmPassword, loading, errors } =
		useSelector<any, any>(s => s.login);

	return (
		<div className={classes.root}>
			<Helmet>
				<title>
					{ls.changePassword} | {ls.appName}
				</title>
			</Helmet>
			<Grid
				className={clsx(classes.container, "card-box")}
				container
				justify="center"
				alignItems="center"
			>
				<Card className={classes.card}>
					<Grid container className={classes.logoContainer} justify="center">
						<Grid item className="panel-heading">
							<img
								src="/img/logo_wiipo_color.png"
								style={{ width: 250 }}
								alt="logo"
							/>
						</Grid>
					</Grid>
					<CardContent>
						<form className="form-signin" ref={form} onSubmit={onSubmit}>
							<Grid container justify="center">
								<Grid item xs={12}>
									<TextField
										id="password"
										label="Nova senha"
										type="password"
										className={classes.textField}
										value={password}
										onChange={e => change("password", e.target.value)}
										margin="normal"
										fullWidth
										required
										error={errors["password"] !== undefined}
										helperText={errors["password"]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="confirmPassword"
										label="Confirme a nova senha"
										type="password"
										className={classes.textField}
										value={confirmPassword}
										onChange={e => change("confirmPassword", e.target.value)}
										margin="normal"
										fullWidth
										required
										error={errors["confirmPassword"] !== undefined}
										helperText={errors["confirmPassword"]}
									/>
								</Grid>
								<Grid item md={8}>
									<Tooltip title="Preencha os campos corretamente para ativar o botão">
										<Button
											type="submit"
											className={classes.submitButton}
											disabled={
												!recoverEmail ||
												!validateEmail(recoverEmail) ||
												loading.recovery ||
												password !== confirmPassword
											}
										>
											{ls.changePassword}
											{loading.recovery ? (
												<Spinner style={{ height: 15, width: 15, margin: 5 }} />
											) : null}
										</Button>
									</Tooltip>
								</Grid>
								<Grid item xs={12} className={classes.newAccount}>
									<Link to="/login">{ls.backToLogin}</Link>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Grid>
		</div>
	);
}

export default ChangePassword;
