import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Select from "Components/Select";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 600,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

const extraProperties = [
	"ifood_code",
	"ifood_shortcode",
	"ifood_place",
	"ifood_occasion",
	"ifood_value",
	"ifood_fee",
	"uber_code",
	"uber_shortcode",
	"uber_place",
	"uber_service",
	"uber_value",
	"uber_distance",
	"uber_duration",
	"uber_city",
	"uber_fee"
];

let debounceExtraProperties: (() => void) & _.Cancelable = null;

function CreateEditExtraProperty({ open, handleClose, onAdd }) {
	const classes = useStyles({});

	const [name, setName] = useState("");
	const [value, setValue] = useState("");
	const [errors, setErrors] = useState({});

	const onSubmit = e => {
		e.preventDefault();

		const errors: any = {};

		if (!name) errors.name = ls.required;
		if (!value) errors.admFee = ls.required;

		setErrors(errors);

		if (Object.keys(errors).length > 0) return;

		if (debounceExtraProperties) debounceExtraProperties.cancel();

		debounceExtraProperties = _.debounce(() => {
			onAdd(name, value);
		}, 1000);

		debounceExtraProperties();
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.add}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Select
										id="name"
										required
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										options={extraProperties.map(c => ({
											id: c,
											value: ls[c]
										}))}
										onChange={(_, v) => setName(v)}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextInput
										id="value"
										required
										variant="outlined"
										name="value"
										value={value}
										errors={errors}
										onChange={(_, v) => setValue(v)}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button color="primary" variant="contained" type="submit">
									{ls.save}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default CreateEditExtraProperty;
