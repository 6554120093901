import styled from "styled-components";

interface PropsButton {
	isPurple?: boolean;
}

interface PropsText {
	isValue?: boolean;
	isPair?: boolean;
	isDisable?: boolean;
}

export const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	flex-direction: column;
`;

export const Title = styled.h1`
	font: normal normal bold 24px;
	letter-spacing: 0px;
	color: #5135d1;
	opacity: 1;
`;

export const RowTax = styled.div`
	display: flex;
	flex-direction: row;
	max-width: 80%;
	padding: 20px 0px;
`;

export const BackgroundInput = styled.div<PropsText>`
	background: ${props =>
			props.isPair || props.isDisable ? "#F8F8F8" : "#ffffff"}
		0% 0% no-repeat padding-box;
`;

export const ColumnTax = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 200px;
`;

export const TextTax = styled.p<PropsText>`
	margin: 0;
	padding: 0;

	font: normal normal normal 18px;
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
	width: 100%;
	height: 43px;
	padding-bottom: 30px;
	text-align: center;
	background-color: ${props => (props.isValue ? "#F5F5F5" : "#fff")};
`;

export const LineTitle = styled.p`
	font: normal normal normal 18px Product Sans;
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
`;

export const LineValue = styled.label`
	font: normal normal normal 18px Product Sans;
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
`;

export const Button = styled.button<PropsButton>`
	border-radius: 8px;
	border: 1px solid #5734da;
	color: ${props => (props.isPurple ? "#fff" : "#5734DA")};
	background: ${props => (!props.isPurple ? "#fff" : "#5734DA")};
	height: 46px;
	width: 138px;
	margin-right: 10px;
	font-size: 16px;
	font-weight: bold;
	letter-spacing: 0px;
	opacity: 1;
	cursor: pointer;
`;
