/* eslint-disable */
import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import dayjs from "dayjs";
import { excelDateToJSDate } from "helpers/date";
import { removeDiacritics } from "helpers/string";
import { toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	getIfoodProducts,
	importProducts,
	setValue
} from "store/actions/product";
import XLSX from "xlsx";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	}
}));

let debounceImportForm: (() => void) & _.Cancelable = null;

function ImportFormModal({ load }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const inputFile = useRef<HTMLInputElement>(null);

	const [ifood, setIfood] = useState(false);

	const { showImportFormModal, link, errors, loading } = useSelector<any, any>(
		c => c.product
	);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const handleClose = () => handleChange("showImportFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		dispatch(
			getIfoodProducts(link, err => {
				if (err) {
					console.log(err);
					if (err.default) toastWarning(err.default);
					else if (typeof err === "string") toastWarning(err);
					else toastWarning(JSON.stringify(err));
				} else {
					dispatch(clearValues());
					handleClose();
					load();
				}
			})
		);
	};

	const handleFileSelected = e => {
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const sheet = wb.Sheets[wsname];

			const ref = XLSX.utils.decode_range(sheet["!ref"]);

			for (let C = ref.s.c; C <= ref.e.c; ++C) {
				const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
				if (cell && cell.t === "s") {
					cell.v = removeDiacritics(cell.v.trim().toLowerCase());
					if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());
				}
			}

			const data = XLSX.utils.sheet_to_json(sheet);

			const items = [];

			for (const c of data) {
				const name = c["name"];

				if (!name) {
					toastWarning(
						`Nome do produto, linha ${
							data.indexOf(c) + 2
						} não preenchido, é obrigatório`
					);

					return;
				}

				let price = String(c["price"] || "");

				if (price.indexOf("R$") !== -1) {
					price = price.replace("R$", "").trim();
				}

				if (price) {
					while (price.indexOf(",") !== -1) {
						let index = price.indexOf(",");

						if (index >= price.length - 3) {
							price = price.replace(",", ".");
						} else {
							price = price.replace(",", "");
						}
					}
				}

				let promotionalPrice = String(c["promotionalprice"] || "");

				if (promotionalPrice.indexOf("R$") !== -1) {
					promotionalPrice = promotionalPrice.replace("R$", "").trim();
				}

				if (promotionalPrice) {
					while (promotionalPrice.indexOf(",") !== -1) {
						let index = promotionalPrice.indexOf(",");

						if (index >= promotionalPrice.length - 3) {
							promotionalPrice = promotionalPrice.replace(",", ".");
						} else {
							promotionalPrice = promotionalPrice.replace(",", "");
						}
					}
				}

				let promotionalStartAt = c["promotionalstartat"];

				if (promotionalStartAt) {
					if (typeof promotionalStartAt === "number") {
						promotionalStartAt = dayjs(excelDateToJSDate(promotionalStartAt));
					} else {
						if (promotionalStartAt.length !== 10) {
							let aux: string[] = promotionalStartAt.split("/");

							if (aux[0].length === 1) aux[0] = aux[0].padStart(2, "0");
							if (aux[1].length === 1) aux[1] = aux[1].padStart(2, "0");

							promotionalStartAt = aux.join("/");
						}

						promotionalStartAt = dayjs(
							promotionalStartAt,
							promotionalStartAt.length > 10
								? ls.dateFormat
								: ls.dateFormatShort
						);
					}

					promotionalStartAt.utcOffset(-3 * 60);
					promotionalStartAt = promotionalStartAt.toDate();
				}

				let promotionalEndAt = c["promotionalendat"];

				if (promotionalEndAt) {
					if (typeof promotionalEndAt === "number") {
						promotionalEndAt = dayjs(excelDateToJSDate(promotionalEndAt));
					} else {
						if (promotionalEndAt.length !== 10) {
							let aux: string[] = promotionalEndAt.split("/");

							if (aux[0].length === 1) aux[0] = aux[0].padStart(2, "0");
							if (aux[1].length === 1) aux[1] = aux[1].padStart(2, "0");

							promotionalEndAt = aux.join("/");
						}

						promotionalEndAt = dayjs(
							promotionalEndAt,
							promotionalEndAt.length > 10 ? ls.dateFormat : ls.dateFormatShort
						);
					}

					promotionalEndAt.utcOffset(-3 * 60);
					promotionalEndAt = promotionalEndAt.toDate();
				}

				items.push({
					code: c["code"],
					name,
					category: { name: c["category"] },
					description: c["description"],
					price: Math.round(Number(price) * 100),
					promotionalName: c["promotionalname"],
					promotionalPrice: Math.round(Number(promotionalPrice) * 100),
					promotionalStartAt,
					promotionalEndAt
				});
			}

			if (debounceImportForm) debounceImportForm.cancel();

			debounceImportForm = _.debounce(() => {
				dispatch(
					importProducts(items, err => {
						if (err) {
							console.log(err);
							if (err.default) toastWarning(err.default);
							else if (typeof err === "string") toastWarning(err);
							else toastWarning(JSON.stringify(err));
						} else {
							handleClose();
							load();
						}
					})
				);
			}, 1000);

			debounceImportForm();
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={showImportFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showImportFormModal}>
				<div className={classes.paper}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.importProducts}</Typography>
					</div>
					<hr />
					<div className={classes.body}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "space-around",
								marginBottom: 20
							}}
						>
							<div>
								<Button
									color="primary"
									variant="contained"
									onClick={() => setIfood(!ifood)}
								>
									<label>Importar com Ifood</label>
								</Button>
							</div>
							<div>
								<Button
									color="primary"
									variant="contained"
									onClick={openSelectFile}
									disabled={!!ifood}
								>
									<label>Importar com planilha</label>
									<input
										ref={inputFile}
										style={{ display: "none" }}
										type="file"
										accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
										onChange={handleFileSelected}
									/>
								</Button>
							</div>
						</div>
						{ifood && (
							<form
								noValidate
								onSubmit={onSubmit}
								style={{
									marginBottom: 20
								}}
							>
								<Grid container spacing={2} alignItems="center">
									<Grid item xs={10}>
										<TextInput
											id="link"
											required
											autoFocus
											variant="outlined"
											name="linkOfIfood"
											value={link}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={handleChange}
										/>
									</Grid>
									<Grid item xs={2}>
										<Button
											color="primary"
											variant="contained"
											type="submit"
											disabled={loading.ifood}
										>
											Buscar
											{loading.ifood && <Spinner color="secondary" size={16} />}
										</Button>
									</Grid>
								</Grid>
							</form>
						)}
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ImportFormModal;
