import { Grid, IconButton } from "@material-ui/core";
import dayjs from "dayjs";
import { toastError } from "helpers/toast";
import ls from "Localization";
import React from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { deletePhoto, getProduct } from "store/actions/product";

const TableProduct: React.FC = () => {
	const dispatch = useDispatch();

	const { files, item } = useSelector<any, any>(c => c.product);

	const handleDelete = c => {
		dispatch(
			deletePhoto(c.id, err => {
				if (err) {
					if (typeof err !== "string") err = JSON.stringify(err);

					toastError(err);
				} else {
					dispatch(
						getProduct(item.id, "photos", err => {
							if (err) {
								if (typeof err !== "string") err = JSON.stringify(err);

								toastError(err);
							}
						})
					);
				}
			})
		);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<ReactTable
					data={files}
					getTdProps={() => ({
						style: {
							display: "flex",
							alignItems: "center"
						}
					})}
					columns={[
						{
							Header: "Adicionado em",
							id: "creationDate",
							accessor: (c: any) =>
								dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds)
						},
						{ Header: "Nome", accessor: "fileName" },
						{ Header: "Link", accessor: "link" },
						{
							Header: "Ações",
							id: "actions",
							accessor: c => (
								<div>
									<IconButton
										style={{ padding: 4 }}
										onClick={() => handleDelete(c)}
									>
										<MdDelete />
									</IconButton>
								</div>
							),
							width: 60
						}
					]}
					pageSize={5}
				/>
			</Grid>
		</Grid>
	);
};

export default TableProduct;
