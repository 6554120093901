import { Button, Fab } from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { getBusinessTypeFromString } from "helpers/business";
import { stringSort } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import statusRequest from "models/requestCardsStatus";
import serviceStatus from "models/serviceStatus";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessesCategories } from "store/actions/business/category/report";
import { clearValues } from "store/actions/business/report";
import { setValue } from "store/actions/requestCards/create";
import { getRequestCards } from "store/actions/requestCards/report";
import { State } from "store/reducers";
import { IInitialState } from "store/reducers/requestCards/types";

import useStyles from "./styles";

let getBusinessDebounced: (() => void) & _.Cancelable = null;
let getBusinessCategoriesDebounced: (() => void) & _.Cancelable = null;

function ReportRequestCards({ history, match, location }) {
	const dispatch = useDispatch();
	const classes = useStyles({});

	const { user } = useSelector<any, any>(c => c.login);

	const { isStore, isCompany, isHeadquarters, isFranchise } =
		getBusinessTypeFromString(match.path);

	const filterType = useMemo(() => {
		if (isStore) return 1;
		if (isCompany) return 2;
		if (isHeadquarters) return 4;
		return 8;
	}, [isStore, isCompany, isHeadquarters]);

	const table = useRef(null);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch, isStore, isCompany, isHeadquarters, isFranchise]);

	useEffect(() => {
		dispatch(
			setValue({
				request: {
					businessId: "",
					quantityCard: 0,
					street: "",
					document: "",
					zipcode: "",
					name: "",
					number: 0,
					neighborhood: "",
					complement: "",
					city: "",
					state: "",
					phone: "",
					email: "",
					service: ""
				},
				cnpj: ""
			})
		);
	}, []);

	const { items, pages, loading, status } = useSelector<State, IInitialState>(
		s => s.requestReport
	);

	const objectStatus = useMemo(() => {
		const keys = Object?.keys(statusRequest).map(i => i);
		const filterObject = status?.filter(i => !keys.includes(i)).map(i => i);
		if (!filterObject) {
			return keys;
		}
		const object = filterObject?.reduce((acc, item) => {
			acc[item] = item;
			return acc;
		}, statusRequest);
		return object;
	}, [status]);

	const statusAllRequestsCards = useCallback(
		status => {
			const verify =
				ls[objectStatus[status]] && ls[objectStatus[status]] !== "Não definido";

			if (verify) {
				return ls[objectStatus[status]];
			}
			return status || ls.notDefined;
		},
		[objectStatus]
	);

	const columns = useMemo(
		() =>
			[
				{
					Header: `${ls.id} do ${ls.order}`,
					id: "id",
					accessor: c => c.id,
					width: 120,
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.creationDate,
					id: "creationDate",
					accessor: c =>
						dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
					width: 140,
					sortMethod: (a, b, desc: boolean) => {
						let aD = dayjs(a, ls.dateFormatWithoutSeconds);
						let bD = dayjs(b, ls.dateFormatWithoutSeconds);

						return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
					},
					Filter: DateSelectFilter,
					show: true
				},
				{
					Header: ls.service,
					id: "service",
					accessor: c => ls[serviceStatus[c.service]],
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter ? filter.value : ""}
						>
							<option value="">{ls.all}</option>
							{Object.keys(serviceStatus).map(item => (
								<option value={item} key={item}>
									{ls[serviceStatus[item]]}
								</option>
							))}
						</select>
					),
					width: 200,
					show: true
				},
				{
					Header: ls.status,
					id: "status",
					accessor: c => statusAllRequestsCards(c.status),
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter ? filter.value : ""}
						>
							<option value="">{ls.all}</option>
							{Object.keys(objectStatus)?.map(item => (
								<option value={item} key={item}>
									{statusAllRequestsCards(item)}
								</option>
							))}
						</select>
					),
					width: 200,
					show: true
				},
				{
					Header: ls.createdBy,
					id: "createdBy",
					accessor: c => c.createdBy?.fullName ?? "Não informado",
					width: 160,
					filterable: false,
					show: true
				},
				{
					Header: ls.businessName,
					id: "businessName",
					width: 300,
					accessor: c => c?.business?.displayName,
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.economicGroup,
					id: "economicGroup",
					accessor: c => c?.business?.headquarters?.socialName || "-",
					sortMethod: stringSort,
					show: true,
					width: 300
				},
				{
					Header: ls.cnpj,
					id: "cnpj",
					accessor: c => c?.business?.cnpj,
					show: false,
					width: 170
				},
				{
					Header: ls.address,
					id: "adress",
					accessor: c =>
						`${c?.street} - ${c?.city} - ${c?.neighborhood} - ${c?.state} - ${c?.number}`,
					width: 350,
					show: true,
					filterable: false
				},
				{
					Header: ls.tracking,
					id: "trackingCode",
					accessor: c => c?.trackingCode,
					show: true,
					width: 170
				},
				// {
				// 	Header: ls.initStock,
				// 	id: "lastTransfer",
				// 	accessor: c => (
				// 		<div className={classes.textAlignCenter}>
				// 			{validService(c.service)
				// 				? dayjs(c.lastTransfer?.creationDate).format(
				// 						ls.dateFormatWithoutSeconds
				// 				  )
				// 				: "-"}
				// 		</div>
				// 	),
				// 	show: false,
				// 	width: 170,
				// 	filterable: false
				// },

				{
					Header: ls.quantity,
					id: "quantityCard",
					accessor: c => c.quantityCard,
					show: true,
					width: 120,
					filterable: false
				}
			].filter(c => c),
		[]
	);

	const isAdmin = useMemo(() => isInRole(user, ["admin"]), [user]);

	const renderHeaderRightComponent = () => {
		if (!isStore || !isAdmin) return null;

		return (
			<div className={classes.headerRightContainer}>
				<Button
					color="primary"
					variant="text"
					onClick={() => history.push("/Store/Category")}
				>
					Categorias
				</Button>
			</div>
		);
	};

	const handleFetchBusinessCategory = () => {
		if (getBusinessCategoriesDebounced) {
			getBusinessCategoriesDebounced.cancel();
		}

		getBusinessCategoriesDebounced = _.debounce(
			() =>
				dispatch(
					getBusinessesCategories(null, null, null, null, null, null, null)
				),
			500
		);

		getBusinessCategoriesDebounced();
	};

	useEffect(() => {
		handleFetchBusinessCategory();
	}, []);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBusinessDebounced) {
			getBusinessDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");
			let date = null;

			if (creationDate?.value.startDate || creationDate?.value.endDate) {
				date = {
					startAt: creationDate.value.startDate,
					endAt: creationDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "creationDate",
					value: JSON.stringify(date)
				});
			}
		}

		getBusinessDebounced = _.debounce(
			() =>
				dispatch(
					getRequestCards(
						page * pageSize,
						pageSize,
						filtered
							.filter(i => i.value)
							.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								`&filters[type]=${filterType}`
							),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					)
				),
			500
		);

		getBusinessDebounced();
	};

	useEffect(() => {
		if (table.current) {
			let { page, pageSize, sorted, filtered } = table.current.state;

			if (getBusinessDebounced) {
				getBusinessDebounced.cancel();
			}

			if (!sorted) sorted = [];

			getBusinessDebounced = _.debounce(
				() =>
					dispatch(
						getRequestCards(
							page * pageSize,
							pageSize,
							filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								`&filters[type]=${filterType}`
							),
							sorted[0]?.id,
							sorted[0]?.desc
						)
					),
				500
			);

			getBusinessDebounced();
		}
	}, [isStore, isCompany, isHeadquarters, isFranchise, dispatch, filterType]);

	return (
		<div>
			<Helmet>
				<title>Minhas Solicitações</title>
			</Helmet>
			<Report
				manual
				title="Minhas Solicitações"
				data={items}
				tableRef={table}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				loading={loading}
				columns={columns}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				onRowClicked={row => {
					history.push({
						pathname: `/RequestCards/${row.id}/Detail`,
						search: "",
						state: { id: row.id }
					});
				}}
				defaultSorted={[
					{
						id: "code",
						desc: true
					}
				]}
				headerRightComponent={renderHeaderRightComponent()}
			/>
			<Fab
				color="primary"
				aria-label="Add"
				className={classes.fab}
				onClick={() => history.push("/RequestCards/Add")}
			>
				<MdAdd size={24} />
			</Fab>
		</div>
	);
}

export default ReportRequestCards;
