import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "Components/Checkbox";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import iconType from "models/iconType";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as actions from "store/actions/business/category/create";

import styles from "./styles";

let debounceBusinessCategoryCreate: (() => void) & _.Cancelable = null;

export function RenderBusinessCategoryCreate({
	classes,
	state,
	setValue,
	clearValues,
	createBusinessCategory,
	history
}) {
	const [formRef, setFormRef] = useState(null);

	const handleChange = (name, value) => {
		setValue({
			[name]: value
		});
	};

	const onSubmit = e => {
		e.preventDefault();
		if (!formRef.checkValidity()) {
			formRef.reportValidity();
		} else {
			if (debounceBusinessCategoryCreate)
				debounceBusinessCategoryCreate.cancel();

			debounceBusinessCategoryCreate = _.debounce(() => {
				createBusinessCategory(err => {
					if (err) {
						toastError(err);
					} else {
						history.push("/Store/Category");
					}
				});
			}, 1000);

			debounceBusinessCategoryCreate();
		}
	};

	const { name, color, icon, iconSource, isVisible, loading, errors } = state;

	return (
		<>
			<Helmet>
				<title>
					{ls.createBusinessCategory} | {ls.appName}
				</title>
			</Helmet>
			<Grid container justify="center" spacing={5}>
				<Grid item xs={11}>
					<Paper className={classes.root}>
						<Typography variant="h5">{ls.createBusinessCategory}</Typography>
						<hr className={classes.line} />

						<form onSubmit={onSubmit} ref={ref => setFormRef(ref)}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="name"
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="color"
										variant="outlined"
										name="color"
										value={color}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="icon"
										variant="outlined"
										name="icon"
										value={icon}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Select
										errors={errors}
										id="iconSource"
										name="iconSource"
										value={iconSource}
										onChange={handleChange}
										options={Object.keys(iconType).map(c => ({
											id: c,
											value: iconType[c]
										}))}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Checkbox
										id="isVisible"
										name="isVisible"
										checked={isVisible}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>

							<hr className={classes.line} />
							<Grid container justify="flex-end">
								<Button
									variant="contained"
									color="default"
									className={classes.button}
									onClick={clearValues}
									disabled={loading}
								>
									{ls.clear}
								</Button>
								<Button
									variant="contained"
									color="primary"
									className={classes.button}
									disabled={loading}
									type="submit"
								>
									{ls.save}
									{loading && <Spinner color="secondary" size={16} />}
								</Button>
							</Grid>
						</form>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
}

const mapStateToProps = ({ businessCategoryCreate: state }) => ({
	state
});

const mapDispatchToProps = {
	...actions
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RenderBusinessCategoryCreate));
