import { getErrors } from "helpers/error";
import * as api from "services/api";

export const CREATE_TAX = "[TAX CREATE] CREATE_TAX";
export const CREATE_TAX_SUCCESS = "[TAX CREATE] CREATE_TAX_SUCCESS";
export const CREATE_TAX_FAILED = "[TAX CREATE] CREATE_TAX_FAILED";

export const SET_VALUE = "[TAX CREATE] SET_VALUE";
export const CLEAR_VALUES = "[TAX CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function updateTaxBags(businessId, model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_TAX });

		try {
			const { login } = getState();

			const filterName = model.map(i => ({
				limitRescueFee: Number(i.limitRescueFee) * 100,
				prePaidBalanceAccountTypeId: i.prePaidBalanceAccountTypeId
			}));

			let response = await api.sendPut(
				`/Configuration/Business/${businessId}/PrePaidBalanceType`,
				filterName,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				dispatch({
					type: CREATE_TAX_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_TAX_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_TAX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function updateTaxBagsToHeadquarters(businessId, model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_TAX });

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/Configuration/Business/${businessId}/Headquarter`,
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				dispatch({
					type: CREATE_TAX_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_TAX_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_TAX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createTax(businessId, model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_TAX });

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/Configuration/Business/${businessId}`,
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				dispatch({
					type: CREATE_TAX_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_TAX_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_TAX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getTax(businessId, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_TAX });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Configuration/Business/${businessId}`,

				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);

				return dispatch({
					type: CREATE_TAX_SUCCESS,
					payload: result.model
				});
			}
			const errors = getErrors(result.errors);

			callback && callback(errors);

			return dispatch({
				type: CREATE_TAX_FAILED,
				payload: errors
			});
		} catch (error) {
			return dispatch({
				type: CREATE_TAX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
