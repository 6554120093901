import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import { exportExcelGenerate } from "Components/Report/export";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import dayjs from "dayjs";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { AiOutlineFileExcel } from "react-icons/ai";
import { CgTemplate } from "react-icons/cg";
import { CiImport } from "react-icons/ci";
import { FaFileImport, FaPlus } from "react-icons/fa";
import { MdDelete, MdEdit, MdPeople } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteCostCenter,
	downloadCostCenterUsers,
	getCostCenter,
	getCostCenters,
	importCostCenter,
	importUserToCostCenter,
	setValue
} from "store/actions/costCenter";
import { CostCenterState } from "store/reducers/costCenter";
import XLSX from "xlsx";

import CreateForm from "../Forms/Add";
import EditForm from "../Forms/Edit";
import Users from "../Forms/Users";
import styles from "./styles";

let getCostCentersDebounced: (() => void) & _.Cancelable = null;

function CostCenterReport({ item }) {
	const dispatch = useDispatch();

	const [loadingSub, setLoadingSub] = useState({});
	const [alertModal, setAlertModal] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const inputRefUser = useRef<HTMLInputElement>(null);
	const table = useRef(null);

	const state = useSelector<any, CostCenterState>(c => c.costCenter);

	const loadData = useCallback(() => {
		dispatch(
			getCostCenters(
				null,
				null,
				`&filters[businessId]=${item.id}&filters[status]=1`,
				null,
				null,
				null,
				null
			)
		);
		setIsLoaded(true);
	}, [dispatch, item.id]);

	const handleImport = useCallback(() => {
		inputRef.current.click();
	}, []);

	const handleDownloadCostCenterUsers = useCallback(
		id => {
			dispatch(downloadCostCenterUsers(id));
		},
		[dispatch]
	);

	const handleImportUser = useCallback(() => {
		inputRefUser.current.click();
	}, []);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(id, item) => {
			setLoadingSub({ [id]: item.id });
			dispatch(
				getCostCenter(item.id, (err, model) => {
					if (err) {
						console.log(err);
						if (err.default) toastError(err.default);
						else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
					} else {
						handleChange(id, Boolean(model));
					}
					setLoadingSub({ [id]: false });
				})
			);
		},
		[dispatch, handleChange]
	);

	const handleDelete = useCallback(
		(id: string) => {
			dispatch(
				deleteCostCenter(id, err => {
					if (err) {
						console.log(err);
						if (err.default) toastError(err.default);
						else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
					} else {
						setAlertModal(null);
					}
				})
			);
		},
		[dispatch]
	);

	const showConfirm = useCallback(
		itemId => {
			setAlertModal(
				<Dialog
					open
					onClose={() => setAlertModal(null)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Tem certeza que deseja confirmar a exclusão?
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Após a confirmação da exclusão não será possível recuperar os
							dados.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => handleDelete(itemId)}
							color="primary"
							autoFocus
						>
							Confirmar
						</Button>
						<Button onClick={() => setAlertModal(null)} color="primary">
							Cancelar
						</Button>
					</DialogActions>
				</Dialog>
			);
		},
		[handleDelete]
	);

	const handleFetchData = useCallback(
		(tableState, _instance) => {
			if (!isLoaded) {
				return;
			}
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getCostCentersDebounced) {
				getCostCentersDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");
				let date = null;

				if (creationDate?.value.startDate || creationDate?.value.endDate) {
					date = {
						startAt: creationDate.value.startDate,
						endAt: creationDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "creationDate",
						value: JSON.stringify(date)
					});
				}
			}

			getCostCentersDebounced = _.debounce(
				() =>
					dispatch(
						getCostCenters(
							page * pageSize,
							pageSize,
							`&filters[businessId]=${
								item.id
							}&filters[status]=1${filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								""
							)}`,
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getCostCentersDebounced();
		},
		[dispatch, item.id, isLoaded]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state, null),
		[handleFetchData]
	);

	const handleFilesChangeUser = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files[0];

			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;

			reader.onload = e => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
				/* Get first worksheet */
				const wsName = wb.SheetNames[0];
				const sheet = wb.Sheets[wsName];

				const ref = XLSX.utils.decode_range(sheet["!ref"]);

				for (let C = ref.s.c; C <= ref.e.c; ++C) {
					const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
					if (cell && cell.t === "s") {
						cell.v = cell.v.trim();
						if (cell.w) cell.w = cell.w.trim();
					}
				}

				/* Update state */
				const data = [];

				const json = XLSX.utils.sheet_to_json(sheet, {});
				for (const item of json) {
					if (!item["Código centro de custo"] || !item["CPF"]) continue;

					data.push({
						referenceCode: item["Código centro de custo"],
						document: item["CPF"]
					});
				}

				dispatch(
					importUserToCostCenter(data, err => {
						if (err) {
							toastError(JSON.stringify(err));
						} else {
							load();
							toastSuccess("Centro de custos importados com sucesso");
						}
					})
				);
			};

			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		},
		[dispatch, load]
	);

	const handleFilesChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files[0];

			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;

			reader.onload = e => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
				/* Get first worksheet */
				const wsName = wb.SheetNames[0];
				const sheet = wb.Sheets[wsName];

				const ref = XLSX.utils.decode_range(sheet["!ref"]);

				for (let C = ref.s.c; C <= ref.e.c; ++C) {
					const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
					if (cell && cell.t === "s") {
						cell.v = cell.v.trim();
						if (cell.w) cell.w = cell.w.trim();
					}
				}

				/* Update state */
				const data = [];

				const json = XLSX.utils.sheet_to_json(sheet, {});

				for (const item of json) {
					if (!item["code"] || !item["name"]) continue;

					data.push({
						referenceCode: item["code"],
						name: item["name"]
					});
				}

				dispatch(
					importCostCenter(data, err => {
						if (err) {
							console.log(err);
							toastError(err);
						} else {
							load();
							toastSuccess("Centro de custos importados com sucesso");
						}
					})
				);
			};

			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		},
		[dispatch, load]
	);

	const columns = [
		{
			Header: ls.creationDate,
			id: "creationDate",
			accessor: c => dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
			width: 180,
			show: true,
			sortMethod: (a, b, _desc: boolean) => {
				let aD = dayjs(a, ls.dateFormatWithoutSeconds);
				let bD = dayjs(b, ls.dateFormatWithoutSeconds);

				return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
			},
			Filter: DateSelectFilter
		},
		{
			Header: ls.referenceCode,
			id: "referenceCode",
			accessor: c => (c.referenceCode ? c.referenceCode : "Não informado"),
			width: 180
		},
		{
			Header: ls.name,
			id: "name",
			accessor: c => (c.name ? c.name : "Não informado")
		},
		{
			Header: ls.actions,
			id: "actions",
			filterable: false,
			accessor: c => (
				<Grid container spacing={2} justify="center">
					<Grid item>
						{
							<TooltipLight title="Exportar usuários" placement="top">
								<IconButton style={{ padding: 8, fontSize: "1.3rem" }}>
									<AiOutlineFileExcel
										size={24}
										onClick={() => handleDownloadCostCenterUsers(c.id)}
									/>
								</IconButton>
							</TooltipLight>
						}
						{loadingSub["showUserFormModal"] === c.id ? (
							<Spinner color="secondary" size={16} />
						) : (
							<TooltipLight title="Selecionar usuários" placement="top">
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showUserFormModal", c)}
								>
									<MdPeople />
								</IconButton>
							</TooltipLight>
						)}
						{loadingSub["showEditFormModal"] === c.id ? (
							<Spinner color="secondary" size={16} />
						) : (
							<TooltipLight title="Editar centro de custo" placement="top">
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showEditFormModal", c)}
								>
									<MdEdit />
								</IconButton>
							</TooltipLight>
						)}
						<TooltipLight title="Excluir centro de custo" placement="top">
							<IconButton
								style={{
									padding: 8,
									fontSize: "1.3rem"
								}}
								onClick={() => showConfirm(c.id)}
							>
								<MdDelete />
							</IconButton>
						</TooltipLight>
					</Grid>
				</Grid>
			),
			width: 140
		}
	];

	const templateJson = useMemo(
		() => [
			{
				CPF: 11111111111,
				"Código centro de custo": 111
			}
		],
		[]
	);

	return (
		<LoadOnScroll load={loadData}>
			<Report
				manual
				tableRef={table}
				title={ls.costCenter}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				headerRightComponent={
					<div>
						<a
							href="https://docs.google.com/spreadsheets/d/1pFtkJwdTnjyUk_ukbDDjUR1O6MMXBr2njxcrIhY8m_Q/edit?usp=sharing"
							target="_blank"
							rel="noopener noreferrer"
							style={{ marginRight: 16 }}
						>
							Baixar modelo de importação
						</a>
						{state.loading.import ? (
							<Spinner color="secondary" size={16} />
						) : (
							<TooltipLight title="Importar centros de custos" placement="top">
								<IconButton onClick={handleImport}>
									<FaFileImport />
								</IconButton>
							</TooltipLight>
						)}

						{state.loading.import ? (
							<Spinner
								color="secondary"
								size={16}
								style={{ marginRight: 16, marginLeft: 16 }}
							/>
						) : (
							<TooltipLight
								title="Importar usuários aos centros de custos"
								placement="top"
							>
								<IconButton onClick={handleImportUser}>
									<CiImport />
								</IconButton>
							</TooltipLight>
						)}

						{state.loading.import ? (
							<Spinner color="secondary" size={16} />
						) : (
							<TooltipLight
								title="Template para importar usuários aos centros de custos"
								placement="top"
							>
								<IconButton
									onClick={() =>
										exportExcelGenerate(
											templateJson,
											"Exemplo Centro_De_Custo",
											"Template_Importação_Usuários_Centro_de_custo"
										)
									}
								>
									<CgTemplate size={24} />
								</IconButton>
							</TooltipLight>
						)}

						<IconButton
							color="primary"
							onClick={() => {
								handleChange("showFormModal", true);
							}}
						>
							<FaPlus />
						</IconButton>
					</div>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			{alertModal}
			<CreateForm load={load} />
			<EditForm load={load} />
			<Users business={item} />

			<input
				ref={inputRefUser}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFilesChangeUser}
			/>

			<input
				ref={inputRef}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFilesChange}
			/>
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(CostCenterReport);
