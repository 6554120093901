export default {
	0: "Nenhum",
	1: "Wiipoflex",
	2: "Wiipo Request",
	3: "Wiipo Request + Wiipoflex",
	4: "Clube Wiipo",
	5: "Wiipoflex + Clube Wiipo",
	6: "Clube Wiipo + Wiipo Request",
	7: "Wiipoflex + Clube Wiipo + Wiipo Request"
};
