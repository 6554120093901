export default {
	"-1": "noMessage",
	0: "pending",
	1: "sent",
	2: "received",
	3: "read",
	4: "noSale",
	5: "noPhone",
	6: "ignored",
	9: "failed"
};
