import { modalAlert } from "Components/Modals/ModalAlert";
import ls from "Localization";
import * as api from "services/api";

export const CONSULT_HCM = "[CONSULT HCM] CONSULT_HCM";
export const CONSULT_HCM_SUCCESS = "[CONSULT HCM] CONSULT_HCM_SUCCESS";
export const CONSULT_HCM_FAILED = "[CONSULT HCM] CONSULT_HCM_FAILED";

export const SET_VALUE = "[CONSULT HCM] SET_VALUE";
export const CLEAR_VALUES = "[CONSULT HCM] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function userConsultHCM(id, data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CONSULT_HCM });

		try {
			const { login } = getState();

			let response = await api.sendPost(`/Business/${id}/Employee/Cnpj`, data, {
				Authorization: `Bearer ${login.token}`
			});

			const result = await response.json();

			if (result.isValid === false) {
				callback && callback(result.errors.default);
				return dispatch({
					type: CONSULT_HCM_FAILED
				});
			}

			let link = document.createElement("a");
			link.href = `data:${result.type};base64,${result.content}`;
			link.download = result.filename;
			link.click();

			callback && callback(null);
			return dispatch({
				type: CONSULT_HCM_SUCCESS,
				payload: result.model
			});
		} catch (error) {
			callback && callback(error);

			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});

			return dispatch({
				type: CONSULT_HCM_FAILED
			});
		}
	};
}
