import * as actions from "store/actions/role/create";
import { IRefreshSwap } from "store/reducers/user/types/refreshSwap";

const initialState: IRefreshSwap = {
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_ROLE: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.CREATE_ROLE_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_ROLE_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
