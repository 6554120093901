import * as actions from "store/actions/integrations";

import { IIntegrations } from "./types";

const initialState: IIntegrations = {
	items: [],
	properties: [{ name: "", size: 6, decimal: 2, customValue: "" }],
	item: null,
	url: "",
	emailTo: "",
	from: "",
	to: "",
	companyId: "",
	type: "0",
	format: "0",
	object: "0",
	provider: "",
	showFormModal: false,
	showEditFormModal: false,
	showSendMailFormModal: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_INTEGRATION: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}

		case actions.GET_INTEGRATION_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.url = payload.url ?? initialState.url;
				newState.type = payload.type ?? initialState.type;
				newState.format = payload.format ?? initialState.format;
				newState.object = payload.object ?? initialState.object;
				newState.provider = payload.provider ?? initialState.provider;
				newState.properties = payload.properties?.length
					? payload.properties
					: initialState.properties;
				newState.companyId = payload.companyId ?? initialState.companyId;
			} else {
				newState.item = null;
				newState.url = initialState.url;
				newState.type = initialState.type;
				newState.format = initialState.format;
				newState.object = initialState.object;
				newState.provider = initialState.provider;
				newState.properties = initialState.properties;
				newState.companyId = initialState.companyId;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}

		case actions.GET_INTEGRATION_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.GET_INTEGRATIONS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}

		case actions.GET_INTEGRATIONS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}

		case actions.GET_INTEGRATIONS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.CREATE_INTEGRATION: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}

		case actions.CREATE_INTEGRATION_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				properties: initialState.properties,
				companyId: initialState.companyId
			};
		}

		case actions.CREATE_INTEGRATION_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}

		case actions.UPDATE_INTEGRATION: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_INTEGRATION_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				url: initialState.url,
				emailTo: initialState.emailTo,
				type: initialState.type,
				format: initialState.format,
				object: initialState.object,
				provider: initialState.provider,
				properties: initialState.properties,
				companyId: initialState.companyId
			};
		}

		case actions.UPDATE_INTEGRATION_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}

		case actions.SEND_INTEGRATION_MAIL: {
			return {
				...state,
				loading: { send: true },
				errors: initialState.errors
			};
		}

		case actions.SEND_INTEGRATION_MAIL_SUCCESS: {
			return {
				...state,
				loading: { send: false }
			};
		}

		case actions.SEND_INTEGRATION_MAIL_FAILED: {
			return {
				...state,
				loading: { send: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				url: initialState.url,
				emailTo: initialState.emailTo,
				from: initialState.from,
				to: initialState.to,
				type: initialState.type,
				format: initialState.format,
				object: initialState.object,
				provider: initialState.provider,
				properties: initialState.properties,
				companyId: initialState.companyId
			};

		default: {
			return state;
		}
	}
};

export default reducer;
