import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_CREDIT = "[CREDIT DETAIL] GET_CREDIT";
export const GET_CREDIT_SUCCESS = "[CREDIT DETAIL] GET_CREDIT_SUCCESS";
export const GET_CREDIT_FAILED = "[CREDIT DETAIL] GET_CREDIT_FAILED";

export const GET_BUSINESS_TINY = "[CREDIT DETAIL] GET_BUSINESS_TINY";
export const GET_BUSINESS_TINY_SUCCESS =
	"[CREDIT DETAIL] GET_BUSINESS_TINY_SUCCESS";
export const GET_BUSINESS_TINY_FAILED =
	"[CREDIT DETAIL] GET_BUSINESS_TINY_FAILED";

export const UPDATE_CREDIT_DETAIL_CATEGORIES =
	"[CREDIT_DETAIL] UPDATE_CREDIT_DETAIL_CATEGORIES";
export const UPDATE_CREDIT_DETAIL_CATEGORIES_SUCCESS =
	"[CREDIT_DETAIL] UPDATE_CREDIT_DETAIL_CATEGORIES_SUCCESS";
export const UPDATE_CREDIT_DETAIL_CATEGORIES_FAILED =
	"[CREDIT_DETAIL] UPDATE_CREDIT_DETAIL_CATEGORIES_FAILED";

export const UPDATE_CREDIT_DETAIL_STORES =
	"[CREDIT_DETAIL] UPDATE_CREDIT_DETAIL_STORES";
export const UPDATE_CREDIT_DETAIL_STORES_SUCCESS =
	"[CREDIT_DETAIL] UPDATE_CREDIT_DETAIL_STORES_SUCCESS";
export const UPDATE_CREDIT_DETAIL_STORES_FAILED =
	"[CREDIT_DETAIL] UPDATE_CREDIT_DETAIL_STORES_FAILED";

export const SET_VALUE = "[CREDIT DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CREDIT DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCredit(id, include, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CREDIT });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/Credit/${id}?include=${include}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CREDIT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CREDIT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_CREDIT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editCreditPolicyCategories(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CREDIT_DETAIL_CATEGORIES });
		try {
			const { login, creditDetail } = getState();

			const { item } = creditDetail;

			const response = await api.sendPut(
				`/Credit/${item.id}/Categories`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CREDIT_DETAIL_CATEGORIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CREDIT_DETAIL_CATEGORIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CREDIT_DETAIL_CATEGORIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editCreditPolicyStores(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CREDIT_DETAIL_STORES });

		try {
			const { login, creditDetail } = getState();

			const { item } = creditDetail;

			const response = await api.sendPut(`/Credit/${item.id}/Stores`, data, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CREDIT_DETAIL_STORES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CREDIT_DETAIL_STORES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CREDIT_DETAIL_STORES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getStoresTiny(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS_TINY });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Business/Store/Tiny", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
