import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
	root: {
		padding: theme.spacing(2)
	},
	paper: {
		position: "absolute",
		width: 400,
		borderRadius: theme.spacing(),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: "none"
	},
	form: {
		padding: theme.spacing(2, 4)
	},
	alert: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		backgroundColor: "#cdeefd"
	},
	alertIcon: {
		marginRight: theme.spacing(2),
		color: "#02587f"
	},
	alertText: {
		color: "#02587f"
	},
	line: {},
	button: {
		margin: theme.spacing()
	},
	title: {
		margin: theme.spacing()
	}
}));

export default useStyles;
