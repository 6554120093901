import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import ButtonCopyClipboard from "Components/ButtonCopyClipboard";
import { MONEY } from "Components/NumberFormat";
import LabelTypeBag from "Components/views/Dashboard/User/Detail/AccountHolder/LabelTypeBag";
import { toastError } from "helpers/toast";
import ls from "Localization";
import React, { useCallback, useMemo, useState } from "react";
import { IoRefreshOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import {
	blockTreasuryAccount,
	createTreasuryAccount,
	getTreasuryAccountBalance
} from "store/actions/business/detail";

import useStyles, { GridButtonCopy } from "./styles";

function TreasuryAccount({ item, admin, id }) {
	const classes = useStyles({});

	const dispatch = useDispatch();
	const [treasuryAccountBalance, setTreasuryAccountBalance] =
		useState<any>(null);

	const typeBAG = useMemo(() => {
		const findTypeBAG = item.businessTreasuryAccounts?.find(
			i => i.treasuryAccountId === item.treasuryAccountId
		)?.accountType;

		return findTypeBAG;
	}, [item.businessTreasuryAccounts, item.treasuryAccountId]);

	const textButton = useMemo(() => {
		const items = item;

		if (items.treasuryAccountHolderId) {
			if (items.treasuryAccountStatus === "active") {
				return ls.block;
			}
			return ls.active;
		}
		return ls.create;
	}, [item]);

	const handleGetTreasuryBalance = useCallback(() => {
		dispatch(
			getTreasuryAccountBalance(id, (err, model) => {
				if (err) {
					toastError(err);
				} else {
					setTreasuryAccountBalance(model.treasuryAccountBalance);
				}
			})
		);
	}, [dispatch, id]);

	const handleTreasuryAccount = () => {
		if (item.treasuryAccountStatus === "active") {
			dispatch(
				blockTreasuryAccount(item.id, err => {
					if (err) {
						toastError(err);
					}
				})
			);
		} else {
			dispatch(
				createTreasuryAccount(item.id, err => {
					if (err) {
						toastError(err);
					}
				})
			);
		}
	};

	if (!item) return null;

	return (
		<>
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6">{ls.businessesAccount}</Typography>

					<div>
						{admin && (
							<Button color="primary" onClick={handleTreasuryAccount}>
								{textButton}
							</Button>
						)}
					</div>
				</div>
				<hr className={classes.line} />

				<Grid container spacing={2} className={classes.form}>
					{admin && (
						<>
							<GridButtonCopy item xs={12} sm={6} md={4} lg={4}>
								<strong>{ls.treasuryAccountHolder}</strong>:{" "}
								{item.treasuryAccountHolderId || ls.notDefined}
								{item.treasuryAccountHolderId && (
									<ButtonCopyClipboard
										valueCopy={item.treasuryAccountHolderId}
									/>
								)}
							</GridButtonCopy>
							<GridButtonCopy
								item
								xs={12}
								sm={8}
								md={6}
								lg={4}
								style={{ display: "flex", flexDirection: "row" }}
							>
								<strong>{ls.treasuryAccount}</strong>:{" "}
								{item.treasuryAccountId && admin && typeBAG !== undefined && (
									<LabelTypeBag typeAccount={typeBAG} />
								)}
								{item.treasuryAccountId || ls.notDefined}
								{item.treasuryAccountId && (
									<ButtonCopyClipboard valueCopy={item.treasuryAccountId} />
								)}
							</GridButtonCopy>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<strong>
									{ls.treasuryAccount} {ls.status}
								</strong>
								: {ls[item.treasuryAccountStatus]}
							</Grid>
						</>
					)}
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<strong>{ls.balance}</strong>:{" "}
						<MONEY
							displayType="text"
							value={
								(treasuryAccountBalance || (item.treasuryAccountBalance ?? 0)) /
								100.0
							}
						/>
						<IconButton
							onClick={handleGetTreasuryBalance}
							style={{
								background: "#5734DA",
								borderRadius: 4,
								width: 22,
								height: 22,
								marginLeft: 4
							}}
							size="small"
						>
							<IoRefreshOutline size={20} color="#ffffff" />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
		</>
	);
}

export default TreasuryAccount;
