import styled from "styled-components";

export const Container = styled.div`
	padding: 30px;
`;

export const Title = styled.div`
	text-align: left;
	font: normal normal 600 24px/32px Exo;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	margin-bottom: 20px;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	max-width: 1120px;
`;

export const Columns = styled.div`
	flex-direction: column;
	width: 50%;
	padding: 20px;
`;

export const BackgroundButton = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 20px;
	font: normal normal 600 18px Exo;
	padding: 6px;
`;
