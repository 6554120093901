import IconButton from "@material-ui/core/IconButton";
import Report from "Components/Report";
import TooltipLight from "Components/TooltipLight";
import { removeDiacritics } from "helpers/string";
import { toastWarning } from "helpers/toast";
import ls from "Localization";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaFileImport } from "react-icons/fa";
import XLSX from "xlsx";

const ProductsReport: React.FC<{
	skus?: Array<{ sku: number }>;
	onSelectReport: (products: any) => void;
}> = ({ skus, onSelectReport }) => {
	const table = useRef(null);

	const [products, setProducts] = useState<Array<{ sku: number }>>([]);
	const columns = [
		{
			Header: "SKU",
			accessor: "sku"
		}
	];
	const inputRef = useRef<HTMLInputElement>(null);

	const handleImport = useCallback(() => {
		inputRef.current.click();
	}, []);

	const handleFilesChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files[0];

			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			reader.onload = e => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
				/* Get first worksheet */
				const wsName = wb.SheetNames[0];
				const sheet = wb.Sheets[wsName];
				const ref = XLSX.utils.decode_range(sheet["!ref"]);
				for (let C = ref.s.c; C <= ref.e.c; ++C) {
					const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
					if (cell && cell.t === "s") {
						cell.v = removeDiacritics(cell.v.trim().toLowerCase());
						if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());
					}
				}

				const productsArray = XLSX.utils
					.sheet_to_json(sheet, {})
					.map((item: any) => {
						if (!item.sku) {
							toastWarning("A propriedade sku é obrigatório");
							return;
						}

						return item;
					});
				setProducts(productsArray);
				onSelectReport(productsArray);
			};

			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		},
		[onSelectReport]
	);

	useEffect(() => {
		setProducts(skus);
	}, [skus]);

	return (
		<>
			<Report
				manual
				tableRef={table}
				title={ls.products}
				data={products || []}
				columns={columns}
				showExport
				headerRightComponent={
					<div>
						<a
							href="https://docs.google.com/spreadsheets/d/1pFtkJwdTnjyUk_ukbDDjUR1O6MMXBr2njxcrIhY8m_Q/edit?usp=sharing"
							target="_blank"
							rel="noopener noreferrer"
							style={{ marginRight: 16 }}
						>
							Baixar modelo de importação
						</a>

						<TooltipLight title={ls.importProducts} placement="top">
							<IconButton onClick={handleImport}>
								<FaFileImport />
							</IconButton>
						</TooltipLight>
					</div>
				}
				defaultSorted={[
					{
						id: "sku",
						desc: true
					}
				]}
			/>

			<input
				ref={inputRef}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFilesChange}
			/>
		</>
	);
};

export default ProductsReport;
