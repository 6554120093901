import * as actions from "store/actions/product";

import { IProduct } from "./types";

const initialState: IProduct = {
	items: [],
	companiesTiny: [],
	companies: [],
	ifood: {},
	files: [],
	item: null,
	name: "",
	description: "",
	categoryId: "",
	businessId: "",
	price: 0,
	discount: 0,
	discountType: 0,
	delivery: false,
	isDigital: false,
	homeOffers: false,
	bannerLink: "",
	bannerFile: null,
	showQrCode: false,
	digitalProvider: "",
	digitalProviderCode: "",
	digitalUseInstructions: "",
	digitalUrl: "",
	tos: "",
	link: "",
	status: 1,
	count: 0,
	pages: 0,
	push: 0,
	showFormModal: false,
	showImportFormModal: false,
	showPromotionsFormModal: false,
	showCreatePromotionFormModal: false,
	showEditPromotionFormModal: false,
	showEditFormModal: false,
	showCompaniesFormModal: false,
	showModalBanner: false,
	showModalReportClicks: false,
	errors: {},
	loading: {},
	endDateType: false,
	fixedEndDate: null,
	dynamicEndDate: 0
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_PRODUCT: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}

		case actions.GET_PRODUCT_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.name = payload.name ?? initialState.name;
				newState.description = payload.description ?? initialState.description;
				newState.price = payload.price / 100;
				newState.discount = payload.discount / 100;
				newState.discountType =
					payload.discountType ?? initialState.discountType;
				newState.companies = payload.companies ?? initialState.companies;
				newState.businessId = payload.businessId ?? initialState.businessId;
				newState.categoryId = payload.categoryId ?? initialState.categoryId;
				newState.delivery = payload.delivery ?? initialState.delivery;
				newState.status = payload.status ?? initialState.status;
				newState.files = payload.files ?? initialState.files;
				newState.isDigital = payload.isDigital ?? initialState.isDigital;
				newState.showQrCode = payload.showQrCode ?? initialState.showQrCode;
				newState.digitalProvider =
					payload.digitalProvider ?? initialState.digitalProvider;
				newState.digitalProviderCode =
					payload.digitalProviderCode ?? initialState.digitalProviderCode;
				newState.digitalUseInstructions =
					payload.digitalUseInstructions ?? initialState.digitalUseInstructions;
				newState.digitalUrl = payload.digitalUrl ?? initialState.digitalUrl;
				newState.tos = payload.tos ?? initialState.tos;
				newState.endDateType =
					payload.isDynamicEndDate ?? initialState.endDateType;
				newState.fixedEndDate =
					payload.fixedEndDate ?? initialState.fixedEndDate;
				newState.dynamicEndDate =
					payload.dynamicEndDate ?? initialState.dynamicEndDate;
				newState.bannerFile = payload.bannerFile ?? initialState.bannerFile;
				newState.homeOffers = payload.homeOffers ?? initialState.homeOffers;
				newState.bannerLink = payload.bannerLink ?? initialState.bannerLink;
			} else {
				newState.item = null;
				newState.name = initialState.name;
				newState.price = initialState.price;
				newState.discount = initialState.discount;
				newState.categoryId = initialState.categoryId;
				newState.discountType = initialState.discountType;
				newState.companies = initialState.companies;
				newState.delivery = initialState.delivery;
				newState.status = initialState.status;
				newState.files = initialState.files;
				newState.isDigital = initialState.isDigital;
				newState.showQrCode = initialState.showQrCode;
				newState.digitalProvider = initialState.digitalProvider;
				newState.digitalProviderCode = initialState.digitalProviderCode;
				newState.digitalUseInstructions = initialState.digitalUseInstructions;
				newState.digitalUrl = initialState.digitalUrl;
				newState.tos = initialState.tos;
				newState.endDateType = initialState.endDateType;
				newState.fixedEndDate = initialState.fixedEndDate;
				newState.dynamicEndDate = initialState.dynamicEndDate;
				newState.bannerFile = initialState.bannerFile;
				newState.homeOffers = initialState.homeOffers;
				newState.bannerLink = initialState.bannerLink;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}

		case actions.GET_PRODUCT_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.GET_PRODUCTS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}

		case actions.GET_PRODUCTS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}

		case actions.GET_PRODUCTS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.CREATE_PRODUCT: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}

		case actions.CREATE_PRODUCT_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				name: initialState.name,
				price: initialState.price,
				discount: initialState.discount,
				categoryId: initialState.categoryId,
				discountType: initialState.discountType,
				companies: initialState.companies,
				delivery: initialState.delivery,
				status: initialState.status,
				files: initialState.files,
				isDigital: initialState.isDigital,
				showQrCode: initialState.showQrCode,
				digitalProviderCode: initialState.digitalProviderCode,
				digitalProvider: initialState.digitalProvider,
				digitalUseInstructions: initialState.digitalUseInstructions,
				digitalUrl: initialState.digitalUrl,
				tos: initialState.tos,
				endDateType: initialState.endDateType,
				fixedEndDate: initialState.fixedEndDate,
				dynamicEndDate: initialState.dynamicEndDate
			};
		}

		case actions.CREATE_PRODUCT_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}

		case actions.UPDATE_PRODUCT: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_PRODUCT_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				name: initialState.name,
				price: initialState.price,
				discount: initialState.discount,
				categoryId: initialState.categoryId,
				discountType: initialState.discountType,
				companies: initialState.companies,
				delivery: initialState.delivery,
				status: initialState.status,
				files: initialState.files,
				isDigital: initialState.isDigital,
				showQrCode: initialState.showQrCode,
				digitalProviderCode: initialState.digitalProviderCode,
				digitalProvider: initialState.digitalProvider,
				digitalUseInstructions: initialState.digitalUseInstructions,
				digitalUrl: initialState.digitalUrl,
				tos: initialState.tos,
				endDateType: initialState.endDateType,
				fixedEndDate: initialState.fixedEndDate,
				dynamicEndDate: initialState.dynamicEndDate
			};
		}

		case actions.UPDATE_PRODUCT_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}

		case actions.UPLOAD_PRODUCT_PHOTOS: {
			return {
				...state,
				loading: { photos: true },
				errors: initialState.errors
			};
		}

		case actions.UPLOAD_PRODUCT_PHOTOS_SUCCESS: {
			return {
				...state,
				loading: { photos: false },
				item: { ...state.item, files: [...state.item.files, ...payload] },
				files: [...state.item.files, ...payload]
			};
		}

		case actions.UPLOAD_PRODUCT_PHOTOS_FAILED: {
			return {
				...state,
				loading: { photos: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			return {
				...state,
				item: null,
				name: initialState.name,
				price: initialState.price,
				discount: initialState.discount,
				categoryId: initialState.categoryId,
				discountType: initialState.discountType,
				companies: initialState.companies,
				delivery: initialState.delivery,
				status: initialState.status,
				files: initialState.files,
				isDigital: initialState.isDigital,
				showQrCode: initialState.showQrCode,
				digitalProviderCode: initialState.digitalProviderCode,
				digitalProvider: initialState.digitalProvider,
				digitalUseInstructions: initialState.digitalUseInstructions,
				digitalUrl: initialState.digitalUrl,
				tos: initialState.tos,
				endDateType: initialState.endDateType,
				fixedEndDate: initialState.fixedEndDate,
				dynamicEndDate: initialState.dynamicEndDate,
				homeOffers: initialState.homeOffers,
				bannerLink: initialState.bannerLink,
				bannerFile: initialState.bannerFile
			};
		case actions.GET_BUSINESS_TINY: {
			return {
				...state,
				loading: { getStore: true },
				errors: initialState.errors
			};
		}

		case actions.GET_BUSINESS_TINY_SUCCESS: {
			return {
				...state,
				companiesTiny: payload || [],
				loading: { getStore: false }
			};
		}

		case actions.GET_BUSINESS_TINY_FAILED: {
			return {
				...state,
				loading: { getStore: false },
				errors: payload
			};
		}

		case actions.UPDATE_PRODUCT_COMPANIES: {
			return {
				...state,
				loading: { companies: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_PRODUCT_COMPANIES_SUCCESS: {
			return {
				...state,
				loading: { companies: false }
			};
		}

		case actions.UPDATE_PRODUCT_COMPANIES_FAILED: {
			return {
				...state,
				loading: { companies: false },
				errors: payload
			};
		}

		case actions.GET_IFOOD_PRODUCTS: {
			return {
				...state,
				loading: { ifood: true },
				errors: initialState.errors
			};
		}
		case actions.GET_IFOOD_PRODUCTS_SUCCESS: {
			return {
				...state,
				link: "",
				ifood: payload,
				loading: { ifood: false }
			};
		}
		case actions.GET_IFOOD_PRODUCTS_FAILED: {
			return {
				...state,
				loading: { ifood: false },
				errors: payload
			};
		}

		case actions.DELETE_PRODUCT: {
			return {
				...state,
				loading: { deleteProduct: true },
				errors: initialState.errors
			};
		}

		case actions.DELETE_PRODUCT_SUCCESS: {
			let { items } = state;

			items = items.filter(c => c.id !== payload);

			return {
				...state,
				items: [...items],
				loading: { deleteProduct: false }
			};
		}

		case actions.DELETE_PRODUCT_FAILED: {
			return {
				...state,
				loading: { deleteProduct: false },
				errors: payload
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
