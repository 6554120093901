import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

export const CREATE_CHARGE = "[CHARGE CREATE] CHARGE";
export const CREATE_CHARGE_SUCCESS = "[CHARGE CREATE] CREATE_CHARGE_SUCCESS";
export const CREATE_CHARGE_FAILED = "[CHARGE CREATE] CREATE_CHARGE_FAILED";

export const SET_VALUE = "[CHARGE CREATE] SET_VALUE";
export const CLEAR_VALUES = "[CHARGE CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createCharge(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_CHARGE });

		try {
			const { login, chargeCreate } = getState();

			const { type, value, description, clientId } = chargeCreate;

			const errors: any = {};

			if (!value) errors.value = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_CHARGE_FAILED,
					payload: errors
				});
			}

			const model = {
				value: Math.round(value * 100),
				type,
				description
			};

			const response = await api.sendPost(`/Charge/${clientId}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CHARGE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_CHARGE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_CHARGE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
