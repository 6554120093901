import { Grid } from "@material-ui/core";
import styled from "styled-components";

const styles = theme => ({
	root: {
		padding: theme.spacing(2)
	},
	form: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	row: {
		display: "flex"
	},
	alert: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		backgroundColor: "#cdeefd"
	},
	alertIcon: {
		marginRight: theme.spacing(2),
		color: "#02587f"
	},
	alertText: {
		color: "#02587f"
	},
	line: {
		marginLeft: -16,
		marginRight: -16
	},
	button: {
		margin: theme.spacing()
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	unlinkedUser: {
		marginLeft: 5
	},
	userImportedBy: {
		border: "0",
		color: "white",
		padding: 5,
		backgroundColor: "#00c89b",
		borderRadius: 5
	},
	userUndefinedImportedBy: {
		backgroundColor: "#f49a30"
	},
	userDefinedImportedBy: {
		backgroundColor: "#00c89b"
	}
});

export default styles;

export const GridButtonCopy = styled(Grid)`
	display: flex;
	flex-direction: row;
	align-items: center;
	background: none;

	& button {
		background: none;
		border: none;
	}
`;
