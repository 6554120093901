import React from "react";

import QrCodeBlob from "./QrCodeBlob";
import { Container, Text, TextPage } from "./styles";

interface PropsTemplatePDF {
	pixLink: string;
}

const TemplatePDF: React.FC<PropsTemplatePDF> = ({ pixLink }) => {
	const splitText = (text: string, maxLength: number) => {
		const words = text.split(" ");
		const lines = [];
		let currentLine = "";

		for (const word of words) {
			if ((currentLine + word).length <= maxLength) {
				currentLine += word + " ";
			} else {
				lines.push(currentLine.trim());
				currentLine = word + " ";
			}
		}

		if (currentLine.trim()) {
			lines.push(currentLine.trim());
		}

		return lines;
	};

	const lines = splitText(pixLink, 100);

	return (
		<div>
			<Container>
				<div
					style={{
						alignItems: "center",
						height: 170,
						width: 170,
						marginBottom: 20
					}}
				>
					<QrCodeBlob qrCodeValue={pixLink} />
				</div>
				<TextPage>
					{lines.map((line, index) => (
						<Text
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
								fontSize: 16,
								padding: 0,
								margin: 0,
								marginLeft: 16
							}}
							key={index}
						>
							{line}
						</Text>
					))}
				</TextPage>
			</Container>
		</div>
	);
};

export default TemplatePDF;
