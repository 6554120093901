import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";

export const EDIT_TRANSACTION = "[TRANSACTION EDIT] EDIT_TRANSACTION";
export const EDIT_TRANSACTION_SUCCESS =
	"[TRANSACTION EDIT] EDIT_TRANSACTION_SUCCESS";
export const EDIT_TRANSACTION_FAILED =
	"[TRANSACTION EDIT] EDIT_TRANSACTION_FAILED";

export const SET_VALUE = "[TRANSACTION EDIT] SET_VALUE";
export const CLEAR_VALUES = "[TRANSACTION EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function editTransaction(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_TRANSACTION });

		try {
			const { login, transactionEdit } = getState();

			const {
				item,
				creationDate,
				value,
				discount,
				installmentAmount,
				status,
				description
			} = transactionEdit;

			const errors: any = {};

			dispatch(setValue({ errors }));

			if (!value) errors.value = ls.required;
			else if (value < 0) errors.value = ls.invalid;

			if (discount < 0) errors.discount = ls.invalid;

			if (!installmentAmount) errors.installmentAmount = ls.required;
			else if (installmentAmount < 0) errors.installmentAmount = ls.invalid;

			if (_.keys(errors).length > 0) return;

			const model = {
				...item,
				creationDate,
				value: Math.round(value * 100),
				discount: Math.round(discount * 100),
				installmentAmount,
				status,
				description,
				// Clean unnecessary values to avoid errors
				client: undefined,
				company: undefined,
				store: undefined,
				historic: undefined,
				installments: undefined,
				products: undefined,
				mainSale: undefined,
				moderations: undefined,
				orders: undefined,
				splits: undefined,
				seller: undefined,
				transaction: undefined,
				properties: undefined,
				extraProperties: undefined
			};

			const response = await api.sendPut("/Sale/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: EDIT_TRANSACTION_SUCCESS
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_TRANSACTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_TRANSACTION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
