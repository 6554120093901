import styled from "styled-components";

interface PropsButton {
	isPurple?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px;
`;

export const Title = styled.h1`
	font: normal normal bold 24px;
	letter-spacing: 0px;
	margin-bottom: 20px;
	color: #464646;
	opacity: 1;
	padding: 0;
	margin: 0;
`;

export const Body = styled.div``;

export const TextBody = styled.p`
	font: normal normal normal 26px;
	letter-spacing: 0px;
	color: #464646;
	opacity: 1;
`;

export const RowButtons = styled.div`
	margin-top: 20px;
`;

export const Button = styled.button<PropsButton>`
	width: 138px;
	height: 46px;
	border: 1px solid #5734da;
	font-size: 18px;
	border-radius: 8px;
	opacity: 1;
	color: ${props => (props.isPurple ? "#fff" : "#5734da")};
	background-color: ${props => (props.isPurple ? "#5734da" : "#fff")};
	margin-right: 10px;
	cursor: pointer;
`;
