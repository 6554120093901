import { Button, Typography } from "@material-ui/core";
import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { importTransactions, setValue } from "store/actions/transaction/import";

import { Container } from "./styles";
import useStyles from "./styles";

let debounceImportTransactions: (() => void) & _.Cancelable = null;

function ImportTransactions({ open, items, handleClose, load }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { loading } = useSelector<any, any>(s => s.transactionImport);

	const [data, setData] = useState([]);

	useEffect(() => {
		dispatch(setValue({ loading: { import: false } }));
		setData(items || []);
	}, [dispatch, items]);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceImportTransactions) debounceImportTransactions.cancel();

		debounceImportTransactions = _.debounce(() => {
			dispatch(
				importTransactions(data, (err, transactions) => {
					if (err) {
						handleClose();
						toastError(ls.transactionsSentError);
					} else {
						handleClose(transactions);
						load();
						toastSuccess(ls.transactionsSentSuccess);
					}
				})
			);
		}, 1000);

		debounceImportTransactions();
	};

	const renderEditable = cellInfo => (
		<div
			style={{ backgroundColor: "#fafafa", textAlign: "center" }}
			// contentEditable
			suppressContentEditableWarning
			onBlur={e => {
				data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
				setData([...data]);
			}}
			dangerouslySetInnerHTML={{
				__html: data[cellInfo.index][cellInfo.column.id]
			}}
		/>
	);

	return (
		<ModalGeneric
			open={open}
			handleClose={handleClose}
			stylePaper={{
				width: "80%",
				borderRadius: 15,
				backgroundColor: "#fff"
			}}
			body={() => (
				<Container>
					<Typography variant="h6">{ls.importTransactions}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<ReactTable
								data={data}
								columns={
									[
										{
											Header: ls.creationDate,

											accessor: "creationDate",
											Cell: renderEditable,
											width: 160
										},
										{
											getProps: (_state, rowInfo) => {
												if (rowInfo && rowInfo.row) {
													return {
														style: {
															color: !rowInfo.row._original.isValidCpf && "red"
														},
														title: rowInfo.row._original.isValidCpf
															? ""
															: ls.ErrorCpfNotFound
													};
												}
												return {};
											},
											Header: ls.cpf,
											id: "cpf",
											accessor: "cpf",
											Cell: renderEditable,
											width: 160
										},
										{
											getProps: (_state, rowInfo) => {
												if (rowInfo && rowInfo.row) {
													return {
														style: {
															color: !rowInfo.row._original.isValidCnpj && "red"
														},
														title: rowInfo.row._original.isValidCnpj
															? ""
															: ls.ErrorCnpjNotFound
													};
												}
												return {};
											},
											Header: ls.storeCnpj,
											accessor: "storeCnpj",
											Cell: renderEditable,
											width: 160
										},
										{
											Header: ls.value,
											accessor: "value",
											Cell: renderEditable
										},
										{
											Header: ls.installmentsOptional,
											accessor: "installmentAmount",
											Cell: renderEditable,
											width: 160
										},
										{
											Header: ls.descriptionOptional,
											accessor: "description",
											Cell: renderEditable,
											width: 160
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>

							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={
										loading.import || data.some(row => row.isValidRow === false)
									}
								>
									{ls.import}
									{loading.import && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</Container>
			)}
		/>
	);
}

export default ImportTransactions;
