import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding-top: 20px;
	padding-bottom: 10px;
	padding-left: 20px;
	justify-content: space-between;
	p {
		font-size: 15px;
		font-weight: bold;
		color: #494949;
		margin-right: 30px;
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 15px;
	justify-content: center;
`;

export const Label = styled.p`
	font-size: 10px;
	margin-right: 15px;
`;
