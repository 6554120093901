export default {
	0: "cardIssue",
	1: "secondCard",
	2: "cardDelivery",
	3: "activeAccountCost",
	4: "inactivityFee",
	5: "taxCashWithdrawal",
	6: "limitRescueFee",
	7: "taxTransferBagWithdraw"
};
