/* eslint-disable */
import "./index.css";

import PropTypes from "prop-types";
import React from "react";
import FlipMove from "react-flip-move";

import UploadIcon from "./UploadIcon.svg";

const styles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	flexWrap: "wrap",
	width: "100%"
};

class ReactImageUploadComponent extends React.Component<any, any> {
	inputElement = null;

	constructor(props: any) {
		super(props);
		this.state = {
			pictures: props.defaultImage ? [props.defaultImage] : [],
			files: [],
			notAcceptedFileType: [],
			notAcceptedFileSize: []
		};
		this.inputElement = "";
		this.onDropFile = this.onDropFile.bind(this);
		this.onUploadClick = this.onUploadClick.bind(this);
		this.triggerFileUpload = this.triggerFileUpload.bind(this);
	}

	/*
   Load image at the beggining if defaultImage prop exists
   */
	componentWillReceiveProps(nextProps) {
		if (nextProps.defaultImage) {
			this.setState({ pictures: [nextProps.defaultImage] });
		}
	}

	componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
		if (prevState.files !== this.state.files) {
			this.props.onChange(this.state.files, this.state.pictures);
		}
	}

	/*
	 Check file extension (onDropFile)
	 */
	hasExtension(fileName) {
		const pattern =
			"(" + this.props.imgExtension.join("|").replace(/\./g, "\\.") + ")$";
		return new RegExp(pattern, "i").test(fileName);
	}

	/*
   Handle file validation
   */
	onDropFile(e) {
		const { files } = e.target;
		const allFilePromises = [];

		// Iterate over all uploaded files
		const notAcceptedFileType = [];
		const notAcceptedFileSize = [];

		for (let i = 0; i < files.length; i++) {
			let f = files[i];
			// Check for file extension
			if (!this.hasExtension(f.name)) {
				notAcceptedFileType.push(f.name);
				continue;
			}
			// Check for file size
			if (f.size > this.props.maxFileSize) {
				notAcceptedFileSize.push(f.name);
				continue;
			}

			allFilePromises.push(this.readFile(f));
		}

		this.setState({ notAcceptedFileSize, notAcceptedFileType });

		Promise.all(allFilePromises).then(newFilesData => {
			const dataURLs = this.state.pictures.slice();
			const files = this.state.files.slice();

			newFilesData.forEach(newFileData => {
				dataURLs.push(newFileData.dataURL);
				files.push(newFileData.file);
			});

			this.setState({ pictures: dataURLs, files });
		});
	}

	onUploadClick(e) {
		// Fixes https://github.com/JakeHartnell/react-images-upload/issues/55
		e.target.value = null;
	}

	/*
     Read a file and return a promise that when resolved gives the file itself and the data URL
   */
	readFile(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			// Read the image via FileReader API and save image result in state.
			reader.onload = function (e) {
				// Add the file name to the data URL
				let dataURL = e.target.result as string;
				dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
				resolve({ file, dataURL });
			};

			reader.readAsDataURL(file);
		});
	}

	/*
   Remove the image from state
   */
	removeImage(picture) {
		const removeIndex = this.state.pictures.findIndex(e => e === picture);
		const filteredPictures = this.state.pictures.filter(
			(e, index) => index !== removeIndex
		);
		const filteredFiles = this.state.files.filter(
			(e, index) => index !== removeIndex
		);

		this.setState({ pictures: filteredPictures, files: filteredFiles }, () => {
			this.props.onChange(this.state.files, this.state.pictures);
			this.props.onDelete && this.props.onDelete(picture);
		});
	}

	/*
   Check if any errors && render
   */
	renderErrors() {
		let notAccepted = "";
		if (this.state.notAcceptedFileType.length > 0) {
			notAccepted = this.state.notAcceptedFileType.map((error, index) => (
				<div
					className={"errorMessage " + this.props.errorClass}
					key={index}
					style={this.props.errorStyle}
				>
					* {error} {this.props.fileTypeError}
				</div>
			));
		}
		if (this.state.notAcceptedFileSize.length > 0) {
			notAccepted = this.state.notAcceptedFileSize.map((error, index) => (
				<div
					className={"errorMessage " + this.props.errorClass}
					key={index}
					style={this.props.errorStyle}
				>
					* {error} {this.props.fileSizeError}
				</div>
			));
		}
		return notAccepted;
	}

	/*
   Render the upload icon
   */
	renderIcon() {
		if (this.props.withIcon) {
			return <img src={UploadIcon} className="uploadIcon" alt="Upload Icon" />;
		}
	}

	/*
   Render label
   */
	renderLabel() {
		if (this.props.withLabel) {
			return (
				<p
					className={"label " + this.props.labelClass}
					style={this.props.labelStyles}
				>
					{this.props.label}
				</p>
			);
		}
	}

	/*
   Render preview images
   */
	renderPreview() {
		return (
			<div className="uploadPicturesWrapper">
				<FlipMove enterAnimation="fade" leaveAnimation="fade" style={styles}>
					{this.renderPreviewPictures()}
				</FlipMove>
			</div>
		);
	}

	renderPreviewPictures() {
		return this.state.pictures.map((picture, index) => (
			<div key={index} className="uploadPictureContainer">
				<div className="deleteImage" onClick={() => this.removeImage(picture)}>
					X
				</div>
				<img src={picture} className="uploadPicture" alt="preview" />
			</div>
		));
	}

	/*
   On button click, trigger input file to open
   */
	triggerFileUpload() {
		this.inputElement.click();
	}

	clearPictures() {
		this.setState({ pictures: [] });
	}

	render() {
		const { hideOnPreview, withPreview } = this.props;
		const { pictures } = this.state;

		return (
			<div
				className={"fileUploader " + this.props.className}
				style={this.props.style}
			>
				<div
					className={
						"fileContainer " + (pictures[0] ? "" : "fileContainerWithoutImage ")
					}
					style={this.props.fileContainerStyle}
				>
					{withPreview && hideOnPreview && pictures[0]
						? null
						: this.renderIcon()}
					{withPreview && hideOnPreview && pictures[0]
						? null
						: this.renderLabel()}
					<div className="errorsContainer">{this.renderErrors()}</div>
					{withPreview && hideOnPreview && pictures[0] ? null : (
						<button
							type={this.props.buttonType}
							className={"chooseFileButton " + this.props.buttonClassName}
							style={this.props.buttonStyles}
							onClick={this.triggerFileUpload}
						>
							{this.props.buttonText}
						</button>
					)}
					<input
						type="file"
						ref={input => (this.inputElement = input)}
						name={this.props.name}
						multiple={!this.props.singleImage}
						onChange={this.onDropFile}
						onClick={this.onUploadClick}
						accept={this.props.accept}
					/>
					{this.props.withPreview ? this.renderPreview() : null}
				</div>
			</div>
		);
	}
}

ReactImageUploadComponent["defaultProps"] = {
	className: "",
	fileContainerStyle: {},
	buttonClassName: "",
	buttonStyles: {},
	withPreview: false,
	accept: "image/*",
	name: "",
	withIcon: true,
	buttonText: "Choose images",
	buttonType: "button",
	withLabel: true,
	label: "Max file size: 5mb, accepted: jpg|gif|png",
	labelStyles: {},
	labelClass: "",
	imgExtension: [".jpg", ".jpeg", ".gif", ".png"],
	maxFileSize: 5242880,
	fileSizeError: " file size is too big",
	fileTypeError: " is not a supported file extension",
	errorClass: "",
	style: {},
	errorStyle: {},
	singleImage: false,
	onChange: () => {},
	defaultImage: ""
};

ReactImageUploadComponent["propTypes"] = {
	style: PropTypes.object,
	fileContainerStyle: PropTypes.object,
	className: PropTypes.string,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	buttonClassName: PropTypes.string,
	buttonStyles: PropTypes.object,
	buttonType: PropTypes.string,
	withPreview: PropTypes.bool,
	accept: PropTypes.string,
	name: PropTypes.string,
	withIcon: PropTypes.bool,
	buttonText: PropTypes.string,
	withLabel: PropTypes.bool,
	label: PropTypes.string,
	labelStyles: PropTypes.object,
	labelClass: PropTypes.string,
	imgExtension: PropTypes.array,
	maxFileSize: PropTypes.number,
	fileSizeError: PropTypes.string,
	fileTypeError: PropTypes.string,
	errorClass: PropTypes.string,
	errorStyle: PropTypes.object,
	singleImage: PropTypes.bool,
	defaultImage: PropTypes.string
};

export default ReactImageUploadComponent;
