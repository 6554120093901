import { Button, Grid, Paper, Typography } from "@material-ui/core";
import AboutTab from "Components/Marketing/AboutTab";
import EmailTab from "Components/Marketing/EmailTab";
import PushTab from "Components/Marketing/PushTab";
import SendToTab from "Components/Marketing/SendToTab";
import SmsTab from "Components/Marketing/SmsTab";
import Tabs from "Components/Marketing/Tabs";
import WhatsAppTab from "Components/Marketing/WhatsAppTab";
import { getExcelData } from "Components/Report/export";
import Spinner from "Components/Spinner";
import dayjs from "dayjs";
import { CustomFile, handleChangeEvent } from "helpers/files";
import ls from "Localization";
import _ from "lodash";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import ReactExport from "react-export-excel";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	createMarketing,
	setValue
} from "store/actions/marketing";
import { getEnabledUsers } from "store/actions/user/report";

import useStyles from "./styles";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

let debounceCreateMarketing: (() => void) & _.Cancelable = null;

const handleFilterUsers = (c, value) =>
	`${c.fullName?.toLowerCase()} - ${c.businessName?.toLowerCase()}`.indexOf(
		value.toLowerCase()
	) !== -1;

export function MarketingCreate({ history }) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [tab, setTab] = useState(0);
	const [whatsAppImage, setWhatsAppImage] = useState<CustomFile>(null);
	const [previewImage, setPreviewImage] = useState<string>("");
	const inputFileRef = useRef(null);
	const [filterIn, setFilterIn] = useState("");
	const [filterOut, setFilterOut] = useState("");

	const [exportData, setExportData] = useState(null);

	const [filterSelected, setFilterSelected] = useState("");

	useEffect(() => {
		dispatch(getEnabledUsers(filterSelected));
	}, [dispatch, filterSelected]);

	const {
		name,
		startAt,
		economicMode,
		emailSubject,
		emailMessage,
		smsMessage,
		whatsAppFile,
		whatsAppMessage,
		whatsAppRestrict,
		pushTitle,
		pushMessage,
		pushAction,
		pushValue,
		users,
		errors,
		loading
	} = useSelector<any, any>(s => s.marketing);

	const usersState = useSelector<any, any>(s => s.userReport);

	const usersEnabled = useMemo(() => {
		if (!usersState.enabled?.length) return [];

		const users = [];

		if (emailMessage) {
			users.push(...usersState.enabled.filter(c => c.email));
		}

		if (smsMessage) {
			users.push(
				...usersState.enabled.filter(
					c => !users.find(u => u === c) && c.phoneNumber > 0
				)
			);
		}

		if (whatsAppMessage) {
			users.push(
				...usersState.enabled.filter(
					c => !users.find(u => u === c) && c.sale > 0
				)
			);
		}

		if (pushMessage) {
			users.push(
				...usersState.enabled.filter(
					c => !users.find(u => u === c) && c.pushToken > 0
				)
			);
		}

		return users;
	}, [
		usersState.enabled,
		emailMessage,
		smsMessage,
		whatsAppMessage,
		pushMessage
	]);

	const handleExport = useCallback(() => {
		const title = name || "marketing_usuarios";

		const data = getExcelData(
			[
				{ id: "businessName", Header: "Empresa" },
				{ id: "fullName", Header: "Nome completo" },
				{ id: "cpf", Header: "CPF" },
				{ id: "email", Header: "E-mail" },
				{ id: "phoneNumber", Header: "Telefone" }
			],
			usersEnabled
		);

		setExportData({ title, data });

		setTimeout(() => {
			setExportData(null);
		}, 1000);
	}, [usersEnabled, name]);

	const handleChange = useCallback(
		(name: string, value: any) => {
			dispatch(
				setValue({
					[name]: value
				})
			);
		},
		[dispatch]
	);

	const handleRight = () => {
		let newUsers = usersEnabled
			.filter(
				c =>
					!users.find(u => u.userId === c.id) && handleFilterUsers(c, filterOut)
			)
			.map(c => ({ userId: c.id }));

		handleChange("users", [...users, ...newUsers]);

		setFilterOut("");
	};

	const handleLeft = () => {
		let keep = [];
		if (filterIn) {
			let filtered = usersEnabled.filter(c => handleFilterUsers(c, filterIn));

			keep = users.filter(u => !filtered.find(c => c.id === u.userId));
		}

		handleChange("users", keep);
		setFilterIn("");
	};

	const handleUserChange = (items: any[], index: number) => {
		let item = users.find(c => c.userId === items[index].id);

		if (item) {
			handleChange(
				"users",
				users.filter(c => c !== item)
			);
		} else {
			handleChange("users", [...users, { userId: items[index].id }]);
		}
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (debounceCreateMarketing) debounceCreateMarketing.cancel();

		debounceCreateMarketing = _.debounce(() => {
			dispatch(
				createMarketing(whatsAppImage, (err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push(`/marketing/${model.id ? model.id : model}`);
					}
				})
			);
		}, 1000);

		debounceCreateMarketing();
	};

	const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = handleChangeEvent(e);

		if (files && files.length) {
			const file: any = files[0];
			const preview = URL.createObjectURL(file);

			if (preview) {
				handleChange("whatsAppFile", null);
			}

			setWhatsAppImage(file);
			setPreviewImage(preview);
		}
	};

	let filteredIn = usersEnabled.filter(c => handleFilterUsers(c, filterIn));

	let filteredOut = usersEnabled.filter(c => handleFilterUsers(c, filterOut));

	let usersIn = filteredIn.filter(c => users.find(u => u.userId === c.id));

	let usersNotIn = filteredOut.filter(c => !users.find(u => u.userId === c.id));

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<Typography variant="h6">Criar nova campanha</Typography>
					<hr className={classes.line} />

					<form noValidate onSubmit={onSubmit}>
						<Tabs onChangeTab={setTab} tab={tab} usersState={usersState} />
						<AboutTab
							economicMode={economicMode}
							errors={errors}
							filterSelected={filterSelected}
							handleChange={handleChange}
							index={0}
							name={name}
							onSelectFilter={setFilterSelected}
							startAt={startAt}
							tab={tab}
						/>
						<EmailTab
							emailMessage={emailMessage}
							emailSubject={emailSubject}
							errors={errors}
							handleChange={handleChange}
							index={1}
							tab={tab}
						/>
						<SmsTab
							errors={errors}
							handleChange={handleChange}
							index={2}
							smsMessage={smsMessage}
							tab={tab}
						/>
						<WhatsAppTab
							tab={tab}
							index={3}
							clearWhatsAppImage={() => setWhatsAppImage(null)}
							errors={errors}
							handleChange={handleChange}
							inputFileRef={inputFileRef}
							previewImage={previewImage}
							selectFile={selectFile}
							whatsAppFile={whatsAppFile}
							whatsAppImage={whatsAppImage}
							whatsAppMessage={whatsAppMessage}
							whatsAppRestrict={whatsAppRestrict}
						/>
						<PushTab
							tab={tab}
							index={4}
							errors={errors}
							handleChange={handleChange}
							pushAction={pushAction}
							pushMessage={pushMessage}
							pushTitle={pushTitle}
							pushValue={pushValue}
						/>

						<SendToTab
							index={5}
							tab={tab}
							handleChange={handleChange}
							errors={errors}
							filterIn={filterIn}
							filterOut={filterOut}
							handleExport={handleExport}
							handleLeft={handleLeft}
							handleRight={handleRight}
							handleUserChange={handleUserChange}
							onChangeFilterIn={setFilterIn}
							onChangeFilterOut={setFilterOut}
							usersEnabled={usersEnabled}
							usersIn={usersIn}
							usersNotIn={usersNotIn}
						/>
						<hr className={classes.line} />
						<Grid container justify="flex-end">
							<Button
								variant="contained"
								color="default"
								className={classes.button}
								onClick={() => dispatch(clearValues())}
							>
								{ls.clear}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								type="submit"
							>
								{ls.save}
								{loading.create && <Spinner color="secondary" size={16} />}
							</Button>
						</Grid>
					</form>
				</Paper>
			</Grid>
			{exportData && (
				<ExcelFile
					filename={`${exportData.title}_${dayjs().format(
						"YYYY-MM-DD-kk-mm-ss"
					)}`}
					hideElement
				>
					<ExcelSheet dataSet={exportData.data} name={exportData.title} />
				</ExcelFile>
			)}
		</Grid>
	);
}

export default MarketingCreate;
