import * as actions from "store/actions/user/detail";

import { IDetailUser } from "../types/detail";

const initialState: IDetailUser = {
	item: null,
	limit: 0,
	credit: 0,
	transactions: [],
	allowances: [],
	properties: [],
	balanceAccounts: [],
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_USER: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_USER_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload
			};
		}

		case actions.GET_USER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}

		case actions.GET_USER_LIMIT: {
			return {
				...state,
				loading: {
					...state.loading,
					getLimit: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_USER_LIMIT_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					getLimit: false
				},
				limit: payload.limit,
				limitTotal: payload.limitTotal,
				credit: payload.credit
			};
		}

		case actions.GET_USER_LIMIT_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getLimit: false
				},
				errors: payload
			};
		}

		case actions.GET_USER_TRANSACTIONS: {
			return {
				...state,
				loading: {
					...state.loading,
					getTransactions: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_USER_TRANSACTIONS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					getTransactions: false
				},
				transactions: payload || []
			};
		}

		case actions.GET_USER_TRANSACTIONS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getAllowances: false
				},
				errors: payload
			};
		}

		case actions.GET_USER_ALLOWANCE_POLICIES: {
			return {
				...state,
				loading: {
					...state.loading,
					getAllowances: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_USER_ALLOWANCE_POLICIES_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					getAllowances: false
				},
				allowances: payload || []
			};
		}

		case actions.GET_USER_ALLOWANCE_POLICIES_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getTransactions: false
				},
				errors: payload
			};
		}

		case actions.EDIT_USER_BENEFITS: {
			return {
				...state,
				loading: { benefits: true },
				errors: initialState.errors
			};
		}

		case actions.EDIT_USER_BENEFITS_SUCCESS: {
			return {
				...state,
				loading: { benefits: false }
			};
		}

		case actions.EDIT_USER_BENEFITS_FAILED: {
			return {
				...state,
				loading: { benefits: false },
				errors: payload
			};
		}

		case actions.ACTIVE_USER: {
			return {
				...state,
				loading: {
					...state.loading,
					active: true
				},
				errors: initialState.errors
			};
		}

		case actions.ACTIVE_USER_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					active: false
				}
			};
		}

		case actions.ACTIVE_USER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					active: false
				},
				errors: payload
			};
		}

		case actions.CHANGE_PASSWORD: {
			return {
				...state,
				loading: {
					...state.loading,
					password: true
				},
				errors: initialState.errors
			};
		}

		case actions.CHANGE_PASSWORD_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					password: false
				}
			};
		}

		case actions.CHANGE_PASSWORD_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					password: false
				},
				errors: payload
			};
		}

		case actions.GET_EXTRA_PROPERTIES: {
			return {
				...state,
				loading: {
					...state.loading,
					getProperties: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_EXTRA_PROPERTIES_SUCCESS: {
			return {
				...state,
				properties: payload,
				loading: {
					...state.loading,
					getProperties: false
				}
			};
		}

		case actions.GET_EXTRA_PROPERTIES_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getProperties: false
				},
				errors: payload
			};
		}

		case actions.GET_PREPAID_BALANCEACCCOUNT_USER: {
			return {
				...state,
				loading: {
					...state.loading,
					getBalanceAccounts: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_PREPAID_BALANCEACCCOUNT_USER_SUCCESS: {
			return {
				...state,
				balanceAccounts: payload,
				loading: {
					...state.loading,
					getBalanceAccounts: false
				}
			};
		}

		case actions.GET_PREPAID_BALANCEACCCOUNT_USER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getBalanceAccounts: false
				},
				errors: payload
			};
		}

		case actions.GET_USER_FROM_SWAP_SUCCESS: {
			return {
				...state,
				userSwap: payload,
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			return {
				...initialState
			};

		default: {
			return state;
		}
	}
};

export default reducer;
