import { getErrors } from "helpers/error";
import { CNPJValidation, CPFValidation } from "helpers/validators";
import { isValidNumber } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

export const GET_BUSINESS = "[BUSINESS EDITs] GET_BUSINESS";
export const GET_BUSINESS_SUCCESS = "[BUSINESS EDITs] GET_BUSINESS_SUCCESS";
export const GET_BUSINESS_FAILED = "[BUSINESS EDITs] GET_BUSINESS_FAILED";

export const EDIT_BUSINESS = "[BUSINESS EDIT] EDIT_BUSINESS";
export const EDIT_BUSINESS_SUCCESS = "[BUSINESS EDIT] EDIT_BUSINESS_SUCCESS";
export const EDIT_BUSINESS_FAILED = "[BUSINESS EDIT] EDIT_BUSINESS_FAILED";

export const SET_VALUE = "[BUSINESS EDIT] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getBusiness(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS });

		try {
			const { login } = getState();
			const response = await api.sendGet(`/Business/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);

			dispatch({
				type: GET_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editBusiness(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_BUSINESS });

		try {
			const { login, businessEdit } = getState();

			const {
				item,
				name,
				socialName,
				cnpj,
				phoneNumber,
				street,
				number,
				complement,
				neighborhood,
				city,
				state,
				linkType,
				link,
				zipCode,
				country,
				category: categoryId,
				status,
				highlight,
				national,
				teaser,
				hideContactInfo,
				hideLocationInfo,
				about,
				isStore,
				isCompany,
				isHeadquarters,
				isFranchise,
				hasWiipoFlex,
				hasWiipoClub,
				hasWiipoRequest,
				hasHCMIntegrator,
				isHcmPartial
			} = businessEdit;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!socialName) errors.socialName = ls.required;

			if (!cnpj) errors.cnpj = ls.required;
			else if (!(CNPJValidation(cnpj) || CPFValidation(cnpj))) {
				errors.cnpj = ls.invalidCnpj;
			}

			if (!phoneNumber) errors.phoneNumber = ls.required;
			else if (!isValidNumber(phoneNumber)) {
				errors.phoneNumber = ls.invalidPhoneNumber;
			}

			if (!national) {
				if (!street) errors.street = ls.emptyStreet;
				if (!number) errors.number = ls.emptyNumber;
				if (!neighborhood) errors.neighborhood = ls.required;
				if (!city) errors.city = ls.emptyCity;
				if (!state) errors.state = ls.required;
				if (!zipCode) errors.zipCode = ls.required;
			}

			if (!country) errors.country = ls.required;

			let type = 0;

			if (isStore) type += 1;
			if (isCompany) type += 2;
			if (isHeadquarters) type += 4;
			if (isFranchise) type += 8;

			if (isStore && !categoryId) errors.category = ls.required;
			if (!status && status !== 0) errors.status = ls.required;
			if (!type) errors.type = "Selecione pelo menos uma opção";
			if (link) {
				if (!(link.startsWith("http://") || link.startsWith("https://"))) {
					errors.link = "Um link deve iniciar com http:// ou https://";
				}
			}

			let businessServices = 0;

			if (hasWiipoFlex) businessServices += 1;
			if (hasWiipoClub) businessServices += 4;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: EDIT_BUSINESS_FAILED,
					payload: errors
				});
			}

			let { documents } = item;

			let document = documents.find(c => c.type === 0);

			if (document) {
				document.value = cnpj;
			} else {
				documents.push({
					type: 0,
					value: cnpj
				});
			}

			let response = await api.sendPut(
				`/Business/${item.id}`,
				{
					...item,
					name,
					socialName,
					categoryId,
					status,
					type,
					service: businessServices,
					phoneNumber,
					highlight,
					national,
					teaser,
					hideContactInfo,
					hideLocationInfo,
					documents,
					linkType: linkType || 0,
					link: link || "",
					address: {
						...item.address,
						street,
						number,
						complement,
						neighborhood,
						city,
						state,
						country,
						zipCode
					},
					about,
					addresses: undefined,
					category: undefined,
					config: undefined,
					phoneNumbers: undefined,
					responsible: undefined,
					users: undefined,
					files: undefined,
					hasHCMIntegrator,
					hasWiipoFlex,
					hasWiipoClub,
					hasWiipoRequest,
					isHcmPartial
				},
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			dispatch(setValue({ errors, loading: { edit: false } }));

			if (result.isValid) {
				dispatch({
					type: EDIT_BUSINESS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_BUSINESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: EDIT_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
