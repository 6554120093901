import { Grid, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import Spinner from "Components/Spinner";
import Switch from "Components/Switch";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FaCalendar, FaPlus } from "react-icons/fa";
import { MdEdit, MdPeople, MdStore, MdViewModule } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	getAllowance,
	getAllowances,
	setValue
} from "store/actions/allowance";

import CategoriesForm from "../Forms/Categories";
import CreateForm from "../Forms/Create";
import DaysForm from "../Forms/Days";
import EditForm from "../Forms/Edit";
import StoresForm from "../Forms/Stores";
import UsersForm from "../Forms/Users";
import styles from "./styles";

let getAllowancesDebounced: (() => void) & _.Cancelable = null;

function Allowances({ business }) {
	const dispatch = useDispatch();

	const table = useRef(null);

	const [loadingSub, setLoadingSub] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);
	const [getAllowancePolicies, setGetAllowancePolicies] = useState(false);
	const [filterAllowance, setFilterAllowance] = useState([]);

	const state = useSelector<any, any>(c => c.allowance);

	const loadData = useCallback(() => {
		dispatch(
			getAllowances(
				null,
				null,
				`&filters[companyId]=${business.id}`,
				null,
				null,
				null,
				null
			)
		);
		setIsLoaded(true);
	}, [dispatch, business.id]);

	const handleChange = useCallback(
		(id: string, value: any) => {
			if (["showFormModal"].includes(id)) {
				dispatch(clearValues());
			}

			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(id, include, item) => {
			setLoadingSub({ [id]: item.id });
			dispatch(
				getAllowance(item.id, include, (err, model) => {
					if (err) {
						console.log(err);
					} else {
						handleChange(id, Boolean(model));
					}
					setLoadingSub({ [id]: false });
				})
			);
		},
		[dispatch, handleChange]
	);

	const handleFetchData = useCallback(
		(tableState, _instance, value?) => {
			if (!isLoaded) {
				return;
			}
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getAllowancesDebounced) {
				getAllowancesDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let startAt = filtered.find(c => c.id === "startDate");
			let endAt = filtered.find(c => c.id === "endDate");

			if (startAt?.value) {
				filtered = filtered.filter(c => c.id !== "startDate");
				let date = null;

				if (startAt?.value.startDate || startAt?.value.endDate) {
					date = {
						startAt: startAt.value.startDate,
						endAt: startAt.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "startDate",
						value: JSON.stringify(date)
					});
				}
			}

			if (endAt?.value) {
				filtered = filtered.filter(c => c.id !== "endDate");
				let date = null;

				if (endAt?.value.startDate || endAt?.value.endDate) {
					date = {
						startAt: endAt.value.startDate,
						endAt: endAt.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "endDate",
						value: JSON.stringify(date)
					});
				}
			}

			if (value) {
				filterAllowance.push({
					id: "status",
					value: 1
				});
			} else {
				filterAllowance.push({
					id: "status",
					value: "all"
				});
			}

			const filter = `&filters[companyId]=${business.id}${filtered.reduce(
				(p, c) => `${p}&filters[${c.id}]=${c.value}`,
				""
			)}${filterAllowance.reduce(
				(p, c) => `${p}&filters[${c.id}]=${c.value}`,
				""
			)}`;

			getAllowancesDebounced = _.debounce(
				() =>
					dispatch(
						getAllowances(
							page * pageSize,
							pageSize,
							filter,
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getAllowancesDebounced();
			setFilterAllowance([]);
		},
		[business.id, dispatch, filterAllowance, getAllowancePolicies, isLoaded]
	);

	const columns = useMemo(
		() => [
			{
				Header: ls.name,
				id: "name",
				show: true,
				accessor: c => (c.name ? c.name : ls.notInformed)
			},
			{
				Header: ls.start,
				id: "startDate",
				accessor: c =>
					c.startDate
						? dayjs(c.startDate).format(ls.dateFormatWithoutSeconds)
						: ls.notInformed,
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.end,
				id: "endDate",
				accessor: c =>
					c.endDate
						? dayjs(c.endDate).format(ls.dateFormatWithoutSeconds)
						: ls.notInformed,
				width: 180,
				show: true,
				sortMethod: (a, b, _desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.createdBy,
				id: "createdBy",
				show: true,
				accessor: c => (c.createdBy ? c.createdBy.fullName : ls.notInformed),
				width: 120
			},
			{
				Header: ls.updatedBy,
				id: "updatedBy",
				show: true,
				accessor: c => (c.updatedBy ? c.updatedBy.fullName : ls.notInformed),
				width: 120
			},
			{
				Header: ls.actions,
				id: "actions",
				filterable: false,
				accessor: c => (
					<Grid container spacing={2} justify="center">
						<Grid item>
							{loadingSub["showDaysFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1rem"
									}}
									onClick={() => handleEdit("showDaysFormModal", "days", c)}
								>
									<FaCalendar />
								</IconButton>
							)}
							{loadingSub["showCategoryFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() =>
										handleEdit("showCategoryFormModal", "categories", c)
									}
								>
									<MdViewModule />
								</IconButton>
							)}
							{loadingSub["showStoreFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showStoreFormModal", "stores", c)}
								>
									<MdStore />
								</IconButton>
							)}
							{loadingSub["showUserFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showUserFormModal", "users", c)}
								>
									<MdPeople />
								</IconButton>
							)}
							{loadingSub["showEditFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showEditFormModal", undefined, c)}
								>
									<MdEdit />
								</IconButton>
							)}
						</Grid>
					</Grid>
				),
				width: 200
			}
		],
		[handleEdit, loadingSub]
	);

	const load = useCallback(
		value => handleFetchData(table.current?.state, null, value),
		[handleFetchData]
	);

	return (
		<LoadOnScroll load={loadData}>
			<Report
				manual
				tableRef={table}
				title={
					getAllowancePolicies
						? ls.allowancePoliciesActive
						: ls.allowancePolicies
				}
				componentTitle={
					<Switch
						id="getAllowancePoliciesToogle"
						name="getAllowancePoliciesToogle"
						value={getAllowancePolicies}
						checked={getAllowancePolicies}
						onChange={(id, value) => {
							setGetAllowancePolicies(value);
							load(value);
						}}
						color="primary"
					/>
				}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				headerRightComponent={
					<div>
						<IconButton
							color="primary"
							onClick={() => handleChange("showFormModal", true)}
						>
							<FaPlus />
						</IconButton>
					</div>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "externalId",
						desc: false
					}
				]}
			/>

			<CreateForm load={load} value={getAllowancePolicies} />
			<EditForm load={load} value={getAllowancePolicies} />
			<DaysForm />
			<UsersForm business={business} />
			<StoresForm />
			<CategoriesForm />
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(Allowances);
