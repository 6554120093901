import QRCode from "qrcode";
import React, { useEffect, useState } from "react";

interface PropsQrCodeBlob {
	qrCodeValue: string;
}

const QrCodeBlob: React.FC<PropsQrCodeBlob> = ({ qrCodeValue }) => {
	const [blobUrl, setBlobUrl] = useState("");

	useEffect(() => {
		const generateBlob = async () => {
			const canvas = document.createElement("canvas");
			await QRCode.toCanvas(canvas, qrCodeValue);
			canvas.toBlob(blob => {
				const url = URL.createObjectURL(blob);
				setBlobUrl(url);
			});
		};

		generateBlob();
	}, [qrCodeValue]);

	return <img src={blobUrl} alt="Exported QR Code" width={200} height={200} />;
};

export default QrCodeBlob;
