import { FormControlLabel } from "@material-ui/core";
import CheckboxMN, {
	CheckboxProps as CheckboxPropsMN
} from "@material-ui/core/Checkbox";
import React from "react";

import ls from "../../Localization";
import useStyles from "./styles";

interface CheckboxProps extends Omit<CheckboxPropsMN, "onChange"> {
	id: string;
	name: string;
	onChange(id: string, value: boolean): void;
	hideLabel?: boolean;
	stylesCheckBox?: React.CSSProperties;
}

function Checkbox(props: CheckboxProps) {
	const classes = useStyles({});

	const { id, name, checked, onChange, hideLabel, stylesCheckBox } = props;

	return (
		<FormControlLabel
			control={
				<CheckboxMN
					style={stylesCheckBox}
					classes={classes}
					checked={checked}
					onChange={e => onChange(id, e.target.checked)}
					value={name}
				/>
			}
			label={hideLabel ? undefined : ls[name] || name}
		/>
	);
}

Checkbox.defaultProps = {
	hideLabel: false,
	stylesCheckBox: {}
};

export default Checkbox;
