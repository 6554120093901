import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_ALL_CASHBACK = "[CASHBACK] GET_ALL_CASHBACK";
export const GET_ALL_CASHBACK_SUCCESS = "[CASHBACK] GET_ALL_CASHBACK_SUCCESS";
export const GET_ALL_CASHBACK_FAILED = "[CASHBACK] GET_ALL_CASHBACK_FAILED";

export const GET_CASHBACK = "[CASHBACK] GET_CASHBACK";
export const GET_CASHBACK_SUCCESS = "[CASHBACK] GET_CASHBACK_SUCCESS";
export const GET_CASHBACK_FAILED = "[CASHBACK] GET_CASHBACK_FAILED";

export const GET_COMPANY_TINY = "[CASHBACK] GET_COMPANY_TINY";
export const GET_COMPANY_TINY_SUCCESS = "[CASHBACK] GET_COMPANY_TINY_SUCCESS";
export const GET_COMPANY_TINY_FAILED = "[CASHBACK] GET_COMPANY_TINY_FAILED";

export const GET_STORE_TINY = "[CASHBACK] GET_STORE_TINY";
export const GET_STORE_TINY_SUCCESS = "[CASHBACK] GET_STORE_TINY_SUCCESS";
export const GET_STORE_TINY_FAILED = "[CASHBACK] GET_STORE_TINY_FAILED";

export const CREATE_CASHBACK = "[CASHBACK] CREATE_CASHBACK";
export const CREATE_CASHBACK_SUCCESS = "[CASHBACK] CREATE_CASHBACK_SUCCESS";
export const CREATE_CASHBACK_FAILED = "[CASHBACK] CREATE_CASHBACK_FAILED";

export const UPDATE_CASHBACK_COMPANIES = "[CASHBACK] UPDATE_CASHBACK_COMPANIES";
export const UPDATE_CASHBACK_COMPANIES_SUCCESS =
	"[CASHBACK] UPDATE_CASHBACK_COMPANIES_SUCCESS";
export const UPDATE_CASHBACK_COMPANIES_FAILED =
	"[CASHBACK] UPDATE_CASHBACK_COMPANIES_FAILED";

export const UPDATE_CASHBACK_STORES = "[CASHBACK] UPDATE_CASHBACK_STORES";
export const UPDATE_CASHBACK_STORES_SUCCESS =
	"[CASHBACK] UPDATE_CASHBACK_STORES_SUCCESS";
export const UPDATE_CASHBACK_STORES_FAILED =
	"[CASHBACK] UPDATE_CASHBACK_STORES_FAILED";

export const UPDATE_CASHBACK = "[CASHBACK] UPDATE_CASHBACK";
export const UPDATE_CASHBACK_SUCCESS = "[CASHBACK] UPDATE_CASHBACK_SUCCESS";
export const UPDATE_CASHBACK_FAILED = "[CASHBACK] UPDATE_CASHBACK_FAILED";

export const DELETE_CASHBACK = "[CASHBACK] DELETE_CASHBACK";
export const DELETE_CASHBACK_SUCCESS = "[CASHBACK] DELETE_CASHBACK_SUCCESS";
export const DELETE_CASHBACK_FAILED = "[CASHBACK] DELETE_CASHBACK_FAILED";

export const SET_VALUE = "[CASHBACK] SET_VALUE";
export const CLEAR_VALUES = "[CASHBACK] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getAllCashback(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALL_CASHBACK });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/CashbackPolicy?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_ALL_CASHBACK_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALL_CASHBACK_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ALL_CASHBACK_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getCashback(id, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CASHBACK });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/CashbackPolicy/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CASHBACK_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CASHBACK_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CASHBACK_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getStoresTiny(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_STORE_TINY });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Business/Store/Tiny", {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_STORE_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_STORE_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_STORE_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getCompaniesTiny(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_COMPANY_TINY });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Business/Company/Tiny", {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COMPANY_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_COMPANY_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_COMPANY_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createCashback(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_CASHBACK });

		try {
			const { login, cashbackPolicy, businessDetail } = getState();

			const { id } = businessDetail.item;

			const {
				minValue,
				status,
				maxInvestment,
				value,
				maxValue,
				limit,
				limitPerUser,
				activeOn,
				creditMinValue,
				creditBusinessId,
				info,
				startAt,
				endAt,
				creditStartAt,
				creditEndAt,
				dynamicDateStart,
				dynamicDateEnd,
				validateDaysDynamicDate
			} = cashbackPolicy;

			const errors: any = {};

			if (!value && String(value) !== "0") errors.value = ls.required;
			if (!creditBusinessId) errors.creditBusinessId = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_CASHBACK_FAILED,
					payload: errors
				});
			}

			const model = {
				businessId: id,
				minValue: Math.round((minValue || 0) * 100),
				status,
				maxInvestment: Math.round((maxInvestment || 0) * 100),
				value: Math.round(value * 100),
				maxValue: Math.round((maxValue || 0) * 100),
				limit: limit || 0,
				limitPerUser: limitPerUser || 0,
				activeOn: activeOn || 0,
				creditMinValue: Math.round((creditMinValue || 0) * 100),
				creditBusinessId,
				info,
				startAt,
				endAt,
				creditStartAt,
				creditEndAt,
				dynamicDateStart,
				dynamicDateEnd,
				validateDaysDynamicDate
			};

			const response = await api.sendPost("/CashbackPolicy", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CASHBACK_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_CASHBACK_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_CASHBACK_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editCashback(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CASHBACK });

		try {
			const { login, cashbackPolicy } = getState();

			const {
				item,
				minValue,
				status,
				maxInvestment,
				value,
				maxValue,
				limit,
				limitPerUser,
				activeOn,
				creditMinValue,
				creditBusinessId,
				info,
				startAt,
				endAt,
				creditStartAt,
				creditEndAt,
				dynamicDateStart,
				dynamicDateEnd,
				validateDaysDynamicDate
			} = cashbackPolicy;

			const errors: any = {};

			if (!value && String(value) !== "0") errors.value = ls.required;
			if (!creditBusinessId) errors.creditBusinessId = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_CASHBACK_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				minValue: Math.round((minValue || 0) * 100),
				status,
				maxInvestment: Math.round((maxInvestment || 0) * 100),
				value: Math.round(value * 100),
				maxValue: Math.round((maxValue || 0) * 100),
				limit: limit || 0,
				limitPerUser: limitPerUser || 0,
				activeOn: activeOn || 1,
				creditMinValue: Math.round((creditMinValue || 0) * 100),
				creditBusinessId,
				info,
				startAt,
				endAt,
				creditStartAt,
				creditEndAt,
				stores: undefined,
				companies: undefined,
				creditBusiness: undefined,
				business: undefined,
				dynamicDateStart,
				dynamicDateEnd,
				validateDaysDynamicDate
			};

			const response = await api.sendPut(`/CashbackPolicy/${item.id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CASHBACK_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CASHBACK_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_CASHBACK_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editCashbackCompanies(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CASHBACK_COMPANIES });

		try {
			const { login, cashbackPolicy } = getState();

			const { item } = cashbackPolicy;

			const response = await api.sendPut(
				`/CashbackPolicy/${item.id}/Companies`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CASHBACK_COMPANIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CASHBACK_COMPANIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_CASHBACK_COMPANIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editCashbackStores(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CASHBACK_STORES });

		try {
			const { login, cashbackPolicy } = getState();

			const { item } = cashbackPolicy;

			const response = await api.sendPut(
				`/CashbackPolicy/${item.id}/Stores`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CASHBACK_STORES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CASHBACK_STORES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_CASHBACK_STORES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteCashback(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_CASHBACK });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/CashbackPolicy/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				dispatch({
					type: DELETE_CASHBACK_SUCCESS,
					payload: id
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_CASHBACK_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: DELETE_CASHBACK_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
