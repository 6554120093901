import * as actions from "store/actions/transaction/detail";

import { IDetailTransaction } from "../types/detail";

const initialState: IDetailTransaction = {
	item: null,
	extraProperties: [],
	transactionDetail: [],
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_TRANSACTION: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_TRANSACTION_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload
			};
		}
		case actions.GET_TRANSACTION_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.GET_TRANSACTION_DETAIL: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_TRANSACTION_DETAIL_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				transactionDetail: payload
			};
		}
		case actions.GET_TRANSACTION_DETAIL_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.GET_EXTRA_PROPERTY_TRANSACTION: {
			return {
				...state,
				loading: {
					...state.loading,
					extraProperties: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_EXTRA_PROPERTY_TRANSACTION_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					extraProperties: false
				},
				extraProperties: payload
			};
		}
		case actions.GET_EXTRA_PROPERTY_TRANSACTION_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					extraProperties: false
				},
				errors: payload
			};
		}
		case actions.DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_REQUEST: {
			const { id } = payload;
			const newState = { ...state };
			const { products = [] } = newState.item || { products: [] };

			const productIndex = products.findIndex(item => item.id === id);

			if (productIndex >= 0) {
				newState.item.products[productIndex].downloadingPDF = true;
			}

			return newState;
		}
		case actions.DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_FAILED:
		case actions.DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_SUCCES: {
			const { id } = payload;
			const newState = { ...state };
			const { products = [] } = newState.item || { products: [] };

			const productIndex = products.findIndex(item => item.id === id);

			if (productIndex >= 0) {
				newState.item.products[productIndex].downloadingPDF = false;
			}

			return newState;
		}
		case actions.MAIL_TRANSACTION_SALE_PRODUCT_PDF_REQUEST: {
			const { id } = payload;
			const newState = { ...state };
			const { products = [] } = newState.item || { products: [] };

			const productIndex = products.findIndex(item => item.id === id);

			if (productIndex >= 0) {
				newState.item.products[productIndex].sendingEmail = true;
			}

			return newState;
		}
		case actions.MAIL_TRANSACTION_SALE_PRODUCT_PDF_FAILED:
		case actions.MAIL_TRANSACTION_SALE_PRODUCT_PDF_SUCCES: {
			const { id } = payload;
			const newState = { ...state };
			const { products = [] } = newState.item || { products: [] };

			const productIndex = products.findIndex(item => item.id === id);

			if (productIndex >= 0) {
				newState.item.products[productIndex].sendingEmail = false;
			}

			return newState;
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
