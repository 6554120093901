import styled from "styled-components";

interface PropsFooter {
	isPJEnabled: boolean;
}

export const Footer = styled.div<PropsFooter>`
	display: flex;
	justify-content: "space-between";
	width: 100%;
	flex-direction: row;
	align-items: center;
	padding: 16px 0;
	margin-top: 10px;

	& button {
		margin-left: 8px;
	}
`;
