import { Badge, Tab, Tabs as TabsMUI, Typography } from "@material-ui/core";
import React from "react";

interface TabsProps {
	tab: number;
	onChangeTab: (value: number) => void;
	usersState: {
		email: string;
		sms: string;
		whatsApp: string;
		push: string;
	};
}

const Tabs: React.FC<TabsProps> = ({ tab, onChangeTab, usersState }) => (
	<TabsMUI
		value={tab}
		indicatorColor="primary"
		textColor="primary"
		onChange={(_, v) => onChangeTab(v)}
		variant="scrollable"
	>
		<Tab label="Sobre" />
		<Tab
			label={
				<Badge color="secondary" badgeContent={usersState.email} max={99999}>
					<Typography>E-mail</Typography>
				</Badge>
			}
		/>
		<Tab
			label={
				<Badge color="secondary" badgeContent={usersState.sms} max={99999}>
					<Typography>SMS</Typography>
				</Badge>
			}
		/>
		<Tab
			label={
				<Badge color="secondary" badgeContent={usersState.whatsApp} max={99999}>
					<Typography>WhatsApp</Typography>
				</Badge>
			}
		/>
		<Tab
			label={
				<Badge color="secondary" badgeContent={usersState.push} max={99999}>
					<Typography>Push</Typography>
				</Badge>
			}
		/>
		<Tab label="Enviar para" />
	</TabsMUI>
);

export default Tabs;
