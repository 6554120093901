/* eslint-disable no-console */
/* eslint-disable no-alert */
import { IconButton } from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import exportToXlsx from "helpers/exportToXlsx";
import ls from "Localization";
import _ from "lodash";
import limitType from "models/limitType";
import userKycStatus from "models/userKycStatus";
import userStatus from "models/userStatus";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { FaFileExport } from "react-icons/fa";
import { connect } from "react-redux";
import { getRoles } from "store/actions/role/details";
import { exportUsersData } from "store/actions/user/export";
import * as actions from "store/actions/user/report";

import ImportLimits from "../Import";

let getUsersDebounced: (() => void) & _.Cancelable = null;

function UserReport({
	state,
	roles,
	getUsers,
	getRoles,
	exportUsersData,
	history,
	login: { user }
}) {
	const table = useRef(null);

	const isAdmin = useMemo(() => isInRole(user, ["admin"]), [user]);

	useEffect(() => {
		getRoles();
	}, [getRoles]);

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.name,
				accessor: "fullName",
				width: 250,
				show: true
			},
			{
				Header: ls.birthDate,
				id: "birthDate",
				accessor: c =>
					c.birthDate
						? dayjs(c.birthDate.replace("T00", "T03")).format(
								ls.dateFormatShort
						  )
						: "Não informado",
				width: 250,
				show: true
			},
			{
				Header: ls.mothersName,
				id: "mothersName",
				accessor: c => (c.mothersName ? c.mothersName : "Não informado"),
				width: 250,
				show: true
			},
			{
				Header: ls.phoneNumber,
				id: "phoneNumber",
				accessor: c => (c.phoneNumber ? c.phoneNumber : "Não informado"),
				width: 150,
				show: true
			},
			{
				Header: ls.cpf,
				id: "cpf",
				accessor: c => (c.cpf ? c.cpf : "Não informado"),
				width: 150,
				show: true
			},
			{
				Header: ls.email,
				id: "email",
				accessor: c => (c.email ? c.email : "Não informado"),
				width: 200,
				show: true
			},
			{
				Header: ls.address,
				id: "address",
				accessor: c => (c.address ? c.address.fullAddress : ls.notDefined),
				show: true
			},
			{
				Header: ls.business,
				id: "business",
				accessor: c => c.business?.business?.name ?? ls.none,
				show: true
			},
			{
				Header: ls.role,
				id: "roleId",
				accessor: c => ls[c.business?.role?.name] ?? ls.none,
				width: 200,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{roles.map(c => (
							<option key={c.id} value={c.id}>
								{ls[c.name] ?? c.name}
							</option>
						))}
					</select>
				),
				show: true
			},
			{
				Header: ls.hasLogin,
				id: "hasLogin",
				accessor: c => (c.hasLogin ? "Sim" : "Não"),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : ""}
					>
						<option value="">Todos</option>
						<option value="true">Sim</option>
						<option value="false">Não</option>
					</select>
				),
				show: true
			},
			{
				Header: ls.status,
				id: "status",
				accessor: c => ls[userStatus[c.status]],
				width: 200,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(userStatus).map(c => (
							<option key={c} value={c}>
								{ls[userStatus[c]]}
							</option>
						))}
					</select>
				),
				show: true
			},
			{
				Header: ls.kycStatus,
				id: "kycStatus",
				accessor: c => ls[userKycStatus[c.swapDetails?.kycStatus]],
				width: 200,
				show: false,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(userKycStatus).map(c => (
							<option key={c} value={c}>
								{ls[userKycStatus[c]]}
							</option>
						))}
					</select>
				)
			}
		],
		[roles]
	);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getUsersDebounced) {
			getUsersDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");
			let date = null;

			if (creationDate?.value.startDate || creationDate?.value.endDate) {
				date = {
					startAt: creationDate.value.startDate,
					endAt: creationDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "creationDate",
					value: JSON.stringify(date)
				});
			}
		}

		getUsersDebounced = _.debounce(
			() =>
				getUsers(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
					sorted[0]?.id,
					sorted[0]?.desc,
					toExport,
					callback
				),
			500
		);

		getUsersDebounced();
	};

	const handleExportUsersData = useCallback(() => {
		exportUsersData(null, (err, model) => {
			if (err) {
				console.log(err);
				alert(`Erro ao exportar os dados dos usuários: ${err.default}`);
			} else {
				const data = model.flatMap(c => ({
					...c,
					available: c.available && (c.available / 100.0).toFixed(2),
					limit: c.limit && (c.limit / 100.0).toFixed(2),
					limitType: ls[limitType[c.limitType]],
					status: ls[userStatus[c.status]],
					used: c.used && (c.used / 100.0).toFixed(2)
				}));

				if (data.length) {
					exportToXlsx(data, "users");
				} else {
					alert("Não há usuários cadastrados e/ou ativos");
				}
			}
		});
	}, [exportUsersData]);

	const load = () => handleFetchData(table.current?.state, null);

	return (
		<div>
			<Helmet>
				<title>
					{ls.users} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={`${ls.users}`}
				data={state.items}
				pages={state.pages}
				loading={state.loading}
				onFetchData={handleFetchData}
				filterable
				showExport={false}
				useQueryString
				headerRightComponent={
					<>{isAdmin ? <ImportLimits load={load} /> : null}</>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				onRowClicked={row => window.open(`/User/${row.id}`, "_blank")}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
		</div>
	);
}

const mapStateToProps = ({
	userReport: state,
	roleDetails: { items: roles },
	login
}) => ({ state, roles, login });

const mapDispatchToProps = { ...actions, getRoles, exportUsersData };

export default connect(mapStateToProps, mapDispatchToProps)(UserReport);
