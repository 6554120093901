import styled from "styled-components";

export const Container = styled.div`
	max-height: 340px;
	overflow: auto;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 10px 5px;
`;

export const Title = styled.div`
	text-align: left;
	font: normal normal medium 19px/25px Exo;
	letter-spacing: 0px;
	color: #494949;
	opacity: 1;
`;

export const ButtonRead = styled.button`
	background: none;
	border: none;
`;

export const TextButtonRead = styled.div`
	text-align: left;
	font: normal normal normal 10px/13px Exo;
	letter-spacing: 0px;
	color: #848484;
	opacity: 1;
	text-decoration-line: underline;
`;
