import dayjs from "dayjs";
import ls from "Localization";
import XLSX from "xlsx";

const exportToXlsx = (data: any[], nameFile: string) => {
	const xData = data.map(c => {
		const newObj = {};

		// eslint-disable-next-line guard-for-in
		for (let key in c) {
			newObj[ls[key]] = c[key];
		}

		return newObj;
	});

	const nSheet = XLSX.utils.json_to_sheet(xData, {
		header: Object.keys(xData[0])
	});

	const nWb = XLSX.utils.book_new();

	XLSX.utils.book_append_sheet(nWb, nSheet);

	XLSX.writeFile(
		nWb,
		`${nameFile}-${dayjs().format("YYYY-MM-DD-kk-mm-ss")}.xlsx`,
		{
			bookType: "xlsx",
			type: "binary"
		}
	);
};

export default exportToXlsx;
