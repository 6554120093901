import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import clsx from "clsx";

const Item = props => {
	const { classes, text, to, icon, nested, children, ...rest } = props;

	const navLinkProps: any = {};

	if (to) {
		navLinkProps.activeClassName = classes.itemActive;
		navLinkProps.component = NavLink;
	}

	return (
		<ListItem
			button
			className={clsx(classes.item, classes.itemLink, {
				[classes.nested]: nested
			})}
			{...navLinkProps}
			to={to}
			{...rest}
		>
			<ListItemIcon className={classes.inherit}>{icon}</ListItemIcon>
			<ListItemText
				primary={text}
				className={classes.itemText}
				classes={{ primary: classes.inherit }}
			/>
			{children}
		</ListItem>
	);
};

export default Item;
