import { getErrors } from "helpers/error";
import * as api from "services/api";

export const EDIT_MODERATION = "[MODERATION EDIT] EDIT_MODERATION";
export const EDIT_MODERATION_SUCCESS =
	"[MODERATION EDIT] EDIT_MODERATION_SUCCESS";
export const EDIT_MODERATION_FAILED =
	"[MODERATION EDIT] EDIT_MODERATION_FAILED";

export const SET_VALUE = "[MODERATION EDIT] SET_VALUE";
export const CLEAR_VALUES = "[MODERATION EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function editModeration(callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_MODERATION });
		try {
			const { login, moderationEdit } = getState();

			const { originalAmount, amount, category, status, item } = moderationEdit;

			const model = {
				originalAmount: Math.round(originalAmount * 100),
				amount: Math.round(amount * 100),
				category,
				status,
				clientId: item.clientId,
				companyId: item.companyId,
				saleId: item.saleId
			};

			let response = await api.sendPut(`/Moderation/${item.id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_MODERATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_MODERATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: EDIT_MODERATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
