import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
`;

export const Title = styled.div`
	font-size: 20px;
	display: flex;
	align-self: center;
	font-weight: bold;
	font-family: "Exo";
	padding: 12px;
	color: #5734da;
`;

export const ColumnContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
`;

export const Text = styled.p`
	display: flex;
	align-self: center;
	color: #707070;
	font: normal normal 600 16px Exo;
	margin: 0 auto;
	width: 476px;
	margin-bottom: 12px;
`;

export const BackgroundButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%;
	font: normal normal 700 27px Exo;
	padding: 8px;
	cursor: pointer;
`;

export const TextCode = styled.p`
	font: normal normal 500 18px Exo;
	color: #707070;
	text-align: center;
`;
