import { IconButton } from "@material-ui/core";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import _ from "lodash";
import React, { useRef } from "react";
import { FaFileImport } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { importLimits } from "store/actions/user/import";
import XLSX from "xlsx";

let debounceImportLimits: (() => void) & _.Cancelable = null;

function ImportLimits({ load }) {
	const dispatch = useDispatch();

	const { loading } = useSelector<any, any>(s => s.userImport);

	const inputFile = useRef<HTMLInputElement>(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const handleFileSelected = e => {
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const sheetName = wb.SheetNames[0];
			const sheet = wb.Sheets[sheetName];

			const data = XLSX.utils.sheet_to_json(sheet);

			if (debounceImportLimits) debounceImportLimits.cancel();

			debounceImportLimits = _.debounce(() => {
				dispatch(
					importLimits(
						data.map(c => ({
							document: c["CPF"],
							limit: Math.round(c["Limite"] * 100)
						})),
						err => {
							if (err) alert(err);
							else load();
						}
					)
				);
			}, 1000);

			debounceImportLimits();
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	return (
		<>
			<TooltipLight title="Importar limites" placement="top">
				<IconButton onClick={openSelectFile}>
					{loading ? <Spinner color="secondary" size={16} /> : <FaFileImport />}
				</IconButton>
			</TooltipLight>
			<input
				ref={inputFile}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileSelected}
			/>
		</>
	);
}

export default ImportLimits;
