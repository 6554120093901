import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_BUSINESSES_BRANCHES = "[BUSINESS REPORT] GET_BUSINESSES";
export const GET_BUSINESSES_SUCCESS_BRANCHES =
	"[BUSINESS REPORT] GET_BUSINESSES_SUCCESS";
export const GET_BUSINESSES_FAILED_BRANCHES =
	"[BUSINESS REPORT] GET_BUSINESSES_FAILED";

export const SET_VALUE = "[BUSINESS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getBusinesses(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESSES_BRANCHES });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Business?skip=${skip}&getTransfers=true&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_BUSINESSES_SUCCESS_BRANCHES,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				return callback && callback(null, result.model, result.params);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: GET_BUSINESSES_FAILED_BRANCHES,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: GET_BUSINESSES_FAILED_BRANCHES,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
