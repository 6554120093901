import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	form: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4)
	},
	alert: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		backgroundColor: "#cdeefd"
	},
	alertIcon: {
		marginRight: theme.spacing(2),
		color: "#02587f"
	},
	alertText: {
		color: "#02587f"
	},
	line: {
		marginLeft: -16,
		marginRight: -16
	},
	button: {
		":disabled": {
			color: "#21b3a3"
		},
		margin: theme.spacing()
	},
	xButton: {
		position: "absolute",
		borderRadius: 50,
		backgroundColor: "red"
	},
	preview: {
		width: 100,
		height: 100,
		borderRadius: 50,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover"
	},
	divButton: {
		width: "100%",
		height: "100%",
		position: "relative"
	},
	teste: {
		display: "flex",
		flexDirection: "column"
	}
}));

export default useStyles;
