import * as actions from "store/actions/credit/detail";

import { IDetailCredit } from "../types/detail";

const initialState: IDetailCredit = {
	item: null,
	storesTiny: [],
	categories: [],
	stores: [],
	showCategoryFormModal: false,
	showStoreFormModal: false,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_CREDIT: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_CREDIT_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.categories = payload.categories?.length
					? payload.categories
					: initialState.categories;
				newState.stores = payload.stores?.length
					? payload.stores
					: initialState.stores;
			} else {
				newState.item = null;
				newState.categories = initialState.categories;
				newState.stores = initialState.stores;
			}

			return {
				...newState,
				loading: {
					...state.loading,
					get: false
				}
			};
		}
		case actions.GET_CREDIT_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.GET_BUSINESS_TINY: {
			return {
				...state,
				loading: { getStore: true },
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_TINY_SUCCESS: {
			return {
				...state,
				storesTiny: payload || [],
				loading: { getStore: false }
			};
		}
		case actions.GET_BUSINESS_TINY_FAILED: {
			return {
				...state,
				loading: { getStore: false },
				errors: payload
			};
		}
		case actions.UPDATE_CREDIT_DETAIL_CATEGORIES: {
			return {
				...state,
				loading: { categories: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CREDIT_DETAIL_CATEGORIES_SUCCESS: {
			return {
				...state,
				loading: { categories: false }
			};
		}
		case actions.UPDATE_CREDIT_DETAIL_CATEGORIES_FAILED: {
			return {
				...state,
				loading: { categories: false },
				errors: payload
			};
		}
		case actions.UPDATE_CREDIT_DETAIL_STORES: {
			return {
				...state,
				loading: { stores: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CREDIT_DETAIL_STORES_SUCCESS: {
			return {
				...state,
				loading: { stores: false }
			};
		}
		case actions.UPDATE_CREDIT_DETAIL_STORES_FAILED: {
			return {
				...state,
				loading: { stores: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
