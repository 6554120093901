import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		overflow: "scroll",
		justifyContent: "center"
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		flexGrow: 1,
		overflow: "scroll",
		maxWidth: "calc(80%)",
		maxHeight: "calc(95%)",
		padding: theme.spacing(1, 2),
		backgroundColor: theme.palette.background.paper
	},
	row: {
		flex: 1,
		display: "flex",
		alignItems: "center",
		marginTop: 24
	},
	column: {
		display: "flex",
		flexDirection: "column"
	},
	input: {
		height: 10,
		fontSize: 14,
		maxWidth: 350
	},
	buttonFooter: {
		height: 45,
		width: 150,
		fontSize: 16
	},
	button: {
		height: 20,
		width: 120,
		fontSize: 11,
		marginTop: 5,
		background: "#fff",
		color: "red"
	},
	inputSendAt: {
		height: 10,
		fontSize: 14,
		width: 150
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		marginTop: 32,
		"& button": {
			marginLeft: theme.spacing(1)
		}
	},
	dateContent: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		marginTop: 20,
		width: "100%"
	},
	dateContentRow: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "0.225rem"
	},
	dateMultiCNPJ: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	},
	containerDetail: {
		display: "flex",
		flex: 1,
		flexDirection: "column",
		alignItems: "flex-start",
		padding: 20,
		paddingBottom: 40
	},
	containerInput: {
		width: 280,
		marginTop: 10,
		flexDirection: "column",
		fontSize: 14
	},
	labelMaxLengthDescription: { marginTop: 5, fontSize: 10, color: "#fe7c7b" }
}));
