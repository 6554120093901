import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import { Callback } from "Types/Callback";

export const GET_CONVENIENCE_FEES = "[CONVENIENCE_FEE] GET_CONVENIENCE_FEES";
export const GET_CONVENIENCE_FEES_SUCCESS =
	"[CONVENIENCE_FEE] GET_CONVENIENCE_FEES_SUCCESS";
export const GET_CONVENIENCE_FEES_FAILED =
	"[CONVENIENCE_FEE] GET_CONVENIENCE_FEES_FAILED";

export const GET_CONVENIENCE_FEE = "[CONVENIENCE_FEE] GET_CONVENIENCE_FEE";
export const GET_CONVENIENCE_FEE_SUCCESS =
	"[CONVENIENCE_FEE] GET_CONVENIENCE_FEE_SUCCESS";
export const GET_CONVENIENCE_FEE_FAILED =
	"[CONVENIENCE_FEE] GET_CONVENIENCE_FEE_FAILED";

export const CREATE_CONVENIENCE_FEE =
	"[CONVENIENCE_FEE] CREATE_CONVENIENCE_FEE";
export const CREATE_CONVENIENCE_FEE_SUCCESS =
	"[CONVENIENCE_FEE] CREATE_CONVENIENCE_FEE_SUCCESS";
export const CREATE_CONVENIENCE_FEE_FAILED =
	"[CONVENIENCE_FEE] CREATE_CONVENIENCE_FEE_FAILED";

export const UPDATE_CONVENIENCE_FEE =
	"[CONVENIENCE_FEE] UPDATE_CONVENIENCE_FEE";
export const UPDATE_CONVENIENCE_FEE_SUCCESS =
	"[CONVENIENCE_FEE] UPDATE_CONVENIENCE_FEE_SUCCESS";
export const UPDATE_CONVENIENCE_FEE_FAILED =
	"[CONVENIENCE_FEE] UPDATE_CONVENIENCE_FEE_FAILED";

export const SET_VALUE = "[CONVENIENCE_FEE] SET_VALUE";
export const CLEAR_VALUES = "[CONVENIENCE_FEE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getConvenienceFees(id, callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONVENIENCE_FEES });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/ConvenienceFee/Business/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONVENIENCE_FEES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CONVENIENCE_FEES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CONVENIENCE_FEES_FAILED,
				payload: {
					error: "Connection error", exception: error
				}
			});
		}
	};
}

export function getConvenienceFee(id, include, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONVENIENCE_FEE });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/ConvenienceFee/${id}?include=${include}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONVENIENCE_FEE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CONVENIENCE_FEE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CONVENIENCE_FEE_FAILED,
				payload: {
					error: "Connection error", exception: error
				}
			});
		}
	};
}

export function createConvenienceFee(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_CONVENIENCE_FEE });

		try {
			const { login, convenienceFee, businessDetail } = getState();

			const { minValue, maxValue, fee } = convenienceFee;

			const errors: any = {};

			if ((!minValue && minValue !== 0) || minValue < 0)
				errors.minValue = ls.invalid;

			if (
				(!maxValue && maxValue !== 0) ||
				maxValue < 0 ||
				maxValue < minValue ||
				maxValue > 42949672
			)
				errors.maxValue = ls.invalid;

			if ((!fee && fee !== 0) || fee < 0) errors.fee = ls.invalid;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_CONVENIENCE_FEE_FAILED,
					payload: errors
				});
			}

			const model = {
				minValue: Math.round(minValue * 100),
				maxValue: Math.round(maxValue * 100),
				fee: Math.round(fee * 100),
				businessId: businessDetail.item.id
			};

			const response = await api.sendPost("/ConvenienceFee", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CONVENIENCE_FEE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_CONVENIENCE_FEE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_CONVENIENCE_FEE_FAILED,
				payload: {
					error: "Connection error", exception: error
				}
			});
		}
	};
}

export function editConvenienceFee(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CONVENIENCE_FEE });

		try {
			const { login, convenienceFee } = getState();

			const { item, minValue, maxValue, fee } = convenienceFee;

			const errors: any = {};

			if ((!minValue && minValue !== 0) || minValue < 0)
				errors.minValue = ls.invalid;

			if (
				(!maxValue && maxValue !== 0) ||
				maxValue < 0 ||
				maxValue < minValue ||
				maxValue > 42949672
			)
				errors.maxValue = ls.invalid;

			if ((!fee && fee !== 0) || fee < 0) errors.fee = ls.invalid;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_CONVENIENCE_FEE_FAILED,
					payload: errors
				});
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_CONVENIENCE_FEE_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				minValue: Math.round(minValue * 100),
				maxValue: Math.round(maxValue * 100),
				fee: Math.round(fee * 100),
				business: undefined
			};

			const response = await api.sendPut("/ConvenienceFee/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CONVENIENCE_FEE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CONVENIENCE_FEE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_CONVENIENCE_FEE_FAILED,
				payload: {
					error: "Connection error", exception: error
				}
			});
		}
	};
}
