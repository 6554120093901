import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import styled from "styled-components";

export const Container = styled.div`
	padding: 20px;
`;

export const Title = styled.h4`
	text-align: left;
	font: normal normal 600 20px/27px Exo;
	letter-spacing: 0px;
	color: #5734da;
	opacity: 1;
	padding: 0;
	margin: 0;
`;

export const RowDates = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 20px;
`;

export const LabelDate = styled.p`
	text-align: left;
	font: normal normal medium 20px/27px Exo;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	margin-right: 20px;
`;

export const InputDate = styled(DatePicker)`
	margin-right: 40px;
`;

export const useStyles = makeStyles(() => ({
	inputDueDate: {
		height: 20,
		fontSize: 18,
		width: 150
	}
}));
