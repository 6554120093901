import { makeStyles } from "@material-ui/core/styles";
import ReactTable from "react-table";
import styled from "styled-components";

interface PropsTable {
	tableRowHeight?: number;
}

const useStyles = makeStyles((theme: any) => ({
	cardContent: {
		padding: 0
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300
	},
	action: {
		height: "100%",
		margin: 0,
		alignSelf: "unset"
	},
	titleComponent: {
		display: "flex",
		alignItems: "center"
	}
}));

export default useStyles;

export const StyledReactTable = styled(ReactTable)<PropsTable>`
	.rt-thead {
		height: 40px;
		z-index: 1;
	}
	.rt-tr {
		${props =>
			props.tableRowHeight ? `heigth: ${props.tableRowHeight}px` : ""}
		z-index: 0;
	}
	.rt-tbody {
		z-index: 0;
	}
`;
