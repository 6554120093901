import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: any) => ({
	box: {
		display: "flex",
		flex: 1,
		width: "100%",
		border: "1px dashed #B9B9B9",
		borderRadius: 4,
		paddingTop: 28,
		paddingBottom: 42,
		justifyContent: "center",
		alignItems: "center",
		cursor: "pointer"
	},
	dragInformation: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center"
	},
	uploadButton: {
		color: "#5734D9",
		fontSize: "18px",
		textDecorationLine: "underline",
		marginTop: theme.spacing(1)
	},
	uploadedImage: {
		width: 600,
		height: 150
	}
}));

export default useStyles;
