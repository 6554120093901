/* eslint-disable no-nested-ternary */
import { Typography } from "@material-ui/core";
import { toastError, toastWarning } from "helpers/toast";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import useStyles from "./styles";

const BannerDrop: React.FC<{
	onChooseImage: (formData: FormData) => void;
	bannerUrl: string;
}> = ({ onChooseImage, bannerUrl }) => {
	const [inputedFile, setInputedFile] = useState("");
	const styles = useStyles({});

	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
		if (!acceptedFiles.length)
			return toastWarning("Apenas imagens são aceitas!");
		acceptedFiles.forEach(file => {
			const reader = new FileReader();
			onChooseImage(file);
			reader.onabort = () =>
				toastWarning(
					"A leitura da imagem foi interrompida de forma inesperada!"
				);
			reader.onerror = () => toastError("Ocorreu um erro ao ler a sua imagem!");
			reader.onload = () => {
				// Do whatever you want with the file contents
				const binaryStr = Buffer.from(reader.result as ArrayBuffer).toString(
					"base64"
				);
				setInputedFile(`data:${file.type};base64, ${binaryStr}`);
			};
			reader.readAsArrayBuffer(file);
		});
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"image/jpeg": [],
			"image/png": [],
			"image/webp": [],
			"image/heic": [],
			"image/jfif": []
		},
		maxFiles: 1,
		useFsAccessApi: true
	});

	useEffect(() => {
		setInputedFile(bannerUrl);
	}, [bannerUrl]);

	return (
		<div className={styles.box} {...getRootProps()}>
			<input {...getInputProps()} />
			{isDragActive ? (
				<p>Solte seus arquivos aqui</p>
			) : (
				<div className={styles.dragInformation}>
					{inputedFile ? (
						<img
							alt="Upload feito"
							src={inputedFile}
							className={styles.uploadedImage}
						/>
					) : (
						<>
							<Typography style={{ color: "#999A99", fontSize: 20 }}>
								Banner*
							</Typography>
							<Typography style={{ fontSize: 16, color: "#999A99" }}>
								Tamanho: 600px x 150px
							</Typography>
							<a
								role="button"
								tabIndex={0}
								className={styles.uploadButton}
								onKeyPress={() => {}}
								onClick={() => {}}
							>
								Fazer upload
							</a>
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default BannerDrop;
