export const transformLinkType = (value: string) => {
	switch (value) {
		case "Site":
			return 0;
		case "Catalog":
			return 1;
		case "Menu":
			return 2;
		case "Hire":
			return 3;
		case "Subscribe":
			return 4;
		case "Experience":
			return 5;
		case "Buy":
			return 6;
		case "Accede":
			return 7;
		default:
			return 8;
	}
};
