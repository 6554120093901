import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { PERCENTAGE2 } from "Components/NumberFormat";
import { MONEY } from "Components/NumberFormat";
import ls from "Localization";
import React, { useState } from "react";

import EditConfig from "../../EditConfig";
import useStyles from "./styles";

function Config({ config, admin, id, hasWiipoClub, hasHCM, isBusinessAdmin }) {
	const classes = useStyles({});

	const [showEditConfig, setShowEditConfig] = useState(false);

	const handleEditConfigClose = () => {
		setShowEditConfig(false);
	};

	const onPressEdit = () => {
		setShowEditConfig(true);
	};

	return (
		<>
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6">{ls.benefits}</Typography>

					<div>
						{(admin || isBusinessAdmin) && (
							<Button color="primary" onClick={onPressEdit}>
								{ls.edit}
							</Button>
						)}
					</div>
				</div>
				<hr className={classes.line} />
				<Grid container spacing={2} className={classes.form}>
					{admin && (
						<>
							<Grid item xs={12} sm={6} md={4} lg={2}>
								<strong>{ls.fee}</strong>:{" "}
								<PERCENTAGE2
									displayType="text"
									value={config ? config.fee / 100 : 0}
								/>
							</Grid>

							{hasWiipoClub && hasHCM && (
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<strong>{ls.percentClubWiipo}</strong>:{" "}
									<PERCENTAGE2
										displayType="text"
										value={config ? config.percentClubWiipo / 100 : 0}
									/>
								</Grid>
							)}

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<strong>{ls.admFee}</strong>:{" "}
								<MONEY
									displayType="text"
									value={config ? config.admFee / 100 : 0}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={2}>
								<strong>{ls.discount}</strong>:{" "}
								<PERCENTAGE2
									displayType="text"
									value={config ? config.discount / 100 : 0}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={2}>
								<strong>{ls.paymentDay}</strong>:{" "}
								{config ? config.paymentDay : ""}
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={1}>
								<strong>{ls.color}</strong>: {config ? config.color : ""}
							</Grid>
						</>
					)}
					{admin ||
						(isBusinessAdmin && (
							<Grid
								item
								xs={12}
								sm={admin ? 6 : 12}
								md={admin ? 4 : 12}
								lg={admin ? 2 : 12}
							>
								<strong>{ls.percentClubWiipo}</strong>:{" "}
								{config ? config.percentClubWiipo / 100.0 : 0}%
							</Grid>
						))}
				</Grid>
			</Paper>
			{showEditConfig && (
				<EditConfig
					open={showEditConfig}
					handleClose={handleEditConfigClose}
					hasWiipoClub={hasWiipoClub}
					hasHCM={hasHCM}
					admin={admin}
					isBusinessAdmin={isBusinessAdmin}
					id={id}
				/>
			)}
		</>
	);
}

export default Config;
