import { IconButton } from "@material-ui/core";
import Report from "Components/Report";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import installmentPaymentType from "models/installmentPaymentType";
import installmentStatus from "models/installmentStatus";
import React, { useMemo } from "react";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	getInstallment,
	setValue
} from "store/actions/installment/edit";
import { getTransaction } from "store/actions/transaction/detail";

import EditInstallment from "./Edit";

function Installments() {
	const dispatch = useDispatch();

	const { user } = useSelector<any, any>(s => s.login);
	const { item: sale } = useSelector<any, any>(c => c.transactionDetail);

	const { installments: items } = sale;

	const handleEdit = item => {
		dispatch(clearValues());

		dispatch(
			getInstallment(item.id, err => {
				if (err) {
					console.log(err);
				} else {
					dispatch(setValue({ showEdit: true }));
				}
			})
		);
	};

	const handleAfterEdit = () => {
		dispatch(getTransaction(sale.id));
	};

	const isAdmin = isInRole(user, ["Admin"]);

	const columns = useMemo(
		() =>
			[
				{
					Header: ls.paymentDay,
					id: "payDate",
					accessor: c => dayjs(c.payDate).format(ls.dateFormatShort),
					width: 140,
					sortMethod: (a, b, desc: boolean) => {
						let aD = dayjs(a, ls.dateFormatShort);
						let bD = dayjs(b, ls.dateFormatShort);

						return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
					}
				},
				{
					Header: ls.installment,
					id: "installment",
					accessor: c => c.number + 1,
					width: 120
				},
				{
					Header: ls.value,
					id: "value",
					accessor: c => toStringCurrency(c.value / 100),
					width: 120
				},
				{
					Header: ls.fee,
					id: "fee",
					accessor: c => toStringCurrency(c.fee / 100),
					width: 120
				},
				{
					Header: ls.paymentType,
					id: "paymentType",

					accessor: c => ls[installmentPaymentType[c.paymentType]]
				},
				{
					Header: ls.status,
					id: "status",
					sortable: false,
					accessor: c => ls[installmentStatus[c.status]],
					width: 120
				},
				isAdmin
					? {
							Header: ls.actions,
							id: "actions",
							accessor: c => (
								<div>
									<IconButton
										style={{ padding: 4 }}
										onClick={() => handleEdit(c)}
									>
										<MdEdit />
									</IconButton>
								</div>
							),
							width: 100
					  }
					: null
			].filter(c => c),
		[isAdmin]
	);

	return (
		<div>
			<Report
				title={ls.installments}
				data={items}
				PaginationComponent={_ => null}
				defaultPageSize={items.length || 1}
				columns={columns}
			/>
			<EditInstallment afterEdit={handleAfterEdit} />
		</div>
	);
}

export default Installments;
