import * as actions from "store/actions/user/edit";

import { IEditUser } from "../types/edit";

const initialState: IEditUser = {
	item: null,
	name: "",
	lastName: "",
	email: "",
	cpf: "",
	enroll: "",
	admissionDate: null,
	phoneNumber: "",
	status: 1,
	street: "",
	number: "",
	complement: "",
	neighborhood: "",
	city: "",
	state: "",
	zipCode: "",
	country: "BR",
	isPJ: false,
	importedBy: 0,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.EDIT_USER: {
			return {
				...state,
				loading: { edit: true },
				errors: initialState.errors
			};
		}
		case actions.EDIT_USER_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.EDIT_USER_FAILED: {
			return {
				...state,
				loading: { edit: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
