import React from "react";

import TransactionReport from "../../Transaction/Report/index";

const params = {
	wiipoClub: true
};

const TransactionWiipoClub: React.FC = () => (
	<TransactionReport params={params} />
);

export default TransactionWiipoClub;
