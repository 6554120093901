import { Tooltip as TooltipMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useRef } from "react";

function arrowGenerator(color: string) {
	return {
		"&[x-placement*=\"bottom\"] $arrow": {
			top: 0,
			left: 0,
			marginTop: "-0.9em",
			width: "3em",
			height: "1em",
			"&::before": {
				borderWidth: "0 1em 1em 1em",
				borderColor: `transparent transparent ${color} transparent`
			}
		},
		"&[x-placement*=\"top\"] $arrow": {
			bottom: 0,
			left: 0,
			marginBottom: "-0.9em",
			width: "3em",
			height: "1em",
			"&::before": {
				borderWidth: "1em 1em 0 1em",
				borderColor: `${color} transparent transparent transparent`
			}
		},
		"&[x-placement*=\"right\"] $arrow": {
			left: 0,
			marginLeft: "-0.9em",
			height: "3em",
			width: "1em",
			"&::before": {
				borderWidth: "1em 1em 1em 0",
				borderColor: `transparent ${color} transparent transparent`
			}
		},
		"&[x-placement*=\"left\"] $arrow": {
			right: 0,
			marginRight: "-0.9em",
			height: "3em",
			width: "1em",
			"&::before": {
				borderWidth: "1em 0 1em 1em",
				borderColor: `transparent transparent transparent ${color}`
			}
		}
	};
}

const useStyles = makeStyles((theme: any) => ({
	lightTooltip: {
		backgroundColor: theme.palette.common.white,
		color: theme.palette.text.primary,
		boxShadow: theme.shadows[1],
		fontSize: 11
	},
	arrowPopper: arrowGenerator(theme.palette.grey[700]),
	arrow: {
		position: "absolute",
		fontSize: 7,
		width: "3em",
		height: "3em",
		"&::before": {
			content: "\"\"",
			margin: "auto",
			display: "block",
			width: 0,
			height: 0,
			borderStyle: "solid"
		}
	},
	bootstrapPopper: arrowGenerator(theme.palette.common.black),
	bootstrapTooltip: {
		backgroundColor: theme.palette.common.black,
		fontSize: "0.9rem"
	},
	bootstrapPlacementLeft: {
		margin: "0 8px"
	},
	bootstrapPlacementRight: {
		margin: "0 8px"
	},
	bootstrapPlacementTop: {
		margin: "8px 0"
	},
	bootstrapPlacementBottom: {
		margin: "8px 0"
	}
}));

export default function Tooltip(props: any) {
	const classes = useStyles({});

	const arrowRef = useRef(null);

	const { title, children } = props;
	return (
		<TooltipMUI
			title={
				<>
					{title}
					<span className={classes.arrow} ref={arrowRef} />
				</>
			}
			classes={{
				tooltip: classes.bootstrapTooltip,
				popper: classes.bootstrapPopper,
				tooltipPlacementLeft: classes.bootstrapPlacementLeft,
				tooltipPlacementRight: classes.bootstrapPlacementRight,
				tooltipPlacementTop: classes.bootstrapPlacementTop,
				tooltipPlacementBottom: classes.bootstrapPlacementBottom
			}}
			PopperProps={{
				popperOptions: {
					modifiers: {
						arrow: {
							enabled: Boolean(arrowRef.current),
							element: arrowRef.current
						}
					}
				}
			}}
		>
			<span>{children}</span>
		</TooltipMUI>
	);
}
