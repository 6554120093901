import { Box, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import history from "AppHistory";

interface ArrayTable {
	label: string;
	table: () => JSX.Element;
}

interface TabPanelProps {
	children: React.ReactNode;
	index: number;
	value: number;
}

interface IPropsTabsTable {
	tabs: ArrayTable[];
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index } = props;

	return (
		<div>{value === index && <Box style={{ padding: 3 }}>{children}</Box>}</div>
	);
}

const TabsTable: React.FC<IPropsTabsTable> = ({ tabs }) => {
	const [tab, setTab] = useState(0);

	useEffect(() => {
		const query = queryString.parse(window.location.search) as Record<
			string,
			string
		>;

		setTab(Number(query.tabIndex || 0));
	}, [window.location.search]);

	const setValue = (value: number) => {
		history.push({
			pathname: window.location.pathname,
			search: queryString.stringify({ tabIndex: value })
		});
	};

	return (
		<Box style={{ width: "100%" }}>
			<Box>
				{tabs.length > 0 && (
					<Tabs
						value={tab}
						onChange={(e, value) => setValue(value)}
						style={{ marginLeft: 4 }}
						TabIndicatorProps={{
							style: {
								background: "#5734d9"
							}
						}}
					>
						{tabs.map((i, index) => (
							<Tab key={index} label={i.label} />
						))}
					</Tabs>
				)}
			</Box>
			{tabs.map((i, index) => (
				<TabPanel value={tab} key={index} index={index}>
					{i.table()}
				</TabPanel>
			))}
		</Box>
	);
};

export default TabsTable;
