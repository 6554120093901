import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import moderationCategory from "models/moderationCategory";
import moderationStatus from "models/moderationStatus";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editModeration,
	setValue
} from "store/actions/moderation/edit";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceEditModeration: (() => void) & _.Cancelable = null;

function EditModeration({ item, load }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const {
		originalAmount,
		amount,
		category,
		status,
		showEditModal,
		errors,
		loading
	} = useSelector<any, any>(s => s.moderationEdit);

	useEffect(() => {
		if (item) {
			dispatch(
				setValue({
					item,
					amount: item.amount / 100,
					originalAmount: item.originalAmount / 100,
					category: item.category,
					status: item.status
				})
			);
		}
	}, [dispatch, showEditModal, item]);

	const handleChange = (name, value) => {
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const handleClose = () => {
		handleChange("showEditModal", false);
	};

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditModeration) debounceEditModeration.cancel();

		debounceEditModeration = _.debounce(() => {
			dispatch(
				editModeration(err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
						load();
					}
				})
			);
		}, 1000);

		debounceEditModeration();
	};

	if (!item) return;

	return (
		<Modal
			aria-labelledby="edit-order-modal-title"
			aria-describedby="edit-order-modal-description"
			className={classes.modal}
			open={showEditModal}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showEditModal}>
				<div className={classes.paper}>
					<Typography variant="h6">Editar moderação de subsídio</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="amount"
										required
										variant="outlined"
										name="authorizedAllowance"
										value={amount}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputComponent: MONEY
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="originalAmount"
										required
										variant="outlined"
										name="requestAllowance"
										InputProps={{
											inputComponent: MONEY
										}}
										value={originalAmount}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<Select
										errors={errors}
										id="category"
										name="category"
										value={category}
										required
										onChange={handleChange}
										options={[
											...Object.keys(moderationCategory).map(c => ({
												id: c,
												value: ls[moderationCategory[c]]
											}))
										]}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											...Object.keys(moderationStatus).map(c => ({
												id: c,
												value: ls[moderationStatus[c]]
											}))
										]}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.edit}
								>
									{ls.save}
									{loading.edit && <Spinner color="secondary" size={20} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditModeration;
