import produce from "immer";
import { Reducer } from "react";
import {
	ActionPayload,
	ActionTypes,
	IAccountHolderTransactionState
} from "store/actions/accountHolderTransaction/types";

const initialState: IAccountHolderTransactionState = {
	items: [],
	pages: 0,
	loading: false,
	insufficientFunds: false
};

const reducer: Reducer<IAccountHolderTransactionState, ActionPayload> = (
	state = initialState,
	{ type, payload }
) =>
	produce(state, draft => {
		switch (type) {
			case ActionTypes.GET_ACCOUNT_HOLDER_TRANSACTION_REQUEST:
			case ActionTypes.CREATE_ACCOUNT_HOLDER_TRANSACTION_REQUEST:
			case ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_REQUEST: {
				draft.loading = true;
				draft.insufficientFunds = false;
				break;
			}
			case ActionTypes.CREATE_ACCOUNT_HOLDER_TRANSACTION_SUCCESS:
			case ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_SUCCESS: {
				draft.loading = false;
				break;
			}

			case ActionTypes.IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED: {
				draft.loading = false;
				draft.insufficientFunds = true;
				break;
			}

			case ActionTypes.GET_ACCOUNT_HOLDER_TRANSACTION_SUCCESS: {
				const { items = [], pages = 0 } = payload;
				draft = { items, pages, loading: false, insufficientFunds: false };
				break;
			}

			default: {
				draft.loading = false;
			}
		}
		return draft;
	});

export default reducer;
