export default {
	0: "allowance",
	1: "order",
	2: "consumptionLimit"
};

export enum ModerationCategory {
	Allowance = 0,
	Order = 1,
	ConsumptionLimit = 2
}
