import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	TextField,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import { CreditType } from "models/creditOrigin";
import creditStatus from "models/creditStatus";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearValues, editCredit, setValue } from "store/actions/credit/edit";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceEditCredit: (() => void) & _.Cancelable = null;

function EditCredit({ open, item, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (open && item) {
			dispatch(clearValues());

			dispatch(
				setValue({
					item,
					company: item.company,
					companyId: item.companyId,
					value: item.value / 100,
					user: item.user,
					minValue: item.minValue / 100,
					startDate: item.startDate,
					expirationDate: item.expirationDate,
					userId: item.userId,
					status: item.status,
					origin: item.origin,
					description: item.description
				})
			);
		}
	}, [dispatch, open, item]);

	const {
		value,
		status,
		origin,
		expirationDate,
		minValue,
		startDate,
		description,
		loading,
		errors
	} = useSelector<any, any>(s => s.creditEdit);

	const handleChange = (name, value) => {
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditCredit) debounceEditCredit.cancel();

		debounceEditCredit = _.debounce(() => {
			dispatch(
				editCredit((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						handleClose();
					}
				})
			);
		}, 1000);

		debounceEditCredit();
	};

	return (
		<Modal
			aria-labelledby="edit-credit-modal-title"
			aria-describedby="edit-credit-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editCredit}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="value"
										required
										variant="outlined"
										name="value"
										value={value}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputComponent: MONEY
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="minValue"
										required
										variant="outlined"
										name="minValue"
										InputProps={{
											inputComponent: MONEY
										}}
										value={minValue}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<DatePicker
										value={startDate}
										label={ls.startAt}
										fullWidth
										onChange={date => handleChange("startDate", date)}
										format={ls.dateFormatShort}
										inputVariant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<DatePicker
										value={expirationDate}
										label={ls.expirationDate}
										fullWidth
										onChange={date => handleChange("expirationDate", date)}
										format={ls.dateFormatShort}
										inputVariant="outlined"
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											...Object.keys(creditStatus).map(c => ({
												id: c,
												value: ls[creditStatus[c]]
											}))
										]}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Select
										errors={errors}
										id="origin"
										name="origin"
										value={origin}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: CreditType.Undefined, value: "Não definido" },
											{ id: CreditType.Cashback, value: "Cashback" },
											{ id: CreditType.Campaign, value: "Campanha" },
											{ id: CreditType.Release, value: "Lançamento" },
											{ id: CreditType.Refund, value: "Reembolso" },
											{ id: CreditType.Prize, value: "Premiação" }
										]}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={12}>
									<TextField
										multiline
										rows={3}
										variant="outlined"
										value={description}
										label={ls.description}
										fullWidth
										onChange={e => handleChange("description", e.target.value)}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.edit}
								>
									{ls.save}
									{loading.edit && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditCredit;
