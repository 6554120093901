import { Dayjs } from "dayjs";

export enum ActionTypes {
	CREATE_ACCOUNT_HOLDER_TRANSACTION_REQUEST = "[TREASURY ACCOUNT] CREATE_ACCOUNT_HOLDER_TRANSACTION",
	CREATE_ACCOUNT_HOLDER_TRANSACTION_SUCCESS = "[TREASURY ACCOUNT] CREATE_ACCOUNT_HOLDER_TRANSACTION_SUCCESS",
	CREATE_ACCOUNT_HOLDER_TRANSACTION_FAILED = "[TREASURY ACCOUNT] CREATE_ACCOUNT_HOLDER_TRANSACTION_FAILED",

	IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_REQUEST = "[TREASURY ACCOUNT] IMPORT_ACCOUNT_HOLDER_TRANSACTIONS",
	IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_SUCCESS = "[TREASURY ACCOUNT] IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_SUCCESS",
	IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED = "[TREASURY ACCOUNT] IMPORT_ACCOUNT_HOLDER_TRANSACTIONS_FAILED",

	GET_ACCOUNT_HOLDER_TRANSACTION_REQUEST = "[TREASURY ACCOUNT] GET_ACCOUNT_HOLDER_TRANSACTION_REQUEST",
	GET_ACCOUNT_HOLDER_TRANSACTION_SUCCESS = "[TREASURY ACCOUNT] GET_ACCOUNT_HOLDER_TRANSACTION_SUCCESS",
	GET_ACCOUNT_HOLDER_TRANSACTION_FAILED = "[TREASURY ACCOUNT] GET_ACCOUNT_HOLDER_TRANSACTION_FAILED"
}

export interface IAccountHolderTransaction {
	id: string;
	createdById: string;
	businessId: string;
	business: string;
	value: number;
	type: number;
	status: number;
	sendAt?: Dayjs;
}

export interface IAccountHolderTransactionState {
	items: IAccountHolderTransaction[];
	pages: number;
	loading: boolean;
	insufficientFunds: boolean;
}

export interface ActionPayload {
	type: string;
	payload?: {
		item?: IAccountHolderTransaction;
		items?: IAccountHolderTransaction[];
		pages?: number;
		insufficientFunds?: boolean;
	};
}
