import { Button, Popover, Typography } from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import { statusTransaction, typesTransaction } from "models/enumTypes";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { createTransaction } from "store/actions/accountHolderTransaction/create";
import { getTransfers } from "store/actions/accountHolderTransaction/report";
import { IAccountHolderTransactionState } from "store/actions/accountHolderTransaction/types";

import AddAccountHolderTransaction from "../../Add/AccountHolderTransaction";

interface IUserProps {
	cpf: string;
	name: string;
	lastName: string;
}

interface Props {
	id: string;
	user: IUserProps;
	load: Function;
	isWiipoFlex?: boolean;
}

interface StatusProps {
	item: any;
}

const StatusComponent: React.FC<StatusProps> = ({ item }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	if (item.statusReason) {
		return (
			<>
				<Typography
					aria-owns={open ? "mouse-over-popover" : undefined}
					aria-haspopup="true"
					onMouseEnter={handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
				>
					{statusTransaction[item.status]}
				</Typography>
				<Popover
					id="mouse-over-popover"
					open={open}
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left"
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left"
					}}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<Typography>{item.statusReason}</Typography>
				</Popover>
			</>
		);
	}

	return statusTransaction[item.status] ?? item.status;
};

const AccountHolderTransaction: React.FC<Props> = ({
	id,
	user,
	load: loadUser,
	isWiipoFlex
}) => {
	const dispatch = useDispatch();
	const [showModalAdd, setShowModalAdd] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const filterProps = useRef<any>({});

	const transactionsDebounced = useRef<(() => any) & _.Cancelable>(null);
	const table = useRef(null);
	const contentTable = useRef<HTMLDivElement>(null);

	const { items, pages, loading } = useSelector<
		any,
		IAccountHolderTransactionState
	>(s => s.accountHolderTransaction);

	const load = useCallback(() => {
		dispatch(getTransfers({ user_id: id, ...filterProps.current }));
	}, [dispatch, id]);

	useEffect(() => {
		if (isLoaded) {
			load();
		}
	}, [isLoaded, load]);

	useEffect(() => {
		const onScroll = (_e): void => {
			if (contentTable && contentTable.current) {
				const rect = contentTable.current.getBoundingClientRect();
				if (
					rect.top -
						(window.innerHeight || document.documentElement.clientHeight) <=
						0 &&
					!isLoaded
				) {
					setIsLoaded(true);
				}
			}
		};

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	});

	const handleFetchData = useCallback(
		tableState => {
			if (!isLoaded) {
				return;
			}

			if (transactionsDebounced.current) {
				transactionsDebounced.current.cancel();
			}

			let { page, pageSize, sorted, filtered } = tableState;

			const [sort] = sorted || [{ id: "", desc: true }];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");
				let date = null;

				if (creationDate?.value.startDate || creationDate?.value.endDate) {
					date = {
						startAt: creationDate.value.startDate,
						endAt: creationDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "creationDate",
						value: JSON.stringify(date)
					});
				}
			}

			const filters = {};

			filtered.forEach(item => {
				filters[`filters[${item.id}]`] = item.value;
			});

			filterProps.current = {
				skip: page * pageSize,
				take: pageSize,
				filter: filters,
				sort: sort.id,
				desc: sort.desc
			};

			transactionsDebounced.current = _.debounce(() => load(), 500);

			transactionsDebounced.current();
		},
		[isLoaded, load]
	);

	const handleSubmit = useCallback(
		({ type, value, balanceAccountTypeId, description }) => {
			dispatch(
				createTransaction(
					{ value, type, balanceAccountTypeId, user_id: id, description },
					err => {
						if (err) {
							toastError(err);
						} else {
							load();
							loadUser();
						}
					}
				)
			);
		},
		[dispatch, id, load, loadUser]
	);

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				defaultSortDesc: true,
				width: 180,
				show: true,
				sortMethod: (a, b, _desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					if (aD.isSame(bD)) {
						return 0;
					}

					return aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.createdBy,
				id: "createdBy",
				accessor: c => c.createdBy?.fullName ?? "Não disponivel"
			},
			{
				Header: ls.value,
				id: "value",
				accessor: c => toStringCurrency(c.value / 100)
			},
			{
				Header: ls.type,
				id: "type",
				accessor: c => typesTransaction[c.type] ?? c.type,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : ""}
					>
						<option value="">{ls.all}</option>
						{Object.keys(typesTransaction).map(item => (
							<option value={item} key={item}>
								{typesTransaction[item]}
							</option>
						))}
					</select>
				)
			},
			{
				Header: ls.balanceAccountType,
				id: "balanceAccountType",
				accessor: "balanceAccountType.name"
			},
			{
				Header: ls.status,
				id: "status",
				accessor: c => <StatusComponent item={c} />,
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : ""}
					>
						<option value="">{ls.all}</option>
						{Object.keys(statusTransaction).map(item => (
							<option value={item} key={item}>
								{statusTransaction[item] ?? item}
							</option>
						))}
					</select>
				)
			}
		],
		[]
	);

	return (
		<>
			<AddAccountHolderTransaction
				open={showModalAdd}
				onAdd={handleSubmit}
				user={user}
				handleClose={() => setShowModalAdd(false)}
			/>
			<div ref={contentTable}>
				<Report
					manual
					tableRef={table}
					title={ls.accountHolderTransaction}
					data={items || []}
					pages={pages}
					loading={loading}
					onFetchData={handleFetchData}
					filterable
					headerRightComponent={
						<>
							{!isWiipoFlex && (
								<Button color="primary">
									<Link to="/BalanceAccountType">{ls.types}</Link>
								</Button>
							)}
							<Button color="primary" onClick={() => setShowModalAdd(true)}>
								Adicionar
							</Button>
						</>
					}
					defaultFilterMethod={(filter, row) =>
						String(row[filter.id])
							.toLowerCase()
							.indexOf(filter.value.toLowerCase()) > -1
					}
					columns={columns}
					defaultSorted={[
						{
							id: "creationDate",
							desc: true
						}
					]}
				/>
			</div>
		</>
	);
};

export default AccountHolderTransaction;
