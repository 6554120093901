import ModalSelector from "Components/ModalSelector";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	editBalanceAccountTypeStores,
	getBusinessesTiny,
	setValue
} from "store/actions/balanceAccount/report";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";

let debounceEditBalanceAccountTypeStores: (() => void) & _.Cancelable = null;

function BusinessesForm() {
	const dispatch = useDispatch();

	const { showBusinessesFormModal, businessesTiny, businesses, loading } =
		useSelector<any, IBalanceAccountState>(c => c.balanceAccount);

	useEffect(() => {
		if (showBusinessesFormModal) dispatch(getBusinessesTiny());
	}, [dispatch, showBusinessesFormModal]);

	const handleClose = () =>
		dispatch(setValue({ showBusinessesFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ businessId: u.id, status: u.status }));

		if (debounceEditBalanceAccountTypeStores)
			debounceEditBalanceAccountTypeStores.cancel();

		debounceEditBalanceAccountTypeStores = _.debounce(() => {
			dispatch(
				editBalanceAccountTypeStores(data, err => {
					if (err) {
						console.log("err", err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						handleClose();
					}
				})
			);
		}, 1000);

		debounceEditBalanceAccountTypeStores();
	};

	if (!showBusinessesFormModal) return null;

	return (
		<ModalSelector
			open={showBusinessesFormModal}
			id="edit-balance-account-type-businesses-policy"
			loading={loading}
			data={businessesTiny.map(c => ({
				id: c.id,
				name: c.name,
				status: businesses.find(d => d.businessId === c.id && d.status) ? 1 : 0
			}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="Empresas"
		/>
	);
}

export default BusinessesForm;
