import * as actions from "store/actions/exportHistory";

import { IExportHistory } from "./types";

const initialState: IExportHistory = {
	items: [],
	filter: [],
	pages: null,
	count: 0,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_EXPORT_HISTORIES: {
			return {
				...state,
				loading: { ...state.loading, search: true },
				errors: initialState.errors
			};
		}
		case actions.GET_EXPORT_HISTORIES_SUCCESS: {
			return {
				...state,
				loading: { ...state.loading, search: false },
				items: payload
			};
		}
		case actions.GET_EXPORT_HISTORIES_FAILED: {
			return {
				...state,
				loading: { ...state.loading, search: false },
				errors: payload
			};
		}
		case actions.SEND_EMAIL: {
			return {
				...state,
				loading: { ...state.loading, sendEmail: true },
				errors: initialState.errors
			};
		}
		case actions.SEND_EMAIL_SUCCESS: {
			return {
				...state,
				loading: { ...state.loading, sendEmail: false }
			};
		}
		case actions.SEND_EMAIL_FAILED: {
			return {
				...state,
				loading: { ...state.loading, sendEmail: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
