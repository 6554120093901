import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fab,
	Grid,
	IconButton
} from "@material-ui/core";
import Report from "Components/Report";
import dayjs from "dayjs";
import { toastError } from "helpers/toast";
import ls from "Localization";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { MdAdd, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
	clearValues,
	setValue as setEditValue
} from "store/actions/campaign/createEdit";
import { deleteCampaign, getCampaigns } from "store/actions/campaign/list";
import { State } from "store/reducers";
import { CampaignListState } from "store/reducers/campaign/list";
import { Campaign } from "Types/Campaign";

import useStyles from "./styles";

const CampaignReport: React.FC = () => {
	const styles = useStyles({});
	const table = useRef(null);
	const dispatch = useDispatch();
	const { items, loading } = useSelector<State, CampaignListState>(
		s => s.campaignList
	);
	const [openDialog, setOpenDialog] = useState(false);
	const [deleteCampaignId, setDeleteCampaignId] = useState("");

	const onPressDelete = useCallback((id: string) => {
		setOpenDialog(true);
		setDeleteCampaignId(id);
	}, []);

	const onPressConfirmDelete = useCallback(() => {
		dispatch(
			deleteCampaign(deleteCampaignId, error => {
				if (error) toastError(JSON.stringify(error));
				else {
					setOpenDialog(false);
					setDeleteCampaignId(undefined);
					dispatch(getCampaigns(() => {}));
				}
			})
		);
	}, [deleteCampaignId, dispatch]);

	const columns = [
		{
			Header: "Criado em",
			id: "createdAt",
			accessor: (c: Campaign) =>
				dayjs(c.createdAt).add(3, "hour").format(ls.dateFormatWithoutSeconds)
		},
		{
			Header: ls.name,
			accessor: "name"
		},
		{
			Header: ls.startAt,
			id: "startAt",
			accessor: (c: Campaign) =>
				dayjs(c.startAt).add(3, "hour").format(ls.dateFormatWithoutSeconds)
		},
		{
			Header: ls.endAt,
			id: "endAt",
			accessor: (c: Campaign) =>
				dayjs(c.endAt).add(3, "hour").format(ls.dateFormatWithoutSeconds)
		},
		{
			Header: ls.actions,
			id: "actions",
			accessor: c => (
				<Grid justify="center" alignItems="center" direction="row">
					<IconButton
						style={{
							fontSize: "1.3rem"
						}}
						onClick={() => dispatch(setEditValue({ item: c }))}
					>
						<Link
							to={{
								pathname: "/CampaignClub/Edit"
							}}
						>
							<MdEdit />
						</Link>
					</IconButton>
					<IconButton
						style={{
							fontSize: "1.3rem"
						}}
						onClick={() => onPressDelete(c.id)}
					>
						<FaTrash />
					</IconButton>
				</Grid>
			)
		}
	];

	useEffect(() => {
		dispatch(getCampaigns(() => {}));
	}, [dispatch]);
	return (
		<>
			<Report
				manual
				tableRef={table}
				title={ls.campaigns}
				data={items}
				columns={columns}
				loading={loading.get}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>

			<Fab
				color="primary"
				aria-label="Add"
				className={styles.fab}
				onClick={() => {
					dispatch(clearValues());
					window.open("/CampaignClub/Create", "_blank");
				}}
			>
				<MdAdd size={24} />
			</Fab>

			<Dialog
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Deseja apagar a campanha?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Esta ação é permanente e você perderá todos os dados de sua
						campanha!
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenDialog(false)}>Não</Button>
					<Button onClick={onPressConfirmDelete} autoFocus>
						Sim
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default CampaignReport;
