import {
	Backdrop,
	Button,
	Checkbox,
	Fade,
	FormControlLabel,
	Grid,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import weekDays from "models/weekDays";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editAllowanceDays,
	setValue
} from "store/actions/allowance";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceDaysForm: (() => void) & _.Cancelable = null;

function DaysForm() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { showDaysFormModal, days, errors, loading } = useSelector<any, any>(
		c => c.allowance
	);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleDayChange = (dayOfWeek: number, id: string, value: any) => {
		let day = days.find(c => c.dayOfWeek === dayOfWeek);

		day[id] = value;

		dispatch(setValue({ days }));
	};

	const handleClose = () => handleChange("showDaysFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceDaysForm) debounceDaysForm.cancel();

		debounceDaysForm = _.debounce(() => {
			dispatch(
				editAllowanceDays((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceDaysForm();
	};

	return (
		<Modal
			aria-labelledby="edit-allowance-days-modal-title"
			aria-describedby="edit-allowance-days-modal-description"
			className={classes.modal}
			open={showDaysFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showDaysFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editAllowanceTime}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>Dia</TableCell>
												<TableCell>Início</TableCell>
												<TableCell>Fim</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{(days || []).map(c => (
												<TableRow key={c.dayOfWeek}>
													<TableCell style={{ padding: 8, width: 116 }}>
														<FormControlLabel
															control={
																<Checkbox
																	style={{ padding: 2 }}
																	checked={c.enable}
																	onChange={(e, v) =>
																		handleDayChange(c.dayOfWeek, "enable", v)
																	}
																/>
															}
															label={ls[weekDays[c.dayOfWeek]]}
														/>
													</TableCell>
													<TableCell style={{ padding: 8 }}>
														{c.enable && (
															<TextInput
																id="start"
																name="start"
																inputProps={{
																	type: "time"
																}}
																hideLabel
																variant="standard"
																errors={errors}
																onChange={(id, v) =>
																	handleDayChange(c.dayOfWeek, id, v)
																}
																value={c.start}
															/>
														)}
													</TableCell>
													<TableCell style={{ padding: 8 }}>
														{c.enable && (
															<TextInput
																id="end"
																name="end"
																inputProps={{
																	type: "time"
																}}
																hideLabel
																variant="standard"
																errors={errors}
																onChange={(id, v) =>
																	handleDayChange(c.dayOfWeek, id, v)
																}
																value={c.end}
															/>
														)}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.days}
								>
									{ls.save}
									{loading.days && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default DaysForm;
