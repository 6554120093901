import * as actions from "store/actions/world";

import { IWorldState } from "./types";

const initialState: IWorldState = {
	items: [],
	states: [],
	cities: [],
	neighborhoods: [],
	streets: [],
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_STATES: {
			return {
				...state,
				loading: { state: true },
				errors: initialState.errors
			};
		}
		case actions.GET_STATES_SUCCESS: {
			return {
				...state,
				states: payload || [],
				loading: { state: false }
			};
		}
		case actions.GET_STATES_FAILED: {
			return {
				...state,
				loading: { state: false },
				errors: payload
			};
		}
		case actions.GET_CITIES: {
			return {
				...state,
				loading: { city: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CITIES_SUCCESS: {
			return {
				...state,
				cities: payload || [],
				loading: { city: false }
			};
		}
		case actions.GET_CITIES_FAILED: {
			return {
				...state,
				loading: { city: false },
				errors: payload
			};
		}
		case actions.GET_NEIGHBORHOODS: {
			return {
				...state,
				loading: { neighborhood: true },
				errors: initialState.errors
			};
		}
		case actions.GET_NEIGHBORHOODS_SUCCESS: {
			return {
				...state,
				neighborhoods: payload || [],
				loading: { neighborhood: false }
			};
		}
		case actions.GET_NEIGHBORHOODS_FAILED: {
			return {
				...state,
				loading: { neighborhood: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
