import { makeStyles } from "@material-ui/core/styles";
import { emphasize } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme: any) => ({
	root: { width: "100%" },
	input: {
		display: "flex",
		marginLeft: 15,
		padding: 0,
		alignItems: "center"
	},
	inputStartAdornment: {
		marginLeft: 0
	},
	valueContainer: {
		display: "flex",
		flexWrap: "wrap",
		flex: 1,
		alignItems: "center",
		overflow: "auto",
		minHeight: 38,
		padding: "8px 0"
	},
	chip: {
		margin: theme.spacing(0.5, 0.25)
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === "light"
				? theme.palette.grey[300]
				: theme.palette.grey[700],
			0.08
		)
	},
	noOptionsMessage: {
		padding: theme.spacing(1, 2)
	},
	singleValue: {
		fontSize: "1rem",
		whiteSpace: "nowrap",
		// textOverflow: "ellipsis",
		width: "95%",
		overflow: "hidden"
	},
	placeholder: {
		position: "absolute",
		left: 16,
		fontSize: "1rem"
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing(),
		minWidth: 280,
		left: 0,
		right: 0,
		backgroundColor: "#fff"
	},
	divider: {
		height: theme.spacing(2)
	},
	iconContainer: {
		padding: "4px !important"
	},
	inputOutlined: {
		minHeight: 56
	},
	placeholderStartAdornment: {
		left: 48
	},
	rootOutlined: {}
}));

export default useStyles;
