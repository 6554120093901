import styled from "styled-components";

interface IPropsButton {
	backgroudLoading: boolean;
}

export const Container = styled.div`
	background-color: #fff;
	padding: 40px;
	text-align: center;
`;

export const TextCenter = styled.span`
	font-size: 24px;
	font-weight: normal;
	letter-spacing: 0px;
	font-family: "Exo";
	color: #707070;
	margin-bottom: 20px;
`;

export const TextBold = styled.span`
	font-size: 21px;
	font-weight: bold;
	letter-spacing: 0px;
	font-family: "Exo";
	letter-spacing: 0px;
	color: #707070;
`;

export const RowButtons = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 20px;
	width: 100%;
	justify-content: center;
	text-align: left;
`;

export const ButtonCancel = styled.button`
	margin-right: 20px;
	background: #b9b9b9 0% 0% no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	color: #fff;
	border: none;
	padding: 10px;
	font: normal normal 600 18px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	width: 140px;
	cursor: pointer;
`;

export const ButtonTransfer = styled.button<IPropsButton>`
	background: ${props => (props.backgroudLoading ? "#b9b9b9" : "#5734d9")} 0% 0%
		no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	color: #fff;
	border: none;
	padding: 10px;
	font: normal normal 600 18px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	width: 140px;
	cursor: pointer;
`;
