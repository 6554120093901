import { Popover, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { MdInfo } from "react-icons/md";

interface PopoverProps {
	children?: ReactElement;
	handlePopoverOpen: (event) => void;
	icon?: ReactElement;
	anchorEl: any;
}

const PopoverComponent: React.FC<PopoverProps> = ({
	children,
	icon,
	handlePopoverOpen,
	anchorEl
}) => {
	const open = Boolean(anchorEl);
	return (
		<div>
			<Typography
				aria-owns={open ? "mouse-over-popover" : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={() => {}}
			>
				{icon || <MdInfo color="#5734D9" style={{ marginLeft: 5 }} />}
			</Typography>
			<Popover
				id="mouse-over-popover"
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left"
				}}
				onClose={() => {}}
				disableRestoreFocus
			>
				{children}
			</Popover>
		</div>
	);
};

export default PopoverComponent;
