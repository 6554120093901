import styled from "styled-components";

interface PropsActive {
	active: boolean;
}

export const Container = styled.div<PropsActive>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${props => (props.active ? "#5734da" : "#fff")};
	margin-right: 20px;
	height: 20px;
	width: 30px;
	border-radius: 26px;
	border: 2px solid #efefef;
	padding: 4px;
	margin-right: 15px;
`;

export const RowLabel = styled.div`
	display: flex;
	flex-direction: row;
	margin: 10px 0;
`;

export const Label = styled.div`
	text-align: center;
	font: normal normal medium 16px/21px Exo;
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
`;

export const ButtonYes = styled.button<PropsActive>`
	background-color: ${props => (props.active ? "#fff" : "#fff")};
	border: none;
	padding: 5px;
	border-radius: 26px;
`;

export const ButtonNo = styled.button<PropsActive>`
	background-color: ${props => (!props.active ? "#D1D1D1" : "#5734da")};
	border: none;
	padding: 5px;
	border-radius: 26px;
`;
