const styles = theme => {
	return {
		root: {
			padding: theme.spacing(2)
		},
		form: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(2)
		},
		line: {
			marginLeft: -16,
			marginRight: -16
		},
		header: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between"
		}
	};
};

export default styles;
