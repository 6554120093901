import { Button, Grid, Link, Paper, Typography } from "@material-ui/core";
import { CircularProgress, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Report from "Components/Report";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { storeIsViaVarejo } from "helpers/stores-via-varejo";
import { toStringCurrency, toStringPercentage } from "helpers/string";
import ls from "Localization";
import businessStatus from "models/businessStatus";
import saleStatus from "models/saleStatus";
import saleType from "models/saleType";
import React, { useCallback, useEffect, useState } from "react";
import { MdEmail, MdPictureAsPdf } from "react-icons/md";
import { connect } from "react-redux";
import * as actions from "store/actions/transaction/detail";

import EditTransaction from "../Edit";
import ExtraProperties from "./ExtraProperties";
import Installments from "./Installments";
import Menu from "./Menu";
import ModalClientMail from "./ModalClientMail";
import styles from "./styles";

export function TransactionDetail({
	classes,
	history,
	state: { item, loading },
	clearValues,
	getTransaction,

	downloadPDF,
	downloadPDFAll,
	sendMail,
	sendMailAll,
	login: { user },
	match: {
		params: { id }
	}
}) {
	const [showEdit, setShowEdit] = useState(false);

	const [showModalAdd, setShowModalAdd] = useState(false);
	const [saleProps, setSaleProps] = useState({
		id: "",
		saleId: ""
	});

	useEffect(() => {
		clearValues();
	}, [clearValues]);

	useEffect(() => {
		getTransaction(id);
	}, [getTransaction, id]);

	const handleCloseEdit = () => {
		getTransaction(id);
		setShowEdit(false);
	};

	const handleDownloadPDF = useCallback(
		({ id, saleId }) => {
			downloadPDF({ id, saleId });
		},
		[downloadPDF]
	);

	const handleSendMail = useCallback(
		({ id, saleId, email }) => {
			sendMail({ id, saleId, email });
		},
		[sendMail]
	);

	if (loading.get) return <div>Carregando...</div>;

	if (!item) return null;

	let {
		creationDate,
		code,
		store,
		company,
		fee,
		value,
		discount,
		description,
		client,
		type,
		products,
		status,
		splits
	} = item;

	if (
		products.length > 0 &&
		saleProps.id !== products[0].id &&
		saleProps.saleId !== products[0].saleId
	) {
		setSaleProps({
			id: products[0].id,
			saleId: products[0].saleId
		});
	}

	const isAdmin = isInRole(user, ["Admin"]);

	if (!store) return null;

	let storeName = store.displayName || store.name;

	if (store.status !== 1) storeName += `(${ls[businessStatus[store.status]]})`;

	return (
		<>
			<ModalClientMail
				open={showModalAdd}
				onSubmitMail={({ email }) => {
					handleSendMail({
						id: saleProps.id,
						saleId: saleProps.saleId,
						email
					});
				}}
				emailDefault={client.email}
				handleClose={() => setShowModalAdd(false)}
			/>
			<Grid container justify="center" spacing={5}>
				<Grid item xs={11}>
					<Paper className={classes.root}>
						<div className={classes.header}>
							<Typography variant="h6">{ls.transactionData}</Typography>
							<div>
								{isAdmin && (
									<Button color="primary" onClick={() => setShowEdit(true)}>
										{ls.edit}
									</Button>
								)}
								{!storeIsViaVarejo(store.id) && <Menu item={item} />}
							</div>
						</div>
						<hr className={classes.line} />

						<Grid container spacing={2} className={classes.form}>
							<Grid item xs={12} sm={6} md={3} lg={2}>
								<strong>{ls.code}</strong>: {code}
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<strong>{ls.creationDate}</strong>:{" "}
								{dayjs(creationDate).format(ls.dateFormatWithoutSeconds)}
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<strong>{ls.client}</strong>
								{": "}
								<Link
									style={{ cursor: "pointer" }}
									onClick={() => window.open(`/User/${client.id}`, "_blank")}
									underline="hover"
								>
									{client ? `${client?.fullName}` : ls.notDefined}{" "}
								</Link>
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<strong>{ls.company}</strong>
								{": "}
								<Link
									style={{ cursor: "pointer" }}
									onClick={() =>
										window.open(`/Company/${company.id}`, "_blank")
									}
									underline="hover"
								>
									{company ? `${company?.displayName}` : ls.notDefined}{" "}
								</Link>
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={5}>
								<strong>{ls.store}</strong>
								{": "}
								<Link
									style={{ cursor: "pointer" }}
									onClick={() => window.open(`/Store/${store.id}`, "_blank")}
									underline="hover"
								>
									{" "}
									{store ? `${storeName}` : ls.notDefined}{" "}
								</Link>
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<strong>{ls.type}</strong>: {ls[saleType[type]]}
							</Grid>

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<strong>{ls.status}</strong>: {ls[saleStatus[status]]}
							</Grid>

							<Grid item xs={12} />

							<Grid item xs={12} sm={6} md={3} lg={2}>
								<strong>{ls.value}</strong>: {toStringCurrency(value / 100)}
							</Grid>

							<Grid item xs={12} sm={6} md={3} lg={3}>
								<strong>{ls.discount}</strong>:{" "}
								{toStringCurrency(discount / 100)}(
								{toStringPercentage(discount, value)})
							</Grid>

							<Grid item xs={12} sm={6} md={3} lg={3}>
								<strong>{ls.finalValue}</strong>:{" "}
								{storeIsViaVarejo(store.id)
									? toStringCurrency(value / 100)
									: toStringCurrency((value - discount) / 100)}
							</Grid>

							<Grid item xs={12} sm={6} md={3} lg={2}>
								<strong>{ls.fee}</strong>: {toStringCurrency(fee / 100)}(
								{toStringPercentage(fee, value - discount)})
							</Grid>

							<Grid item xs={12}>
								<strong>{ls.description}</strong>:{" "}
								{description || ls.notDefined}
							</Grid>

							<Grid item xs={12}>
								<strong>{ls.externalId}</strong>:{" "}
								{item?.externalId || ls.notDefined}
							</Grid>
						</Grid>

						<EditTransaction
							open={showEdit}
							item={item}
							handleClose={handleCloseEdit}
						/>
					</Paper>
				</Grid>

				{products.length ? (
					<Grid item xs={11}>
						<Report
							title={ls.products}
							data={products}
							PaginationComponent={_ => null}
							headerRightComponent={<div />}
							showExport
							showEmail
							showPDF
							sendMailAll={sendMailAll}
							downloadPDFAll={downloadPDFAll}
							saleProps={saleProps}
							clientEmail={client.email}
							visibleColumns={[]}
							defaultPageSize={products.length || 1}
							qtdProduct={products.length}
							columns={[
								{
									Header: ls.name,
									id: "name",
									accessor: c => c.product.name
								},
								{
									Header: ls.price,
									id: "price",
									accessor: c => toStringCurrency(c.price / 100),
									maxWidth: 110
								},
								{
									Header: ls.discount,
									id: "discount",
									accessor: c => toStringCurrency(c.discount / 100),
									maxWidth: 110
								},
								{
									Header: "PIN",
									id: "pin",
									accessor: c => c.pin
								},
								{
									Header: "Serial",
									id: "serial",
									accessor: c => c.serial
								},
								{
									Header: ls.actions,
									id: "actions",
									accessor: c => (
										<div>
											{(c.serial || c.pin) && (
												<>
													{c.downloadingPDF ? (
														<CircularProgress size={20} />
													) : (
														<>
															<IconButton
																style={{ padding: 4 }}
																onClick={() => {
																	handleDownloadPDF({
																		id: c.id,
																		saleId: c.saleId
																	});
																}}
															>
																<MdPictureAsPdf />
															</IconButton>
														</>
													)}
													{c.sendingEmail ? (
														<CircularProgress size={20} />
													) : (
														<>
															<IconButton
																style={{ padding: 4 }}
																onClick={() => {
																	setSaleProps({
																		id: c.id,
																		saleId: c.saleId
																	});
																	setShowModalAdd(true);
																}}
															>
																<MdEmail />
															</IconButton>
														</>
													)}
												</>
											)}
										</div>
									),
									width: 100
								}
							]}
						/>
					</Grid>
				) : null}

				{splits && splits.length ? (
					<Grid item xs={11}>
						<Report
							title={ls.splits}
							data={splits}
							PaginationComponent={_ => null}
							defaultPageSize={splits.length || 1}
							onRowClicked={row => history.push(`/Transaction/${row.id}`)}
							columns={[
								{
									Header: ls.code,
									id: "code",
									accessor: c => c.code,
									show: true,
									width: 80
								},
								{
									Header: ls.client,
									id: "client",
									accessor: c => c.client?.fullName,
									show: true,
									width: 150
								},
								{
									Header: ls.company,
									id: "company",
									accessor: c => c.company?.name ?? ls.none,
									show: true,
									width: 180
								},
								{
									Header: ls.value,
									id: "value",
									format: c => c.value / 100,
									accessor: c => toStringCurrency(c.value / 100),
									width: 120
								},
								{
									Header: ls.discount,
									id: "discount",
									format: c => c.discount / 100,
									accessor: c =>
										`${toStringCurrency(c.discount / 100)}(${toStringPercentage(
											c.discount,
											c.value
										)})`,
									show: true,
									width: 150
								},
								{
									Header: ls.finalValue,
									id: "finalValue",
									format: c => (c.value - c.discount) / 100,
									accessor: c => toStringCurrency((c.value - c.discount) / 100),
									show: true,
									width: 120
								},
								{
									Header: ls.fee,
									id: "fee",
									format: c => c.fee / 100,
									accessor: c =>
										`${toStringCurrency(c.fee / 100)}(${toStringPercentage(
											c.fee,
											c.value - c.discount
										)})`,
									show: true,
									width: 150
								},
								{
									Header: ls.installments,
									id: "installmentAmount",
									accessor: c => c.installmentAmount
								},
								{
									Header: ls.status,
									id: "status",
									sortable: false,
									show: true,
									accessor: c => ls[saleStatus[c.status]],
									Filter: ({ filter, onChange }) => (
										<select
											onChange={event => onChange(event.target.value)}
											style={{ width: "100%" }}
											value={filter ? filter.value : "all"}
										>
											<option value="">Todos</option>
											{Object.keys(saleStatus).map(c => (
												<option key={c} value={c}>
													{ls[saleStatus[c]]}
												</option>
											))}
										</select>
									)
								}
							]}
						/>
					</Grid>
				) : null}

				<Grid item xs={11}>
					<Installments />
				</Grid>

				{isAdmin && (
					<Grid item xs={11}>
						<ExtraProperties id={id} />
					</Grid>
				)}
			</Grid>
		</>
	);
}

const mapStateToProps = ({ transactionDetail: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = {
	...actions
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(TransactionDetail));
