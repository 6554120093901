import { validateEmail } from "helpers/email";
import { getErrors } from "helpers/error";
import { CNPJValidation, CPFValidation } from "helpers/validators";
import { isValidNumber } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

export const EDIT_USER = "[USER EDIT] EDIT_USER";
export const EDIT_USER_SUCCESS = "[USER EDIT] EDIT_USER_SUCCESS";
export const EDIT_USER_FAILED = "[USER EDIT] EDIT_USER_FAILED";

export const SET_VALUE = "[USER EDIT] SET_VALUE";
export const CLEAR_VALUES = "[USER EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function editUser(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_USER });

		try {
			const { login, userEdit } = getState();

			const {
				item,
				name,
				lastName,
				email,
				phoneNumber,
				cpf,
				enroll,
				admissionDate,
				status,
				street,
				number,
				complement,
				neighborhood,
				city,
				state,
				country,
				zipCode,
				business,
				mothersName,
				birthDate,
				isPJ,
				importedBy
			} = userEdit;

			let errors: any = {};

			if (!number) {
				errors.number = ls.emptyNumber;
			}

			if (!neighborhood) {
				errors.neighborhood = ls.emptyCity;
			}

			if (!city) {
				errors.city = ls.emptyCity;
			}

			if (!street) {
				errors.street = ls.emptyStreet;
			}

			if (!zipCode) {
				errors.zipcode = ls.required;
			}

			if (!name) errors.name = ls.required;
			if (!lastName) errors.lastName = ls.required;
			if (!state) errors.state = ls.required;
			if (!status && status !== 0) errors.status = ls.required;

			if (cpf && !(CPFValidation(cpf) || CNPJValidation(cpf))) {
				errors.cpf = ls.invalidCpf;
			}

			if (!phoneNumber) {
				errors.phoneNumber = ls.phoneEmpty;
			} else if (!isValidNumber(phoneNumber)) {
				errors.phoneNumber = ls.invalidPhoneNumber;
			}

			if (!email) {
				errors.email = ls.emptyEmail;
			} else if (!validateEmail(email)) {
				errors.email = ls.emailNotValid;
			}

			dispatch(setValue({ errors }));

			if (_.keys(errors).length > 0)
				return dispatch({ type: EDIT_USER_FAILED, payload: errors });

			let documents = item.documents || [];

			if (cpf) {
				let document = documents.find(c => c.type === 0 || c.type === 3);

				if (document) {
					document.type = CPFValidation(cpf) ? 0 : 3;
					document.value = cpf;
				} else {
					documents.push({
						type: CPFValidation(cpf) ? 0 : 3,
						value: cpf
					});
				}
			} else {
				documents = documents.filter(c => c.type !== 0 && c.type !== 3);
			}

			const model = {
				...item,
				cpf,
				name,
				lastName,
				email,
				enroll,
				phoneNumber,
				admissionDate,
				address: {
					...(item.address || {}),
					street,
					number,
					complement,
					neighborhood,
					city,
					state,
					zipCode,
					country
				},
				addresses: null,
				files: null,
				phoneNumbers: null,
				businesses: null,
				status,
				documents,
				business: {
					roleId: business?.roleId,
					businessId: business?.businessId
				},
				mothersName,
				birthDate,
				isPJ,
				importedBy
			};

			const response = await api.sendPut(`/User/${item.id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_USER_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			errors = getErrors(result.errors);

			dispatch({
				type: EDIT_USER_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: EDIT_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
