import {
	Button,
	ButtonGroup,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import TooltipLight from "Components/TooltipLight";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import exportHistoryStatus from "models/exportHistoryStatus";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { FaRedo, FaRegEnvelope, FaRegFileExcel } from "react-icons/fa";
import { connect } from "react-redux";
import { exportConsumption } from "store/actions/business/detail";
import * as actions from "store/actions/exportHistory";

import SendEmail from "../SendEmail";

dayjs.extend(customParseFormat);

let getExportsDebounced: (() => void) & _.Cancelable = null;

function ExportReport({
	state,
	login: { user },
	getExports,
	sendEmail,
	exportConsumption,
	setValue
}) {
	const table = useRef(null);

	const [showSendEmail, setShowSendEmail] = useState(null);
	const [AlertComponent, setAlert] = useState(null);

	const isRecharge = useCallback(
		description => String(description).includes("Relatório médio de recargas"),
		[]
	);

	const isAdmin = useMemo(() => isInRole(user, ["admin"]), [user]);

	const columns = useMemo(
		() =>
			[
				{
					Header: ls.creationDate,
					id: "creationDate",
					accessor: c =>
						dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
					width: 180,
					show: true,
					sortMethod: (a, b, desc: boolean) => {
						let aD = dayjs(a, ls.dateFormatWithoutSeconds);
						let bD = dayjs(b, ls.dateFormatWithoutSeconds);

						return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
					},
					ignoreMaxDate: true,
					Filter: DateSelectFilter
				},
				isAdmin
					? {
							Header: ls.createdBy,
							id: "user",
							accessor: c => c.user.fullName,
							show: true,
							width: 200
					  }
					: null,
				{
					Header: ls.description,
					id: "description",
					accessor: c => c.description,
					show: true
				},
				{
					Header: ls.business,
					id: "business",
					accessor: c => c.business?.name ?? ls.notDefined,
					show: true,
					width: 200
				},
				{
					Header: ls.cnpj,
					id: "business.cnpj",
					accessor: c => c.business?.cnpj ?? ls.notDefined,
					show: true,
					width: 180
				},
				{
					Header: ls.from,
					id: "from",
					accessor: c =>
						dayjs(c.from).add(3, "hour").format(ls.dateFormatShort),
					width: 110,
					show: true,
					sortMethod: (a, b, desc: boolean) => {
						let aD = dayjs(a, ls.dateFormatShort);
						let bD = dayjs(b, ls.dateFormatShort);

						return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
					},
					filterable: false
				},
				{
					Header: ls.to,
					id: "to",
					accessor: c => dayjs(c.to).format(ls.dateFormatShort),
					width: 110,
					show: true,
					sortMethod: (a, b, desc: boolean) => {
						let aD = dayjs(a, ls.dateFormatShort);
						let bD = dayjs(b, ls.dateFormatShort);

						return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
					},
					filterable: false
				},
				{
					Header: ls.total,
					id: "total",
					format: c => JSON.parse(c.data || null)?.total ?? 0,
					accessor: c =>
						toStringCurrency(JSON.parse(c.data || null)?.total ?? 0),
					show: true,
					width: 100,
					filterable: false,
					sortable: false
				},
				isAdmin
					? {
							Header: ls.status,
							id: "status",
							accessor: c => ls[exportHistoryStatus[c.status]],
							Filter: ({ filter, onChange }) => (
								<select
									onChange={event => onChange(event.target.value)}
									style={{ width: "100%" }}
									value={filter?.value}
								>
									<option value="">Todos</option>
									{Object.keys(exportHistoryStatus).map(c => (
										<option key={c} value={c}>
											{ls[exportHistoryStatus[c]]}
										</option>
									))}
								</select>
							),
							show: true,
							width: 100
					  }
					: null,
				{
					Header: ls.actions,
					id: "actions",
					accessor: c => (
						<ButtonGroup size="small">
							<TooltipLight title="Baixar relatório" placement="top">
								<Button
									color="secondary"
									onClick={() => window.open(c.link, "_blank")}
								>
									<FaRegFileExcel size={16} style={{ cursor: "pointer" }} />
								</Button>
							</TooltipLight>
							{/* <FaRegFilePdf
					size={18}
					style={{ cursor: "pointer" }}
					onClick={() => window.open(c.linkPdf, "_blank")}
				/> */}
							<TooltipLight title="Enviar por e-mail" placement="top">
								<Button
									color={c.mailSentAt ? "primary" : "secondary"}
									onClick={() => setShowSendEmail(c)}
								>
									<FaRegEnvelope size={16} style={{ cursor: "pointer" }} />
								</Button>
							</TooltipLight>
							{c.status ? (
								<TooltipLight title="Reemitir" placement="top">
									<Button
										color="default"
										onClick={() => handleShowRedoConfirm(c)}
									>
										<FaRedo size={16} style={{ cursor: "pointer" }} />
									</Button>
								</TooltipLight>
							) : null}
						</ButtonGroup>
					),
					width: 160,
					show: true,
					filterable: false
				}
			].filter(c => c),
		[isAdmin]
	);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getExportsDebounced) {
			getExportsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");
			let date = null;

			if (creationDate?.value.startDate || creationDate?.value.endDate) {
				date = {
					startAt: creationDate.value.startDate,
					endAt: creationDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "creationDate",
					value: JSON.stringify(date)
				});
			}
		}

		getExportsDebounced = _.debounce(
			() =>
				getExports(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
					sorted[0]?.id,
					sorted[0]?.desc,
					toExport,
					callback
				),
			500
		);

		getExportsDebounced();
	};

	const load = () => handleFetchData(table.current?.state, null);

	const handleSendEmail = (
		item,
		email: string,
		boleto: string,
		markAsSent: boolean,
		includeIntegrationFile: boolean
	) => {
		sendEmail(
			item.id,
			email,
			boleto,
			markAsSent,
			includeIntegrationFile,
			err => {
				if (err) toastError(err);
				else {
					setShowSendEmail(null);
					load();
				}
			}
		);
	};

	const handleRedo = item => {
		exportConsumption(
			item.businessId,
			dayjs(item.from),
			dayjs(item.to),
			false, // Relatório unificado, por default em reprocessar é false
			isRecharge(item.description),
			(err, model) => {
				if (err) {
					console.log(err);
					if (err.default) toastError(err.default);
					else if (typeof err === "string") toastError(err);
					else toastError(JSON.stringify(err));
				} else {
					toastSuccess("Reemitido com sucesso");
					setAlert(null);
					load();
				}
			}
		);
	};

	const handleShowRedoConfirm = item => {
		setAlert(
			<Dialog
				open
				onClose={() => setAlert(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja confirmar a reemissão da exportação?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação da reemissão da exportação, a exportação antiga
						será inativada.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleRedo(item)} color="primary" autoFocus>
						Confirmar
					</Button>
					<Button onClick={() => setAlert(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const { items, pages, loading, filter } = state;

	return (
		<div>
			<Helmet>
				<title>
					{ls.exports} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.exports}
				data={items}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				useQueryString
				loading={loading.search}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			<SendEmail
				item={showSendEmail}
				open={Boolean(showSendEmail)}
				loading={loading.sendEmail}
				handleClose={() => setShowSendEmail(null)}
				handleConfirm={handleSendEmail}
				isAdmin={isAdmin}
			/>

			{AlertComponent}
		</div>
	);
}

const mapStateToProps = ({ exportHistory: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = { ...actions, exportConsumption };

export default connect(mapStateToProps, mapDispatchToProps)(ExportReport);
