import { Fab } from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import { CREDIT_TYPES } from "models/creditOrigin";
import creditStatus from "models/creditStatus";
import React, { useEffect, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { MdAdd } from "react-icons/md";
import { connect } from "react-redux";
import * as actions from "store/actions/credit/report";

import ImportCredits from "../Import";
import useStyles from "./styles";

dayjs.extend(customParseFormat);

let getCreditsDebounced: (() => void) & _.Cancelable = null;

function CreditReport({
	state,
	getCredits,
	clearValues,
	history,
	login: { user }
}) {
	const classes = useStyles({});

	const table = useRef(null);
	useEffect(() => {
		clearValues();
	}, [clearValues]);

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 150,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.client,
				id: "client",
				accessor: c => c.user?.fullName ?? ls.none,
				show: true
			},
			{
				Header: ls.cpf,
				id: "cpf",
				show: false,
				accessor: c => (c.user?.cpf ? c.user?.cpf : ls.none)
			},
			{
				Header: ls.business,
				id: "company",
				accessor: c => c.company?.name ?? ls.none,
				show: true
			},
			{
				Header: ls.cnpj,
				id: "cnpj",
				show: false,
				accessor: c => (c.company?.cnpj ? c.company?.cnpj : ls.none)
			},
			{
				Header: ls.minValue,
				id: "minValue",
				show: true,
				format: c => c.minValue / 100,
				accessor: c => toStringCurrency(c.minValue / 100),
				filterable: false,
				width: 120
			},
			{
				Header: ls.value,
				id: "value",
				show: true,
				format: c => c.value / 100,
				accessor: c => toStringCurrency(c.value / 100),
				Footer: () => <b>{toStringCurrency(state.value / 100)}</b>,
				filterable: false,
				width: 120
			},
			{
				Header: ls.used,
				id: "used",
				show: true,
				format: c => c.used / 100,
				accessor: c => toStringCurrency(c.used / 100),
				Footer: () => {
					if (state.used) return <b>{toStringCurrency(state.used / 100)}</b>;
					return <b>{toStringCurrency(0)}</b>;
				},
				filterable: false,
				width: 120
			},
			{
				Header: ls.startAt,
				id: "startDate",
				accessor: c =>
					c.startDate
						? dayjs(c.startDate).format(ls.dateFormatWithoutSeconds)
						: "Sem data de início",
				width: 150,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.expirationDate,
				id: "expirationDate",
				accessor: c =>
					c.expirationDate
						? dayjs(c.expirationDate).format(ls.dateFormatWithoutSeconds)
						: "Não expira",
				width: 150,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.status,
				id: "status",
				sortable: false,
				show: true,
				accessor: c => ls[creditStatus[c.status]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(creditStatus).map(c => (
							<option key={c} value={c}>
								{ls[creditStatus[c]]}
							</option>
						))}
					</select>
				),
				width: 150
			},
			{
				Header: ls.description,
				id: "description",
				show: true,
				accessor: c => c.description
			},
			{
				Header: ls.origin,
				id: "origin",
				sortable: false,
				show: true,
				accessor: c => CREDIT_TYPES[c.origin],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(CREDIT_TYPES).map(c => (
							<option key={c} value={c}>
								{CREDIT_TYPES[c]}
							</option>
						))}
					</select>
				),
				width: 150
			}
		],
		[]
	);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getCreditsDebounced) {
			getCreditsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");
		let startDate = filtered.find(c => c.id === "startDate");
		let expirationDate = filtered.find(c => c.id === "expirationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");
			let date = null;

			if (creationDate?.value.startDate || creationDate?.value.endDate) {
				date = {
					startAt: creationDate.value.startDate,
					endAt: creationDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "creationDate",
					value: JSON.stringify(date)
				});
			}
		}

		if (startDate?.value) {
			filtered = filtered.filter(c => c.id !== "startDate");
			let date = null;

			if (startDate?.value.startDate || startDate?.value.endDate) {
				date = {
					startAt: startDate.value.startDate,
					endAt: startDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "startDate",
					value: JSON.stringify(date)
				});
			}
		}

		if (expirationDate?.value) {
			filtered = filtered.filter(c => c.id !== "expirationDate");
			let date = null;

			if (expirationDate?.value.startDate || expirationDate?.value.endDate) {
				date = {
					startAt: expirationDate.value.startDate,
					endAt: expirationDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "expirationDate",
					value: JSON.stringify(date)
				});
			}
		}

		getCreditsDebounced = _.debounce(
			() =>
				getCredits(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
					sorted[0]?.id,
					sorted[0]?.desc,
					toExport,
					callback
				),
			500
		);

		getCreditsDebounced();
	};

	const load = () => handleFetchData(table.current?.state, null);

	const isAdmin = isInRole(user, ["admin"]);

	return (
		<div>
			<Helmet>
				<title>
					{ls.credits} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				headerRightComponent={
					isAdmin ? (
						<div style={{ display: "flex", alignItems: "center" }}>
							<a
								href="https://docs.google.com/spreadsheets/d/1Q4ctoSJ48ytDyIMwM7M7_RPQvkNVdZAI/edit?usp=sharing"
								target="_blank"
								rel="noopener noreferrer"
								style={{ marginRight: 16 }}
							>
								Baixar modelo de importação
							</a>
							<ImportCredits load={load} />
						</div>
					) : undefined
				}
				title={ls.credits}
				data={state.items}
				pages={state.pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={state.loading}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
				onRowClicked={row => window.open(`/Credit/${row.id}`, "_blank")}
			/>

			{isAdmin && (
				<Fab
					color="primary"
					aria-label="Add"
					className={classes.fab}
					onClick={() => history.push("/Credit/Add")}
				>
					<MdAdd size={24} />
				</Fab>
			)}
		</div>
	);
}

const mapStateToProps = ({ creditReport: state, login }) => ({ state, login });

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(CreditReport);
