/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/anchor-has-content */
import { Button, Grid, Paper, Typography } from "@material-ui/core";
import {
	ChevronRight,
	Event,
	FileCopy,
	InsertDriveFile
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import dayjs from "dayjs";
import { copyTextToClipboard } from "helpers/string";
import ls from "Localization";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceOrder } from "store/actions/order/invoiceOrderViaVarejo";
import { getTrackingOrder } from "store/actions/order/trackingOrderViaVarejo";

import { CustomSlider, useStyles } from "./styles";

type Props = {
	idPurchase: number;
};

type Tracking = {
	id_purchase: number;
	status_purchase: string;
	id_order_partner: string;
	shipping_company: string;
	key_access: string;
	date_prediction: string;
	created_at: string;
	status: string;
};

type Invoice = {
	data: string;
	filename: string;
	format: string;
	mime_type: string;
};

type LabelMarkProps = {
	label: string;
	date?: string;
};

const LabelMark = (props: LabelMarkProps) => (
	<Grid container direction="row" alignItems="center" style={{ width: 500 }}>
		<Grid item>
			<ChevronRight />
		</Grid>
		<Grid item style={{ marginBottom: 2, marginRight: 10, fontWeight: "bold" }}>
			{props.label}
		</Grid>
		{props.date && (
			<>
				<Grid item>
					<Event fontSize="small" style={{ fontSize: 17 }} />
				</Grid>
				<Grid item style={{ marginBottom: 2, marginLeft: 5 }}>
					{dayjs(props.date).format(ls.dateFormatWithoutSeconds)}
				</Grid>
			</>
		)}
	</Grid>
);

const FakeLabelMark = () => (
	<Grid container direction="row" alignItems="center" style={{ width: 500 }}>
		<Grid item style={{ marginBottom: 2, marginRight: 10 }}>
			<Skeleton style={{ height: 20, width: 300 }} />
		</Grid>
	</Grid>
);

export default function TrackingOrderViaVarejo(props: Props) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { idPurchase } = props;
	const [haveInvoice, setHaveInvoice] = useState(false);
	const [positionMark, setPositionMark] = useState(0);
	const [marks, setMarks] = useState([]);
	const [datePrediction, setDatePrediction] = useState<string>(null);
	const [shippingCompany, setShippingCompany] = useState<string>(null);

	const { item: trackings } = useSelector<any, { item: Tracking[] }>(
		s => s.trackingOrderViaVarejo
	);
	const { item: invoice } = useSelector<any, { item: Invoice }>(
		s => s.invoiceOrderViaVarejo
	);

	useEffect(() => {
		if (haveInvoice) dispatch(getInvoiceOrder(idPurchase));
	}, [haveInvoice, dispatch, idPurchase]);

	useEffect(() => {
		dispatch(getTrackingOrder(idPurchase));
	}, [idPurchase, dispatch]);

	useEffect(() => {
		if (!trackings) return;

		const invoiceIssued = trackings.filter(tracking => tracking.key_access);
		if (invoiceIssued.length >= 1) setHaveInvoice(true);
	}, [trackings]);

	useEffect(() => {
		if (!trackings) return;

		const marks = trackings.reverse().map((tracking, counter) => ({
			value: counter,
			label: (
				<LabelMark
					key={counter}
					label={tracking.status}
					date={tracking.created_at}
				/>
			)
		}));
		setMarks(marks);
		setPositionMark(marks.length);

		if (marks.length <= 4) {
			for (let index = 1; index <= 4; index++) {
				marks.push({
					value: marks.length,
					label: <FakeLabelMark />
				});
			}
			setPositionMark(trackings.length - 1);
		}

		if (trackings.length > 0) {
			const { date_prediction, shipping_company } = trackings[0];

			setDatePrediction(
				dayjs(date_prediction).format(ls.dateFormatWithoutSeconds)
			);
			setShippingCompany(shipping_company);
		}
	}, [trackings]);

	const copyClipBoardInvoice = () => {
		const trackingWithInvoiceCode = trackings.find(t => t.key_access);
		if (trackingWithInvoiceCode)
			copyTextToClipboard(
				() => {},
				trackingWithInvoiceCode.key_access,
				"Código NFE Copiado",
				{ autoClose: 1000 }
			);
	};

	const downloadInvoice = () => {
		const link: any = document.getElementById("download-invoice");
		if (link) {
			link.href = `data:${invoice.mime_type};base64,${invoice.data}`;
			link.download = invoice.filename;
			link.click();
		}
	};

	if (!trackings) return null;

	return (
		<Grid container justify="space-between" wrap="wrap">
			<Grid item xs={8}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.trackingOrder}</Typography>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.trackingArea}>
						<CustomSlider
							disabled
							orientation="vertical"
							max={marks.length - 1}
							value={positionMark}
							valueLabelDisplay="auto"
							marks={marks}
						/>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={4}>
				<Paper className={classes.detailsTrackingArea}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.actionsTrackingOrder}</Typography>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.actionsArea}>
						<Grid item xs={12}>
							<div className={classes.detailItemAction}>
								<strong>{ls.datePrediction}</strong>
								{": "}
								{datePrediction !== null ? datePrediction : ls.notDefined}
							</div>

							<div className={classes.detailItemAction}>
								<strong>{ls.shippingCompany}</strong>
								{": "}
								{shippingCompany !== null ? shippingCompany : ls.notDefined}
							</div>

							<div className={classes.detailItemAction}>
								<Button
									variant="contained"
									color="primary"
									size="small"
									disabled={!haveInvoice}
									startIcon={<InsertDriveFile />}
									onClick={() => downloadInvoice()}
								>
									Baixar NFE
								</Button>
								<a id="download-invoice" hidden />
								<Button
									variant="contained"
									color="primary"
									size="small"
									style={{ marginLeft: 10 }}
									disabled={!haveInvoice}
									startIcon={<FileCopy />}
									onClick={() => copyClipBoardInvoice()}
								>
									Copiar código NFE
								</Button>
							</div>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
		</Grid>
	);
}
