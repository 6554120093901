import _ from "lodash";

import ls from "../Localization";

export function getErrors(errors: any) {
	const toReturn: any = { _original: errors };
	for (const error of _.toPairs<any>(errors)) {
		toReturn[error[0]] = error[1].map((c: any) => ls[c] || c).join();
	}
	console.log(toReturn);
	return toReturn;
}
