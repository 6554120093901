import {
	Backdrop,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fade,
	Grid,
	IconButton,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import React, { useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import {
	deleteItem,
	getProduct,
	setValue,
	uploadItems
} from "store/actions/product";
import ProductItemStatus from "Types/ProductItemStatus";
import XLSX from "xlsx";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceFilesChange: (() => void) & _.Cancelable = null;

function ItemsModal() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const [alertModal, setAlertModal] = useState(null);

	const inputRef = useRef<HTMLInputElement>(null);

	const { showItemsModal, loading, item } = useSelector<any, any>(
		c => c.product
	);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showItemsModal", false);

	const handleDeleteProductItem = itemId => {
		dispatch(
			deleteItem(itemId, err => {
				if (err) {
					setAlertModal(null);

					console.log(err);

					alert(err.default);
				} else {
					setAlertModal(null);
					dispatch(
						getProduct(item.id, "items", (err, model) => {
							if (err) {
								setAlertModal(null);

								if (typeof err !== "string") err = JSON.stringify(err);

								console.log(err);
							}
						})
					);
				}
			})
		);
	};

	const showConfirm = itemId => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja confirmar a exclusão?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação da exclusão não será possível recuperar os dados.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleDeleteProductItem(itemId)}
						color="primary"
						autoFocus
					>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files[0];

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const wsName = wb.SheetNames[0];
			const sheet = wb.Sheets[wsName];

			const data = XLSX.utils.sheet_to_json(sheet);
			/* Update state */
			const items = data.map(c => ({
				pin: c["pin"] || c["Pin"] || c["PIN"],
				serial: c["serial"] || c["Serial"] || c["SERIAL"],
				status: 0
			}));

			if (debounceFilesChange) debounceFilesChange.cancel();

			debounceFilesChange = _.debounce(() => {
				dispatch(
					uploadItems(items, err => {
						if (err) {
							if (typeof err !== "string") err = JSON.stringify(err);

							console.log(err);

							alert(err);
						} else {
							dispatch(
								getProduct(item.id, "items", (err, model) => {
									if (err) {
										if (typeof err !== "string") err = JSON.stringify(err);

										console.log(err);
									} else {
										alert("Importado com sucesso");
									}
								})
							);
						}
					})
				);
			}, 1000);

			debounceFilesChange();
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);
	};

	if (!showItemsModal) return null;

	return (
		<Modal
			aria-labelledby="edit-product-items-modal-title"
			aria-describedby="edit-product-items-modal-description"
			className={classes.modal}
			open={showItemsModal || false}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showItemsModal}>
				<div className={classes.paper}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant="h6">Items de produto</Typography>
						<Button
							onClick={() => inputRef.current?.click()}
							disabled={loading.photos}
						>
							Importar
						</Button>
					</div>
					<hr />
					<div className={classes.body}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ReactTable
									data={item?.items || []}
									getTdProps={() => ({
										style: {
											display: "flex",
											alignItems: "center"
										}
									})}
									columns={[
										{
											Header: "Adicionado em",
											id: "creationDate",
											accessor: (c: any) =>
												dayjs(c.creationDate).format(
													ls.dateFormatWithoutSeconds
												),
											width: 130
										},
										{ Header: "PIN", accessor: "pin" },
										{ Header: "Serial", accessor: "serial" },
										{
											Header: "Status",
											id: "status",
											accessor: c => ls[ProductItemStatus[c.status]],
											width: 120
										},
										{
											Header: "Ações",
											id: "actions",
											accessor: c => (
												<div>
													<IconButton
														style={{ padding: 4 }}
														onClick={() => showConfirm(c.id)}
													>
														<MdDelete />
													</IconButton>
												</div>
											),
											width: 60
										}
									]}
									pageSize={5}
								/>
							</Grid>
						</Grid>
						<div className={classes.footer}>
							<Button color="primary" variant="contained" onClick={handleClose}>
								{ls.close}
							</Button>
						</div>
						{alertModal}
					</div>
					<input
						ref={inputRef}
						style={{ display: "none" }}
						type="file"
						accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						onChange={handleFilesChange}
					/>
				</div>
			</Fade>
		</Modal>
	);
}

export default ItemsModal;
