import * as actions from "store/actions/header";

import { IHeader } from "./types";

const initialState: IHeader = {
	openMenu: true,
	openAccount: false,
	openNotification: false,
	openUsers: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
