import { Button, Grid, Paper, Typography } from "@material-ui/core";
import NumberFormatCustom from "Components/NumberFormat";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { Field, FormikProvider, useFormik } from "formik";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	createUploadBalanceAccountType,
	updateUploadBalanceAccountType
} from "store/actions/balanceAccount/report";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";
import * as Yup from "yup";

import useStyles from "./styles";

const schema = Yup.object().shape({
	code: Yup.number().positive(ls.invalid).required(ls.required),
	name: Yup.string().required(ls.required)
});

let debounceUpdateUploadBalanceAccountType: (() => void) & _.Cancelable = null;
let debounceCreateUploadBalanceAccountType: (() => void) & _.Cancelable = null;

export default function BalanceAccountTypeForm({ history }) {
	const { state } = history.location;
	const dispatch = useDispatch();
	const classes = useStyles({});
	const [loading, setLoading] = useState(false);
	const [currentType, setCurrentType] = useState({
		name: "",
		code: "",
		description: "",
		imageIcon: "",
		imageBanner: ""
	});
	const { types } = useSelector<any, IBalanceAccountState>(
		types => types.balanceAccount
	);

	useEffect(() => {
		if (state && state.id) {
			const { id } = state;
			const filteredType = types.find(item => item.id === id);
			setCurrentType({
				code: filteredType.externalId,
				name: filteredType.name,
				description: filteredType.description,
				imageIcon: filteredType.icon || "",
				imageBanner: filteredType.banner || ""
			});
		} else if (types.length > 0) {
			const maxId = Math.max(
				...types.map(item => parseInt(item.externalId, 10))
			);
			setCurrentType({
				code: String(maxId + 1),
				name: "",
				description: "",
				imageIcon: "",
				imageBanner: ""
			});
		}
	}, [state, types]);

	useEffect(() => {});

	const preview = useCallback(img => {
		if (img) {
			if (img instanceof File) {
				return URL.createObjectURL(img);
			}
			return img;
		}
		return null;
	}, []);

	const handleSubmit = useCallback(
		async data => {
			if (state && state.id) {
				if (debounceUpdateUploadBalanceAccountType)
					debounceUpdateUploadBalanceAccountType.cancel();

				debounceUpdateUploadBalanceAccountType = _.debounce(() => {
					dispatch(
						updateUploadBalanceAccountType(
							{
								id: state.id,
								name: data.name,
								externalId: data.code,
								description: data.description,
								icon: data.imageIcon,
								banner: data.imageBanner
							},
							error => {
								setLoading(true);
								if (!error) history.push("/BalanceAccountType");
							}
						)
					);
				}, 1000);

				debounceUpdateUploadBalanceAccountType();
			} else {
				if (debounceCreateUploadBalanceAccountType)
					debounceCreateUploadBalanceAccountType.cancel();

				debounceCreateUploadBalanceAccountType = _.debounce(() => {
					dispatch(
						createUploadBalanceAccountType(
							{
								name: data.name,
								externalId: data.code,
								description: data.description,
								icon: data.imageIcon,
								banner: data.imageBanner
							},
							error => {
								setLoading(true);
								if (!error) history.push("/BalanceAccountType");
							}
						)
					);
				}, 1000);

				debounceCreateUploadBalanceAccountType();
			}
		},
		[dispatch, state, history]
	);

	const formik = useFormik({
		initialValues: currentType,
		enableReinitialize: true,
		validationSchema: schema,
		onSubmit: handleSubmit
	});

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<Typography variant="h5">{ls.formBalanceAccount}</Typography>
					<hr className={classes.line} />
					<FormikProvider value={formik}>
						<form onSubmit={formik.handleSubmit}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="code"
										variant="outlined"
										required
										name="code"
										InputProps={{
											inputComponent: NumberFormatCustom
										}}
										value={formik.values.code}
										errors={formik.touched.code ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="name"
										variant="outlined"
										required
										name="name"
										value={formik.values.name}
										errors={formik.touched.name ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="description"
										variant="outlined"
										name="description"
										value={formik.values.description}
										errors={formik.touched.description ? formik.errors : {}}
										onChange={(id, value) => formik.setFieldValue(id, value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3} />
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Field name="imageIcon">
										{({ field, form }) => (
											<>
												{!field.value ? (
													<Button
														variant="contained"
														component="label"
														color="default"
														className={classes.button}
													>
														{ls.selectIcon}
														<input
															type="file"
															hidden
															onChange={event => {
																form.setFieldValue(
																	field.name,
																	event.target.files[0]
																);
															}}
														/>
													</Button>
												) : (
													<div className={classes.button}>
														<img
															alt=""
															className={classes.preview}
															style={{
																backgroundImage: `url(${preview(field.value)})`
															}}
														/>
														<button
															className={classes.xButton}
															type="button"
															onClick={() => {
																form.setFieldValue(field.name);
															}}
														>
															x
														</button>
													</div>
												)}
											</>
										)}
									</Field>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Field name="imageBanner">
										{({ field, form }) => (
											<>
												{!field.value ? (
													<Button
														variant="contained"
														component="label"
														color="default"
														className={classes.button}
													>
														{ls.selectBanner}
														<input
															type="file"
															hidden
															onChange={event => {
																form.setFieldValue(
																	field.name,
																	event.target.files[0]
																);
															}}
														/>
													</Button>
												) : (
													<div className={classes.button}>
														<img
															alt=""
															className={classes.preview}
															style={{
																backgroundImage: `url(${preview(field.value)})`
															}}
														/>
														<button
															className={classes.xButton}
															type="button"
															onClick={() => {
																form.setFieldValue(field.name);
															}}
														>
															x
														</button>
													</div>
												)}
											</>
										)}
									</Field>
								</Grid>
							</Grid>
							<hr className={classes.line} />
							<Grid container justify="flex-end">
								<Button
									variant="contained"
									color="default"
									className={classes.button}
									onClick={() => {
										formik.resetForm();
										formik.resetForm();
										setLoading(false);
									}}
								>
									{ls.clear}
								</Button>
								<Button
									variant="contained"
									color="primary"
									className={classes.button}
									type="submit"
									disabled={loading}
								>
									{loading ? <Spinner color="secondary" size={16} /> : ls.save}
								</Button>
							</Grid>
						</form>
					</FormikProvider>
				</Paper>
			</Grid>
		</Grid>
	);
}
