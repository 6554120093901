import dayjs from "dayjs";
import {
	ActionTypes,
	ITreasuryAccountTransferState
} from "store/actions/treasuryAccountTransfer/types";

const initialState: ITreasuryAccountTransferState = {
	items: [],
	pages: 0,
	loading: false,
	showModalUser: false,
	showModalType: false,
	showModalBusiness: false,
	model: [],
	importTATransfersData: []
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_REQUEST: {
			return {
				...state,
				loading: true
			};
		}
		case ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_SUCCESS: {
			return {
				...state,
				loading: false
			};
		}
		case ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_REQUEST: {
			return {
				...state,
				loading: true
			};
		}
		case ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload.items
			};
		}
		case ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case ActionTypes.GET_TREASURY_ACCOUNT_TRANSACTION_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case ActionTypes.SET_VALUE:
			return {
				...state,
				...payload
			};
		case ActionTypes.CLEAR_VALUES:
			return {
				...initialState,
				creationDate: dayjs()
			};
		default: {
			return state;
		}
	}
};

export default reducer;
