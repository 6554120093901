import { Button, Switch } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Report from "Components/Report";
import SwitchGeneric from "Components/SwitchGeneric";
import ClubWiipoTransfer from "Components/views/Dashboard/Business/Detail/BalanceAccontTransfer/ClubWiipoTransfer";
import { isInRole } from "helpers/auth";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getBalanceAccountTypeForBusiness } from "store/actions/balanceAccount/report";
import { getBalanceAccountTransfer } from "store/actions/balanceAccount/transfer";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";
import {
	updateBusinessPolicy,
	updateBusinessPolicyTransfer
} from "store/actions/business/policy";
import { State } from "store/reducers";
import { stateBalanceAccountTransfer } from "store/reducers/types/balanceAccount";

import styles from "./styles";

let debounceGetTransferBags: (() => void) & _.Cancelable = null;

function BalanceAccountTransferReport({
	id,
	isAdmin,
	classes,
	isFilterActiveHeadquarter,
	isHeadquarters
}) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const [loading, setLoading] = useState(true);
	const [filterActive, setFilterActive] = useState(false);

	const { user } = useSelector<any, any>(s => s.login);

	const isWiipoFlex = isInRole(user, ["Admin Wiipo Flex"]);

	const { types } = useSelector<State, IBalanceAccountState>(
		state => state.balanceAccount
	);

	const idsG4f = [
		"14467598-7197-424c-e043-08db976c0599",
		"21a67077-1ac6-45ee-07fa-08db976c4ad1",
		"e3f8bb9c-8d42-4fa2-12a4-08da2dcc7dc9"
	];

	const { items } = useSelector<State, stateBalanceAccountTransfer>(
		s => s.balanceAccountTransfer
	);

	const typesFilter = types
		.filter(c =>
			!isAdmin && !isWiipoFlex
				? c.externalId !== "19" && c.externalId !== "0"
				: c
		)
		.filter(c =>
			!isAdmin && id && !idsG4f.includes(id) ? c.externalId !== "26" : c
		);

	const findClube = useMemo(
		() => types.find(i => i.externalId === "0")?.id,
		[types]
	);

	const getTransferBags = useCallback(() => {
		if (debounceGetTransferBags) {
			debounceGetTransferBags.cancel();
		}

		setLoading(true);

		debounceGetTransferBags = _.debounce(
			() =>
				dispatch(
					getBalanceAccountTransfer({
						balanceAccountId: findClube,
						businessId: id,
						callback: err => {
							if (err) {
								toastError(err);
							}

							setLoading(false);
						}
					})
				),
			500
		);
		if (findClube) {
			debounceGetTransferBags();
		}
	}, [dispatch, findClube, id]);

	useEffect(() => {
		getTransferBags();
	}, [isFilterActiveHeadquarter]);

	useEffect(() => {
		dispatch(getBalanceAccountTypeForBusiness(id, filterActive));
		setLoading(false);
	}, [dispatch, id, filterActive]);

	const filterItems = useMemo(() => {
		const newArray = typesFilter
			?.filter(
				i =>
					i.businesses.length > 0 &&
					i.businesses?.filter(i => i.status === 1) &&
					i.externalId !== "0"
			)
			?.map(i => {
				const existBalance = Array.isArray(items)
					? items.find(item => item?.toPrePaidBalanceAccountType?.id === i.id)
					: null;

				return {
					name: i.name,
					fromPrePaidBalanceAccountTypeId: findClube,
					toPrePaidBalanceAccountTypeId: i.id,
					status: Boolean(existBalance?.status)
				};
			});

		return newArray;
	}, [findClube, items, typesFilter]);

	const handleUpdateBusinessPolicy = useCallback(
		(prePaidBalanceAccountTypeId: string, status: boolean) => {
			setLoading(true);
			dispatch(
				updateBusinessPolicy(
					[
						{
							prePaidBalanceAccountTypeId,
							status
						}
					],
					err => {
						if (err) {
							if (err.default) {
								toastError("Você não tem permissão para alterar este bolso.");
							} else {
								toastError(err);
							}
						} else {
							dispatch(getBalanceAccountTypeForBusiness(id, filterActive));
						}
						setLoading(false);
					}
				)
			);
		},
		[dispatch, id, setLoading, filterActive]
	);

	const handleUpdateBusinessPolicyTransfer = useCallback(
		(prePaidBalanceAccountTypeId: string, status: boolean) => {
			setLoading(true);
			dispatch(
				updateBusinessPolicyTransfer(
					[
						{
							prePaidBalanceAccountTypeId,
							status
						}
					],
					err => {
						if (err) {
							if (err.default) {
								toastError("Você não tem permissão para alterar este bolso.");
							} else {
								toastError(err);
							}
						} else {
							dispatch(getBalanceAccountTypeForBusiness(id, filterActive));
						}
						setLoading(false);
					}
				)
			);
		},
		[dispatch, id, setLoading, filterActive]
	);

	const handleUpdateLoadFilter = useCallback(
		(status: boolean) => {
			setFilterActive(status);
			dispatch(getBalanceAccountTypeForBusiness(id, status));
		},
		[dispatch, id, setFilterActive]
	);

	return (
		<Report
			tableRef={table}
			loading={loading}
			key="balance-account-type-report"
			title={ls.balanceAccountTypeReport}
			headerRightComponent={
				<>
					{isAdmin && (
						<Link to="/BalanceAccountType">
							<Button color="primary">{ls.configure}</Button>
						</Link>
					)}
					<div className={classes.filterActive}>
						<Switch
							id="switchFilterActive"
							value={filterActive}
							onChange={(_, value) => {
								handleUpdateLoadFilter(value);
							}}
						/>
						Bolsos Ativos
					</div>
				</>
			}
			data={typesFilter || []}
			visibleColumns={[]}
			pageSizeOptions={[10]}
			pageSize={10}
			columns={[
				{
					Header: ls.name,
					id: "name",
					accessor: c => c.name
				},
				{
					Header: ls.code,
					id: "code",
					accessor: c => c.externalId
				},
				{
					Header: ls.description,
					id: "description",
					accessor: c => c.description
				},
				{
					Header: ls.activateBag,
					id: "businessesPolicy",
					width: 100,
					accessor: c => (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center"
							}}
						>
							<SwitchGeneric
								disabled={isFilterActiveHeadquarter && !isHeadquarters}
								changeActive={() => {
									handleUpdateBusinessPolicy(
										c.id,
										!(c.businesses.filter(b => b.status === 1).length > 0)
									);
								}}
								active={c.businesses.filter(b => b.status === 1).length > 0}
							/>
						</div>
					)
				},
				{
					Header: ls.authorizeTransfer,
					id: "businessTransferPolicies",
					width: 180,
					accessor: c => (
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center"
							}}
						>
							<SwitchGeneric
								disabled={isFilterActiveHeadquarter && !isHeadquarters}
								changeActive={() => {
									handleUpdateBusinessPolicyTransfer(
										c.id,
										!(
											c.businessTransferPolicies.filter(b => b.status === 1)
												.length > 0
										)
									);
								}}
								active={
									c.businessTransferPolicies.filter(b => b.status === 1)
										.length > 0
								}
							/>
						</div>
					)
				},
				{
					Header: ls.destinyTransfer,
					id: "destinyTransfer",
					width: 300,
					accessor: c =>
						c.externalId === "0" &&
						(c.businesses.filter(b => b.status === 1).length > 0 ||
							c.businessTransferPolicies.filter(b => b.status === 1).length >
								0) ? (
							<ClubWiipoTransfer
								businessId={id}
								balanceAccountId={c.id}
								items={filterItems}
								isDisableButton={isFilterActiveHeadquarter && !isHeadquarters}
							/>
						) : (
							<></>
						)
				}
			]}
		/>
	);
}

export default withStyles(styles, { withTheme: true })(
	BalanceAccountTransferReport
);
