import * as actions from "store/actions/cashbackPolicy";
import Business from "Types/Business";
import ICashbackPolicy from "Types/CashbackPolicy";

interface ICompaniesTiny {
	displayName: string;
	id: string;
	name: string;
	socialName: string;
	status: number;
}

interface ICompanies {
	business: ICompaniesTiny;
	businessId: string;
	cashbackId: string;
	creationDate: Date;
	id: string;
	status: number;
	updateDate: Date;
}

export interface CashbackPolicyState {
	items: ICashbackPolicy[];
	item: ICashbackPolicy;
	minValue: number;
	business?: Business;
	businessId: string;
	companies: ICompanies[];
	companiesTiny: ICompaniesTiny[];
	stores: ICompanies[];
	storesTiny: ICompaniesTiny[];
	status: number;
	maxInvestment: number;
	value: number;
	maxValue: number;
	limit: number;
	limitPerUser: number;
	activeOn: number;
	creditMinValue: number;
	creditBusinessId: string;
	creditBusiness?: Business;
	info: string;
	startAt: Date;
	endAt: Date;
	dynamicDateStart: boolean;
	dynamicDateEnd: boolean;
	validateDaysDynamicDate: number;
	creditStartAt: Date;
	creditEndAt: Date;
	count: number;
	push: number;
	showFormModal: boolean;
	showImportFormModal: boolean;
	showEditFormModal: boolean;
	showCompaniesFormModal: boolean;
	showStoresFormModal: boolean;
	pages: any;
	errors: {};
	loading: {
		getAll?: boolean;
		get?: boolean;
		create?: boolean;
		update?: boolean;
		delete?: boolean;
		updateCompanies?: boolean;
		updateStores?: boolean;
		getStores?: boolean;
		getCompanies?: boolean;
	};
}

const initialState: CashbackPolicyState = {
	items: [],
	item: null,
	minValue: 0,
	businessId: "",
	status: 1,
	companies: [],
	companiesTiny: [],
	stores: [],
	storesTiny: [],
	maxInvestment: 0,
	value: 0,
	maxValue: 0,
	limit: 0,
	limitPerUser: 0,
	activeOn: 1,
	creditMinValue: 0,
	creditBusinessId: "",
	creditBusiness: null,
	info: "",
	startAt: null,
	endAt: null,
	creditStartAt: null,
	creditEndAt: null,
	dynamicDateStart: false,
	dynamicDateEnd: false,
	validateDaysDynamicDate: 0,
	count: 0,
	pages: 0,
	push: 0,
	showFormModal: false,
	showEditFormModal: false,
	showImportFormModal: false,
	showCompaniesFormModal: false,
	showStoresFormModal: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_ALL_CASHBACK: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}

		case actions.GET_ALL_CASHBACK_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}

		case actions.GET_ALL_CASHBACK_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.GET_CASHBACK: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}

		case actions.GET_CASHBACK_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.minValue = payload.minValue / 100.0 ?? initialState.minValue;
				newState.businessId = payload.businessId ?? initialState.businessId;
				newState.status = payload.status ?? initialState.status;
				newState.companies = payload.companies ?? initialState.companies;
				newState.companiesTiny =
					payload.companiesTiny ?? initialState.companiesTiny;
				newState.stores = payload.stores ?? initialState.stores;
				newState.storesTiny = payload.storesTiny ?? initialState.storesTiny;
				newState.maxInvestment =
					payload.maxInvestment / 100.0 ?? initialState.maxInvestment;
				newState.value = payload.value / 100.0 ?? initialState.value;
				newState.maxValue = payload.maxValue / 100.0 ?? initialState.maxValue;
				newState.limit = payload.limit ?? initialState.limit;
				newState.limitPerUser =
					payload.limitPerUser ?? initialState.limitPerUser;
				newState.activeOn = payload.activeOn ?? initialState.activeOn;
				newState.creditMinValue =
					payload.creditMinValue / 100.0 ?? initialState.creditMinValue;
				newState.creditBusinessId =
					payload.creditBusinessId ?? initialState.creditBusinessId;
				newState.creditBusiness =
					payload.creditBusiness ?? initialState.creditBusiness;
				newState.info = payload.info ?? initialState.info;
				newState.startAt = payload.startAt ?? initialState.startAt;
				newState.endAt = payload.endAt ?? initialState.endAt;
				newState.creditStartAt =
					payload.creditStartAt ?? initialState.creditStartAt;
				newState.creditEndAt = payload.creditEndAt ?? initialState.creditEndAt;
			} else {
				newState.item = null;
				newState.minValue = initialState.minValue;
				newState.businessId = initialState.businessId;
				newState.status = initialState.status;
				newState.companies = initialState.companies;
				newState.companiesTiny = initialState.companiesTiny;
				newState.stores = initialState.stores;
				newState.storesTiny = initialState.storesTiny;
				newState.maxInvestment = initialState.maxInvestment;
				newState.value = initialState.value;
				newState.maxValue = initialState.maxValue;
				newState.limit = initialState.limit;
				newState.limitPerUser = initialState.limitPerUser;
				newState.activeOn = initialState.activeOn;
				newState.creditMinValue = initialState.creditMinValue;
				newState.creditBusinessId = initialState.creditBusinessId;
				newState.creditBusiness = initialState.creditBusiness;
				newState.info = initialState.info;
				newState.startAt = initialState.startAt;
				newState.endAt = initialState.endAt;
				newState.creditStartAt = initialState.creditStartAt;
				newState.creditEndAt = initialState.creditEndAt;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}

		case actions.GET_CASHBACK_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.GET_STORE_TINY: {
			return {
				...state,
				loading: { getStores: true },
				errors: initialState.errors
			};
		}

		case actions.GET_STORE_TINY_SUCCESS: {
			return {
				...state,
				storesTiny: payload || [],
				loading: { getStores: false }
			};
		}

		case actions.GET_STORE_TINY_FAILED: {
			return {
				...state,
				loading: { getStores: false },
				errors: payload
			};
		}

		case actions.GET_COMPANY_TINY: {
			return {
				...state,
				loading: { getCompanies: true },
				errors: initialState.errors
			};
		}

		case actions.GET_COMPANY_TINY_SUCCESS: {
			return {
				...state,
				companiesTiny: payload || [],
				loading: { getCompanies: false }
			};
		}

		case actions.GET_COMPANY_TINY_FAILED: {
			return {
				...state,
				loading: { getCompanies: false },
				errors: payload
			};
		}

		case actions.CREATE_CASHBACK: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}

		case actions.CREATE_CASHBACK_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				minValue: initialState.minValue,
				businessId: initialState.businessId,
				status: initialState.status,
				companies: initialState.companies,
				companiesTiny: initialState.companiesTiny,
				stores: initialState.stores,
				storesTiny: initialState.storesTiny,
				maxInvestment: initialState.maxInvestment,
				value: initialState.value,
				maxValue: initialState.maxValue,
				limit: initialState.limit,
				limitPerUser: initialState.limitPerUser,
				activeOn: initialState.activeOn,
				creditMinValue: initialState.creditMinValue,
				creditBusinessId: initialState.creditBusinessId,
				creditBusiness: initialState.creditBusiness,
				info: initialState.info,
				startAt: initialState.startAt,
				endAt: initialState.endAt,
				creditStartAt: initialState.creditStartAt,
				creditEndAt: initialState.creditEndAt
			};
		}

		case actions.CREATE_CASHBACK_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}

		case actions.UPDATE_CASHBACK: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_CASHBACK_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				minValue: initialState.minValue,
				businessId: initialState.businessId,
				status: initialState.status,
				companies: initialState.companies,
				companiesTiny: initialState.companiesTiny,
				stores: initialState.stores,
				storesTiny: initialState.storesTiny,
				maxInvestment: initialState.maxInvestment,
				value: initialState.value,
				maxValue: initialState.maxValue,
				limit: initialState.limit,
				limitPerUser: initialState.limitPerUser,
				activeOn: initialState.activeOn,
				creditMinValue: initialState.creditMinValue,
				creditBusinessId: initialState.creditBusinessId,
				creditBusiness: initialState.creditBusiness,
				info: initialState.info,
				startAt: initialState.startAt,
				endAt: initialState.endAt,
				creditStartAt: initialState.creditStartAt,
				creditEndAt: initialState.creditEndAt
			};
		}

		case actions.UPDATE_CASHBACK_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}

		case actions.UPDATE_CASHBACK_COMPANIES: {
			return {
				...state,
				loading: { updateCompanies: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_CASHBACK_COMPANIES_SUCCESS: {
			return {
				...state,
				loading: { updateCompanies: false }
			};
		}

		case actions.UPDATE_CASHBACK_COMPANIES_FAILED: {
			return {
				...state,
				loading: { updateCompanies: false },
				errors: payload
			};
		}

		case actions.UPDATE_CASHBACK_STORES: {
			return {
				...state,
				loading: { updateStores: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_CASHBACK_STORES_SUCCESS: {
			return {
				...state,
				loading: { updateStores: false }
			};
		}

		case actions.UPDATE_CASHBACK_STORES_FAILED: {
			return {
				...state,
				loading: { updateStores: false },
				errors: payload
			};
		}

		case actions.DELETE_CASHBACK: {
			return {
				...state,
				loading: { delete: true },
				errors: initialState.errors
			};
		}

		case actions.DELETE_CASHBACK_SUCCESS: {
			let { items } = state;

			items = items.filter(c => c.id !== payload);

			return {
				...state,
				items: [...items],
				loading: { delete: false }
			};
		}

		case actions.DELETE_CASHBACK_FAILED: {
			return {
				...state,
				loading: { delete: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				minValue: initialState.minValue,
				businessId: initialState.businessId,
				status: initialState.status,
				companies: initialState.companies,
				companiesTiny: initialState.companiesTiny,
				stores: initialState.stores,
				storesTiny: initialState.storesTiny,
				maxInvestment: initialState.maxInvestment,
				value: initialState.value,
				maxValue: initialState.maxValue,
				limit: initialState.limit,
				limitPerUser: initialState.limitPerUser,
				activeOn: initialState.activeOn,
				creditMinValue: initialState.creditMinValue,
				creditBusinessId: initialState.creditBusinessId,
				creditBusiness: initialState.creditBusiness,
				info: initialState.info,
				startAt: initialState.startAt,
				endAt: initialState.endAt,
				creditStartAt: initialState.creditStartAt,
				creditEndAt: initialState.creditEndAt
			};
		default: {
			return state;
		}
	}
};

export default reducer;
