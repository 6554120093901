import { Card, CardContent, Grid } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { MdBlock } from "react-icons/md";

import useStyles from "./styles";

// import { Container } from './styles';

const ErrorMobile: React.FC = () => {
	const classes = useStyles();
	return (
		<Grid
			className={clsx(classes.container, "card-box")}
			container
			justify="center"
			alignItems="center"
		>
			<Card className={classes.card}>
				<Grid container className={classes.logoContainer} justify="center">
					<Grid item className="panel-heading">
						<img
							src="/img/logo_wiipo_color.png"
							style={{ width: 250 }}
							alt="logo"
						/>
					</Grid>
				</Grid>
				<CardContent
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center"
					}}
				>
					<MdBlock size={160} color="red" />
					<strong>Não é possível acessar via celular</strong>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default ErrorMobile;
