import * as actions from "store/actions/header/notificationsUser";

import { INotificationUser } from "../types/notificationUser";

const initialState: INotificationUser = {
	items: [],
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.NOTIFICATION_USER: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.NOTIFICATION_USER_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload
			};
		}
		case actions.NOTIFICATION_USER_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
