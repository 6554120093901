import * as actions from "store/actions/campaign/createEdit";
import { Campaign } from "Types/Campaign";

export interface CampaignCreateEditState {
	item: Campaign;
	loading: { create: boolean; edit: boolean; upload: boolean };
	errors: any;
}

const initialState = {
	item: {
		name: "",
		startAt: new Date(),
		endAt: new Date(),
		campaignSkus: [],
		bannerUrl: "",
		banner: null,
		color: "#5734DA"
	},
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_CAMPAIGN: {
			return {
				...state,
				loading: {
					...state.loading,
					create: true
				},
				errors: initialState.errors
			};
		}
		case actions.CREATE_CAMPAIGN_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					create: false
				},
				item: payload
			};
		}
		case actions.CREATE_CAMPAIGN_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					create: false
				},
				errors: payload
			};
		}
		case actions.UPDATE_CAMPAIGN: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CAMPAIGN_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: false
				},
				item: payload
			};
		}
		case actions.UPDATE_CAMPAIGN_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: false
				},
				errors: payload
			};
		}
		case actions.UPLOAD_BANNER: {
			return {
				...state,
				loading: {
					...state.loading,
					upload: true
				},
				errors: initialState.errors
			};
		}
		case actions.UPLOAD_BANNER_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					upload: false
				},
				item: payload
			};
		}
		case actions.UPLOAD_BANNER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					upload: false
				},
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...{ ...initialState }
			};
		default: {
			return state;
		}
	}
};

export default reducer;
