import dayjs from "dayjs";
import { excelDateToJSDate } from "helpers/date";
import { validateEmail } from "helpers/email";
import { toStringCurrency } from "helpers/string";
import { toastError, toastWarning } from "helpers/toast";
import { validationCEP } from "helpers/validators";
import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";

const getValue = (c: string) => {
	let value = String(c);

	if (value.indexOf("R$") !== -1) {
		value = value.replace("R$", "").trim();
	}

	if (value) {
		while (value.split(/[.,]/).length > 2) {
			value = value.replace(".", "");
		}

		while (value.indexOf(",") !== -1) {
			let index = value.indexOf(",");

			if (index >= value.length - 3) {
				value = value.replace(",", ".");
			} else {
				value = value.replace(",", "");
			}
		}
	}

	if (Number.isNaN(+value)) {
		console.log("Value is NaN", value, c);

		throw new Error(`Value ${c} is NaN`);
	}

	return Number(value);
};

export function extractUsersFromJson(json: any[]) {
	const users = [];

	for (const user of json) {
		let cpf = String(user["cpf"])?.match(/\d/g)?.join("");

		if (!cpf) {
			toastWarning(
				`CPF da linha ${json.indexOf(user) + 2} não preenchido, é obrigatório`
			);

			return;
		}

		cpf = cpf.padStart(11, "0");

		if (!user["pais"]) {
			toastWarning(
				`País da linha  ${
					json.indexOf(user) + 2
				}  não preenchido, obrigatório e deve conter 2 caracteres`
			);

			return;
		}
		if (user["pais"]?.length > 2 || user["pais"]?.length < 2) {
			toastWarning(
				`País da linha ${
					json.indexOf(user) + 2
				} invalido deve conter 2 caracteres`
			);

			return;
		}

		if (!user["estado"]) {
			toastWarning(
				`País da linha não preenchido ${
					json.indexOf(user) + 2
				}  não preenchido, obrigatório e deve conter 2 caracteres`
			);

			return;
		}
		if (user["estado"]?.length > 2 || user["estado"]?.length < 2) {
			toastWarning(
				`Estado da linha ${
					json.indexOf(user) + 2
				} invalido deve conter 2 caracteres`
			);

			return;
		}

		const cep = String(user["cep"])?.replace(/\D/g, "");

		if (!cep) {
			toastWarning(
				`CEP da linha ${json.indexOf(user) + 2} não preenchido, é obrigatório`
			);

			return;
		}

		if (cep.length < 8) {
			toastWarning(
				`CEP da linha ${json.indexOf(user) + 2} invalido, deve conter 8 digitos`
			);

			return;
		}

		if (!validationCEP(user["cep"])) {
			toastWarning(`CEP da linha ${json.indexOf(user) + 2} formatado errado.`);

			return;
		}

		const fullName: string = user["nome completo"];

		let name = "";
		let lastName = "";

		if (fullName) {
			const names = fullName?.split(" ");

			name = names?.shift();

			lastName = names?.join(" ");
		}

		if (!name) name = user["nome"];

		if (!name) {
			toastWarning(
				`Nome do CPF ${cpf}, linha ${
					json.indexOf(user) + 2
				} não preenchido, é obrigatório`
			);

			return;
		}

		if (!lastName) lastName = user["sobrenome completo"];

		if (!lastName) {
			toastWarning(
				`Sobrenome de ${name}(${cpf}), linha ${
					json.indexOf(user) + 2
				} não preenchido, é obrigatório`
			);

			return;
		}

		const email = user["email"];

		if (email && !validateEmail(email)) {
			toastWarning(
				`E-mail de ${name} ${lastName}, linha ${
					json.indexOf(user) + 2
				} é inválido`
			);

			return;
		}

		let phoneNumber = String(user["telefone"]).match(/\d/g)?.join("");

		if (!email && !phoneNumber) {
			toastWarning(
				`E-mail e/ou telefone de ${name} ${lastName}, linha ${
					json.indexOf(user) + 2
				} não preenchidos, informe uma ou as duas opções`
			);

			return;
		}

		if (phoneNumber) {
			while (phoneNumber[0] === "0") {
				phoneNumber = phoneNumber.substr(1);
			}

			let phoneNumberParsed = null;

			try {
				if (phoneNumber.length === 8) phoneNumber = `55479${phoneNumber}`;
				else if (phoneNumber.length === 9) phoneNumber = `5547${phoneNumber}`;
				else if (phoneNumber.length === 10)
					phoneNumber = `55${phoneNumber.substr(0, 2)}9${phoneNumber.substr(
						2
					)}`;
				else if (phoneNumber.length === 11) phoneNumber = `55${phoneNumber}`;
				else if (phoneNumber.length === 12) {
					phoneNumber = `${phoneNumber.substr(0, 4)}9${phoneNumber.substr(4)}`;
				}

				phoneNumberParsed = parsePhoneNumber(`+${phoneNumber}`);
			} catch (error) {
				if (error?.message === "INVALID_COUNTRY") {
					if (phoneNumber.length === 8) phoneNumber = `55479${phoneNumber}`;
					else if (phoneNumber.length === 9) phoneNumber = `5547${phoneNumber}`;
					else if (phoneNumber.length === 10)
						phoneNumber = `55${phoneNumber.substr(0, 2)}9${phoneNumber.substr(
							2
						)}`;
					else if (phoneNumber.length === 11) phoneNumber = `55${phoneNumber}`;
					else {
						console.log("INVALID_COUNTRY", phoneNumber);

						toastWarning(
							`Telefone de ${name} ${lastName}, linha ${
								json.indexOf(user) + 2
							} é inválido`
						);

						return;
					}
				} else if (error?.message === "NOT_A_NUMBER") {
					console.log("NOT_A_NUMBER", phoneNumber);

					toastWarning(
						`Telefone de ${name} ${lastName}, linha ${
							json.indexOf(user) + 2
						} é inválido`
					);

					return;
				} else if (error?.message === "TOO_LONG") {
					if (phoneNumber.indexOf("55") === 0) {
						phoneNumber = phoneNumber.substr(0, 13);
					} else {
						console.log("TOO_LONG", phoneNumber);

						toastWarning(
							`Telefone de ${name} ${lastName}, linha ${
								json.indexOf(user) + 2
							} é inválido`
						);

						return;
					}
				} else {
					console.log(JSON.stringify(error));

					console.log(phoneNumber);

					toastWarning(
						`Telefone de ${name} ${lastName}, linha ${
							json.indexOf(user) + 2
						} é inválido`
					);

					return;
				}

				try {
					phoneNumber = phoneNumber.substr(0, 13);
					phoneNumberParsed = parsePhoneNumber(`+${phoneNumber}`);
				} catch (error) {
					console.log("2", error?.message, phoneNumber);

					toastWarning(
						`Telefone de ${name} ${lastName}, linha ${
							json.indexOf(user) + 2
						} é inválido`
					);

					return;
				}
			}

			const internationalPhoneNumber = phoneNumberParsed.formatInternational();

			if (isValidNumber(internationalPhoneNumber)) {
				phoneNumber = internationalPhoneNumber;
			} else {
				console.log(internationalPhoneNumber);

				toastWarning(
					`Telefone de ${name} ${lastName}, linha ${
						json.indexOf(user) + 2
					} é inválido`
				);

				return;
			}
		}

		const limit = getValue(user["limite"] || "");

		let admissionDate = user["data de admissao"];

		if (admissionDate) {
			// console.log("Data de admissão", cpf, admissionDate);

			if (typeof admissionDate === "number") {
				admissionDate = dayjs(excelDateToJSDate(admissionDate));
			} else {
				console.log(admissionDate);

				toastWarning(
					`Formato da data de admissão de ${name} ${lastName}, linha ${
						json.indexOf(user) + 2
					} é inválida. \n\nDica: \n1. Formate a célula para o tipo data, selecione o formato correto que ta usando, Ex.: DD/MM/AAAA. \n2. Recorte a coluna inteira para o bloco de notas. \n3. Copie o conteúdo do bloco de notas e cole na coluna novamente. \n\nObs.: Se funcionar, a data ficará alinhada à direita.`
				);

				return;
			}

			admissionDate.utcOffset(-3 * 60);

			admissionDate = admissionDate?.startOf("d").format("DD/MM/YYYY");
		}

		const costCenterCode = user["centro de custo"];

		let birthDate = user["data de nascimento"];

		if (birthDate) {
			if (typeof birthDate === "number") {
				birthDate = dayjs(excelDateToJSDate(birthDate));
			} else {
				console.log(admissionDate);

				toastWarning(
					`Formato da data de admissão de ${name} ${lastName}, linha ${
						json.indexOf(user) + 2
					} é inválida. \n\nDica: \n1. Formate a célula para o tipo data, selecione o formato correto que ta usando, Ex.: DD/MM/AAAA. \n2. Recorte a coluna inteira para o bloco de notas. \n3. Copie o conteúdo do bloco de notas e cole na coluna novamente. \n\nObs.: Se funcionar, a data ficará alinhada à direita.`
				);

				return;
			}

			birthDate.utcOffset(-3 * 60);

			if ((birthDate as dayjs.Dayjs).isAfter(dayjs())) {
				console.log(birthDate.format());

				toastWarning(
					`Data de nascimento de ${name} ${lastName}, linha ${
						json.indexOf(user) + 2
					} é inválida`
				);

				return;
			}

			birthDate = birthDate.startOf("d").format("DD/MM/YYYY");
		}

		users.push({
			cpf,
			enroll: user["matricula"],
			name,
			lastName,
			email,
			limit: limit ? toStringCurrency(Number(limit)) : null,
			admissionDate: admissionDate || undefined,
			phoneNumber,
			costCenterCode,
			birthDate,
			mothersName: user["nome da mae"],
			street: user["endereco"],
			number: user["numero"],
			complement: user["complemento"],
			neighborhood: user["bairro"],
			city: user["cidade"],
			state: user["estado"],
			country: user["pais"],
			zipCode: user["cep"]
		});
	}

	return users;
}
