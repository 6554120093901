import * as actions from "store/actions/checkout";

import { ICheckout } from "./types";

const initialState: ICheckout = {
	items: [],
	pages: null,
	count: 0,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_CHECKOUTS: {
			return {
				...state,
				loading: { ...state.loading, search: true },
				errors: initialState.errors
			};
		}

		case actions.GET_CHECKOUTS_SUCCESS: {
			return {
				...state,
				loading: { ...state.loading, search: false },
				items: payload
			};
		}

		case actions.GET_CHECKOUTS_FAILED: {
			return {
				...state,
				loading: { ...state.loading, search: false },
				errors: payload
			};
		}

		case actions.CONFIRM_CHECKOUT: {
			return {
				...state,
				loading: { ...state.loading, confirm: true },
				errors: initialState.errors
			};
		}

		case actions.CONFIRM_CHECKOUT_SUCCESS: {
			let item = state.items.find(c => c.id === payload.id);

			if (item) {
				item.status = payload.status;
			}

			return {
				...state,
				loading: { ...state.loading, confirm: false },
				items: [...state.items]
			};
		}

		case actions.CONFIRM_CHECKOUT_FAILED: {
			return {
				...state,
				loading: { ...state.loading, confirm: false },
				errors: payload
			};
		}

		case actions.CONFIRM_PAID_CHECKOUT: {
			return {
				...state,
				loading: { ...state.loading, confirm: true },
				errors: initialState.errors
			};
		}

		case actions.CONFIRM_PAID_CHECKOUT_SUCCESS: {
			let item = state.items.find(c => c.id === payload.id);

			if (item) {
				item.status = payload.status;
			}

			return {
				...state,
				loading: { ...state.loading, confirm: false },
				items: [...state.items]
			};
		}

		case actions.CONFIRM_PAID_CHECKOUT_FAILED: {
			return {
				...state,
				loading: { ...state.loading, confirm: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			return {
				...initialState
			};

		default: {
			return state;
		}
	}
};

export default reducer;
