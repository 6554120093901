import { Button } from "@material-ui/core";
import { PDFExport } from "@progress/kendo-react-pdf";
import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import { copyTextToClipboard } from "helpers/string";
import ls from "Localization";
import React, { useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import QRCode from "react-qr-code";

import {
	BackgroundButton,
	ColumnContainer,
	Container,
	Text,
	TextCode,
	Title
} from "./styles";
import TemplatePDF from "./TemplatePDF";

interface IPropsModal {
	open: boolean;
	handleClose: () => void;
	pixLink: string;
	loadingPix: boolean;
}

const ModalPix: React.FC<IPropsModal> = ({
	open,
	handleClose,
	pixLink,
	loadingPix
}) => {
	const pdfExportComponent = useRef(null);

	const handleDownloadPDF = () => {
		if (pdfExportComponent.current) {
			pdfExportComponent.current.save();
		}
	};

	return (
		<ModalGeneric
			open={open}
			handleClose={handleClose}
			stylePaper={{
				width: "715px",
				maxHeight: "671px"
			}}
			body={() => (
				<Container>
					<AiOutlineClose
						color="#5734da"
						size={18}
						onClick={handleClose}
						style={{
							display: "flex",
							alignSelf: "flex-end",
							cursor: "pointer",
							margin: 10
						}}
					/>
					<Title>{ls.paymentPix}</Title>
					<ColumnContainer>
						<p
							style={{
								fontWeight: "normal",
								textAlign: "center",
								font: "normal normal 600 16px Exo",
								marginBottom: "12px",
								color: "#707070"
							}}
						>
							Compensação em tempo real
						</p>
						<Text>
							Efetue o PIX lendo o QR Code abaixo utilizando o aplicativo
							bancário do seu celular. Se não conseguir ler o código QR, copie o
							texto abaixo e cole no seu aplicativo.
						</Text>
					</ColumnContainer>

					{loadingPix ? (
						<Spinner
							color="primary"
							style={{ alignSelf: "center" }}
							size="2rem"
						/>
					) : (
						<>
							<div
								style={{
									display: "flex",
									flex: 1,
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "white"
								}}
							>
								<QRCode
									size={256}
									style={{
										height: 170,
										width: 170,
										border: "3px solid #5734D9",
										borderRadius: 5,
										padding: 10
									}}
									value={pixLink || ""}
									viewBox="0 0 256 256"
								/>
								<section
									style={{
										wordWrap: "break-word",
										width: "80%"
									}}
								>
									<TextCode>{pixLink}</TextCode>
								</section>
							</div>
							<BackgroundButton>
								<Button
									color="primary"
									size="medium"
									variant="contained"
									type="submit"
									onClick={() => {
										copyTextToClipboard(() => {}, pixLink, "PIX Copiado");
									}}
									style={{
										fontSize: 14,
										borderRadius: 8,
										fontWeight: 600,
										width: 94,
										alignSelf: "center",
										height: 43
									}}
								>
									Copiar
								</Button>

								<div>
									<button
										onClick={handleDownloadPDF}
										style={{
											fontSize: 14,
											borderRadius: 8,
											fontWeight: 600,
											width: 124,
											alignSelf: "center",
											height: 43,
											backgroundColor: "#5734D9",
											textTransform: "uppercase",
											marginLeft: 10,
											color: "#fff",
											border: "none",
											cursor: "pointer",
											marginBottom: 1
										}}
										type="button"
									>
										Baixar PDF
									</button>
									<div style={{ position: "absolute", left: -9999 }}>
										<PDFExport ref={pdfExportComponent} paperSize="A4">
											<TemplatePDF pixLink={pixLink} />
										</PDFExport>
									</div>
								</div>
							</BackgroundButton>
						</>
					)}
				</Container>
			)}
		/>
	);
};

export default ModalPix;
