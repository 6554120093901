import { getErrors } from "helpers/error";
import * as api from "services/api";

import { ActionTypes } from "../types";

export function getQrCode(fundsId, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: ActionTypes.GET_FUNDS_IN_QRCODEPIX_REQUEST });
		try {
			const { login } = getState();
			let response = await api.sendGet(`/funds/${fundsId}/generate/qrcode`, {
				Authorization: `Bearer ${login.token}`
			});
			let result = await response.json();
			if (result.isValid) {
				dispatch({
					type: ActionTypes.GET_FUNDS_IN_QRCODEPIX_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);
				dispatch({
					type: ActionTypes.GET_FUNDS_IN_QRCODEPIX_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: ActionTypes.GET_FUNDS_IN_QRCODEPIX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
