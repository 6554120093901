import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Board from "react-trello";
import accessionStatus from "models/accessionStatus";
import { Paper, Typography, Button, Grid } from "@material-ui/core";
import ls from "Localization";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/accession";
import Spinner from "Components/Spinner";

import styles from "./styles";
import { useParams } from "react-router";
import CreateForm from "./Forms/Create";
import EditForm from "./Forms/Edit";

function Accession({ classes }) {
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const { id } = useParams<any>();

	useEffect(() => {
		dispatch(actions.clearValues(true));

		setData({
			lanes: Object.keys(accessionStatus).map(id => ({
				id: id.toString(),
				title: ls[accessionStatus[id]],
				label: "0",
				cards: []
			}))
		});

		dispatch(actions.getAccessions(0, 1000, `&filters[storeId]=${id}`));
	}, [dispatch, id]);

	const { items, loading } = useSelector<any, any>(s => s.accession);

	let total = Object.keys(items)
		.map(c => items[c])
		.reduce((a, b) => a + b.length, 0);

	useEffect(() => {
		let newData = {
			lanes: Object.keys(accessionStatus).map(id => ({
				id: id.toString(),
				title: ls[accessionStatus[id]],
				label: "0",
				cards: []
			}))
		};

		for (const lane of newData.lanes) {
			lane.cards = (items[lane.id] || [])
				.filter(c => c.storeId === id)
				.map(c => ({
					id: c.id,
					title: c.user?.fullName?.substr(0, 20),
					description: c.email,
					laneId: lane.id,
					label: c.user?.business?.business?.name.substr(0, 10) ?? ""
				}));

			lane.label = lane.cards.length.toString();
		}

		setData(newData);
	}, [id, items, total]);

	const handleDragEnd = (
		cardId: string,
		srcLaneId: string,
		destLaneId: string
	) => {
		if (srcLaneId === destLaneId) return;

		dispatch(
			actions.editAccessionStatus(cardId, destLaneId, (err, model) => {
				if (err) console.log(err);
				else {
					let item = items[srcLaneId].find(c => c.id === cardId);
					items[srcLaneId] = items[srcLaneId].filter(c => c.id !== cardId);
					items[destLaneId].push(item);

					dispatch(actions.setValue({ items: { ...items } }));
				}
			})
		);
	};

	const handleChange = (id: string, value: any) => {
		dispatch(actions.setValue({ [id]: value }));
	};

	const handleEdit = (cardId, metadata, laneId) => {
		let item = items[laneId].find(c => c.id === cardId);

		dispatch(
			actions.setValue({
				showEditFormModal: Boolean(item),
				item,
				email: item?.email || "",
				status: item?.status || "0"
			})
		);
	};

	if (!data || loading.getAll) return null;

	return (
		<div>
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6">
						{ls.accessions}{" "}
						{loading.getAll && <Spinner color="secondary" size={16} />}
					</Typography>

					<div>
						<Button
							color="primary"
							onClick={() => handleChange("showFormModal", true)}
						>
							{ls.add}
						</Button>
					</div>
				</div>
				<hr className={classes.line} />

				<Grid container spacing={2} className={classes.form}>
					<Board
						data={data}
						hideCardDeleteIcon
						handleDragEnd={handleDragEnd}
						laneStyle={{ height: 540 }}
						style={{ backgroundColor: "white", height: 600 }}
						onCardClick={handleEdit}
					/>
				</Grid>
			</Paper>
			<CreateForm />
			<EditForm />
		</div>
	);
}

export default withStyles(styles, { withTheme: true })(Accession);
