import * as actions from "store/actions/user/report";

import { IReportUser } from "../types/report";

const initialState: IReportUser = {
	items: [],
	tiny: [],
	enabled: [],
	loading: false,
	count: 0,
	pages: 0,
	push: 0,
	sms: 0,
	whatsApp: 0,
	email: 0,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_USERS: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.GET_USERS_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload
			};
		}
		case actions.GET_USERS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.GET_USERS_TINY: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.GET_USERS_TINY_SUCCESS: {
			return {
				...state,
				loading: false,
				tiny: payload
			};
		}
		case actions.GET_USERS_TINY_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.GET_ENABLED_USERS: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.GET_ENABLED_USERS_SUCCESS: {
			return {
				...state,
				loading: false,
				enabled: payload
			};
		}
		case actions.GET_ENABLED_USERS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
