import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

export const GET_BUSINESS_CATEGORY =
	"[BUSINESS CATEGORY EDIT] GET_BUSINESS_CATEGORY";
export const GET_BUSINESS_CATEGORY_SUCCESS =
	"[BUSINESS CATEGORY EDIT] GET_BUSINESS_CATEGORY_SUCCESS";
export const GET_BUSINESS_CATEGORY_FAILED =
	"[BUSINESS CATEGORY EDIT] GET_BUSINESS_CATEGORY_FAILED";

export const EDIT_BUSINESS_CATEGORY =
	"[BUSINESS CATEGORY EDIT] EDIT_BUSINESS_CATEGORY";
export const EDIT_BUSINESS_CATEGORY_SUCCESS =
	"[BUSINESS CATEGORY EDIT] EDIT_BUSINESS_CATEGORY_SUCCESS";
export const EDIT_BUSINESS_CATEGORY_FAILED =
	"[BUSINESS CATEGORY EDIT] EDIT_BUSINESS_CATEGORY_FAILED";

export const SET_VALUE = "[BUSINESS CATEGORY EDIT] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS CATEGORY EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getBusinessCategory(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS_CATEGORY });

		try {
			const { login } = getState();
			let response = await api.sendGet("/Business/Category/" + id, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_CATEGORY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_CATEGORY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editBusinessCategory(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_BUSINESS_CATEGORY });

		try {
			const { login, businessCategoryEdit } = getState();

			let { item, name, icon, iconSource, isVisible, color } =
				businessCategoryEdit;

			const errors: any = {};

			if (!name) errors.name = ls.required;

			dispatch(setValue({ errors }));

			if (_.keys(errors).length > 0) return;

			let response = await api.sendPut(
				"/Business/Category/" + item.id,
				{
					...item,
					name,
					color,
					icon,
					iconSource,
					isVisible
				},
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_BUSINESS_CATEGORY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_BUSINESS_CATEGORY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_BUSINESS_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
