import ModalSelector from "Components/ModalSelector";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editCashbackCompanies,
	getCompaniesTiny,
	setValue
} from "store/actions/cashbackPolicy";
import { CashbackPolicyState } from "store/reducers/cashbackPolicy";

let debounceCompaniesForm: (() => void) & _.Cancelable = null;

function CompaniesForm() {
	const dispatch = useDispatch();

	const { showCompaniesFormModal, companiesTiny, companies, loading } =
		useSelector<any, CashbackPolicyState>(c => c.cashbackPolicy);

	useEffect(() => {
		if (showCompaniesFormModal)
			dispatch(
				getCompaniesTiny(err => {
					if (err) console.log(err);
				})
			);
	}, [dispatch, showCompaniesFormModal]);

	const handleClose = () =>
		dispatch(setValue({ showCompaniesFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ businessId: u.id, status: u.status }));

		if (debounceCompaniesForm) debounceCompaniesForm.cancel();

		debounceCompaniesForm = _.debounce(() => {
			dispatch(
				editCashbackCompanies(data, err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						handleClose();
						dispatch(clearValues());
					}
				})
			);
		}, 1000);

		debounceCompaniesForm();
	};

	if (!showCompaniesFormModal) return null;

	return (
		<ModalSelector
			open={showCompaniesFormModal}
			id="edit-cashback-business-policy"
			loading={loading}
			data={companiesTiny.map(c => ({
				id: c.id,
				name: c.name,
				status: companies.find(d => d.businessId === c.id && d.status) ? 1 : 0
			}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="Empresas"
		/>
	);
}

export default CompaniesForm;
