import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const SEND_USER_SWAP = "[USER REPORT] SEND_USER_SWAP";
export const SEND_USER_SWAP_SUCCESS = "[USER REPORT] SEND_USER_SWAP_SUCCESS";
export const SEND_USER_SWAP_FAILED = "[USER REPORT] SEND_USER_SWAP_FAILED";

export const SET_VALUE = "[USER REPORT] SET_VALUE";
export const CLEAR_VALUES = "[USER REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function sendUserToSwap(id: string, callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: SEND_USER_SWAP });

		try {
			const { login } = getState();

			let response = await api.sendPost(`/User/${id}/AccountHolder`, null, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SEND_USER_SWAP_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: SEND_USER_SWAP_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: SEND_USER_SWAP_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
