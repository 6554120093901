import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AutoComplete2 from "Components/AutoComplete2";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import chargeType from "models/chargeType";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	createCharge,
	setValue
} from "store/actions/charge/create";
import { getUsers } from "store/actions/user/report";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	},
	margin: {},
	textField: {}
}));

let debounceFindClient: (() => void) & _.Cancelable = null;
let debounceCreateCharge: (() => void) & _.Cancelable = null;

function AddCharge({ open, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch, open]);

	const { description, type, value, errors, loading } = useSelector<any, any>(
		s => s.chargeCreate
	);

	const { loading: userLoading } = useSelector<any, any>(s => s.userReport);

	const handleChange = useCallback(
		(name, value) => {
			dispatch(
				setValue({
					[name]: value
				})
			);
		},
		[dispatch]
	);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();

			if (debounceCreateCharge) debounceCreateCharge.cancel();

			debounceCreateCharge = _.debounce(() => {
				dispatch(
					createCharge((err, model) => {
						if (err) {
							console.log(err);
							if (err.default) alert(err.default);
							else if (typeof err === "string") alert(err);
							else alert(JSON.stringify(err));
						} else {
							handleClose(model);
						}
					})
				);
			}, 1000);

			debounceCreateCharge();
		},
		[dispatch, handleClose]
	);

	const findClient = useCallback(
		(name, callback) => {
			if (name.length >= 3) {
				if (debounceFindClient) debounceFindClient.cancel();

				debounceFindClient = _.debounce(() => {
					dispatch(
						getUsers(
							0,
							10,
							`&filters[fullName]=${name}`,
							undefined,
							false,
							false,
							(errors, models) => {
								if (models) {
									callback(
										models.map(c => ({
											id: c.id,
											label: `${c.fullName}`
										}))
									);
								} else {
									callback([]);
								}
							}
						)
					);
				}, 1000);

				debounceFindClient();
			}
		},
		[dispatch]
	);

	if (!open) return null;

	return (
		<Modal
			aria-labelledby="add-transaction-modal-title"
			aria-describedby="add-transaction-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.addCharge}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} lg={4}>
									<AutoComplete2
										id="clientId"
										name="client"
										errors={errors}
										onChange={handleChange}
										loadOptions={findClient}
										loading={userLoading}
										filterOptions={options => options}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="value"
										required
										variant="outlined"
										name="value"
										InputProps={{
											inputComponent: MONEY
										}}
										value={value}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<Select
										errors={errors}
										id="type"
										name="type"
										value={type}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											...Object.keys(chargeType).map(c => ({
												id: c,
												value: ls[chargeType[c]]
											}))
										]}
									/>
								</Grid>

								<Grid item xs={12}>
									<TextInput
										id="description"
										name="description"
										value={description}
										errors={errors}
										multiline
										rows={3}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default AddCharge;
