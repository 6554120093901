import * as api from "services/api";
import { getErrors } from "helpers/error";
import { Callback } from "Types/Callback";

export const GET_STATES = "[WORLD] GET_STATES";
export const GET_STATES_SUCCESS = "[WORLD] GET_STATES_SUCCESS";
export const GET_STATES_FAILED = "[WORLD] GET_STATES_FAILED";

export const GET_CITIES = "[WORLD] GET_CITIES";
export const GET_CITIES_SUCCESS = "[WORLD] GET_CITIES_SUCCESS";
export const GET_CITIES_FAILED = "[WORLD] GET_CITIES_FAILED";

export const GET_NEIGHBORHOODS = "[WORLD] GET_NEIGHBORHOODS";
export const GET_NEIGHBORHOODS_SUCCESS = "[WORLD] GET_NEIGHBORHOODS_SUCCESS";
export const GET_NEIGHBORHOODS_FAILED = "[WORLD] GET_NEIGHBORHOODS_FAILED";

export const SET_VALUE = "[WORLD] SET_VALUE";
export const CLEAR_VALUES = "[WORLD] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getStates(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_STATES });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/World/State`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_STATES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_STATES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_STATES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getCities(state, name, callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CITIES });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/World/City?filters[state]=${state}&filters[name]=${name || ""}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CITIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CITIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CITIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getNeighborhoods(city, name, callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_NEIGHBORHOODS });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/World/Neighborhood?filters[city]=${city}&filters[name]=${name || ""}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_NEIGHBORHOODS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_NEIGHBORHOODS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_NEIGHBORHOODS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
