import React from "react";

import { ButtonNo, ButtonYes, Container, Label, RowLabel } from "./styles";

interface iPropsSwitch {
	active: boolean;
	disabled?: boolean;
	text?: string;
	changeActive: (id?, value?) => void;
	containerTestId?: string;
}

const SwitchGeneric: React.FC<iPropsSwitch> = ({
	active,
	disabled,
	changeActive,
	text,
	containerTestId
}) => (
	<RowLabel>
		<Container active={active} data-testid={containerTestId}>
			<ButtonNo
				type="button"
				active={active}
				onClick={changeActive}
				disabled={disabled}
			/>

			<ButtonYes
				type="button"
				active={active}
				onClick={changeActive}
				disabled={disabled}
			/>
		</Container>
		<Label>{text}</Label>
	</RowLabel>
);

export default SwitchGeneric;
