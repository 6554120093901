import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editProductCategory,
	setValue
} from "store/actions/productCategory";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 400,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceEditForm: (() => void) & _.Cancelable = null;

function EditForm({ load }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { showEditFormModal, name, errors, loading } = useSelector<any, any>(
		c => c.productCategory
	);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showEditFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditForm) debounceEditForm.cancel();

		debounceEditForm = _.debounce(() => {
			dispatch(
				editProductCategory((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						load();
						handleClose();
						dispatch(clearValues());
					}
				})
			);
		}, 1000);

		debounceEditForm();
	};

	return (
		<Modal
			aria-labelledby="edit-product-category-modal-title"
			aria-describedby="edit-product-category-modal-description"
			className={classes.modal}
			open={showEditFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showEditFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editProductCategory}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextInput
										id="name"
										required
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.update}
								>
									{ls.save}
									{loading.update && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditForm;
