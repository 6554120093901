import * as actions from "store/actions/business/createBatch";

const initialState = {
	items: [],
	codCli: "",
	pageIdentifier: "",
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.BATCH_BUSINESS: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.BATCH_BUSINESS_SUCCESS: {
			return {
				...state,
				items: payload,
				loading: false
			};
		}
		case actions.BATCH_BUSINESS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
