import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import {
	Paper,
	Typography,
	Button,
	Grid,
	Table,
	TableHead,
	TableRow,
	TableBody,
	IconButton
} from "@material-ui/core";
import ls from "Localization";

import TableCell from "Components/TableCell";
import Spinner from "Components/Spinner";

import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { MdEdit } from "react-icons/md";

import CreateForm from "./Forms/Create";
import EditForm from "./Forms/Edit";

import {
	getConvenienceFees,
	clearValues,
	setValue,
	getConvenienceFee
} from "store/actions/convenienceFee";
import { toStringCurrency } from "helpers/string";

function ConvenienceFees({ classes }) {
	const dispatch = useDispatch();

	const [loadingSub, setLoadingSub] = useState({});

	const { item: business } = useSelector<any, any>(c => c.businessDetail);
	const { items, loading } = useSelector<any, any>(c => c.convenienceFee);

	useEffect(() => {
		dispatch(clearValues(true));
		if (business) {
			dispatch(getConvenienceFees(business.id));
		}
	}, [business, dispatch]);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleEdit = (id, include, item) => {
		setLoadingSub({ [id]: item.id });
		dispatch(
			getConvenienceFee(item.id, include, (err, model) => {
				if (err) {
					console.log(err);
				} else {
					handleChange(id, Boolean(model));
				}
				setLoadingSub({ [id]: false });
			})
		);
	};

	return (
		<div>
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6">
						{ls.convenienceFees}{" "}
						{loading.getAll && <Spinner color="secondary" size={16} />}
					</Typography>
					<div>
						<Button
							color="primary"
							onClick={() => handleChange("showFormModal", true)}
						>
							{ls.add}
						</Button>
					</div>
				</div>
				<hr className={classes.line} />

				<Grid container spacing={2} className={classes.form}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>{ls.minValue}</TableCell>
								<TableCell>{ls.maxValue}</TableCell>
								<TableCell>{ls.fee}</TableCell>
								<TableCell>{ls.actions}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(items || []).map(row => (
								<TableRow key={row.id}>
									<TableCell>
										{toStringCurrency(row.minValue / 100.0)}
									</TableCell>
									<TableCell>
										{toStringCurrency(row.maxValue / 100.0)}
									</TableCell>
									<TableCell>{toStringCurrency(row.fee / 100.0)}</TableCell>
									<TableCell style={{ padding: 0 }}>
										{loadingSub["showEditFormModal"] === row.id ? (
											<Spinner color="secondary" size={16} />
										) : (
											<IconButton
												style={{
													padding: 8,
													fontSize: "1.3rem"
												}}
												onClick={() =>
													handleEdit("showEditFormModal", undefined, row)
												}
											>
												<MdEdit />
											</IconButton>
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			</Paper>
			<CreateForm />
			<EditForm />
		</div>
	);
}

export default withStyles(styles, { withTheme: true })(ConvenienceFees);
