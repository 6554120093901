import { toStringCurrency } from "helpers/string";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { ITreasuryAccountWiipoFlexState } from "store/actions/treasuryAccountWiipoFlex/types";
import { State } from "store/reducers";

import { Container, Header, Label, Row } from "./styles";

const ExtractPMATemplate: React.FC = () => {
	const { items } = useSelector<State, any>(s => s.reportComponent);
	const { totalBalance } = useSelector<State, ITreasuryAccountWiipoFlexState>(
		s => s.treasuryAccountWiipoFlexDeposit
	);

	let dates = [];

	const filterItems = items[0]?.data?.map(i => {
		const [datePart, timePart] = i[2].split(" - ");
		const [day, month, year] = datePart.split("/");
		const [hours, minutes] = timePart.split(":");

		const date = new Date(`${year}-${month}-${day}T${hours}:${minutes}`);

		if (!dates.includes(date.toLocaleDateString())) {
			dates.push(date.toLocaleDateString());
		}

		return {
			date: date.toLocaleDateString(),
			time: date.toLocaleTimeString(),
			operation: i[15],
			origin: i[3],
			destiny: i[7],
			user: i[14],
			description: i[17],
			value: i[11],
			type: i[10],
			valueBefore: i[13]
		};
	});

	const datesVariables = useMemo(() => {
		const orderDates = filterItems?.sort((a, b) => a.date - b.date);

		const firstDate =
			orderDates && orderDates.length > 0
				? orderDates[0]?.date.replace(" ", "")
				: null;
		const lastDate =
			orderDates && orderDates.length > 0
				? orderDates.reverse()[0]?.date.replace(" ", "")
				: null;

		return {
			firstDate,
			lastDate
		};
	}, [filterItems]);

	const tableDate = useCallback(() => {
		const datesOrder = dates.sort((a, b) => a - b);

		if (dates.length === 0) {
			return;
		}

		return datesOrder?.map(i => {
			const filterItemsDate = filterItems.filter(item => item.date === i);

			let valuebefore =
				filterItems[filterItemsDate.length - 1]?.valueBefore || 0;

			return (
				<>
					<Row
						style={{
							paddingLeft: 20,
							justifyContent: "space-between"
						}}
					>
						<Label style={{ color: "#000", fontWeight: "bold" }}>{i}</Label>
						<Label
							style={{ color: "#000", fontWeight: "bold", marginRight: 20 }}
						>
							{toStringCurrency(valuebefore / 100)}
						</Label>
					</Row>

					{filterItemsDate.map(i => (
						<Row style={{ paddingLeft: 20 }}>
							<Label style={{ width: 50 }}>{i.time}</Label>
							<Label style={{ width: 150 }}>{i.operation}</Label>
							<Label style={{ width: 150 }}>{i.origin}</Label>
							<Label style={{ width: 150 }}>{i.destiny}</Label>
							<Label style={{ width: 100 }}>{i.user}</Label>
							<Label style={{ width: 100 }}>{i.description}</Label>
							<Label style={{ width: 50 }}>{i.type}</Label>
							<Label
								style={{
									width: 100,
									color: i.type === "Débito" ? "red" : "green"
								}}
							>
								{i.value}
							</Label>
						</Row>
					))}
				</>
			);
		});
	}, [dates, filterItems]);

	return (
		<Container>
			<img src="/img/logo_wiipo_color.png" style={{ width: 250 }} alt="logo" />
			<p style={{ color: "#000" }}>
				Senior Instituição de Pagamentos LTDA CNPJ: 28.359.802/0001-01
			</p>
			<Header>
				<p>Saldo Atual: {toStringCurrency(totalBalance / 100)} </p>
				{datesVariables.firstDate && datesVariables.lastDate ? (
					<p>{` ${datesVariables.lastDate} - ${datesVariables.firstDate}`}</p>
				) : (
					<></>
				)}
				<p>{new Date().toLocaleTimeString()}</p>
			</Header>
			<Row
				style={{
					background: "#5734d9",
					width: "100%",
					justifyContent: "space-between",
					padding: 10,
					paddingLeft: 26
				}}
			>
				<Label style={{ width: 50, color: "#fff" }}>Hora</Label>
				<Label style={{ width: 150, color: "#fff" }}>Operação</Label>
				<Label style={{ width: 150, color: "#fff" }}>Origem</Label>
				<Label style={{ width: 150, color: "#fff" }}>Destino</Label>
				<Label style={{ width: 100, color: "#fff" }}>Criado por</Label>
				<Label style={{ width: 100, color: "#fff" }}>Detalhes</Label>
				<Label style={{ width: 50, color: "#fff" }}>Tipo</Label>
				<Label style={{ width: 100, color: "#fff" }}>Valor</Label>
			</Row>
			{tableDate()}
		</Container>
	);
};

export default ExtractPMATemplate;
