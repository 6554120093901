import styled from "styled-components";

interface PropsSelect {
	isTextSelect?: boolean;
}

export const Container = styled.div`
	display: flex;
	align-items: center;
	max-width: 330px;
	text-overflow: ellipsis;
`;

export const TextSelect = styled.p<PropsSelect>`
	display: flex;
	align-items: center;
	text-align: left;
	font-weight: ${props => (props.isTextSelect ? "normal" : "bold")};
	font-size: 16px;
	color: ${props => (props.isTextSelect ? "#707070" : "#5236D1")};
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
	overflow: hidden;
	max-width: 230px;
	text-overflow: ellipsis;
	padding-left: 10px;
	margin: 0;
	&:hover {
		overflow: visible;
	}
`;
