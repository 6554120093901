/* eslint-disable react-hooks/exhaustive-deps */
import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import Checkbox from "Components/Checkbox";
import { CEP, CPFCNPJ, MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { isInRole } from "helpers/auth";
import { AsYouType } from "libphonenumber-js";
import ls from "Localization";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "store/actions/role/details";
import { clearValues, createUser, setValue } from "store/actions/user/create";

import { Footer } from "./styles";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		marginTop: 16,
		padding: theme.spacing(0, 2, 0, 0)
	},
	margin: {},
	textField: {}
}));

function AddUser({
	open,
	business,
	handleClose,
	load,
	hasHCMIntegrator,
	hasWiipoClub
}) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (open) {
			dispatch(clearValues());
			dispatch(getRoles());
		}
	}, [dispatch, open]);

	const [zipCodeLoading, setZipCodeLoading] = useState(false);
	const [checkZipCode, setCheckZipCode] = useState(false);

	const { user } = useSelector<any, any>(s => s.login);
	const { items: roleItems } = useSelector<any, any>(s => s.roleDetails);

	const {
		name,
		lastName,
		email,
		cpf,
		businessId,
		role,
		phoneNumber,
		enroll,
		admissionDate,
		limit,
		status,
		loading,
		street,
		number,
		complement,
		neighborhood,
		city,
		state: stateOfCountry,
		zipCode,
		errors,
		birthDate,
		mothersName,
		isPJ
	} = useSelector<any, any>(s => s.userCreate);

	useEffect(() => {
		if (checkZipCode && zipCode?.length === 8) {
			setZipCodeLoading(true);
			fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json) {
						dispatch(
							setValue({
								street: json.logradouro,
								number: "",
								complement: json.complemento,
								neighborhood: json.bairro,
								city: json.localidade,
								state: json.uf,
								zipCode
							})
						);
					}
					setZipCodeLoading(false);
				})
				.catch(err => {
					console.log(err);
					setZipCodeLoading(false);
				});
		}
	}, [zipCode]);

	const handleChange = (id, value) => {
		if (id === "zipCode") setCheckZipCode(true);

		dispatch(
			setValue({
				[id]: value
			})
		);
	};

	useEffect(() => {
		if (business !== businessId) dispatch(setValue({ businessId: business }));
	}, [dispatch, business, businessId]);

	const isAdmin = isInRole(user, ["admin"]);

	let roles = roleItems
		.filter(c => isAdmin || c.normalizedName !== "ADMIN")
		.map(c => ({
			id: c.id,
			value: ls[c.name]
		}));

	const onSubmit = e => {
		e.preventDefault();

		dispatch(
			createUser((err, model) => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					model.businesses[0].role = roles.find(c => c.id === role);
					handleClose(model);
					load();
				}
			}, hasWiipoClub)
		);
	};

	const shouldDisableLimit = useMemo(() => {
		if (isAdmin) return false;
		if (!hasWiipoClub) return true;
		return false;
	}, []);

	return (
		<Modal
			aria-labelledby="add-user-modal-title"
			aria-describedby="add-user-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.addUser}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="name"
										required
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										placeholder={ls.namePlaceholder}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="lastName"
										required
										variant="outlined"
										name="lastName"
										value={lastName}
										errors={errors}
										placeholder={ls.lastNamePlaceholder}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="cpf"
										variant="outlined"
										name="cpfcnpj"
										value={cpf}
										required
										InputProps={{
											inputComponent: CPFCNPJ
										}}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="email"
										variant="outlined"
										name="email"
										value={email}
										errors={errors}
										placeholder={ls.emailPlaceholder}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="phoneNumber"
										variant="outlined"
										name="phoneNumber"
										value={phoneNumber}
										errors={errors}
										onChange={(name, value) =>
											handleChange(
												name,
												new AsYouType().input(
													value[0] === "+" ? value : `+${value}`
												)
											)
										}
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<DatePicker
										value={admissionDate}
										label={ls.admissionDate}
										fullWidth
										onChange={date => handleChange("admissionDate", date)}
										format={ls.dateFormatShort}
										inputVariant="outlined"
										clearable
										clearLabel="Limpar"
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<DatePicker
										id="birthDate"
										name="birthDate"
										fullWidth
										value={birthDate}
										label={ls.birthDate}
										format={ls.dateFormatShort}
										onChange={date => handleChange("birthDate", date)}
										inputVariant="outlined"
										clearable
										clearLabel="Limpar"
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="mothersName"
										variant="outlined"
										name="mothersName"
										value={mothersName}
										errors={errors}
										placeholder={ls.mothersName}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="enroll"
										variant="outlined"
										name="enroll"
										value={enroll}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<Select
										errors={errors}
										id="role"
										name="role"
										placeholder={ls.rolePlaceholder}
										value={role}
										required
										onChange={handleChange}
										options={roles}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="limit"
										required
										variant="outlined"
										name="limit"
										InputProps={{
											inputComponent: MONEY
										}}
										value={limit}
										errors={errors}
										disabled={shouldDisableLimit}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: 0, value: "Pendente" },
											{ id: 1, value: "Ativo" },
											{ id: 2, value: "Desativado temporariamente" },
											{ id: 3, value: "Desativado" }
										]}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography>Endereço:</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="zipCode"
										variant="outlined"
										name="zipCode"
										required={false}
										value={zipCode}
										errors={errors}
										InputProps={{
											inputComponent: CEP
										}}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={9} md={8} lg={7}>
									<TextInput
										id="street"
										variant="outlined"
										name="street"
										required={false}
										value={street}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={3} md={3} lg={2}>
									<TextInput
										id="number"
										variant="outlined"
										name="number"
										required={false}
										value={number}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="complement"
										variant="outlined"
										name="complement"
										value={complement}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="neighborhood"
										variant="outlined"
										name="neighborhood"
										required={false}
										value={neighborhood}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="city"
										variant="outlined"
										name="city"
										required={false}
										value={city}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={4} md={3} lg={2}>
									<TextInput
										id="state"
										variant="outlined"
										name="state"
										inputProps={{
											maxLength: 2
										}}
										required={false}
										value={stateOfCountry}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<Footer isPJEnabled={hasHCMIntegrator}>
								{hasHCMIntegrator && (
									<Grid item xs={6}>
										<Checkbox
											id="isPJ"
											name="isPJ"
											checked={isPJ}
											onChange={handleChange}
										/>
									</Grid>
								)}
								<div>
									<Button variant="contained" onClick={() => handleClose()}>
										{ls.cancel}
									</Button>
									<Button
										color="primary"
										variant="contained"
										type="submit"
										disabled={loading.create || zipCodeLoading}
									>
										{ls.save}
										{(loading.create || zipCodeLoading) && (
											<Spinner color="secondary" size={16} />
										)}
									</Button>
								</div>
							</Footer>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default AddUser;
