import React from "react";

import { ListItem, ListItemText } from "@material-ui/core";

export const renderRow = (handleChange, items, props) => {
	const { index, style } = props;

	if (!items.length)
		return (
			<ListItem button style={style} key={index}>
				<ListItemText
					style={{
						whiteSpace: "nowrap",
						maxWidth: 400
					}}
					primary="Nenhum item"
				/>
			</ListItem>
		);

	return (
		<ListItem
			button
			style={style}
			key={index}
			onClick={() =>
				handleChange(items, index, "status", items[index].status ? 0 : 1)
			}
		>
			<ListItemText
				style={{
					whiteSpace: "nowrap",
					maxWidth: 400
				}}
				primary={items[index].name}
			/>
		</ListItem>
	);
};
