/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Grid,
	IconButton,
	Link as LinkMUI,
	Paper,
	Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ButtonCopyClipboard from "Components/ButtonCopyClipboard";
import ImageInput from "Components/ImageInput";
import { CPFCNPJ } from "Components/NumberFormat";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { getBusinessTypeFromString } from "helpers/business";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import businessStatus from "models/businessStatus";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { FaDownload, FaRegCalendarAlt, FaRegFileExcel } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getBalanceAccountTypeForBusiness } from "store/actions/balanceAccount/report";
import * as businessCreateActions from "store/actions/business/create";
import {
	activeBusiness,
	downloadIntegrationLog,
	exportConsumption,
	getBusiness,
	getBusinessDays,
	HCMSync,
	setValue
} from "store/actions/business/detail";
import { getTax, updateTaxBagsToHeadquarters } from "store/actions/tax";

import ExportConsumption from "../ExportConsumption";
import Accession from "./Accession";
import Allowances from "./Allowance/Report";
import BalanceAccountTransfer from "./BalanceAccountTransfer";
import Branches from "./Branches";
import CashbackPolicy from "./CashbackPolicy/Report";
import Config from "./Config";
import ConsumptionPolicies from "./ConsumptionPolicy/Report";
import ConvenienceFees from "./ConvenienceFees";
import CostCenter from "./CostCenter/Report";
import { DataBankDetails } from "./DataBank";
import DaysForm from "./Days";
import DeliveryAddresses from "./DeliveryAddress/Report";
import ExtraPropertiesReport from "./ExtraProperties";
import FranchiseBusinesses from "./FranchiseBusinesses";
import ImportParcialHCM from "./ImportParcialHCM";
import Integrations from "./Integrations";
import Products from "./Product/Report";
import ProductCategories from "./ProductCategory/Report";
import styles, { ButtonSync, TypeSyncHCM } from "./styles";
import Tax from "./Tax";
import TreasuryAccount from "./TreasuryAccount";
import TreasuryAccountTransfer from "./TreasuryAccountTransfer";
import Users from "./Users";
import VirtualCard from "./VirtualCard";

interface ItemsTax {
	businessId: string;
	business: any;
	newConsumptionTemplate: string;
	consumptionReportTemplate: string;
	checkoutTemplate: string;
	creditCardFee: number;
	creditCardInstallmentFee: number;
	inactivityFee: number;
	limitRescueFee: number;
	taxTransferBagWithdraw: number;
	useHeadquarterConfig: boolean;
	taxCashWithdrawal: number;
	prePaidBalanceFees: any[];
	id: string;
	creationDate: string;
	updateDate: string;
}

let updateHeadquarterEditTax: (() => any) & _.Cancelable = null;

export function RenderBusinessDetail({
	classes,
	state: { item, loading },
	login: { user },
	addImageToBusiness,
	history,
	match: {
		params: { id },
		path
	}
}) {
	const dispatch = useDispatch();
	const [showExportConsumption, setShowExportConsumption] = useState(false);
	const [initializing, setInitializing] = useState(true);
	const [taxs, setTaxs] = useState<ItemsTax>();
	const [filterActive, setFilterActive] = useState(false);
	const [loadingtaxs, setLoading] = useState(true);
	const [disabledButton, setDisabledButton] = useState(false);
	let admin = isInRole(user, ["admin"]);
	let isBusinessAdmin = isInRole(user, ["Business Admin"]);
	const isWiipoFlex = isInRole(user, ["Admin Wiipo Flex"]);

	const updateConfigHeadQuarters = useCallback(
		(status: boolean) => {
			if (updateHeadquarterEditTax) updateHeadquarterEditTax.cancel();
			updateHeadquarterEditTax = _.debounce(() => {
				dispatch(
					updateTaxBagsToHeadquarters(
						id,
						{ useHeadquarterConfig: status },
						(err, model) => {
							if (err) {
								toastError(err);
							} else {
								setTaxs(model);
								if (item?.headquarters.id === model?.businessId) {
									setFilterActive(true);
								} else {
									setFilterActive(model?.useHeadquarterConfig);
								}

								dispatch(getBalanceAccountTypeForBusiness(id, false));
								toastSuccess("Salvo com sucesso");
							}
						}
					)
				);
			}, 500);

			updateHeadquarterEditTax();
		},
		[dispatch, id]
	);

	const handleUpdateLoadFilter = useCallback(
		(status: boolean) => {
			setFilterActive(status);
			updateConfigHeadQuarters(status);
		},
		[updateConfigHeadQuarters]
	);

	const load = useCallback(() => {
		dispatch(
			getBusiness(id, err => {
				if (err) {
					toastError(`Erro ao carregar informação da empresa ==> ${err}`);
				}

				setInitializing(false);
			})
		);
	}, [id, dispatch]);

	const getTaxs = useCallback(() => {
		dispatch(
			getTax(id, (err, model) => {
				if (err) {
					toastError(JSON.stringify(err));
				} else {
					setTaxs(model);
					if (item?.headquarters.id === model?.businessId) {
						setFilterActive(true);
					} else {
						setFilterActive(model?.useHeadquarterConfig);
					}
				}
			})
		);
	}, []);

	useEffect(() => {
		load();
		dispatch(getBalanceAccountTypeForBusiness(id, false));
		if (admin) {
			getTaxs();
		}
		setLoading(false);
	}, [id]);

	const handleImageChange = image => {
		if (!image) return;

		addImageToBusiness(id, image, 1, err => {
			if (err) toastError(JSON.stringify(err));
			else
				dispatch(
					getBusiness(id, err => {
						if (err) {
							toastError(
								`Erro ao carregar informação da empresa ==> ${JSON.stringify(
									err
								)}`
							);
						}
					})
				);
		});
	};

	const handleExportConsumption = (from, to, unifiedOfGroup) => {
		dispatch(
			exportConsumption(item.id, from, to, unifiedOfGroup, false, err => {
				if (err) {
					if (err.default) toastError(JSON.stringify(err.default));
					else if (typeof err === "string") toastError(JSON.stringify(err));
					else toastError(JSON.stringify(err));
				} else {
					setShowExportConsumption(false);
					history.push("/Export");
				}
			})
		);
	};

	const handleActiveBusiness = () => {
		dispatch(
			activeBusiness(id, err => {
				if (err) toastError(JSON.stringify(err));
			})
		);
	};

	const handleEditDays = () => {
		dispatch(
			getBusinessDays(id, err => {
				if (err) {
					if (typeof err !== "string") err = JSON.stringify(err);
					toastError(JSON.stringify(err));
				} else {
					dispatch(setValue({ showDaysFormModal: true }));
				}
			})
		);
	};

	const handleHCMSync = useCallback(() => {
		setDisabledButton(true);
		dispatch(
			HCMSync(id, err => {
				setDisabledButton(false);
				if (err) {
					if (typeof err !== "string") err = JSON.stringify(err);
					toastError(JSON.stringify(err));
				}
			})
		);
	}, []);

	const handleDownloadIntegrationHCM = () => {
		dispatch(downloadIntegrationLog(id));
	};

	if (!item || initializing) {
		return <div>Carregando...</div>;
	}

	const GROUP_ID_SENIOR = item.type === 12; // Necessário para ocultar o CNPJ do grupo Senior para os clientes, id 12 só é definido para a senior

	let {
		creationDate,
		name,
		socialName,
		documents,
		phoneNumber,
		address,
		category,
		logo,
		status,
		config,
		responsible,
		teaser,
		about,
		isStore,
		isCompany,
		isHeadquarters,
		headquarters,
		headquartersId,
		isFranchise,
		hasHCMIntegrator,
		hasWiipoClub,
		isHcmPartial,
		treasuryAccountHolderId
	} = item;

	let cnpj = documents ? documents.find(c => c.type === 0) : {};

	const { type } = getBusinessTypeFromString(path);

	return (
		<>
			<Helmet>
				<title>
					{ls[`${type}Data`]} | {ls.appName}
				</title>
			</Helmet>
			<Grid container justify="center" spacing={5}>
				<Grid item xs={11}>
					<Paper className={classes.root}>
						<div className={classes.header}>
							<Typography variant="h6">{ls[`${type}Data`]}</Typography>

							<div>
								{hasHCMIntegrator && (
									<>
										<ButtonSync
											style={{
												color: "#fff",
												backgroundColor: disabledButton ? "#EFEFEF" : "#FE9D2B"
											}}
											disabled={disabledButton}
											onClick={handleHCMSync}
										>
											{disabledButton ? (
												<Spinner color="secondary" size={16} />
											) : (
												"Sincronizar usuários"
											)}
										</ButtonSync>
									</>
								)}

								{admin && (
									<Link to={`/${type}/${item.id}/Edit`}>
										<Button color="primary">{ls.edit}</Button>
									</Link>
								)}

								{admin && isStore && (
									<TooltipLight title="Editar horários" placement="top">
										<IconButton onClick={handleEditDays}>
											<FaRegCalendarAlt />
										</IconButton>
									</TooltipLight>
								)}

								{admin && status !== 1 && (
									<Button color="primary" onClick={handleActiveBusiness}>
										{ls.activate}
										{loading.active && <Spinner color="secondary" size={16} />}
									</Button>
								)}

								{hasWiipoClub && (
									<TooltipLight title="Exportar consumos" placement="top">
										<IconButton onClick={() => setShowExportConsumption(true)}>
											<FaRegFileExcel />
										</IconButton>
									</TooltipLight>
								)}

								{admin && hasHCMIntegrator && (
									<TooltipLight
										title="Exportar Log Integração HCM"
										placement="top"
									>
										<IconButton onClick={handleDownloadIntegrationHCM}>
											<FaDownload />
										</IconButton>
									</TooltipLight>
								)}
							</div>
						</div>

						<hr className={classes.line} />

						<Grid container spacing={2} className={classes.form}>
							{admin && isStore && (
								<Grid item xs={12} sm={12} md={4} lg={3}>
									<ImageInput
										singleImage
										onDelete={(e: any) => console.log(e)}
										withPreview
										hideOnPreview
										withIcon
										defaultImage={typeof logo === "string" ? logo : undefined}
										buttonText="Escolher foto"
										fileSizeError={ls.fileSizeIsTooBig}
										onChange={images => handleImageChange(images[0])}
										label="Escolha uma foto para aparecer no aplicativo. Tamanho máximo: 256kb. Resolução recomendada: 800px por 600px"
										imgExtension={[".jpg", ".png", ".jpeg", ".webp"]}
										maxFileSize={262144}
									/>
								</Grid>
							)}

							<Grid
								container
								item
								spacing={2}
								xs={12}
								md={admin && isStore ? 8 : 12}
								lg={admin && isStore ? 9 : 12}
							>
								{admin && (
									<Grid item xs={12} sm={6} md={4} lg={4}>
										<strong>{ls.creationDate}</strong>:{" "}
										{dayjs(creationDate).format(ls.dateFormatWithoutSeconds)}
									</Grid>
								)}

								<Grid item xs={12} sm={6} md={4}>
									<strong>{ls.name}</strong>: {name}
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<strong>{ls.socialName}</strong>:{" "}
									{socialName || ls.notDefined}
								</Grid>

								{((GROUP_ID_SENIOR && admin) || !GROUP_ID_SENIOR) && (
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center"
										}}
									>
										<strong>{ls.cnpj}</strong>:{" "}
										{cnpj ? (
											<>
												<CPFCNPJ
													displayType="text"
													value={cnpj.value}
													style={{ marginLeft: 5 }}
												/>
												<ButtonCopyClipboard valueCopy={cnpj.value} />
											</>
										) : (
											ls.notDefined
										)}
									</Grid>
								)}
								{headquartersId ? (
									<Grid item xs={12} sm={6} md={4} style={{ marginTop: 10 }}>
										<strong>{ls.headquarters}</strong>:{" "}
										<LinkMUI
											style={{ cursor: "pointer" }}
											onClick={() =>
												window.open(`/Company/${headquartersId}`, "_blank")
											}
											underline="hover"
											color={"primary"}
										>
											{headquarters?.displayName}
										</LinkMUI>
									</Grid>
								) : (
									<></>
								)}
								<Grid item xs={12} sm={6} md={4} style={{ marginTop: 10 }}>
									<strong>{ls.phoneNumber}</strong>:{" "}
									{phoneNumber || ls.notDefined}
								</Grid>

								{admin && isStore && (
									<Grid item xs={12} sm={6} md={4}>
										<strong>{ls.category}</strong>:{" "}
										{category ? category.name : ls.notDefined}
									</Grid>
								)}

								<Grid item xs={12}>
									<strong>{ls.address}</strong>:{" "}
									{address ? address.fullAddress : ls.notDefined}
								</Grid>

								{admin && responsible && (
									<Grid container item xs={12}>
										<Grid item xs={12}>
											<br />
											<strong>{ls.responsibleData}</strong>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<strong>{ls.name}</strong>: {responsible.fullName}
										</Grid>
										<Grid item xs={12} sm={6} md={5}>
											<strong>{ls.email}</strong>: {responsible.email}
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											<strong>{ls.phoneNumber}</strong>:{" "}
											{responsible.phoneNumber}
										</Grid>
									</Grid>
								)}

								{admin && (
									<Grid
										item
										xs={12}
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center"
										}}
									>
										<br />
										<strong>{ls.status}</strong>: {ls[businessStatus[status]]}
										{hasHCMIntegrator && isHcmPartial && (
											<>
												<strong style={{ marginLeft: 10 }}>{ls.sync}</strong>:
												<TypeSyncHCM>{ls.hcmParcial}</TypeSyncHCM>
											</>
										)}
									</Grid>
								)}

								{admin && isStore && (
									<Grid item xs={12}>
										<strong>{ls.teaser}</strong>: {teaser}
									</Grid>
								)}
								{admin && isStore && (
									<Grid item xs={12}>
										<strong>{ls.about}</strong>: {about}
									</Grid>
								)}
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				{admin && isHcmPartial && (
					<Grid item xs={11}>
						<ImportParcialHCM businessId={id} />
					</Grid>
				)}

				{(admin || isBusinessAdmin) && (
					<Grid item xs={11}>
						<Config
							config={config}
							admin={admin}
							isBusinessAdmin={isBusinessAdmin}
							id={id}
							hasHCM={hasHCMIntegrator}
							hasWiipoClub={hasWiipoClub}
						/>
					</Grid>
				)}

				{(admin || isWiipoFlex) && (
					<Grid item xs={11}>
						<TreasuryAccount id={id} item={item} admin={admin} />
					</Grid>
				)}

				{treasuryAccountHolderId && (admin || isWiipoFlex) && (
					<Grid item xs={11}>
						<TreasuryAccountTransfer
							id={id}
							business={item}
							load={load}
							isHeadquarters={isHeadquarters}
						/>
					</Grid>
				)}

				{treasuryAccountHolderId && (admin || isWiipoFlex) && (
					<Grid item xs={11}>
						<BalanceAccountTransfer
							id={id}
							isAdmin={admin}
							isFilterActiveHeadquarter={filterActive}
							isHeadquarters={isHeadquarters}
						/>
					</Grid>
				)}

				{treasuryAccountHolderId && admin && (
					<Grid item xs={11}>
						<Tax
							id={id}
							isHeadquarter={isHeadquarters}
							isExistHeadquarter={Boolean(item?.headquarters)}
							name={socialName}
							cnpj={cnpj.value}
							filterActive={filterActive}
							handleUpdateLoadFilter={handleUpdateLoadFilter}
							taxs={taxs}
							loading={loadingtaxs}
						/>
					</Grid>
				)}

				{isStore && (
					<Grid item xs={11}>
						<DataBankDetails />
					</Grid>
				)}

				{admin && (
					<Grid item xs={11}>
						<ExtraPropertiesReport id={id} />
					</Grid>
				)}

				{admin && (isStore || isHeadquarters) && item?.config?.accessionUrl && (
					<Grid item xs={11}>
						<ConvenienceFees />
					</Grid>
				)}

				{admin && (isStore || isHeadquarters) && item?.config?.accessionUrl && (
					<Grid item xs={11}>
						<Accession />
					</Grid>
				)}

				{admin && (isStore || isHeadquarters) && (
					<Grid item xs={11}>
						<VirtualCard />
					</Grid>
				)}

				{isCompany && [admin].includes(true) && (
					<Grid item xs={11}>
						<Integrations />
					</Grid>
				)}

				{isCompany && [admin, isBusinessAdmin].includes(true) && (
					<Grid item xs={11}>
						<ConsumptionPolicies business={item} />
					</Grid>
				)}

				{isCompany && [admin, isBusinessAdmin].includes(true) && (
					<Grid item xs={11}>
						<Allowances business={item} />
					</Grid>
				)}

				{admin && isStore && (
					<Grid item xs={11}>
						<CashbackPolicy business={item} />
					</Grid>
				)}

				{admin && isStore && (
					<Grid item xs={11}>
						<ProductCategories business={item} />
					</Grid>
				)}

				<Grid item xs={11}>
					<Products business={item} hide={!(admin && isStore)} />
				</Grid>

				{admin && isStore && (
					<Grid item xs={11}>
						<DeliveryAddresses business={item} />
					</Grid>
				)}

				{isHeadquarters && (
					<Grid item xs={11}>
						<Branches />
					</Grid>
				)}

				{admin && isFranchise && (
					<Grid item xs={11}>
						<FranchiseBusinesses />
					</Grid>
				)}

				{(isCompany || isHeadquarters) && (
					<Grid item xs={11}>
						<CostCenter item={item} />
					</Grid>
				)}

				<Grid item xs={11}>
					<Users
						setValue={setValue}
						item={item}
						history={history}
						hasHCMIntegrator={hasHCMIntegrator}
					/>
				</Grid>

				{admin && isStore && <DaysForm />}

				<ExportConsumption
					open={showExportConsumption}
					business={item}
					handleClose={() => setShowExportConsumption(false)}
					handleSubmit={handleExportConsumption}
				/>
			</Grid>
		</>
	);
}

const mapStateToProps = ({ businessDetail: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = {
	addImageToBusiness: businessCreateActions.addImageToBusiness
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RenderBusinessDetail));
