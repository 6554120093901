import { IconButton } from "@material-ui/core";
import Spinner from "Components/Spinner";
import dayjs from "dayjs";
import { excelDateToJSDate } from "helpers/date";
import { removeDiacritics } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useRef } from "react";
import { FaFileImport } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { importCredits } from "store/actions/credit/import";
import XLSX from "xlsx";

let debounceImportCredits: (() => void) & _.Cancelable = null;

function ImportCredits({ load }) {
	const dispatch = useDispatch();

	const { loading } = useSelector<any, any>(s => s.creditImport);

	const inputFile = useRef<HTMLInputElement>(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const handleFileSelected = e => {
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const sheet = wb.Sheets[wsname];

			const ref = XLSX.utils.decode_range(sheet["!ref"]);

			for (let C = ref.s.c; C <= ref.e.c; ++C) {
				const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
				if (cell && cell.t === "s") {
					cell.v = removeDiacritics(cell.v.trim().toLowerCase());
					if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());
				}
			}

			let data = XLSX.utils.sheet_to_json(sheet);

			data = data.map(c => {
				const model = {
					description: c["description"],
					origin: c["origin"],
					client: c["client"],
					company: c["company"],
					value: c["value"],
					minValue: c["min"],
					startDate: c["start"],
					expirationDate: c["expiration"],
					stores: c["stores"]?.split(","),
					categories: c["categories"]?.split(",")
				};

				if (model.client) {
					model.client = String(model.client)
						?.match(/\d/g)
						?.join("")
						.padStart(11, "0");
				}

				if (model.minValue) {
					if (typeof model.minValue === "number") {
						model.minValue = Math.round(model.minValue * 100);
					}

					model.minValue = String(model.minValue).match(/\d/g).join("");
				} else {
					model.minValue = 0;
				}

				if (model.value) {
					if (typeof model.value === "number") {
						model.value = Math.round(model.value * 100);
					}

					model.value = String(model.value).match(/\d/g).join("");
				} else {
					model.value = 0;
				}

				if (model.startDate) {
					if (typeof model.startDate === "number") {
						model.startDate = dayjs(excelDateToJSDate(model.startDate), {
							utc: false
						})
							.add(3, "hour")
							.startOf("date");
					} else {
						model.startDate = dayjs(
							model.startDate,
							model.startDate.length > 10 ? ls.dateFormat : ls.dateFormatShort
						).startOf("date");
					}
				}

				if (model.expirationDate) {
					if (typeof model.expirationDate === "number") {
						model.expirationDate = dayjs(
							excelDateToJSDate(model.expirationDate),
							{ utc: false }
						)
							.add(3, "hour")
							.endOf("date");
					} else {
						model.expirationDate = dayjs(
							model.expirationDate,
							model.expirationDate.length > 10
								? ls.dateFormat
								: ls.dateFormatShort
						).endOf("date");
					}
				}

				return model;
			});

			if (debounceImportCredits) debounceImportCredits.cancel();

			debounceImportCredits = _.debounce(() => {
				dispatch(
					importCredits(data, err => {
						if (err) alert(err);
						else load();
					})
				);
			}, 1000);

			debounceImportCredits();
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	return (
		<div>
			{loading ? (
				<Spinner color="secondary" size={16} />
			) : (
				<IconButton onClick={openSelectFile}>
					<FaFileImport />
				</IconButton>
			)}
			<input
				ref={inputFile}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileSelected}
			/>
		</div>
	);
}

export default ImportCredits;
