import { IconButton } from "@material-ui/core";
import icon_Ticket from "assets/icon_ticket.svg";
import icon_Nf from "assets/icon-nf.svg";
import icon_Pix from "assets/icon-pix.svg";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import React, { useCallback, useMemo } from "react";
import { AiOutlineMore } from "react-icons/ai";

interface ActionsProps {
	id: number;
	title: string;
	body: JSX.Element;
	show: boolean;
}

interface ActionsComponentProps {
	handleOpenModalPdf: (link: string, line: string) => void;
	handleOpenModalPix: (id: string) => void;
	handleDowloadNF: (id: string) => void;
	getToggleId: (id: string) => void;
	handleToggle: (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		id: string
	) => void;
	loading: boolean;
	isAdmin: boolean;
	moreAnchorRef: any;
	c: any;
}

const Actions: React.FC<ActionsComponentProps> = ({
	handleOpenModalPdf,
	handleOpenModalPix,
	handleDowloadNF,
	handleToggle,
	getToggleId,
	loading,
	isAdmin,
	moreAnchorRef,
	c
}) => {
	const shouldShowMore = useCallback(
		(status: any, type: any, pdflink?: any) => {
			let show = false;
			if ([2, 4, 8].includes(status)) show = true;
			if (!isAdmin && [7].includes(status)) show = false;
			if (type !== 0) show = true;
			if (status === 4 && type === 0) show = false;
			if (!pdflink && status !== 1) show = false;
			return show;
		},
		[isAdmin]
	);

	const ACTIONS: ActionsProps[] = useMemo(
		() => [
			{
				id: 0,
				title: "Pague por boleto",
				show: [2, 3].includes(c.status),
				body: (
					<IconButton
						style={{ padding: 4, marginRight: 8 }}
						onClick={() => {
							handleOpenModalPdf(c.pdfLink, c.digitableLine);
						}}
					>
						<img src={icon_Ticket} alt="icon ticket" />
					</IconButton>
				)
			},
			{
				id: 1,
				title: "Pague por pix",
				show: [2, 3].includes(c.status),
				body: (
					<IconButton
						style={{
							padding: 4,
							marginRight: 8,
							color: "#2E8ACC"
						}}
						onClick={() => {
							getToggleId(c.id);
							handleOpenModalPix(c.id);
						}}
					>
						<img
							src={icon_Pix}
							alt="icon pix"
							width={25}
							height={25}
							style={{ color: "#2E8ACC" }}
							color="#2E8ACC"
						/>
					</IconButton>
				)
			},
			{
				id: 2,
				title: !c.invoicePdfLink ? "Gerando sua nota" : "Baixe sua nota",
				show: [1, 2, 3, 4].includes(c.status),
				body:
					!c.invoicePdfLink && c.status !== 1 ? (
						<IconButton style={{ padding: 4, marginRight: 8 }}>
							<Spinner color="inherit" size={16} />
						</IconButton>
					) : (
						<IconButton
							style={{ padding: 4, marginRight: 8 }}
							onClick={() => {
								getToggleId(c.id);
								window.open(c.invoicePdfLink, "_blank");
							}}
						>
							<img src={icon_Nf} alt="icon Nf" width={25} height={25} />
						</IconButton>
					)
			},
			{
				id: 3,
				title: "Mais",
				show: shouldShowMore(c.status, c.type, c.invoicePdfLink),
				body: (
					<IconButton
						ref={moreAnchorRef}
						aria-haspopup
						style={{ padding: 4 }}
						onClick={e => handleToggle(e, c.id)}
					>
						<AiOutlineMore />
					</IconButton>
				)
			}
		],
		[
			c.digitableLine,
			c.id,
			c.invoicePdfLink,
			c.pdfLink,
			c.status,
			c.type,
			getToggleId,
			handleOpenModalPdf,
			handleOpenModalPix,
			handleToggle,
			loading,
			moreAnchorRef,
			shouldShowMore
		]
	);

	return (
		<>
			{ACTIONS.map(
				item =>
					item.show && (
						<TooltipLight title={item.title} placement="top" key={item.id}>
							{item.body}
						</TooltipLight>
					)
			)}
		</>
	);
};

export default Actions;
