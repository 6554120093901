/* eslint-disable react-hooks/exhaustive-deps */
import {
	Badge,
	Box,
	Button,
	Grid,
	IconButton,
	ListItem,
	ListItemText,
	Paper,
	Tab,
	Tabs,
	Typography
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import Checkbox from "Components/Checkbox";
import { FilterOptions } from "Components/Marketing/FilterOptions";
import { getExcelData } from "Components/Report/export";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import TooltipLight from "Components/TooltipLight";
import dayjs from "dayjs";
import { CustomFile, handleChangeEvent } from "helpers/files";
import ls from "Localization";
import _ from "lodash";
import pushActionType from "models/pushActionType";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import ReactExport from "react-export-excel";
import {
	AiFillCloseCircle,
	AiOutlineDoubleLeft,
	AiOutlineDoubleRight
} from "react-icons/ai";
import { FaRegFileExcel } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList } from "react-window";
import {
	clearValues,
	editMarketing,
	getMarketing,
	getMarketingUsersTiny,
	setValue
} from "store/actions/marketing";
import { getEnabledUsers } from "store/actions/user/report";

import useStyles from "./styles";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

let debounceEditMarketing: (() => void) & _.Cancelable = null;

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

const handleFilterUsers = (c, value) =>
	`${c.fullName.toLowerCase()} - ${c.businessName?.toLowerCase()}`.indexOf(
		value.toLowerCase()
	) !== -1;

export function MarketingEdit({
	history,
	match: {
		params: { id }
	}
}) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [tab, setTab] = useState(0);
	const [whatsAppImage, setWhatsAppImage] = useState<CustomFile>(null);
	const [previewImage, setPreviewImage] = useState<string>("");
	const inputFileRef = useRef(null);
	const [filterIn, setFilterIn] = useState("");
	const [filterOut, setFilterOut] = useState("");

	const [exportData, setExportData] = useState(null);

	const [filterSelected, setFilterSelected] = useState("");

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getMarketing(id));
		dispatch(getMarketingUsersTiny(id));
	}, []);

	useEffect(() => {
		dispatch(getEnabledUsers(filterSelected));
	}, [dispatch, filterSelected]);

	let {
		name,
		startAt,
		economicMode,
		emailSubject,
		emailMessage,
		smsMessage,
		whatsAppFile,
		whatsAppMessage,
		whatsAppRestrict,
		pushTitle,
		pushMessage,
		pushAction,
		pushValue,
		usersTiny,
		errors,
		loading
	} = useSelector<any, any>(s => s.marketing);

	if (!usersTiny) usersTiny = [];

	const usersState = useSelector<any, any>(s => s.userReport);

	const usersEnabled = useMemo(() => {
		if (!usersState.enabled?.length) return [];

		const users = [];

		if (emailMessage) {
			users.push(...usersState.enabled.filter(c => c.email));
		}

		if (smsMessage) {
			users.push(
				...usersState.enabled.filter(
					c => !users.find(u => u === c) && c.phoneNumber > 0
				)
			);
		}

		if (whatsAppMessage) {
			users.push(
				...usersState.enabled.filter(
					c => !users.find(u => u === c) && c.sale > 0
				)
			);
		}

		if (pushMessage) {
			users.push(
				...usersState.enabled.filter(
					c => !users.find(u => u === c) && c.pushToken > 0
				)
			);
		}

		return users;
	}, [
		usersState.enabled,
		emailMessage,
		smsMessage,
		whatsAppMessage,
		pushMessage
	]);

	const handleExport = useCallback(() => {
		const title = name || "marketing_usuarios";

		const data = getExcelData(
			[
				{ id: "businessName", Header: "Empresa" },
				{ id: "fullName", Header: "Nome completo" },
				{ id: "cpf", Header: "CPF" },
				{ id: "email", Header: "E-mail" },
				{ id: "phoneNumber", Header: "Telefone" }
			],
			usersEnabled
		);

		setExportData({ title, data });

		setTimeout(() => {
			setExportData(null);
		}, 1000);
	}, [usersEnabled, name]);

	const handleChange = useCallback(
		(name: string, value: any) => {
			dispatch(
				setValue({
					[name]: value
				})
			);
		},
		[dispatch]
	);

	const handleRight = () => {
		let newUsers = usersEnabled
			.filter(
				c => !usersTiny.find(u => u === c.id) && handleFilterUsers(c, filterOut)
			)
			.map(c => c.id);

		handleChange("usersTiny", [...usersTiny, ...newUsers]);

		setFilterOut("");
	};

	const handleLeft = () => {
		let keep = [];
		if (filterIn) {
			let filtered = usersEnabled.filter(c => handleFilterUsers(c, filterIn));

			keep = usersTiny.filter(u => !filtered.find(c => c.id === u));
		}

		handleChange("usersTiny", keep);
		setFilterIn("");
	};

	const handleUserChange = (items: any[], index: number) => {
		let item = usersTiny.find(c => c === items[index].id);

		if (item) {
			handleChange(
				"usersTiny",
				usersTiny.filter(c => c !== item)
			);
		} else {
			handleChange("usersTiny", [...usersTiny, items[index].id]);
		}
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (debounceEditMarketing) debounceEditMarketing.cancel();

		debounceEditMarketing = _.debounce(() => {
			dispatch(
				editMarketing(whatsAppImage, (err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						history.push(`/marketing/${model.id ? model.id : model}`);
					}
				})
			);
		}, 1000);

		debounceEditMarketing();
	};

	const selectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = handleChangeEvent(e);

		if (files && files.length) {
			const file: any = files[0];
			const preview = URL.createObjectURL(file);

			if (preview) {
				handleChange("whatsAppFile", null);
			}

			setWhatsAppImage(file);
			setPreviewImage(preview);
		}
	};

	const renderRow = (items, props) => {
		const { index, style } = props;

		if (!items.length)
			return (
				<ListItem button style={style} key={index}>
					<ListItemText
						style={{
							whiteSpace: "nowrap",
							maxWidth: 400
						}}
						primary="Nenhum item"
					/>
				</ListItem>
			);

		return (
			<ListItem
				button
				style={style}
				key={index}
				onClick={() => handleUserChange(items, index)}
			>
				<ListItemText
					style={{
						whiteSpace: "nowrap",
						maxWidth: 400
					}}
					primary={`${items[index].fullName} - ${items[index].businessName}`}
				/>
			</ListItem>
		);
	};

	let filteredIn = usersEnabled.filter(c => handleFilterUsers(c, filterIn));

	let filteredOut = usersEnabled.filter(c => handleFilterUsers(c, filterOut));

	let usersIn = filteredIn.filter(c => usersTiny.find(u => u === c.id));

	let usersNotIn = filteredOut.filter(
		c => !usersTiny.find(u => u.userId === c)
	);

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<Typography variant="h6">
						Editar campanha{" "}
						{loading.get && <Spinner color="secondary" size={16} />}
					</Typography>

					<hr className={classes.line} />

					<form noValidate onSubmit={onSubmit}>
						<Tabs
							value={tab}
							indicatorColor="primary"
							textColor="primary"
							onChange={(_, v) => setTab(v)}
							centered
						>
							<Tab label="Sobre" />
							<Tab
								label={
									<Badge
										color="secondary"
										badgeContent={usersState.email}
										max={99999}
									>
										<Typography>E-mail</Typography>
									</Badge>
								}
							/>
							<Tab
								label={
									<Badge
										color="secondary"
										badgeContent={usersState.sms}
										max={99999}
									>
										<Typography>SMS</Typography>
									</Badge>
								}
							/>
							<Tab
								label={
									<Badge
										color="secondary"
										badgeContent={usersState.whatsApp}
										max={99999}
									>
										<Typography>WhatsApp</Typography>
									</Badge>
								}
							/>
							<Tab
								label={
									<Badge
										color="secondary"
										badgeContent={usersState.push}
										max={99999}
									>
										<Typography>Push</Typography>
									</Badge>
								}
							/>
							<Tab label="Enviar para" />
						</Tabs>
						<TabPanel value={tab} index={0}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12} sm={6} md={6}>
									<TextInput
										id="name"
										required
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6}>
									<DateTimePicker
										value={startAt}
										label={ls.startAt}
										fullWidth
										onChange={date => handleChange("startAt", date)}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
									/>
								</Grid>
								<Grid container item xs={12}>
									<Grid item xs={12}>
										<Checkbox
											id="economicMode"
											name="economicMode"
											checked={economicMode}
											onChange={handleChange}
										/>
									</Grid>
									<FilterOptions
										selected={filterSelected}
										onChange={setFilterSelected}
									/>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={1}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12}>
									<TextInput
										id="emailSubject"
										required
										variant="outlined"
										name="emailSubject"
										value={emailSubject}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextInput
										id="emailMessage"
										required
										variant="outlined"
										name="emailMessage"
										value={emailMessage}
										multiline
										rows={6}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<strong>Preview</strong>:
									<div
										style={{
											overflow: "auto"
										}}
										dangerouslySetInnerHTML={{ __html: emailMessage }}
									/>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={2}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12}>
									<TextInput
										id="smsMessage"
										required
										variant="outlined"
										name="smsMessage"
										value={smsMessage}
										errors={errors}
										multiline
										rows={6}
										onChange={handleChange}
										FormHelperTextProps={{ style: { textAlign: "right" } }}
										helperText={`${smsMessage.length ?? 0}/140`}
									/>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={3}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12}>
									<div>
										<Button
											variant="contained"
											color="primary"
											onClick={() => inputFileRef.current?.click()}
										>
											Adicionar arquivo
										</Button>
										<Typography
											style={{ display: "inline-block", marginLeft: 8 }}
										>
											{whatsAppImage?.name ?? "Nenhum arquivo selecionado"}
										</Typography>
										<input
											style={{ display: "none" }}
											type="file"
											ref={inputFileRef}
											onChange={selectFile}
										/>
									</div>
									{(whatsAppFile || whatsAppImage) && (
										<div
											style={{
												width: "100%",
												position: "relative",
												marginTop: 15
											}}
										>
											<IconButton
												style={{ position: "absolute", zIndex: 1 }}
												onClick={() => {
													setWhatsAppImage(null);
													handleChange("whatsAppFile", null);
												}}
											>
												<AiFillCloseCircle
													size={22}
													color="#ccc"
													style={{
														position: "relative"
													}}
												/>
											</IconButton>
											<img
												src={whatsAppFile || previewImage}
												alt="preview"
												style={{
													width: "auto",
													height: 96,
													objectFit: "cover",
													borderRadius: 10,
													boxShadow: "rgb(0, 0, 0, 0.25) 5px 5px 10px"
												}}
											/>
										</div>
									)}
								</Grid>
								<Grid item xs={12}>
									<TextInput
										id="whatsAppMessage"
										required
										variant="outlined"
										name="whatsAppMessage"
										value={whatsAppMessage}
										errors={errors}
										multiline
										rows={6}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<Checkbox
										id="whatsAppRestrict"
										name="whatsAppRestrict"
										checked={whatsAppRestrict}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={4}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12}>
									<TextInput
										id="pushTitle"
										required
										variant="outlined"
										name="pushTitle"
										value={pushTitle}
										errors={errors}
										onChange={handleChange}
										FormHelperTextProps={{ style: { textAlign: "right" } }}
										helperText={`${pushTitle.length ?? 0}/44`}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextInput
										id="pushMessage"
										required
										variant="outlined"
										name="pushMessage"
										value={pushMessage}
										errors={errors}
										multiline
										rows={6}
										onChange={handleChange}
										FormHelperTextProps={{ style: { textAlign: "right" } }}
										helperText={`${pushMessage.length ?? 0}/178`}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Select
										errors={errors}
										id="pushAction"
										name="action"
										required
										value={pushAction}
										onChange={handleChange}
										options={Object.keys(pushActionType).map(c => ({
											id: c,
											value: ls[pushActionType[c]]
										}))}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextInput
										id="pushValue"
										variant="outlined"
										name="value"
										value={pushValue}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
						</TabPanel>

						<TabPanel value={tab} index={5}>
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12}>
									<Typography>{errors["users"]}</Typography>
								</Grid>
								<Grid item xs={12} md={5}>
									<Typography style={{ marginLeft: 16 }} variant="body2">
										Não enviar para:
									</Typography>
									<Grid
										item
										md={12}
										container
										justify="flex-end"
										alignItems="center"
										alignContent="flex-end"
									>
										<TooltipLight
											title="Fazer download da lista"
											placement="top"
										>
											<IconButton onClick={handleExport}>
												<FaRegFileExcel />
											</IconButton>
										</TooltipLight>
										<Badge
											style={{ marginLeft: 12 }}
											color="secondary"
											max={99999}
											badgeContent={usersEnabled.length}
										/>
									</Grid>
									<TextInput
										id="filterOut"
										variant="standard"
										name="filterOut"
										hideLabel
										placeholder="Filtro"
										value={filterOut}
										errors={errors}
										onChange={(id, v) => setFilterOut(v)}
									/>
									<FixedSizeList
										height={400}
										itemSize={36}
										itemCount={usersNotIn.length}
									>
										{props => renderRow(usersNotIn, props)}
									</FixedSizeList>
								</Grid>
								<Grid
									item
									xs={12}
									md={2}
									container
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<Grid
										item
										xs={12}
										style={{ display: "flex", justifyContent: "center" }}
									>
										<IconButton
											disabled={!usersNotIn.length}
											onClick={handleRight}
										>
											<AiOutlineDoubleRight />
										</IconButton>
									</Grid>
									<Grid
										item
										xs={12}
										style={{ display: "flex", justifyContent: "center" }}
									>
										<IconButton disabled={!usersIn.length} onClick={handleLeft}>
											<AiOutlineDoubleLeft />
										</IconButton>
									</Grid>
								</Grid>
								<Grid item xs={12} md={5}>
									<Typography style={{ marginLeft: 16 }} variant="body2">
										Enviar para:
									</Typography>
									<TextInput
										id="filterIn"
										variant="standard"
										name="filterIn"
										hideLabel
										placeholder="Filtro"
										value={filterIn}
										errors={errors}
										onChange={(id, v) => setFilterIn(v)}
									/>
									<FixedSizeList
										height={400}
										itemSize={36}
										itemCount={usersIn.length || 1}
									>
										{props => renderRow(usersIn, props)}
									</FixedSizeList>
								</Grid>
							</Grid>
						</TabPanel>

						<hr className={classes.line} />
						<Grid container justify="flex-end">
							<Button
								variant="contained"
								color="default"
								className={classes.button}
								onClick={() => dispatch(clearValues())}
							>
								{ls.clear}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								type="submit"
								disabled={loading.update}
							>
								{ls.save}
								{loading.update && <Spinner color="secondary" size={16} />}
							</Button>
						</Grid>
					</form>
				</Paper>
			</Grid>
			{exportData && (
				<ExcelFile
					filename={`${exportData.title}_${dayjs().format(
						"YYYY-MM-DD-kk-mm-ss"
					)}`}
					hideElement
				>
					<ExcelSheet dataSet={exportData.data} name={exportData.title} />
				</ExcelFile>
			)}
		</Grid>
	);
}

export default MarketingEdit;
