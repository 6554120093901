import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	line: {
		marginBottom: 10
	},
	container: {
		padding: 20
	},
	footerButtons: {
		marginTop: 25
	},
	gridForm: {
		marginTop: 10
	},
	selectBank: {
		zIndex: 3
	},
	pixTypeInput: {
		zIndex: 1
	}
}));
