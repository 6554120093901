import styled from "styled-components";

export const Container = styled.div`
	p {
		margin-top: 20px;
	}
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	font: normal normal normal 19px Exo;
	letter-spacing: 0px;
	color: #484848;
	opacity: 1;

	h4 {
		margin: 0;
		margin-top: 20px;
		padding: 0;
		text-align: left;
		font: normal normal 600 19px/25px Exo;
		letter-spacing: 0px;
		color: #484848;
		opacity: 1;
	}
`;

export const BackgroundCheckBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	p {
		text-align: left;
		font: normal normal normal 17px Exo;
		letter-spacing: 0px;
		color: #707070;
		opacity: 1;
		width: 150px;
	}
`;
