import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme: any) => ({
	root: {
		padding: theme.spacing(2)
	},
	paper: {
		position: "absolute",
		width: 400,
		borderRadius: theme.spacing(),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: "none"
	},
	form: {
		padding: theme.spacing(2, 4)
	},
	alert: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		backgroundColor: "#cdeefd"
	},
	alertIcon: {
		marginRight: theme.spacing(2),
		color: "#02587f"
	},
	alertText: {
		color: "#02587f"
	},
	line: {},
	button: {
		margin: theme.spacing()
	},
	title: {
		margin: theme.spacing()
	},
	subTitle: {
		fontSize: 15
	},
	hcmGrid: {
		padding: 0,
		marginBottom: 5,
		fontSize: 17
	},
	hcmSwitch: {
		marginLeft: theme.spacing(2)
	}
}));

export default useStyles;

export const GridCheckboxHCM = styled(Grid)`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-left: 1px;
	padding: 5px 0px;
`;

export const LabelCheckboxHCM = styled.div`
	text-align: left;
	font: normal normal normal 17px Exo;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	margin-right: 5px;
`;
