import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import Tooltip from "Components/Tooltip";
import { sumCurrency } from "helpers/money";
import { toStringCurrency } from "helpers/string";
import { toastError, toastSuccess, toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { getBalanceAccountTypes } from "store/actions/balanceAccount/report";
import { createTransactionMultiCNPJ } from "store/actions/treasuryAccountTransfer/create";
import { setValue } from "store/actions/treasuryAccountTransfer/create";
import { ITreasuryAccountTransferState } from "store/actions/treasuryAccountTransfer/types";
import { State } from "store/reducers";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: "calc(80%)",
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	},
	dateContent: {
		display: "flex",
		justifyContent: "flex-end",
		padding: "1rem"
	},
	containerDetail: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between"
	},
	containerInput: { width: 380, marginTop: 10, flexDirection: "column" },
	labelMaxLengthDescription: { marginTop: 5, fontSize: 10, color: "#fe7c7b" }
}));

export const parseImportError = {
	user_not_found: "ErrorUserNotFound",
	mothers_name_or_birthdate_not_found: "ErrorUserBirthdateOrMothersNameNotFound"
};

interface IPropsImportTATransfersBusiness {
	id: string;
	open: boolean;

	handleClose: (
		shouldOpenModalTransferTypeModal?: boolean,
		shouldOpenModalUser?: boolean,
		notOpenModals?: boolean,
		shouldOpenBusinessModal?: boolean
	) => void;
}

let debounceTransferTransactionsMultipleCNPJ: (() => void) & _.Cancelable =
	null;

function ImportTATransfersBusiness({
	id,
	open,
	handleClose
}: IPropsImportTATransfersBusiness) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);

	const { model, returnErrors } = useSelector<
		State,
		ITreasuryAccountTransferState
	>(s => s.treasuryAccountTransfer);

	const handleChange = useCallback(
		(name, value) => {
			dispatch(
				setValue({
					[name]: value
				})
			);
		},
		[dispatch]
	);

	useEffect(() => {
		if (open) {
			dispatch(getBalanceAccountTypes({}));
		}
	}, [dispatch, open]);

	const onSubmitTransferBusiness = e => {
		e.preventDefault();
		setLoadingButton(true);

		if (debounceTransferTransactionsMultipleCNPJ)
			debounceTransferTransactionsMultipleCNPJ.cancel();

		debounceTransferTransactionsMultipleCNPJ = _.debounce(() => {
			const data = model
				.map(c => ({
					...c,
					missingValue: Math.round(Number(c.missingValue))
				}))
				.filter(c => c.missingValue);

			if (data.length === 0) {
				setLoadingButton(false);
				handleClose(false, true);

				return;
			}

			dispatch(
				createTransactionMultiCNPJ(id, model, err => {
					if (err) {
						try {
							let errors = [];
							Object.keys(err._original).map(item => {
								let errUser = err._original[item];
								try {
									if (typeof err._original[item] === "string")
										errUser = JSON.parse(err._original[item]);
								} catch (error) {
									toastError(JSON.stringify(error));
								}

								for (const cnpj of errUser) {
									let error: any = {
										cnpj,
										description: item
									};

									errors.push(error);
								}
								return item;
							});
							handleChange("returnErrors", errors);
							toastWarning(ls.transferSucessPartialBrancher);
							setLoading(false);
							setLoadingButton(false);
						} catch (error) {
							toastError(JSON.stringify(error));
							setLoadingButton(false);
						}
					} else {
						setLoadingButton(false);
						handleClose(false, true);
						if (sumCurrency("missingValue", model) / 100 > 0)
							toastSuccess(ls.transferSucessBrancher);
					}
				})
			);
		}, 1000);

		debounceTransferTransactionsMultipleCNPJ();
	};

	const getFooterProps = () => ({
		style: { color: "#000", fontWeight: "bold" }
	});

	const renderEditable = useCallback(
		cellInfo => (
			<div
				style={{ backgroundColor: "#fafafa" }}
				contentEditable
				suppressContentEditableWarning
				onBlur={e => {
					const string = e.target.innerText;

					const number = Number(string.replace(/\D/g, ""));

					const updatedModel = model.map((row, index) => {
						if (index === cellInfo.index) {
							const updatedRow = { ...row };

							if (number === 0) {
								if (number > 10) {
									updatedRow[cellInfo.column.id] = number;
								} else {
									updatedRow[cellInfo.column.id] = number / 100;
								}
							}
							if (
								String(updatedRow[cellInfo.column.id]).replace(/\D/g, "") ===
								String(number).replace(/\D/g, "")
							)
								return updatedRow;
							updatedRow[cellInfo.column.id] = number;
							return updatedRow;
						}
						return row;
					});

					handleChange("model", updatedModel);
				}}
			>
				<Tooltip title="Edite caso queira enviar o valor total da carga">
					{toStringCurrency(
						Number(model[cellInfo.index][cellInfo.column.id]) / 100
					)}
				</Tooltip>
			</div>
		),
		[model, handleChange]
	);

	const totalTreasuryAccountBalance = useMemo(() => {
		if (model.length > 0) {
			const totalTreasuryAccountBalanceAux =
				model
					.map(i => i.business.treasuryAccountBalance)
					.reduce((p, c) => p + c || 0, 0) / 100;
			return totalTreasuryAccountBalanceAux;
		}

		return 0;
	}, [model]);

	const columnsMultiCNPJ = useMemo(() => {
		const columns = [
			{
				Header: ls.treasuryAccountID,
				id: "treasuryAccount",
				accessor: c => c.business.treasuryAccountHolderId,
				width: 290
			},
			{
				Header: ls.name,
				id: "name",
				accessor: c => c.business.displayName,
				width: 150,
				Footer: `${model.length} Filiais`,
				getFooterProps
			},
			{
				Header: ls.cnpj,
				id: "cnpj",
				accessor: c => c.business.cnpj,
				width: 180
			},
			{
				Header: ls.treasuryAccountBalance,
				id: "treasuryAccountBalance",
				accessor: c =>
					toStringCurrency(c.business.treasuryAccountBalance / 100),
				Footer: toStringCurrency(totalTreasuryAccountBalance),
				getFooterProps,
				width: 150
			},
			// descomentar quando o jonatan subir o backend
			// {
			// 	Header: ls.pendingTransfersValue,
			// 	id: "pendingTransfersValue",
			// 	accessor: c => toStringCurrency(c.pendingTransfersValue / 100),
			// 	Footer: toStringCurrency(
			// 		sumCurrency("pendingTransfersValue", model) / 100
			// 	),
			// 	getFooterProps,
			// 	width: 150
			// },
			{
				Header: ls.totalRequiredValue,
				id: "totalRequiredValue",
				accessor: c => toStringCurrency(c.totalRequiredValue / 100),
				Footer: toStringCurrency(
					sumCurrency("totalRequiredValue", model) / 100
				),
				getFooterProps,
				width: 150
			},
			{
				Header: ls.valueSubtract,
				id: "missingValue",
				accessor: c => c.missingValue / 100,
				Footer: toStringCurrency(sumCurrency("missingValue", model) / 100),
				getFooterProps,
				Cell: renderEditable,
				width: 150
			}
		] as unknown[];

		return columns;
	}, [model, renderEditable, totalTreasuryAccountBalance]);

	if (returnErrors?.length > 0)
		return (
			<Modal
				aria-labelledby="import-user-modal-title"
				aria-describedby="import-user-modal-description"
				className={classes.modal}
				open
				onClose={() => handleChange("returnErrors", [])}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in>
					<div className={classes.paper}>
						<Typography variant="h6">
							As seguintes transferencias não foram importados por causa dos
							seguintes erros
						</Typography>
						<hr />
						<div className={classes.body}>
							<ReactTable
								data={returnErrors}
								columns={
									[
										{
											Header: ls.cnpj,
											accessor: "cnpj",
											width: 200
										},
										{
											Header: ls.description,
											accessor: "description"
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button
									variant="contained"
									onClick={() => {
										handleClose(true);
										handleChange("returnErrors", []);
									}}
								>
									{ls.close}
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);
	if (!open) return null;

	return (
		<Modal
			aria-labelledby="import-user-modal-title"
			aria-describedby="import-user-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose(true)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in>
				<div className={classes.paper}>
					<Typography variant="h6">
						As seguintes filiais foram encontradas na importação
					</Typography>
					<hr />
					<div className={classes.body}>
						{loading ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								<Spinner color="secondary" size="2rem" />
							</div>
						) : (
							<form noValidate onSubmit={onSubmitTransferBusiness}>
								<ReactTable
									data={model}
									columns={columnsMultiCNPJ}
									defaultPageSize={10}
									className="-striped -highlight"
								/>
								<div className={classes.footer}>
									<Button
										variant="contained"
										onClick={() => {
											handleClose(false, false, true);
										}}
									>
										{ls.close}
									</Button>
									<Button
										color="primary"
										variant="contained"
										type="submit"
										disabled={loadingButton}
									>
										{loadingButton ? (
											<Spinner color="secondary" size={16} />
										) : (
											ls.continue
										)}
									</Button>
								</div>
							</form>
						)}
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ImportTATransfersBusiness;
