import * as actions from "store/actions/tax";

import { IRefreshSwap } from "../user/types/refreshSwap";

const initialState: IRefreshSwap = {
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_TAX: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.CREATE_TAX_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_TAX_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
