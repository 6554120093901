import "react-toastify/dist/ReactToastify.min.css";

import {
	Backdrop,
	Button,
	Fade,
	IconButton,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import { removeDiacritics } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { FaFileImport, FaTrash } from "react-icons/fa";
import { MdOutlineCheckCircleOutline } from "react-icons/md";
import { VscError } from "react-icons/vsc";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { userConsultHCM } from "store/actions/user/consultHCM";
import XLSX from "xlsx";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	toast: {
		Width: "100%",
		Height: "100%"
	},
	row: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		minHeight: "350px",
		display: "flex",
		flexDirection: "column",
		paddingLeft: 50,
		paddingTop: 20
	},
	body: {
		padding: theme.spacing(2, 0),
		marginRight: 100
	},
	fileName: {
		color: "#5734D9",
		marginLeft: 5,
		fontWeight: "bold",
		margin: 15,
		display: "flex",
		flexDirection: "row",
		alignItems: "center"
	},
	fileNameError: {
		color: "red",
		marginLeft: 5,
		fontWeight: "bold",
		margin: 15,
		display: "flex",
		flexDirection: "row",
		alignItems: "center"
	},
	footer: {
		display: "flex",
		marginTop: 20
	}
}));

let debounceConsultHCM: (() => void) & _.Cancelable = null;

function ConsultHCM({ open, id, handleClose, handleToast }) {
	const dispatch = useDispatch();
	const [fileName, setFileName] = useState("");
	const [returnErrors, setReturnErrors] = useState([]);
	const [error, setError] = useState(false);
	const [data, setData] = useState([]);

	const handleTrash = useCallback(() => {
		setFileName("");
		setData([]);
		setError(false);
	}, []);

	const classes = useStyles({});

	const inputFile = useRef<HTMLInputElement>(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};
	let arrayErrors = [];

	const handleFileSelected = e => {
		handleTrash();
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const sheetName = wb.SheetNames[0];

			const sheet = wb.Sheets[sheetName];

			let headers = [];
			let range = XLSX.utils.decode_range(sheet["!ref"]);
			let C;
			let R = range.s.r;
			for (C = range.s.c; C <= range.e.c; ++C) {
				let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

				if (cell && cell.t === "s") {
					cell.v = removeDiacritics(cell.v.trim().toLowerCase());
					if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());

					headers.push(removeDiacritics(cell.v.trim().toLowerCase()));
				}
			}

			const cpfHeader = headers.filter(i => i === "cpf");

			if (cpfHeader.length === 0) {
				setFileName("Erro: coluna CPF não encontrada");
				setError(true);
				return;
			}

			const data = XLSX.utils.sheet_to_json(sheet);

			const arrayCPFs = data.map((i, index) => {
				let string = String(i["cpf"])?.match(/\d/g).join("").padStart(11, "0");

				if (i["cpf"] && string.match(/^([0-9]{11})$/)) {
					return string;
				}
				arrayErrors.push({
					description: `CPF não é valido ${i["cpf"]} está na linha ${
						index + 1
					} da planilha`
				});
				return null;
			});

			if (arrayErrors.length > 0) {
				setReturnErrors(arrayErrors);
				return;
			}

			setError(false);
			setFileName(file.name);
			setData(arrayCPFs.filter(i => i));
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	const { loading } = useSelector<any, any>(s => s.userConsultHCM);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceConsultHCM) debounceConsultHCM.cancel();
		debounceConsultHCM = _.debounce(() => {
			dispatch(
				userConsultHCM(id, data, err => {
					if (!err) {
						handleClose();
						handleTrash();
					} else {
						handleClose();
						handleTrash();
						handleToast(err);
					}
				})
			);
		}, 1000);
		debounceConsultHCM();
	};

	if (returnErrors.length > 0)
		return (
			<Modal
				aria-labelledby="import-user-modal-title"
				aria-describedby="import-user-modal-description"
				className={classes.modal}
				open
				onClose={() => setReturnErrors([])}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in>
					<div className={classes.paper}>
						<Typography variant="h6">
							Os seguintes usuários não foram importados por causa dos seguintes
							erros
						</Typography>
						<hr />
						<div className={classes.body}>
							<ReactTable
								data={returnErrors}
								columns={
									[
										{
											Header: ls.description,
											accessor: "description"
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => setReturnErrors([])}>
									{ls.close}
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);

	return (
		<Modal
			aria-labelledby="import-user-modal-title"
			aria-describedby="import-user-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.toast}>
					<div className={classes.paper}>
						<div className={classes.body}>
							<Typography variant="h6" style={{ marginBottom: 10 }}>
								{ls.consultHCM}
							</Typography>
							<Typography variant="subtitle2">
								Utilize esse recurso para pesquisar CPFs de usuários e
							</Typography>
							<Typography variant="subtitle2" style={{ marginBottom: 20 }}>
								descobrir qual cnpj ele está vinculado no cadastro HCM
							</Typography>
							<form noValidate onSubmit={onSubmit}>
								<div className={classes.row}>
									<div>
										<TooltipLight title="Importar usuários" placement="top">
											<IconButton onClick={openSelectFile}>
												<FaFileImport />
											</IconButton>
										</TooltipLight>
										Importar usuários
									</div>
									<div>
										<TooltipLight
											title="Limpar"
											style={{ marginLeft: 10 }}
											placement="top"
										>
											<IconButton onClick={handleTrash}>
												<FaTrash />
											</IconButton>
										</TooltipLight>
										Limpar
									</div>
								</div>
								{fileName && !error && (
									<div className={classes.fileName}>
										{fileName}
										<MdOutlineCheckCircleOutline
											size="23"
											color="#04c456"
											style={{ marginLeft: 5 }}
										/>
									</div>
								)}
								{error && (
									<div className={classes.fileNameError}>
										{fileName}
										<VscError size="23" color="red" style={{ marginLeft: 5 }} />
									</div>
								)}
								<input
									ref={inputFile}
									style={{ display: "none" }}
									type="file"
									accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									onChange={handleFileSelected}
								/>

								<div className={classes.footer}>
									<Button
										variant="contained"
										onClick={() => {
											handleClose();
											handleTrash();
										}}
									>
										{ls.cancel}
									</Button>
									<Button
										color="primary"
										variant="contained"
										type="submit"
										style={{ marginLeft: 20 }}
										disabled={loading || error || data.length === 0}
									>
										{ls.process}
										{loading && <Spinner color="secondary" size={16} />}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ConsultHCM;
