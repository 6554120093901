import { modalAlert } from "Components/Modals/ModalAlert";
import { getErrors } from "helpers/error";
import ls from "Localization";
import * as api from "services/api";

import {
	ActionPayload,
	ActionTypes,
	IAccountHolderTransaction
} from "../types";

export interface IAccountHolderTransactionRequest {
	user_id: string;
	skip?: number;
	take?: number;
	filter?: any;
	sort?: string;
	desc?: boolean;
}

interface IState {
	treasuryAccountTransactionCreate: IAccountHolderTransaction;
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export const getTransfers =
	({
		user_id,
		skip,
		take,
		filter,
		sort = "creationDate",
		desc = true
	}: IAccountHolderTransactionRequest) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_ACCOUNT_HOLDER_TRANSACTION_REQUEST });

		const queryString = new URLSearchParams({
			skip: String(skip),
			take: String(take),
			...(filter || { filters: null }),
			sort,
			desc: desc === true ? "true" : "false"
		}).toString();

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/User/${user_id}/BalanceAccount/Transfer?${queryString}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				const { count = 0 } = result.params || { count: 0 };
				return dispatch({
					type: ActionTypes.GET_ACCOUNT_HOLDER_TRANSACTION_SUCCESS,
					payload: { items: result.model, pages: Math.ceil(count / take || 1) }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.GET_ACCOUNT_HOLDER_TRANSACTION_FAILED
			});
		}
	};
