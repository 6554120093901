import { modalAlert } from "Components/Modals/ModalAlert";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

import { ActionPayload, ActionTypes } from "../../types";

interface ITreasuryAccountWiipoFlexRequest {
	description: string;
	dueDate: string;
	status: number;
	id: string;
}

interface IState {
	treasuryAccountWiipoFlexEdit: ITreasuryAccountWiipoFlexRequest;
	callback(err?: any, model?: any): void;
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: ActionTypes.SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: ActionTypes.CLEAR_VALUES };
}

export const editTransactionStatus =
	(callback, treasuryAccountWiipoFlexEdit) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.EDIT_FUNDS_IN_WIIPOFLEX_REQUEST });

		try {
			const { login } = getState();

			const { id, status, dueDate } = treasuryAccountWiipoFlexEdit;

			let errors: any = {};

			if (!status) errors.status = ls.required;
			if (!id) errors.id = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: ActionTypes.EDIT_FUNDS_IN_WIIPOFLEX_FAILED,
					payload: errors
				});
			}

			const model = {
				id,
				status,
				dueDate
			};

			const response = await api.sendPut(`/Funds/${id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch({
					type: ActionTypes.EDIT_FUNDS_IN_WIIPOFLEX_SUCCESS,
					payload: { item: result.model, params: result.params }
				});
			}
			errors = getErrors(result.errors);

			callback && callback(errors);
			return dispatch({
				type: ActionTypes.EDIT_FUNDS_IN_WIIPOFLEX_FAILED,
				payload: errors
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.EDIT_FUNDS_IN_WIIPOFLEX_FAILED
			});
		}
	};
