import * as actions from "store/actions/consumptionPolicy";

import { IConsumptionPolicy } from "./types";

const initialState: IConsumptionPolicy = {
	items: [],
	storesTiny: [],
	item: null,
	name: "",
	companyId: "",
	categories: [],
	stores: [],
	users: [],
	main: true,
	onBoarding: false,
	limit: 0,
	maxLimit: null,
	maxInstallment: 12,
	minTimeSinceAdmission: 0,
	maxTimeSinceAdmission: null,
	limitType: 0,
	status: 1,
	count: 0,
	pages: 0,
	push: 0,
	showFormModal: false,
	showEditFormModal: false,
	showUserFormModal: false,
	showCategoryFormModal: false,
	showStoreFormModal: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_CONSUMPTION_POLICY: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CONSUMPTION_POLICY_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.name = payload.name ?? initialState.name;
				newState.users = payload.users?.length
					? payload.users
					: initialState.users;
				newState.categories = payload.categories?.length
					? payload.categories
					: initialState.categories;
				newState.stores = payload.stores?.length
					? payload.stores
					: initialState.stores;
				newState.main = payload.main;
				newState.onBoarding = payload.onBoarding;
				newState.limit = payload.limit / 100;
				newState.maxLimit = payload.maxLimit / 100;
				newState.maxLimit = payload.maxLimit ? payload.maxLimit / 100 : null;
				newState.maxInstallment = payload.maxInstallment;
				newState.minTimeSinceAdmission = payload.minTimeSinceAdmission;
				newState.maxTimeSinceAdmission = payload.maxTimeSinceAdmission;
				newState.limitType = payload.limitType ?? initialState.limitType;
				newState.companyId = payload.companyId ?? initialState.companyId;
				newState.status = payload.status ?? initialState.status;
			} else {
				newState.item = null;
				newState.users = initialState.users;
				newState.categories = initialState.categories;
				newState.stores = initialState.stores;
				newState.main = initialState.main;
				newState.onBoarding = initialState.onBoarding;
				newState.limit = initialState.limit;
				newState.maxLimit = initialState.maxLimit;
				newState.maxInstallment = initialState.maxInstallment;
				newState.minTimeSinceAdmission = initialState.minTimeSinceAdmission;
				newState.maxTimeSinceAdmission = initialState.maxTimeSinceAdmission;
				newState.limitType = initialState.limitType;
				newState.companyId = initialState.companyId;
				newState.status = initialState.status;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}
		case actions.GET_CONSUMPTION_POLICY_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}
		case actions.GET_CONSUMPTION_POLICIES: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CONSUMPTION_POLICIES_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_CONSUMPTION_POLICIES_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}
		case actions.GET_BUSINESS_TINY: {
			return {
				...state,
				loading: { getStore: true },
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_TINY_SUCCESS: {
			return {
				...state,
				storesTiny: payload || [],
				loading: { getStore: false }
			};
		}
		case actions.GET_BUSINESS_TINY_FAILED: {
			return {
				...state,
				loading: { getStore: false },
				errors: payload
			};
		}
		case actions.CREATE_CONSUMPTION_POLICY: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_CONSUMPTION_POLICY_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				name: initialState.name,
				users: initialState.users,
				categories: initialState.categories,
				stores: initialState.stores,
				main: initialState.main,
				onBoarding: initialState.onBoarding,
				limit: initialState.limit,
				maxLimit: initialState.maxLimit,
				maxInstallment: initialState.maxInstallment,
				minTimeSinceAdmission: initialState.minTimeSinceAdmission,
				maxTimeSinceAdmission: initialState.maxTimeSinceAdmission,
				limitType: initialState.limitType,
				companyId: initialState.companyId,
				status: initialState.status
			};
		}
		case actions.CREATE_CONSUMPTION_POLICY_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				users: initialState.users,
				categories: initialState.categories,
				stores: initialState.stores,
				main: initialState.main,
				onBoarding: initialState.onBoarding,
				limit: initialState.limit,
				maxLimit: initialState.maxLimit,
				maxInstallment: initialState.maxInstallment,
				minTimeSinceAdmission: initialState.minTimeSinceAdmission,
				maxTimeSinceAdmission: initialState.maxTimeSinceAdmission,
				limitType: initialState.limitType,
				companyId: initialState.companyId,
				status: initialState.status
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_USERS: {
			return {
				...state,
				loading: { users: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_USERS_SUCCESS: {
			return {
				...state,
				loading: { users: false }
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_USERS_FAILED: {
			return {
				...state,
				loading: { users: false },
				errors: payload
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_CATEGORIES: {
			return {
				...state,
				loading: { categories: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_CATEGORIES_SUCCESS: {
			return {
				...state,
				loading: { categories: false }
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_CATEGORIES_FAILED: {
			return {
				...state,
				loading: { categories: false },
				errors: payload
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_STORES: {
			return {
				...state,
				loading: { stores: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_STORES_SUCCESS: {
			return {
				...state,
				loading: { stores: false }
			};
		}
		case actions.UPDATE_CONSUMPTION_POLICY_STORES_FAILED: {
			return {
				...state,
				loading: { stores: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				showFormModal: false,
				showEditFormModal: false,
				name: initialState.name,
				users: initialState.users,
				categories: initialState.categories,
				stores: initialState.stores,
				main: initialState.main,
				onBoarding: initialState.onBoarding,
				limit: initialState.limit,
				maxLimit: initialState.maxLimit,
				maxInstallment: initialState.maxInstallment,
				minTimeSinceAdmission: initialState.minTimeSinceAdmission,
				maxTimeSinceAdmission: initialState.maxTimeSinceAdmission,
				limitType: initialState.limitType,
				companyId: initialState.companyId,
				status: initialState.status
			};
		default: {
			return state;
		}
	}
};

export default reducer;
