import { Button } from "@material-ui/core";
import Report from "Components/Report";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import { toastError } from "helpers/toast";
import ls from "Localization";
import { isObject } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addExtraPropertyTransaction,
	getTransactionDetail,
	getTransactionExtraProperties
} from "store/actions/transaction/detail";

import CreateEditExtraProperty from "./CreateEditExtraProperty";

function ExtraProperties({ id }) {
	const dispatch = useDispatch();

	const [showAddExtraProperties, setShowAddExtraProperties] = useState(false);
	const { extraProperties, transactionDetail, loading } = useSelector<any, any>(
		s => s.transactionDetail
	);

	const toCurrency = [
		"availableBalanceBefore",
		"txnAmount",
		"billingAmountAuthorized",
		"billingAmount"
	];
	const dateFormat = ["addedTime"];

	useEffect(() => {
		dispatch(getTransactionExtraProperties(id));
		dispatch(getTransactionDetail(id));
	}, [dispatch, id]);

	const handleAddExtraProperty = useCallback(
		(name: string, value: string) => {
			dispatch(
				addExtraPropertyTransaction(id, { name, value }, (err, _model) => {
					if (err) {
						toastError(err);
					} else {
						dispatch(getTransactionExtraProperties(id));
						setShowAddExtraProperties(false);
					}
				})
			);
		},
		[dispatch, id]
	);

	let transactionDetailFormatted = [];
	if (transactionDetail) {
		transactionDetailFormatted = Object.keys(transactionDetail).map(key => {
			if (key && !isObject(transactionDetail[key])) {
				return {
					name: key,
					value: transactionDetail[key] ? transactionDetail[key] : ls.notDefined
				};
			}
			if (key && isObject(transactionDetail[key])) {
				Object.keys(transactionDetail[key]).map(item => ({
					name: item,
					value: transactionDetail[item]
						? transactionDetail[item]
						: ls.notDefined
				}));
			}
		});

		transactionDetailFormatted = transactionDetailFormatted.filter(item => {
			if (item) return item;
		});

		transactionDetailFormatted = transactionDetailFormatted.map(item => {
			if (toCurrency.find(data => data === item.name))
				return {
					name: item.name,
					value:
						item.value !== ls.notDefined
							? toStringCurrency(item.value / 100)
							: item.value
				};
			if (dateFormat.find(data => data === item.name))
				return {
					name: item.name,
					value: dayjs(item.value).format(ls.dateFormatWithoutSeconds)
				};

			return item;
		});
	}

	return (
		<>
			<CreateEditExtraProperty
				open={showAddExtraProperties}
				onAdd={handleAddExtraProperty}
				handleClose={() => setShowAddExtraProperties(false)}
			/>
			<Report
				title={ls.extraProperties}
				loading={loading.extraProperties}
				data={[...extraProperties, ...transactionDetailFormatted]}
				PaginationComponent={_ => null}
				headerRightComponent={
					<Button
						color="primary"
						onClick={() => setShowAddExtraProperties(prev => !prev)}
					>
						Adicionar
					</Button>
				}
				visibleColumns={[]}
				defaultPageSize={
					[...extraProperties, ...transactionDetailFormatted]?.length || 1
				}
				columns={[
					{
						Header: ls.name,
						id: "name",
						accessor: c => ls[c.name]
					},
					{
						Header: ls.value,
						id: "value",
						accessor: c => (c.value ? c.value : ls.notDefined)
					}
				]}
			/>
		</>
	);
}

export default ExtraProperties;
