import styled from "styled-components";

export const Container = styled.div`
	margin: 32px;
	display: flex;
	flex-direction: column;
	min-height: 50vh;
	margin-bottom: 40px;
`;

export const BackgroundPage = styled.div`
	border: 1px solid #afafaf;
	border-radius: 16px;
	border-color: #afafaf;
`;

export const Body = styled.div`
	padding: 28px;
	display: flex;
	flex-direction: column;
`;

export const Section = styled.div`
	display: flex;
	flex-direction: column;
	padding: 28px;
	align-items: flex-start;
`;

export const Title = styled.p`
	font: normal normal 600 20px Exo;
	letter-spacing: 0px;
	color: #474747;
	opacity: 1;
`;

export const SubTitle = styled.span`
	font: normal normal normal 16px Exo;
	letter-spacing: 0px;
	color: #808080;
	opacity: 1;
`;

export const RowDetail = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const TextDetail = styled.p`
	font: normal normal medium 18px Exo;
	letter-spacing: 0px;
	color: #474747;
	opacity: 1;
`;

export const RowGray = styled.div`
	background-color: #f6f6f6;
	height: 11px;
	width: 100%;
`;

export const RowStatus = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	font: normal normal medium 18px Exo;
	letter-spacing: 0px;
	color: #474747;
	opacity: 1;

	strong {
		font: normal normal bold 16px Exo;
		letter-spacing: 0px;
		opacity: 1;

		display: flex;
		flex-direction: row;
		align-items: center;

		button {
			cursor: pointer;
			font: normal normal bold 18px/24px Exo;
			letter-spacing: 0px;
			color: #5734da;
			background: none;
		}
	}

	button {
		margin-left: 10px;
		cursor: pointer;
		border: none;
		padding: 10px 16px;
		font: normal normal 600 16px Exo;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
		background: #fe9d2b 0% 0% no-repeat padding-box;
		border-radius: 8px;
		opacity: 1;
	}

	margin-bottom: 10px;
`;

export const BackgroundButton = styled.div`
	margin-left: 10px;
	cursor: pointer;
	font: normal normal bold 18px/24px Exo;
	letter-spacing: 0px;
	color: #5734da;
	background: none;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const RowAddress = styled.div`
	display: flex;
	flex-direction: row;
	p {
		font: normal normal medium 18px/24px Exo;
		letter-spacing: 0px;
		color: #474747;
		opacity: 1;
	}

	margin-bottom: 10px;
`;

export const RowDestiny = styled.div`
	font: normal normal medium 18px/24px Exo;
	letter-spacing: 0px;
	color: #474747;
	opacity: 1;

	margin-bottom: 10px;
`;

export const RowCPFDestiny = styled.div`
	font: normal normal medium 18px/24px Exo;
	letter-spacing: 0px;
	color: #474747;
	opacity: 1;
	margin-bottom: 40px;
`;

export const ButtonRed = styled.button`
	color: #fff;
	padding: 10px;
	background-color: red;
	border-radius: 16px;
	border: none;
	font-size: 18px;
	font-weight: bold;
	width: 200px;
	height: 45px;
`;
