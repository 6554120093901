import * as api from "services/api";
import { getErrors } from "helpers/error";

export const GET_USER_INFO = "[DASHBOARD] GET_USER_INFO";
export const GET_USER_INFO_SUCCESS = "[DASHBOARD] GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILED = "[DASHBOARD] GET_USER_INFO_FAILED";

export const GET_SALE_INFO = "[DASHBOARD] GET_SALE_INFO";
export const GET_SALE_INFO_SUCCESS = "[DASHBOARD] GET_SALE_INFO_SUCCESS";
export const GET_SALE_INFO_FAILED = "[DASHBOARD] GET_SALE_INFO_FAILED";

export const GET_BUSINESS_INFO = "[DASHBOARD] GET_BUSINESS_INFO";
export const GET_BUSINESS_INFO_SUCCESS =
	"[DASHBOARD] GET_BUSINESS_INFO_SUCCESS";
export const GET_BUSINESS_INFO_FAILED = "[DASHBOARD] GET_BUSINESS_INFO_FAILED";

export function getSaleInfo() {
	return async (dispatch, getState) => {
		const { token } = getState().login;

		dispatch({
			type: GET_SALE_INFO
		});

		try {
			let response = await api.sendGet("/sale/info", {
				Authorization: "Bearer " + token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_SALE_INFO_SUCCESS,
					payload: result.model
				});
			} else {
				dispatch({
					type: GET_SALE_INFO_FAILED,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_SALE_INFO_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getUserInfo() {
	return async (dispatch, getState) => {
		const { token } = getState().login;

		dispatch({
			type: GET_USER_INFO
		});

		try {
			let response = await api.sendGet("/user/info", {
				Authorization: "Bearer " + token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_INFO_SUCCESS,
					payload: result.model
				});
			} else {
				dispatch({
					type: GET_USER_INFO_FAILED,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_USER_INFO_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getBusinessInfo() {
	return async (dispatch, getState) => {
		const { token } = getState().login;

		dispatch({
			type: GET_BUSINESS_INFO
		});

		try {
			let response = await api.sendGet("/business/info", {
				Authorization: `Bearer ${token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_INFO_SUCCESS,
					payload: result.model
				});
			} else {
				dispatch({
					type: GET_BUSINESS_INFO_FAILED,
					payload: getErrors(result.errors)
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_BUSINESS_INFO_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
