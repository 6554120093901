import * as actions from "store/actions/transaction/import";

interface IReducerAction {
	type: string;
	payload: any;
}

export interface IInitialState {
	items: Array<any>;
	pages: null;
	count: number;
	value: number;
	discount: number;
	fee: number;
	loading: { import: boolean };
	errors: {};
}

const initialState: IInitialState = {
	items: [],
	pages: null,
	count: 0,
	value: 0,
	discount: 0,
	fee: 0,
	loading: { import: false },
	errors: {}
};

const reducer = (
	state: IInitialState = initialState,
	{ type, payload }: IReducerAction
) => {
	switch (type) {
		case actions.IMPORT_TRANSACTIONS: {
			return {
				...state,
				loading: { import: true },
				errors: initialState.errors
			};
		}
		case actions.IMPORT_TRANSACTIONS_SUCCESS: {
			return {
				...state,
				loading: { import: false },
				items: payload || []
			};
		}
		case actions.IMPORT_TRANSACTIONS_FAILED: {
			return {
				...state,
				loading: { import: false },
				errors: payload || []
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...(payload || [])
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		case actions.VERIFY_IMPORT_TRANSACTIONS: {
			return {
				...state,
				loading: { import: true },
				errors: initialState.errors
			};
		}
		case actions.VERIFY_IMPORT_TRANSACTIONS_SUCCESS: {
			return {
				...state,
				loading: { import: false },
				items: payload || []
			};
		}
		case actions.VERIFY_IMPORT_TRANSACTIONS_FAILED: {
			return {
				...state,
				loading: { import: false },
				errors: payload || []
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
