import dayjs, { Dayjs } from "dayjs";

interface ProspDateFormattedParams {
	filter: {
		value: {
			startDate: string | Dayjs;
			endDate: string | Dayjs;
		};
	};
}

export function excelDateToJSDate(date: number) {
	return new Date(Math.round((date - (25567 + 1)) * 86400 * 1000));
}

export const dateFormatted = (date: Date) => date.toLocaleDateString("pt-BR");

export const TimeFormatted = (date: Date) =>
	`${date.getHours() >= 10 ? `${date.getHours()}` : `0${date.getHours()}`}: ${
		date.getMinutes() >= 10 ? `${date.getMinutes()}` : `0${date.getMinutes()} `
	}`;

export const dateFormattedForParams = (data: ProspDateFormattedParams) => {
	if (data?.filter) {
		const { filter } = data;

		let startDateF = filter?.value?.startDate;

		if (typeof startDateF === "string") {
			startDateF = dayjs(startDateF, { format: "YYYY-MM-DDTHH:mm:ssZ[Z]" });
		}

		let endDateF = filter?.value?.endDate;

		if (typeof endDateF === "string") {
			endDateF = dayjs(endDateF, { format: "YYYY-MM-DDTHH:mm:ssZ[Z]" });
		}

		return { startDateF: startDateF?.format(), endDateF: startDateF?.format() };
	}
	return null;
};

export const dateFormattedSubmit = (
	startDate: dayjs.Dayjs,
	endDate: dayjs.Dayjs
) => {
	let startDateStr = startDate?.format();

	let endDateStr = endDate?.format();

	return { startDate: startDateStr, endDate: endDateStr };
};

export const getFirstBusinessDayOfMonth = (year, month) => {
	const date = new Date(year, month, 9);

	return date;
};

export const getPastMonths = () => {
	const currentDate = new Date();
	const currentMonth = currentDate.getMonth();
	const currentYear = currentDate.getFullYear();

	const result = [];

	for (let i = 3; i <= currentMonth + 1; i++) {
		result.push(new Date(currentYear, i, 1).getMonth() + 1);
	}

	return result;
};
