import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme?: any) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	flexPaper: {
		flex: 1,
		margin: 16,
		minWidth: 350
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

export default useStyles;
