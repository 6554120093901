import { Button, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import { toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/business/detail";
import { getBusinesses } from "store/actions/business/report/branches";
import { State } from "store/reducers";
import { IBusinessBranches } from "store/reducers/business/types/branches";
import { IBusinessDetail } from "store/reducers/business/types/detail";

import AddBranch from "./AddBranch";
import styles from "./styles";

let getBusinessDebounced: (() => void) & _.Cancelable = null;

function Branches() {
	const dispatch = useDispatch();

	const table = useRef(null);

	const [showAdd, setShowAdd] = useState(false);

	const { user } = useSelector<any, any>(s => s.login);

	const { item } = useSelector<State, IBusinessDetail>(s => s.businessDetail);

	const { items, loading, count, pages } = useSelector<
		State,
		IBusinessBranches
	>(s => s.businessReportBranches);

	const isAdmin = isInRole(user, ["admin"]);

	const handleAddUserClose = reload => {
		if (reload) load();

		setShowAdd(false);
	};

	const load = useCallback(() => {
		if (!table.current) return;

		let { page, pageSize, sorted, filtered, toExport, callback } =
			table.current.state;

		if (!sorted) sorted = [];

		dispatch(
			getBusinesses(
				page * pageSize,
				pageSize,
				filtered.reduce(
					(p, c) => `${p}&filters[${c.id}]=${c.value}`,
					`&filters[headquartersId]=${item.id}`
				),
				sorted[0]?.id,
				sorted[0]?.desc,
				toExport,
				callback
			)
		);
	}, [dispatch, item]);

	const handleRemoveBranch = useCallback(
		branchId => {
			dispatch(
				actions.addBranch("", branchId, err => {
					if (err) {
						console.log(err);
						if (err.default) toastWarning(err.default);
						else if (typeof err === "string") toastWarning(err);
						else toastWarning(JSON.stringify(err));
					} else {
						load();
					}
				})
			);
		},
		[dispatch, load]
	);

	const handleFetchData = useCallback(
		tableState => {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (!sorted) sorted = [];

			if (getBusinessDebounced) {
				getBusinessDebounced.cancel();
			}

			getBusinessDebounced = _.debounce(
				() =>
					dispatch(
						getBusinesses(
							page * pageSize,
							pageSize,
							filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								`&filters[headquartersId]=${item.id}`
							),
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getBusinessDebounced();
		},
		[dispatch, item.id]
	);

	const columns = useMemo(() => {
		const columns = [
			{
				Header: ls.name,
				id: "name",
				accessor: c => c.name,
				defaultSortDesc: true,
				show: true,
				width: 250
			},
			{
				Header: ls.socialName,
				id: "socialName",
				accessor: c => c.socialName,
				defaultSortDesc: true,
				show: true,
				width: 250
			},
			{
				Header: ls.phoneNumber,
				id: "phoneNumber",
				accessor: c => c.phoneNumber,
				defaultSortDesc: true,
				width: 170,
				show: true
			},
			{
				Header: ls.cnpj,
				id: "cnpj",
				show: true,
				width: 170,
				accessor: c => c.cnpj
			},
			{
				Header: ls.address,
				id: "address",
				show: true,
				accessor: c => c.address?.fullAddress
			},
			{
				Header: ls.balance,
				id: "treasuryAccountBalance",
				show: true,
				width: 150,
				accessor: c => toStringCurrency(c.treasuryAccountBalance / 100)
			},
			{
				Header: ls.actions,
				id: "actions",
				show: true,
				width: 100,
				accessor: c => (
					<>
						{isAdmin && (
							<div
								style={{
									display: "flex",
									justifyContent: "space-around",
									alignItems: "center",
									flex: 1,
									cursor: "pointer"
								}}
								title="Remover filial"
							>
								<IconButton
									style={{
										padding: 8
									}}
									onClick={() => handleRemoveBranch(c.id)}
								>
									<MdDelete color="red" />
								</IconButton>
							</div>
						)}
					</>
				)
			}
		];

		return columns;
	}, [handleRemoveBranch, isAdmin]);

	if (!item) return null;

	return (
		<div>
			{isAdmin && <AddBranch open={showAdd} handleClose={handleAddUserClose} />}
			<LoadOnScroll load={load}>
				<Report
					manual
					tableRef={table}
					title={`${ls.branches} [${count}]`}
					data={items || []}
					pages={pages}
					loading={loading}
					onFetchData={handleFetchData}
					filterable
					showExport
					visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
					headerRightComponent={
						<>
							{isAdmin && (
								<div>
									<Button color="primary" onClick={() => setShowAdd(true)}>
										{ls.addBranch}
									</Button>
								</div>
							)}
						</>
					}
					defaultFilterMethod={(filter, row) =>
						String(row[filter.id])
							.toLowerCase()
							.indexOf(filter.value.toLowerCase()) > -1
					}
					columns={columns}
					onRowClicked={row => {
						const isStore = [1, 3].includes(row.type);

						const path = isStore ? "store" : "company";

						window.open(`/${path}/${row.id}`, "_blank");
					}}
					defaultSorted={[
						{
							id: "creationDate",
							desc: true
						}
					]}
				/>
			</LoadOnScroll>
			{isAdmin && <AddBranch open={showAdd} handleClose={handleAddUserClose} />}
		</div>
	);
}

export default withStyles(styles, { withTheme: true })(Branches);
