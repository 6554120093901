import * as actions from "store/actions/convenienceFee";

import { IConvenienceFee } from "./types";

const initialState: IConvenienceFee = {
	items: [],
	item: null,
	businessId: "",
	minValue: 0,
	maxValue: 42949672,
	fee: 0,
	showEditFormModal: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_CONVENIENCE_FEE: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CONVENIENCE_FEE_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.minValue = payload.minValue / 100.0;
				newState.maxValue = payload.maxValue / 100.0;
				newState.fee = payload.fee / 100.0;
				newState.businessId = payload.businessId ?? initialState.businessId;
			} else {
				newState.item = null;
				newState.minValue = initialState.minValue;
				newState.maxValue = initialState.maxValue;
				newState.fee = initialState.fee;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}
		case actions.GET_CONVENIENCE_FEE_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}
		case actions.GET_CONVENIENCE_FEES: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_CONVENIENCE_FEES_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_CONVENIENCE_FEES_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}
		case actions.CREATE_CONVENIENCE_FEE: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_CONVENIENCE_FEE_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				minValue: initialState.minValue,
				maxValue: initialState.maxValue,
				fee: initialState.fee
			};
		}
		case actions.CREATE_CONVENIENCE_FEE_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.UPDATE_CONVENIENCE_FEE: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_CONVENIENCE_FEE_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				minValue: initialState.minValue,
				maxValue: initialState.maxValue,
				fee: initialState.fee
			};
		}
		case actions.UPDATE_CONVENIENCE_FEE_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				minValue: initialState.minValue,
				maxValue: initialState.maxValue,
				fee: initialState.fee
			};
		default: {
			return state;
		}
	}
};

export default reducer;
