import { TextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import React, { useRef } from "react";

import ls from "../../Localization";

interface TextInputProps extends Omit<TextFieldProps, "onChange"> {
	id: string;
	name: string;
	value: any;
	hideLabel?: boolean;
	onChange(id: string, value: string): void;
	errors?: any;
}

export function TextInput({
	id,
	name,
	errors,
	onChange,
	value,
	variant = "outlined",
	hideLabel,
	helperText,
	...rest
}: TextInputProps) {
	const inputRef = useRef(null);

	let error = errors ? errors[id] : undefined;

	if (!error && helperText) error = helperText;

	return (
		<TextField
			id={id}
			inputRef={inputRef}
			style={{ zIndex: 0 }}
			label={hideLabel ? undefined : ls[name]}
			fullWidth
			variant={variant as any}
			helperText={error}
			error={!!error}
			placeholder={ls[`${name}Placeholder`]}
			onChange={e => onChange(id, e.target.value)}
			value={value}
			{...rest}
		/>
	);
}

export default TextInput;
