import React, { useState, useCallback } from 'react';
import { render } from 'react-dom';

import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from "@material-ui/core";

interface IModalAlert {
  title: string;
  body: string;
  onClickOk?: () => void;
}

const ModalAlert: React.FC<IModalAlert> = ({
  title,
  body,
  onClickOk,
}) => {

  const [show, setShow] = useState(true);
  
  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClickOk = useCallback(() => {
    if (onClickOk) {
      onClickOk();
    }
    
    handleClose();
  }, [onClickOk, handleClose]);
  
  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
			open={show}
			onClose={handleClose}
		>
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClickOk} autoFocus type="button">
          OK
        </Button>
      </DialogActions>
		</Dialog>
  );
}

export default ModalAlert;

export const modalAlert = ({ 
  title,
  body,
  onClickOk,
  }: Omit<IModalAlert, 'children'>): void => {
  const containerDomNode = document.createElement('div');
  document.body.appendChild(containerDomNode);
  render(
    <ModalAlert 
      title={title} 
      body={body}
      onClickOk={onClickOk}  
    />, containerDomNode);
};