import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const CREATE_LIMIT_CLUB = "[LIMIT_CLUB CREATE] CREATE_BUSINESS";
export const CREATE_LIMIT_CLUB_SUCCESS =
	"[LIMIT_CLUB CREATE] CREATE_LIMIT_CLUB_SUCCESS";
export const CREATE_LIMIT_CLUB_FAILED =
	"[LIMIT_CLUB CREATE] CREATE_LIMIT_CLUB_FAILED";

export const SET_VALUE = "[LIMIT_CLUB CREATE] SET_VALUE";
export const CLEAR_VALUES = "[LIMIT_CLUB CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createLimitClub(
	percentClubWiipo: number,
	id: string,
	callback?: Callback<any>
) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_LIMIT_CLUB });

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/Business/${id}/Club/Limit`,
				{
					PercentClubWiipo: percentClubWiipo
				},
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.status === 200) {
				callback && callback();
				return dispatch({
					type: CREATE_LIMIT_CLUB_SUCCESS,
					payload: {}
				});
			}

			let result = await response.json();

			const errors = getErrors(result.errors);
			callback && callback(errors);
			return dispatch({
				type: CREATE_LIMIT_CLUB_FAILED,
				payload: errors
			});
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: CREATE_LIMIT_CLUB_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
