import styled from "styled-components";

interface PropsLine {
	isPair?: boolean;
	isDisable?: boolean;
}

export const Container = styled.div`
	background: none;
	border: none;
	opacity: 1;
	display: flex;
	flex-direction: column;
	max-height: 186px;
	margin-bottom: 5px;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

export const BackgroundInput = styled.div<PropsLine>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: none;
	padding: 0px 0px;
	width: 100%;
`;

export const Line = styled.input<PropsLine>`
	height: 32px;
	width: 70%;
	background: none;
	opacity: 1;
	text-align: left;
	font-size: 18px;
	letter-spacing: 0px;
	color: #494949;
	opacity: 1;
	${props => (props.isDisable ? "border:none" : "border: 1px solid #D6D6D6;")};
	border-radius: 8px;
	text-align: center;
	margin-right: 10px;

	&:focus {
		outline: none;
	}
`;

export const Button = styled.button`
	background: #5734da 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	border: none;
	width: 31px;
	height: 21px;
	color: #ffffff;
	font: normal normal 600 10px Exo;
	cursor: pointer;
`;
