import { makeStyles } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 20px;
`;

export const BackgroundIcon = styled.div`
	background: #f3edff;
	border-radius: 32px;
	padding: 10px;
	padding-left: 15px;
	padding-bottom: 8px;
	width: 50px;
`;

export const Title = styled.h4`
	text-align: left;
	font: normal normal 600 20px Exo;
	letter-spacing: 0px;
	color: #5734da;
	opacity: 1;
	padding: 0;
	margin: 0;
	margin-top: 20px;
`;

export const RowDates = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 20px;
`;

export const ColumnDates = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const LabelDate = styled.p`
	text-align: left;
	font: normal normal medium 20px/27px Exo;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	margin-right: 20px;
	margin-bottom: 6px;
`;

export const InputDate = styled(DatePicker)`
	margin-right: 40px;
`;

export const useStyles = makeStyles(() => ({
	inputDueDate: {
		height: 20,
		fontSize: 18,
		borderRadius: 16,
		width: 150,
		paddingLeft: 10
	}
}));
