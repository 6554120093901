import { ListItem, ListItemText } from "@material-ui/core";
import React from "react";

interface UserRowProps {
	items: any;
	props: any;
	handleUserChange: (items: any, index: any) => void;
}

const UserRow: React.FC<UserRowProps> = ({
	handleUserChange,
	items,
	props
}) => {
	const { index, style } = props;

	if (!items.length)
		return (
			<ListItem button style={style} key={index}>
				<ListItemText
					style={{
						whiteSpace: "nowrap",
						maxWidth: 400
					}}
					primary="Nenhum item"
				/>
			</ListItem>
		);

	return (
		<ListItem
			button
			style={style}
			key={index}
			onClick={() => handleUserChange(items, index)}
		>
			<ListItemText
				style={{
					whiteSpace: "nowrap",
					maxWidth: 400
				}}
				primary={`${items[index].fullName} - ${items[index].businessName}`}
			/>
		</ListItem>
	);
};

export default UserRow;
