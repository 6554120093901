import { findMimeType } from "./mime";

export function readFile(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		// Read the image via FileReader API and save image result in state.
		reader.onload = function (e) {
			// Add the file name to the data URL
			let dataURL = e.target.result as string;
			dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
			resolve({ file, dataURL });
		};

		reader.readAsDataURL(file);
	});
}

const getNameAndExtension = filename => {
	const nameParts = filename.split(".");

	if (nameParts.length <= 1 || !nameParts[0]) {
		return [filename, ""];
	}

	return [nameParts.slice(0, -1).join("."), nameParts[nameParts.length - 1]];
};

export class CustomFile extends File {
	filename?: string;

	extension?: string;

	mimeType?: string;
}

const addPropsToFile = (file: CustomFile) => {
	const { name } = file;

	const [filename, extension] = getNameAndExtension(name);
	const mimeType = file.type || (extension && findMimeType(extension)) || "";

	file.filename = filename;
	file.extension = extension;
	file.mimeType = mimeType;

	return file;
};

export const handleChangeEvent = (
	event: React.ChangeEvent<HTMLInputElement>
) => {
	if (!event.target || !event.target.files || !event.target.files.length) {
		return null;
	}

	// Transform FileList to array
	const fileList = [...(event.target.files as any)];

	return fileList.map(addPropsToFile);
};
