import { maskCNPJ, PERCENTAGE2 } from "Components/NumberFormat";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import { ExportButtons } from "Components/WiipoClub/Invocing/ExportButtons";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import { uniqueId } from "lodash";
import queryString from "query-string";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
	exportInvoiceClubWiipo,
	getInvoiceClubWiipo
} from "store/actions/invoicingClubeWiipo/report";
import {
	IInvoiceClubeWiipoRequest,
	IInvoiceClubeWiipoState,
	InvoiceClubeWiipoReport,
	TypeExportInvoicingClubeWiipo
} from "store/actions/invoicingClubeWiipo/types";

let getInvoicing: (() => void) & _.Cancelable = null;

const InvoicingClub: React.FC = () => {
	const { search } = useLocation();
	const searchParams: { businessName?: string; cnpj?: string } =
		queryString.parse(search);

	const [loadingExportErp, setLoadingExportErp] = useState<boolean>(false);
	const [loadingExportTransfeera, setLoadingExportTransfeera] =
		useState<boolean>(false);
	const [loadingExportDefault, setLoadingExportDefault] =
		useState<boolean>(false);

	const [reports, setReports] = useState<InvoiceClubeWiipoReport[]>([]);
	const dispatch = useDispatch();
	const table = useRef(null);

	function getCurrentStartDate(): string {
		return dayjs().date() > 20
			? dayjs().date(20).subtract(1, "month").format("YYYY-MM-DD")
			: dayjs().date(20).subtract(2, "month").format("YYYY-MM-DD");
	}

	function getCurrentEndDate() {
		return dayjs().date() < 19
			? dayjs().date(19).subtract(1, "month").format("YYYY-MM-DD")
			: dayjs().date(19).format("YYYY-MM-DD");
	}

	function formatDate(date: string): string {
		return dayjs(date).format("YYYY-MM-DD");
	}

	const [filters, setFilters] = useState<IInvoiceClubeWiipoRequest>({
		skip: 0,
		take: 10,
		startDate: getCurrentStartDate(),
		endDate: getCurrentEndDate(),
		...(searchParams?.businessName && { name: searchParams.businessName }),
		...(searchParams?.cnpj && { name: searchParams.cnpj })
	});

	const invoicingClubeWiipoReport = useSelector<
		{ invoicingClubeWiipo: IInvoiceClubeWiipoState },
		IInvoiceClubeWiipoState
	>(s => s.invoicingClubeWiipo);

	const {
		items,
		pages,
		loading,
		sumUsersWhoConsumedTotal,
		sumCompaniesReachedTotal,
		count
	} = invoicingClubeWiipoReport;

	useEffect(() => {
		setReports(
			items.map(item => {
				item.id = uniqueId("report-");
				item.checked = false;
				return item;
			})
		);
	}, [items]);

	const handleFetchData = tableState => {
		const { page, pageSize, filtered } = tableState;

		if (getInvoicing) getInvoicing.cancel();

		const periodData: { value: { startDate: string; endDate: string } } =
			filtered.find(c => c.id === "periodData");
		const name: { value: string } = filtered.find(c => c.id === "businessName");
		const cnpj: { value: string } = filtered.find(
			c => c.id === "businessDocument"
		);

		getInvoicing = _.debounce(() => {
			const currentFilters = {
				skip: page * pageSize,
				take: pageSize,
				startDate: periodData?.value?.startDate
					? formatDate(periodData.value.startDate)
					: getCurrentStartDate(),
				endDate: periodData?.value?.endDate
					? formatDate(periodData.value.endDate)
					: getCurrentEndDate(),
				...(name?.value && { name: name.value }),
				...(cnpj?.value && { cnpj: cnpj.value })
			};
			setFilters(currentFilters);
			dispatch(getInvoiceClubWiipo(currentFilters));
		}, 500);

		getInvoicing();
	};

	const columns = useMemo(
		() => [
			{
				Header: ls.period,
				id: "periodData",
				accessor: () =>
					`${dayjs(filters.startDate).format("DD/MM")} até
           ${dayjs(filters.endDate).format("DD/MM")}`,
				width: 150,
				show: true,
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				id: "businessName",
				Header: ls.partnerName,
				accessor: "businessName"
			},
			{
				id: "businessDocument",
				Header: ls.cnpj,
				width: 200,
				accessor: (c: InvoiceClubeWiipoReport) => maskCNPJ(c.businessDocument),
				Footer: () => <b>{count}</b>
			},
			{
				id: "fee",
				Header: ls.fee,
				filterable: false,
				accessor: (c: InvoiceClubeWiipoReport) => (
					<PERCENTAGE2
						displayType="text"
						value={c.businessFee ? c.businessFee / 100 : 0}
					/>
				)
			},
			{
				id: "usersWhoConsumed",
				Header: ls.usersWhoConsumed,
				accessor: "usersWhoConsumed",
				filterable: false,
				Footer: () => <b>{sumUsersWhoConsumedTotal}</b>
			},
			{
				id: "companiesReached",
				Header: ls.companiesReached,
				accessor: "companiesReached",
				filterable: false,
				Footer: () => <b>{sumCompaniesReachedTotal}</b>
			},
			{
				id: "sumSaleValue",
				Header: ls.sumSaleValue,
				accessor: (c: InvoiceClubeWiipoReport) =>
					toStringCurrency(c.sumSaleValue / 100),
				filterable: false
			},
			{
				id: "sumSaleTax",
				Header: ls.sumSaleTax,
				accessor: (c: InvoiceClubeWiipoReport) =>
					toStringCurrency(c.sumSaleTax / 100),
				filterable: false
			},
			{
				id: "sumAmount",
				Header: ls.sumAmount,
				accessor: (c: InvoiceClubeWiipoReport) =>
					toStringCurrency(c.sumAmount / 100),
				filterable: false
			},
			{
				id: "sumTakeRate",
				Header: ls.sumTakeRate,
				accessor: (c: InvoiceClubeWiipoReport) =>
					toStringCurrency(c.sumTakeRate / 100),
				filterable: false
			},
			{
				id: "administrateTax",
				Header: ls.admFee,
				accessor: (c: InvoiceClubeWiipoReport) =>
					toStringCurrency(c.administrateTax / 100),
				filterable: false
			},
			{
				id: "sumTransferAmount",
				Header: ls.sumTransferAmount,
				accessor: (c: InvoiceClubeWiipoReport) =>
					toStringCurrency(c.sumTransferAmount / 100),
				filterable: false
			},
			{
				id: "dueDate",
				Header: ls.dueDate,
				accessor: (c: InvoiceClubeWiipoReport) =>
					dayjs(c.dueDate).format("DD/MM/YYYY"),
				filterable: false
			}
		],
		[
			count,
			filters.endDate,
			filters.startDate,
			sumCompaniesReachedTotal,
			sumUsersWhoConsumedTotal
		]
	);

	const onRowClickedCheckBox = useCallback(
		(value: InvoiceClubeWiipoReport, checked: boolean) => {
			const newReports = [...reports];
			const reportFound = newReports.find(i => i?.id === value?.id);

			if (reportFound) reportFound.checked = checked;

			setReports(newReports);
		},
		[reports]
	);

	function handleExport(typeExport: TypeExportInvoicingClubeWiipo) {
		const cnpjs = reports
			.filter(c => c.checked === true)
			.map(c => c.businessDocument);

		dispatch(
			exportInvoiceClubWiipo({
				startDate: filters.startDate,
				endDate: filters.endDate,
				typeExport,
				cnpjs
			})
		);

		setTimeout(() => {
			if (typeExport === TypeExportInvoicingClubeWiipo.erp)
				setLoadingExportErp(false);
			if (typeExport === TypeExportInvoicingClubeWiipo.transfeera)
				setLoadingExportTransfeera(false);
			if (typeExport === TypeExportInvoicingClubeWiipo.default)
				setLoadingExportDefault(false);
		}, 2000);
	}

	return (
		<div>
			<Helmet>
				<title>
					{ls.invoicingClubeWiipo} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				title={ls.invoicingClubeWiipo}
				data={reports}
				tableRef={table}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				enableCheckBox
				onRowClickedCheckBox={onRowClickedCheckBox}
				headerRightComponent={
					<ExportButtons
						erp={{
							status: loadingExportErp,
							onClick: () => {
								setLoadingExportErp(true);
								handleExport(TypeExportInvoicingClubeWiipo.erp);
							}
						}}
						transfeera={{
							status: loadingExportTransfeera,
							onClick: () => {
								setLoadingExportTransfeera(true);
								handleExport(TypeExportInvoicingClubeWiipo.transfeera);
							}
						}}
						default={{
							status: loadingExportDefault,
							onClick: () => {
								setLoadingExportDefault(true);
								handleExport(TypeExportInvoicingClubeWiipo.default);
							}
						}}
					/>
				}
				useQueryString
				loading={loading}
				columns={columns}
				visibleColumns={columns.map(c => c.Header)}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
			/>
		</div>
	);
};

export default InvoicingClub;
