import ModalGeneric from "Components/Modals/ModalGeneric";
import React from "react";

import { Body, Button, Container, RowButtons, TextBody, Title } from "./styles";

interface IPropsModalLog {
	open: boolean;
	handleClose: () => void;
	text: string;
	clickOk: () => void;
}

const ModalReprocess: React.FC<IPropsModalLog> = ({
	open,
	handleClose,
	text,
	clickOk
}) => (
	<ModalGeneric
		handleClose={handleClose}
		open={open}
		stylePaper={{ width: "30% !important" }}
		body={() => (
			<Container>
				<Title>Atenção</Title>
				<Body>
					<TextBody>{text}</TextBody>
				</Body>
				<RowButtons>
					<Button onClick={handleClose}>Cancelar</Button>
					<Button isPurple onClick={clickOk}>
						Continuar
					</Button>
				</RowButtons>
			</Container>
		)}
	/>
);

export default ModalReprocess;
