import { IconButton } from "@material-ui/core";
import { dateFormatted, TimeFormatted } from "helpers/date";
import { toStringCurrency } from "helpers/string";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import SalaryLimitEnum from "Types/SalaryLimitEnum";

import iconCart from "../../../assets/icon-cart-shopping.svg";
import {
	Body,
	Container,
	Header,
	ImageCart,
	RowHeader,
	SubTitleHeader,
	SubTractRed,
	SumGreen,
	TextBold,
	TextGrey,
	TextWhite,
	TitleHeader
} from "./styles";

interface IPropsLimit {
	credit: number;
	limitTotal: number;
	salary: any;
	totalCredit: number;
}

interface IPropsPopover {
	propsLimit: IPropsLimit;
	handlePopoverClose: () => void;
}

const UserDetailPopover: React.FC<IPropsPopover> = ({
	propsLimit,
	handlePopoverClose
}) => {
	const data = new Date(propsLimit.salary?.updateDate);
	const dateFormat = dateFormatted(data);
	const TimeFormat = TimeFormatted(data);

	const { limitTotal } = propsLimit;
	const { base, percentLimit, margin } = propsLimit.salary;
	const dataSalary = {
		[SalaryLimitEnum.Margin]: margin,
		sum: propsLimit.salary?.sum,
		sub: propsLimit.salary?.subtract,
		[SalaryLimitEnum.Base]: base,
		[SalaryLimitEnum.PercentLimit]: percentLimit,
		[SalaryLimitEnum.LimitTotal]: limitTotal
	};

	let count = 1;

	return (
		<Container>
			<Header>
				<RowHeader>
					<TitleHeader>Memória de cálculo limite</TitleHeader>
					<IconButton
						style={{
							position: "absolute",
							right: 0
						}}
						onClick={handlePopoverClose}
					>
						<AiOutlineClose color="#fff" size={12} />{" "}
					</IconButton>
				</RowHeader>
				{propsLimit.salary?.updateDate && (
					<SubTitleHeader>
						Última atualização em {dateFormat} ás {TimeFormat}
					</SubTitleHeader>
				)}
			</Header>
			<Body>
				{Object.keys(dataSalary)?.map(item => {
					count++;
					switch (item) {
						case "Salário liquído":
						case "Salário base":
						case "Limite final":
							if (count % 2 === 0) {
								return (
									<TextWhite key={count}>
										<TextBold>
											{item}: {toStringCurrency(dataSalary[item] / 100)}
										</TextBold>
									</TextWhite>
								);
							}
							return (
								<TextGrey key={count}>
									<TextBold>
										{item}: {toStringCurrency(dataSalary[item] / 100)}
									</TextBold>
								</TextGrey>
							);
						case "Limite setado":
							if (count % 2 === 0) {
								return (
									<TextWhite key={count}>
										<ImageCart src={iconCart} alt="cart" /> Limite setado:{" "}
										{percentLimit / 100}%
									</TextWhite>
								);
							}
							return (
								<TextGrey key={count}>
									<ImageCart src={iconCart} alt="cart" /> Limite setado:{" "}
									{percentLimit / 100}%
								</TextGrey>
							);
						case "sum":
							return dataSalary[item] ? (
								Object.keys(dataSalary[item]).map((i, ind) => {
									if ((count + ind) % 2 === 0) {
										return (
											<TextWhite key={count}>
												<SumGreen>+</SumGreen> {i.toLocaleUpperCase()}:{" "}
												{toStringCurrency(dataSalary[item][i] / 100)}
											</TextWhite>
										);
									}
									return (
										<TextGrey key={count}>
											<SumGreen>+</SumGreen>
											{i.toLocaleUpperCase()}:{" "}
											{toStringCurrency(dataSalary[item][i] / 100)}
										</TextGrey>
									);
								})
							) : (
								<></>
							);

						case "sub":
							return dataSalary[item] ? (
								Object.keys(dataSalary[item]).map((i, ind) => {
									if ((count + ind + 1) % 2 === 0) {
										return (
											<TextWhite key={count}>
												<SubTractRed>-</SubTractRed> {i.toLocaleUpperCase()}:{" "}
												{toStringCurrency(dataSalary[item][i] / 100)}
											</TextWhite>
										);
									}
									return (
										<TextGrey key={count}>
											<SubTractRed>-</SubTractRed>
											{i.toLocaleUpperCase()}:{" "}
											{toStringCurrency(dataSalary[item][i] / 100)}
										</TextGrey>
									);
								})
							) : (
								<></>
							);
						default:
							return <></>;
					}
				})}
			</Body>
		</Container>
	);
};
export default UserDetailPopover;
