import { modalAlert } from "Components/Modals/ModalAlert";
import { getErrors } from "helpers/error";
import ls from "Localization";
import * as api from "services/api";

import {
	ActionPayload,
	ActionTypes,
	ICardUser,
	ICardUserResult
} from "../types";

interface IState {
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

interface IChangeCardStatusProps {
	cardId: string;
	userId: string;
	status: number;
}

export const getCardsFromUserId =
	({ userId }: ICardUser) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_CARD_USER_REQUEST });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/UserCard/User/${userId}/Cards`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				return dispatch({
					type: ActionTypes.GET_CARD_USER_SUCCESS,
					payload: { items: result.model }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.GET_CARD_USER_FAILED
			});
		}
	};

export const changeCardStatusById =
	({ cardId, userId, status }: IChangeCardStatusProps) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({
			type: ActionTypes.CHANGE_STATUS_CARD_USER_REQUEST,
			payload: {
				items: [
					{
						id: cardId,
						userId
					} as ICardUserResult
				]
			}
		});

		try {
			const { login } = getState();

			const response = await api.sendPut(
				`/UserCard/Card/${cardId}/User/${userId}/Status/${status}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				modalAlert({
					title: ls.successTitle,
					body: ls.successMsgGeneric
				});
				return dispatch({
					type: ActionTypes.CHANGE_STATUS_CARD_USER_SUCCESS,
					payload: { items: [{ ...result.model }] }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.CHANGE_STATUS_CARD_USER_FAILED
			});
		}
	};
