import styled from "styled-components";

export const Container = styled.div`
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
`;

export const TextPage = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 40px;
`;

export const Text = styled.p`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-size: 10;
	padding: 0;
	margin: 0;
`;
