import { Checkbox } from "@material-ui/core";
import { PropsSelectCheckBox } from "models/interfaces/components/selectCheckBox";
import React from "react";

import { Container, RowCheckBox } from "./styles";

const SelectCheckBox: React.FC<PropsSelectCheckBox> = ({
	items,
	editItems
}) => (
	<Container>
		{items.length > 0 &&
			items?.map(i => (
				<RowCheckBox key={i.name}>
					<Checkbox
						id="selectAll"
						name="selectAll"
						checked={i.status}
						onChange={() => {
							editItems(i.toPrePaidBalanceAccountTypeId);
						}}
					/>
					<p>{i.name}</p>
				</RowCheckBox>
			))}
	</Container>
);

export default SelectCheckBox;
