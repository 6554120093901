import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_CONSUMPTION_POLICIES =
	"[CONSUMPTION_POLICY] GET_CONSUMPTION_POLICIES";
export const GET_CONSUMPTION_POLICIES_SUCCESS =
	"[CONSUMPTION_POLICY] GET_CONSUMPTION_POLICIES_SUCCESS";
export const GET_CONSUMPTION_POLICIES_FAILED =
	"[CONSUMPTION_POLICY] GET_CONSUMPTION_POLICIES_FAILED";

export const GET_BUSINESS_TINY = "[CONSUMPTION_POLICY] GET_BUSINESS_TINY";
export const GET_BUSINESS_TINY_SUCCESS =
	"[CONSUMPTION_POLICY] GET_BUSINESS_TINY_SUCCESS";
export const GET_BUSINESS_TINY_FAILED =
	"[CONSUMPTION_POLICY] GET_BUSINESS_TINY_FAILED";

export const GET_CONSUMPTION_POLICY =
	"[CONSUMPTION_POLICY] GET_CONSUMPTION_POLICY";
export const GET_CONSUMPTION_POLICY_SUCCESS =
	"[CONSUMPTION_POLICY] GET_CONSUMPTION_POLICY_SUCCESS";
export const GET_CONSUMPTION_POLICY_FAILED =
	"[CONSUMPTION_POLICY] GET_CONSUMPTION_POLICY_FAILED";

export const CREATE_CONSUMPTION_POLICY =
	"[CONSUMPTION_POLICY] CREATE_CONSUMPTION_POLICY";
export const CREATE_CONSUMPTION_POLICY_SUCCESS =
	"[CONSUMPTION_POLICY] CREATE_CONSUMPTION_POLICY_SUCCESS";
export const CREATE_CONSUMPTION_POLICY_FAILED =
	"[CONSUMPTION_POLICY] CREATE_CONSUMPTION_POLICY_FAILED";

export const UPDATE_CONSUMPTION_POLICY =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY";
export const UPDATE_CONSUMPTION_POLICY_SUCCESS =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_SUCCESS";
export const UPDATE_CONSUMPTION_POLICY_FAILED =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_FAILED";

export const UPDATE_CONSUMPTION_POLICY_USERS =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_USERS";
export const UPDATE_CONSUMPTION_POLICY_USERS_SUCCESS =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_USERS_SUCCESS";
export const UPDATE_CONSUMPTION_POLICY_USERS_FAILED =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_USERS_FAILED";

export const UPDATE_CONSUMPTION_POLICY_CATEGORIES =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_CATEGORIES";
export const UPDATE_CONSUMPTION_POLICY_CATEGORIES_SUCCESS =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_CATEGORIES_SUCCESS";
export const UPDATE_CONSUMPTION_POLICY_CATEGORIES_FAILED =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_CATEGORIES_FAILED";

export const UPDATE_CONSUMPTION_POLICY_STORES =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_STORES";
export const UPDATE_CONSUMPTION_POLICY_STORES_SUCCESS =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_STORES_SUCCESS";
export const UPDATE_CONSUMPTION_POLICY_STORES_FAILED =
	"[CONSUMPTION_POLICY] UPDATE_CONSUMPTION_POLICY_STORES_FAILED";

export const SET_VALUE = "[CONSUMPTION_POLICY] SET_VALUE";
export const CLEAR_VALUES = "[CONSUMPTION_POLICY] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getConsumptionPolicies(
	id,
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONSUMPTION_POLICIES });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/ConsumptionPolicy/Business/${id}?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_CONSUMPTION_POLICIES_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CONSUMPTION_POLICIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CONSUMPTION_POLICIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getConsumptionPolicy(id, include, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CONSUMPTION_POLICY });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/ConsumptionPolicy/${id}?include=${include}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_CONSUMPTION_POLICY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CONSUMPTION_POLICY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_CONSUMPTION_POLICY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createConsumptionPolicy(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_CONSUMPTION_POLICY });

		try {
			const { login, consumptionPolicy, businessDetail } = getState();

			const {
				name,
				main,
				limit,
				maxLimit,
				maxInstallment,
				minTimeSinceAdmission,
				maxTimeSinceAdmission,
				limitType
			} = consumptionPolicy;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!limit) errors.limit = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_CONSUMPTION_POLICY_FAILED,
					payload: errors
				});
			}

			const model = {
				name,
				main,
				limit: Math.round(limit * 100),
				maxLimit: Math.round(maxLimit * 100),
				maxInstallment,
				minTimeSinceAdmission,
				maxTimeSinceAdmission,
				limitType,
				companyId: businessDetail.item.id
			};

			const response = await api.sendPost("/ConsumptionPolicy", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_CONSUMPTION_POLICY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_CONSUMPTION_POLICY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_CONSUMPTION_POLICY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editConsumptionPolicy(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CONSUMPTION_POLICY });

		try {
			const { login, consumptionPolicy } = getState();

			const {
				item,
				name,
				main,
				onBoarding,
				limit,
				maxLimit,
				maxInstallment,
				minTimeSinceAdmission,
				maxTimeSinceAdmission,
				limitType
			} = consumptionPolicy;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!limit) errors.limite = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_CONSUMPTION_POLICY_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				name,
				main,
				onBoarding,
				limit: Math.round(limit * 100),
				maxLimit: Math.round(maxLimit * 100),
				maxInstallment,
				minTimeSinceAdmission,
				maxTimeSinceAdmission,
				limitType,
				categories: undefined,
				company: undefined,
				createdBy: undefined,
				stores: undefined,
				updatedBy: undefined,
				historic: undefined,
				users: undefined
			};

			const response = await api.sendPut(
				"/ConsumptionPolicy/" + item.id,
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_CONSUMPTION_POLICY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editConsumptionPolicyUsers(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CONSUMPTION_POLICY_USERS });

		try {
			const { login, consumptionPolicy } = getState();

			const { item } = consumptionPolicy;

			const response = await api.sendPut(
				`/ConsumptionPolicy/${item.id}/Users`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_USERS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_USERS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CONSUMPTION_POLICY_USERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editConsumptionPolicyCategories(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CONSUMPTION_POLICY_CATEGORIES });

		try {
			const { login, consumptionPolicy } = getState();

			const { item } = consumptionPolicy;

			const response = await api.sendPut(
				`/ConsumptionPolicy/${item.id}/Categories`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_CATEGORIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_CATEGORIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CONSUMPTION_POLICY_CATEGORIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editConsumptionPolicyStores(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CONSUMPTION_POLICY_STORES });

		try {
			const { login, consumptionPolicy } = getState();

			const { item } = consumptionPolicy;

			const response = await api.sendPut(
				`/ConsumptionPolicy/${item.id}/Stores`,
				data,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_STORES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_CONSUMPTION_POLICY_STORES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_CONSUMPTION_POLICY_STORES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getStoresTiny(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS_TINY });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Business/Store/Tiny", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
