import ModalSelector from "Components/ModalSelector";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessesCategories } from "store/actions/business/category/report";
import {
	clearValues,
	editConsumptionPolicyCategories,
	setValue
} from "store/actions/consumptionPolicy";

let debounceCategoriesForm: (() => void) & _.Cancelable = null;

function CategoriesForm() {
	const dispatch = useDispatch();

	const { showCategoryFormModal, categories, loading } = useSelector<any, any>(
		c => c.consumptionPolicy
	);

	const categoryReport = useSelector<any, any>(c => c.businessCategoryReport);

	const handleClose = () =>
		dispatch(setValue({ showCategoryFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ categoryId: u.id, status: u.status }));

		if (debounceCategoriesForm) debounceCategoriesForm.cancel();

		debounceCategoriesForm = _.debounce(() => {
			dispatch(
				editConsumptionPolicyCategories(data, err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceCategoriesForm();
	};

	useEffect(() => {
		if (showCategoryFormModal) dispatch(getBusinessesCategories());
	}, [dispatch, showCategoryFormModal]);

	if (!showCategoryFormModal) return null;

	return (
		<ModalSelector
			open={showCategoryFormModal}
			id="edit-consumptionPolicy-category-form"
			loading={loading}
			data={categoryReport.items
				.filter(c => c.name !== "Admin")
				.map(c => ({
					id: c.id,
					name: c.name,
					status: categories.find(d => d.categoryId === c.id && d.status)
						? 1
						: 0
				}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="categories"
		/>
	);
}

export default CategoriesForm;
