import ModalGeneric from "Components/Modals/ModalGeneric";
import React from "react";
import { useSelector } from "react-redux";

import ButtonNotification from "./ButtonNotification";
// import { ButtonRead, Container, Header, TextButtonRead, Title } from "./styles";
import { Container, Header, Title } from "./styles";

interface IPropsModal {
	open: boolean;
	handleClose: () => void;
}

const ModalNotification: React.FC<IPropsModal> = ({ open, handleClose }) => {
	const { items } = useSelector<any, any>(s => s.notificationUser);

	const filterData = items.map(i => ({
		id: i.funds?.code,
		businessName: i.funds?.business?.socialName,
		cnpj: i.funds?.business?.cnpj,
		value: i.funds?.value,
		toStatus: i.toStatus,
		fromStatus: i.fromStatus,
		type: i.funds?.type,
		action: i.action
	}));

	return (
		<ModalGeneric
			open={open}
			handleClose={handleClose}
			stylePaper={{
				width: "35%"
			}}
			styleModal={{
				justifyContent: "end",
				marginRight: 80,
				marginTop: 65,
				alignItems: "initial",
				height: 350
			}}
			body={() => (
				<Container>
					<Header>
						<Title>Notificações</Title>
						{/* <ButtonRead>
							{" "}
							<TextButtonRead>marcar como lido</TextButtonRead>
						</ButtonRead> */}
					</Header>
					{filterData.map((i, index) => (
						<ButtonNotification key={index} object={i} />
					))}
				</Container>
			)}
		/>
	);
};

export default ModalNotification;
