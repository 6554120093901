import { Grid } from "@material-ui/core";
import TextInput from "Components/TextInput";
import React from "react";
import useStyles from "views/Dashboard/Marketing/Create/styles";

import TabPanel from "../TabPanel";

interface SmsTabProps {
	index: number;
	tab: number;
	handleChange: (key: any, value: any) => void;
	errors: any;
	smsMessage: string;
}

const SmsTab: React.FC<SmsTabProps> = ({
	errors,
	handleChange,
	index,
	smsMessage,
	tab
}) => {
	const classes = useStyles({});

	return (
		<TabPanel value={tab} index={index}>
			<Grid container spacing={2} className={classes.form}>
				<Grid item xs={12}>
					<TextInput
						id="smsMessage"
						required
						variant="outlined"
						name="smsMessage"
						value={smsMessage}
						errors={errors}
						multiline
						rows={6}
						onChange={handleChange}
						FormHelperTextProps={{ style: { textAlign: "right" } }}
						helperText={`${smsMessage.length ?? 0}/140`}
					/>
				</Grid>
			</Grid>
		</TabPanel>
	);
};

export default SmsTab;
