import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import installmentPaymentType from "models/installmentPaymentType";
import installmentStatus from "models/installmentStatus";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllowances } from "store/actions/allowance";
import { getCredits } from "store/actions/credit/report";
import { editInstallment, setValue } from "store/actions/installment/edit";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 860,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

interface Props {
	afterEdit?: () => void;
}

let debounceEditInstallment: (() => void) & _.Cancelable = null;

function EditInstallment({ afterEdit }: Props) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [credits, setCredits] = useState([]);
	const [allowances, setAllowances] = useState([]);

	const handleClose = () => {
		dispatch(setValue({ showEdit: false }));
	};

	const {
		item,
		showEdit,
		payDate,
		allowanceId,
		creditId,
		number,
		value,
		fee,
		paymentType,
		status,
		loading,
		errors
	} = useSelector<any, any>(s => s.installmentEdit);

	useEffect(() => {
		if (!showEdit || !item) return;

		if (paymentType === "1") {
			dispatch(
				getCredits(
					0,
					100,
					`&filters[userId]=${item.userId}`,
					"creationDate",
					true,
					true,
					(err, data) => {
						if (err) {
							console.log(err);
						} else {
							setCredits(data);
						}
					}
				)
			);
		}

		if (paymentType === "2") {
			dispatch(
				getAllowances(
					0,
					100,
					`&filters[userId]=${item.userId}`,
					"creationDate",
					true,
					true,
					(err, data) => {
						if (err) {
							console.log(err);
						} else {
							setAllowances(data);
						}
					}
				)
			);
		}
	}, [showEdit, item, paymentType]);

	const handleChange = (name, value) => {
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditInstallment) debounceEditInstallment.cancel();

		debounceEditInstallment = _.debounce(() => {
			dispatch(
				editInstallment(err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						handleClose();
						afterEdit && afterEdit();
					}
				})
			);
		}, 1000);

		debounceEditInstallment();
	};

	return (
		<Modal
			aria-labelledby="edit-installment-modal-title"
			aria-describedby="edit-installment-modal-description"
			className={classes.modal}
			open={showEdit}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showEdit}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editInstallment}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<DatePicker
										// className={clsx(classes.margin, classes.textField)}
										value={payDate}
										fullWidth
										label={ls.paymentDay}
										onChange={date => handleChange("payDate", date)}
										format={ls.dateFormatShort}
										inputVariant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="number"
										required
										variant="outlined"
										name="number"
										value={number}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputProps: {
												step: 1,
												min: 1,
												max: 12
											}
										}}
										type="number"
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="value"
										required
										variant="outlined"
										name="value"
										value={value}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputComponent: MONEY
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="fee"
										required
										variant="outlined"
										name="fee"
										value={fee}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputComponent: MONEY
										}}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											...Object.keys(installmentStatus).map(c => ({
												id: c,
												value: ls[installmentStatus[c]]
											}))
										]}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<Select
										errors={errors}
										id="paymentType"
										name="paymentType"
										value={paymentType}
										required
										onChange={handleChange}
										options={[
											...Object.keys(installmentPaymentType).map(c => ({
												id: c,
												value: ls[installmentPaymentType[c]]
											}))
										]}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4} lg={5}>
									{paymentType === "1" && (
										<Select
											errors={errors}
											id="creditId"
											name="credit"
											value={creditId}
											required
											onChange={handleChange}
											options={credits.map(c => ({
												id: c.id,
												value: `${dayjs(c.creationDate).format(
													ls.dateFormatShort
												)} - Disponível ${toStringCurrency(
													(c.value - c.used) / 100.0
												)}`
											}))}
										/>
									)}

									{paymentType === "2" && (
										<Select
											errors={errors}
											id="allowanceId"
											name="allowance"
											value={allowanceId}
											required
											onChange={handleChange}
											options={allowances.map(c => {
												let allowanceUser = c.users.find(
													u => u.userId === item.userId
												);

												if (!allowanceUser) return null;

												return {
													id: allowanceUser.id,
													value: `${c.name}`
												};
											})}
										/>
									)}
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.edit}
								>
									{ls.save}
									{loading.edit && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditInstallment;
