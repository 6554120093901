import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { getStoresTiny } from "store/actions/credit/detail";

import { setValue } from "store/actions/credit/create";

import ModalSelector from "Components/ModalSelector";

function StoresForm() {
	const dispatch = useDispatch();

	const { storesTiny } = useSelector<any, any>(c => c.creditDetail);
	const { showStoreFormModal, stores, loading } = useSelector<any, any>(
		c => c.creditCreate
	);

	useEffect(() => {
		if (showStoreFormModal) dispatch(getStoresTiny());
	}, [dispatch, showStoreFormModal]);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showStoreFormModal", false);

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ businessId: u.id, status: u.status }));

		handleChange("stores", data);
		handleClose();
	};

	if (!showStoreFormModal) return null;

	return (
		<ModalSelector
			open={showStoreFormModal}
			id={"edit-credit-store"}
			loading={loading}
			data={storesTiny.map(c => ({
				id: c.id,
				name: c.name,
				status: stores.find(d => d.categoryId === c.id && d.status) ? 1 : 0
			}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="stores"
		/>
	);
}

export default StoresForm;
