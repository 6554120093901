import dayjs from "dayjs";
import { getErrors } from "helpers/error";
import { toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_COST_CENTERS = "[COST_CENTER] GET_COST_CENTERS";
export const GET_COST_CENTERS_SUCCESS =
	"[COST_CENTER] GET_COST_CENTERS_SUCCESS";
export const GET_COST_CENTERS_FAILED = "[COST_CENTER] GET_COST_CENTERS_FAILED";

export const GET_COST_CENTER = "[COST_CENTER] GET_COST_CENTER";
export const GET_COST_CENTER_SUCCESS = "[COST_CENTER] GET_COST_CENTER_SUCCESS";
export const GET_COST_CENTER_FAILED = "[COST_CENTER] GET_COST_CENTER_FAILED";

export const CREATE_COST_CENTER = "[COST_CENTER] CREATE_COST_CENTER";
export const CREATE_COST_CENTER_SUCCESS =
	"[COST_CENTER] CREATE_COST_CENTER_SUCCESS";
export const CREATE_COST_CENTER_FAILED =
	"[COST_CENTER] CREATE_COST_CENTER_FAILED";

export const IMPORT_COST_CENTER = "[COST_CENTER] IMPORT_COST_CENTER";
export const IMPORT_COST_CENTER_SUCCESS =
	"[COST_CENTER] IMPORT_COST_CENTER_SUCCESS";
export const IMPORT_COST_CENTER_FAILED =
	"[COST_CENTER] IMPORT_COST_CENTER_FAILED";

export const UPDATE_COST_CENTER = "[COST_CENTER] UPDATE_COST_CENTER";
export const UPDATE_COST_CENTER_SUCCESS =
	"[COST_CENTER] UPDATE_COST_CENTER_SUCCESS";
export const UPDATE_COST_CENTER_FAILED =
	"[COST_CENTER] UPDATE_COST_CENTER_FAILED";

export const UPDATE_COST_CENTER_USERS =
	"[COST_CENTER] UPDATE_COST_CENTER_USERS";
export const UPDATE_COST_CENTER_USERS_SUCCESS =
	"[COST_CENTER] UPDATE_COST_CENTER_USERS_SUCCESS";
export const UPDATE_COST_CENTER_USERS_FAILED =
	"[COST_CENTER] UPDATE_COST_CENTER_USERS_FAILED";

export const DELETE_COST_CENTER = "[COST_CENTER] DELETE_COST_CENTER";
export const DELETE_COST_CENTER_SUCCESS =
	"[COST_CENTER] DELETE_COST_CENTER_SUCCESS";
export const DELETE_COST_CENTER_FAILED =
	"[COST_CENTER] DELETE_COST_CENTER_FAILED";

export const SET_VALUE = "[COST_CENTER] SET_VALUE";
export const CLEAR_VALUES = "[COST_CENTER] CLEAR_VALUES";

export const CANCEL_DOWNLOAD_COST_CENTERS_FAILED =
	"[COST_CENTER CANCEL_DOWNLOAD_COST_CENTERS_FAILED";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getCostCenters(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_COST_CENTERS });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/CostCenter?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_COST_CENTERS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_COST_CENTERS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_COST_CENTERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getCostCenter(id, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_COST_CENTER });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/CostCenter/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_COST_CENTER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_COST_CENTER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_COST_CENTER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createCostCenter(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_COST_CENTER });

		try {
			const { login, costCenter, businessDetail } = getState();

			const { id } = businessDetail.item;

			const { referenceCode, name } = costCenter;

			const errors: any = {};

			if (!referenceCode) errors.referenceCode = ls.required;
			if (!name) errors.name = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_COST_CENTER_FAILED,
					payload: errors
				});
			}

			const model = {
				creationDate: dayjs(),
				referenceCode,
				name,
				businessId: id
			};

			const response = await api.sendPost("/CostCenter", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_COST_CENTER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_COST_CENTER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_COST_CENTER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function importUserToCostCenter(model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_COST_CENTER });

		try {
			const { login, businessDetail } = getState();

			const { id } = businessDetail.item;

			const response = await api.sendPost(
				`/CostCenter/ImportUsers/${id}`,
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_COST_CENTER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_COST_CENTER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_COST_CENTER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function importCostCenter(model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_COST_CENTER });

		try {
			const { login, businessDetail } = getState();

			const { id } = businessDetail.item;

			const response = await api.sendPost(`/CostCenter/import/${id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_COST_CENTER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_COST_CENTER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_COST_CENTER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editCostCenter(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_COST_CENTER });

		try {
			const { login, costCenter } = getState();

			const { item, referenceCode, name } = costCenter;

			const errors: any = {};

			if (!referenceCode) errors.referenceCode = ls.required;
			if (!name) errors.name = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_COST_CENTER_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				referenceCode,
				name,
				users: undefined,
				business: undefined
			};

			const response = await api.sendPut(`/CostCenter/${item.id}`, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_COST_CENTER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_COST_CENTER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_COST_CENTER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editCostCenterUsers(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_COST_CENTER_USERS });

		try {
			const { login, costCenter } = getState();

			const { item } = costCenter;

			const response = await api.sendPut(`/CostCenter/${item.id}/Users`, data, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_COST_CENTER_USERS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_COST_CENTER_USERS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_COST_CENTER_USERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteCostCenter(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_COST_CENTER });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/CostCenter/${id}`, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: DELETE_COST_CENTER_SUCCESS,
					payload: id
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_COST_CENTER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: DELETE_COST_CENTER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const downloadCostCenterUsers =
	(id: string) => async (dispatch, getState) => {
		try {
			const { login } = getState();

			const response = await api.sendGet(`/CostCenter/${id}/Download/Excel`, {
				Authorization: `Bearer ${login.token}`
			});

			const result = await response.json();

			let link = document.createElement("a"); // Create <a>
			link.href = `data:${result.type};base64,${result.content}`;
			link.download = result.filename;
			link.click();
		} catch (error) {
			toastWarning(ls.errorMsgGeneric);

			return dispatch({
				type: CANCEL_DOWNLOAD_COST_CENTERS_FAILED
			});
		}
	};
