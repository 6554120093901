import ModalGeneric from "Components/Modals/ModalGeneric";
import React from "react";

import { Body, Button, Container, RowTax, TextBody, Title } from "./styles";

interface PropsModalTax {
	open: boolean;
	handleClose: () => void;
	businessName?: string;
	cnpj?: string;
	saveData?: () => void;
}

const ModalSave: React.FC<PropsModalTax> = ({
	open,
	handleClose,
	businessName,
	cnpj,
	saveData
}) => (
	<ModalGeneric
		open={open}
		handleClose={() => handleClose()}
		buttonClose
		stylePaper={{
			maxWidth: 667,
			maxHeight: 616,
			padding: 20,
			borderRadius: 16
		}}
		body={() => (
			<Container>
				<Title>Atenção</Title>
				<Body>
					<TextBody>
						Você está alterando as taxas para a empresa {businessName} CNPJ{" "}
						{cnpj} Tem certeza que deseja confirmar essa ação?
					</TextBody>
				</Body>
				<RowTax>
					<Button onClick={handleClose} style={{ marginRight: 20 }}>
						Cancelar
					</Button>
					<Button isPurple onClick={saveData}>
						Salvar
					</Button>
				</RowTax>
			</Container>
		)}
	/>
);

export default ModalSave;
