const idsUsers = [
	"31998649-c8f5-49c7-9e18-08d9b98384d9",
	"0dc45d5c-362c-4a95-ece5-08d9fc451e90",
	"4409396c-b11a-4fcb-0a1a-08da5b66de39",
	"df85ee80-dbe6-4319-f5fa-08daa78ad1ff",
	"e49718fb-f501-478d-37a3-08da15dbe212",
	"9dece3cc-acd3-403a-f6f9-08dac897f1ff"
];

export const verifyOpenEditCPF = (id: string) => {
	const findItem = idsUsers.find(i => i === id);
	return Boolean(findItem);
};
