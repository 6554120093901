import * as actions from "store/actions/campaign/list";
import { Campaign } from "Types/Campaign";

export interface CampaignListState {
	item: Campaign;
	items: Campaign[];
	loading: { get: boolean; delete: boolean };
	errors: any;
}

const initialState = {
	item: null,
	items: [],
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_CAMPAIGN: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_CAMPAIGN_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload
			};
		}
		case actions.GET_CAMPAIGN_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.GET_CAMPAIGNS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_CAMPAIGNS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				items: payload
			};
		}
		case actions.GET_CAMPAIGNS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.DELETE_CAMPAIGN: {
			return {
				...state,
				loading: {
					...state.loading,
					delete: true
				},
				errors: initialState.errors
			};
		}
		case actions.DELETE_CAMPAIGN_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					delete: false
				}
			};
		}
		case actions.DELETE_CAMPAIGN_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					delete: false
				},
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
