import "react-datepicker/dist/react-datepicker.css";

import ptBR from "date-fns/locale/pt-BR";
import dayjs, { Dayjs } from "dayjs";
import { toastWarning } from "helpers/toast";
import React, { useCallback, useEffect, useMemo } from "react";
import { registerLocale } from "react-datepicker";

import Header from "./Header";
import {
	ButtonCancel,
	ButtonOk,
	DatePickerContainer,
	RowButtons,
	StyledHeader,
	TextButton,
	TextButtonOk,
	TextErrorDate
} from "./styles";

registerLocale("pt-BR", ptBR);

interface PropsComponent {
	date: Dayjs;
	placeholder: string;
	onChange: (date: Date) => void;
	dateFormaTime?: boolean;
	openTime?: boolean;
	excludeDates?: Date[];
	startDateVerify: boolean;
	minDate?: Date;
	open: boolean;
	handleOpen: () => void;
	handleClose: () => void;
	admin?: boolean;
}

const DateTimePicker: React.FC<PropsComponent> = ({
	onChange,
	placeholder,
	date,
	startDateVerify,
	admin,
	excludeDates,
	open,
	handleOpen,
	handleClose,
	openTime,
	dateFormaTime,
	minDate
}) => {
	const startDate = useMemo(
		() => (date ? new Date(dayjs(date).toDate()) : null),
		[date]
	);

	const minTime = useMemo(() => {
		const now = new Date();
		if ((startDate && startDate?.getDate() === now.getDate()) || !startDate) {
			return dayjs().add(30, "minute").startOf("minute").toDate();
		}
		return dayjs().startOf("day").toDate();
	}, [startDate]);

	const maxTime = useMemo(() => dayjs().endOf("day").toDate(), []);

	const verifyToast = useCallback(() => {
		const finishDate = new Date("2023-04-26T23:59:59Z");
		const now = new Date();

		if (now <= finishDate && open && !admin) {
			toastWarning(
				"Entre os dias 24/04 e 26/04 estaremos em manutenção, por favor agende sua recarga para dia 27/04 em diante"
			);
		}
	}, [admin, open]);

	useEffect(() => {
		verifyToast();
	}, [verifyToast]);

	return (
		<StyledHeader>
			<DatePickerContainer
				excludeDates={excludeDates}
				open={open}
				onInputClick={handleOpen}
				selected={startDate}
				onChange={onChange}
				shouldCloseOnSelect
				showTimeSelect={openTime}
				renderCustomHeader={props => <Header props={props} />}
				minDate={minDate || minTime}
				minTime={minTime}
				maxTime={maxTime}
				placeholderText={placeholder}
				dateFormat={dateFormaTime ? "dd/MM/yyyy - HH:mm:ss" : "dd/MM/yyyy"}
				timeFormat="HH:mm"
				timeCaption="Horário"
				timeIntervals={30}
				locale="pt-BR"
				calendarClassName="custom-calendar"
			>
				{!startDateVerify && startDate && openTime && (
					<TextErrorDate>Escolha um horário</TextErrorDate>
				)}
				<RowButtons isDisable={!startDateVerify}>
					<ButtonCancel type="button" disabled={false} onClick={handleClose}>
						<TextButton>Cancel</TextButton>
					</ButtonCancel>
					<ButtonOk
						type="button"
						disabled={!startDateVerify}
						onClick={handleClose}
					>
						<TextButtonOk isDisable={!startDateVerify}>Ok</TextButtonOk>
					</ButtonOk>
				</RowButtons>
			</DatePickerContainer>
		</StyledHeader>
	);
};

export default DateTimePicker;
