import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import dayjs from "dayjs";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	sendIntegrationEmail,
	setValue
} from "store/actions/integrations";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 400,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceSendEmail: (() => void) & _.Cancelable = null;

function SendEmail() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { item: business } = useSelector<any, any>(s => s.businessDetail);
	const { showSendMailFormModal, emailTo, from, to, errors, loading } =
		useSelector<any, any>(c => c.integrations);

	useEffect(() => {
		if (showSendMailFormModal) {
			const renewalDay = business.config.renewalDay || 20;

			let f = dayjs();
			let t = dayjs();

			if (f.date() < renewalDay) f = f.subtract(1, "month");
			else t = t.add(1, "month");

			f = f.set("date", renewalDay);
			t = t.set("date", renewalDay - 1);

			dispatch(setValue({ from: f, to: t }));
		}
	}, [showSendMailFormModal, dispatch, business]);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceSendEmail) debounceSendEmail.cancel();

		debounceSendEmail = _.debounce(() => {
			dispatch(
				sendIntegrationEmail(err => {
					if (err) {
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
						alert("Enviado com sucesso");
					}
				})
			);
		}, 1000);

		debounceSendEmail();
	};

	const handleChange = (id: string, value: any) => {
		const values = { [id]: value };

		dispatch(setValue(values));
	};

	const handleClose = () => handleChange("showSendMailFormModal", false);

	return (
		<Modal
			aria-labelledby="send-email-modal-title"
			aria-describedby="send-email-modal-description"
			className={classes.modal}
			open={showSendMailFormModal}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showSendMailFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.sendEmail}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography>Periodo:</Typography>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										format={ls.dateFormatShort}
										label="De:"
										value={from}
										onChange={v => handleChange("from", v)}
										inputVariant="outlined"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										format={ls.dateFormatShort}
										label="Até:"
										value={to}
										onChange={v => handleChange("to", v)}
										inputVariant="outlined"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography>Enviar para:</Typography>
								</Grid>
								<Grid item xs={12}>
									<TextInput
										id="emailTo"
										required
										variant="outlined"
										name="emailTo"
										value={emailTo}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button color="primary" variant="contained" type="submit">
									{ls.send}{" "}
									{loading.send && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default SendEmail;
