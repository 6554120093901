import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Recaptcha = React.forwardRef<any, any>((props, ref) => (
	<ReCAPTCHA
		ref={ref as any}
		sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
		translate="yes"
		onChange={token => props.change(token)}
	/>
));

export default Recaptcha;
