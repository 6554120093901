import ButtonCopyClipboard from "Components/ButtonCopyClipboard";
import dayjs from "dayjs";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import requestCardsStatus from "models/requestCardsStatus";
import serviceStatus from "models/serviceStatus";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { CancelRequestCards } from "store/actions/requestCards/detail";
import { requestItems } from "store/reducers/requestCards/types";

import {
	BackgroundButton,
	BackgroundPage,
	Body,
	ButtonRed,
	Container,
	RowAddress,
	RowCPFDestiny,
	RowDestiny,
	RowDetail,
	RowGray,
	RowStatus,
	Section,
	SubTitle,
	TextDetail,
	Title
} from "./styles";

interface ItemProps {
	item: requestItems;
	id: string;
}

let cancelCardDelivery: (() => void) & _.Cancelable = null;

const Request: React.FC<ItemProps> = ({ item, id }) => {
	const dispatch = useDispatch();
	const {
		creationDate,
		createdBy,
		business,
		quantityCard,
		status,
		statusDescription,
		trackingCode,
		street,
		number,
		neighborhood,
		city,
		state,
		name,
		document,
		zipcode,
		service,
		requestId
	} = item;

	const handleFetchCardDelivery = useCallback(() => {
		if (cancelCardDelivery) {
			cancelCardDelivery.cancel();
		}

		cancelCardDelivery = _.debounce(
			() =>
				dispatch(
					CancelRequestCards(requestId, err => {
						if (err) {
							toastError(JSON.stringify(err));
						} else {
							toastSuccess("Seu pedido foi cancelado com sucesso");
						}
					})
				),
			500
		);

		cancelCardDelivery();
	}, [dispatch, requestId]);

	return (
		<Container>
			<BackgroundPage>
				<Body>
					<Title>Pedido #{id}</Title>
					<SubTitle style={{ marginTop: 10 }}>
						Solicitado em: {dayjs(creationDate).format("DD/MM/YYYY  HH:mm:ss")}
					</SubTitle>
					<SubTitle style={{ marginBottom: 20 }}>
						Solicitado por: {createdBy?.fullName || ls.notDefined}
					</SubTitle>

					<RowDetail>
						<TextDetail style={{ marginRight: 40 }}>
							Empresa: {business?.displayName || ls.notDefined}
						</TextDetail>
						<TextDetail style={{ marginRight: 40 }}>
							CNPJ: {business?.cnpj || ls.notDefined}
						</TextDetail>
						<TextDetail>Quantidade: {quantityCard} cartões</TextDetail>
					</RowDetail>
				</Body>
				<RowGray />
				<Section>
					<RowStatus>
						<p style={{ marginRight: 40 }}>
							Status: {ls[requestCardsStatus[status]]}
						</p>
						{statusDescription && (
							<p style={{ marginRight: 40 }}>
								Rastreamento: {statusDescription}
							</p>
						)}
					</RowStatus>
					<RowStatus>
						<strong style={{ marginRight: 40 }}>
							Código de rastreio:
							<BackgroundButton>
								{trackingCode}
								<ButtonCopyClipboard valueCopy={trackingCode} />
							</BackgroundButton>
						</strong>
						<span style={{ marginRight: 40 }}>
							Tipo de entrega: {ls[serviceStatus[service]]}
							{/* <button type="button">Retificar endereço</button> */}
						</span>
					</RowStatus>
					<RowAddress>
						<p>
							Endereço de entrega: R. {street}, {number} - {neighborhood},{" "}
							{city}- {state}, CEP {zipcode}.{" "}
						</p>
					</RowAddress>
					<RowDestiny>Destinatário: {name}</RowDestiny>
					<RowCPFDestiny>CPF do destinatário: {document}</RowCPFDestiny>
				</Section>
				{requestId && (
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "flex-end",
							padding: 26
						}}
					>
						<ButtonRed onClick={handleFetchCardDelivery} type="button">
							Cancelar Pedido
						</ButtonRed>
					</div>
				)}
			</BackgroundPage>
		</Container>
	);
};

export default Request;
