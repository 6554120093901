import Report from "Components/Report";
import SwitchGeneric from "Components/SwitchGeneric";
import EditLine from "Components/views/Dashboard/Business/Detail/Tax/EditLine";
import ModalTax from "Components/views/Dashboard/Business/Detail/Tax/ModalTax";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createTax } from "store/actions/tax";

import { ButtonTaxClub } from "./styles";

export interface Bags {
	externalId: string;
	name: string;
	description: any;
	icon: any;
	banner: any;
	businesses: Business[];
	businessTransferPolicies: BusinessTransferPolicy[];
	prePaidBalanceFees: PrePaidBalanceFee[];
	id: string;
	creationDate: string;
	updateDate: string;
	deleted: boolean;
	deletedBy: any;
	deleteDate: any;
}
interface PrePaidBalanceFee {
	configurationId: string;
	configuration: any;
	prePaidBalanceAccountTypeId: string;
	prePaidBalanceAccountType: any;
	limitRescueFee: number;
	id: string;
	creationDate: string;
	updateDate: string;
	deleted: boolean;
	deletedBy: any;
	deleteDate: any;
}
interface Business {
	prePaidBalanceAccountTypeId: string;
	businessId: string;
	business: any;
	status: number;
	id: string;
	creationDate: string;
	updateDate: string;
	deleted: boolean;
	deletedBy: any;
	deleteDate: any;
}

interface BusinessTransferPolicy {
	prePaidBalanceAccountTypeId: string;
	businessId: string;
	business: any;
	status: number;
	id: string;
	creationDate: string;
	updateDate: string;
	deleted: boolean;
	deletedBy: any;
	deleteDate: any;
}

let debounceEditTax: (() => any) & _.Cancelable = null;

const Tax = ({
	id,
	isExistHeadquarter,
	isHeadquarter,
	cnpj,
	name,
	taxs,
	filterActive,
	handleUpdateLoadFilter,
	loading
}) => {
	const dispatch = useDispatch();
	const table = useRef(null);

	const [openModal, setOpenModal] = useState(false);

	const handleOpen = useCallback(() => {
		setOpenModal(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpenModal(false);
	}, []);

	const saveDataTax = useCallback(
		item => {
			if (debounceEditTax) debounceEditTax.cancel();
			debounceEditTax = _.debounce(() => {
				dispatch(
					createTax(id, item, err => {
						if (err) {
							toastError(err);
						} else {
							toastSuccess("Salvo com sucesso");
							handleClose();
						}
					})
				);
			}, 500);

			debounceEditTax();
		},
		[dispatch, handleClose, id]
	);

	const [changeData, setChangeData] = useState([]);

	const existValueLength = useCallback(existValue => {
		if (existValue !== undefined && existValue !== null) {
			return Object.keys(existValue ?? {})?.length > 0;
		}
		return false;
	}, []);

	const filterTaxs = useCallback(
		(isFilter: boolean) => {
			const existValue = changeData.length > 0 ? changeData : [taxs];
			const existValueFirst =
				Boolean(existValue) && existValue.length > 0
					? existValueLength(existValue[0])
					: false;

			if (isFilter && existValueFirst) {
				return Object.keys(existValue[0])
					.filter(i => i.indexOf("Type") < 0)
					.filter(
						i =>
							i !== "creditCardFee" &&
							i !== "creditCardInstallmentFee" &&
							i !== "prePaidBalanceFees"
					)
					.filter(i => taxs[i] !== null)
					.map(i => ({ [i]: taxs[i] }))
					.filter(
						item =>
							(Object.values(item)[0] || Object.values(item)[0] === 0) &&
							!Number.isNaN(Number(Object.values(item)[0]))
					)
					.filter(item => !Number.isNaN(Object.values(item)[0]));
			}

			return existValue.length > 0
				? existValue.filter(i => i !== undefined)
				: [];
		},
		[changeData, existValueLength, taxs]
	);

	const editChangeData = useCallback(
		(id: string, value: string) => {
			const newChangeData = [...filterTaxs(false)];

			let findItem = newChangeData.find(i => i[id] || i[id] === 0);
			if (findItem) {
				findItem[id] = Number(value.replace(/[^0-9]/g, ""));
			}

			setChangeData(newChangeData);
		},
		[filterTaxs]
	);

	const returnTypeTax = useCallback(
		id => {
			const item =
				filterTaxs(false).length > 0
					? Object.keys(filterTaxs(false)[0]).map(j => {
							if (j === `${id}Type`) {
								return taxs[j];
							}
					  })
					: [];

			const removeNull = item?.find(i => i === 1 || i === 0);

			return item.length > 0 ? removeNull : 0;
		},
		[filterTaxs, taxs]
	);

	const isDisableSelected = useCallback(
		(title: string) => {
			if (title === "inactivityFee") return true;
			if (filterActive) return true;
			return false;
		},
		[filterActive]
	);

	return (
		<>
			<Report
				tableRef={table}
				loading={loading}
				key="balance-account-type-report"
				title={ls.taxes}
				headerRightComponent={
					<>
						{(isExistHeadquarter || (isHeadquarter && filterActive)) && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									paddingRight: 20
								}}
							>
								<SwitchGeneric
									active={filterActive}
									changeActive={() => {
										handleUpdateLoadFilter(!filterActive);
									}}
								/>
								Usar configurações da matriz
							</div>
						)}
					</>
				}
				data={filterTaxs(true)}
				showPagination={false}
				visibleColumns={[]}
				pageSizeOptions={[1]}
				pageSize={10}
				columns={[
					{
						Header: ls.description,
						id: "description",
						accessor: c => <>{ls[Object.keys(c)[0]] || Object.keys(c)[0]}</>
					},
					{
						Header: ls.type,
						id: "typeTax",
						accessor: c => {
							const title = Object.keys(c)[0];
							const value = c[title];
							return title === "limitRescueFee" ? (
								<></>
							) : (
								<EditLine
									disabledEdit={isDisableSelected(title)}
									returnTypeTax={returnTypeTax}
									saveDataTax={saveDataTax}
									cnpj={cnpj}
									item={c}
									isSelect
									value={value}
									key={c.id}
									editItem={editChangeData}
								/>
							);
						},
						width: 200
					},
					{
						Header: ls.percentageValue,
						id: "percentage",
						accessor: c => {
							const title = Object.keys(c)[0];
							const value = c[title];

							return title === "limitRescueFee" ? (
								<div
									style={{
										width: "100%",
										display: "flex",
										justifyContent: "center"
									}}
								>
									<ButtonTaxClub onClick={handleOpen} type="button">
										Ver Taxas
									</ButtonTaxClub>
								</div>
							) : (
								<EditLine
									returnTypeTax={returnTypeTax}
									disabledEdit={filterActive}
									saveDataTax={saveDataTax}
									item={c}
									value={value}
									key={c.id}
									editItem={editChangeData}
								/>
							);
						}
					}
					// {
					// 	Header: ls.value,
					// 	id: "value",
					// 	accessor: c => {
					// 		const title = Object.keys(c)[0];
					// 		const value = c[title];

					// 		return title === "limitRescueFee" ? (
					// 			<div
					// 				style={{
					// 					width: "100%",
					// 					display: "flex",
					// 					justifyContent: "center"
					// 				}}
					// 			>
					// 				<ButtonTaxClub onClick={handleOpen} type="button">
					// 					Ver Taxas
					// 				</ButtonTaxClub>
					// 			</div>
					// 		) : (
					// 			<EditLine
					// 				saveDataTax={saveDataTax}
					// 				item={c}
					// 				value={value}
					// 				key={c.id}
					// 				editItem={editChangeData}
					// 			/>
					// 		);
					// 	}
					// },
					// {
					// 	Header: ls.payer,
					// 	id: "businessesPolicy",
					// 	accessor: c => <>{ls[Object.keys(c)[0]] || Object.keys(c)[0]}</>
					// }
				]}
			/>
			{openModal && (
				<ModalTax
					open={openModal}
					disabledEdit={filterActive && !isHeadquarter}
					handleClose={handleClose}
					cnpj={cnpj}
					name={name}
					id={id}
				/>
			)}
		</>
	);
};

export default Tax;
