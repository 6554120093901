import TabsTable from "Components/TabsTable";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearValues } from "store/actions/treasuryAccountWiipoFlex/create";
import * as actions from "store/actions/treasuryAccountWiipoFlex/report";
import { ITreasuryAccountTransferRequest } from "store/actions/treasuryAccountWiipoFlex/report";

import Create from "../Create";
import TreasuryAccountWiipoFlexReportDeposit from "./ReportDeposit";
import TreasuryAccountWiipoFlexReportTransfer from "./ReportTransfer";

dayjs.extend(customParseFormat);

function TreasuryAccountWiipoFlexReport() {
	const dispatch = useDispatch();

	const handleGetTransfers = useCallback(
		(props: ITreasuryAccountTransferRequest) => {
			const { skip, take, filter, sort, desc, toExport, callback } = props;
			dispatch(
				actions.getTransactionFunds({
					skip,
					take,
					filter,
					sort,
					desc,
					toExport,
					callback
				})
			);
		},
		[dispatch]
	);
	const { user } = useSelector<any, any>(s => s.login);
	const isAdmin = isInRole(user, ["admin"]);
	const arrayTabs = useMemo(
		() => [
			{
				label: "Depósitos",
				table: () => (
					<TreasuryAccountWiipoFlexReportDeposit
						handleGetTransfers={handleGetTransfers}
					/>
				)
			},
			{
				label: "Extrato",
				table: () => <TreasuryAccountWiipoFlexReportTransfer />
			}
		],
		[handleGetTransfers]
	);

	useEffect(() => {
		handleGetTransfers({ callback: () => {} });
		dispatch(clearValues());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Create handleGetTransfers={handleGetTransfers} />
			<TabsTable
				tabs={
					isAdmin ? arrayTabs : arrayTabs.filter(i => i.label !== "Extrato")
				}
			/>
		</>
	);
}

export default TreasuryAccountWiipoFlexReport;
