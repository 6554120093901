import * as actions from "store/actions/credit/edit";

import { IEditCredit } from "../types/edit";

const initialState: IEditCredit = {
	item: null,
	companyId: "",
	company: null,
	expirationDate: null,
	startDate: null,
	value: 0,
	minValue: 0,
	userId: "",
	user: null,
	status: 1,
	description: "",
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.EDIT_CREDIT: {
			return {
				...state,
				loading: { edit: true },
				errors: initialState.errors
			};
		}
		case actions.EDIT_CREDIT_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.EDIT_CREDIT_FAILED: {
			return {
				...state,
				loading: { edit: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
