import { ClickAwayListener, Menu, MenuItem, MenuList } from "@material-ui/core";
import ls from "Localization";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { downloadReport } from "store/actions/treasuryAccountWiipoFlex/report";

interface ReportDepositMenuProps {
	anchorEl: Element;
	openMenu: boolean;
	onClickEdit: () => void;
	fundId: string;
	onClickAway: () => void;
	type: 0 | 1;
	shouldShowEdit?: boolean;
}

const ReportDepositMenu: React.FC<ReportDepositMenuProps> = ({
	anchorEl,
	openMenu,
	onClickEdit,
	fundId,
	onClickAway,
	type,
	shouldShowEdit
}) => {
	const dispatch = useDispatch();

	const onClickDownloadReport = useCallback(() => {
		dispatch(downloadReport(fundId));
	}, [fundId, dispatch]);
	return (
		<Menu
			transformOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			style={{ boxShadow: "0 5px 10px -2px rgba(0,0,0,.16)" }}
			anchorEl={anchorEl}
			open={openMenu}
		>
			<ClickAwayListener onClickAway={onClickAway}>
				<MenuList
					autoFocusItem={openMenu}
					id="composition-menu"
					aria-labelledby="composition-button"
				>
					{shouldShowEdit && (
						<MenuItem onClick={onClickEdit}>{ls.edit}</MenuItem>
					)}

					{type === 1 && (
						<MenuItem onClick={onClickDownloadReport}>
							Baixar relatório
						</MenuItem>
					)}
				</MenuList>
			</ClickAwayListener>
		</Menu>
	);
};
export default ReportDepositMenu;
