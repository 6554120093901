/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	FormHelperText,
	Grid,
	Paper,
	Typography
} from "@material-ui/core";
import Checkbox from "Components/Checkbox";
import { CEP, CPFCNPJ } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import Switch from "Components/Switch";
import TextInput from "Components/TextInput";
import { removeDiacritics } from "helpers/string";
import { toastError } from "helpers/toast";
import { AsYouType } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import {
	FaCheckCircle,
	FaEye,
	FaFileSignature,
	FaGlobe,
	FaHandshake,
	FaListAlt,
	FaShoppingBag
} from "react-icons/fa";
import { MdRestaurant } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessesCategories } from "store/actions/business/category/report";
import {
	clearValues,
	editBusiness,
	getBusiness,
	setValue
} from "store/actions/business/edit";

import { transformLinkType } from "./helpers/transformLinkType";
import useStyles, { GridCheckboxHCM, LabelCheckboxHCM } from "./styles";

let debounceEditBusiness: (() => void) & _.Cancelable = null;

export function RenderBusinessCreate({ history, match }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const [hasHCM, setHasHCM] = useState(false);
	const [wiipoFlex, setWiipoFlex] = useState(false);
	const [wiipoClub, setWiipoClub] = useState(false);

	useEffect(() => {
		dispatch(getBusinessesCategories());
	}, []);

	useEffect(() => {
		dispatch(
			getBusiness(match.params.id, (err, model) => {
				if (err) {
					toastError(`Erro ao carregar => ${err}`);
				} else {
					setHasHCM(model.hasHCMIntegrator);
					setWiipoFlex(model.hasWiipoFlex);
					setWiipoClub(model.hasWiipoClub);
				}
			})
		);
	}, [match.params.id]);

	const {
		name,
		socialName,
		cnpj,
		phoneNumber,
		street,
		number,
		complement,
		neighborhood,
		city,
		state,
		linkType,
		link,
		zipCode,
		country,
		category,
		status,
		about,
		teaser,
		highlight,
		national,
		hideContactInfo,
		hideLocationInfo,
		isStore,
		isCompany,
		isHeadquarters,
		isFranchise,
		errors,
		loading,
		hasWiipoFlex,
		hasWiipoRequest,
		hasHCMIntegrator,
		isHcmPartial,
		treasuryAccountHolderId
	} = useSelector<any, any>(c => c.businessEdit);

	const { items } = useSelector<any, any>(c => c.businessCategoryReport);

	const handleChange = (name, value) => {
		if (
			[
				"state",
				"country",
				"name",
				"socialName",
				"street",
				"complement",
				"neighborhood",
				"city",
				"state",
				"country"
			].includes(name) &&
			value
		) {
			value = removeDiacritics(value);
			value = value.replace(/[^\w\s]/g, "").toUpperCase();
		}

		if (name === "number") {
			const formattedValue = value.toString().replace(/[^n|sN|S0-9]/g, "");
			dispatch(
				setValue({
					[name]: formattedValue
				})
			);
		} else {
			dispatch(
				setValue({
					[name]: value
				})
			);
		}
	};

	const titlePage = useMemo(() => {
		if (isStore) {
			return "Store";
		}
		if (isCompany) {
			return "Company";
		}

		if (isHeadquarters) {
			return "Headquarters";
		}

		return "Franchise";
	}, []);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditBusiness) debounceEditBusiness.cancel();

		debounceEditBusiness = _.debounce(() => {
			handleChange("hasWiipoClub", wiipoClub);
			handleChange("hasWiipoFlex", wiipoFlex);
			handleChange("hasHCMIntegrator", hasHCM);

			dispatch(
				editBusiness((err, model) => {
					if (err) {
						if (err.default) toastError(err.default);
						else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
					} else {
						history.push(`/${titlePage}/${model.id ? model.id : model}`);
					}
				})
			);
		}, 1000);

		debounceEditBusiness();
	};

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<div className={classes.titleContainer}>
						<Typography variant="h6">
							{ls.businessData}{" "}
							{loading.get && <Spinner color="secondary" size={16} />}{" "}
						</Typography>
					</div>
					<hr className={classes.line} />

					<form noValidate onSubmit={onSubmit}>
						<Grid container spacing={2} className={classes.form}>
							<Grid item xs={6}>
								<Typography className={classes.subTitle}>
									Tipo de cadastro
								</Typography>
								<Checkbox
									id="isStore"
									name="store"
									checked={isStore}
									onChange={handleChange}
								/>
								<Checkbox
									id="isCompany"
									name="company"
									checked={isCompany}
									onChange={handleChange}
								/>
								<Checkbox
									id="isHeadquarters"
									name="headquarters"
									checked={isHeadquarters}
									onChange={handleChange}
								/>
								<Checkbox
									id="isFranchise"
									name="franchise"
									checked={isFranchise}
									onChange={handleChange}
								/>
								<FormHelperText error={Boolean(errors.type)}>
									{errors.type}
								</FormHelperText>
							</Grid>
							<Grid item xs={6}>
								<Typography className={classes.subTitle}>
									Produtos contratados
								</Typography>
								<Checkbox
									id="hasWiipoClub"
									name="Clube Wiipo"
									checked={wiipoClub}
									onChange={(id, value) => {
										setWiipoClub(value);
									}}
								/>
								<Checkbox
									id="hasWiipoFlex"
									name="Wiipo Flex"
									checked={wiipoFlex}
									onChange={(id, value) => {
										if (hasWiipoFlex) return;

										setWiipoFlex(value);
									}}
								/>
								<Checkbox
									id="hasWiipoRequest"
									name="Wiipo Request"
									checked={hasWiipoRequest}
									onChange={handleChange}
								/>
							</Grid>

							<Grid container item xs={12} sm={12} md={12} lg={12}>
								<Grid item xs={6}>
									{ls.hasHCMService}

									<Switch
										id="hasHCMIntegrator"
										name="hasHCMIntegrator"
										disabled={hasWiipoFlex}
										value={hasHCM}
										checked={hasHCM}
										onChange={(id, value) => {
											if (hasHCMIntegrator) return;

											setHasHCM(value);
										}}
										color="primary"
										className={classes.hcmSwitch}
									/>
								</Grid>
							</Grid>

							{hasHCM && (
								<GridCheckboxHCM container item xs={12} sm={12} md={12} lg={12}>
									<LabelCheckboxHCM>
										Selecione o tipo de sincronia:
									</LabelCheckboxHCM>
									<Checkbox
										id="isHcmPartial"
										name="HCMTotal"
										style={{ color: "#000000" }}
										checked={!isHcmPartial}
										onChange={id => {
											handleChange(id, false);
										}}
									/>
									<Checkbox
										id="isHcmPartial"
										name="HCMManual"
										checked={isHcmPartial}
										onChange={id => {
											handleChange(id, true);
										}}
									/>
								</GridCheckboxHCM>
							)}

							<Grid item xs={12} sm={6} md={6} lg={4}>
								<TextInput
									id="name"
									required
									variant="outlined"
									name="businessName"
									value={name}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<TextInput
									id="socialName"
									required
									variant="outlined"
									name="socialName"
									value={socialName}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<TextInput
									id="cnpj"
									variant="outlined"
									name="cpfcnpj"
									value={cnpj}
									disabled={treasuryAccountHolderId}
									required
									InputProps={{
										inputComponent: CPFCNPJ
									}}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							{isStore && (
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Select
										errors={errors}
										id="category"
										name="category"
										value={category}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											...items.map(c => ({
												id: c.id,
												value: c.name,
												data: c
											}))
										]}
									/>
								</Grid>
							)}

							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Select
									errors={errors}
									id="status"
									name="status"
									value={status}
									required
									onChange={handleChange}
									options={[
										{ id: "", value: ls.noneSelectedText, disabled: true },
										{ id: 0, value: "Pendente" },
										{ id: 1, value: "Ativo" },
										{ id: 2, value: "Desativado temporariamente" },
										{ id: 3, value: "Desativado" }
									]}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextInput
									id="phoneNumber"
									required
									variant="outlined"
									name="phoneNumber"
									value={phoneNumber}
									errors={errors}
									onChange={(name, value) =>
										handleChange(
											name,
											new AsYouType().input(
												value[0] === "+" ? value : `+${value}`
											)
										)
									}
								/>
							</Grid>
							{isStore && (
								<Grid container item xs={12} spacing={4}>
									<Grid item xs={4}>
										<Select
											id="linkType"
											data-testid="linkType"
											name="linkType"
											value={
												typeof linkType === "string"
													? transformLinkType(linkType)
													: linkType
											}
											onChange={handleChange}
											leftIcons={[
												<FaGlobe
													style={{ marginRight: 4, color: "#707070" }}
												/>,
												<FaListAlt
													style={{ marginRight: 4, color: "#707070" }}
												/>,
												<MdRestaurant
													style={{ marginRight: 4, color: "#707070" }}
												/>,
												<FaHandshake
													style={{ marginRight: 4, color: "#707070" }}
												/>,
												<FaFileSignature
													style={{ marginRight: 4, color: "#707070" }}
												/>,
												<FaEye style={{ marginRight: 4, color: "#707070" }} />,
												<FaShoppingBag
													style={{ marginRight: 4, color: "#707070" }}
												/>,
												<FaCheckCircle
													style={{ marginRight: 4, color: "#707070" }}
												/>
											]}
											options={[
												{ id: 999, value: ls.noneSelectedText, disabled: true },
												{ id: 0, value: "Site" },
												{ id: 1, value: "Catálogo" },
												{ id: 2, value: "Cardápio" },
												{ id: 3, value: "Contratar" },
												{ id: 4, value: "Assinar" },
												{ id: 5, value: "Conhecer" },
												{ id: 6, value: "Comprar" },
												{ id: 7, value: "Aderir" }
											]}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextInput
											id="link"
											inputProps={{ "data-testid": "link" }}
											variant="outlined"
											name="link"
											errors={errors}
											value={link}
											onChange={handleChange}
										/>
									</Grid>
								</Grid>
							)}

							{isStore && (
								<Grid item xs={12}>
									<Checkbox
										id="hideContactInfo"
										name="hideContactInfo"
										checked={hideContactInfo}
										onChange={handleChange}
									/>
								</Grid>
							)}
							<Grid item xs={12} container alignItems="center" spacing={2}>
								<Grid item>
									<Typography variant="body1">Endereço:</Typography>
								</Grid>

								{isStore && (
									<Grid item>
										<Checkbox
											id="national"
											name="national"
											checked={national}
											onChange={handleChange}
										/>
									</Grid>
								)}

								{isStore && (
									<Grid item>
										<Checkbox
											id="hideLocationInfo"
											name="hideLocationInfo"
											checked={hideLocationInfo}
											onChange={handleChange}
										/>
									</Grid>
								)}
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextInput
									id="zipCode"
									variant="outlined"
									name="zipCode"
									required={!national}
									value={zipCode}
									errors={errors}
									InputProps={{
										inputComponent: CEP
									}}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={9} md={8} lg={7}>
								<TextInput
									id="street"
									variant="outlined"
									name="street"
									required={!national}
									value={street}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={3} md={3} lg={2}>
								<TextInput
									id="number"
									variant="outlined"
									name="number"
									required={!national}
									value={number}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={3}>
								<TextInput
									id="complement"
									variant="outlined"
									name="complement"
									value={complement}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<TextInput
									id="neighborhood"
									variant="outlined"
									name="neighborhood"
									required={!national}
									value={neighborhood}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={5}>
								<TextInput
									id="city"
									variant="outlined"
									name="city"
									required={!national}
									value={city}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={4} md={3} lg={2}>
								<TextInput
									id="state"
									variant="outlined"
									name="state"
									inputProps={{
										maxLength: 2
									}}
									required={!national}
									value={state}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={4} md={3} lg={2}>
								<TextInput
									id="country"
									variant="outlined"
									name="country"
									required
									inputProps={{
										maxLength: 2
									}}
									value={country}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>

							{isStore && (
								<Grid item xs={12}>
									<TextInput
										id="teaser"
										variant="outlined"
										name="teaser"
										value={teaser}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
							)}

							{isStore && (
								<Grid item xs={12}>
									<TextInput
										id="about"
										variant="outlined"
										name="about"
										multiline
										value={about}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
							)}

							{isStore && (
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Checkbox
										id="highlight"
										name="highlight"
										checked={highlight}
										onChange={handleChange}
									/>
								</Grid>
							)}
						</Grid>

						<hr className={classes.line} />
						<Grid container justify="flex-end">
							<Button
								variant="contained"
								color="default"
								className={classes.button}
								onClick={() => dispatch(clearValues())}
							>
								{ls.clear}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								type="submit"
								disabled={loading.edit}
							>
								{loading.edit ? (
									<Spinner color="secondary" size={16} />
								) : (
									ls.save
								)}
							</Button>
						</Grid>
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
}

export default RenderBusinessCreate;
