import { getErrors } from "helpers/error";
import { toastWarning } from "helpers/toast";
import * as api from "services/api";
import { Callback } from "Types/Callback";

import { ActionTypes } from "../types";

export interface ITreasuryAccountTransferRequest {
	skip?: number;
	take?: number;
	filter?: any;
	sort?: string;
	desc?: boolean;
	toExport?: boolean;
	callback: (err?: any, model?: any) => void;
}

export interface ITreasuryAccountTransferRequestById {
	id?: string;
	callback: (err?: any, model?: any) => void;
}

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: ActionTypes.SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: ActionTypes.CLEAR_VALUES };
}

export function getTransactionFunds({
	skip = 0,
	take = 10,
	filter,
	sort = "code",
	desc = true,
	toExport,
	callback
}: ITreasuryAccountTransferRequest) {
	return async (dispatch, getState) => {
		dispatch({ type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_REQUEST });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Funds?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				if (result?.model && result?.model.length > 0) {
					const verifyProcessing = result?.model.filter(i => i.status === 0);
					const verifyAwaitPayment = result?.model.filter(i => i.status === 2);
					if (verifyProcessing.length > 0) {
						toastWarning(
							`Você tem ${verifyProcessing.length}  pedido(s) em processamento, esse processo pode levar até 5 minutos.`
						);
					}
					if (verifyAwaitPayment.length > 0) {
						toastWarning(
							`Você tem ${verifyAwaitPayment.length}  pedido(s) aguardando pagamento, a nota pode demorar 10 minutos para ser gerada.`
						);
					}
				}

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_FAILED,
				payload: errors
			});

			return callback && callback(errors, null);
		} catch (error) {
			dispatch({
				type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
			return callback && callback(error, null);
		}
	};
}

export function getPMATransfer({
	skip = 0,
	take = 10,
	filter,
	sort = "CreationDate",
	desc = true,
	toExport,
	callback
}: ITreasuryAccountTransferRequest) {
	return async (dispatch, getState) => {
		dispatch({ type: ActionTypes.GET_PMA_TRANSFER_IN_WIIPOFLEX_REQUEST });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Programmanageraccounttransfer?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					if (result.params) {
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								totalValue: result.params.totalValue,
								...result.params
							})
						);
					}
					dispatch({
						type: ActionTypes.GET_PMA_TRANSFER_IN_WIIPOFLEX_SUCCESS,
						payload: result.model
					});
				}
				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: ActionTypes.GET_PMA_TRANSFER_IN_WIIPOFLEX_FAILED,
				payload: errors
			});

			return callback && callback(errors);
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: ActionTypes.GET_PMA_TRANSFER_IN_WIIPOFLEX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getFundsById(
	{ id }: ITreasuryAccountTransferRequestById,
	callback: Callback<any>
) {
	return async (dispatch, getState) => {
		dispatch({ type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_REQUEST });
		id = "f55c5191-62dc-46d8-a474-a46999b5c8e9";
		try {
			const { login } = getState();

			let response = await api.sendGet(`/Funds/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_FAILED,
				payload: errors
			});

			return callback && callback(errors);
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: ActionTypes.GET_FUNDS_IN_WIIPOFLEX_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const downloadReport = (id: string) => async (dispatch, getState) => {
	try {
		const { login } = getState();

		const response = await api.sendGet(
			`/Funds/${id}/Transfers/Download/Excel`,
			{
				Authorization: `Bearer ${login.token}`
			}
		);

		const result = await response.json();

		let link = document.createElement("a"); // Create <a>
		link.href = `data:${result.type};base64,${result.content}`;
		link.download = result.filename;
		link.click();
	} catch (error) {
		return dispatch({
			type: ActionTypes.EXPORT_FUND_REPORT,
			payload: {
				error: "Connection error",
				exception: error
			}
		});
	}
};
