import {
	ActionTypes,
	ITreasuryAccountWiipoFlexState
} from "store/actions/invoice/types";

const initialState: ITreasuryAccountWiipoFlexState = {
	items: [],
	count: 0,
	totalValue: 0,
	pages: 0,
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.GET_INVOICE_TRANSFER_REQUEST: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case ActionTypes.GET_INVOICE_TRANSFER_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload
			};
		}
		case ActionTypes.GET_INVOICE_TRANSFER_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case ActionTypes.SET_VALUE:
			return {
				...state,
				...payload
			};
		case ActionTypes.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
