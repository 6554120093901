import {
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography
} from "@material-ui/core";
import clsx from "clsx";
import Recaptcha from "Components/Recaptcha";
import Spinner from "Components/Spinner";
import Tooltip from "Components/Tooltip";
import { toastWarning } from "helpers/toast";
import React, { createRef, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { validateEmail } from "../../helpers/email";
import ls from "../../Localization";
import {
	confirmRecoverToken,
	setValue,
	submitRecoverPassword
} from "../../store/actions/login";
import useStyles from "./styles";

export function RecoverPassword() {
	const recaptcha = createRef<any>();
	const classes = useStyles({});
	const dispatch = useDispatch();
	const location: any = useLocation();

	const change = (name: string, value: string) => {
		const values: any = { [name]: value };

		if (name === "recoverEmail") {
			values.showCodeInput = false;
		}

		if (location?.state?.email) {
			values.showCodeInput = true;
		}

		dispatch(setValue(values));
	};

	useEffect(() => {
		dispatch(setValue({ loading: { recovery: false } }));
	}, [dispatch]);

	const form = useRef<HTMLFormElement>(null);

	useEffect(() => {
		if (location?.state?.email) {
			change("recoverEmail", location?.state?.email);
			dispatch(submitRecoverPassword());
		}
	}, [location?.state, dispatch]);

	const onSubmit = e => {
		e.preventDefault();
		const captchaValue = recaptcha?.current?.getValue();
		if (!captchaValue) {
			return toastWarning("Por favor, confirme que você não é um robô.");
		}

		if (!form.current.checkValidity()) {
			form.current.reportValidity();
		} else if (showCodeInput) {
			dispatch(confirmRecoverToken());
		} else {
			dispatch(submitRecoverPassword());
			change("captcha", "");
			recaptcha.current.reset();
		}
	};

	const { recoverEmail, code, showCodeInput, loading, errors } = useSelector<
		any,
		any
	>(s => s.login);

	return (
		<div className={classes.root}>
			<Helmet>
				<title>
					{ls.recoverPassword} | {ls.appName}
				</title>
			</Helmet>
			<Grid
				className={clsx(classes.container, "card-box")}
				container
				justify="center"
				alignItems="center"
			>
				<Card className={classes.card}>
					<Grid container className={classes.logoContainer} justify="center">
						<Grid item className="panel-heading">
							<img
								src="/img/logo_wiipo_color.png"
								style={{ width: 250 }}
								alt="logo"
							/>
						</Grid>
					</Grid>
					<CardContent>
						<form className="form-signin" ref={form} onSubmit={onSubmit}>
							<Grid container justify="center">
								<Grid item xs={12}>
									<TextField
										id="recoverEmail"
										label="E-mail"
										type="email"
										className={classes.textField}
										value={recoverEmail}
										onChange={e => change("recoverEmail", e.target.value)}
										margin="normal"
										fullWidth
										required
										error={errors["recoverEmail"] !== undefined}
										helperText={errors["recoverEmail"]}
									/>
								</Grid>

								{showCodeInput && (
									<Grid item xs={12} style={{ marginBottom: 12 }}>
										<TextField
											id="code"
											label={"Informe o código enviado para o e-mail acima"}
											type="text"
											className={classes.textField}
											value={code}
											onChange={e => change("code", e.target.value)}
											margin="normal"
											fullWidth
											required
											error={errors["code"] !== undefined}
											helperText={errors["code"]}
										/>

										<Typography style={{ marginTop: 8 }}>
											O código será enviado para o seu e-mail caso ele exista em
											nossa base
										</Typography>
									</Grid>
								)}

								<Recaptcha
									ref={recaptcha}
									change={(value: string) => change("captcha", value)}
								/>

								<Grid item md={8}>
									<Tooltip title="Preencha os campos corretamente para ativar o botão">
										<Button
											type="submit"
											id="button"
											className={classes.submitButton}
											disabled={
												!recoverEmail ||
												!validateEmail(recoverEmail) ||
												loading.recovery
											}
										>
											{ls.confirm}
											{loading.recovery ? (
												<Spinner style={{ height: 15, width: 15, margin: 5 }} />
											) : null}
										</Button>
									</Tooltip>
								</Grid>
								<Grid item xs={12} className={classes.newAccount}>
									<Link to="/login">{ls.backToLogin}</Link>
								</Grid>
							</Grid>
						</form>
					</CardContent>
				</Card>
			</Grid>
		</div>
	);
}

export default RecoverPassword;
