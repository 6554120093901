/* eslint-disable react-hooks/exhaustive-deps */
import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import AutoComplete from "Components/AutoComplete";
import Checkbox from "Components/Checkbox";
import { CEP, CPFCNPJ } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { isInRole } from "helpers/auth";
import { verifyOpenEditCPF } from "helpers/openEditCPF";
import { toastError, toastSuccess, toastWarning } from "helpers/toast";
import { AsYouType } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import userImportedBy from "models/userImportedBy";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "store/actions/business/report";
import { getRoles } from "store/actions/role/details";
import { clearValues, editUser, setValue } from "store/actions/user/edit";

import { Footer } from "./styles";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		marginTop: 16,
		padding: theme.spacing(0, 2, 0, 0)
	}
}));

let debounceFindCompany: (() => void) & _.Cancelable = null;
let debounceEditUser: (() => void) & _.Cancelable = null;

function EditUser({ open, item, handleClose, hasHCMIntegrator }) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const accountHolderId = item?.accountHolderId;

	useEffect(() => {
		if (open && item) {
			dispatch(clearValues());
			dispatch(
				setValue({
					item,
					name: item.name,
					business: item.business,
					lastName: item.lastName,
					email: item.email,
					cpf: item.cpf,
					enroll: item.enroll,
					admissionDate: item.admissionDate,
					phoneNumber: item.phoneNumber,
					status: item.status,
					street: item.address?.street ?? "",
					number: item.address?.number ?? "",
					complement: item.address?.complement ?? "",
					neighborhood: item.address?.neighborhood ?? "",
					city: item.address?.city ?? "",
					state: item.address?.state ?? "SC",
					country: item.address?.country ?? "BR",
					zipCode: item.address?.zipCode ?? "",
					mothersName: item.mothersName ?? "",
					birthDate: item.birthDate ?? "",
					isPj: item.isPJ,
					importedBy: item.importedBy
				})
			);

			dispatch(getRoles());
		}
	}, [dispatch, open, item]);

	const [zipCodeLoading, setZipCodeLoading] = useState(false);
	const [checkZipCode, setCheckZipCode] = useState(false);
	const [checkEditBusiness, setCheckEditBusiness] = useState(false);

	const { items: roleItems } = useSelector<any, any>(s => s.roleDetails);
	const { items: companies, loading: companyLoading } = useSelector<any, any>(
		s => s.businessReport
	);

	let {
		name,
		lastName,
		email,
		cpf,
		enroll,
		admissionDate,
		phoneNumber,
		status,
		street,
		number,
		complement,
		neighborhood,
		city,
		state: stateOfCountry,
		zipCode,
		loading,
		business,
		birthDate,
		mothersName,
		isPJ,
		errors,
		importedBy
	} = useSelector<any, any>(s => s.userEdit);

	const { user } = useSelector<any, any>(s => s.login);

	const isAdmin = isInRole(user, ["admin"]);

	useEffect(() => {
		if (checkZipCode && zipCode?.length === 8) {
			setZipCodeLoading(true);
			fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json) {
						dispatch(
							setValue({
								street: json.logradouro,
								number: "",
								complement: json.complemento,
								neighborhood: json.bairro,
								city: json.localidade,
								state: json.uf,
								zipCode
							})
						);
					}
					setZipCodeLoading(false);
				})
				.catch(err => {
					console.log(err);
					setZipCodeLoading(false);
				});
		}
	}, [zipCode]);

	const handleChange = useCallback((id, value) => {
		if (id === "zipCode") setCheckZipCode(true);

		if (id === "role") {
			if (!business) {
				business = {};
			}

			business.roleId = value;

			id = "business";
			value = business;
		}

		if (id === "company") {
			if (!business) {
				business = {};
			}
			if (business?.businessId !== value.value && accountHolderId) {
				setCheckEditBusiness(true);
			}

			if (business) {
				business.businessId = value.value;
				business.business = value.data;
			}

			id = "business";
			value = business;
		}

		dispatch(
			setValue({
				[id]: value
			})
		);
	}, []);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditUser) debounceEditUser.cancel();

		debounceEditUser = _.debounce(() => {
			dispatch(
				editUser(err => {
					if (err) {
						if (err.default) {
							if (err.default.includes("No Account Holder")) {
								toastError(
									"Não é possível migrar usuário na bandeira: Account holder não encontrada na tesouraria da empresa"
								);
							} else if (err.default.includes("invalid")) {
								try {
									const errArray = JSON.parse(err.default);

									const filterIsEmpty = errArray.filter(
										i => Object.keys(i).length > 0
									);

									const object = Object.keys(filterIsEmpty[0]).map(
										i => `${i} :  ${filterIsEmpty[0][i]}`
									);

									toastWarning(
										`Usuário editado com sucesso mas com erro na bandeira : ${object}`
									);
								} catch (error) {
									toastError("Erro fatal entre em contato conosco");
								}

								handleClose();
							} else {
								toastError(err.default);
							}
						} else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
						setCheckEditBusiness(false);
					} else {
						if (checkEditBusiness && accountHolderId) {
							toastSuccess("Usuário migrado com sucesso na bandeira");
						} else {
							toastSuccess("Usuário editado com sucesso!");
						}
						setCheckEditBusiness(false);
						handleClose();
					}
				})
			);
		}, 1000);

		if (business?.roleId) {
			debounceEditUser();
		} else {
			toastWarning("Preencha a função do usuário");
		}
	};

	let roles = roleItems
		.filter(c => isAdmin || c.normalizedName !== "ADMIN")
		.map(c => ({
			id: c.id,
			value: ls[c.name]
		}));

	const companiesSuggestions = companies.map(c => ({
		value: c.id,
		label: c.name,
		data: c
	}));

	let selectedCompany = companiesSuggestions.find(
		c => c.value === business?.businessId
	);

	if (!selectedCompany) {
		selectedCompany = {
			value: business?.businessId,
			label: business?.business?.name
		};
	}

	const finCompany = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindCompany) debounceFindCompany.cancel();

			debounceFindCompany = _.debounce(() => {
				dispatch(
					getBusinesses(
						0,
						10,
						`&filters[name]=${name}`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => ({
										value: c.id,
										label: `${c.displayName} - ${c.cnpj}`,
										data: c
									}))
								);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindCompany();
		}
	};

	return (
		<Modal
			aria-labelledby="edit-user-modal-title"
			aria-describedby="edit-user-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editUser}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="name"
										required
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										placeholder={ls.namePlaceholder}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="lastName"
										required
										variant="outlined"
										name="lastName"
										value={lastName}
										errors={errors}
										placeholder={ls.lastNamePlaceholder}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="cpf"
										variant="outlined"
										name="cpfcnpj"
										disabled={Boolean(
											!verifyOpenEditCPF(user?.id) || accountHolderId
										)}
										value={cpf}
										InputProps={{
											inputComponent: CPFCNPJ
										}}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="email"
										variant="outlined"
										name="email"
										value={email}
										errors={errors}
										placeholder={ls.emailPlaceholder}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="phoneNumber"
										variant="outlined"
										name="phoneNumber"
										value={phoneNumber}
										errors={errors}
										onChange={(name, value) =>
											handleChange(
												name,
												new AsYouType().input(
													value[0] === "+" ? value : `+${value}`
												)
											)
										}
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<DatePicker
										value={admissionDate}
										label={ls.admissionDate}
										fullWidth
										onChange={date => handleChange("admissionDate", date)}
										format={ls.dateFormatShort}
										inputVariant="outlined"
										clearable
										clearLabel="Limpar"
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="enroll"
										variant="outlined"
										name="enroll"
										value={enroll}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} md={6} lg={4}>
									<Select
										errors={errors}
										id="role"
										name="role"
										placeholder={ls.rolePlaceholder}
										value={business?.roleId ?? ""}
										required
										onChange={handleChange}
										options={roles}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: 0, value: "Pendente" },
											{ id: 1, value: "Ativo" },
											{ id: 2, value: "Desativado temporariamente" },
											{ id: 3, value: "Desativado" }
										]}
									/>
								</Grid>

								{isAdmin && (
									<Grid item xs={12} md={6} lg={4}>
										<AutoComplete
											id="company"
											name="company"
											placeholder={ls.companyPlaceholder}
											variant="outlined"
											label={ls.company}
											isClearable
											async
											textFieldProps={{}}
											closeMenuOnSelect
											errors={errors}
											value={selectedCompany}
											onChange={handleChange}
											suggestions={companiesSuggestions}
											loadOptions={finCompany}
											loading={companyLoading}
										/>
									</Grid>
								)}
								<Grid item xs={12} md={6} lg={4}>
									<DatePicker
										value={String(birthDate).replace("T00", "T03")}
										label={ls.birthDate}
										required
										fullWidth
										disableFuture
										onChange={date => {
											handleChange("birthDate", date.toISOString());
										}}
										format={ls.dateFormatShort}
										inputVariant="outlined"
										clearable
										clearLabel="Limpar"
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<TextInput
										id="mothersName"
										required
										variant="outlined"
										name="mothersName"
										value={mothersName}
										errors={errors}
										placeholder={ls.mothersName}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<Typography>Endereço:</Typography>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="zipCode"
										variant="outlined"
										name="zipCode"
										required={false}
										value={zipCode}
										errors={errors}
										InputProps={{
											inputComponent: CEP
										}}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={9} md={8} lg={7}>
									<TextInput
										id="street"
										variant="outlined"
										name="street"
										required={false}
										value={street}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={3} md={3} lg={2}>
									<TextInput
										id="number"
										variant="outlined"
										name="number"
										required={false}
										value={number}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="complement"
										variant="outlined"
										name="complement"
										value={complement}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="neighborhood"
										variant="outlined"
										name="neighborhood"
										required={false}
										value={neighborhood}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="city"
										variant="outlined"
										name="city"
										value={city}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={4} md={3} lg={2}>
									<TextInput
										id="state"
										variant="outlined"
										name="state"
										inputProps={{
											maxLength: 2
										}}
										required
										value={stateOfCountry}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>

							<Footer
								isPJEnabled={
									!hasHCMIntegrator && (importedBy === 2 || importedBy === 3)
								}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										width: "50%"
									}}
								>
									{!hasHCMIntegrator && (importedBy === 2 || importedBy === 3) && (
										<Grid item>
											<Checkbox
												id="isPJ"
												name="isPJ"
												checked={isPJ}
												onChange={handleChange}
											/>
										</Grid>
									)}

									{isAdmin && (
										<Grid item>
											<Select
												errors={errors}
												id="importedBy"
												name="importedBy"
												value={importedBy}
												onChange={handleChange}
												options={[
													{
														id: "",
														value: ls.noneSelectedText,
														disabled: true
													},
													{ id: 0, value: ls[userImportedBy[0]] },
													{ id: 1, value: ls[userImportedBy[1]] },
													{ id: 2, value: ls[userImportedBy[2]] },
													{ id: 3, value: ls[userImportedBy[3]] }
												]}
											/>
										</Grid>
									)}
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end",
										width: "50%"
									}}
								>
									<Button variant="contained" onClick={() => handleClose()}>
										{ls.cancel}
									</Button>
									<Button
										color="primary"
										variant="contained"
										type="submit"
										disabled={loading.edit || zipCodeLoading}
									>
										{ls.save}
										{(loading.edit || zipCodeLoading) && (
											<Spinner color="secondary" size={16} />
										)}
									</Button>
								</div>
							</Footer>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditUser;
