import { Grid } from "@material-ui/core";
import TextInput from "Components/TextInput";
import React from "react";
import useStyles from "views/Dashboard/Marketing/Create/styles";

import TabPanel from "../TabPanel";

interface EmailTabProps {
	index: number;
	tab: number;
	emailSubject: string;
	handleChange: (key: any, value: any) => void;
	errors: any;
	emailMessage: string;
}

const EmailTab: React.FC<EmailTabProps> = ({
	errors,
	handleChange,
	index,
	emailSubject,
	tab,
	emailMessage
}) => {
	const classes = useStyles({});

	return (
		<TabPanel value={tab} index={index}>
			<Grid container spacing={2} className={classes.form}>
				<Grid item xs={12}>
					<TextInput
						id="emailSubject"
						required
						variant="outlined"
						name="emailSubject"
						value={emailSubject}
						errors={errors}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						id="emailMessage"
						required
						variant="outlined"
						name="emailMessage"
						value={emailMessage}
						multiline
						rows={6}
						errors={errors}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<strong>Preview</strong>:
					<div
						style={{
							overflow: "auto"
						}}
						// eslint-disable-next-line react/no-danger
						dangerouslySetInnerHTML={{ __html: emailMessage }}
					/>
				</Grid>
			</Grid>
		</TabPanel>
	);
};

export default EmailTab;
