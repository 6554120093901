import {
	Button,
	Grid,
	IconButton,
	ListItem,
	ListItemText,
	Paper,
	TextField,
	Typography
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import AutoComplete2 from "Components/AutoComplete2";
import Checkbox from "Components/Checkbox";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import { CreditType } from "models/creditOrigin";
import creditStatus from "models/creditStatus";
import React, { useEffect, useState } from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { MdStore, MdViewModule } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { FixedSizeList } from "react-window";
import { getBusinesses } from "store/actions/business/report";
import {
	clearValues,
	createCredit,
	setValue
} from "store/actions/credit/create";
import { getEnabledUsers, getUsers } from "store/actions/user/report";

import CategoriesModal from "./Policy/Categories";
import StoresModal from "./Policy/Stores";
import useStyles from "./styles";

const handleFilterUsers = (c, value) =>
	`${c?.fullName?.toLowerCase()} - ${c?.businessName.toLowerCase()}`.indexOf(
		value.toLowerCase()
	) !== -1;

let debounceFindClient: (() => void) & _.Cancelable = null;
let debounceFindStore: (() => void) & _.Cancelable = null;
let debounceCreateCredit: (() => void) & _.Cancelable = null;

export function CreditAdd({ history }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const [filterIn, setFilterIn] = useState("");
	const [filterOut, setFilterOut] = useState("");

	const {
		value,
		status,
		origin,
		expirationDate,
		minValue,
		users,
		startDate,
		multiplePeople,
		description,
		errors,
		loading
	} = useSelector<any, any>(s => s.creditCreate);

	const usersState = useSelector<any, any>(s => s.userReport);

	const { loading: storeLoading } = useSelector<any, any>(
		s => s.businessReport
	);

	const handleChange = (name, value) => {
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const onSubmit = e => {
		e.preventDefault();

		if (debounceCreateCredit) debounceCreateCredit.cancel();

		debounceCreateCredit = _.debounce(() => {
			dispatch(
				createCredit((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						if (multiplePeople && (users || []).length) {
							history.push("/Credit");
						} else {
							history.push(`/Credit/${model.id}`);
						}
					}
				})
			);
		}, 1000);

		debounceCreateCredit();
	};

	const findClient = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindClient) debounceFindClient.cancel();

			debounceFindClient = _.debounce(() => {
				dispatch(
					getUsers(
						0,
						10,
						`&filters[fullName]=${name}`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback &&
									callback(
										models.map(c => ({
											id: c.id,
											label: `${c.fullName}`
										}))
									);
							} else {
								callback && callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindClient();
		}
	};

	const findCompany = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindStore) debounceFindStore.cancel();

			debounceFindStore = _.debounce(() => {
				dispatch(
					getBusinesses(
						0,
						10,
						`&filters[name]=${name}&filters[type]=2`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => ({
										id: c.id,
										label: `${c.displayName}`
									}))
								);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindStore();
		}
	};

	useEffect(() => {
		if (multiplePeople) {
			dispatch(getEnabledUsers());
		}
	}, [dispatch, multiplePeople]);

	const handleRight = () => {
		let newUsers = (usersState.enabled || [])
			?.filter(
				c =>
					!(users || []).find(u => u.userId === c.id) &&
					handleFilterUsers(c, filterOut)
			)
			.map(c => ({ userId: c.id }));

		handleChange("users", [...(users || []), ...newUsers]);

		setFilterOut("");
	};

	const handleLeft = () => {
		let keep = [];
		if (filterIn) {
			let filtered = (usersState.enabled || []).filter(c =>
				handleFilterUsers(c, filterIn)
			);

			keep = (users || []).filter(u => !filtered.find(c => c.id === u.userId));
		}

		handleChange("users", keep);
		setFilterIn("");
	};

	const handleUserChange = (items: any[], index: number) => {
		let item = (users || []).find(c => c.userId === items[index].id);

		if (item) {
			handleChange(
				"users",
				(users || []).filter(c => c !== item)
			);
		} else {
			handleChange("users", [...(users || []), { userId: items[index].id }]);
		}
	};

	const renderRow = (items, props) => {
		const { index, style } = props;

		if (!items.length)
			return (
				<ListItem button style={style} key={index}>
					<ListItemText
						style={{
							whiteSpace: "nowrap",
							maxWidth: 400
						}}
						primary="Nenhum item"
					/>
				</ListItem>
			);

		return (
			<ListItem
				button
				style={style}
				key={index}
				onClick={() => handleUserChange(items, index)}
			>
				<ListItemText
					style={{
						whiteSpace: "nowrap",
						maxWidth: 400
					}}
					primary={`${items[index].fullName} - ${items[index]?.businessName}`}
				/>
			</ListItem>
		);
	};

	let filteredIn = (usersState.enabled || [])?.filter(c =>
		handleFilterUsers(c, filterIn)
	);

	let filteredOut = (usersState.enabled || [])?.filter(c =>
		handleFilterUsers(c, filterOut)
	);

	let usersIn = filteredIn.filter(c =>
		(users || []).find(u => u.userId === c.id)
	);

	let usersNotIn = filteredOut.filter(
		c => !(users || []).find(u => u.userId === c.id)
	);

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<Typography variant="h6">{ls.addCredit}</Typography>
					<hr className={classes.line} />

					<form noValidate onSubmit={onSubmit}>
						<Grid container spacing={2} className={classes.form}>
							<Grid item xs={12} md={4} lg={4}>
								<AutoComplete2
									id="userId"
									name="client"
									disabled={multiplePeople && true}
									errors={errors}
									onChange={handleChange}
									loadOptions={findClient}
									loading={usersState.userLoading}
									filterOptions={(options, state) => options}
								/>
							</Grid>
							<Grid item xs={12} md={4} lg={4}>
								<AutoComplete2
									id="companyId"
									name="company"
									errors={errors}
									onChange={handleChange}
									loadOptions={findCompany}
									loading={storeLoading}
									filterOptions={(options, state) => options}
								/>
							</Grid>
							<Grid item xs={12} sm={4} md={4}>
								<TextInput
									id="value"
									required
									variant="outlined"
									name="value"
									InputProps={{
										inputComponent: MONEY
									}}
									value={value}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={3} md={2}>
								<TextInput
									id="minValue"
									required
									variant="outlined"
									name="minValue"
									InputProps={{
										inputComponent: MONEY
									}}
									value={minValue}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={3} md={2}>
								<Select
									errors={errors}
									id="status"
									name="status"
									value={status}
									required
									onChange={handleChange}
									options={[
										{ id: "", value: ls.noneSelectedText, disabled: true },
										...Object.keys(creditStatus).map(c => ({
											id: c,
											value: ls[creditStatus[c]]
										}))
									]}
								/>
							</Grid>
							<Grid item xs={12} sm={3} md={2}>
								<Select
									errors={errors}
									id="origin"
									name="origin"
									value={origin}
									required
									onChange={handleChange}
									options={[
										{ id: "", value: ls.noneSelectedText, disabled: true },
										{ id: CreditType.Undefined, value: "Não definido" },
										{ id: CreditType.Cashback, value: "Cashback" },
										{ id: CreditType.Campaign, value: "Campanha" },
										{ id: CreditType.Release, value: "Lançamento" },
										{ id: CreditType.Refund, value: "Reembolso" },
										{ id: CreditType.Prize, value: "Premiação" }
									]}
								/>
							</Grid>
							<Grid item xs={12} sm={3} md={3}>
								<DatePicker
									value={startDate}
									label={ls.startAt}
									fullWidth
									onChange={date => handleChange("startDate", date)}
									format={ls.dateFormatShort}
									inputVariant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={3} md={3}>
								<DatePicker
									value={expirationDate}
									label={ls.expirationDate}
									fullWidth
									onChange={date => handleChange("expirationDate", date)}
									format={ls.dateFormatShort}
									inputVariant="outlined"
								/>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<TextField
									multiline
									rows={3}
									variant="outlined"
									value={description}
									label={ls.description}
									fullWidth
									onChange={e => handleChange("description", e.target.value)}
								/>
							</Grid>
							<Grid item xs={12}>
								<div>
									{loading["showCategoryFormModal"] ? (
										<Spinner color="secondary" size={16} />
									) : (
										<IconButton
											style={{
												padding: 8,
												fontSize: "1.3rem"
											}}
											onClick={() =>
												handleChange("showCategoryFormModal", true)
											}
										>
											<MdViewModule />
										</IconButton>
									)}
									{loading["showStoreFormModal"] ? (
										<Spinner color="secondary" size={16} />
									) : (
										<IconButton
											style={{
												padding: 8,
												fontSize: "1.3rem"
											}}
											onClick={() => handleChange("showStoreFormModal", true)}
										>
											<MdStore />
										</IconButton>
									)}
								</div>
							</Grid>
							<Grid item xs={12}>
								<Checkbox
									id="multiplePeople"
									name="Múltiplos usuários"
									checked={multiplePeople}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>

						{multiplePeople && (
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={12}>
									<Typography>{errors["users"]}</Typography>
								</Grid>
								<Grid item xs={12} md={5}>
									<Typography style={{ marginLeft: 16 }} variant="body2">
										Não enviar para:
									</Typography>
									<TextInput
										id="filterOut"
										variant="standard"
										name="filterOut"
										hideLabel
										placeholder="Filtro"
										value={filterOut}
										errors={errors}
										onChange={(id, v) => setFilterOut(v)}
									/>
									<FixedSizeList
										height={400}
										itemSize={36}
										itemCount={usersNotIn.length}
									>
										{props => renderRow(usersNotIn, props)}
									</FixedSizeList>
								</Grid>
								<Grid
									item
									xs={12}
									md={2}
									container
									justify="center"
									alignItems="center"
									alignContent="center"
								>
									<Grid
										item
										xs={12}
										style={{ display: "flex", justifyContent: "center" }}
									>
										<IconButton
											disabled={!usersNotIn.length}
											onClick={handleRight}
										>
											<AiOutlineDoubleRight />
										</IconButton>
									</Grid>
									<Grid
										item
										xs={12}
										style={{ display: "flex", justifyContent: "center" }}
									>
										<IconButton disabled={!usersIn.length} onClick={handleLeft}>
											<AiOutlineDoubleLeft />
										</IconButton>
									</Grid>
								</Grid>
								<Grid item xs={12} md={5}>
									<Typography style={{ marginLeft: 16 }} variant="body2">
										Enviar para:
									</Typography>
									<TextInput
										id="filterIn"
										variant="standard"
										name="filterIn"
										hideLabel
										placeholder="Filtro"
										value={filterIn}
										errors={errors}
										onChange={(id, v) => setFilterIn(v)}
									/>
									<FixedSizeList
										height={400}
										itemSize={36}
										itemCount={usersIn.length || 1}
									>
										{props => renderRow(usersIn, props)}
									</FixedSizeList>
								</Grid>
							</Grid>
						)}

						<hr className={classes.line} />
						<Grid container justify="flex-end">
							<Button
								variant="contained"
								color="default"
								className={classes.button}
								onClick={() => dispatch(clearValues())}
							>
								{ls.clear}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								type="submit"
							>
								{ls.save}
								{loading.create && <Spinner color="secondary" size={16} />}
							</Button>
						</Grid>
					</form>
				</Paper>
				<CategoriesModal />
				<StoresModal />
			</Grid>
		</Grid>
	);
}

export default CreditAdd;
