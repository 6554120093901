import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme: any) => {
	return {
		root: {
			display: "flex"
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			})
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		menuButton: {
			marginLeft: 12,
			marginRight: 36
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: "nowrap"
		},
		drawerPaper: {
			width: drawerWidth
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			})
		},
		drawerClose: {
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			overflowX: "hidden",
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9) + 1
			}
		},
		content: {
			flexGrow: 1,
			padding: theme.spacing(2)
		},
		toolbar: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: "0 8px",
			...theme.mixins.toolbar
		},
		logo: {
			objectFit: "contain",
			maxHeight: theme.spacing(6)
		},
		item: {
			paddingLeft: theme.spacing(3),
			paddingTop: 0,
			paddingBottom: 0
		},
		itemCollapse: {
			paddingLeft: theme.spacing(3)
		},
		nested: {
			paddingLeft: theme.spacing(4)
		},
		itemLink: {
			display: "flex",
			textDecoration: "none",
			color: "rgba(0, 0, 0, 0.54)",
			width: "100%",
			paddingTop: 11,
			paddingBottom: 11
		},
		itemText: {},
		itemActive: { fontWeight: "bold !important" as "bold", color: "#123382" },
		inherit: {
			color: "inherit"
		},
		paper: {
			marginRight: theme.spacing(2)
		},
		grow: {
			flexGrow: 1
		}
	};
});

export default useStyles;
