const styles = theme => ({
	root: {
		padding: theme.spacing(2)
	},
	form: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	alert: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		backgroundColor: "#cdeefd"
	},
	alertIcon: {
		marginRight: theme.spacing(2),
		color: "#02587f"
	},
	alertText: {
		color: "#02587f"
	},
	line: {
		marginLeft: -16,
		marginRight: -16
	},
	button: {
		margin: theme.spacing()
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	},
	margin: {
		margin: theme.spacing(2)
	},
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	gridLoading: {
		margin: "10px"
	}
});

export default styles;
