import headerImg from "assets/header-img.png";
import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 26px;
	font-family: "DejaVu Sans";
	font-weight: normal;
`;

export const Header = styled.div`
	background-image: url(${headerImg});
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-top-right-radius: 16px;
	border-top-left-radius: 16px;
	height: 80px;
	width: 100%;
	align-items: flex-end;
	position: relative;
	bottom: 30px;
`;

export const Title = styled.strong`
	text-align: right;
	color: #5734d9;
	font-weight: normal;
	background-color: #fff;
	padding: 6px;
	border-radius: 12px;
	font-size: 9px;
	margin-bottom: 20px;
	margin-right: 20px;
	margin-top: 20px;
`;

export const BackgroundButton = styled.div`
	margin-left: 10px;
	cursor: pointer;
	font-family: "DejaVu Sans";
	font-weight: normal;
	font-size: 10px;
	letter-spacing: 0px;
	color: #5734da;
	background: none;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const BackgroundPage = styled.div`
	padding-top: 10px;
	border: 1px solid #afafaf;
	border-radius: 16px;
	border-color: #afafaf;
	width: 100%;
`;

export const RowAddress = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 8px;
	p {
		font-family: "DejaVu Sans";
		font-weight: normal;
		font-size: 10px;
		letter-spacing: 0px;
		opacity: 1;
	}

	margin-bottom: 10px;
`;

export const RowDestiny = styled.div`
	font-family: "DejaVu Sans";
	font-weight: normal;
	font-size: 10px;
	letter-spacing: 0px;
	margin-left: 8px;
	opacity: 1;

	margin-bottom: 10px;
`;

export const RowCPFDestiny = styled.div`
	font-family: "DejaVu Sans";
	font-weight: normal;
	font-size: 10px;
	letter-spacing: 0px;
	margin-left: 8px;
	opacity: 1;
	margin-bottom: 40px;
`;

export const RowGray = styled.div`
	background-color: #f6f6f6;
	height: 25px;
	font-size: 10px;
	border-radius: 9px;
	background-color: #ccc2f3;
	width: 90%;
	padding-top: 9px;
	padding-left: 9px;
	text-align: left;
	margin-bottom: 20px;
`;

export const RowStatus = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	align-items: center;
	font-family: "DejaVu Sans";
	font-weight: normal;
	font-size: 10px;
	letter-spacing: 0px;
	opacity: 1;
	margin-left: 8px;
	strong {
		font-family: "DejaVu Sans";
		font-weight: normal;
		font-size: 10px;
		letter-spacing: 0px;
		opacity: 1;
		display: flex;
		flex-direction: row;
		align-items: center;

		button {
			cursor: pointer;
			font-family: "DejaVu Sans";
			font-weight: normal;
			font-size: 14px;
			letter-spacing: 0px;
			color: #5734da;
			background: none;
		}
	}

	button {
		margin-left: 10px;
		cursor: pointer;
		border: none;
		padding: 10px 16px;
		font: normal normal 600 10px Exo;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
		background: #fe9d2b 0% 0% no-repeat padding-box;
		border-radius: 10px;
		opacity: 1;
	}

	margin-bottom: 10px;

	p {
		font-family: "DejaVu Sans";
		font-weight: normal;
		font-size: 10px;
	}
`;

export const SubTitle = styled.span`
	font: normal normal normal 14px Exo;
	letter-spacing: 0px;
	opacity: 1;
`;

export const RowDetail = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-left: 8px;
`;

export const TextDetail = styled.p`
	font-size: 10px;
	letter-spacing: 0px;
	opacity: 1;
`;

export const Section = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding-left: 16px;
	padding-right: 16px;
	align-items: flex-start;
`;

export const ColumnTitle = styled.div`
	display: flex;
	flex-direction: column;
	width: 25%;
`;

export const LabelColumn = styled.strong`
	color: #5734da;
	background-color: #ccc2f3;
	border-radius: 6px;
	padding: 4px;
	font-size: 8px;
	max-width: 60px;
	margin-bottom: 10px;
`;

export const TextLabelColumn = styled.strong`
	font-size: 10px;
	font-weight: 600;
`;

export const SubTitleLabel = styled.strong`
	font-size: 8px;
	font-weight: 200;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	padding-left: 16px;
	padding-right: 16px;
	margin-bottom: 30px;
`;

export const SaleSold = styled.div``;
