import {
	Backdrop,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import accessionStatus from "models/accessionStatus";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	deleteAccession,
	editAccession,
	setValue
} from "store/actions/accession";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 600,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceEditForm: (() => void) & _.Cancelable = null;

function EditForm() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { showEditFormModal, email, status, errors, item, loading } =
		useSelector<any, any>(c => c.accession);

	const [alertModal, setAlertModal] = useState(null);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showEditFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditForm) debounceEditForm.cancel();

		debounceEditForm = _.debounce(() => {
			dispatch(
				editAccession(err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceEditForm();
	};

	const handleDeleteAccession = () => {
		dispatch(
			deleteAccession(item.id, err => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					handleClose();
					setAlertModal(null);
				}
			})
		);
	};

	const showConfirm = () => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja confirmar a exclusão?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação da exclusão não será possível recuperar os dados.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteAccession} color="primary" autoFocus>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<Modal
			aria-labelledby="edit-product-modal-title"
			aria-describedby="edit-product-modal-description"
			className={classes.modal}
			open={showEditFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showEditFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editAccession}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={12} md={6}>
									<TextInput
										id="email"
										required
										variant="outlined"
										name="email"
										value={email}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={12} md={6}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											...Object.keys(accessionStatus).map(c => ({
												id: c,
												value: ls[accessionStatus[c]]
											}))
										]}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button
									color="secondary"
									variant="contained"
									onClick={showConfirm}
									disabled={loading.delete}
								>
									{ls.delete}
									{loading.delete && <Spinner color="secondary" size={16} />}
								</Button>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.update}
								>
									{ls.save}
									{loading.update && <Spinner color="secondary" size={16} />}
								</Button>

								{alertModal}
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditForm;
