import * as actions from "store/actions/charge/delete";

import { IDeleteCharge } from "../types/delete";

const initialState: IDeleteCharge = {
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.DELETE_CHARGE:
			return {
				...state,
				loading: true
			};
		case actions.DELETE_CHARGE_SUCCESS:
			return {
				...state,
				loading: false
			};
		case actions.DELETE_CHARGE_FAILED:
			return {
				...state,
				loading: false,
				errors: payload.errors
			};
		default:
			return state;
	}
};

export default reducer;
