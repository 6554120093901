import { Button } from "@material-ui/core";
import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { toastError, toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "store/actions/product";
import {
	clearValues,
	getExportClicksBanners,
	setValue as changeValueClicks
} from "store/actions/product/banners";

import {
	Container,
	InputDate,
	LabelDate,
	RowDates,
	Title,
	useStyles
} from "./styles";

dayjs.extend(utc);

let debounceGetClicks: (() => any) & _.Cancelable = null;

const ModalClicks: React.FC = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { showModalReportClicks } = useSelector<any, any>(s => s.product);
	const { startDate, endDate, loading } = useSelector<any, any>(
		s => s.productBanners
	);

	const maxDate = useMemo(() => {
		const start = new Date(String(startDate));
		start.setMonth(start.getMonth() + 1);

		const now = new Date();

		return start < now ? start : now;
	}, [startDate]);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleChangeClicks = useCallback(
		(id: string, value: any) => {
			dispatch(changeValueClicks({ [id]: value }));
		},
		[dispatch]
	);

	const handleClose = useCallback(
		() => handleChange("showModalReportClicks", false),
		[handleChange]
	);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();

			if (debounceGetClicks) debounceGetClicks.cancel();
			debounceGetClicks = _.debounce(() => {
				dispatch(
					getExportClicksBanners((err, model: any) => {
						if (err) {
							toastError(err);
						} else {
							handleClose();
							dispatch(clearValues());
							window.open(model.url, "_blank");
						}
					})
				);
			}, 500);
			if (startDate && endDate) {
				debounceGetClicks();
			} else {
				toastWarning("Preencha as datas antes de continuar");
			}
		},
		[dispatch, endDate, handleClose, startDate]
	);

	return (
		<ModalGeneric
			handleClose={handleClose}
			open={showModalReportClicks}
			stylePaper={{ width: "30% !important" }}
			body={() => (
				<Container>
					<Title>Selecione o período para o relatório:</Title>
					<RowDates>
						<LabelDate>De:</LabelDate>

						<InputDate
							style={{ maxWidth: 138 }}
							size="small"
							placeholder="00/00/0000"
							format={ls.dateFormatShort}
							InputProps={{
								classes: { input: classes.inputDueDate }
							}}
							maxDate={maxDate}
							value={startDate}
							onChange={v => {
								handleChangeClicks(
									"startDate",
									v.utc().hour(0).minute(0).second(0)
								);
							}}
							inputVariant="outlined"
							fullWidth
						/>

						<LabelDate>Até:</LabelDate>

						<InputDate
							style={{ maxWidth: 138 }}
							size="small"
							placeholder="00/00/0000"
							format={ls.dateFormatShort}
							InputProps={{
								classes: { input: classes.inputDueDate }
							}}
							value={endDate}
							maxDate={maxDate}
							onChange={v => {
								handleChangeClicks(
									"endDate",
									v.utc().hour(23).minute(59).second(59)
								);
							}}
							inputVariant="outlined"
							fullWidth
						/>
					</RowDates>

					<form
						noValidate
						onSubmit={onSubmit}
						style={{
							display: "flex",
							justifyContent: "flex-end",
							marginTop: 20
						}}
					>
						<Button variant="contained" onClick={handleClose} size="medium">
							{ls.cancel}
						</Button>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							size="medium"
							style={{ marginLeft: 10 }}
						>
							{loading ? <Spinner color="inherit" size={24} /> : ls.export}
						</Button>
					</form>
				</Container>
			)}
		/>
	);
};

export default ModalClicks;
