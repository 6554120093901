import * as actions from "store/actions/order/detail";

import { IDetailOrder } from "../types/detail";

const initialState: IDetailOrder = {
	item: null,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_ORDER: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_ORDER_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload
			};
		}
		case actions.GET_ORDER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
