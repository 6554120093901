/* eslint-disable consistent-return */
import { getErrors } from "helpers/error";
import _ from "lodash";
import * as api from "services/api";
import * as types from "store/actions/business/dataBank/types";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: types.SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: types.CLEAR_VALUES };
}

interface GetBanks {
	term?: string;
	skip?: number;
	take?: number;
}

export function getBanks({ term = "", skip = 0, take = 10 }: GetBanks) {
	return async (dispatch, getState) => {
		dispatch({
			type: types.GET_BANKS_DATA
		});
		try {
			const { token } = getState().login;
			if (!token) {
				return dispatch(setValue({ loading: false }));
			}
			const response = await api.sendGet(
				`/data-bank/bank?term=${term?.toLowerCase()}&skip=${skip}&take=${take}`,
				{
					Authorization: `Bearer ${token}`
				}
			);

			const result = await response.json();

			if (result.isValid) {
				dispatch({
					type: types.GET_BANKS_DATA_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: types.GET_BANKS_DATA_FAILED,
					payload: errors
				});
			}
		} catch (error) {
			dispatch({
				type: types.GET_BANKS_DATA_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function getDataBank(businessId: string) {
	return async (dispatch, getState) => {
		dispatch({ type: types.DATA_BANK_BUSINESS });

		try {
			const { login } = getState();

			const errors: any = {};
			dispatch(setValue({ errors }));
			if (_.keys(errors).length > 0) return;

			const response = await api.sendGet(`/data-bank/business/${businessId}`, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				let result = await response.json();
				dispatch({
					type: types.GET_DATA_BANK_BUSINESS_SUCCESS,
					payload: result.model
				});
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: types.GET_DATA_BANK_BUSINESS_FAILED,
					payload: errors
				});
			}
		} catch (error) {
			return dispatch({
				type: types.GET_DATA_BANK_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
