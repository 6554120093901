import { Box, Typography } from "@material-ui/core";
import React from "react";

interface TabPanelProps {
	value: number;
	index: number;
}
const TabPanel: React.FC<TabPanelProps> = ({
	children,
	value,
	index,
	...other
}) => (
	<Typography
		component="div"
		role="tabpanel"
		hidden={value !== index}
		id={`scrollable-auto-tabpanel-${index}`}
		aria-labelledby={`scrollable-auto-tab-${index}`}
		{...other}
	>
		{value === index && <Box p={3}>{children}</Box>}
	</Typography>
);

export default TabPanel;
