import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { MONEY } from "Components/NumberFormat";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	createConvenienceFee,
	setValue
} from "store/actions/convenienceFee";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceCreateForm: (() => void) & _.Cancelable = null;

function CreateForm() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { showFormModal, minValue, maxValue, fee, errors, loading } =
		useSelector<any, any>(c => c.convenienceFee);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceCreateForm) debounceCreateForm.cancel();

		debounceCreateForm = _.debounce(() => {
			dispatch(
				createConvenienceFee((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceCreateForm();
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={showFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.createConvenienceFee}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="minValue"
										required
										variant="outlined"
										name="minValue"
										InputProps={{
											inputComponent: MONEY
										}}
										value={minValue}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="maxValue"
										required
										variant="outlined"
										name="maxValue"
										InputProps={{
											inputComponent: MONEY
										}}
										value={maxValue}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="fee"
										required
										variant="outlined"
										name="fee"
										InputProps={{
											inputComponent: MONEY
										}}
										value={fee}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default CreateForm;
