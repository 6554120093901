import styled from "styled-components";

interface PropsFooter {
	isPJEnabled: boolean;
}

export const Footer = styled.div<PropsFooter>`
	display: flex;
	width: 100%;
	justify-content: ${props =>
		props.isPJEnabled ? "space-between" : "flex-end"};
	align-items: center;
	padding: 16px 0;

	& button {
		margin-left: 8px;
	}
`;
