import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import Actions from "Components/views/Dashboard/WiipoFlex/TreasuryAccount/Report/Actions";
import ReportDepositMenu from "Components/views/Dashboard/WiipoFlex/TreasuryAccount/ReportDepositMenu";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import FundsPaymentType from "models/fundsPaymentType";
import FundsStatus from "models/fundsStatus";
import FundsType from "models/fundsType";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { downloadNF } from "store/actions/treasuryAccountWiipoFlex/nf";
import { getQrCode } from "store/actions/treasuryAccountWiipoFlex/qrcode";
import { ITreasuryAccountTransferRequest } from "store/actions/treasuryAccountWiipoFlex/report";

import ModalEdit from "../../../../../../Components/views/Dashboard/WiipoFlex/TreasuryAccount/Edit/ModalEdit";
import ModalPdf from "../../../../../../Components/views/Dashboard/WiipoFlex/TreasuryAccount/Report/ModalPdf";
import ModalPix from "../../../../../../Components/views/Dashboard/WiipoFlex/TreasuryAccount/Report/ModalPix";

dayjs.extend(customParseFormat);

let getTransfersDebounced: (() => void) & _.Cancelable = null;

interface IProps {
	handleGetTransfers: (props: ITreasuryAccountTransferRequest) => void;
}

interface IPropsEdit {
	id: string;
	status: number;
	value: number;
	dueDate: string;
}

function TreasuryAccountWiipoFlexReportDeposit({ handleGetTransfers }: IProps) {
	const dispatch = useDispatch();
	const table = useRef(null);
	const [openModalPdf, setOpenModalPdf] = useState(false);
	const [openModalEdit, setOpenModalEdit] = useState(false);
	const [openModalPix, setOpenModalPix] = useState(false);
	const [linkPix, setLinkPix] = useState("");
	const [linkPdf, setLinkPdf] = useState("");
	const [typeableLine, setTypeableLine] = useState("");
	const [valuesEdit, setValuesEdit] = useState<IPropsEdit>();
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [toggleId, setToggleId] = useState("");
	const [loadingPix, setLoadingPix] = useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	const getToggleId = useCallback((id: string) => {
		setToggleId(id);
	}, []);

	const state = useSelector<any, any>(
		state => state.treasuryAccountWiipoFlexDeposit
	);

	const handleDownloadNF = useCallback(
		(id: string) => {
			dispatch(
				downloadNF({ id }, err => {
					if (err) {
						toastError(err);
					}
					setToggleId(null);
				})
			);
		},
		[dispatch]
	);

	const handleOpenModalPdf = useCallback((link: string, line: string) => {
		setTypeableLine(line);
		setLinkPdf(link);

		setOpenModalPdf(true);
	}, []);

	const handleOpenModalEdit = useCallback(
		(id: string, status: number, value: number, dueDate: string) => {
			setValuesEdit({ id, status, value, dueDate });
			setOpenModalEdit(true);
		},
		[]
	);

	const handleCloseModalEdit = useCallback(() => {
		handleGetTransfers({
			callback: () => {
				setOpenModalEdit(false);
			}
		});
	}, [handleGetTransfers]);

	const handleCloseModalPdf = useCallback(() => {
		setTypeableLine("");
		setLinkPdf("");

		setOpenModalPdf(false);
	}, []);

	const handleCloseModalPix = useCallback(() => {
		setTypeableLine("");
		setLinkPix("");

		setOpenModalPix(false);
	}, []);

	const handleOpenModalPix = useCallback(
		(id: string) => {
			setOpenModalPix(true);
			setLoadingPix(true);

			dispatch(
				getQrCode(id, (err, data) => {
					if (err) {
						toastError(JSON.parse(err));
						handleCloseModalPix();
					} else {
						setLinkPix(data);
					}
					setLoadingPix(false);
				})
			);
		},
		[dispatch, handleCloseModalPix]
	);

	const handleToggle = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
			anchorRef.current = e.currentTarget;
			setToggleId(id);
			setOpenMenu(!openMenu);
		},
		[openMenu, anchorRef]
	);

	const onClickAway = useCallback(() => setOpenMenu(false), []);

	const valueTotal = state.items.map(i => i.value).reduce((a, b) => a + b, 0);

	const { user } = useSelector<any, any>(s => s.login);

	const isAdmin = useMemo(() => isInRole(user, ["admin"]), [user]);

	const filterFundsPaymentType = useMemo(() => {
		if (isAdmin) {
			return Object.keys(FundsStatus);
		}

		return Object.keys(FundsStatus).filter(i => Number(i) !== 7);
	}, [isAdmin]);

	const filterItems = useMemo(() => {
		if (isAdmin) {
			return state.items;
		}
		return state.items.map(i => {
			if (i.status === 7) {
				return { ...i, status: 3 };
			}
			return i;
		});
	}, [isAdmin, state.items]);

	const columns = useMemo(
		() => [
			{
				Header: ls.id.toUpperCase(),
				id: "code",
				accessor: c => c.code,
				show: true,
				width: 140
			},
			{
				Header: ls.idNF,
				id: "invoiceNumber",
				accessor: c =>
					c.errorReason ? (
						<div>
							<TooltipLight title={c.errorReason} placement="top">
								<div
									style={{
										color: "red"
									}}
								>
									Erro
								</div>
							</TooltipLight>
						</div>
					) : (
						<div>{c.invoiceNumber}</div>
					),
				show: true,
				width: 140
			},
			{
				Header: ls.name,
				id: "business",
				accessor: c => c.business?.socialName,
				show: true,
				width: 190
			},
			{
				Header: ls.cnpj,
				id: "cnpj",
				accessor: c => c.business?.cnpj,
				show: true,
				width: 170
			},
			{
				Header: ls.treasuryAccountBusiness,
				id: "treasury",
				accessor: c => c.business?.treasuryAccountHolderId,
				show: true,
				width: 300
			},
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 140,
				show: true,
				sortMethod: (a, b) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					// eslint-disable-next-line no-nested-ternary
					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.dueDate,
				id: "dueDate",
				accessor: c =>
					dayjs(c.dueDate).add(3, "hour").format(ls.dateFormatShort),
				width: 140,
				show: true,
				sortMethod: (a, b) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds).add(3, "hour");
					let bD = dayjs(b, ls.dateFormatWithoutSeconds).add(3, "hour");

					// eslint-disable-next-line no-nested-ternary
					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.value,
				id: "value",
				format: c => c.value / 100,
				accessor: c => toStringCurrency(c.value / 100),
				Footer: () => <b>{toStringCurrency(valueTotal / 100)}</b>,
				width: 150,
				show: true
			},
			{
				Header: ls.type,
				id: "type",
				width: 180,
				show: true,
				accessor: c => ls[FundsType[c.type]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(FundsType).map(c => (
							<option key={c} value={c}>
								{ls[FundsType[c]]}
							</option>
						))}
					</select>
				)
			},
			{
				Header: `${ls.paymentType}`,
				id: "paymentType",
				width: 180,
				show: true,
				accessor: c => ls[FundsPaymentType[c.paymentType]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(FundsPaymentType).map(c => (
							<option key={c} value={c}>
								{ls[FundsPaymentType[c]]}
							</option>
						))}
					</select>
				)
			},
			{
				Header: ls.status,
				id: "status",
				sortable: false,
				show: true,
				width: 170,
				accessor: c => {
					if (c.status === 0) {
						return (
							<>
								{ls[FundsStatus[c.status]]}
								<Spinner style={{ marginLeft: 5 }} color="inherit" size={16} />
							</>
						);
					}
					return ls[FundsStatus[c.status]];
				},
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{filterFundsPaymentType.map(c => (
							<option key={c} value={c}>
								{ls[FundsStatus[c]]}
							</option>
						))}
					</select>
				)
			},
			{
				Header: ls.createdBy,
				id: "createdBy",
				accessor: c => (
					<p style={{ textAlign: "center", width: "100%" }}>
						{c.createdBy?.fullName || ls.none}{" "}
					</p>
				),
				width: 150,
				show: true
			},
			{
				Header: ls.updatedBy,
				id: "updatedBy",
				accessor: c => (
					<p style={{ textAlign: "center", width: "100%" }}>
						{c.updatedBy?.fullName || "-"}
					</p>
				),
				width: 150,
				show: true
			},
			{
				Header: ls.actions,
				id: "actions",
				accessor: c => (
					<div key={c.id}>
						<>
							<>
								<Actions
									c={c}
									loading={toggleId === c.id}
									getToggleId={getToggleId}
									handleDowloadNF={handleDownloadNF}
									handleOpenModalPdf={handleOpenModalPdf}
									handleOpenModalPix={handleOpenModalPix}
									handleToggle={handleToggle}
									moreAnchorRef={anchorRef}
									isAdmin={isAdmin}
								/>

								{toggleId === c.id && (
									<ReportDepositMenu
										anchorEl={anchorRef.current}
										onClickAway={onClickAway}
										fundId={c.id}
										type={c.type}
										shouldShowEdit={
											c.status !== 4 || (!isAdmin && c.status !== 7)
										}
										onClickEdit={() => {
											handleOpenModalEdit(c.id, c.status, c.value, c.dueDate);
										}}
										openMenu={openMenu}
									/>
								)}
							</>
						</>
					</div>
				),
				width: 160,
				show: true,
				filterable: false
			}
		],
		[
			valueTotal,
			filterFundsPaymentType,
			toggleId,
			getToggleId,
			handleDownloadNF,
			handleOpenModalPdf,
			handleOpenModalPix,
			handleToggle,
			isAdmin,
			onClickAway,
			openMenu,
			handleOpenModalEdit
		]
	);

	const handleFetchData = useCallback(
		tableState => {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getTransfersDebounced) {
				getTransfersDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");
				let date = null;

				if (creationDate?.value.startDate || creationDate?.value.endDate) {
					date = {
						startAt: creationDate.value.startDate,
						endAt: creationDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "creationDate",
						value: JSON.stringify(date)
					});
				}
			}
			let dueDate = filtered.find(c => c.id === "dueDate");

			if (dueDate?.value) {
				filtered = filtered.filter(c => c.id !== "dueDate");
				let date = null;

				if (dueDate?.value.startDate || dueDate?.value.endDate) {
					date = {
						startAt: dueDate.value.startDate,
						endAt: dueDate.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "dueDate",
						value: JSON.stringify(date)
					});
				}
			}

			const filterTable = filtered.reduce(
				(p, c) => `${p}&filters[${c.id}]=${c.value}`,
				""
			);

			getTransfersDebounced = _.debounce(
				() =>
					handleGetTransfers({
						skip: page * pageSize,
						take: pageSize,
						filter: filterTable,
						sort: sorted[0]?.id,
						desc: sorted[0]?.desc,
						toExport,
						callback
					}),
				1000
			);

			getTransfersDebounced();
		},
		[handleGetTransfers]
	);

	return (
		<div>
			<Helmet>
				<title>
					{ls.lastDeposits} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.lastDeposits}
				data={filterItems}
				pages={state.pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={state.loading}
				defaultSorted={[
					{
						id: "code",
						desc: true
					}
				]}
			/>

			<ModalPdf
				open={openModalPdf}
				handleClose={handleCloseModalPdf}
				pdfLink={linkPdf}
				digitableLine={typeableLine}
			/>

			<ModalPix
				open={openModalPix}
				handleClose={handleCloseModalPix}
				pixLink={linkPix}
				loadingPix={loadingPix}
			/>

			<ModalEdit
				open={openModalEdit}
				handleClose={handleCloseModalEdit}
				valuesEdit={valuesEdit}
			/>
		</div>
	);
}

export default TreasuryAccountWiipoFlexReportDeposit;
