import { Button, Fab } from "@material-ui/core";
import Report from "Components/Report";
import MultiSelect from "Components/Select/MultiSelect";
import ModalSelectImport from "Components/views/Dashboard/Business/Create/ModalSelectImport";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { getBusinessTypeFromString } from "helpers/business";
import { stringSort } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import businessStatus from "models/businessStatus";
import products from "models/products";
import statesBR from "models/statesBR";
import queryString from "query-string";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { Helmet } from "react-helmet";
import { MdAdd, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getBusinessesCategories } from "store/actions/business/category/report";
import { clearValues, getBusinesses } from "store/actions/business/report";

import useStyles from "./styles";

let getBusinessDebounced: (() => void) & _.Cancelable = null;
let getBusinessCategoriesDebounced: (() => void) & _.Cancelable = null;

function RenderBusinessReport({ history, match, location }) {
	const dispatch = useDispatch();
	const classes = useStyles({});

	const [open, setOpen] = useState(false);
	const { items: categories } = useSelector<any, any>(
		c => c.businessCategoryReport
	);

	const { user } = useSelector<any, any>(c => c.login);

	const query = queryString.parse(location.search);

	const { isStore, isCompany, isHeadquarters, isFranchise, type } =
		getBusinessTypeFromString(match.path);

	const getPageIdentifier = useCallback(
		(isHistory?: boolean) => {
			if (isStore) return isHistory ? "Store" : ls.stores;
			if (isCompany) return isHistory ? "Company" : ls.companies;
			if (isHeadquarters) return isHistory ? "Headquarters" : ls.headquarters;
			return isHistory ? "Franchise" : ls.franchises;
		},
		[isStore, isCompany, isHeadquarters]
	);

	const getAccessorLabel = useCallback(c => {
		if (c.phoneNumber) return c.phoneNumber;
		if (c.responsible && c.responsible.phoneNumber)
			return c.responsible.phoneNumber;
		return "Não informado";
	}, []);
	const filterType = useMemo(() => {
		if (isStore) return 1;
		if (isCompany) return 2;
		if (isHeadquarters) return 4;
		return 8;
	}, [isStore, isCompany, isHeadquarters]);

	const table = useRef(null);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch, isStore, isCompany, isHeadquarters, isFranchise]);

	const { items, pages, loading } = useSelector<any, any>(
		s => s.businessReport
	);

	const validService = useCallback((number: number) => {
		if (number !== 3 && number !== 5 && number !== 7) {
			return false;
		}
		return true;
	}, []);

	const columns = useMemo(
		() =>
			[
				{
					Header: ls.code,
					accessor: "code",
					width: 80,
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.name,
					id: "name",
					accessor: c => {
						let { name } = c;
						if (c.socialName && name !== c.socialName)
							name += `(${c.socialName})`;

						return name;
					},
					width: 300,
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.phoneNumber,
					id: "phoneNumber",
					accessor: c => getAccessorLabel(c),
					width: 180,
					show: true
				},
				{
					Header: ls.cnpj,
					id: "cnpj",
					accessor: c => c.cnpj ?? "Não informado",
					width: 160,
					show: true
				},
				{
					Header: ls.address,
					id: "address",
					accessor: c =>
						c.address
							? `${c.address.street ? c.address.street : ls.notDefined}
						 ${c.address.number ? `- ${c.address.number}` : "Sem número"}
						 ${c.address.complement ? `- ${c.address.complement}` : ""}`
							: ls.notDefined,
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.city,
					id: "city",
					accessor: c => (c.address ? c.address.city : ls.notDefined),
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.economicGroup,
					id: "headquartersName",
					accessor: c => (
						<div className={classes.textAlignCenter}>
							{c.headquarters?.socialName || ls.none}
						</div>
					),
					show: false,
					width: 170
				},
				{
					Header: ls.product,
					id: "service",
					accessor: c => (
						<div className={classes.textAlignCenter}>
							{products[c.service] || ls.none}{" "}
						</div>
					),
					Filter: ({ filter, onChange }) => (
						<MultiSelect filter={filter} onChange={onChange} />
					),
					width: 250,
					show: false
				},
				{
					Header: ls.firstRecharge,
					id: "firstTransfer",
					accessor: c => (
						<div className={classes.textAlignCenter}>
							{validService(c.service)
								? dayjs(c.firstTransfer?.creationDate).format(
										ls.dateFormatWithoutSeconds
								  )
								: "-"}
						</div>
					),
					show: false,
					width: 170,
					filterable: false
				},
				{
					Header: ls.lastRecharge,
					id: "lastTransfer",
					accessor: c => (
						<div className={classes.textAlignCenter}>
							{validService(c.service)
								? dayjs(c.lastTransfer?.creationDate).format(
										ls.dateFormatWithoutSeconds
								  )
								: "-"}
						</div>
					),
					show: false,
					width: 170,
					filterable: false
				},

				{
					Header: ls.state,
					id: "state",
					accessor: c => (c.address ? c.address.state : ls.notDefined),
					sortMethod: stringSort,
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter?.value ?? query?.state ?? "all"}
						>
							<option value="">Todos</option>
							{Object.keys(statesBR).map(c => (
								<option key={c} value={c}>
									{statesBR[c]}
								</option>
							))}
						</select>
					),
					show: true
				},
				// {
				// 	Header: ls.type,
				// 	id: "type",
				// 	accessor: c => ls[businessType[c.type]],
				// 	Filter: ({ filter, onChange }) => (
				// 		<select
				// 			onChange={event => onChange(event.target.value)}
				// 			style={{ width: "100%" }}
				// 			value={filter ? filter.value : "all"}
				// 		>
				// 			<option value="">Todos</option>
				// 			{Object.keys(businessType).map(c => (
				// 				<option key={c} value={c}>
				// 					{ls[businessType[c]]}
				// 				</option>
				// 			))}
				// 		</select>
				// 	)
				// },
				isStore
					? {
							Header: ls.category,
							id: "category",
							accessor: c => (c.category ? c.category.name : ls.notDefined),
							sortMethod: stringSort,
							Filter: ({ filter, onChange }) => (
								<select
									onChange={event => onChange(event.target.value)}
									style={{ width: "100%" }}
									value={filter?.value ?? query?.category ?? "all"}
								>
									<option value="">Todos</option>
									{categories.map(c => (
										<option key={c.id} value={c.name}>
											{c.name}
										</option>
									))}
								</select>
							),
							width: 120
					  }
					: null,
				{
					Header: ls.status,
					id: "status",
					accessor: c => ls[businessStatus[c.status]],
					Filter: ({ filter, onChange }) => (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter?.value ?? query?.status ?? "all"}
						>
							<option value="">Todos</option>
							{Object.keys(businessStatus).map(c => (
								<option key={c} value={c}>
									{ls[businessStatus[c]]}
								</option>
							))}
						</select>
					),
					width: 200,
					show: true
				},
				{
					Header: ls.actions,
					id: "actions",
					accessor: c => (
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								flex: 1
							}}
						>
							<Link to={`/${type}/${c.id}/Edit`}>
								<MdEdit />
							</Link>
						</div>
					),
					filterable: false,
					width: 80,
					show: true
				}
			].filter(c => c),
		[isStore]
	);

	const isAdmin = useMemo(() => isInRole(user, ["admin"]), [user]);

	const renderHeaderRightComponent = () => {
		if (!isStore || !isAdmin) return null;

		return (
			<div className={classes.headerRightContainer}>
				<Button
					color="primary"
					variant="text"
					onClick={() => history.push("/Store/Category")}
				>
					Categorias
				</Button>
			</div>
		);
	};

	const handleFetchBusinessCategory = () => {
		if (getBusinessCategoriesDebounced) {
			getBusinessCategoriesDebounced.cancel();
		}

		getBusinessCategoriesDebounced = _.debounce(
			() =>
				dispatch(
					getBusinessesCategories(null, null, null, null, null, null, null)
				),
			500
		);

		getBusinessCategoriesDebounced();
	};

	useEffect(() => {
		handleFetchBusinessCategory();
	}, []);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBusinessDebounced) {
			getBusinessDebounced.cancel();
		}

		if (!sorted) sorted = [];

		getBusinessDebounced = _.debounce(
			() =>
				dispatch(
					getBusinesses(
						page * pageSize,
						pageSize,
						filtered
							.filter(i => i.value)
							.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								`&filters[type]=${filterType}`
							),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					)
				),
			500
		);

		getBusinessDebounced();
	};

	useEffect(() => {
		if (table.current) {
			let { page, pageSize, sorted, filtered } = table.current.state;

			if (getBusinessDebounced) {
				getBusinessDebounced.cancel();
			}

			if (!sorted) sorted = [];

			getBusinessDebounced = _.debounce(
				() =>
					dispatch(
						getBusinesses(
							page * pageSize,
							pageSize,
							filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								`&filters[type]=${filterType}`
							),
							sorted[0]?.id,
							sorted[0]?.desc
						)
					),
				500
			);

			getBusinessDebounced();
		}
	}, [isStore, isCompany, isHeadquarters, isFranchise, dispatch, filterType]);

	return (
		<div>
			<Helmet>
				<title>
					{getPageIdentifier()} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				title={getPageIdentifier()}
				data={items}
				tableRef={table}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				loading={loading}
				columns={columns}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				onRowClicked={row =>
					history.push(`/${isStore ? "Store" : "Company"}/${row.id}`)
				}
				defaultSorted={[
					{
						id: "code",
						desc: true
					}
				]}
				headerRightComponent={renderHeaderRightComponent()}
			/>
			<ModalSelectImport
				open={open}
				handleClose={() => setOpen(false)}
				pageIdentifier={getPageIdentifier(true)}
				historyPush={() => history.push(`/${getPageIdentifier(true)}/Create`)}
			/>
			<Fab
				color="primary"
				aria-label="Add"
				className={classes.fab}
				onClick={() => {
					if (getPageIdentifier(true) === "Store")
						history.push(`/${getPageIdentifier(true)}/Create`);
					setOpen(true);
				}}
			>
				<MdAdd size={24} />
			</Fab>
		</div>
	);
}

export default RenderBusinessReport;
