import TooltipLight from "Components/TooltipLight";
import styled from "styled-components";

export const Container = styled.div`
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	opacity: 1;
	padding: 20px;
`;

export const Title = styled.h1`
	margin: 0;
	margin-bottom: 20px;
	text-align: left;
	font: normal normal normal 24px Exo;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
`;

export const Line = styled.div`
	background-color: #707070;
	height: 1px;
	width: 100%;
	margin-bottom: 20px;
`;

export const Label = styled.p`
	text-align: left;
	font-size: 1rem;
	font-family: "Exo", sans-serif;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0px;
	color: #353535;
	opacity: 1;
	margin-right: 15px;
`;

export const RowCodCli = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 40px;
	height: 60px;
	width: 100%;
`;

export const BackgroundInput = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 70%;
`;

export const GoToManual = styled.button`
	border: none;
	background: none;
	text-align: left;
	letter-spacing: 0px;
	color: #5734d9;
	opacity: 1;
	cursor: pointer;
	font-size: 1rem;
`;

export const ButtonAtt = styled(TooltipLight)`
	background: #5734d9 0% 0% no-repeat padding-box;
	border-radius: 8px;
	opacity: 1;

	&:hover {
		background-color: #5734d9;
	}
`;
