import * as actions from "store/actions/business/policy";

import { IBusinessPolicy } from "../types/policy";

const initialState: IBusinessPolicy = {
	item: null,
	properties: [],
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.UPLOAD_BUSINESS_POLICY_REQUEST: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}
		case actions.UPLOAD_BUSINESS_POLICY_SUCCESS: {
			return {
				...initialState,
				loading: {
					...state.loading,
					edit: false
				}
			};
		}
		case actions.UPLOAD_BUSINESS_POLICY_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: false
				},
				errors: payload
			};
		}
		case actions.UPLOAD_BUSINESS_POLICY_TRANSFER_REQUEST: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}
		case actions.UPLOAD_BUSINESS_POLICY_TRANSFER_SUCCESS: {
			return {
				...initialState,
				loading: {
					...state.loading,
					edit: false
				}
			};
		}
		case actions.UPLOAD_BUSINESS_POLICY_TRANSFER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: false
				},
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
