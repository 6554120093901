import { makeStyles, Modal } from "@material-ui/core";
import styled from "styled-components";

interface PropsContainerModal {
	backgroundNone?: boolean;
}

export const ContainerModal = styled(Modal)<PropsContainerModal>`
	${props =>
		props.backgroundNone
			? ".MuiBackdrop-root{background: none !important}"
			: ""}
`;

export const Container = styled.div``;

export const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		minWidth: "calc(50%)",
		padding: theme.spacing(1, 2),
		borderRadius: 6
	}
}));

export const RowClose = styled.div`
	width: 100%;
	display: flex;
	align-items: flex-end;
`;

export const ButtonClose = styled.button`
	background: none;
	border: none;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
	font-size: 20px;
	color: #707070;
	padding: 10px;
`;
