import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "Components/Checkbox";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import iconType from "models/iconType";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/business/category/edit";

import styles from "./styles";

let debounceusinessCategoryEdit: (() => void) & _.Cancelable = null;

export function RenderBusinessCategoryEdit({
	classes,
	state,
	setValue,
	clearValues,
	getBusinessCategory,
	editBusinessCategory,
	history,
	match: {
		params: { id }
	}
}) {
	const [formRef, setFormRef] = useState(null);

	useEffect(() => {
		getBusinessCategory(id);
	}, [getBusinessCategory, id]);

	const handleChange = (name, value) => {
		setValue({
			[name]: value
		});
	};

	const onSubmit = e => {
		e.preventDefault();
		if (!formRef.checkValidity()) {
			formRef.reportValidity();
		} else {
			if (debounceusinessCategoryEdit) debounceusinessCategoryEdit.cancel();

			debounceusinessCategoryEdit = _.debounce(() => {
				editBusinessCategory(err => {
					if (err) {
						toastError(JSON.stringify(err));
					} else {
						history.push("/Store/Category");
					}
				});
			}, 1000);

			debounceusinessCategoryEdit();
		}
	};

	const { name, color, icon, iconSource, isVisible, loading, errors } = state;

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<Typography variant="h5">{ls.editBusinessCategory}</Typography>
					<hr className={classes.line} />

					<form onSubmit={onSubmit} ref={ref => setFormRef(ref)}>
						<Grid container spacing={2} className={classes.form}>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextInput
									id="name"
									variant="outlined"
									required
									name="name"
									disabled={loading.edit}
									value={name}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextInput
									id="color"
									variant="outlined"
									name="color"
									disabled={loading.edit}
									value={color}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<TextInput
									id="icon"
									variant="outlined"
									name="icon"
									disabled={loading.edit}
									value={icon}
									errors={errors}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Select
									errors={errors}
									id="iconSource"
									name="iconSource"
									value={iconSource}
									onChange={handleChange}
									options={Object.keys(iconType).map(c => ({
										id: c,
										value: iconType[c]
									}))}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} lg={3}>
								<Checkbox
									id="isVisible"
									name="isVisible"
									checked={isVisible}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>

						<hr className={classes.line} />
						<Grid container justify="flex-end">
							<Button
								variant="contained"
								color="default"
								className={classes.button}
								disabled={loading.edit}
								onClick={clearValues}
							>
								{ls.clear}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								disabled={loading.edit}
								type="submit"
							>
								{ls.save}
								{loading.edit && <Spinner color="secondary" size={16} />}
							</Button>
						</Grid>
					</form>
				</Paper>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = ({ businessCategoryEdit: state }) => ({
	state
});

const mapDispatchToProps = {
	...actions
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(RenderBusinessCategoryEdit));
