import CircularProgress, {
	CircularProgressProps
} from "@material-ui/core/CircularProgress";
import React, { useEffect, useState } from "react";

type Props = CircularProgressProps;

export default function Spinner(props: Props) {
	const [completed, setCompleted] = useState(0);

	const { ...rest } = props;

	useEffect(() => {
		const progress = () => {
			setCompleted(prev => (prev >= 100 ? 0 : prev + 1));
		};

		let timer = setInterval(progress, 20);
		return () => {
			clearInterval(timer);
		};
	}, []);

	return <CircularProgress {...rest} variant="determinate" value={completed} />;
}
