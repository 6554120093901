export default {
	material: "Material",
	"material-community": "Material Community",
	"font-awesome": "Font Awesome",
	octicon: "Octicon",
	ionicon: "Ionicon",
	foundation: "Foundation",
	evilicon: "Evilicon",
	"simple-line-icon": "Simple Line Icon",
	zocial: "Zocial",
	entypo: "Entypo",
	feather: "Feather",
	antdesign: "Antdesign"
};
