import styled from "styled-components";

export const Container = styled.div`
	max-height: 265px;
	width: 270px;
	border-radius: 9px;
`;

export const Header = styled.div`
	height: 50px;
	width: 100%;
	background: #5734d9;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	text-align: left;
	color: #7d7d7d;
	font-size: 11px;
`;

export const RowHeader = styled.div`
	display: flex;
	flex-direction: row;
`;

export const TitleHeader = styled.div`
	font-size: 13px;
	color: #fff;
	margin-top: 10px;
`;

export const SubTitleHeader = styled.div`
	font-size: 8px;
	color: #fff;
`;

export const SumGreen = styled.div`
	width: 12px;
	height: 14px;
	background-color: #3cb371;
	color: #fff;
	display: flex;
	align-items: center;
	margin-right: 5px;
	margin-bottom: 2px;
	padding-left: 2px;
	padding-top: 3px;
`;

export const SubTractRed = styled.div`
	width: 12px;
	height: 14px;
	background-color: #b30012;
	color: #fff;
	display: flex;
	align-items: center;
	margin-right: 5px;
	margin-bottom: 2px;
	padding-left: 4px;
`;

export const TextWhite = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 5px 5px 35px;
	color: #7d7d7d;
`;

export const TextGrey = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 5px 5px 35px;
	background-color: #efefef;
`;

export const TextBold = styled.div`
	color: #7d7d7d;
	font-weight: bold;
`;

export const ImageCart = styled.img`
	height: 12px;
	width: 14px;
	color: #5734d9;
	margin-right: 5px;
`;
