import {
	Button,
	ClickAwayListener,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grow,
	IconButton,
	MenuItem,
	MenuList,
	Paper,
	Popper
} from "@material-ui/core";
import ls from "Localization";
import React, { useCallback, useRef, useState } from "react";
import { MdMoreVert } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { createModeration } from "store/actions/moderation/create";

function Menu({ item }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [openMenu, setOpenMenu] = useState(false);
	const [alertModal, setAlertModal] = useState(null);

	const menuRef = useRef(null);

	const handleMenuClose = event => {
		if (menuRef.current && menuRef.current.contains(event.target)) {
			return;
		}

		setOpenMenu(false);
	};

	function handleMenuListKeyDown(event) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenMenu(false);
		}
	}

	const handleAddModeration = useCallback(() => {
		dispatch(
			createModeration(
				{
					saleId: item.id,
					status: 2,
					category: 0,
					originalAmount: 0,
					amount: 0,
					clientId: item.clientId,
					companyId: item.companyId
				},
				(err, model) => {
					if (err) {
						alert(err);
					} else {
						history.push(`/Moderation/${model.id}`);
					}
				}
			)
		);
	}, [item]);

	const handleShowAddModerationConfirm = () => {
		setAlertModal(
			<Dialog
				open
				onClose={() => setAlertModal(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Tem certeza que deseja criar uma moderação para essa venda?
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Após a confirmação uma moderação sera criada para essa venda.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleAddModeration()}
						color="primary"
						autoFocus
					>
						Confirmar
					</Button>
					<Button onClick={() => setAlertModal(null)} color="primary">
						Cancelar
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<>
			<IconButton
				ref={menuRef}
				aria-controls={openMenu ? "menu-list-grow" : undefined}
				aria-haspopup="true"
				onClick={() => setOpenMenu(p => !p)}
			>
				<MdMoreVert />
			</IconButton>
			<Popper
				open={openMenu}
				anchorEl={menuRef.current}
				role={undefined}
				transition
				disablePortal
				placement="bottom-end"
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "right top" : "right bottom"
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleMenuClose}>
								<MenuList
									autoFocusItem={openMenu}
									id="menu-list-grow"
									onKeyDown={handleMenuListKeyDown}
								>
									<MenuItem onClick={handleShowAddModerationConfirm}>
										{ls.addModeration}
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			{alertModal}
		</>
	);
}

export default Menu;
