import {
	Button,
	Card,
	CardContent,
	Checkbox,
	Fade,
	Grid,
	Paper,
	TextField,
	Typography
} from "@material-ui/core";
import history from "AppHistory";
import clsx from "clsx";
import { modalAlert } from "Components/Modals/ModalAlert";
import Spinner from "Components/Spinner";
import Tooltip from "Components/Tooltip";
import UserVerificationCodeModal from "Components/UserVerificationCodeModal";
import { validateEmail } from "helpers/email";
import { toastError } from "helpers/toast";
import ls from "Localization";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { MdInfo } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import {
	clearValues,
	confirmCode,
	resendCode,
	setValue,
	submitLogin
} from "store/actions/login";

import useStyles from "./styles";

export enum ApprovalMethod {
	App = 0,
	WhatsApp = 1,
	Sms = 2,
	PrePaidCard = 3,
	CardSe = 4,
	Email = 5
}

export function Login() {
	const dispatch = useDispatch();
	const [openVerificationCodeModal, setOpenVerificationCodeModal] =
		useState(false);
	const [approvalMethod, setApprovalMethod] = useState(ApprovalMethod.Email);
	const [verificationCodeStep, setVerificationCodeStep] = useState(1);
	const [code, setCode] = useState("");
	const [userId, setUserId] = useState("");
	const [disableResendButton, setDisableResendButton] = useState(true);

	const classes = useStyles({});

	const { username, password, message, loading, errors, trustUserAgentClient } =
		useSelector<any, any>(s => s.login);

	const isFirstAccess: boolean =
		errors.reset === "you need to reset your password";

	if (isFirstAccess) {
		modalAlert({
			title: ls.almost,
			body: ls.firstPassword,
			onClickOk: () => {
				history.push({
					pathname: "/RecoverPassword",
					search: "",
					state: { email: username }
				});
			}
		});
	}

	const isLocked: boolean = errors.default === "The account is locked out";

	if (isLocked) {
		modalAlert({
			title: ls.userBlocked,
			body: ls.userBlockedMessage,
			onClickOk: () => {
				history.push({
					pathname: "/RecoverPassword",
					search: "",
					state: { email: username }
				});
			}
		});
	}

	const form = useRef<HTMLFormElement>(null);

	const change = useCallback(
		(name: string, value: any) => {
			if (name === "trustUserAgentClient") {
				localStorage.setItem("trustUserAgentClient", value.toString());
			}
			dispatch(setValue({ [name]: value }));
		},
		[dispatch]
	);

	const onClickResendCode = useCallback(async () => {
		await resendCode(userId, approvalMethod);
	}, [approvalMethod, userId]);

	const onClickSubmitCode = useCallback(
		verificationCodeStep => {
			if (verificationCodeStep === 1) {
				dispatch(
					submitLogin(userId => {
						if (userId) {
							setUserId(userId);
							setVerificationCodeStep(2);
						} else {
							setVerificationCodeStep(1);
							setOpenVerificationCodeModal(false);
						}
					}, approvalMethod)
				);
			} else {
				dispatch(confirmCode(userId, code, trustUserAgentClient));
			}
		},
		[dispatch, approvalMethod, userId, code, trustUserAgentClient]
	);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const trustUserAgentClient = localStorage.getItem("trustUserAgentClient");

		if (!form.current.checkValidity()) {
			form.current.reportValidity();
		} else if (trustUserAgentClient === "true") {
			dispatch(
				submitLogin((userId, errors) => {
					if (userId) {
						setUserId(userId);
						setVerificationCodeStep(2);
						setOpenVerificationCodeModal(true);
					}
				}, approvalMethod)
			);
		} else {
			setVerificationCodeStep(1);
			setOpenVerificationCodeModal(true);
		}
	};

	const onCloseModal = useCallback(() => {
		setOpenVerificationCodeModal(false);
		setApprovalMethod(ApprovalMethod.Email);
		setVerificationCodeStep(1);
	}, []);

	useEffect(() => {
		dispatch(clearValues());
		const trustUserAgentClient = localStorage.getItem("trustUserAgentClient");
		if (trustUserAgentClient === "true") {
			change("trustUserAgentClient", true);
		}
	}, [dispatch]);

	useEffect(() => {
		if (verificationCodeStep === 2) {
			setTimeout(() => {
				setDisableResendButton(false);
			}, 60000);
		}
	}, [verificationCodeStep]);

	return (
		<>
			<div className={classes.root}>
				{message && (
					<Grid className={classes.alertContainer} container justify="center">
						<Grid item xs={11} sm={9} md={7} lg={5}>
							<Paper className={classes.alert}>
								<MdInfo className={classes.alertIcon} />
								<Typography className={classes.alertText}>
									{message}.
								</Typography>
							</Paper>
						</Grid>
					</Grid>
				)}

				<Helmet>
					<title>
						{ls.loginIn} {ls.appName}
					</title>
				</Helmet>
				<Grid
					className={clsx(classes.container, "card-box")}
					container
					justify="center"
					alignItems="center"
				>
					<Card className={classes.card}>
						<Grid container className={classes.logoContainer} justify="center">
							<Grid item className="panel-heading">
								<img
									src="/img/logo_wiipo_color.png"
									style={{ width: 250 }}
									alt="logo"
								/>
							</Grid>
						</Grid>
						<CardContent>
							<form className="form-signin" ref={form} onSubmit={onSubmit}>
								<Grid container justify="center">
									<Grid item xs={12}>
										<TextField
											id="username"
											label="E-mail"
											type="email"
											className={classes.textField}
											value={username}
											onChange={e => change("username", e.target.value)}
											margin="normal"
											fullWidth
											required
											error={errors["username"] !== undefined}
											helperText={errors["username"]}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="password"
											label="Senha"
											type="password"
											className={classes.textField}
											value={password}
											onChange={e => change("password", e.target.value)}
											margin="normal"
											fullWidth
											required
											error={errors["password"] !== undefined}
											helperText={errors["password"]}
										/>
									</Grid>
									{username &&
										validateEmail(username) &&
										password &&
										password.length > 6 && (
											<Fade enter in timeout={{ enter: 1000 }}>
												<Grid
													alignItems="center"
													direction="row"
													container
													xs={12}
													style={{ marginBottom: 12 }}
												>
													<Checkbox
														checked={trustUserAgentClient}
														name={"trustUserAgentClient"}
														onChange={e =>
															change("trustUserAgentClient", e.target.checked)
														}
													/>
													<Typography>Confiar neste navegador?</Typography>
												</Grid>
											</Fade>
										)}
									<Grid item md={8}>
										<Tooltip title="Preencha os campos corretamente para ativar o botão">
											<Button
												type="submit"
												className={classes.submitButton}
												disabled={
													!username ||
													!validateEmail(username) ||
													!password ||
													password.length < 6 ||
													loading.login ||
													loading.user
												}
											>
												{ls.signIn}
												{loading.login || loading.user ? (
													<Spinner
														style={{ height: 15, width: 15, margin: 5 }}
													/>
												) : null}
											</Button>
										</Tooltip>
									</Grid>

									<Grid item xs={12} className={classes.newAccount}>
										<Link to="/RecoverPassword">{ls.recoverPassword}</Link>
									</Grid>
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</div>
			<UserVerificationCodeModal
				openVerificationCodeModal={openVerificationCodeModal}
				onCloseModal={onCloseModal}
				approvalMethod={approvalMethod}
				setApprovalMethod={setApprovalMethod}
				verificationCodeStep={verificationCodeStep}
				setCode={setCode}
				disableResendButton={disableResendButton}
				onClickResendCode={onClickResendCode}
				onClickSubmitCode={onClickSubmitCode}
				loading={loading.login || loading.user}
				code={code}
			/>
		</>
	);
}

export default Login;
