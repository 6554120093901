import { Button, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Report from "Components/Report";
import Spinner from "Components/Spinner";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import { CREDIT_TYPES } from "models/creditOrigin";
import creditStatus from "models/creditStatus";
import saleStatus from "models/saleStatus";
import React, { useEffect, useState } from "react";
import { MdStore, MdViewModule } from "react-icons/md";
import { connect } from "react-redux";
import * as actions from "store/actions/credit/detail";

// import Report from "Components/Report";
// import installmentStatus from "models/installmentStatus";
import EditCredit from "../Edit";
import CategoriesForm from "./Policy/Categories";
import StoresForm from "./Policy/Stores";
import styles from "./styles";

export function CreditDetail({
	classes,
	state: { item, loading },
	login,
	clearValues,
	getCredit,
	setValue,
	match: {
		params: { id }
	}
}) {
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		clearValues();
	}, [clearValues]);

	useEffect(() => {
		getCredit(id);
	}, [getCredit, id]);

	const handleCloseEdit = () => {
		getCredit(id);
		setShowEdit(false);
	};

	const handleChange = (id: string, value: any) => {
		setValue({ [id]: value });
	};

	const handleEdit = (id, include) => {
		handleChange("loading", { [id]: true });
		getCredit(item.id, include, (err, model) => {
			if (err) {
				console.log(err);
			} else {
				handleChange("loading", { [id]: false });
				handleChange(id, Boolean(model));
			}
		});
	};

	if (!item) return null;

	let {
		creationDate,
		company,
		expirationDate,
		value,
		minValue,
		startDate,
		user,
		status,
		origin,
		description,
		uses,
		historic
	} = item;

	const isAdmin = isInRole(login.user, ["admin"]);

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.creditData}</Typography>

						<div>
							{loading["showCategoryFormModal"] ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() =>
										handleEdit("showCategoryFormModal", "categories")
									}
								>
									<MdViewModule />
								</IconButton>
							)}
							{loading["showStoreFormModal"] ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showStoreFormModal", "stores")}
								>
									<MdStore />
								</IconButton>
							)}
							{isAdmin && (
								<Button color="primary" onClick={() => setShowEdit(true)}>
									{ls.edit}
								</Button>
							)}
						</div>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.creationDate}</strong>:{" "}
							{dayjs(creationDate).format(ls.dateFormatWithoutSeconds)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.client}</strong>: {user?.fullName}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.company}</strong>: {company?.displayName}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.status}</strong>: {ls[creditStatus[status]]}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.origin}</strong>: {CREDIT_TYPES[origin]}
						</Grid>

						<Grid item xs={12} />

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.startAt}</strong>:{" "}
							{startDate
								? dayjs(startDate).format(ls.dateFormatWithoutSeconds)
								: "Sem data de início"}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.expirationDate}</strong>:{" "}
							{expirationDate
								? dayjs(expirationDate).format(ls.dateFormatWithoutSeconds)
								: "Não expira"}
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={2}>
							<strong>{ls.value}</strong>: {toStringCurrency(value / 100)}
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={3}>
							<strong>{ls.minValue}</strong>: {toStringCurrency(minValue / 100)}
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={12}>
							<strong>{ls.description}</strong>: {description}
						</Grid>
					</Grid>

					<EditCredit
						open={showEdit}
						item={item}
						handleClose={handleCloseEdit}
					/>

					<CategoriesForm />
					<StoresForm />
				</Paper>
			</Grid>

			<Grid item xs={11}>
				<Report
					title={ls.uses}
					data={uses || []}
					PaginationComponent={_ => null}
					defaultPageSize={(uses || []).length || 5}
					columns={[
						{
							Header: ls.paymentDay,
							id: "payDate",
							accessor: c => dayjs(c.payDate).format(ls.dateFormatShort),
							width: 140,
							sortMethod: (a, b, desc: boolean) => {
								let aD = dayjs(a, ls.dateFormatShort);
								let bD = dayjs(b, ls.dateFormatShort);

								return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
							}
						},
						{
							Header: ls.installment,
							id: "installment",
							accessor: c => c.number + 1,
							width: 120
						},
						{
							Header: ls.sale,
							id: "sale",
							accessor: c => c.sale?.code ?? "",
							width: 120
						},
						{
							Header: ls.value,
							id: "value",
							accessor: c => toStringCurrency(c.value / 100),
							width: 120
						},
						{
							Header: ls.fee,
							id: "fee",
							accessor: c => toStringCurrency(c.fee / 100),
							width: 120
						},
						{
							Header: "Status da venda",
							id: "status",
							sortable: false,
							accessor: c => ls[saleStatus[c.sale.status]],
							width: 120
						}
					]}
				/>
			</Grid>

			<Grid item xs={11}>
				<Report
					title={ls.historic}
					data={historic || []}
					PaginationComponent={_ => null}
					defaultPageSize={(historic || []).length || 5}
					columns={[
						{
							Header: ls.creationDate,
							id: "creationDate",
							accessor: c =>
								dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
							width: 140,
							sortMethod: (a, b, desc: boolean) => {
								let aD = dayjs(a, ls.dateFormatWithoutSeconds);
								let bD = dayjs(b, ls.dateFormatWithoutSeconds);

								return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
							}
						},
						{
							Header: ls.description,
							id: "message",
							accessor: c => c.message
						},
						{
							Header: ls.oldStatus,
							id: "oldStatus",
							sortable: false,
							accessor: c => ls[creditStatus[c.oldStatus]],
							width: 120
						},
						{
							Header: ls.newStatus,
							id: "newStatus",
							sortable: false,
							accessor: c => ls[creditStatus[c.newStatus]],
							width: 120
						},
						{
							Header: ls.user,
							id: "user",
							sortable: false,
							accessor: c =>
								c.user
									? c.user.fullName.indexOf("Admin")
										? c.user.fullName
										: c.user.email
									: "",
							width: 200
						}
					]}
				/>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = ({ creditDetail: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = {
	...actions
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(CreditDetail));
