import ModalSelector from "Components/ModalSelector";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editAllowanceUsers,
	setValue
} from "store/actions/allowance";
import { getUsersTiny } from "store/actions/user/report";

let debounceUsersForm: (() => void) & _.Cancelable = null;

function UsersForm({ business }) {
	const dispatch = useDispatch();

	const { tiny } = useSelector<any, any>(c => c.userReport);
	const { showUserFormModal, users, loading } = useSelector<any, any>(
		c => c.allowance
	);

	useEffect(() => {
		if (showUserFormModal)
			dispatch(getUsersTiny(`&filters[businessId]=${business.id}`));
	}, [dispatch, business, showUserFormModal]);

	const handleClose = () => dispatch(setValue({ showUserFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ userId: u.id, status: u.status }));

		if (debounceUsersForm) debounceUsersForm.cancel();

		debounceUsersForm = _.debounce(() => {
			dispatch(
				editAllowanceUsers(data, err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceUsersForm();
	};

	if (!showUserFormModal) return null;

	return (
		<ModalSelector
			open={showUserFormModal}
			id="user-form-modal"
			loading={loading}
			data={(tiny || [])
				.filter(c => c.name !== "Admin")
				.map(c => ({
					id: c.id,
					name: c.fullName,
					status: users.find(d => d.userId === c.id && d.status) ? 1 : 0
				}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="categories"
		/>
	);
}

export default UsersForm;
