import products from "models/products";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { AiOutlineDown } from "react-icons/ai";

import {
	BackgroundSelected,
	ButtonOpenSelect,
	Container,
	ContainerOption,
	RowCheckbox
} from "./styles";

interface PropsMultiSelect {
	filter: any;
	onChange: any;
}

const MultiSelect: React.FC<PropsMultiSelect> = ({ filter, onChange }) => {
	const [selectedValues, setSelectedValues] = useState([]);
	const ref = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	useEffect(() => {
		if (!filter) {
			setSelectedValues([]);
		}
	}, [filter]);

	const handleToggleSelect = () => {
		setIsOpen(!isOpen);
	};

	const filterProduct = Object.keys(products).filter(
		i => i !== "0" && i !== "3" && i !== "5" && i !== "6" && i !== "7"
	);

	const handleChangeValue = useCallback(
		(selectValue: string[], value: string) => {
			const isWiipo = selectValue.filter(i => i === "1").length > 0;
			const isClubWiipo = selectValue.filter(i => i === "4").length > 0;
			const isWiipoRequest = selectValue.filter(i => i === "2").length > 0;

			if (isWiipo && !isClubWiipo && isWiipoRequest) {
				return "3";
			}
			if (isWiipo && isClubWiipo && !isWiipoRequest) {
				return "5";
			}
			if (!isWiipo && isClubWiipo && !isWiipoRequest) {
				return "4";
			}
			if (isWiipo && isClubWiipo && isWiipoRequest) {
				return "7";
			}

			return value;
		},
		[]
	);

	const handleReplaceValue = useMemo(() => {
		const newSelected = selectedValues.map(i => i.replace(`${i}`, products[i]));
		return newSelected.join(",");
	}, [selectedValues]);

	const onChangeInput = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const { checked, value } = event.target;
			if (checked) {
				setSelectedValues([...selectedValues, value]);
				onChange(handleChangeValue([...selectedValues, value], value));
			} else {
				setSelectedValues(selectedValues.filter(item => item !== value));
				onChange(
					handleChangeValue(
						selectedValues.filter(item => item !== value),
						""
					)
				);
			}
		},
		[handleChangeValue, onChange, selectedValues]
	);

	return (
		<Container>
			<div ref={ref}>
				<input
					type="text"
					value={handleReplaceValue}
					onFocus={handleToggleSelect}
					onBlur={handleToggleSelect}
					readOnly
					style={{ width: "80%" }}
				/>
				<ButtonOpenSelect type="button" onClick={handleToggleSelect}>
					<AiOutlineDown />
				</ButtonOpenSelect>
				{isOpen && (
					<BackgroundSelected>
						{filterProduct.map(c => (
							<ContainerOption key={c}>
								<RowCheckbox>
									<input
										type="checkbox"
										placeholder="Selecione uma das opções"
										value={c}
										checked={selectedValues.includes(c)}
										onChange={onChangeInput}
									/>
									{products[c]}
								</RowCheckbox>
							</ContainerOption>
						))}
					</BackgroundSelected>
				)}
			</div>
		</Container>
	);
};

export default MultiSelect;
