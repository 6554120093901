import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TrackingOrderViaVarejo from "Components/views/Dashboard/Order/Details/TrackingOrderViaVarejo";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { storeIsViaVarejo } from "helpers/stores-via-varejo";
import {
	getTimeString,
	toStringCurrency,
	toStringPercentage
} from "helpers/string";
import ls from "Localization";
import orderSaleStatus from "models/orderSaleStatus";
import { SaleStatus } from "models/saleStatus";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "store/actions/order/detail";

import EditOrder from "../Edit";
import styles from "./styles";

export function OrderDetail({
	classes,
	state: { item, loading },
	clearValues,
	getOrder,
	login: { user },
	match: {
		params: { id }
	}
}) {
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		clearValues();
	}, [clearValues]);

	useEffect(() => {
		getOrder(id);
	}, [getOrder, id]);

	const handleCloseEdit = () => {
		getOrder(id);
		setShowEdit(false);
	};

	if (loading.get) return <div>Carregando...</div>;

	if (!item) return null;

	let { creationDate, code, client, sales } = item;

	let { sale, status, deliveryInfo } = sales[0];

	let { store, fee, value, discount } = sale;

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.orderData}</Typography>
						<div>
							{isInRole(user, ["admin"]) && (
								<Button color="primary" onClick={() => setShowEdit(true)}>
									{ls.edit}
								</Button>
							)}
						</div>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={6} md={3} lg={2}>
							<strong>{ls.code}</strong>: {code}
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.creationDate}</strong>:{" "}
							{dayjs(creationDate).format(ls.dateFormatWithoutSeconds)}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.client}</strong>: {client.fullName}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.store}</strong>: {store.displayName}
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={2}>
							<strong>Cód venda:</strong>:{" "}
							<Link to={`/Transaction/${sale.id}`}>{sale.code}</Link>
						</Grid>

						<Grid item xs={12} sm={6} md={4} lg={3}>
							<strong>{ls.status}</strong>: {ls[orderSaleStatus[status]]}
						</Grid>

						<Grid item xs={12} />

						<Grid item xs={12} sm={6} md={3} lg={2}>
							<strong>{ls.value}</strong>: {toStringCurrency(value / 100)}
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={3}>
							<strong>{ls.discount}</strong>: {toStringCurrency(discount / 100)}
							({toStringPercentage(discount, value)})
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={3}>
							<strong>{ls.finalValue}</strong>:{" "}
							{storeIsViaVarejo(store.id)
								? toStringCurrency(value / 100)
								: toStringCurrency((value - discount) / 100)}
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={2}>
							<strong>{ls.fee}</strong>: {toStringCurrency(fee / 100)}(
							{toStringPercentage(fee, value - discount)})
						</Grid>

						<Grid item xs={12} />

						<Grid item xs={12} sm={6} md={3} lg={2}>
							<strong>{ls.delivery}</strong>:{" "}
							{toStringCurrency(deliveryInfo.price / 100)}
						</Grid>

						<Grid item xs={12} sm={6} md={3} lg={3}>
							<strong>Tempo entrega</strong>: {getTimeString(deliveryInfo.time)}{" "}
							-{" "}
							{dayjs(creationDate)
								.add(deliveryInfo.time, "minute")
								.format(ls.dateFormatWithoutSeconds)}
						</Grid>

						<Grid item xs={12} sm={12} md={6} lg={7}>
							<strong>{ls.address}</strong>: {deliveryInfo.address.fullAddress}
						</Grid>
					</Grid>
				</Paper>

				<EditOrder open={showEdit} item={item} handleClose={handleCloseEdit} />
				{storeIsViaVarejo(sale.storeId) &&
					sale.status !== SaleStatus.PendingApproval && (
						<TrackingOrderViaVarejo idPurchase={sale.externalId} />
					)}
			</Grid>
		</Grid>
	);
}

const mapStateToProps = ({ orderDetail: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = {
	...actions
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(OrderDetail));
