import { Button } from "@material-ui/core";
import Report from "Components/Report";
import ls from "Localization";
import React, { useState, useEffect } from "react";
import AddExtraProperties from "../../Add/ExtraProperties";
import {
	addExtraProperty,
	getExtraProperties
} from "store/actions/user/detail";
import { useDispatch, useSelector } from "react-redux";

function ExtraPropertiesReport({ id }) {
	const dispatch = useDispatch();
	const [showAddExtraProperties, setShowAddExtraProperties] = useState(false);

	const { properties } = useSelector<any, any>(s => s.userDetail);

	console.log(properties);

	const load = () => {
		dispatch(
			getExtraProperties(id, err => {
				if (err) {
					alert(err);
				}
			})
		);
	};

	useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleAddExtraProperty = (name: string, value: string) => {
		dispatch(
			addExtraProperty(id, { name, value }, err => {
				if (err) {
					alert(err);
				} else {
					load();
					setShowAddExtraProperties(false);
				}
			})
		);
	};

	return (
		<>
			<AddExtraProperties
				open={showAddExtraProperties}
				onAdd={handleAddExtraProperty}
				handleClose={() => setShowAddExtraProperties(false)}
			/>
			<Report
				title={ls.extraProperties}
				data={properties || []}
				PaginationComponent={_ => null}
				headerRightComponent={
					<Button
						color="primary"
						onClick={e => setShowAddExtraProperties(prev => !prev)}
					>
						Adicionar
					</Button>
				}
				visibleColumns={[]}
				defaultPageSize={(properties || []).length || 1}
				columns={[
					{
						Header: ls.name,
						id: "name",
						accessor: c => ls[c.name] ?? c.name
					},
					{
						Header: ls.value,
						id: "value",
						accessor: c => c.value
					}
				]}
			/>
		</>
	);
}

export default ExtraPropertiesReport;
