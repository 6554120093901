import * as types from "store/actions/business/dataBank/types";

import { IBusinessDataBank } from "../types/dataBank";

const initialState: IBusinessDataBank = {
	banks: [],
	item: {
		bankId: "",
		bank: {},
		agency: "",
		account: "",
		accountDigit: "",
		holderName: "",
		cpfCnpj: "",
		pixKey: null,
		pixType: null,
		businessId: ""
	},
	showModalUpdateOrCreate: false,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case types.GET_BANKS_DATA: {
			return {
				...state,
				loading: { ...state.loading, getBanks: true },
				errors: {}
			};
		}
		case types.GET_BANKS_DATA_SUCCESS: {
			return {
				...state,
				banks: payload,
				loading: { ...state.loading, getBanks: false },
				errors: {}
			};
		}
		case types.GET_BANKS_DATA_FAILED: {
			return {
				...state,
				loading: { ...state.loading, getBanks: false },
				error: payload
			};
		}
		case types.DATA_BANK_BUSINESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case types.GET_DATA_BANK_BUSINESS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload
			};
		}
		case types.GET_DATA_BANK_BUSINESS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case types.SET_VALUE:
			return {
				...state,
				...payload
			};
		case types.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
