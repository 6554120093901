import * as actions from "store/actions/order/edit";

import { IEditOrder } from "../types/edit";

const initialState: IEditOrder = {
	item: null,
	status: 1,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.EDIT_ORDER: {
			return {
				...state,
				loading: { edit: true },
				errors: initialState.errors
			};
		}
		case actions.EDIT_ORDER_FAILED: {
			return {
				...state,
				loading: { edit: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.EDIT_ORDER_SUCCESS:
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
