import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_PMA_BALANCE = "[PMA] GET_PMA_BALANCE";
export const GET_PMA_BALANCE_SUCCESS = "[PMA] GET_PMA_BALANCE_SUCCESS";
export const GET_PMA_BALANCE_FAILED = "[PMA] GET_PMA_BALANCE_FAILED";

export const SET_VALUE = "[PMA] SET_VALUE";
export const CLEAR_VALUES = "[PMA] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getPmaBalance(callback?: Callback<number>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PMA_BALANCE });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				"/Account/Balance",
				{
					Authorization: `Bearer ${login.token}`
				},
				"swap"
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PMA_BALANCE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PMA_BALANCE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_PMA_BALANCE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
