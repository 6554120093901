export default {
	0: "pendingApproval",
	1: "partialApproved",
	2: "approved",
	3: "refunded",
	4: "canceled",
	5: "refused"
};

export enum SaleStatus {
	PendingApproval = 0,
	PartialApproved = 1,
	Approved = 2,
	Refunded = 3,
	Canceled = 4,
	Refused = 5
}
