import { Grid, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import Spinner from "Components/Spinner";
import { isInRole } from "helpers/auth";
import { toStringCurrency, toStringPercentage } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { FaPlus, FaStamp } from "react-icons/fa";
import { MdEdit, MdPeople, MdStore, MdViewModule } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	getConsumptionPolicies,
	getConsumptionPolicy,
	setValue
} from "store/actions/consumptionPolicy";
import { getModerations } from "store/actions/moderation/report";

import CategoriesForm from "../Forms/Categories";
import CreateForm from "../Forms/Create";
import EditForm from "../Forms/Edit";
import StoresForm from "../Forms/Stores";
import UsersForm from "../Forms/Users";
import styles from "./styles";

let getConsumptionPoliciesDebounced: (() => void) & _.Cancelable = null;

function ConsumptionPolices({ business }) {
	const dispatch = useDispatch();

	const table = useRef(null);

	const [loadingSub, setLoadingSub] = useState({});
	const [isLoaded, setIsLoaded] = useState(false);

	const handleFetchData = useCallback(
		(tableState, _instance) => {
			if (!isLoaded) {
				return;
			}

			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getConsumptionPoliciesDebounced) {
				getConsumptionPoliciesDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: creationDate.value.startDate
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: creationDate.value.endDate
					});
			}

			getConsumptionPoliciesDebounced = _.debounce(
				() =>
					dispatch(
						getConsumptionPolicies(
							business.id,
							page * pageSize,
							pageSize,
							filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getConsumptionPoliciesDebounced();
		},
		[business.id, dispatch, isLoaded]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state, null),
		[handleFetchData]
	);

	const loadData = useCallback(() => {
		dispatch(
			getModerations(
				null,
				null,
				`&filters[companyId]=${business.id}&filters[badge]=${true}`,
				null,
				null,
				null,
				null
			)
		);

		setIsLoaded(true);
	}, [dispatch, business.id]);

	useEffect(() => {
		if (isLoaded) {
			load();
		}
	}, [isLoaded, load]);

	const state = useSelector<any, any>(c => c.consumptionPolicy);
	const { items } = useSelector<any, any>(c => c.moderationReport);
	const { user } = useSelector<any, any>(c => c.login);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(id, include, item) => {
			setLoadingSub({ [id]: item.id });
			dispatch(
				getConsumptionPolicy(item.id, include, (err, model) => {
					if (err) {
						console.log(err);
					} else {
						handleChange(id, Boolean(model));
					}
					setLoadingSub({ [id]: false });
				})
			);
		},
		[dispatch, handleChange]
	);

	const columns = useMemo(
		() => [
			{
				Header: ls.name,
				id: "name",
				show: true,
				accessor: c => (c.name ? c.name : ls.notInformed)
			},
			{
				Header: ls.main,
				id: "main",
				accessor: c => (c.main ? ls.yes : ls.no),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : ""}
					>
						<option value="">Todos</option>
						<option value="true">Sim</option>
						<option value="false">Não</option>
					</select>
				),
				width: 120
			},
			{
				Header: ls.limit,
				id: "limit",
				show: true,
				accessor: c =>
					c.limitType
						? toStringPercentage(c.limit, 10000, 2)
						: toStringCurrency(c.limit / 100.0),
				width: 160
			},
			{
				Header: ls.maxLimit,
				id: "maxLimit",
				show: true,
				accessor: c =>
					c.maxLimit ? toStringCurrency(c.maxLimit / 100.0) : ls.notInformed,
				width: 160
			},
			{
				Header: ls.maxInstallment,
				id: "maxInstallment",
				show: true,
				accessor: c => (c.maxInstallment ? c.maxInstallment : ls.notInformed),
				width: 160
			},
			{
				Header: ls.createdBy,
				id: "createdBy",
				show: true,
				accessor: c => (c.createdBy ? c.createdBy.fullName : ls.notInformed),
				width: 120
			},
			{
				Header: ls.updatedBy,
				id: "updatedBy",
				show: true,
				accessor: c => (c.updatedBy ? c.updatedBy.fullName : ls.notInformed),
				width: 120
			},
			{
				Header: ls.actions,
				id: "actions",
				filterable: false,
				accessor: c => (
					<Grid container spacing={2} justify="center">
						<Grid item>
							{c.main ? null : loadingSub["showCategoryFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() =>
										handleEdit("showCategoryFormModal", "categories", c)
									}
								>
									<MdViewModule />
								</IconButton>
							)}
							{c.main ? null : loadingSub["showStoreFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showStoreFormModal", "stores", c)}
								>
									<MdStore />
								</IconButton>
							)}
							{loadingSub["showUserFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showUserFormModal", "users", c)}
								>
									<MdPeople />
								</IconButton>
							)}
							{loadingSub["showEditFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showEditFormModal", undefined, c)}
								>
									<MdEdit />
								</IconButton>
							)}
						</Grid>
					</Grid>
				),
				width: 200
			}
		],
		[handleEdit, loadingSub]
	);

	let isAdmin = isInRole(user, ["admin"]);

	return (
		<LoadOnScroll load={loadData}>
			<Report
				manual
				tableRef={table}
				title={ls.consumptionPolicyPolicies}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				hasBadge={isAdmin}
				badgeIcon={<FaStamp size={22} color="#FFA800" />}
				badgeContent={items.length}
				badgeColor="secondary"
				badgeStyle={{ marginLeft: 20 }}
				badgeInfo="Solicitações de subsídio pendentes"
				badgeInfoStyle={{ marginLeft: 15 }}
				badgeHasLink
				badgeLink="/Moderation"
				headerRightComponent={
					<div>
						<IconButton
							color="primary"
							onClick={() => handleChange("showFormModal", true)}
						>
							<FaPlus />
						</IconButton>
					</div>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>

			<CreateForm load={load} />
			<EditForm load={load} />
			<CategoriesForm />
			<UsersForm business={business} />
			<StoresForm />
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(ConsumptionPolices);
