import * as actions from "store/actions/business/limitClub";

import { IBusinessLimits } from "../types/limit";

const initialState: IBusinessLimits = {
	percentClubWiipo: 0,
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_LIMIT_CLUB: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.CREATE_LIMIT_CLUB_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_LIMIT_CLUB_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
