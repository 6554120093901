/* eslint-disable no-case-declarations */
import * as actions from "store/actions/business/category/edit";

import { ICategoryEdit } from "../types/edit";

const initialState: ICategoryEdit = {
	item: null,
	name: "",
	color: "#3C80FF",
	icon: "",
	iconSource: "material",
	isVisible: true,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_BUSINESS_CATEGORY: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_CATEGORY_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload,
				name: payload.name || initialState.name,
				color: payload.color || initialState.color,
				icon: payload.icon || initialState.icon,
				iconSource: payload.iconSource || initialState.iconSource,
				isVisible: payload.isVisible
			};
		}
		case actions.GET_BUSINESS_CATEGORY_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.EDIT_BUSINESS_CATEGORY: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: true
				},
				errors: initialState.errors
			};
		}
		case actions.EDIT_BUSINESS_CATEGORY_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.EDIT_BUSINESS_CATEGORY_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					edit: false
				},
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			let itemState: any = {
				item: state.item
			};

			if (state.item) {
				itemState.name = state.item.name || initialState.name;
				itemState.color = state.item.color || initialState.color;
				itemState.icon = state.item.icon || initialState.icon;
				itemState.iconSource = state.item.iconSource || initialState.iconSource;
				itemState.isVisible = state.item.isVisible;
			}

			return {
				...initialState,
				...itemState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
