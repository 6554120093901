import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import Checkbox from "Components/Checkbox";
import { MONEY } from "Components/NumberFormat";
import { PERCENTAGE2 } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { isInRole } from "helpers/auth";
import { handleChangeEvent } from "helpers/files";
import { toastError, toastSuccess, toastWarning } from "helpers/toast";
import { validationLink } from "helpers/validators";
import ls from "Localization";
import _ from "lodash";
import digitalProviderType from "models/digitalProvider";
import valueType from "models/valueType";
import React, { useCallback, useRef } from "react";
import { MdOutlineCheckCircleOutline, MdPhoto } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { clearValues, createProduct, setValue } from "store/actions/product";
import { getBannersOrder } from "store/actions/product/banners";
import { setValue as setValueCategory } from "store/actions/productCategory";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceCreateForm: (() => void) & _.Cancelable = null;

function CreateForm({ load }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const inputRef = useRef<HTMLInputElement>(null);

	const {
		showFormModal,
		name,
		description,
		categoryId,
		price,
		isDigital,
		homeOffers,
		bannerLink,
		bannerFile,
		showQrCode,
		digitalProvider,
		digitalProviderCode,
		digitalUseInstructions,
		digitalUrl,
		discount,
		discountType,
		tos,
		errors,
		loading,
		endDateType,
		fixedEndDate,
		dynamicEndDate
	} = useSelector<any, any>(c => c.product);

	const categories = useSelector<any, any>(c => c.productCategory);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleClose = useCallback(
		() => handleChange("showFormModal", false),
		[handleChange]
	);

	const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = handleChangeEvent(e);

		if (files && files.length) {
			handleChange("bannerFile", files);
		}
	};

	const { user } = useSelector<any, any>(s => s.login);

	const isAdmin = isInRole(user, ["admin"]);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();

			if (debounceCreateForm) debounceCreateForm.cancel();

			debounceCreateForm = _.debounce(() => {
				dispatch(
					createProduct(err => {
						if (err) {
							if (err.default) toastError(err.default);
							else if (typeof err === "string") toastError(err);
							else toastError(JSON.stringify(err));
						} else {
							toastSuccess("Produto criado com sucesso");
							dispatch(clearValues());
							load();
							handleClose();
							if (homeOffers && isAdmin) {
								dispatch(
									getBannersOrder(err => {
										if (err) {
											toastError(err);
										}
									})
								);
								handleChange("showModalBanner", true);
							}
						}
					})
				);
			}, 1000);

			if (!homeOffers || !isAdmin) {
				debounceCreateForm();
			} else if (bannerLink) {
				if (bannerFile) {
					if (validationLink(bannerLink)) {
						debounceCreateForm();
					} else {
						toastError("Um link deve iniciar com http:// ou https://");
					}
				} else {
					toastWarning("Adicione uma imagem para continuar");
				}
			} else {
				toastWarning("Preencha o link da oferta");
			}
		},
		[
			homeOffers,
			bannerLink,
			dispatch,
			load,
			handleClose,
			handleChange,
			bannerFile
		]
	);

	return (
		<>
			<Modal
				aria-labelledby="edit-config-modal-title"
				aria-describedby="edit-config-modal-description"
				className={classes.modal}
				open={showFormModal}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={showFormModal}>
					<div className={classes.paper}>
						<Typography variant="h6">{ls.createProduct}</Typography>
						<hr />
						<div className={classes.body}>
							<form noValidate onSubmit={onSubmit}>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={6} lg={4}>
										<TextInput
											id="name"
											required
											variant="outlined"
											name="name"
											value={name}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={handleChange}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={6} lg={4}>
										<Select
											errors={errors}
											id="categoryId"
											name="category"
											value={categoryId}
											onChange={(id, value) => {
												if (value === "add")
													dispatch(setValueCategory({ showFormModal: true }));
												else handleChange(id, value);
											}}
											options={[
												{
													id: "add",
													value: "Adicionar nova"
												},
												...(categories.items || []).map(c => ({
													id: c.id,
													value: c.name
												}))
											]}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={4}>
										<TextInput
											id="price"
											required
											variant="outlined"
											name="price"
											InputProps={{
												inputComponent: MONEY
											}}
											value={price}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={handleChange}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={4}>
										<Select
											errors={errors}
											id="discountType"
											name="discountType"
											value={discountType}
											onChange={handleChange}
											options={Object.keys(valueType).map(c => ({
												id: c,
												value: ls[valueType[c]]
											}))}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={4}>
										<TextInput
											id="discount"
											required
											variant="outlined"
											name="discount"
											InputProps={{
												inputComponent: +discountType ? PERCENTAGE2 : MONEY
											}}
											value={discount}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={handleChange}
										/>
									</Grid>

									<Grid item xs={12}>
										<TextInput
											id="description"
											variant="outlined"
											name="description"
											value={description}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={handleChange}
											multiline
											rows={3}
										/>
									</Grid>

									<Grid item xs={12}>
										<Checkbox
											id="isDigital"
											name="isDigital"
											checked={isDigital}
											onChange={handleChange}
										/>

										{isAdmin && (
											<Checkbox
												id="homeOffers"
												name="homeOffers"
												checked={homeOffers}
												onChange={handleChange}
											/>
										)}
										{homeOffers && (
											<>
												<TextInput
													id="bannerLink"
													variant="outlined"
													name="linkBanner"
													size="small"
													value={bannerLink}
													errors={errors}
													required={homeOffers}
													style={{ width: 400 }}
													onFocus={e => e.target.select()}
													onChange={handleChange}
													multiline
												/>

												<Grid
													item
													xs={12}
													style={{
														display: "flex",
														flexDirection: "row",
														marginTop: 10
													}}
												>
													<div style={{ width: "38%" }} />
													<Button
														style={{
															background: bannerFile ? "#00FF00" : "#F6F6F6",
															border: 1,
															borderColor: "D9D9D9",
															borderRadius: 4,
															fontWeight: "normal",
															color: bannerFile ? "#FFF" : "#707070",
															fontSize: 12
														}}
														onClick={() => inputRef.current?.click()}
													>
														{bannerFile ? (
															<div
																style={{
																	display: "flex",
																	alignItems: "center"
																}}
															>
																<MdOutlineCheckCircleOutline
																	size={25}
																	color="#04c456"
																	style={{ marginRight: 5 }}
																/>
																Contém imagem
															</div>
														) : (
															<>
																<MdPhoto size={25} style={{ marginRight: 8 }} />{" "}
																Adicionar Imagem{" "}
															</>
														)}
													</Button>
													<div
														style={{
															background: "#F6F6F6",
															border: 1,
															borderColor: "D9D9D9",
															borderRadius: 4,
															fontWeight: "normal",
															color: "#BBBBBB",
															fontSize: 12,
															marginLeft: 10,
															padding: 10
														}}
													>
														Tamanho do banner: 800 x 300 px
													</div>
												</Grid>
											</>
										)}
									</Grid>

									{isDigital && (
										<>
											<Grid item xs={12}>
												<Checkbox
													id="showQrCode"
													name="showQrCode"
													checked={showQrCode}
													onChange={handleChange}
												/>
											</Grid>

											<Grid item xs={12} sm={8} md={8} lg={6}>
												<Select
													errors={errors}
													id="endDateType"
													name="endDateType"
													value={endDateType}
													required
													onChange={handleChange}
													options={[
														{
															id: "",
															value: ls.noneSelectedText,
															disabled: true
														},
														{ id: false, value: "Fixa" },
														{ id: true, value: "Dinâmica após a compra" }
													]}
												/>
											</Grid>

											{!endDateType ? (
												<>
													<Grid item xs={12} sm={6} md={6} lg={6}>
														<DateTimePicker
															fullWidth
															value={fixedEndDate}
															label={ls.creditEndAt}
															onChange={date =>
																handleChange("fixedEndDate", date)
															}
															format={ls.dateFormatWithoutSeconds}
															inputVariant="outlined"
															ampm={false}
															error={!fixedEndDate}
															helperText={!fixedEndDate && ls.requiredField}
															clearable
														/>
													</Grid>
												</>
											) : (
												<Grid item xs={12} sm={6} md={6} lg={6}>
													<TextInput
														id="dynamicEndDate"
														label={ls.creditEndAt}
														variant="outlined"
														name="dynamicEndDate"
														InputProps={{
															inputProps: {
																step: 1,
																min: 0
															}
														}}
														type="number"
														value={dynamicEndDate}
														errors={errors}
														onFocus={e => e.target.select()}
														onChange={handleChange}
													/>
												</Grid>
											)}

											<Grid item xs={12} sm={6} md={4}>
												<Select
													errors={errors}
													id="digitalProvider"
													name="digitalProvider"
													value={digitalProvider}
													onChange={handleChange}
													options={Object.keys(digitalProviderType).map(c => ({
														id: c,
														value: ls[digitalProviderType[c]]
													}))}
												/>
											</Grid>

											{["0", "2", "3", "4"].includes(
												String(digitalProvider)
											) && (
												<Grid item xs={12} sm={6} md={8}>
													<TextInput
														id="digitalProviderCode"
														required
														variant="outlined"
														name="digitalProviderCode"
														value={digitalProviderCode}
														errors={errors}
														onFocus={e => e.target.select()}
														onChange={handleChange}
													/>
												</Grid>
											)}

											<Grid item xs={12}>
												<TextInput
													id="digitalUseInstructions"
													variant="outlined"
													name="digitalUseInstructions"
													value={digitalUseInstructions}
													errors={errors}
													onFocus={e => e.target.select()}
													onChange={handleChange}
													multiline
													rows={3}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextInput
													id="tos"
													variant="outlined"
													name="tos"
													value={tos}
													errors={errors}
													onFocus={e => e.target.select()}
													onChange={handleChange}
													multiline
													rows={3}
												/>
											</Grid>
											<Grid item xs={12}>
												<TextInput
													id="digitalUrl"
													variant="outlined"
													name="digitalUrl"
													value={digitalUrl}
													errors={errors}
													onFocus={e => e.target.select()}
													onChange={handleChange}
												/>
											</Grid>
										</>
									)}
									<input
										ref={inputRef}
										style={{ display: "none" }}
										type="file"
										multiple
										accept="image/png,image/jpeg"
										onChange={handleFilesChange}
									/>
								</Grid>

								<div className={classes.footer}>
									<Button variant="contained" onClick={handleClose}>
										{ls.cancel}
									</Button>
									<Button
										color="primary"
										variant="contained"
										type="submit"
										disabled={loading.create}
									>
										{ls.save}
										{loading.create && <Spinner color="secondary" size={16} />}
									</Button>
								</div>
							</form>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
}

export default CreateForm;
