export default {
	0: "pending",
	1: "confirmed",
	2: "inTheWay",
	3: "delivered",
	4: "done",
	5: "returned",
	6: "requestedReturn",
	9: "canceled"
};
