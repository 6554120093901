import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { importUsers, setValue } from "store/actions/business/detail";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: "calc(80%)",
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceImportUsers: (() => void) & _.Cancelable = null;

function ImportUsers({ open, items, handleClose, load, hasWiipoClub }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { loading } = useSelector<any, any>(s => s.businessDetail);
	const [returnErrors, setReturnErrors] = useState([]);

	const [data, setData] = useState([]);

	useEffect(() => {
		dispatch(setValue({ loading: { import: false } }));
		setData(items || []);
	}, [dispatch, items]);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceImportUsers) debounceImportUsers.cancel();

		debounceImportUsers = _.debounce(() => {
			dispatch(
				importUsers(
					data,
					(err, users) => {
						if (err) {
							if (err.default) toastError(err.default);
							else if (typeof err === "string") toastError(err);
							else if (err.user_creation_error) {
								err.user_creation_error = err._original.user_creation_error;
								let errors = [];

								try {
									if (typeof err.user_creation_error === "string")
										err.user_creation_error = JSON.parse(
											err.user_creation_error
										);
								} catch (error) {
									console.log(error);
								}

								for (const list of err.user_creation_error) {
									let list2 = JSON.parse(list);

									for (const e of list2) {
										let error: any = { description: ls[e.code] };

										switch (e.code) {
											case "DuplicateUserName":
												error.cpf = e.description.split("'")[1].split("'")[0];
												break;
											default:
												error.cpf = e.description;
												break;
										}

										errors.push(error);
									}
								}

								handleClose();

								setReturnErrors(errors);
							} else {
								try {
									let errors = Object.keys(err).map(item => ({
										cpf: item,
										description: err[item]
									}));
									if (errors.length === 0) {
										toastError(err);
									} else {
										setReturnErrors(errors.filter(i => i.cpf !== "_original"));
									}
								} catch (error) {
									console.log(error);
								}
							}
						} else {
							toastSuccess("Usuários importados com sucesso");
							handleClose(users);
							load();
						}
					},
					hasWiipoClub
				)
			);
		}, 1000);

		debounceImportUsers();
	};

	const renderEditable = (cellInfo, c) => (
		<div
			style={{ backgroundColor: "#fafafa" }}
			contentEditable
			suppressContentEditableWarning
			onBlur={e => {
				data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
				setData([...data]);
			}}
			dangerouslySetInnerHTML={{
				__html: data[cellInfo.index][cellInfo.column.id]
			}}
		/>
	);

	if (returnErrors.length > 0)
		return (
			<Modal
				aria-labelledby="import-user-modal-title"
				aria-describedby="import-user-modal-description"
				className={classes.modal}
				open
				onClose={() => setReturnErrors([])}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in>
					<div className={classes.paper}>
						<Typography variant="h6">
							Os seguintes usuários não foram importados por causa dos seguintes
							erros
						</Typography>
						<hr />
						<div className={classes.body}>
							<ReactTable
								data={returnErrors}
								columns={
									[
										{
											Header: ls.cpfcnpj,
											accessor: "cpf"
										},
										{
											Header: ls.description,
											accessor: "description"
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => setReturnErrors([])}>
									{ls.close}
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);

	return (
		<Modal
			aria-labelledby="import-user-modal-title"
			aria-describedby="import-user-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.importUsers}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<ReactTable
								data={data}
								columns={
									[
										{
											Header: ls.cpfcnpj,
											accessor: "cpf",
											Cell: renderEditable
										},
										{
											Header: ls.enroll,
											accessor: "enroll",
											Cell: renderEditable
										},
										{
											Header: ls.name,
											accessor: "name",
											Cell: renderEditable
										},
										{
											Header: ls.lastName,
											accessor: "lastName",
											Cell: renderEditable
										},
										{
											Header: ls.email,
											accessor: "email",
											Cell: renderEditable
										},
										{
											Header: ls.phoneNumber,
											accessor: "phoneNumber",
											Cell: renderEditable
										},
										{
											Header: ls.limit,
											accessor: "limit",
											Cell: renderEditable
										},
										{
											Header: ls.birthDate,
											accessor: "birthDate",
											Cell: renderEditable
										},
										{
											Header: ls.admissionDate,
											accessor: "admissionDate",
											Cell: renderEditable
										},
										{
											Header: ls.costCenter,
											accessor: "costCenter",
											Cell: renderEditable
										},
										{
											Header: ls.mothersName,
											accessor: "mothersName",
											Cell: renderEditable
										},
										{
											Header: ls.zipCode,
											accessor: "zipCode",
											Cell: renderEditable
										},
										{
											Header: ls.street,
											accessor: "street",
											Cell: renderEditable
										},
										{
											Header: ls.number,
											accessor: "number",
											Cell: renderEditable
										},
										{
											Header: ls.complement,
											accessor: "complement",
											Cell: renderEditable
										},
										{
											Header: ls.neighborhood,
											accessor: "neighborhood",
											Cell: renderEditable
										},
										{
											Header: ls.city,
											accessor: "city",
											Cell: renderEditable
										},
										{
											Header: ls.state,
											accessor: "state",
											Cell: renderEditable
										},
										{
											Header: ls.country,
											accessor: "country",
											Cell: renderEditable
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.import}
								>
									{ls.import}
									{loading.import && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ImportUsers;
