import { modalAlert } from "Components/Modals/ModalAlert";
import { decimalValueTrunc } from "helpers/decimalsNumber";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

import {
	ActionPayload,
	ActionTypes,
	ITreasuryAccountWiipoFlex
} from "../types";

export interface IFundsPrePaidBalanceType {
	prePaidBalanceAccountTypeId: string;
	value: number;
}

export interface ITreasuryAccountWiipoFlexRequest {
	value: number;
	dueDate: number;
	cnpj: string;
	fundsPrePaidBalanceType: IFundsPrePaidBalanceType[];
	description: string;
	numberContract: number;
	numberOrder: number;
}

interface IState {
	treasuryAccountWiipoFlexCreate: ITreasuryAccountWiipoFlexRequest;
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: ActionTypes.SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: ActionTypes.CLEAR_VALUES };
}

export const createTransaction =
	(callback?: Callback<ITreasuryAccountWiipoFlex>) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_REQUEST });

		try {
			const { login, treasuryAccountWiipoFlexCreate } = getState();

			const {
				value,
				dueDate,
				cnpj,
				fundsPrePaidBalanceType,
				numberContract,
				numberOrder
			} = treasuryAccountWiipoFlexCreate;

			let errors: any = {};

			if (!value) errors.value = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_FAILED,
					payload: errors
				});
			}

			const model = {
				type: 0,
				paymentType: 0,
				value: decimalValueTrunc(value),
				dueDate,
				cnpj,
				fundsPrePaidBalanceType,
				description:
					(numberOrder ? "Número Ordem de compra: " + numberOrder : "") +
					"\n" +
					(numberContract ? "Número do Contrato:" + numberContract : "")
			};

			const response = await api.sendPost("/Funds", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch({
					type: ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_SUCCESS,
					payload: { item: result.model, params: result.params }
				});
			}
			errors = getErrors(result.errors);

			callback && callback(errors);
			return dispatch({
				type: ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_FAILED,
				payload: errors
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_FAILED
			});
		}
	};
