import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { CPFCNPJ, maskPhone } from "Components/NumberFormat";
import { isInRole } from "helpers/auth";
import ls from "Localization";
import { DataBank, PixType } from "models/dataBank";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDataBank, setValue } from "store/actions/business/dataBank/get";

import { ModalUpdateOrCreateDataBank } from "./ModalCreateEdit";
import { useStyles } from "./styles";

export function DataBankDetails() {
	const classes = useStyles();
	const dispatch = useDispatch();

	const { item: dataBank } = useSelector<
		{ dataDankBusiness: { item: DataBank } },
		{ item: DataBank }
	>(c => c.dataDankBusiness);

	const { item: business } = useSelector<any, any>(c => c.businessDetail);
	const { user } = useSelector<any, any>(s => s.login);

	useEffect(() => {
		dispatch(getDataBank(business.id));
		dispatch(setValue({ showModalUpdateOrCreate: false }));
	}, [business, dispatch]);

	const renderPixKey = (dataBank: DataBank) => {
		switch (dataBank.pixType) {
			case PixType.CpfCnpj:
				return <CPFCNPJ displayType="text" value={dataBank.pixKey} />;
			case PixType.PhoneNumber:
				return maskPhone(dataBank.pixKey);
			default:
				return dataBank.pixKey;
		}
	};

	const isAdmin = isInRole(user, ["admin"]);
	const isBusinessAdmin = isInRole(user, ["Business Admin"]);

	return (
		<Paper className={classes.root}>
			<ModalUpdateOrCreateDataBank />
			<div className={classes.header}>
				<Typography variant="h6">{ls.dataBankAccount}</Typography>

				<div>
					<Button
						color="primary"
						onClick={() =>
							dispatch(setValue({ showModalUpdateOrCreate: true }))
						}
					>
						{dataBank?.bank?.name && (isAdmin || isBusinessAdmin)
							? ls.edit
							: ls.create}
					</Button>
				</div>
			</div>
			<hr className={classes.line} />

			<Grid container spacing={2} className={classes.info}>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<strong>{ls.bankName}</strong>:{" "}
					{dataBank?.bank?.name ? dataBank?.bank?.name : ls.notDefined}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<strong>{ls.holderName}</strong>:{" "}
					{dataBank?.holderName ? dataBank.holderName : ls.notDefined}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<strong>{ls.cpfcnpj}:</strong>{" "}
					{dataBank?.cpfCnpj ? (
						<CPFCNPJ displayType="text" value={dataBank.cpfCnpj} />
					) : (
						ls.notDefined
					)}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<strong>{ls.agency}</strong>:{" "}
					{dataBank?.agency ? dataBank.agency : ls.notDefined}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<strong>{ls.accountWithDigit}</strong>:{" "}
					{dataBank?.account && dataBank?.accountDigit
						? `${dataBank.account}-${dataBank.accountDigit}`
						: ls.notDefined}
				</Grid>
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<strong>{ls.pixKey}</strong>:{" "}
					{dataBank?.pixKey ? renderPixKey(dataBank) : ls.notDefined}
				</Grid>
			</Grid>
		</Paper>
	);
}
