import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@material-ui/core";
import ls from "Localization";
import React, { useCallback } from "react";

import useStyles from "./styles";

interface IModalConfirmation {
	open: boolean;
	handleClose: () => void;
	title?: string;
	body?: JSX.Element;
	childrenjs?: JSX.Element;
	inputs?: JSX.Element;
	yesOrNot?: boolean;
	stylesYes?: {};
	onClickNo?: () => void;
	stylesNo?: {};
	onSubmit: (e: React.FormEvent<HTMLFormElement>, test: boolean) => void;
}

const ModalLimit: React.FC<Omit<IModalConfirmation, "body">> = ({
	open,
	handleClose,
	title,
	onSubmit,
	stylesYes,
	stylesNo,
	yesOrNot,
	childrenjs,
	inputs
}) => {
	const classes = useStyles({});

	const handleClickNo = useCallback(() => {
		handleClose();
	}, [handleClose]);
	return (
		<Dialog
			open={open}
			onClose={handleClickNo}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<form
				noValidate
				onSubmit={e => {
					onSubmit(e, true);
					handleClose();
				}}
			>
				<DialogContent className={classes.modal}>
					{title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

					<DialogContentText
						className={classes.children}
						id="alert-dialog-description"
					>
						{childrenjs}
					</DialogContentText>
					<DialogActions className={classes.rowButton}>
						{inputs}

						<DialogContent>
							<Button
								color="primary"
								type="submit"
								className={classes.buttonOk}
								style={stylesYes}
							>
								{yesOrNot ? ls.confirm : ls.yes}
							</Button>
							<Button
								onClick={handleClickNo}
								className={classes.buttonCancel}
								style={stylesNo}
							>
								{yesOrNot ? ls.cancel : ls.no}
							</Button>
						</DialogContent>
					</DialogActions>
				</DialogContent>
			</form>
		</Dialog>
	);
};

export default ModalLimit;
