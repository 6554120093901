import { Grid, makeStyles } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	},
	form: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2)
	},
	line: {
		marginLeft: -16,
		marginRight: -16
	},
	header: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	}
}));

export default useStyles;

export const GridButtonCopy = styled(Grid)`
	display: flex;
	flex-direction: row;
	align-items: center;
	background: none;

	& button {
		background: none;
		border: none;
	}
`;
