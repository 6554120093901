import { ErrorsValidationUser, User } from "models/interfaces/components/user";

export function CNPJValidation(value) {
	if (!value) return false;

	let cnpj = value.match(/\d/g).join("");

	if (!cnpj) return false;

	if (cnpj.length !== 14) return false;

	// Elimina CNPJs invalidos conhecidos
	if (
		cnpj === "00000000000000" ||
		cnpj === "11111111111111" ||
		cnpj === "22222222222222" ||
		cnpj === "33333333333333" ||
		cnpj === "44444444444444" ||
		cnpj === "55555555555555" ||
		cnpj === "66666666666666" ||
		cnpj === "77777777777777" ||
		cnpj === "88888888888888" ||
		cnpj === "99999999999999"
	)
		return false;

	// Valida DVs
	let size = cnpj.length - 2;
	let numbers = cnpj.substring(0, size);
	let digits = cnpj.substring(size);
	let sum = 0;
	let pos = size - 7;

	for (let i = size; i >= 1; i--) {
		sum += numbers.charAt(size - i) * pos--;
		if (pos < 2) pos = 9;
	}

	let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

	if (result !== +digits.charAt(0)) return false;

	size += 1;
	numbers = cnpj.substring(0, size);
	sum = 0;
	pos = size - 7;

	for (let i = size; i >= 1; i--) {
		sum += numbers.charAt(size - i) * pos--;
		if (pos < 2) pos = 9;
	}

	result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

	if (result !== +digits.charAt(1)) return false;

	return true;
}

export function CPFValidation(value) {
	if (!value) return false;

	value = value.match(/\d/g).join("");

	if (!value) return false;

	value = value.replace(".", "").replace("-", "").replace(" ", "");

	if (value.length !== 11) return false;

	let Sum;
	let Rest;
	Sum = 0;

	if (value === "00000000000") return false;

	for (let i = 1; i <= 9; i++)
		Sum += parseInt(value.substring(i - 1, i), 10) * (11 - i);
	Rest = (Sum * 10) % 11;

	if (Rest === 10 || Rest === 11) Rest = 0;
	if (Rest !== parseInt(value.substring(9, 10), 10)) return false;

	Sum = 0;
	for (let i = 1; i <= 10; i++)
		Sum += parseInt(value.substring(i - 1, i), 10) * (12 - i);
	Rest = (Sum * 10) % 11;

	if (Rest === 10 || Rest === 11) Rest = 0;
	if (Rest !== parseInt(value.substring(10, 11), 10)) return false;
	return true;
}

export function validationLink(link: string) {
	if (
		!(
			link.startsWith("http://") ||
			link.startsWith("https://") ||
			link.startsWith("wiipo://")
		)
	) {
		return false;
	}

	return true;
}

export const validationCEP = (cep: string) => {
	const regex = /^\d{2}\.\d{3}-\d{3}$/;

	return regex.test(cep);
};

export const validationDataUser = (user: User): ErrorsValidationUser[] => {
	let errors: ErrorsValidationUser[] = [];

	if (!user.mothersName) {
		errors.push({ inputError: "Nome da mãe", error: "está em branco" });
	}

	if (!user.phoneNumber) {
		errors.push({ inputError: "Telefone", error: "está em branco" });
	}

	if (!user.email) {
		errors.push({ inputError: "Email", error: "está em branco" });
	}

	if (!user.address?.city) {
		errors.push({ inputError: "Cidade", error: "está em branco" });
	}

	if (!user.address?.street) {
		errors.push({ inputError: "Rua", error: "está em branco" });
	}

	if (!user.address?.neighborhood) {
		errors.push({ inputError: "Bairro", error: "está em branco" });
	}

	if (!user.address?.number) {
		errors.push({ inputError: "Numero do endereço", error: "está em branco" });
	}

	if (!user.address?.zipCode) {
		errors.push({ inputError: "CEP", error: "está em branco" });
	}

	return errors;
};
