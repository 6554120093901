import * as actions from "store/actions/business/detail";

import { IBusinessDetail } from "../types/detail";

const initialState: IBusinessDetail = {
	item: null,
	days: [0, 1, 2, 3, 4, 5, 6].map(c => ({
		dayOfWeek: c,
		open: true,
		hours: [{ dayOfWeek: c, start: "00:00", end: "23:59" }]
	})),
	properties: [],
	showDaysFormModal: false,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_BUSINESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload
			};
		}
		case actions.GET_BUSINESS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}
		case actions.ADD_BRANCH: {
			return {
				...state,
				loading: {
					...state.loading,
					addBranch: true
				},
				errors: initialState.errors
			};
		}
		case actions.ADD_BRANCH_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					addBranch: false
				}
			};
		}
		case actions.ADD_BRANCH_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					addBranch: false
				},
				errors: payload
			};
		}

		case actions.ADD_FRANCHISE_BUSINESS: {
			return {
				...state,
				loading: {
					...state.loading,
					addFranchiseBusiness: true
				},
				errors: initialState.errors
			};
		}
		case actions.ADD_FRANCHISE_BUSINESS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					addFranchiseBusiness: false
				}
			};
		}
		case actions.ADD_FRANCHISE_BUSINESS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					addFranchiseBusiness: false
				},
				errors: payload
			};
		}

		case actions.REMOVE_BUSINESS_FROM_CATEGORY:
		case actions.ADD_BUSINESS_TO_CATEGORY: {
			return {
				...state,
				loading: {
					...state.loading,
					addRemove: true
				},
				errors: initialState.errors
			};
		}
		case actions.REMOVE_BUSINESS_FROM_CATEGORY_SUCCESS:
		case actions.ADD_BUSINESS_TO_CATEGORY_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					addRemove: false
				}
			};
		}
		case actions.REMOVE_BUSINESS_FROM_CATEGORY_FAILED:
		case actions.ADD_BUSINESS_TO_CATEGORY_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					addRemove: false
				},
				errors: payload
			};
		}
		case actions.BUSINESS_ADD_USER: {
			return {
				...state,
				loading: {
					...state.loading,
					addUser: true
				},
				errors: initialState.errors
			};
		}
		case actions.BUSINESS_ADD_USER_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					addUser: false
				}
			};
		}
		case actions.BUSINESS_ADD_USER_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					addUser: false
				},
				errors: payload
			};
		}
		case actions.ACTIVE_BUSINESS: {
			return {
				...state,
				loading: {
					...state.loading,
					active: true
				},
				errors: initialState.errors
			};
		}
		case actions.ACTIVE_BUSINESS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					active: false
				}
			};
		}
		case actions.ACTIVE_BUSINESS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					active: false
				},
				errors: payload
			};
		}
		case actions.EDIT_BUSINESS_CONFIG: {
			return {
				...state,
				loading: {
					...state.loading,
					config: true
				},
				errors: initialState.errors
			};
		}
		case actions.EDIT_BUSINESS_CONFIG_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					config: false
				}
			};
		}
		case actions.EDIT_BUSINESS_CONFIG_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					config: false
				},
				errors: payload
			};
		}
		case actions.IMPORT_USERS: {
			return {
				...state,
				loading: {
					...state.loading,
					import: true
				},
				errors: initialState.errors
			};
		}
		case actions.IMPORT_USERS_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					import: false
				}
			};
		}
		case actions.IMPORT_USERS_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					import: false
				},
				errors: payload
			};
		}
		case actions.EXPORT_CONSUMPTION: {
			return {
				...state,
				loading: {
					...state.loading,
					exportConsumption: true
				},
				errors: initialState.errors
			};
		}
		case actions.EXPORT_CONSUMPTION_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					exportConsumption: false
				}
			};
		}
		case actions.EXPORT_CONSUMPTION_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					exportConsumption: false
				},
				errors: payload
			};
		}
		case actions.EXPORT_INTEGRATION_HCM_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					exportIntegration: false
				},
				errors: payload
			};
		}
		case actions.GET_BUSINESS_DAYS: {
			return {
				...state,
				loading: { days: true },
				errors: initialState.errors
			};
		}
		case actions.GET_BUSINESS_DAYS_SUCCESS: {
			return {
				...state,
				days: payload?.length ? payload : initialState.days,
				loading: { days: false }
			};
		}
		case actions.GET_BUSINESS_DAYS_FAILED: {
			return {
				...state,
				loading: { days: false },
				errors: payload
			};
		}
		case actions.UPDATE_BUSINESS_DAYS: {
			return {
				...state,
				loading: { days: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_BUSINESS_DAYS_SUCCESS: {
			return {
				...state,
				loading: { days: false }
			};
		}
		case actions.UPDATE_BUSINESS_DAYS_FAILED: {
			return {
				...state,
				loading: { days: false },
				errors: payload
			};
		}

		case actions.GET_EXTRA_PROPERTIES: {
			return {
				...state,
				loading: {
					...state.loading,
					getProperties: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_EXTRA_PROPERTIES_SUCCESS: {
			return {
				...state,
				properties: payload,
				loading: {
					...state.loading,
					getProperties: false
				}
			};
		}

		case actions.GET_EXTRA_PROPERTIES_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					getProperties: false
				},
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
