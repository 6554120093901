import { Grid } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import Checkbox from "Components/Checkbox";
import TextInput from "Components/TextInput";
import ls from "Localization";
import React from "react";
import useStyles from "views/Dashboard/Marketing/Create/styles";

import { FilterOptions } from "../FilterOptions";
import TabPanel from "../TabPanel";

interface AboutTabProps {
	index: number;
	tab: number;
	name: string;
	handleChange: (key: any, value: any) => void;
	errors: any;
	startAt: Date;
	economicMode: boolean;
	filterSelected: string;
	onSelectFilter: (value: string) => void;
}

const AboutTab: React.FC<AboutTabProps> = ({
	economicMode,
	errors,
	filterSelected,
	handleChange,
	index,
	name,
	onSelectFilter,
	startAt,
	tab
}) => {
	const classes = useStyles({});

	return (
		<TabPanel value={tab} index={index}>
			<Grid container spacing={2} className={classes.form}>
				<Grid item xs={12} sm={6} md={6}>
					<TextInput
						id="name"
						required
						variant="outlined"
						name="name"
						value={name}
						errors={errors}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={6}>
					<DateTimePicker
						value={startAt}
						label={ls.startAt}
						fullWidth
						onChange={date => handleChange("startAt", date)}
						format={ls.dateFormatWithoutSeconds}
						inputVariant="outlined"
						ampm={false}
					/>
				</Grid>
				<Grid item xs={12}>
					<Grid container item xs={12}>
						<Grid item xs={12}>
							<Checkbox
								id="economicMode"
								name="economicMode"
								checked={economicMode}
								onChange={handleChange}
							/>
						</Grid>
						<FilterOptions
							selected={filterSelected}
							onChange={onSelectFilter}
						/>
					</Grid>
				</Grid>
			</Grid>
		</TabPanel>
	);
};

export default AboutTab;
