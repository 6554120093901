import { Paper } from "@material-ui/core";
import styled from "styled-components";

interface PropsButton {
	isWhite?: boolean;
	isCharge?: boolean;
	isDisable?: boolean;
}

export const Container = styled(Paper)``;

export const Header = styled.div`
	width: 100%;
	padding: 10px 16px;
	display: flex;
	align-items: center;
	padding-top: 20px;
`;

export const Title = styled.div`
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const Buttons = styled.div`
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const Content = styled.div`
	padding: 16px;
	align-items: center;
`;

export const RowAddCPF = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-end;
`;

export const InputAddCPF = styled.input`
	width: 260px;
	height: 43px;
	background: #f4f4f4 0% 0% no-repeat padding-box;
	border: 1px solid #d3d3d3;
	border-radius: 8px;
	opacity: 1;
	padding: 5px;
	padding-left: 10px;
	margin-right: 10px;

	&:focus {
		outline: none;
	}
`;

export const Button = styled.button<PropsButton>`
	width: ${props => (props.isWhite || props.isCharge ? "103px" : "133px")};
	height: 43px;
	background: ${props => {
		if (props.isDisable) {
			return "#D9D9D9";
		}
		if (props.isWhite) {
			return "#ffffff";
		}
		return "#5734da";
	}};
	background-position: 0% 0%;
	background-repeat: no-repeat;
	border-radius: 8px;
	opacity: 1;
	${props => (!props.isWhite ? "border:none" : "border-color:#5734da")};
	padding: 10px;
	color: ${props => (!props.isWhite ? "#ffffff" : "#5734da")};
	font: normal normal 600 16px Exo;
	letter-spacing: 0px;
	text-align: center;
	margin-right: 10px;
	cursor: ${props => (props.isDisable ? "none" : "pointer")};
`;
