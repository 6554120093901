import Report from "Components/Report";
import ls from "Localization";
import React, { useCallback, useEffect } from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getPrePaidBalanceAccountForUser } from "store/actions/user/detail";

function BalanceAccountTransferReport({ id }) {
	const dispatch = useDispatch();

	const { balanceAccounts } = useSelector<any, any>(s => s.userDetail);

	const load = useCallback(() => {
		dispatch(
			getPrePaidBalanceAccountForUser(id, err => {
				if (err) {
					alert(err);
				}
			})
		);
	}, [dispatch, id]);

	useEffect(() => {
		load();
	}, [load]);

	return (
		<>
			{balanceAccounts && balanceAccounts.length > 0 ? (
				<Report
					title={ls.balanceAccountTypeReport}
					data={balanceAccounts}
					PaginationComponent={_ => null}
					defaultPageSize={balanceAccounts.length}
					columns={[
						{
							Header: ls.name,
							id: "name",
							accessor: c => c.type.name
						},
						{
							Header: ls.code,
							id: "externalId",
							accessor: c => c.type.externalId
						},
						{
							Header: ls.description,
							id: "description",
							accessor: c => c.type.description
						},
						{
							Header: ls.transferRelese,
							id: "transactions",
							accessor: c =>
								c.type.businessTransferPolicies.filter(b => b.status === 1)
									.length > 0 ? (
									<FaCheck color="#00C460" size="20px" />
								) : (
									<FaTimes color="#B30012" size="20px" />
								)
						}
					]}
				/>
			) : (
				<></>
			)}
		</>
	);
}

export default BalanceAccountTransferReport;
