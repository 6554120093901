import { Button, makeStyles } from "@material-ui/core";
import DateTimePicker from "Components/DateTimePicker";
import ModalGeneric from "Components/Modals/ModalGeneric";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import dayjs, { Dayjs } from "dayjs";
import { isInRole } from "helpers/auth";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import FundsTypePayment from "models/fundsStatus";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editTransactionStatus } from "store/actions/treasuryAccountWiipoFlex/edit/statusOthers";

import { BackgroundButton, Body, Columns, Container, Title } from "./styles";

interface IValues {
	id: string;
	status: number;
	value: number;
	dueDate: string;
}

interface IPropsModal {
	open: boolean;
	handleClose: () => void;
	valuesEdit: IValues;
}

const useStyles = makeStyles(() => ({
	input: {
		height: 30,
		fontSize: 14,
		width: 150
	},
	inputDueDate: {
		height: 30,
		fontSize: 18,
		width: 150
	}
}));

let editTransactionStatusDebounced: (() => void) & _.Cancelable = null;

const ModalEdit: React.FC<IPropsModal> = ({
	open,
	handleClose,
	valuesEdit
}) => {
	const dispatch = useDispatch();

	const { user } = useSelector<any, any>(s => s.login);

	let admin = isInRole(user, ["admin"]);

	const FundsTypePayMentObj = Object.keys(FundsTypePayment).map(c => ({
		id: c,
		value: ls[FundsTypePayment[c]]
	}));

	const filterType = useMemo(() => {
		if (admin) {
			return FundsTypePayMentObj.filter(
				i =>
					i.id === "1" ||
					i.id === "4" ||
					i.id === "7" ||
					Number(i.id) === valuesEdit?.status
			);
		}

		return FundsTypePayMentObj.filter(i => i.id === "1");
	}, [FundsTypePayMentObj, admin, valuesEdit?.status]);

	const [statusEdit, setStatusEdit] = useState<any>(valuesEdit?.status);
	const [dueDateEdit, setDueDateEdit] = useState<Dayjs>();
	const [valueEdit, setValueEdit] = useState(valuesEdit?.value);
	const [loadingEdit, setLoadingEdit] = useState(false);
	const [openModalCalendar, setOpenModalCalendar] = useState(false);

	const handleOpenCalendar = useCallback(() => {
		setOpenModalCalendar(true);
	}, []);

	const handleCloseCalendar = useCallback(() => {
		setOpenModalCalendar(false);
	}, []);

	const startDateVerify = useMemo(() => {
		const now = new Date();
		now.setMinutes(now.getMinutes() + 30);

		if (dueDateEdit && dayjs(dueDateEdit).toDate() >= now) {
			return true;
		}

		return false;
	}, [dueDateEdit]);

	const { errors, loading } = useSelector<any, any>(
		s => s.treasuryAccountWiipoFlexEditStatus
	);

	const onEditTransaction = useCallback(() => {
		setLoadingEdit(true);
		if (editTransactionStatusDebounced) {
			editTransactionStatusDebounced.cancel();
		}
		editTransactionStatusDebounced = _.debounce(
			() =>
				dispatch(
					editTransactionStatus(
						err => {
							if (err) {
								toastError(err);
							} else {
								toastSuccess("Atualizado o status com sucesso");
							}
							setLoadingEdit(false);
							handleClose();
						},
						{
							id: valuesEdit?.id,
							status: Number(statusEdit),
							dueDate: dueDateEdit
						}
					)
				),
			500
		);

		editTransactionStatusDebounced();
	}, [dispatch, valuesEdit?.id, statusEdit, dueDateEdit, handleClose]);

	useEffect(() => {
		setValueEdit(valuesEdit?.value);
		setDueDateEdit(dayjs(new Date(valuesEdit?.dueDate)));
		setStatusEdit(valuesEdit?.status);
	}, [valuesEdit]);

	return (
		<ModalGeneric
			open={open}
			handleClose={handleClose}
			buttonClose
			body={() => (
				<Container>
					<Title>
						{ls.edit} {ls.status.toLowerCase()}
					</Title>
					<Body>
						<Columns>
							<Select
								errors={errors}
								style={{ height: 50 }}
								id="status"
								name="status"
								value={statusEdit}
								required
								onChange={(id, value) => {
									setStatusEdit(value);
								}}
								options={[
									{
										id: statusEdit,
										value: ls[FundsTypePayment[statusEdit]],
										disabled: true
									},
									...filterType
								]}
							/>
						</Columns>
						<Columns>
							<DateTimePicker
								open={openModalCalendar}
								openTime={false}
								handleOpen={handleOpenCalendar}
								handleClose={handleCloseCalendar}
								startDateVerify={startDateVerify}
								date={dueDateEdit}
								placeholder="00/00/0000"
								onChange={date => {
									setDueDateEdit(dayjs(date).startOf("day"));
								}}
								admin={admin}
							/>
							<TextInput
								size="medium"
								id="value"
								style={{
									borderColor: "#C2C2C2",
									backgroundColor: "#FFFFFF",
									color: "#707070",
									marginTop: 30
								}}
								InputProps={{
									inputComponent: MONEY
								}}
								disabled
								variant="outlined"
								name="value"
								value={valueEdit / 100}
								errors={errors}
								placeholder={ls.value}
								onChange={(id, values) => {
									setValueEdit(Number(values));
								}}
							/>
						</Columns>
					</Body>
					<BackgroundButton>
						<Button
							color="primary"
							size="small"
							variant="contained"
							type="submit"
							onClick={onEditTransaction}
							style={{
								fontSize: 16,
								borderRadius: 6,
								width: 140,
								marginTop: 20,
								height: 35
							}}
							disabled={loading || loadingEdit}
						>
							{loading || loadingEdit ? (
								<Spinner color="inherit" size={18} />
							) : (
								ls.save
							)}
						</Button>
					</BackgroundButton>
				</Container>
			)}
		/>
	);
};

export default ModalEdit;
