import { Button, CircularProgress } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import React from "react";
import { RiFileExcel2Line } from "react-icons/ri";

type Params = {
	erp: {
		status: boolean;
		onClick: () => void;
	};
	transfeera: {
		status: boolean;
		onClick: () => void;
	};
	default: {
		status: boolean;
		onClick: () => void;
	};
};

export function ExportButtons(params: Params) {
	return (
		<div>
			<Button
				variant="outlined"
				color="primary"
				size="small"
				startIcon={
					params.erp.status ? (
						<CircularProgress color="primary" size="18px" />
					) : (
						<GetApp />
					)
				}
				disabled={params.erp.status}
				onClick={() => {
					params.erp.onClick();
				}}
			>
				Template ERP
			</Button>{" "}
			<Button
				variant="outlined"
				color="primary"
				size="small"
				startIcon={
					params.transfeera.status ? (
						<CircularProgress color="primary" size="18px" />
					) : (
						<GetApp />
					)
				}
				disabled={params.transfeera.status}
				onClick={() => {
					params.transfeera.onClick();
				}}
			>
				Template Transfeera
			</Button>{" "}
			<Button
				variant="outlined"
				color="primary"
				size="small"
				startIcon={
					params.default.status ? (
						<CircularProgress color="primary" size="18px" />
					) : (
						<RiFileExcel2Line />
					)
				}
				disabled={params.default.status}
				onClick={() => {
					params.default.onClick();
				}}
			>
				Template Padrão
			</Button>
		</div>
	);
}
