import {
	dangerCardHeader,
	grayColor,
	infoCardHeader,
	primaryCardHeader,
	purpleCardHeader,
	roseCardHeader,
	successCardHeader,
	warningCardHeader
} from "./base";

const cardIconStyle = {
	cardIcon: {
		"&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader,&$purpleCardHeader":
			{
				borderRadius: "3px",
				backgroundColor: grayColor[0],
				padding: "15px",
				marginTop: "-20px",
				marginRight: "15px",
				float: "left"
			}
	},
	warningCardHeader,
	successCardHeader,
	dangerCardHeader,
	infoCardHeader,
	primaryCardHeader,
	roseCardHeader,
	purpleCardHeader
};

export default cardIconStyle;
