import styled from "styled-components";

export const ContainerItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px;
`;

export const NumberDraggableList = styled.div`
	text-align: left;
	font: normal normal 600 21px/28px Exo;
	letter-spacing: 0px;
	color: #6f6f6f;
	opacity: 1;
	border-radius: 16px;
	background-color: #eaeaea;
	padding: 11px;

	width: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContainerDraggableList = styled.div`
	margin: 10px;
	user-select: none;
	width: 100%;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #9b9b9b;
	border-radius: 8px;
	opacity: 1;
`;
