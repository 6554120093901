import dayjs from "dayjs";
import * as actions from "store/actions/charge/create";

import { ICreateCharge } from "../types/create";

const initialState: ICreateCharge = {
	creationDate: dayjs(),
	clientId: "",
	type: 0,
	value: 0,
	description: "",
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_CHARGE: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_CHARGE_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_CHARGE_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
				creationDate: dayjs()
			};
		default: {
			return state;
		}
	}
};

export default reducer;
