import React, { useMemo, useState } from "react";

import { Container, TextLabelBAG } from "./styles";

interface IPropsLabel {
	typeAccount?: number;
	isBagAccount?: boolean;
	icon?: any;
}

const LabelTypeBag: React.FC<IPropsLabel> = ({
	typeAccount,
	isBagAccount,
	icon
}) => {
	const [colorTypeBAG, setColorTypeBAG] = useState("");

	const textTypeBag = useMemo(() => {
		if (!isBagAccount) {
			setColorTypeBAG("red");

			return;
		}
		if (typeAccount) {
			setColorTypeBAG("#FE9D2B");
			return "PÓS";
		}
		setColorTypeBAG("#00B940");
		return "PRÉ";
	}, [isBagAccount, typeAccount]);

	return (
		<Container colorType={colorTypeBAG}>
			{isBagAccount ? <TextLabelBAG>{textTypeBag}</TextLabelBAG> : icon}
		</Container>
	);
};

export default LabelTypeBag;
