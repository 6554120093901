import { IconButton } from "@material-ui/core";
import TooltipLight from "Components/TooltipLight";
import React, { useMemo } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

import { Container } from "./styles";

interface Props {
	props: { date: Date; decreaseMonth: () => void; increaseMonth: () => void };
}

const Header: React.FC<Props> = ({ props }) => {
	const { date, decreaseMonth, increaseMonth } = props;
	const stringMonth = date.toLocaleDateString("pt-BR", { month: "long" });
	const firstLetterUpperCase = useMemo(
		() => stringMonth.charAt(0).toUpperCase() + stringMonth.slice(1),
		[stringMonth]
	);

	return (
		<Container>
			<TooltipLight title="Voltar" placement="top">
				<IconButton onClick={decreaseMonth}>
					<FaChevronLeft color="#fff" />
				</IconButton>
			</TooltipLight>

			<div>
				{firstLetterUpperCase} - {date.getFullYear()}
			</div>
			<TooltipLight title="Próximo" placement="top">
				<IconButton onClick={increaseMonth}>
					<FaChevronRight color="#fff" />
				</IconButton>
			</TooltipLight>
		</Container>
	);
};

export default Header;
