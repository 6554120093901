import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import { Callback } from "Types/Callback";

export const GET_VIRTUAL_CARDS = "[VIRTUAL_CARD] GET_VIRTUAL_CARDS";
export const GET_VIRTUAL_CARDS_SUCCESS =
	"[VIRTUAL_CARD] GET_VIRTUAL_CARDS_SUCCESS";
export const GET_VIRTUAL_CARDS_FAILED =
	"[VIRTUAL_CARD] GET_VIRTUAL_CARDS_FAILED";

export const UPDATE_VIRTUAL_CARD_STATUS =
	"[VIRTUAL_CARD] UPDATE_VIRTUAL_CARD_STATUS";
export const UPDATE_VIRTUAL_CARD_STATUS_SUCCESS =
	"[VIRTUAL_CARD] UPDATE_VIRTUAL_CARD_STATUS_SUCCESS";
export const UPDATE_VIRTUAL_CARD_STATUS_FAILED =
	"[VIRTUAL_CARD] UPDATE_VIRTUAL_CARD_STATUS_FAILED";

export const CREATE_VIRTUAL_CARD = "[VIRTUAL_CARD] CREATE_VIRTUAL_CARD";
export const CREATE_VIRTUAL_CARD_SUCCESS =
	"[VIRTUAL_CARD] CREATE_VIRTUAL_CARD_SUCCESS";
export const CREATE_VIRTUAL_CARD_FAILED =
	"[VIRTUAL_CARD] CREATE_VIRTUAL_CARD_FAILED";

export const UPDATE_VIRTUAL_CARD = "[VIRTUAL_CARD] UPDATE_VIRTUAL_CARD";
export const UPDATE_VIRTUAL_CARD_SUCCESS =
	"[VIRTUAL_CARD] UPDATE_VIRTUAL_CARD_SUCCESS";
export const UPDATE_VIRTUAL_CARD_FAILED =
	"[VIRTUAL_CARD] UPDATE_VIRTUAL_CARD_FAILED";

export const IMPORT_VIRTUAL_CARDS = "[BUSINESS DETAIL] IMPORT_VIRTUAL_CARDS";
export const IMPORT_VIRTUAL_CARDS_SUCCESS =
	"[BUSINESS DETAIL] IMPORT_VIRTUAL_CARDS_SUCCESS";
export const IMPORT_VIRTUAL_CARDS_FAILED =
	"[BUSINESS DETAIL] IMPORT_VIRTUAL_CARDS_FAILED";

export const DELETE_VIRTUAL_CARD = "[VIRTUAL_CARD] DELETE_VIRTUAL_CARD";
export const DELETE_VIRTUAL_CARD_SUCCESS =
	"[VIRTUAL_CARD] DELETE_VIRTUAL_CARD_SUCCESS";
export const DELETE_VIRTUAL_CARD_FAILED =
	"[VIRTUAL_CARD] DELETE_VIRTUAL_CARD_FAILED";

export const SET_VALUE = "[VIRTUAL_CARD] SET_VALUE";
export const CLEAR_VALUES = "[VIRTUAL_CARD] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getVirtualCards(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_VIRTUAL_CARDS });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/VirtualCard?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_VIRTUAL_CARDS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_VIRTUAL_CARDS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_VIRTUAL_CARDS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editVirtualCardStatus(id, status, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_VIRTUAL_CARD_STATUS });

		try {
			const { login } = getState();

			const response = await api.sendPut(`/VirtualCard/${id}/${status}`, null, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_VIRTUAL_CARD_STATUS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_VIRTUAL_CARD_STATUS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_VIRTUAL_CARD_STATUS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createVirtualCard(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_VIRTUAL_CARD });

		try {
			const { login, virtualCard, businessDetail } = getState();

			const { userId, number, status } = virtualCard;

			const errors: any = {};

			if (!userId) errors.userId = ls.required;
			if (!number) errors.number = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_VIRTUAL_CARD_FAILED,
					payload: errors
				});
			}

			const model = {
				userId,
				number,
				storeId: businessDetail.item.id,
				status
			};

			const response = await api.sendPost("/VirtualCard", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_VIRTUAL_CARD_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_VIRTUAL_CARD_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_VIRTUAL_CARD_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editVirtualCard(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_VIRTUAL_CARD });

		try {
			const { login, virtualCard } = getState();

			const { item, number, status } = virtualCard;

			const errors: any = {};

			if (!number) errors.number = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_VIRTUAL_CARD_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				number,
				status
			};

			const response = await api.sendPut("/VirtualCard/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_VIRTUAL_CARD_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_VIRTUAL_CARD_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_VIRTUAL_CARD_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteVirtualCard(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_VIRTUAL_CARD });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/VirtualCard/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_VIRTUAL_CARD_SUCCESS,
					payload: id
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_VIRTUAL_CARD_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: DELETE_VIRTUAL_CARD_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function importVirtualCards(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_VIRTUAL_CARDS });

		try {
			const { login, businessDetail } = getState();

			let model = data.map(({ cpf, number }) => ({
				cpf: cpf.match(/\d/g).join(""),
				number: number.match(/\d/g).join(""),
				status: 1
			}));

			const response = await api.sendPost(
				"/VirtualCard/" + businessDetail.item.id,
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_VIRTUAL_CARDS_SUCCESS,
					payload: result.model
				});

				dispatch(getVirtualCards(businessDetail.item.id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_VIRTUAL_CARDS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_VIRTUAL_CARDS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
