import * as actions from "store/actions/product/banners";

import { IBanners } from "../types/banners";

const initialState: IBanners = {
	items: [],
	loading: false,
	error: {},
	startDate: null,
	endDate: null,
	eventName: ""
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_BANNERS: {
			return {
				...state,
				loading: true,
				errors: initialState.error
			};
		}

		case actions.GET_BANNERS_SUCCESS: {
			return {
				items: payload,
				loading: false
			};
		}

		case actions.GET_BANNERS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}

		case actions.UPDATE_BANNERS: {
			return {
				...state,
				loading: false,
				errors: initialState.error
			};
		}

		case actions.UPDATE_BANNERS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: false
			};
		}

		case actions.UPDATE_BANNERS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.EXPORT_CLICKS_BANNERS: {
			return {
				...state,
				loading: true,
				errors: initialState.error
			};
		}

		case actions.EXPORT_CLICKS_BANNERS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: false
			};
		}

		case actions.EXPORT_CLICKS_BANNERS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...state,
				startDate: initialState.startDate,
				endDate: initialState.endDate
			};
		default: {
			return state;
		}
	}
};

export default reducer;
