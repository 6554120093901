import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Card from "Components/Card/Card";
import CardFooter from "Components/Card/CardFooter";
import CardHeader from "Components/Card/CardHeader";
import CardIcon from "Components/Card/CardIcon";
import * as dateFns from "date-fns";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";
import { FaUsersCog } from "react-icons/fa";
import { FaUserClock } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { sendGet } from "services/api";

import useStyles from "./styles";

interface DashboardInfo {
	businessName: string;
	lastLoginDate: string;
	ip: string;
	passwordAge: string;
	roleName: string;
	daysLeftToPasswordExpire: string;
}

export function Home() {
	const dispatch = useDispatch();
	const classes = useStyles({});
	const { user, token } = useSelector<any, any>(s => s.login);
	const [loading, setLoading] = useState(false);
	const [dashboard, setDashboard] = useState<DashboardInfo>(
		{} as DashboardInfo
	);

	const getDashboardInfo = useCallback(async () => {
		try {
			setLoading(true);
			const response = await sendGet(`/User/Dashboard/${user.id}`, {
				Authorization: "Bearer " + token
			});
			const result = await response.json();
			if (result.model) {
				setDashboard(result.model);
			}
		} catch (error) {
			toastError("Erro ao buscar informações do dashboard");
		} finally {
			setLoading(false);
		}
	}, [token, user.id]);

	useEffect(() => {
		if (user?.id && _.isEmpty(dashboard)) {
			getDashboardInfo();
		}
	}, [dispatch, getDashboardInfo, dashboard, user?.id]);

	return (
		<div>
			<Helmet>
				<title>Dashboard | {ls.appName}</title>
			</Helmet>
			<Grid container spacing={2} style={{ padding: 12 }}>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<Typography variant="h4">
						<b>Olá, {user.fullName}</b>
					</Typography>
				</Grid>
				{loading ? (
					<>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Skeleton variant="rect" width="100%" height={130} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Skeleton variant="rect" width="100%" height={130} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Skeleton variant="rect" width="100%" height={130} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Skeleton variant="rect" width="100%" height={130} />
						</Grid>
					</>
				) : (
					<>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Card>
								<CardHeader color="primary" stats icon>
									<CardIcon color="primary">
										<img src="/img/icon-7.svg" alt="Empresas" width="50" />
									</CardIcon>
									<p className={classes.cardCategory}>Empresa</p>
									<h3 className={classes.cardTitle}>
										{dashboard.businessName}
									</h3>
								</CardHeader>
								<CardFooter stats />
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Card>
								<CardHeader color="primary" stats icon>
									<CardIcon color="primary">
										<ImProfile width={50} />
									</CardIcon>
									<p className={classes.cardCategory}>Perfil</p>
									<h3 className={classes.cardTitle}>{dashboard.roleName}</h3>
								</CardHeader>
								<CardFooter stats />
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Card>
								<CardHeader color="primary" stats icon>
									<CardIcon color="primary">
										<FaUsersCog width={50} />
									</CardIcon>
									<p className={classes.cardCategory}>Último acesso</p>

									<h3 className={classes.cardTitle}>
										{dashboard?.lastLoginDate
											? dateFns.format(
													new Date(dashboard?.lastLoginDate),
													"dd/MM/yyyy HH:mm"
											  )
											: ""}
									</h3>
								</CardHeader>
								<CardFooter stats className={classes.cardFooter}>
									<h3 className={classes.stats}>IP: {dashboard?.ip}</h3>
								</CardFooter>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={6}>
							<Card>
								<CardHeader color="primary" stats icon>
									<CardIcon color="primary">
										<FaUserClock width={50} />
									</CardIcon>
									<p className={classes.cardCategory}>Idade da senha</p>
									<h3 className={classes.cardTitle}>
										{Number(dashboard.passwordAge) < 0
											? "1 dia"
											: `${dashboard.passwordAge} dias`}
									</h3>
								</CardHeader>
								<CardFooter stats className={classes.cardFooter}>
									<h3 className={classes.stats} style={{ color: "red" }}>
										{Number(dashboard.daysLeftToPasswordExpire) === 1
											? `Expira em ${dashboard.daysLeftToPasswordExpire} dia`
											: `Expira em ${dashboard.daysLeftToPasswordExpire} dias`}
									</h3>
								</CardFooter>
							</Card>
						</Grid>
					</>
				)}
			</Grid>
		</div>
	);
}

export default Home;
