import { getErrors } from "helpers/error";
import * as api from "services/api";

export const CREATE_MODERATION = "[MODERATION CREATE] CREATE_MODERATION";
export const CREATE_MODERATION_SUCCESS =
	"[MODERATION CREATE] CREATE_MODERATION_SUCCESS";
export const CREATE_MODERATION_FAILED =
	"[MODERATION CREATE] CREATE_MODERATION_FAILED";

export const SET_VALUE = "[MODERATION CREATE] SET_VALUE";
export const CLEAR_VALUES = "[MODERATION CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createModeration(model, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_MODERATION });
		try {
			const { login } = getState();

			let response = await api.sendPost("/Moderation", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_MODERATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_MODERATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: CREATE_MODERATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
