import { Button } from "@material-ui/core";
import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import { Dayjs } from "dayjs";
import { dateFormattedSubmit } from "helpers/date";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { FaFileExport } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getExtractUser } from "store/actions/user/detail";

import {
	BackgroundIcon,
	ColumnDates,
	Container,
	InputDate,
	LabelDate,
	RowDates,
	Title,
	useStyles
} from "./styles";

interface IPropsModalLog {
	id: string;
	open: boolean;
	handleClose: () => void;
	load: () => void;
}

let debounceModalExtract: (() => void) & _.Cancelable = null;

const ModalExtract: React.FC<IPropsModalLog> = ({
	open,
	id,
	handleClose,
	load
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [startDate, setStartDate] = useState<Dayjs>(null);
	const [endDate, setEndDate] = useState<Dayjs>(null);
	const [loading, setLoading] = useState(false);

	const maxDate = useMemo(() => {
		const now = new Date();

		return now;
	}, []);

	const initDate = useMemo(() => {
		const start = new Date(String(startDate?.add(1, "day")));
		const now = new Date();
		return start < now ? start : now;
	}, [startDate]);

	const clearValue = useCallback(() => {
		setEndDate(null);
		setStartDate(null);
	}, []);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();
			setLoading(true);
			const dateFormatted = dateFormattedSubmit(startDate, endDate);

			const date = {
				startAt: dateFormatted.startDate,
				endAt: dateFormatted.endDate
			};

			if (debounceModalExtract) debounceModalExtract.cancel();
			debounceModalExtract = _.debounce(() => {
				dispatch(
					getExtractUser(
						id,
						`?&filters[period]=${JSON.stringify(date)}`,
						err => {
							if (err) {
								toastError(err);
							} else {
								handleClose();
								clearValue();
								load();
							}
							setLoading(false);
						}
					)
				);
			}, 500);

			debounceModalExtract();
		},
		[clearValue, dispatch, endDate, handleClose, id, load, startDate]
	);

	return (
		<ModalGeneric
			handleClose={handleClose}
			open={open}
			isNotClassName
			stylePaper={{
				width: 533,
				height: 325,
				background: "#fff",
				borderRadius: 16
			}}
			body={() => (
				<Container>
					<BackgroundIcon>
						<FaFileExport color="#5734D9" size={25} />
					</BackgroundIcon>

					<Title>Período para exportar o extrato</Title>
					<RowDates>
						<ColumnDates>
							<LabelDate>De:</LabelDate>

							<InputDate
								style={{ maxWidth: 180 }}
								size="small"
								placeholder="00/00/0000"
								format={ls.dateFormatShort}
								InputProps={{
									classes: { input: classes.inputDueDate }
								}}
								maxDate={new Date()}
								value={startDate}
								onChange={v => {
									setStartDate(v.utc().hour(0).minute(0).second(0));
								}}
								inputVariant="outlined"
								fullWidth
							/>
						</ColumnDates>
						<ColumnDates>
							<LabelDate>Até:</LabelDate>

							<InputDate
								disabled={!startDate}
								style={{ maxWidth: 200 }}
								size="small"
								placeholder="00/00/0000"
								format={ls.dateFormatShort}
								InputProps={{
									classes: { input: classes.inputDueDate }
								}}
								value={endDate}
								minDate={initDate}
								maxDate={maxDate}
								onChange={v => {
									setEndDate(v.utc().hour(23).minute(59).second(59));
								}}
								inputVariant="outlined"
								fullWidth
							/>
						</ColumnDates>
					</RowDates>

					<form
						noValidate
						onSubmit={onSubmit}
						style={{
							display: "flex",
							justifyContent: "space-around",
							width: "100%",
							marginTop: 40,
							paddingRight: 10,
							paddingLeft: 10
						}}
					>
						<Button
							onClick={handleClose}
							size="medium"
							style={{
								borderColor: "#5734D9",
								borderWidth: 1,
								borderStyle: "solid",
								borderRadius: 16,
								width: 183,
								background: "none",
								color: "#5734D9"
							}}
						>
							{ls.cancel}
						</Button>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							size="medium"
							style={{
								borderColor: "#5734D9",
								borderWidth: 1,
								borderRadius: 16,
								width: 183,
								marginRight: 10
							}}
						>
							{loading ? <Spinner color="inherit" size={24} /> : ls.export}
						</Button>
					</form>
				</Container>
			)}
		/>
	);
};

export default ModalExtract;
