import { Grid } from "@material-ui/core";
import Checkbox from "Components/Checkbox";
import React, { useCallback } from "react";

const filterOptions = [
	"clubForWhoIsEligible",
	"clubForWhoAlreadyBought",
	"clubForWhoNeverBought",
	"clubForWhoDownloadedNeverBought",
	"clubForWhoDownloadedNeverBoughtInTheLast30Days",
	"clubForWhoDownloadedNeverBoughtInTheLast60Days"
];

export function FilterOptions({ selected, onChange }) {
	const handleChange = useCallback(
		(name, value) => {
			if (!value) name = "";

			onChange(name);
		},

		[onChange]
	);

	return (
		<div>
			{filterOptions.map(c => (
				<Grid item xs={12} key={c}>
					<Checkbox
						id={c}
						name={c}
						checked={selected === c}
						onChange={handleChange}
					/>
				</Grid>
			))}
		</div>
	);
}
