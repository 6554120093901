import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import chargePersonType from "models/chargePersonType";
import chargeType from "models/chargeType";
import React, { useMemo } from "react";
import { FaTrash } from "react-icons/fa";

export const useColumns = (
	stateValue: any,
	openAlertModal: (c: any) => void,
	user: any
) => {
	const isSuperAdmin = isInRole(user, ["Admin"]);
	const columns = useMemo(
		() => [
			{
				Header: ls.id,
				id: "id",
				accessor: c => c.identifier,
				show: true,
				width: 160
			},
			{
				Header: ls.externalId,
				id: "pmaExternalId",
				accessor: c => c.pmaExternalId || ls.notDefined,
				show: false,
				width: 160
			},
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 200,
				show: true,
				sortMethod: (a, b) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					if (aD.isSame(bD)) {
						return 0;
					}
					if (aD.isAfter(bD)) {
						return 1;
					}
					return -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.type,
				id: "type",
				sortable: false,
				show: true,
				accessor: c => ls[chargeType[c.type]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(chargeType).map(c => (
							<option key={c} value={c}>
								{ls[chargeType[c]]}
							</option>
						))}
					</select>
				),
				width: 160
			},
			{
				Header: ls.chargePersonType,
				id: "chargePersonType",
				accessor: c => ls[chargePersonType[c.chargePersonType]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todas</option>
						{Object.keys(chargePersonType).map(c => (
							<option key={c} value={c}>
								{ls[chargePersonType[c]]}
							</option>
						))}
					</select>
				),
				show: true,
				width: 100
			},
			{
				Header: ls.business,
				id: "business",
				accessor: c => c.businessName ?? ls.none,
				show: true,
				width: 260
			},
			{
				Header: ls.cnpj,
				id: "cnpj",
				accessor: c => c.cnpj ?? ls.none,
				show: true,
				width: 160
			},
			{
				Header: ls.value,
				id: "value",
				show: true,
				format: c => c.value / 100,
				accessor: c => toStringCurrency(c.value / 100),
				Footer: () => <b>{toStringCurrency((stateValue || 0) / 100)}</b>,
				width: 160
			},
			{
				Header: ls.associatedUser,
				id: "username",
				accessor: c => c.userName ?? ls.none,
				show: true,
				width: 260
			},
			{
				Header: "Bolso",
				id: "bagToName",
				accessor: c => c.bagName ?? ls.none,
				show: true,
				width: 160
			},
			{
				Header: ls.cpf,
				id: "cpf",
				accessor: c => c.cpf ?? ls.none,
				show: false,
				width: 160
			},
			{
				Header: ls.reasonCharge,
				id: "description",
				accessor: c => c.description ?? ls.none,
				show: false,
				width: 160
			},
			{
				Header: "",
				id: "actions",
				show: true,
				filterable: false,
				sortable: false,
				accessor: c =>
					(isSuperAdmin && (
						// eslint-disable-next-line jsx-a11y/control-has-associated-label
						<button
							type="submit"
							data-testid="fa-trash-charge-report"
							style={{
								cursor: "pointer",
								color: "#AEAEAE",
								border: "none",
								backgroundColor: "transparent"
							}}
							onClick={() => openAlertModal(c)}
						>
							<FaTrash />
						</button>
					)) ??
					ls.none,
				width: 160
			}
		],
		[stateValue, openAlertModal, isSuperAdmin]
	);

	return {
		columns
	};
};
