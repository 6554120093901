import ModalSelector from "Components/ModalSelector";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getBusinessesTiny,
	setValue,
	updateBusinessTransferPolicies
} from "store/actions/balanceAccount/report";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";

let debounceUpdateBusinessTransferPolicies: (() => void) & _.Cancelable = null;

function BusinessTransferPolicies() {
	const dispatch = useDispatch();

	const {
		showBusinessesTransferFormModal,
		businessesTiny,
		businessTransferPolicies,
		loading
	} = useSelector<any, IBalanceAccountState>(c => c.balanceAccount);

	useEffect(() => {
		if (showBusinessesTransferFormModal) dispatch(getBusinessesTiny());
	}, [dispatch, showBusinessesTransferFormModal]);

	const handleClose = () =>
		dispatch(setValue({ showBusinessesTransferFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ businessId: u.id, status: u.status }));

		if (debounceUpdateBusinessTransferPolicies)
			debounceUpdateBusinessTransferPolicies.cancel();

		debounceUpdateBusinessTransferPolicies = _.debounce(() => {
			dispatch(
				updateBusinessTransferPolicies(data, err => {
					if (err) {
						console.log("err", err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						handleClose();
					}
				})
			);
		}, 1000);

		debounceUpdateBusinessTransferPolicies();
	};

	if (!showBusinessesTransferFormModal) return null;

	return (
		<ModalSelector
			open={showBusinessesTransferFormModal}
			id="edit-balance-account-type-businesses-policy"
			loading={loading}
			data={businessesTiny.map(c => ({
				id: c.id,
				name: c.name,
				status: businessTransferPolicies.find(
					d => d.businessId === c.id && d.status
				)
					? 1
					: 0
			}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="Autorizar transferências"
		/>
	);
}

export default BusinessTransferPolicies;
