/* eslint-disable @typescript-eslint/no-explicit-any */
import "react-toastify/dist/ReactToastify.min.css";

import DayjsUtils from "@date-io/dayjs";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import dayjs from "dayjs";
import ptBR from "dayjs/locale/pt-br";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import React from "react";
import { isMobile } from "react-device-detect";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import ErrorMobile from "views/ErrorMobile";

import history from "./AppHistory";
import storeCreator from "./store";
import AppRouter from "./views/AppRouter";

const getLogRocketId = (str: string) => {
	const ids = str.split(";");
	try {
		const now = new Date();

		const days = dayjs(now).endOf("month").date();

		const divide = Math.round(days / ids.length);

		const index = Math.trunc(now.getDate() / divide);

		return ids[index];
	} catch (error) {
		return ids.pop();
	}
};

if (process.env.REACT_APP_LOG_ROCKET) {
	setupLogRocketReact(LogRocket);

	LogRocket.init(getLogRocketId(process.env.REACT_APP_LOG_ROCKET), {
		network: {
			requestSanitizer: (request: any) => {
				if (request.body?.indexOf && request.body?.indexOf("password") > -1) {
					let body = JSON.parse(request.body);

					if (body.password) body.password = null;

					request.body = JSON.stringify(body);
				}

				// request.headers["Authorization"] = null;

				return request;
			}
		}
	});
}

const { store, persistor } = storeCreator();

const theme = createMuiTheme({
	typography: {
		fontFamily: "'Exo', sans-serif;"
	},
	palette: {
		primary: {
			light: "#FC02AE",
			main: "#5734D9",
			dark: "#8625CD"
		}
	}
});

const App: React.FC = () =>
	!isMobile ? (
		<div className="App">
			<ThemeProvider theme={theme}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<MuiPickersUtilsProvider utils={DayjsUtils} locale={ptBR}>
							<ToastContainer />
							<AppRouter history={history} />
						</MuiPickersUtilsProvider>
					</PersistGate>
				</Provider>
			</ThemeProvider>
		</div>
	) : (
		<ErrorMobile />
	);

export const reduxStore = store;
export default App;
