import { Card } from "@material-ui/core";
import styled from "styled-components";

interface IButtonSelect {
	selected?: boolean;
}

export const Container = styled.div`
	background-color: #fff;
`;

export const TitleContainer = styled.div`
	text-align: left;

	font-size: 28px;
	font-weight: bold;
	font-family: Exo;
	letter-spacing: 0px;
	color: #5734d9;
	margin-top: 30px;
	margin-bottom: 20px;
	margin-left: 25px;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-top: 20px;
`;

export const RowCards = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
`;

export const BoxCard = styled(Card)`
	padding: 20px;
	border-radius: 6px;
`;

export const ColumnCard = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const TitleCard = styled.div`
	text-align: left;
	font: normal normal medium 21px/28px Exo;
	letter-spacing: 0px;
	color: #707070;
`;

export const MoneyCard = styled.div`
	text-align: left;
	font: normal normal bold 26px/35px Exo;
	letter-spacing: 0px;
	color: #5734d9;
	opacity: 1;
`;

export const RowContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const ColumnsLabel = styled.div`
	display: flex;
	flex-direction: column;
	text-align: right;
	width: 30%;
`;

export const ColumnsInput = styled.div`
	display: flex;
	flex-direction: column;
	width: 60%;
`;

export const Label = styled.div`
	font: normal normal medium 21px Exo;
	font-weight: 500;
	font-size: 18px;
	font-family: Exo;
	letter-spacing: 0px;
	color: #707070;
	opacity: 1;
	display: flex;
	align-items: center;
	justify-content: end;
	height: 50px;
	padding-bottom: 5px;
`;

export const BackgroundButton = styled.div`
	display: flex;
	justify-content: end;
	margin-right: 20px;
	font: normal normal 600 18px Exo;
	padding: 6px;
`;

export const ButtonSelection = styled.button<IButtonSelect>`
	background: ${props => (props.selected ? "#5734d9" : "#BBBBBB")} 0% 0%
		no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	padding: 6px 30px;
	border: none;

	text-align: left;
	font: normal normal 600 16px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	cursor: pointer;
	height: 38px;
`;

export const Footer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-top: 20px;
`;
