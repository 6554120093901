import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-top: 30px;
`;

export const TextBody = styled.p`
	text-align: center;
	font: normal normal 600 24px Exo;
	letter-spacing: 0px;
	color: #4f4f4f;
	opacity: 1;
	margin: 0;
	padding: 0;
`;

export const TextAlertBody = styled.p`
	text-align: left;
	font: normal normal normal 22px Exo;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
`;

export const RowBodyButtons = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ButtonCancel = styled.button`
	background: #5734da 0% 0% no-repeat padding-box;
	border-radius: 8px;
	text-align: left;
	font: normal normal bold 21px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	margin-right: 15px;
	width: 157px;
	text-align: center;
	height: 45px;
	border: none;
	cursor: pointer;
`;

export const ButtonExclude = styled.button`
	text-align: left;
	font: normal normal bold 19px Exo;
	letter-spacing: 0px;
	color: #e00000;
	border: 1px solid #e00000;
	border-radius: 8px;
	opacity: 1;
	background-color: #fff;
	width: 157px;
	height: 45px;
	text-align: center;
	cursor: pointer;
`;
