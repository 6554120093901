import React, { useEffect, useState } from "react";

interface PropsImageToBlob {
	imageUrl: string;
	style?: React.CSSProperties;
}

const ImgBlob: React.FC<PropsImageToBlob> = ({ imageUrl, style }) => {
	const [blobUrl, setBlobUrl] = useState("");

	useEffect(() => {
		const getImageBlob = async () => {
			const response = await fetch(imageUrl);
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);
			setBlobUrl(url);
		};

		getImageBlob();
	}, [imageUrl]);

	return (
		<img src={blobUrl} alt="imagem" width={200} height={80} style={style} />
	);
};

export default ImgBlob;
