import * as actions from "store/actions/accession";

import { IAccession } from "./types";

const initialState: IAccession = {
	items: { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] },
	errors: {},
	loading: {},
	item: null,
	email: "",
	userId: "",
	status: "0",
	showFormModal: false,
	showEditFormModal: false
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_ACCESSIONS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_ACCESSIONS_SUCCESS: {
			let items = state.items || initialState.items;

			let items0 = payload.filter(c => c.status === 0);
			let items1 = payload.filter(c => c.status === 1);
			let items2 = payload.filter(c => c.status === 2);
			let items3 = payload.filter(c => c.status === 3);
			let items4 = payload.filter(c => c.status === 4);
			let items5 = payload.filter(c => c.status === 5);

			for (const item of items0) {
				if (!items[0]) items[0] = [];

				if (items[0].find(c => c.id === item.id)) continue;

				items[0].push(item);
			}

			for (const item of items1) {
				if (!items[1]) items[1] = [];

				if (items[1].find(c => c.id === item.id)) continue;

				items[1].push(item);
			}

			for (const item of items2) {
				if (!items[2]) items[2] = [];

				if (items[2].find(c => c.id === item.id)) continue;

				items[2].push(item);
			}

			for (const item of items3) {
				if (!items[3]) items[3] = [];

				if (items[3].find(c => c.id === item.id)) continue;

				items[3].push(item);
			}

			for (const item of items4) {
				if (!items[4]) items[4] = [];

				if (items[4].find(c => c.id === item.id)) continue;

				items[4].push(item);
			}

			for (const item of items5) {
				if (!items[5]) items[5] = [];

				if (items[5].find(c => c.id === item.id)) continue;

				items[5].push(item);
			}

			return {
				...state,
				items,
				loading: { getAll: false }
			};
		}
		case actions.GET_ACCESSIONS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}
		case actions.UPDATE_ACCESSION_STATUS: {
			return {
				...state,
				loading: { status: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_ACCESSION_STATUS_SUCCESS: {
			return {
				...state,
				loading: { status: false }
			};
		}
		case actions.UPDATE_ACCESSION_STATUS_FAILED: {
			return {
				...state,
				loading: { status: false },
				errors: payload
			};
		}
		case actions.CREATE_ACCESSION: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_ACCESSION_SUCCESS: {
			let { items } = state;

			items[payload.status].push(payload);

			return {
				...state,
				loading: { create: false },
				item: null,
				items: { ...items },
				userId: initialState.userId,
				email: initialState.email,
				status: initialState.status
			};
		}
		case actions.CREATE_ACCESSION_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.UPDATE_ACCESSION: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_ACCESSION_SUCCESS: {
			let { items } = state;

			for (const key of Object.keys(items)) {
				items[key] = items[key].filter(c => c.id !== payload.id);
			}

			items[payload.status].push(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items: { ...items },
				email: initialState.email,
				userId: initialState.userId,
				status: initialState.status
			};
		}

		case actions.UPDATE_ACCESSION_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}

		case actions.DELETE_ACCESSION: {
			return {
				...state,
				loading: { delete: true },
				errors: initialState.errors
			};
		}

		case actions.DELETE_ACCESSION_SUCCESS: {
			let { items } = state;

			for (const key of Object.keys(items)) {
				items[key] = items[key].filter(c => c.id !== payload);
			}

			return {
				...state,
				item: null,
				items: { ...items },
				loading: { delete: false },
				email: initialState.email,
				userId: initialState.userId,
				status: initialState.status
			};
		}

		case actions.DELETE_ACCESSION_FAILED: {
			return {
				...state,
				loading: { delete: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			if (payload)
				return {
					...initialState,
					items: { 0: [], 1: [], 2: [], 3: [], 4: [] }
				};

			return {
				...state,
				loading: {}
			};
		default: {
			return state;
		}
	}
};

export default reducer;
