import { Button, Modal, Typography } from "@material-ui/core";
import Spinner from "Components/Spinner";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import React from "react";

import { DeleteModalType } from "./DeleteChargeModal.types";
import useStyles from "./styles";

const DeleteChargeModal: React.FC<DeleteModalType> = ({
	isOpen,
	item,
	closeModal,
	onPressDelete,
	loading
}) => {
	const classes = useStyles({});
	return (
		<Modal
			aria-labelledby="exclude-charge-modal-label"
			aria-describedby="exclude-charge-modal-description"
			className={classes.modal}
			open={isOpen}
			onClose={closeModal}
			closeAfterTransition
		>
			<div className={classes.paper}>
				<Typography variant="body1" align="left">
					{`Tem certeza que deseja excluir o registro ${
						item?.identifier
					} no valor de ${toStringCurrency(item?.value / 100 || 0)}? `}
					<br />
					<b>Essa ação é irreversível.</b>
				</Typography>

				<section className={classes.footer}>
					<Button
						size="large"
						className={classes.button}
						style={{ backgroundColor: "#5734DA", color: "white" }}
						id="modal-delete-button"
						variant="contained"
						onClick={onPressDelete}
					>
						{loading ? <Spinner color="inherit" size={24} /> : ls.delete}
					</Button>
					<Button
						className={classes.button}
						variant="contained"
						size="large"
						onClick={closeModal}
					>
						{ls.cancel}
					</Button>
				</section>
			</div>
		</Modal>
	);
};

export default DeleteChargeModal;
