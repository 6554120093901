import * as api from "services/api";
import { getErrors } from "helpers/error";

import ls from "Localization";

import _ from "lodash";

export const CREATE_BUSINESS_CATEGORY =
	"[BUSINESS CATEGORY CREATE] CREATE_BUSINESS_CATEGORY";
export const CREATE_BUSINESS_CATEGORY_SUCCESS =
	"[BUSINESS CATEGORY CREATE] CREATE_BUSINESS_CATEGORY_SUCCESS";
export const CREATE_BUSINESS_CATEGORY_FAILED =
	"[BUSINESS CATEGORY CREATE] CREATE_BUSINESS_CATEGORY_FAILED";

export const SET_VALUE = "[BUSINESS CATEGORY CREATE] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS CATEGORY CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createBusinessCategory(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_BUSINESS_CATEGORY });

		try {
			const { login, businessCategoryCreate } = getState();

			let { name, color, icon, iconSource, isVisible } = businessCategoryCreate;

			const errors: any = {};

			if (!name) errors.name = ls.required;

			dispatch(setValue({ errors }));

			if (_.keys(errors).length > 0) return;

			let response = await api.sendPost(
				"/Business/Category",
				{
					name,
					color,
					icon,
					iconSource,
					isVisible
				},
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_BUSINESS_CATEGORY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_BUSINESS_CATEGORY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_BUSINESS_CATEGORY_FAILED,
				payload: {
					error: "Connection error", exception: error
				}
			});
		}
	};
}
