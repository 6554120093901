import * as api from "services/api";
import { Callback } from "Types/Callback";

export const DELETE_CHARGE = "[DELETE CHARGE] DELETE_CHARGE";

export const DELETE_CHARGE_SUCCESS = "[DELETE_CHARGE] DELETE_CHARGE_SUCCESS";
export const DELETE_CHARGE_FAILED = "[DELETE_CHARGE] DELETE_CHARGE_FAILED";

export function deleteCharge(chargeId: string, callback: Callback<void>) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_CHARGE });
		try {
			const { login } = getState();

			await api.sendDelete(`/Charge/${chargeId}`, {
				Authorization: `Bearer ${login.token}`
			});
			dispatch({
				type: DELETE_CHARGE_SUCCESS
			});
			callback(null, null);
		} catch (error) {
			dispatch({
				type: DELETE_CHARGE_FAILED,
				payload: { errors: error }
			});
			callback(error, null);
		}
	};
}
