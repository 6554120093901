import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getUserData } from "store/actions/login";

import { clearValues, getUserBusiness } from "../../store/actions/business";
import Header from "./Header";
import Router from "./Router";

export function Dashboard(props: any) {
	const dispatch = useDispatch();
	const { history } = props;

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getUserData());
		dispatch(getUserBusiness());
	}, [dispatch]);

	return (
		<div>
			<Header history={history}>
				<Router history={history} />
			</Header>
		</div>
	);
}

export default Dashboard;
