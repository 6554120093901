import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Fab, Grid } from "@material-ui/core";
import { MdAdd, MdEdit } from "react-icons/md";

import Report from "Components/Report";

import ls from "Localization";

import { getBusinessesCategories } from "store/actions/business/category/report";

import useStyles from "./styles";
import { Link } from "react-router-dom";
import iconType from "models/iconType";
import _ from "lodash";
import { Helmet } from "react-helmet";

let getBusinessCategoriesDebounced: (() => void) & _.Cancelable = null;

function BusinessCategoryReport({ history }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const table = useRef(null);

	const state = useSelector<any, any>(c => c.businessCategoryReport);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getBusinessCategoriesDebounced) {
			getBusinessCategoriesDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate?.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getBusinessCategoriesDebounced = _.debounce(
			() =>
				dispatch(
					getBusinessesCategories(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => p + `&filters[${c.id}]=${c.value}`, ""),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					)
				),
			500
		);

		getBusinessCategoriesDebounced();
	};

	const columns = useMemo(
		() => [
			{
				Header: "Id",
				accessor: "id",
				width: 320
			},
			{
				Header: ls.name,
				accessor: "name"
			},
			{
				Header: ls.color,
				accessor: "color"
			},
			{
				Header: ls.icon,
				accessor: "icon"
			},
			{
				Header: ls.iconSource,
				id: "iconSource",
				accessor: c => iconType[c.iconSource],
				Filter: ({ filter, onChange }) => {
					return (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter?.value ?? "all"}
						>
							<option value="">Todos</option>
							{Object.keys(iconType).map(c => (
								<option key={c} value={c}>
									{iconType[c]}
								</option>
							))}
						</select>
					);
				}
			},
			{
				Header: ls.isVisible,
				id: "isVisible",
				accessor: c => (c.isVisible ? ls.yes : ls.no),
				Filter: ({ filter, onChange }) => {
					return (
						<select
							onChange={event => onChange(event.target.value)}
							style={{ width: "100%" }}
							value={filter ? filter.value : ""}
						>
							<option value="">Todos</option>
							<option value="true">Sim</option>
							<option value="false">Não</option>
						</select>
					);
				}
			},
			{
				Header: ls.actions,
				id: "actions",
				accessor: c => (
					<Grid container spacing={2} justify="center">
						<Grid item>
							<Link to={`/Store/Category/${c.id}/Edit`}>
								<MdEdit />
							</Link>
						</Grid>
					</Grid>
				),
				width: 100,
				filterable: false
			}
		],
		[]
	);

	return (
		<div>
			<Helmet>
				<title>
					{ls.businessCategories} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.businessCategories}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			<Fab
				color="primary"
				aria-label="Add"
				className={classes.fab}
				onClick={() => history.push("/Store/Category/Create")}
			>
				<MdAdd size={24} />
			</Fab>
		</div>
	);
}

export default BusinessCategoryReport;
