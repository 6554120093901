import * as actions from "store/actions/productManagerAccount";

import { IProductManagerAccount } from "./types";

const initialState: IProductManagerAccount = {
	balance: 0,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_PMA_BALANCE: {
			return {
				...state,
				loading: { balance: true },
				errors: initialState.errors
			};
		}
		case actions.GET_PMA_BALANCE_SUCCESS: {
			return {
				...state,
				balance: payload,
				loading: { balance: false }
			};
		}
		case actions.GET_PMA_BALANCE_FAILED: {
			return {
				...state,
				loading: { balance: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			if (payload)
				return {
					...initialState
				};

			return {
				...state,
				loading: {}
			};
		default: {
			return state;
		}
	}
};

export default reducer;
