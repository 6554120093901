import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_BANNERS = "[PRODUCT] GET_BANNERS";
export const GET_BANNERS_SUCCESS = "[PRODUCT] GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAILED = "[PRODUCT] GET_BANNERS_FAILED";

export const UPDATE_BANNERS = "[PRODUCT] UPDATE_BANNERS";
export const UPDATE_BANNERS_SUCCESS = "[PRODUCT] UPDATE_BANNERS_SUCCESS";
export const UPDATE_BANNERS_FAILED = "[PRODUCT] UPDATE_BANNERS_FAILED";

export const EXPORT_CLICKS_BANNERS = "[PRODUCT] EXPORT_CLICKS_BANNERS";
export const EXPORT_CLICKS_BANNERS_SUCCESS =
	"[PRODUCT] EXPORT_CLICKS_BANNERS_SUCCESS";
export const EXPORT_CLICKS_BANNERS_FAILED =
	"[PRODUCT] EXPORT_CLICKS_BANNERS_FAILED";

export const SET_VALUE = "[PRODUCT] SET_VALUE";
export const CLEAR_VALUES = "[PRODUCT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getBannersOrder(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BANNERS });

		try {
			const { login } = getState();

			const response = await api.sendGet("/Product/Banner/Order", {
				Authorization: `Bearer ${login.token}`
			});

			const result = await response.json();

			if (response.status === 200) {
				dispatch({
					type: GET_BANNERS_SUCCESS,
					payload: result
				});

				return callback && callback(null, result);
			}
			const errors = getErrors("Erro ao carregar banners");

			dispatch({
				type: GET_BANNERS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: GET_BANNERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editBannersOrder(items, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_BANNERS });

		try {
			const { login } = getState();

			const itemsFilterObject = items.map((i, index) => ({
				productId: i.productId,
				order: index
			}));
			let errors: any = {};

			const response = await api.sendPost(
				"/Product/Banner/Order",
				itemsFilterObject,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.status === 200) {
				dispatch({
					type: UPDATE_BANNERS_SUCCESS,
					payload: response
				});
				return callback && callback();
			}

			let result = await response.json();
			errors = getErrors(result.errors);

			dispatch({
				type: UPDATE_BANNERS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: UPDATE_BANNERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getExportClicksBanners(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: EXPORT_CLICKS_BANNERS });

		try {
			const { login, productBanners } = getState();

			const { startDate, endDate, eventName } = productBanners;

			const response = await api.sendPost(
				"/Export/MobileAnalytics",
				{ startDate, endDate, eventName },
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			const result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EXPORT_CLICKS_BANNERS_SUCCESS,
					payload: result
				});

				return callback && callback(null, result.model);
			}

			dispatch({
				type: EXPORT_CLICKS_BANNERS_FAILED,
				payload: result.errors
			});
			return callback && callback(result.errors);
		} catch (error) {
			return dispatch({
				type: EXPORT_CLICKS_BANNERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
