import { Button, Fade, Typography } from "@material-ui/core";
import ModalGeneric from "Components/Modals/ModalGeneric";
import TabsTable from "Components/TabsTable";
import { handleChangeEvent } from "helpers/files";
import { toastError } from "helpers/toast";
import ls from "Localization";
import React, { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setValue, uploadPhotos } from "store/actions/product";

import useStyles from "./styles";
import TableProduct from "./TableProduct";

function PhotoModal() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const inputRef = useRef<HTMLInputElement>(null);

	const { showPhotosModal, loading } = useSelector<any, any>(c => c.product);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showPhotosModal", false);

	const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = handleChangeEvent(e);

		if (files && files.length) {
			dispatch(
				uploadPhotos(files, err => {
					if (err) {
						toastError(err);
					}
				})
			);
		}
	};

	const arrayTabs = useMemo(
		() => [
			{
				label: ls.product,
				table: () => <TableProduct />
			}
		],
		[]
	);

	if (!showPhotosModal) return null;

	return (
		<ModalGeneric
			open={showPhotosModal || false}
			handleClose={handleClose}
			stylePaper={{ backgroundColor: "trasparent", padding: 0 }}
			body={() => (
				<Fade in={showPhotosModal}>
					<div className={classes.paper}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h6">Fotos</Typography>
							<Button
								onClick={() => inputRef.current?.click()}
								disabled={loading.photos}
							>
								Adicionar fotos
							</Button>
						</div>

						<hr />
						<div className={classes.body}>
							<TabsTable tabs={arrayTabs} />
							<div className={classes.footer}>
								<Button
									color="primary"
									variant="contained"
									onClick={handleClose}
								>
									{ls.close}
								</Button>
							</div>
						</div>

						<input
							ref={inputRef}
							style={{ display: "none" }}
							type="file"
							multiple
							accept="image/png,image/jpeg"
							onChange={handleFilesChange}
						/>
					</div>
				</Fade>
			)}
		/>
	);
}

export default PhotoModal;
