import { getErrors } from "helpers/error";
import ls from "Localization";
import lodash from "lodash";
import * as api from "services/api";

export const CREATE_TRANSACTION = "[TRANSACTION CREATE] CREATE_TRANSACTION";
export const CREATE_TRANSACTION_SUCCESS =
	"[TRANSACTION CREATE] CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAILED =
	"[TRANSACTION CREATE] CREATE_TRANSACTION_FAILED";

export const SET_VALUE = "[TRANSACTION CREATE] SET_VALUE";
export const CLEAR_VALUES = "[TRANSACTION CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createTransaction(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_TRANSACTION });

		try {
			const { login, transactionCreate } = getState();

			const {
				creationDate,
				storeId,
				clientId,
				value,
				discount,
				installmentAmount,
				paymentMethod,
				description,
				status
			} = transactionCreate;

			const errors: any = {};

			if (!storeId) errors.storeId = ls.required;
			if (!clientId) errors.clientId = ls.required;
			if (!value) errors.value = ls.required;
			if (!installmentAmount) errors.installmentAmount = ls.required;
			if (!status) errors.status = ls.required;

			if (lodash.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_TRANSACTION_FAILED,
					payload: errors
				});
			}

			const model = {
				creationDate,
				storeId,
				clientId,
				value: Math.round(value * 100),
				discount: Math.round(discount * 100),
				installmentAmount,
				description,
				status
			};

			const response = await api.sendPost(
				`/Sale/Admin?paymentMethod=${paymentMethod || ""}`,
				model,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_TRANSACTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_TRANSACTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_TRANSACTION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createTransactionVoucher(model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_TRANSACTION });

		try {
			const { login, transactionCreate } = getState();

			const { storeId, clientId, product, quantity, creditId } =
				transactionCreate;

			const errors: any = {};

			if (!storeId) errors.storeId = ls.required;
			if (!clientId) errors.clientId = ls.required;
			if (!product) errors.product = ls.required;
			if (!creditId) errors.creditId = ls.required;
			if (quantity === 0) errors.quantity = ls.required;

			if (lodash.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_TRANSACTION_FAILED,
					payload: errors
				});
			}

			const response = await api.sendPost("/Sale/Admin/Vouchers", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_TRANSACTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_TRANSACTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_TRANSACTION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
