import * as actions from "store/actions/moderation/report";

import { IReportModeration } from "../types/report";

const initialState: IReportModeration = {
	items: [],
	pages: null,
	count: 0,
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_MODERATIONS: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}

		case actions.GET_MODERATIONS_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload
			};
		}

		case actions.GET_MODERATIONS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			return {
				...initialState
			};

		default: {
			return state;
		}
	}
};

export default reducer;
