import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
	Typography,
	Grid,
	Fade,
	Backdrop,
	Modal,
	Button,
	IconButton
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ReactTable from "react-table";
import dayjs from "dayjs";
import { MdDelete, MdEdit } from "react-icons/md";

import ls from "Localization";

import { getProduct, setValue } from "store/actions/product";
import {
	deleteProductPromotion,
	setValue as setValuePromotion
} from "store/actions/productPromotion";

import CreateProductPromotion from "./Create";
import EditProductPromotion from "./Edit";
import { toStringCurrency } from "helpers/string";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

function ProductPromotionModal() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { showPromotionsFormModal, item } = useSelector<any, any>(
		c => c.product
	);

	const items = item?.promotions;

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleEdit = item => {
		dispatch(
			setValuePromotion({
				item,
				name: item.name,
				price: item.price / 100.0,
				startAt: item.startAt,
				endAt: item.endAt
			})
		);
	};

	const handleClose = () => handleChange("showPromotionsFormModal", false);

	const handleDelete = id => {
		dispatch(
			deleteProductPromotion(id, err => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					dispatch(
						getProduct(item.id, "promotions", err => {
							if (err) {
								console.log(err);
								if (err.default) alert(err.default);
								else if (typeof err === "string") alert(err);
								else alert(JSON.stringify(err));
							}
						})
					);
				}
			})
		);
	};

	return (
		<div>
			<Modal
				aria-labelledby="edit-product-image-modal-title"
				aria-describedby="edit-product-image-modal-description"
				className={classes.modal}
				open={showPromotionsFormModal || false}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={showPromotionsFormModal}>
					<div className={classes.paper}>
						<div style={{ display: "flex", justifyContent: "space-between" }}>
							<Typography variant="h6">{ls.promotions}</Typography>
							<Button
								onClick={() =>
									handleChange("showCreatePromotionFormModal", true)
								}
							>
								{ls.addPromotion}
							</Button>
						</div>
						<hr />
						<div className={classes.body}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<ReactTable
										data={items}
										getTdProps={() => {
											return {
												style: {
													display: "flex",
													alignItems: "center"
												}
											};
										}}
										columns={[
											{
												Header: ls.name,
												accessor: "name"
											},
											{
												Header: ls.price,
												id: "price",
												accessor: (c: any) => toStringCurrency(c.price / 100.0)
											},
											{
												Header: ls.startAt,
												id: "startAt",
												accessor: (c: any) =>
													dayjs(c.startAt).format(ls.dateFormatWithoutSeconds)
											},
											{
												Header: ls.endAt,
												id: "endAt",
												accessor: (c: any) =>
													dayjs(c.endAt).format(ls.dateFormatWithoutSeconds)
											},
											{
												Header: "Ações",
												id: "actions",
												accessor: c => (
													<div
														style={{ display: "flex", flexDirection: "row" }}
													>
														<IconButton
															style={{
																padding: 8,
																fontSize: "1.3rem"
															}}
															onClick={() => {
																handleEdit(c);
															}}
														>
															<MdEdit />
														</IconButton>
														<IconButton
															style={{ padding: 4 }}
															onClick={() => handleDelete(c.id)}
														>
															<MdDelete />
														</IconButton>
													</div>
												),
												width: 90
											}
										]}
										pageSize={5}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button
									color="primary"
									variant="contained"
									onClick={handleClose}
								>
									{ls.close}
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
			<EditProductPromotion />
			<CreateProductPromotion />
		</div>
	);
}

export default ProductPromotionModal;
