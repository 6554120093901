import { Grid, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import Spinner from "Components/Spinner";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import {
	deleteDeliveryAddress,
	getDeliveryAddresses
} from "store/actions/deliveryAddress";

import styles from "./styles";

let getDeliveryAddressesDebounced: (() => void) & _.Cancelable = null;

function DeliveryAddresses({ business }) {
	const history = useHistory();
	const match = useRouteMatch();
	const dispatch = useDispatch();
	const table = useRef(null);

	const [loadingDelete, setLoadingDelete] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);

	const state = useSelector<any, any>(c => c.deliveryAddress);

	const loadData = useCallback(() => {
		dispatch(getDeliveryAddresses(null, null, null, null, null, null, null));
		setIsLoaded(true);
	}, [dispatch]);

	const handleFetchData = useCallback(
		(tableState, _instance) => {
			if (!isLoaded) {
				return;
			}

			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getDeliveryAddressesDebounced) {
				getDeliveryAddressesDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let creationDate = filtered.find(c => c.id === "creationDate");

			if (creationDate?.value) {
				filtered = filtered.filter(c => c.id !== "creationDate");

				if (creationDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: creationDate.value.startDate
					});
				}

				if (creationDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: creationDate.value.endDate
					});
			}

			getDeliveryAddressesDebounced = _.debounce(
				() =>
					dispatch(
						getDeliveryAddresses(
							page * pageSize,
							pageSize,
							filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getDeliveryAddressesDebounced();
		},
		[dispatch, isLoaded]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state, null),
		[handleFetchData]
	);

	const handleDelete = useCallback(
		c => {
			setLoadingDelete(c.id);
			dispatch(
				deleteDeliveryAddress(c.id, err => {
					if (err) {
						if (typeof err !== "string") err = JSON.stringify(err);
						console.log(err);
						alert(err);
					} else {
						load();
					}
					setLoadingDelete("");
				})
			);
		},
		[dispatch, load]
	);

	const columns = useMemo(
		() => [
			{
				Header: ls.name,
				id: "name",
				accessor: c => (c.name ? c.name : "Não informado")
			},
			{
				Header: ls.deliveryValue,
				id: "value",
				show: true,
				format: c => c.value / 100,
				accessor: c => toStringCurrency(c.value / 100),
				width: 120
			},
			{
				Header: ls.minPrice,
				id: "minPrice",
				show: true,
				format: c => c.minPrice / 100,
				accessor: c => toStringCurrency(c.minPrice / 100),
				width: 120
			},
			{
				Header: ls.freeShipping,
				id: "freeShipping",
				accessor: c => (c.freeShipping ? ls.yes : ls.no),
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : ""}
					>
						<option value="">Todos</option>
						<option value="true">Sim</option>
						<option value="false">Não</option>
					</select>
				),
				width: 150
			},
			{
				Header: ls.standardDeliveryTime,
				id: "time",
				accessor: c => (c.time ? `${c.time} min` : ls.notInformed),
				width: 200
			},
			{
				Header: ls.actions,
				id: "actions",
				filterable: false,
				accessor: c => (
					<Grid container spacing={2} justify="center">
						<Grid item>
							<IconButton
								style={{
									padding: 8,
									fontSize: "1.3rem"
								}}
								onClick={() =>
									history.push(
										`/Store/${business.id}/DeliveryAddress/${c.id}/Edit`
									)
								}
							>
								<MdEdit />
							</IconButton>
							{loadingDelete === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{ padding: 8, fontSize: "1.3rem" }}
									onClick={() => handleDelete(c)}
								>
									<MdDelete />
								</IconButton>
							)}
						</Grid>
					</Grid>
				),
				width: 140
			}
		],
		[business.id, handleDelete, history, loadingDelete]
	);

	return (
		<LoadOnScroll load={loadData}>
			<Report
				manual
				tableRef={table}
				title={ls.deliveryAddresses}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				headerRightComponent={
					<div>
						<IconButton
							color="primary"
							onClick={() =>
								history.push(
									`/Store/${match.params["id"]}/DeliveryAddress/Create`
								)
							}
						>
							<FaPlus />
						</IconButton>
					</div>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(DeliveryAddresses);
