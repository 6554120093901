import { modalAlert } from "Components/Modals/ModalAlert";
import ls from "Localization";
import * as api from "services/api";

export const CALC_LIMIT = "[CALC LIMIT] CALC_LIMIT";
export const CALC_LIMIT_SUCCESS = "[CALC LIMIT] CALC_LIMIT_SUCCESS";
export const CALC_LIMIT_FAILED = "[CALC LIMIT] CALC_LIMIT_FAILED";

export const SET_VALUE = "[CALC LIMIT] SET_VALUE";
export const CLEAR_VALUES = "[CALC LIMIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function userCalcLimit(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CALC_LIMIT });

		try {
			const { login } = getState();

			let response = await api.sendGet(`/User/${id}/Limit`, {
				Authorization: `Bearer ${login.token}`
			});

			const result = await response.json();

			if (result.isValid) {
				callback && callback(null, result.model);
				return dispatch({
					type: CALC_LIMIT_SUCCESS
				});
			}

			callback && callback(result.errors);
			return dispatch({
				type: CALC_LIMIT_FAILED,
				payload: result.errors
			});
		} catch (error) {
			callback && callback(error);

			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});

			return dispatch({
				type: CALC_LIMIT_FAILED
			});
		}
	};
}
