import React from "react";
import { RxPencil2 } from "react-icons/rx";

import { Container, TextSelect } from "./styles";

interface ItemsJoin {
	text: string;
	isDisableButton: boolean;
	handleOpen: () => void;
}

const TextPen: React.FC<ItemsJoin> = ({
	text,
	handleOpen,
	isDisableButton
}) => (
	<Container>
		<TextSelect isTextSelect={!!text}>
			{text || "Selecione os bolsos"}
		</TextSelect>

		{!isDisableButton && (
			<RxPencil2
				size={18}
				color="#5236D1"
				style={{ cursor: "pointer", marginLeft: 5 }}
				onClick={handleOpen}
			/>
		)}
	</Container>
);

export default TextPen;
