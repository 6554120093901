import { Dispatch } from "redux";
import * as api from "services/api";

export const CREATE_REQUEST_CARDS = "[REQUEST_CARDS CREATE] CREATE_TRANSACTION";
export const CREATE_REQUEST_CARDS_SUCCESS =
	"[REQUEST_CARDS CREATE] CREATE_REQUEST_CARDS_SUCCESS";
export const CREATE_REQUEST_CARDS_FAILED =
	"[REQUEST_CARDS CREATE] CREATE_REQUEST_CARDS_FAILED";

export const SET_VALUE = "[REQUEST_CARDS CREATE] SET_VALUE";
export const CLEAR_VALUES = "[REQUEST_CARDS CREATE] CLEAR_VALUES";

type SetValueAction = {
	type: string;
	payload: any;
};

type SetValueActionCreator = (
	payload: any
) => (dispatch: Dispatch<SetValueAction>) => void;

export const setValue: SetValueActionCreator =
	payload => (dispatch: Dispatch<SetValueAction>) => {
		dispatch({ type: SET_VALUE, payload });
	};

type ClearValuesAction = {
	type: string;
};

export function clearValues(): ClearValuesAction {
	return { type: CLEAR_VALUES };
}

export const createRequestCards =
	(callback: (err?: any, model?: any) => void) =>
	async (dispatch: Dispatch, getState: any) => {
		dispatch({ type: CREATE_REQUEST_CARDS });

		try {
			const { login, requestCreate } = getState();

			const { request } = requestCreate;

			const response = await api.sendPost("/CardDelivery", request, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				callback && callback();

				return dispatch({
					type: CREATE_REQUEST_CARDS_SUCCESS,
					payload: result.model
				});
			}

			callback && callback(result.errors);
			return dispatch({
				type: CREATE_REQUEST_CARDS_FAILED,
				payload: result.errors
			});
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: CREATE_REQUEST_CARDS_FAILED,
				payload: {
					error,
					exception: error
				}
			});
		}
	};
