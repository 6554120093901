import { getErrors } from "helpers/error";
import * as api from "services/api";

export const GET_USER_BUSINESS = "[BUSINESS] GET_USER_BUSINESS";
export const GET_USER_BUSINESS_SUCCESS = "[BUSINESS] GET_USER_BUSINESS_SUCCESS";
export const GET_USER_BUSINESS_FAILED = "[BUSINESS] GET_USER_BUSINESS_FAILED";

export const SET_VALUE = "[BUSINESS] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getUserBusiness(callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_USER_BUSINESS });

		try {
			const { login } = getState();
			let response = await api.sendGet("/Business/User", {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_BUSINESS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				// if (
				//   errors.default &&
				//   errors.default.indexOf("Model not found.") !== -1
				// ) {
				//   history.push("/Store/Create");
				// }

				dispatch({
					type: GET_USER_BUSINESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_USER_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
