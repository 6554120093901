import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import moderationCategory from "models/moderationCategory";
import moderationStatus from "models/moderationStatus";
import React, { useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { getModerations } from "store/actions/moderation/report";

dayjs.extend(customParseFormat);

let getModerationsDebounced: (() => void) & _.Cancelable = null;

function ModerationReport({ history }) {
	const dispatch = useDispatch();
	const table = useRef(null);

	const state = useSelector<any, any>(c => c.moderationReport);

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.company,
				id: "company",
				accessor: c =>
					c.company && c.company.displayName
						? c.company.displayName
						: ls.notInformed,
				show: true
			},
			{
				Header: ls.client,
				id: "client",
				accessor: c =>
					c.client && c.client.fullName ? c.client.fullName : ls.notInformed,
				show: true,
				width: 150
			},
			{
				Header: ls.store,
				id: "store",
				accessor: c =>
					c.sale && c.sale.store && c.sale.store.displayName
						? c.sale.store.displayName
						: ls.notInformed,
				show: true
			},
			{
				Header: ls.value,
				id: "originalAmount",
				accessor: c =>
					c.originalAmount
						? toStringCurrency(c.originalAmount / 100)
						: ls.notInformed,
				show: true
			},
			{
				Header: ls.allowance,
				id: "amount",
				accessor: c =>
					c.amount ? toStringCurrency(c.amount / 100) : ls.notInformed,
				show: true
			},
			{
				Header: ls.category,
				id: "category",
				sortable: false,
				show: true,
				accessor: c => ls[moderationCategory[c.category]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(moderationCategory).map(c => (
							<option key={c} value={c}>
								{ls[moderationCategory[c]]}
							</option>
						))}
					</select>
				),
				width: 150
			},
			{
				Header: ls.status,
				id: "status",
				sortable: false,
				show: true,
				accessor: c => ls[moderationStatus[c.status]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(moderationStatus).map(c => (
							<option key={c} value={c}>
								{ls[moderationStatus[c]]}
							</option>
						))}
					</select>
				),
				width: 110
			}
		],
		[]
	);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getModerationsDebounced) {
			getModerationsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate?.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		getModerationsDebounced = _.debounce(
			() =>
				dispatch(
					getModerations(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					)
				),
			500
		);

		getModerationsDebounced();
	};

	return (
		<div>
			<Helmet>
				<title>
					{ls.moderation} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.moderation}
				data={state.items || []}
				pages={state.pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={state.loading}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
				onRowClicked={row => history.push(`/Moderation/${row.id}`)}
			/>
		</div>
	);
}

export default ModerationReport;
