/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	FormHelperText,
	Grid,
	Paper,
	Typography
} from "@material-ui/core";
import Checkbox from "Components/Checkbox";
import ImageInput from "Components/ImageInput";
import { CEP, CPFCNPJ, PERCENTAGE2 } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import Switch from "Components/Switch";
import TextInput from "Components/TextInput";
import { getBusinessTypeFromString } from "helpers/business";
import { toastError } from "helpers/toast";
import { AsYouType } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessesCategories } from "store/actions/business/category/report";
import {
	clearValues,
	createBusiness,
	setValue
} from "store/actions/business/create";
import { setValue as setValueLimit } from "store/actions/business/limitClub";
import { createLimitClub } from "store/actions/business/limitClub";

import ModalLimit from "./ModalLimit";
import useStyles, { GridCheckboxHCM, LabelCheckboxHCM } from "./styles";

let debounceBusinessCreate: (() => void) & _.Cancelable = null;
let debounceCreateLimitClub: (() => void) & _.Cancelable = null;

export function BusinessCreate({ history, match }) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [zipCodeLoading, setZipCodeLoading] = useState(false);
	const [checkZipCode, setCheckZipCode] = useState(false);
	const [hasHCM, setHasHCM] = useState(false);
	const [opeModal, setOpenModal] = useState(false);
	const [openModalHCMImport, setOpenModalHCMImport] = useState(false);

	const handleClose = useCallback(() => {
		setOpenModalHCMImport(false);
	}, []);

	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);

	const {
		name,
		socialName,
		cnpj,
		phoneNumber,
		street,
		number,
		complement,
		neighborhood,
		city,
		state: stateOfCountry,
		zipCode,
		country,
		category,
		status,
		highlight,
		national,
		teaser,
		about,
		isStore,
		isCompany,
		isHeadquarters,
		isFranchise,
		errors,
		loading,
		hasWiipoRequest,
		hasWiipoFlex,
		hasWiipoClub,
		isHcmPartial
	} = useSelector<any, any>(s => s.businessCreate);

	const { loading: limitLoading, percentClubWiipo } = useSelector<any, any>(
		s => s.limitClubCreate
	);

	const disabledButton = useMemo(() => {
		if (loading || zipCodeLoading || limitLoading) {
			return true;
		}

		return false;
	}, [hasHCM]);

	useEffect(() => {
		dispatch(getBusinessesCategories());
	}, [dispatch]);

	useEffect(() => {
		dispatch(setValue(getBusinessTypeFromString(match.path)));
	}, [dispatch]);

	const handleChange = (id: string, value: string | number | boolean) => {
		if (id === "zipCode") setCheckZipCode(true);

		if (
			typeof value === "string" &&
			(id === "state" || id === "country") &&
			value
		) {
			value = value.toUpperCase();
		}

		if (id === "percentClubWiipo") {
			dispatch(
				setValueLimit({
					[id]: value
				})
			);
		} else {
			dispatch(
				setValue({
					[id]: value
				})
			);
		}

		if (id === "number") {
			const formattedValue = value.toString().replace(/[^n|sN|S0-9]/g, "");
			dispatch(
				setValue({
					[id]: formattedValue
				})
			);
		}
	};

	const handleClear = () => {
		dispatch(clearValues());
	};

	useEffect(() => {
		if (checkZipCode && zipCode?.length === 8) {
			setZipCodeLoading(true);
			fetch(`https://viacep.com.br/ws/${zipCode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json?.erro) {
						toastError("CEP não encontrado, por favor preencha manualmente!");
					} else if (json) {
						dispatch(
							setValue({
								street: json.logradouro,
								number: "",
								complement: json.complemento,
								neighborhood: json.bairro,
								city: json.localidade,
								state: json.uf,
								zipCode
							})
						);
					}
					setZipCodeLoading(false);
				})
				.catch(err => {
					toastError(err);
					setZipCodeLoading(false);
				});
		}
	}, [zipCode]);

	const historyPushText = useMemo(() => {
		if (isStore) return "Store";
		if (isCompany) return "Company";
		if (isHeadquarters) return "Headquarters";

		return "Franchise";
	}, []);

	const titlePage = useMemo(() => {
		if (isCompany) return ls.companyData;
		if (isStore) return ls.storeData;

		return ls.businessData;
	}, []);

	const isModalClube = useMemo(() => hasHCM && hasWiipoClub, []);

	const historyPush = useCallback((model: any) => {
		history.push(`/${historyPushText}/${model.id ? model.id : model}`);
	}, []);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>, test = false) => {
		e.preventDefault();
		if (debounceBusinessCreate) debounceBusinessCreate.cancel();
		if (debounceCreateLimitClub) debounceCreateLimitClub.cancel();

		debounceBusinessCreate = _.debounce(() => {
			dispatch(
				createBusiness((err, model) => {
					if (err) {
						console.log(err);
						if (err.default) toastError(err.default);
						else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
					}
					if (isModalClube) {
						debounceCreateLimitClub = _.debounce(() => {
							dispatch(
								createLimitClub(
									percentClubWiipo,
									model.id ? model.id : model,
									err => {
										if (err) {
											toastError(`${ls.ErrorLimitClube} ${err}`);
										} else {
											historyPush(model);
										}
									}
								)
							);
						}, 1000);

						debounceCreateLimitClub();
					} else {
						history.push(`/${historyPushText}/${model.id ? model.id : model}`);
					}
				})
			);
		}, 1000);

		if (hasHCM && hasWiipoClub && !test) {
			setOpenModal(true);
		} else {
			debounceBusinessCreate();
		}
	};

	const { items: categories } = useSelector<any, any>(
		s => s.businessCategoryReport
	);

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<Typography variant="h6">{titlePage}</Typography>
					<hr className={classes.line} />

					<form noValidate onSubmit={onSubmit}>
						<Grid container spacing={2} className={classes.form}>
							{isStore && (
								<Grid item xs={12} sm={12} md={4} lg={3}>
									<ImageInput
										singleImage
										onDelete={e => console.log(e)}
										withPreview
										hideOnPreview
										withIcon
										buttonText="Escolher foto"
										fileSizeError={ls.fileSizeIsTooBig}
										onChange={images => handleChange("image", images[0])}
										label="Escolha uma foto para aparecer no aplicativo. Tamanho máximo: 256kb. Resolução recomendada: 800px por 600px"
										imgExtension={[".jpg", ".png", ".jpeg", ".webp"]}
										maxFileSize={262144}
									/>
								</Grid>
							)}
							<Grid
								container
								spacing={2}
								item
								xs={12}
								sm={12}
								md={isStore ? 8 : 12}
								lg={isStore ? 9 : 12}
							>
								<Grid item xs={6}>
									<Typography className={classes.subTitle}>
										Tipo de cadastro
									</Typography>
									<Checkbox
										id="isStore"
										name="store"
										checked={isStore}
										onChange={handleChange}
									/>
									<Checkbox
										id="isCompany"
										name="company"
										checked={isCompany}
										onChange={handleChange}
									/>
									<Checkbox
										id="isHeadquarters"
										name="headquarters"
										checked={isHeadquarters}
										onChange={handleChange}
									/>
									<Checkbox
										id="isFranchise"
										name="franchise"
										checked={isFranchise}
										onChange={handleChange}
									/>
									<FormHelperText error={Boolean(errors.type)}>
										{errors.type}
									</FormHelperText>
								</Grid>
								<Grid item xs={6}>
									<Typography className={classes.subTitle}>
										Produtos contratados
									</Typography>
									<Checkbox
										id="hasWiipoClub"
										name="Clube Wiipo"
										checked={hasWiipoClub}
										onChange={handleChange}
									/>
									<Checkbox
										id="hasWiipoFlex"
										name="Wiipo Flex"
										checked={hasWiipoFlex}
										onChange={handleChange}
									/>
									<Checkbox
										id="hasWiipoRequest"
										name="Wiipo Request"
										checked={hasWiipoRequest}
										onChange={handleChange}
									/>
								</Grid>

								<Grid
									container
									item
									xs={12}
									sm={12}
									md={12}
									lg={12}
									className={classes.hcmGrid}
								>
									<Grid item xs={6}>
										{ls.hasHCMService}

										<Switch
											id="hasHCMIntegrator"
											name="hasHCMIntegrator"
											value={hasHCM}
											checked={hasHCM}
											onChange={(id, value) => {
												setHasHCM(value);
												handleChange(id, value);
											}}
											color="primary"
											className={classes.hcmSwitch}
										/>
									</Grid>
								</Grid>

								{hasHCM && (
									<GridCheckboxHCM
										container
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
									>
										<LabelCheckboxHCM>
											Selecione o tipo de sincronia:
										</LabelCheckboxHCM>
										<Checkbox
											id="isHcmPartial"
											name="HCMTotal"
											style={{ color: "#000000" }}
											checked={!isHcmPartial}
											onChange={id => {
												handleChange(id, false);
											}}
										/>
										<Checkbox
											id="isHcmPartial"
											name="HCMManual"
											checked={isHcmPartial}
											onChange={id => {
												handleChange(id, true);
											}}
										/>
									</GridCheckboxHCM>
								)}

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="name"
										required
										variant="outlined"
										name="businessName"
										value={name}
										inputProps={{ maxLength: 40 }}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="socialName"
										required
										variant="outlined"
										name="socialName"
										inputProps={{ maxLength: 40 }}
										value={socialName}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="cnpj"
										variant="outlined"
										name="cpfcnpj"
										value={cnpj}
										required
										InputProps={{
											inputComponent: CPFCNPJ
										}}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								{isStore && (
									<Grid item xs={12} sm={6} md={6} lg={4}>
										<Select
											errors={errors}
											id="category"
											name="category"
											value={category}
											required
											onChange={handleChange}
											options={[
												{ id: "", value: ls.noneSelectedText, disabled: true },
												...categories.map(c => ({
													id: c.id,
													value: c.name,
													data: c
												}))
											]}
										/>
									</Grid>
								)}

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="phoneNumber"
										required
										variant="outlined"
										name="phoneNumber"
										value={phoneNumber}
										errors={errors}
										onChange={(name, value) =>
											handleChange(
												name,
												new AsYouType().input(
													value[0] === "+" ? value : `+${value}`
												)
											)
										}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: 0, value: "Pendente" },
											{ id: 1, value: "Ativo" },
											{ id: 2, value: "Desativado temporariamente" },
											{ id: 3, value: "Desativado" }
										]}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4} />
								<Grid item xs={12} container alignItems="center" spacing={2}>
									<Grid item>
										<Typography variant="body1">Endereço:</Typography>
									</Grid>

									<Grid item>
										{isStore && (
											<Grid item xs={12} sm={6} md={4} lg={3}>
												<Checkbox
													id="national"
													name="national"
													checked={national}
													onChange={handleChange}
												/>
											</Grid>
										)}
									</Grid>
								</Grid>

								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="zipCode"
										variant="outlined"
										name="zipCode"
										required={!national}
										value={zipCode}
										errors={errors}
										InputProps={{
											inputComponent: CEP
										}}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} sm={9} md={8} lg={7}>
									<TextInput
										id="street"
										variant="outlined"
										inputProps={{ maxLength: 40 }}
										name="street"
										required
										value={street}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={3} md={3} lg={2}>
									<TextInput
										id="number"
										variant="outlined"
										name="number"
										required
										value={number}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									<TextInput
										id="complement"
										variant="outlined"
										name="complement"
										value={complement}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="neighborhood"
										variant="outlined"
										name="neighborhood"
										required
										value={neighborhood}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={5}>
									<TextInput
										id="city"
										variant="outlined"
										name="city"
										required
										value={city}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={4} md={3} lg={2}>
									<TextInput
										id="state"
										variant="outlined"
										name="state"
										inputProps={{
											maxLength: 2
										}}
										required
										value={stateOfCountry}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={4} md={3} lg={2}>
									<TextInput
										id="country"
										variant="outlined"
										name="country"
										required
										inputProps={{
											maxLength: 2
										}}
										value={country}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								{isStore && (
									<Grid item xs={12}>
										<TextInput
											id="teaser"
											variant="outlined"
											name="teaser"
											value={teaser}
											errors={errors}
											onChange={handleChange}
										/>
									</Grid>
								)}
								{isStore && (
									<Grid item xs={12}>
										<TextInput
											id="about"
											variant="outlined"
											name="about"
											multiline
											value={about}
											errors={errors}
											onChange={handleChange}
										/>
									</Grid>
								)}
								{isStore && (
									<Grid item xs={12} sm={6} md={4} lg={3}>
										<Checkbox
											id="highlight"
											name="highlight"
											checked={highlight}
											onChange={handleChange}
										/>
									</Grid>
								)}
							</Grid>
						</Grid>

						<hr className={classes.line} />
						<Grid container justify="flex-end">
							<Button
								variant="contained"
								color="default"
								className={classes.button}
								onClick={handleClear}
							>
								{ls.clear}
							</Button>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								type="submit"
								disabled={disabledButton}
							>
								{loading || zipCodeLoading || limitLoading ? (
									<Spinner color="secondary" size={16} />
								) : (
									ls.save
								)}
							</Button>
						</Grid>
					</form>

					<ModalLimit
						title="Por favor, defina qual a porcentagem de limite do Clube Wiipo para a empresa criada."
						handleClose={handleCloseModal}
						onSubmit={onSubmit}
						onClickNo={handleCloseModal}
						open={opeModal}
						inputs={
							<>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="percentClubWiipo"
										required
										variant="outlined"
										name="limitClubeWiipo"
										InputProps={{
											inputComponent: PERCENTAGE2
										}}
										value={percentClubWiipo}
										errors={null}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
							</>
						}
						childrenjs={
							<>
								{ls.formatString(
									"Esse limite é baseado na remuneração de cada colaborador"
								)}
							</>
						}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
}

export default BusinessCreate;
