import * as api from "services/api";
import { getErrors } from "helpers/error";
import { validateEmail } from "helpers/email";
import ls from "Localization";
import _ from "lodash";
import { Callback } from "Types/Callback";

export const GET_INTEGRATIONS = "[INTEGRATION] GET_INTEGRATIONS";
export const GET_INTEGRATIONS_SUCCESS =
	"[INTEGRATION] GET_INTEGRATIONS_SUCCESS";
export const GET_INTEGRATIONS_FAILED = "[INTEGRATION] GET_INTEGRATIONS_FAILED";

export const GET_BUSINESS_TINY = "[INTEGRATION] GET_BUSINESS_TINY";
export const GET_BUSINESS_TINY_SUCCESS =
	"[INTEGRATION] GET_BUSINESS_TINY_SUCCESS";
export const GET_BUSINESS_TINY_FAILED =
	"[INTEGRATION] GET_BUSINESS_TINY_FAILED";

export const GET_INTEGRATION = "[INTEGRATION] GET_INTEGRATION";
export const GET_INTEGRATION_SUCCESS = "[INTEGRATION] GET_INTEGRATION_SUCCESS";
export const GET_INTEGRATION_FAILED = "[INTEGRATION] GET_INTEGRATION_FAILED";

export const GET_INTEGRATION_FILE = "[INTEGRATION] GET_INTEGRATION_FILE";
export const GET_INTEGRATION_FILE_SUCCESS =
	"[INTEGRATION] GET_INTEGRATION_FILE_SUCCESS";
export const GET_INTEGRATION_FILE_FAILED =
	"[INTEGRATION] GET_INTEGRATION_FILE_FAILED";

export const CREATE_INTEGRATION = "[INTEGRATION] CREATE_INTEGRATION";
export const CREATE_INTEGRATION_SUCCESS =
	"[INTEGRATION] CREATE_INTEGRATION_SUCCESS";
export const CREATE_INTEGRATION_FAILED =
	"[INTEGRATION] CREATE_INTEGRATION_FAILED";

export const UPDATE_INTEGRATION = "[INTEGRATION] UPDATE_INTEGRATION";
export const UPDATE_INTEGRATION_SUCCESS =
	"[INTEGRATION] UPDATE_INTEGRATION_SUCCESS";
export const UPDATE_INTEGRATION_FAILED =
	"[INTEGRATION] UPDATE_INTEGRATION_FAILED";

export const SEND_INTEGRATION_MAIL = "[INTEGRATION] SEND_INTEGRATION_MAIL";
export const SEND_INTEGRATION_MAIL_SUCCESS =
	"[INTEGRATION] SEND_INTEGRATION_MAIL_SUCCESS";
export const SEND_INTEGRATION_MAIL_FAILED =
	"[INTEGRATION] SEND_INTEGRATION_MAIL_FAILED";

export const SET_VALUE = "[INTEGRATION] SET_VALUE";
export const CLEAR_VALUES = "[INTEGRATION] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getIntegrations(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_INTEGRATIONS });

		try {
			const { login, businessDetail } = getState();

			const response = await api.sendGet(
				`/Integration/Business/${businessDetail.item.id}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_INTEGRATIONS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_INTEGRATIONS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_INTEGRATIONS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getIntegration(id, include, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_INTEGRATION });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Integration/${id}?include=${include}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_INTEGRATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_INTEGRATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_INTEGRATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function downloadIntegrationFile(
	id,
	from,
	to,
	callback?: Callback<any>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_INTEGRATION_FILE });

		try {
			const { login } = getState();

			if (from.format) from = from.format();

			if (to.format) to = to.format();

			const response = await api.sendGet(
				`/Integration/${id}/File?from=${from}&to=${to}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_INTEGRATION_FILE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_INTEGRATION_FILE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_INTEGRATION_FILE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createIntegration(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_INTEGRATION });

		try {
			const { login, integrations, businessDetail } = getState();

			let { url, emailTo, type, format, provider, object, properties } =
				integrations;

			const errors: any = {};

			if (+type === 0 && !emailTo) errors.emailTo = ls.required;
			else if (emailTo) {
				let emails = emailTo.split(";");
				if (emails.find(c => !c || !validateEmail(c)))
					errors.emailTo = ls.invalid;
			}

			if (+type === 1 && [null, undefined, ""].includes(provider))
				errors.provider = ls.required;

			if (+type === 1 && !url) errors.url = ls.required;

			if (+type === 0) {
				for (const property of properties) {
					let i = properties.indexOf(property);
					if (!property.name) errors[`${i}name`] = ls.required;

					if (property.name === "custom" && !property.customValue)
						errors[`${i}customValue`] = ls.required;
				}
			} else {
				properties = [];
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_INTEGRATION_FAILED,
					payload: errors
				});
			}

			const model = {
				type,
				url,
				emailTo: emailTo
					?.split(";")
					.filter(c => c)
					.join(";"),
				format,
				object,
				provider,
				properties,
				companyId: businessDetail.item.id
			};

			const response = await api.sendPost("/Integration", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_INTEGRATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_INTEGRATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_INTEGRATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editIntegration(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_INTEGRATION });

		try {
			const { login, integrations } = getState();

			let { item, url, emailTo, type, format, provider, object, properties } =
				integrations;

			const errors: any = {};

			if (+type === 0 && !emailTo) errors.emailTo = ls.required;
			else if (emailTo) {
				let emails = emailTo.split(";");
				if (emails.find(c => !c || !validateEmail(c)))
					errors.emailTo = ls.invalid;
			}

			if (+type === 1 && !provider && +provider !== 0)
				errors.provider = ls.required;

			if (+type === 1 && !url) errors.url = ls.required;

			if (+type === 0) {
				for (const property of properties) {
					let i = properties.indexOf(property);
					if (!property.name) errors[`${i}name`] = ls.required;

					if (property.name === "custom" && !property.customValue)
						errors[`${i}customValue`] = ls.required;
				}
			} else {
				properties = [];
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_INTEGRATION_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				type,
				url,
				emailTo: emailTo
					?.split(";")
					.filter(c => c)
					.join(";"),
				format,
				provider,
				object,
				properties,
				company: undefined,
				user: undefined
			};

			const response = await api.sendPut(`/Integration/${item.id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_INTEGRATION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_INTEGRATION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_INTEGRATION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function sendIntegrationEmail(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: SEND_INTEGRATION_MAIL });

		try {
			const { login, integrations } = getState();

			let { emailTo, from, to, item } = integrations;

			const errors: any = {};

			if (!emailTo) errors.emailTo = ls.required;
			else {
				let emails = emailTo.split(";");
				if (emails.find(c => !c || !validateEmail(c)))
					errors.emailTo = ls.invalid;
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: SEND_INTEGRATION_MAIL_FAILED,
					payload: errors
				});
			}

			if (from.format) from = from.format();

			if (to.format) to = to.format();

			const response = await api.sendPost(
				`/Integration/${item.id}/Mail/${emailTo
					?.split(";")
					.filter(c => c)
					.join(";")}?from=${from}&to=${to}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: SEND_INTEGRATION_MAIL_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: SEND_INTEGRATION_MAIL_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: SEND_INTEGRATION_MAIL_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getStoresTiny(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS_TINY });

		try {
			const { login } = getState();

			const response = await api.sendGet("/Business/Store/Tiny", {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
