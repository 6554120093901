import produce from "immer";
import { Reducer } from "react";
import {
	ActionPayload,
	ActionTypes,
	IBalanceAccountState
} from "store/actions/balanceAccount/types";

const initialState: IBalanceAccountState = {
	item: null,
	types: [],
	pages: 0,
	loading: {},
	showBusinessesFormModal: false,
	showBusinessesTransferFormModal: false,
	businessesTiny: [],
	businesses: [],
	businessTransferPolicies: []
};

const reducer: Reducer<IBalanceAccountState, ActionPayload> = (
	state = initialState,
	{ type, payload }
) =>
	produce(state, draft => {
		switch (type) {
			case ActionTypes.UPDATE_BUSINESS_REQUEST: {
				draft.loading = { ...state.loading, updateBusinesses: true };
				break;
			}

			case ActionTypes.GET_BALANCE_ACCOUNT_TYPE_REQUEST: {
				draft.loading = { ...state.loading, get: true };
				break;
			}

			case ActionTypes.GET_BALANCE_ACCOUNT_TYPES_REQUEST: {
				draft.loading = { ...state.loading, getAll: true };
				break;
			}

			case ActionTypes.GET_BALANCE_ACCOUNT_TYPES_SUCCESS: {
				const { types = [], pages = 0 } = payload;
				draft = {
					...state,
					types,
					pages,
					loading: { ...state.loading, getAll: false }
				};
				break;
			}

			case ActionTypes.GET_BUSINESS_TINY_SUCCESS: {
				draft = {
					...state,
					businessesTiny: payload.businessesTiny,
					loading: { ...state, tiny: false }
				};

				break;
			}

			case ActionTypes.UPDATE_BUSINESS_SUCCESS: {
				draft = { ...state, loading: { ...state, updateBusinesses: false } };

				break;
			}

			case ActionTypes.GET_BALANCE_ACCOUNT_TYPE_SUCCESS: {
				draft = {
					...state,
					item: payload.item,
					businesses: payload.item.businesses ?? [],
					businessTransferPolicies: payload.item.businessTransferPolicies ?? [],
					loading: { ...state, get: false }
				};

				break;
			}

			case ActionTypes.UPDATE_BUSINESS_PERMISSION_REQUEST: {
				draft.loading = { ...state.loading, updateBusinessesPermission: true };
				break;
			}

			case ActionTypes.UPDATE_BUSINESS_PERMISSION_SUCCESS: {
				draft = {
					...state,
					loading: { ...state, updateBusinessesPermission: false }
				};

				break;
			}

			case ActionTypes.GET_BALANCE_ACCOUNT_TYPES_BUSINESS_REQUEST: {
				draft.loading = { ...state.loading, getAll: true };
				break;
			}

			case ActionTypes.GET_BALANCE_ACCOUNT_TYPES_BUSINESS_SUCCESS: {
				const { types = [] } = payload;
				draft = {
					...state,
					types,
					loading: { ...state.loading, getAll: true }
				};
				break;
			}

			case ActionTypes.SET_VALUE: {
				draft = {
					...state,
					...payload
				};

				break;
			}

			case ActionTypes.CLEAR_VALUES: {
				draft = {
					...initialState
				};

				break;
			}

			default: {
				draft.loading = {};
			}
		}
		return draft;
	});

export default reducer;
