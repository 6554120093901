import * as actions from "store/actions/deliveryAddress";

import { IDeliveryAddress } from "./types";

const initialState: IDeliveryAddress = {
	items: [],
	cities: [],
	neighborhoods: [],
	streets: [],
	citiesList: [],
	neighborhoodsList: [],
	streetsList: [],
	item: null,
	name: "",
	value: "0",
	minPrice: "0",
	freeShipping: false,
	time: "1",
	state: null,
	city: null,
	neighborhood: null,
	street: "",
	storeId: "",
	count: 0,
	pages: 0,
	push: 0,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_DELIVERY_ADDRESS: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_DELIVERY_ADDRESS_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.name = payload.name ?? initialState.name;
				newState.value =
					(payload.value / 100.0).toString() ?? initialState.value;
				newState.minPrice =
					(payload.minPrice / 100.0).toString() ?? initialState.minPrice;
				newState.freeShipping =
					payload.freeShipping ?? initialState.freeShipping;
				newState.time = payload.time ?? initialState.time;
				newState.streetsList =
					payload.streets?.map(c => ({
						...c,
						...(c.street || {})
					})) ?? initialState.streetsList;
				newState.neighborhoodsList =
					payload.neighborhoods?.map(c => ({
						...c,
						...(c.neighborhood || {}),
						neighborhood: null
					})) ?? initialState.neighborhoodsList;
				newState.citiesList =
					payload.cities?.map(c => ({ ...c, ...(c.city || {}), city: null })) ??
					initialState.citiesList;
			} else {
				newState.item = null;
				newState.name = initialState.name;
				newState.value = initialState.value;
				newState.minPrice = initialState.minPrice;
				newState.freeShipping = initialState.freeShipping;
				newState.time = initialState.time;
				newState.streetsList = initialState.streetsList;
				newState.neighborhoodsList = initialState.neighborhoodsList;
				newState.citiesList = initialState.citiesList;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}
		case actions.GET_DELIVERY_ADDRESS_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}
		case actions.GET_DELIVERY_ADDRESSES: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_DELIVERY_ADDRESSES_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_DELIVERY_ADDRESSES_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}
		case actions.CREATE_DELIVERY_ADDRESS: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_DELIVERY_ADDRESS_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				name: initialState.name
			};
		}
		case actions.CREATE_DELIVERY_ADDRESS_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.UPDATE_DELIVERY_ADDRESS: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_DELIVERY_ADDRESS_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				name: initialState.name
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
