import storage from "localforage";
import LogRocket from "logrocket";
import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

import { jwt } from "./middlewares/jwt";
import reducers from "./reducers";

const persistConfig = {
	key: "root",
	storage,
	blacklist: []
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers =
	typeof window === "object" && window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
		? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({})
		: compose;

const enhancer = composeEnhancers(
	applyMiddleware(
		jwt,
		thunk,
		LogRocket.reduxMiddleware({
			actionSanitizer: (action: any) => {
				if (action.payload) {
					if (action.payload.password) action.payload.password = null;
					if (action.payload.login) {
						if (action.payload.login.password)
							action.payload.login.password = null;
						// if (action.payload.login.token) action.payload.login.token = null;
					}
				}

				return action;
			},
			stateSanitizer: state => {
				state = { ...state };

				return state;
			}
		})
	)
);

export default () => {
	let store = createStore(persistedReducer, enhancer);
	let persistor = persistStore(store as any);
	return { store, persistor };
};
