import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_ALLOWANCES = "[ALLOWANCE] GET_ALLOWANCES";
export const GET_ALLOWANCES_SUCCESS = "[ALLOWANCE] GET_ALLOWANCES_SUCCESS";
export const GET_ALLOWANCES_FAILED = "[ALLOWANCE] GET_ALLOWANCES_FAILED";

export const GET_BUSINESS_TINY = "[ALLOWANCE] GET_BUSINESS_TINY";
export const GET_BUSINESS_TINY_SUCCESS =
	"[ALLOWANCE] GET_BUSINESS_TINY_SUCCESS";
export const GET_BUSINESS_TINY_FAILED = "[ALLOWANCE] GET_BUSINESS_TINY_FAILED";

export const GET_ALLOWANCE = "[ALLOWANCE] GET_ALLOWANCE";
export const GET_ALLOWANCE_SUCCESS = "[ALLOWANCE] GET_ALLOWANCE_SUCCESS";
export const GET_ALLOWANCE_FAILED = "[ALLOWANCE] GET_ALLOWANCE_FAILED";

export const CREATE_ALLOWANCE = "[ALLOWANCE] CREATE_ALLOWANCE";
export const CREATE_ALLOWANCE_SUCCESS = "[ALLOWANCE] CREATE_ALLOWANCE_SUCCESS";
export const CREATE_ALLOWANCE_FAILED = "[ALLOWANCE] CREATE_ALLOWANCE_FAILED";

export const UPDATE_ALLOWANCE = "[ALLOWANCE] UPDATE_ALLOWANCE";
export const UPDATE_ALLOWANCE_SUCCESS = "[ALLOWANCE] UPDATE_ALLOWANCE_SUCCESS";
export const UPDATE_ALLOWANCE_FAILED = "[ALLOWANCE] UPDATE_ALLOWANCE_FAILED";

export const UPDATE_ALLOWANCE_USERS = "[ALLOWANCE] UPDATE_ALLOWANCE_USERS";
export const UPDATE_ALLOWANCE_USERS_SUCCESS =
	"[ALLOWANCE] UPDATE_ALLOWANCE_USERS_SUCCESS";
export const UPDATE_ALLOWANCE_USERS_FAILED =
	"[ALLOWANCE] UPDATE_ALLOWANCE_USERS_FAILED";

export const UPDATE_ALLOWANCE_USER = "[ALLOWANCE] UPDATE_ALLOWANCE_USER";
export const UPDATE_ALLOWANCE_USER_SUCCESS =
	"[ALLOWANCE] UPDATE_ALLOWANCE_USER_SUCCESS";
export const UPDATE_ALLOWANCE_USER_FAILED =
	"[ALLOWANCE] UPDATE_ALLOWANCE_USER_FAILED";

export const UPDATE_ALLOWANCE_CATEGORIES =
	"[ALLOWANCE] UPDATE_ALLOWANCE_CATEGORIES";
export const UPDATE_ALLOWANCE_CATEGORIES_SUCCESS =
	"[ALLOWANCE] UPDATE_ALLOWANCE_CATEGORIES_SUCCESS";
export const UPDATE_ALLOWANCE_CATEGORIES_FAILED =
	"[ALLOWANCE] UPDATE_ALLOWANCE_CATEGORIES_FAILED";

export const UPDATE_ALLOWANCE_STORES = "[ALLOWANCE] UPDATE_ALLOWANCE_STORES";
export const UPDATE_ALLOWANCE_STORES_SUCCESS =
	"[ALLOWANCE] UPDATE_ALLOWANCE_STORES_SUCCESS";
export const UPDATE_ALLOWANCE_STORES_FAILED =
	"[ALLOWANCE] UPDATE_ALLOWANCE_STORES_FAILED";

export const UPDATE_ALLOWANCE_DAYS = "[ALLOWANCE] UPDATE_ALLOWANCE_DAYS";
export const UPDATE_ALLOWANCE_DAYS_SUCCESS =
	"[ALLOWANCE] UPDATE_ALLOWANCE_DAYS_SUCCESS";
export const UPDATE_ALLOWANCE_DAYS_FAILED =
	"[ALLOWANCE] UPDATE_ALLOWANCE_DAYS_FAILED";

export const SET_VALUE = "[ALLOWANCE] SET_VALUE";
export const CLEAR_VALUES = "[ALLOWANCE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getAllowances(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALLOWANCES });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Allowance?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_ALLOWANCES_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALLOWANCES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ALLOWANCES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getAllowance(id, include, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ALLOWANCE });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Allowance/${id}?include=${include}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ALLOWANCE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ALLOWANCE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ALLOWANCE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createAllowance(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_ALLOWANCE });

		try {
			const { login, allowance, businessDetail } = getState();

			const {
				name,
				baseValue,
				limit,
				maxLimit,
				limitType,
				renewalCycle,
				startDate,
				endDate
			} = allowance;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!limit) errors.limit = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_ALLOWANCE_FAILED,
					payload: errors
				});
			}

			const model = {
				name,
				baseValue: Math.round((baseValue ?? 0) * 100),
				limit: Math.round(limit * 100),
				maxLimit: Math.round(maxLimit * 100),
				limitType,
				renewalCycle,
				startDate,
				endDate,
				companyId: businessDetail.item.id
			};

			const response = await api.sendPost("/Allowance", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_ALLOWANCE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_ALLOWANCE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_ALLOWANCE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAllowance(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ALLOWANCE });

		try {
			const { login, allowance } = getState();

			const {
				item,
				name,
				baseValue,
				limit,
				maxLimit,
				limitType,
				renewalCycle,
				startDate,
				endDate,
				status
			} = allowance;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!limit) errors.limite = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_ALLOWANCE_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				name,
				baseValue: Math.round((baseValue ?? 0) * 100),
				limit: Math.round(limit * 100),
				maxLimit: Math.round(maxLimit * 100),
				limitType,
				renewalCycle,
				startDate,
				endDate,
				status,
				users: undefined,
				company: undefined,
				createdBy: undefined,
				updatedBy: undefined,
				categories: undefined,
				days: undefined,
				historic: undefined,
				stores: undefined
			};

			const response = await api.sendPut(`/Allowance/${item.id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ALLOWANCE_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ALLOWANCE_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_ALLOWANCE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAllowanceDays(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ALLOWANCE_DAYS });

		try {
			const { login, allowance } = getState();

			const { item, days } = allowance;

			const response = await api.sendPut(`/Allowance/${item.id}/Days`, days, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ALLOWANCE_DAYS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ALLOWANCE_DAYS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_ALLOWANCE_DAYS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAllowanceUsers(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ALLOWANCE_USERS });

		try {
			const { login, allowance } = getState();

			const { item } = allowance;

			const response = await api.sendPut(`/Allowance/${item.id}/Users`, data, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ALLOWANCE_USERS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ALLOWANCE_USERS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_ALLOWANCE_USERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAllowanceUser(allowanceId, status, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ALLOWANCE_USER });

		try {
			const { login } = getState();

			const response = await api.sendPut(
				`/Allowance/User/${allowanceId}/Status/${status}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ALLOWANCE_USER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ALLOWANCE_USER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_ALLOWANCE_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAllowanceCategories(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ALLOWANCE_CATEGORIES });

		try {
			const { login, allowance } = getState();

			const { item } = allowance;

			const response = await api.sendPut(
				`/Allowance/${item.id}/Categories`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ALLOWANCE_CATEGORIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ALLOWANCE_CATEGORIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_ALLOWANCE_CATEGORIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAllowanceStores(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ALLOWANCE_STORES });

		try {
			const { login, allowance } = getState();

			const { item } = allowance;

			const response = await api.sendPut(`/Allowance/${item.id}/Stores`, data, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ALLOWANCE_STORES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ALLOWANCE_STORES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			console.log(error);

			return dispatch({
				type: UPDATE_ALLOWANCE_STORES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getStoresTiny(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS_TINY });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Business/Store/Tiny", {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
