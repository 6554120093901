import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import Spinner from "Components/Spinner";
import dayjs from "dayjs";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import CashbackStatus from "models/cashbackStatus";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEdit, MdStore } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	deleteCashback,
	getAllCashback,
	getCashback,
	setValue
} from "store/actions/cashbackPolicy";
import { CashbackPolicyState } from "store/reducers/cashbackPolicy";

import CreateForm from "../Forms/Add";
import CompaniesForm from "../Forms/Companies";
import EditForm from "../Forms/Edit";
import Stores from "../Forms/Stores";
import styles from "./styles";

let getCashbackPoliciesDebounced: (() => void) & _.Cancelable = null;

function CashbackPolicy({ business }) {
	const dispatch = useDispatch();

	const table = useRef(null);

	const [loadingSub, setLoadingSub] = useState({});
	const [alertModal, setAlertModal] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	const state = useSelector<any, CashbackPolicyState>(c => c.cashbackPolicy);

	const loadData = useCallback(() => {
		dispatch(
			getAllCashback(
				null,
				null,
				`&filters[businessId]=${business.id}`,
				null,
				null,
				null,
				null
			)
		);
		setIsLoaded(true);
	}, [business.id, dispatch]);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(id, item) => {
			setLoadingSub({ [id]: item.id });
			dispatch(
				getCashback(item.id, (err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						handleChange(id, Boolean(model));
					}
					setLoadingSub({ [id]: false });
				})
			);
		},
		[dispatch, handleChange]
	);

	const handleDelete = useCallback(
		(id: string) => {
			dispatch(
				deleteCashback(id, err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						setAlertModal(null);
					}
				})
			);
		},
		[dispatch]
	);

	const showConfirm = useCallback(
		itemId => {
			setAlertModal(
				<Dialog
					open
					onClose={() => setAlertModal(null)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Tem certeza que deseja confirmar a exclusão?
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Após a confirmação da exclusão não será possível recuperar os
							dados.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => handleDelete(itemId)}
							color="primary"
							autoFocus
						>
							Confirmar
						</Button>
						<Button onClick={() => setAlertModal(null)} color="primary">
							Cancelar
						</Button>
					</DialogActions>
				</Dialog>
			);
		},
		[handleDelete]
	);

	const handleFetchData = useCallback(
		(tableState, _instance) => {
			if (!isLoaded) {
				return;
			}
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getCashbackPoliciesDebounced) {
				getCashbackPoliciesDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let startAt = filtered.find(c => c.id === "startAt");
			let endAt = filtered.find(c => c.id === "endAt");

			if (startAt?.value) {
				filtered = filtered.filter(c => c.id !== "startAt");
				let date = null;

				if (startAt?.value.startDate || startAt?.value.endDate) {
					date = {
						startAt: startAt.value.startDate,
						endAt: startAt.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "startAt",
						value: JSON.stringify(date)
					});
				}
			}

			if (endAt?.value) {
				filtered = filtered.filter(c => c.id !== "endAt");
				let date = null;

				if (endAt?.value.startDate || endAt?.value.endDate) {
					date = {
						startAt: endAt.value.startDate,
						endAt: endAt.value.endDate
					};
				}

				if (date) {
					filtered.push({
						id: "endAt",
						value: JSON.stringify(date)
					});
				}
			}

			getCashbackPoliciesDebounced = _.debounce(
				() =>
					dispatch(
						getAllCashback(
							page * pageSize,
							pageSize,
							`&filters[businessId]=${business.id}${filtered.reduce(
								(p, c) => `${p}&filters[${c.id}]=${c.value}`,
								""
							)}`,
							sorted[0]?.id,
							sorted[0]?.desc,
							toExport,
							callback
						)
					),
				500
			);

			getCashbackPoliciesDebounced();
		},
		[business.id, dispatch, isLoaded]
	);

	const columns = useMemo(
		() => [
			{
				Header: ls.value,
				id: "value",
				show: true,
				format: c => c.value / 100,
				accessor: c => toStringCurrency(c.value / 100)
			},
			{
				Header: ls.minValue,
				id: "minValue",
				show: true,
				format: c => c.minValue / 100,
				accessor: c => toStringCurrency(c.minValue / 100),
				width: 120
			},
			{
				Header: ls.maxValue,
				id: "maxValue",
				show: true,
				format: c => c.maxValue / 100,
				accessor: c => toStringCurrency(c.maxValue / 100),
				width: 120
			},
			{
				Header: ls.limit,
				id: "limit",
				show: true,
				format: c => c.limit / 100,
				accessor: c => toStringCurrency(c.limit / 100),
				width: 120
			},
			{
				Header: ls.startAt,
				id: "startAt",
				accessor: c =>
					c.startAt
						? dayjs(c.startAt).format(ls.dateFormatWithoutSeconds)
						: ls.notInformed,
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.endAt,
				id: "endAt",
				accessor: c =>
					c.endAt
						? dayjs(c.endAt).format(ls.dateFormatWithoutSeconds)
						: ls.notInformed,
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.status,
				id: "status",
				accessor: c => ls[CashbackStatus[c.status]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter?.value ?? "all"}
					>
						<option value="">Todos</option>
						{Object.keys(CashbackStatus).map(c => (
							<option key={c} value={c}>
								{ls[CashbackStatus[c]]}
							</option>
						))}
					</select>
				),
				width: 200
			},
			{
				Header: ls.actions,
				id: "actions",
				filterable: false,
				accessor: c => (
					<Grid container spacing={2} justify="center">
						<Grid item>
							{loadingSub["showCompaniesFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showCompaniesFormModal", c)}
								>
									<MdStore />
								</IconButton>
							)}
							{loadingSub["showEditFormModal"] === c.id ? (
								<Spinner color="secondary" size={16} />
							) : (
								<IconButton
									style={{
										padding: 8,
										fontSize: "1.3rem"
									}}
									onClick={() => handleEdit("showEditFormModal", c)}
								>
									<MdEdit />
								</IconButton>
							)}
							<IconButton
								style={{
									padding: 8,
									fontSize: "1.3rem"
								}}
								onClick={() => showConfirm(c.id)}
							>
								<MdDelete />
							</IconButton>
						</Grid>
					</Grid>
				),
				width: 200
			}
		],
		[handleEdit, loadingSub, showConfirm]
	);

	const load = useCallback(
		() => handleFetchData(table.current?.state, null),
		[handleFetchData]
	);

	return (
		<LoadOnScroll load={loadData}>
			<Report
				manual
				tableRef={table}
				title={ls.cashbackPolicy}
				data={state.items}
				pages={state.pages}
				loading={state.loading.getAll}
				onFetchData={handleFetchData}
				filterable
				showExport
				headerRightComponent={
					<div>
						<IconButton
							color="primary"
							onClick={() => {
								dispatch(clearValues());
								handleChange("showFormModal", true);
							}}
						>
							<FaPlus />
						</IconButton>
					</div>
				}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				columns={columns}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			{alertModal}
			<CreateForm load={load} configBusiness={business?.config} />
			<EditForm load={load} />
			<CompaniesForm />
			<Stores />
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(CashbackPolicy);
