import { Grid, Paper, Typography } from "@material-ui/core";
import history from "AppHistory";
import { exportExcelGenerate } from "Components/Report/export";
import Spinner from "Components/Spinner";
import Tooltip from "Components/Tooltip";
import Form from "Components/views/Dashboard/WiipoFlex/RequestCards/Form";
import { removeDiacritics } from "helpers/string";
import { toastError } from "helpers/toast";
import { CNPJValidation } from "helpers/validators";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { CgTemplate } from "react-icons/cg";
import { FaFileInvoice } from "react-icons/fa";
import { MdLastPage } from "react-icons/md";
import { SiGooglesheets } from "react-icons/si";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getBusinesses } from "store/actions/business/report";
import { setValue } from "store/actions/requestCards/create";
import { State } from "store/reducers";
import { IInitialState, requestItems } from "store/reducers/requestCards/types";
import XLSX from "xlsx";

import {
	BackgroundHeader,
	BackgroundIcon,
	Body,
	ButtonRequest,
	Footer,
	Header,
	RowIcons,
	Title
} from "./styles";

interface iModel {
	quantityCard: number;
	street: string;
	document: string;
	zipcode: string;
	name: string;
	number: number;
	neighborhood: string;
	complement: string;
	city: string;
	state: string;
	phone: string;
	email: string;
	service: string;
}

let getBusinessDebounced: (() => void) & _.Cancelable = null;

const Add: React.FC = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);
	const { request } = useSelector<State, IInitialState>(s => s.requestCreate);
	const { items } = useSelector<State, IInitialState>(s => s.requestReport);
	const location = useLocation();
	const inputFile = useRef<HTMLInputElement>(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const backToReport = useCallback(() => {
		history.push(location.pathname.replace("/Add", ""));
	}, [location.pathname]);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const lastRequest = useCallback(() => {
		const lastItem: requestItems = {
			businessId: items[0].businessId,
			quantityCard: items[0].quantityCard,
			street: items[0].street,
			zipcode: items[0].zipcode,
			name: items[0].name,
			state: items[0].state,
			service: items[0].service,
			phone: items[0].phone,
			city: items[0].city,
			complement: items[0].complement,
			document: items[0].document,
			email: items[0].email,
			number: items[0].number,
			neighborhood: items[0].neighborhood
		};

		handleChange("request", lastItem);
		handleChange("cnpj", items[0].business?.cnpj);
	}, [handleChange, items]);

	const handleFileSelected = e => {
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const sheetName = wb.SheetNames[0];

			const sheet = wb.Sheets[sheetName];

			let headers = [];
			let range = XLSX.utils.decode_range(sheet["!ref"]);
			let C;
			let R = range.s.r;
			for (C = range.s.c; C <= range.e.c; ++C) {
				let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

				if (cell && cell.t === "s") {
					cell.v = removeDiacritics(cell.v.trim().toLowerCase());
					if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());

					headers.push(removeDiacritics(cell.v.trim().toLowerCase()));
				}
			}

			const data = XLSX.utils.sheet_to_json(sheet);
			const filterData = data.map(i => {
				const model: iModel = {
					quantityCard: i["quantidade de cartoes"],
					name: i["destinatario"],
					document: i["cpf ou cnpj do destinatario"],
					zipcode: i["cep"],
					street: i["logradouro"],
					number: i["numero"],
					complement: i["complemento"],
					city: i["cidade"],
					state: i["estado"],
					phone: i["telefone"],
					email: i["email"],
					neighborhood: i["bairro"],
					service: i["tipo de servico"]
				};

				handleChange("cnpj", i["cnpj da empresa"]);

				return model;
			}, []);
			handleChange("request", filterData[0]);
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	const {
		businessId,
		city,
		document,
		email,
		name,
		neighborhood,
		number,
		phone,
		quantityCard,
		state,
		street,
		zipcode
	} = request;

	const isLoading = useCallback((boolean: boolean) => {
		setLoading(boolean);
	}, []);

	const emailValidation = useMemo(() => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		return emailRegex.test(email);
	}, [email]);

	const isDisableButton = useMemo(() => {
		if (
			!businessId ||
			!city ||
			!document ||
			!email ||
			!name ||
			!neighborhood ||
			(number !== 0 && !number) ||
			!phone ||
			!quantityCard ||
			!state ||
			!street ||
			!zipcode ||
			loading
		) {
			return true;
		}

		return false;
	}, [
		businessId,
		city,
		document,
		email,
		loading,
		name,
		neighborhood,
		number,
		phone,
		quantityCard,
		state,
		street,
		zipcode
	]);

	const editValue = useCallback(
		(index, id, value) => {
			const newRequest = request;
			if (id === "cnpj") {
				handleChange("cnpj", value);
			} else {
				if (id === "phone") {
					if (value.length > 11) {
						return;
					}

					newRequest[id] = value;
				} else if (id === "zipcode") {
					if (value.length > 8) {
						return;
					}
					if (value.length === 8) {
						searchZipCode(value);
					}
					newRequest[id] = value;
				} else if (id === "number") {
					newRequest[id] = value || 0;
				} else {
					newRequest[id] = value;
				}

				handleChange("request", newRequest);
			}
		},
		[handleChange, request]
	);

	const searchZipCode = useCallback(
		zipcode => {
			fetch(`https://viacep.com.br/ws/${zipcode}/json/`)
				.then(res => res.json())
				.then(json => {
					if (json?.erro) {
						toastError("CEP não encontrado, por favor preencha manualmente!");
					} else if (json) {
						editValue(1, "street", json.logradouro);
						editValue(1, "complement", json.complemento);
						editValue(1, "neighborhood", json.bairro);
						editValue(1, "city", json.localidade);
						editValue(1, "state", json.uf);
					}
					isLoading(false);
				})
				.catch(err => {
					toastError(err);
					isLoading(false);
				});
		},
		[editValue, isLoading]
	);

	const onLoadCNPJ = useCallback(
		(index: number, cnpj: string) => {
			if (getBusinessDebounced) {
				getBusinessDebounced.cancel();
			}

			getBusinessDebounced = _.debounce(
				() =>
					dispatch(
						getBusinesses(
							null,
							null,
							`&filters[cnpj]=${cnpj}`,
							null,
							null,
							null,
							(err, models) => {
								if (err) {
									toastError(JSON.stringify(err));
								} else if (models.length <= 0) {
									toastError("CNPJ não encontrado");
								} else if (!models[0]?.treasuryAccountHolderId) {
									toastError(
										"Não será possivel continuar empresa sem treasury!"
									);
								} else {
									editValue(index, "businessId", models[0].id);
								}
							}
						)
					),
				500
			);

			if (CNPJValidation(cnpj)) {
				getBusinessDebounced();
			}
		},
		[dispatch, editValue]
	);

	const goCheck = useCallback(() => {
		if (emailValidation) {
			history.push("/RequestCards/Add/Detail");
		} else {
			toastError("E-mail inválido, por favor corrija e tente novamente");
		}
	}, [emailValidation]);

	const templateJson = useMemo(
		() => [
			{
				"Quantidade de cartões": 100,
				Logradouro: "Rua Alameda Slim",
				CEP: "89235010",
				Destinatário: "Bruno e Marrone",
				Estado: "SC",
				"Tipo de serviço": "WII001_PLUS",
				Telefone: "47999999999",
				Cidade: "São Paulo",
				Complemento: "Casa",
				"CPF ou CNPJ do destinatário": "218.881.930-69",
				Email: "brunoemarrone@gmail.com",
				Numero: 0,
				Bairro: "Centro",
				"CNPJ da empresa": "28.308.418/0001-80"
			}
		],
		[]
	);

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper>
					<Header>
						<Typography variant="h5">{ls.requestNewCards}</Typography>
						<RowIcons>
							<BackgroundIcon type="button" onClick={backToReport}>
								<Tooltip title="Minhas solicitações">
									<FaFileInvoice color="#5734D9" size={22} />
								</Tooltip>
							</BackgroundIcon>
							<BackgroundIcon type="button" onClick={lastRequest}>
								<Tooltip title="Refazer último pedido">
									<MdLastPage color="#5734D9" size={22} />
								</Tooltip>
							</BackgroundIcon>
							<BackgroundIcon type="button" onClick={openSelectFile}>
								<Tooltip title="Importar pedido">
									<SiGooglesheets color="#5734D9" size={22} />
								</Tooltip>
							</BackgroundIcon>
							<BackgroundIcon
								type="button"
								onClick={() =>
									exportExcelGenerate(
										templateJson,
										"Exemplo Pedido",
										"Template_Pedido"
									)
								}
							>
								<Tooltip title="Template da importação do pedido">
									<CgTemplate color="#5734D9" size={24} />
								</Tooltip>
							</BackgroundIcon>
						</RowIcons>
					</Header>

					{request && (
						<Body>
							<div>
								<BackgroundHeader>
									<Title>Pedido #1 </Title>
								</BackgroundHeader>

								<Form
									item={request}
									index={1}
									onChange={editValue}
									onLoadCNPJ={onLoadCNPJ}
									isLoading={isLoading}
								/>
							</div>
						</Body>
					)}

					<Footer>
						<ButtonRequest
							disabled={isDisableButton}
							isDisabled={isDisableButton}
							type="button"
							onClick={goCheck}
						>
							{loading ? <Spinner color="secondary" size={16} /> : "Solicitar"}
						</ButtonRequest>
					</Footer>
				</Paper>
			</Grid>
			<input
				ref={inputFile}
				style={{ display: "none" }}
				type="file"
				accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				onChange={handleFileSelected}
			/>
		</Grid>
	);
};

export default Add;
