import { Grid, Paper, Typography } from "@material-ui/core";
import styled from "styled-components";

interface IProps {
	padding?: string;
	bold?: boolean;
}

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin-top: 20px;
	margin-bottom: 20px;
	height: 100%;

	@media (max-width: 1000px) {
		flex-direction: column;
		margin: 10px;
	}

	@media (max-width: 600px) {
		flex-direction: column;
		margin-bottom: 100px;
	}
`;

export const CardsContainer = styled(Paper)`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	margin-right: 24px;

	@media (max-width: 1000px) {
		flex-direction: column;
		margin: 10px;

		width: 90%;
	}

	@media (max-width: 600px) {
		width: 100%;
	}

	&:first-child {
		width: 45%;
		img {
			background-color: #5734d9;
			padding: 10px;
			border-radius: 0.5rem;
			position: relative;
			top: -20px;
		}

		strong {
			font-size: 30px;
			margin: 0 20px;
		}

		@media (max-width: 1000px) {
			flex-direction: column;
			margin: 10px;

			width: 90%;
		}

		@media (max-width: 600px) {
			width: 100%;

			strong {
				font-size: 16px;
				margin: 0 20px;
			}
		}
	}

	hr {
		width: 100%;
		padding: 0;
	}

	Button {
		height: 30px;
		width: 150px;
	}
`;

export const TextFirstCard = styled.div`
	padding-left: px;
	font-size: 16px;
	line-height: 32px;
	margin: 10px 0;
`;

export const TypographyContainer = styled(Typography)`
	font-size: 23px;
	font-weight: bold;
	color: #5734d9;
	padding-left: 20px;
	padding-top: 10px;
`;

export const GridCardsContainer = styled(Grid)<IProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0 20px;
	align-items: center;
`;

export const GridContainer = styled(Grid)<IProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${props => (props.padding ? props.padding : "0 10px")};

	&:first-child {
		margin: 10px 0px;
	}

	@media (max-width: 600px) {
		flex-direction: column;
		width: 90%;
	}

	strong {
		color: #707070;
		font-weight: ${props => (props.bold ? "bold" : "normal")};
		font-size: 14px;
		margin-right: 5px;
	}

	Button {
		height: 30px;
		width: 150px;
	}
`;

export const ButtonCover = styled.div`
	display: flex;
	justify-content: right;
	margin: 10px;

	Button {
		height: 30px;
		width: 120px;
		margin-right: 20px;
		margin-bottom: 15px;
	}
`;

export const TextContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	padding: 10px;
`;
