import dayjs from "dayjs";
import { ActionTypes } from "store/actions/treasuryAccountWiipoFlex/types";

import { ICreate } from "./types";

const initialState: ICreate = {
	value: 0,
	dueDate: dayjs(),
	cnpj: "",
	description: "",
	fundsPrePaidBalanceType: [
		{
			prePaidBalanceAccountTypeId: "",
			value: 0
		}
	],
	numberContract: 0,
	numberOrder: 0,
	errors: {},
	loading: false
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_REQUEST: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_SUCCESS: {
			return {
				...initialState
			};
		}
		case ActionTypes.CREATE_FUNDS_IN_WIIPOFLEX_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case ActionTypes.SET_VALUE:
			return {
				...state,
				...payload
			};
		case ActionTypes.CLEAR_VALUES:
			return {
				...initialState,
				creationDate: dayjs()
			};
		default: {
			return state;
		}
	}
};

export default reducer;
