import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

interface IPropsStyled {
	isPadding?: boolean;
}

export const StyledTextField = styled(TextField)<IPropsStyled>`
	${props =>
		props.isPadding
			? ""
			: `.MuiFilledInput-inputMarginDense {
		padding: 0;
	}
	.MuiFilledInput-input {
		padding: 0;
	}`}
`;
