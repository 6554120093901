import { makeStyles } from "@material-ui/core/styles";
import { cardTitle, grayColor } from "Components/Card/styles/base";

const useStyles = makeStyles(theme => ({
	cardTitle: {
		...cardTitle,
		marginTop: 0,
		marginBottom: 3
	},
	cardCategory: {
		color: grayColor[0],
		fontSize: 14,
		paddingTop: 10,
		marginBottom: 0,
		marginTop: 0,
		margin: 0
	},
	cardFooter: {
		display: "flex",
		flex: 1,
		alignItems: "center",
		justifyContent: "flex-end"
	},
	stats: {
		color: grayColor[0],
		fontSize: "12px",
		lineHeight: "22px",
		display: "inline-flex",
		"& svg": {
			position: "relative",
			top: "4px",
			width: "16px",
			height: "16px",
			marginRight: "3px"
		},
		"& .fab,& .fas,& .far,& .fal,& .material-icons": {
			position: "relative",
			top: "4px",
			fontSize: "16px",
			marginRight: "3px"
		}
	}
}));

export default useStyles;
