import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import SelectMD, {
	SelectProps as SelectPropsMD
} from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";

import ls from "../../Localization";

const useStyles = makeStyles(theme => ({
	formControl: {
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
}));

interface SelectProps extends Omit<SelectPropsMD, "onChange" | "variant"> {
	id: string;
	name?: string;
	value: string;
	hideLabel?: boolean;
	variant?: "outlined";
	options: any[];
	onChange(id: string, value: any): void;
	errors?: any;
	leftIcons?: JSX.Element[];
}

function Select({
	id,
	name,
	errors,
	onChange,
	value,
	options,
	variant = "outlined",
	hideLabel,
	leftIcons,
	required,
	...rest
}: SelectProps) {
	const classes = useStyles({});

	const inputLabel = useRef(null);
	const [labelWidth, setLabelWidth] = useState(0);

	useEffect(() => {
		setLabelWidth(inputLabel.current?.offsetWidth);
	}, [inputLabel]);

	let error = errors ? errors[id] : undefined;

	return (
		<FormControl variant={variant} fullWidth className={classes.formControl}>
			{name && (
				<InputLabel ref={inputLabel} required={required}>
					{hideLabel ? "" : ls[name]}
				</InputLabel>
			)}
			<SelectMD
				labelId="demo-simple-select-outlined-label"
				id={id}
				value={value}
				onChange={e => onChange(id, e.target.value)}
				labelWidth={labelWidth}
				{...rest}
			>
				{options.map(({ id, value, ...rest }) => (
					<MenuItem key={id} value={id} {...rest}>
						{leftIcons?.length && leftIcons[id]}
						{value}
					</MenuItem>
				))}
			</SelectMD>
			<FormHelperText>{error}</FormHelperText>
		</FormControl>
	);
}

export default Select;
