import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const IMPORT_LIMITS = "[LIMIT IMPORT] IMPORT_LIMITS";
export const IMPORT_LIMITS_SUCCESS = "[LIMIT IMPORT] IMPORT_LIMITS_SUCCESS";
export const IMPORT_LIMITS_FAILED = "[LIMIT IMPORT] IMPORT_LIMITS_FAILED";

export const SET_VALUE = "[LIMIT REPORT] SET_VALUE";
export const CLEAR_VALUES = "[LIMIT REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function importLimits(data?: any[], callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_LIMITS });

		try {
			const { login } = getState();

			let response = await api.sendPost("/User/Limit/Import", data, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_LIMITS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_LIMITS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_LIMITS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
