import dayjs from "dayjs";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

export const EDIT_CREDIT = "[CREDIT EDIT] EDIT_CREDIT";
export const EDIT_CREDIT_SUCCESS = "[CREDIT EDIT] EDIT_CREDIT_SUCCESS";
export const EDIT_CREDIT_FAILED = "[CREDIT EDIT] EDIT_CREDIT_FAILED";

export const SET_VALUE = "[CREDIT EDIT] SET_VALUE";
export const CLEAR_VALUES = "[CREDIT EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function editCredit(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_CREDIT });

		try {
			const { login, creditEdit } = getState();

			const {
				item,
				value,
				expirationDate,
				status,
				origin,
				minValue,
				startDate,
				description
			} = creditEdit;

			const errors: any = {};

			dispatch(setValue({ errors }));

			if (!value) errors.value = ls.required;
			else if (value < 0) errors.value = ls.invalid;

			if (_.keys(errors).length > 0) {
				dispatch({
					type: EDIT_CREDIT_FAILED,
					payload: errors
				});
				return;
			}

			const model = {
				...item,
				value: Math.round(value * 100),
				minValue: Math.round(minValue * 100),
				startDate: startDate ? dayjs(startDate).startOf("day") : undefined,
				expirationDate: expirationDate
					? dayjs(expirationDate).endOf("day")
					: undefined,
				status,
				origin,
				description,
				categories: undefined,
				historic: undefined,
				company: undefined,
				user: undefined,
				uses: undefined,
				transaction: undefined
			};

			const response = await api.sendPut(`/Credit/${item.id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: EDIT_CREDIT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_CREDIT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_CREDIT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
