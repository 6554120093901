import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 32px;
`;

export const Title = styled.span`
	text-align: left;
	font: normal normal 600 24px Exo;
	letter-spacing: 0px;
	color: #1e1e1e;
	opacity: 1;
`;

export const Button = styled.button`
	display: flex;
	flex-direction: row;
	background: none;
	border-color: #5734da;
	align-items: center;
	border-radius: 9px;
	width: 173px;
	height: 51px;
`;

export const TextButton = styled.p`
	color: #5734da;
	font-size: 17px;
	margin: 0;
	padding: 0;
`;

export const RowWidth = styled.div`
	width: 100%;
	height: 1px;
	background: #e5e5e5;
`;

export const BackgroundCheckBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 16px;
	p {
		font: normal normal medium 19px/25px Exo;
		letter-spacing: 0px;
		color: #484848;
		opacity: 1;
	}
	input {
		border-radius: 6px;
		border-color: #707070;
		width: 20px;
		height: 20px;
		border-width: 10px;
	}
`;

export const RowFooter = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 0;
	width: 100%;
	justify-content: space-between;
`;
