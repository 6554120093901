import * as actions from "store/actions/costCenter";
import ICostCenter from "Types/CostCenter";

export interface CostCenterState {
	items: ICostCenter[];
	item: ICostCenter;
	name: string;
	users: [];
	count: number;
	pages: number;
	push: number;
	referenceCode: string;
	showFormModal: boolean;
	showEditFormModal: boolean;
	showImportFormModal: boolean;
	showUserFormModal: boolean;
	errors: {};
	loading: {
		getAll?: boolean;
		get?: boolean;
		create?: boolean;
		update?: boolean;
		delete?: boolean;
		import?: boolean;
		users?: boolean;
	};
}

const initialState: CostCenterState = {
	items: [],
	item: null,
	referenceCode: "",
	name: "",
	users: [],
	count: 0,
	pages: 0,
	push: 0,
	showFormModal: false,
	showEditFormModal: false,
	showImportFormModal: false,
	showUserFormModal: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_COST_CENTERS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}

		case actions.GET_COST_CENTERS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}

		case actions.GET_COST_CENTERS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.GET_COST_CENTER: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}

		case actions.GET_COST_CENTER_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.referenceCode =
					payload.referenceCode ?? initialState.referenceCode;
				newState.name = payload.name ?? initialState.name;
				newState.users = payload.users?.length
					? payload.users
					: initialState.users;
			} else {
				newState.item = null;
				newState.referenceCode = initialState.referenceCode;
				newState.name = initialState.name;
				newState.users = initialState.users;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}

		case actions.GET_COST_CENTER_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.CREATE_COST_CENTER: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}

		case actions.CREATE_COST_CENTER_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				referenceCode: initialState.referenceCode,
				name: initialState.name,
				users: initialState.users
			};
		}

		case actions.CREATE_COST_CENTER_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}

		case actions.IMPORT_COST_CENTER: {
			return {
				...state,
				loading: { import: true },
				errors: initialState.errors
			};
		}

		case actions.IMPORT_COST_CENTER_SUCCESS: {
			return {
				...state,
				loading: { import: false }
			};
		}

		case actions.IMPORT_COST_CENTER_FAILED: {
			return {
				...state,
				loading: { import: false },
				errors: payload
			};
		}

		case actions.UPDATE_COST_CENTER: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_COST_CENTER_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				referenceCode: initialState.referenceCode,
				name: initialState.name,
				users: initialState.users
			};
		}

		case actions.UPDATE_COST_CENTER_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}

		case actions.UPDATE_COST_CENTER_USERS: {
			return {
				...state,
				loading: { users: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_COST_CENTER_USERS_SUCCESS: {
			return {
				...state,
				loading: { users: false }
			};
		}

		case actions.UPDATE_COST_CENTER_USERS_FAILED: {
			return {
				...state,
				loading: { users: false },
				errors: payload
			};
		}

		case actions.DELETE_COST_CENTER: {
			return {
				...state,
				loading: { delete: true },
				errors: initialState.errors
			};
		}

		case actions.DELETE_COST_CENTER_SUCCESS: {
			let { items } = state;

			items = items.filter(c => c.id !== payload);

			return {
				...state,
				items: [...items],
				loading: { delete: false }
			};
		}

		case actions.DELETE_COST_CENTER_FAILED: {
			return {
				...state,
				loading: { delete: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				referenceCode: initialState.referenceCode,
				name: initialState.name,
				users: initialState.users
			};
		default: {
			return state;
		}
	}
};

export default reducer;
