import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import ls from "Localization";
import _ from "lodash";
import orderSaleStatus from "models/orderSaleStatus";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearValues, editOrder, setValue } from "store/actions/order/edit";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 500,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceEditOrder: (() => void) & _.Cancelable = null;

function EditOrder({ open, item, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (open && item) {
			dispatch(clearValues());

			dispatch(
				setValue({
					item,
					status: item.sales[0].status
				})
			);
		}
	}, [dispatch, open, item]);

	const { status, loading, errors } = useSelector<any, any>(s => s.orderEdit);

	if (!item) return null;

	const handleChange = (name, value) => {
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditOrder) debounceEditOrder.cancel();

		debounceEditOrder = _.debounce(() => {
			dispatch(
				editOrder(err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						handleClose();
					}
				})
			);
		}, 1000);

		debounceEditOrder();
	};

	return (
		<Modal
			aria-labelledby="edit-order-modal-title"
			aria-describedby="edit-order-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{`${ls.editOrder} ${item.code}`}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											...Object.keys(orderSaleStatus).map(c => ({
												id: c,
												value: ls[orderSaleStatus[c]]
											}))
										]}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.edit}
								>
									{ls.save}
									{loading.edit && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditOrder;
