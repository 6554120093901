import { Fab } from "@material-ui/core";
import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import { stringSort } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import marketingStatus from "models/marketingStatus";
import queryString from "query-string";
import React, { useEffect, useMemo, useRef } from "react";
import { MdAdd, MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { clearValues, getMarketings } from "store/actions/marketing";
import { Helmet } from "react-helmet";

import useStyles from "./styles";

let getMarketingsDebounced: (() => void) & _.Cancelable = null;

function RenderMarketingsReport({ history, location }) {
	const dispatch = useDispatch();
	const classes = useStyles({});

	const query = queryString.parse(location.search);

	const table = useRef(null);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch]);

	const { items, pages, loading } = useSelector<any, any>(s => s.marketing);

	const columns = useMemo(
		() => [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 150,
				show: true,
				sortMethod: (a, b) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.name,
				accessor: "name",
				sortMethod: stringSort
			},
			{
				Header: ls.startAt,
				id: "startAt",
				accessor: c => dayjs(c.startAt).format(ls.dateFormatWithoutSeconds),
				width: 150,
				show: true,
				sortMethod: (a, b) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				ignoreMaxDate: true,
				Filter: DateSelectFilter
			},
			{
				Header: ls.status,
				id: "status",
				accessor: c => ls[marketingStatus[c.status]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter?.value ?? query?.status ?? "all"}
					>
						<option value="">Todos</option>
						{Object.keys(marketingStatus).map(c => (
							<option key={c} value={c}>
								{ls[marketingStatus[c]]}
							</option>
						))}
					</select>
				),
				width: 200
			},
			{
				Header: ls.actions,
				id: "actions",
				accessor: c => (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flex: 1
						}}
					>
						<Link to={`/marketing/${c.id}/Edit`}>
							<MdEdit />
						</Link>
					</div>
				),
				filterable: false,
				width: 80
			}
		],
		[query.status]
	);

	const handleFetchData = tableState => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getMarketingsDebounced) {
			getMarketingsDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");
		let startAt = filtered.find(c => c.id === "startAt");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");
			let date = null;

			if (creationDate?.value.startDate || creationDate?.value.endDate) {
				date = {
					startAt: creationDate.value.startDate,
					endAt: creationDate.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "creationDate",
					value: JSON.stringify(date)
				});
			}
		}

		if (startAt?.value) {
			filtered = filtered.filter(c => c.id !== "startAt");
			let date = null;

			if (startAt?.value.startDate || startAt?.value.endDate) {
				date = {
					startAt: startAt.value.startDate,
					endAt: startAt.value.endDate
				};
			}

			if (date) {
				filtered.push({
					id: "startAt",
					value: JSON.stringify(date)
				});
			}
		}

		getMarketingsDebounced = _.debounce(
			() =>
				dispatch(
					getMarketings(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					)
				),
			500
		);

		getMarketingsDebounced();
	};

	useEffect(() => {
		if (table.current) {
			handleFetchData(table.current.state);
		}
	}, [dispatch]);

	return (
		<div>
			<Helmet>
				<title>
					{ls.marketing} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				title={ls.marketing}
				data={items}
				tableRef={table}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				loading={loading.getAll}
				columns={columns}
				visibleColumns={columns.map(c => c.Header)}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				onRowClicked={row => history.push(`/Marketing/${row.id}`)}
				defaultSorted={[
					{
						id: "creationDate",
						desc: true
					}
				]}
			/>
			<Fab
				color="primary"
				aria-label="Add"
				className={classes.fab}
				onClick={() => history.push("/Marketing/Create")}
			>
				<MdAdd size={24} />
			</Fab>
		</div>
	);
}

export default RenderMarketingsReport;
