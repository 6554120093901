import * as actions from "store/actions/user/calcLimit";

import { IRefreshSwap } from "../types/refreshSwap";

const initialState: IRefreshSwap = {
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CALC_LIMIT: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.CALC_LIMIT_SUCCESS: {
			return {
				...state,
				loading: false,
				items: payload
			};
		}
		case actions.CALC_LIMIT_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
