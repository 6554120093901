import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.background.paper,
		maxWidth: 370,
		maxHeight: 199,
		padding: theme.spacing(8, 4),
		borderRadius: 20
	},
	footer: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: theme.spacing(2)
	},
	button: {
		width: 144
	}
}));

export default useStyles;
