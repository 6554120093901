import * as actions from "store/actions/marketing";

import { IMarketingState } from "./types";

const initialState: IMarketingState = {
	items: [],
	item: null,
	name: "",
	users: [],
	usersTiny: [],
	startAt: null,
	economicMode: true,
	emailSubject: "",
	emailMessage: "",
	smsMessage: "",
	whatsAppMessage: "",
	whatsAppFile: "",
	whatsAppRestrict: true,
	pushAction: 0,
	pushValue: "",
	pushTitle: "",
	pushMessage: "",
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_MARKETING: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}
		case actions.GET_MARKETING_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.name = payload.name ?? initialState.name;
				newState.startAt = payload.startAt ?? initialState.startAt;
				newState.economicMode =
					payload.economicMode ?? initialState.economicMode;
				newState.emailSubject =
					payload.emailSubject ?? initialState.emailSubject;
				newState.emailMessage =
					payload.emailMessage ?? initialState.emailMessage;
				newState.smsMessage = payload.smsMessage ?? initialState.smsMessage;
				newState.whatsAppMessage =
					payload.whatsAppMessage ?? initialState.whatsAppMessage;
				newState.whatsAppFile =
					payload.whatsAppFile ?? initialState.whatsAppFile;
				newState.whatsAppRestrict =
					payload.whatsAppRestrict ?? initialState.whatsAppRestrict;
				newState.pushMessage = payload.pushMessage ?? initialState.pushMessage;
				newState.pushTitle = payload.pushTitle ?? initialState.pushTitle;
				newState.pushAction = payload.pushAction ?? initialState.pushAction;
				newState.pushValue = payload.pushValue ?? initialState.pushValue;
			} else {
				newState.item = null;
				newState.name = initialState.name;
				newState.startAt = initialState.startAt;
				newState.economicMode = initialState.economicMode;
				newState.emailSubject = initialState.emailSubject;
				newState.emailMessage = initialState.emailMessage;
				newState.smsMessage = initialState.smsMessage;
				newState.whatsAppMessage = initialState.whatsAppMessage;
				newState.whatsAppFile = initialState.whatsAppFile;
				newState.whatsAppRestrict = initialState.whatsAppRestrict;
				newState.pushMessage = initialState.pushMessage;
				newState.pushTitle = initialState.pushTitle;
				newState.pushAction = initialState.pushAction;
				newState.pushValue = initialState.pushValue;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}
		case actions.GET_MARKETING_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}
		case actions.GET_MARKETINGS: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}
		case actions.GET_MARKETINGS_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}
		case actions.GET_MARKETINGS_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}
		case actions.GET_MARKETING_USERS: {
			return {
				...state,
				loading: { getUsers: true },
				errors: initialState.errors
			};
		}
		case actions.GET_MARKETING_USERS_SUCCESS: {
			return {
				...state,
				users: payload || [],
				loading: { getUsers: false }
			};
		}
		case actions.GET_MARKETING_USERS_FAILED: {
			return {
				...state,
				loading: { getUsers: false },
				errors: payload
			};
		}
		case actions.GET_MARKETING_USERS_TINY: {
			return {
				...state,
				loading: { getUsersTiny: true },
				errors: initialState.errors
			};
		}
		case actions.GET_MARKETING_USERS_TINY_SUCCESS: {
			return {
				...state,
				usersTiny: payload || [],
				loading: { getUsersTiny: false }
			};
		}
		case actions.GET_MARKETING_USERS_TINY_FAILED: {
			return {
				...state,
				loading: { getUsersTiny: false },
				errors: payload
			};
		}
		case actions.CREATE_MARKETING: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_MARKETING_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				name: initialState.name,
				users: initialState.users,
				startAt: initialState.startAt,
				economicMode: initialState.economicMode,
				emailSubject: initialState.emailSubject,
				emailMessage: initialState.emailMessage,
				smsMessage: initialState.smsMessage,
				whatsAppMessage: initialState.whatsAppMessage,
				whatsAppFile: initialState.whatsAppFile,
				whatsAppRestrict: initialState.whatsAppRestrict,
				pushTitle: initialState.pushTitle,
				pushMessage: initialState.pushMessage,
				pushAction: initialState.pushAction,
				pushValue: initialState.pushValue
			};
		}
		case actions.CREATE_MARKETING_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.UPDATE_MARKETING: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}
		case actions.UPDATE_MARKETING_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				name: initialState.name,
				users: initialState.users,
				startAt: initialState.startAt,
				economicMode: initialState.economicMode,
				emailSubject: initialState.emailSubject,
				emailMessage: initialState.emailMessage,
				smsMessage: initialState.smsMessage,
				whatsAppMessage: initialState.whatsAppMessage,
				whatsAppFile: initialState.whatsAppFile,
				whatsAppRestrict: initialState.whatsAppRestrict,
				pushTitle: initialState.pushTitle,
				pushMessage: initialState.pushMessage,
				pushAction: initialState.pushAction,
				pushValue: initialState.pushValue
			};
		}
		case actions.UPDATE_MARKETING_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}
		case actions.RESEND_MARKETING: {
			return {
				...state,
				loading: { resend: true },
				errors: initialState.errors
			};
		}
		case actions.RESEND_MARKETING_SUCCESS: {
			return {
				...state,
				loading: { resend: false }
			};
		}
		case actions.RESEND_MARKETING_FAILED: {
			return {
				...state,
				loading: { resend: false },
				errors: payload
			};
		}
		case actions.SET_VALUE: {
			return {
				...state,
				...payload
			};
		}
		case actions.CLEAR_VALUES: {
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				name: initialState.name,
				users: initialState.users,
				startAt: initialState.startAt,
				economicMode: initialState.economicMode,
				emailSubject: initialState.emailSubject,
				emailMessage: initialState.emailMessage,
				smsMessage: initialState.smsMessage,
				whatsAppMessage: initialState.whatsAppMessage,
				whatsAppFile: initialState.whatsAppFile,
				whatsAppRestrict: initialState.whatsAppRestrict,
				pushTitle: initialState.pushTitle,
				pushMessage: initialState.pushMessage,
				pushAction: initialState.pushAction,
				pushValue: initialState.pushValue
			};
		}
		default: {
			return state;
		}
	}
};

export default reducer;
