import { makeStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
	fab: {
		margin: theme.spacing(),
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	},
	headerRightContainer: {
		height: "100%"
	}
}));

export default useStyles;
