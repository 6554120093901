import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
	Typography,
	Grid,
	Fade,
	Backdrop,
	Modal,
	Button
} from "@material-ui/core";

import AutoComplete2 from "Components/AutoComplete2";

import { useSelector, useDispatch } from "react-redux";

import ls from "Localization";
import TextInput from "Components/TextInput";
import Spinner from "Components/Spinner";
import Select from "Components/Select";
import {
	setValue,
	createAccession,
	clearValues
} from "store/actions/accession";
import { getUsers } from "store/actions/user/report";

import _ from "lodash";
import accessionStatus from "models/accessionStatus";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceFindClient: (() => void) & _.Cancelable = null;

function CreateForm() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { showFormModal, email, status, errors, loading } = useSelector<
		any,
		any
	>(c => c.accession);
	const { loading: userLoading } = useSelector<any, any>(s => s.userReport);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		dispatch(
			createAccession(err => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					dispatch(clearValues());
					handleClose();
				}
			})
		);
	};

	const findClient = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindClient) debounceFindClient.cancel();

			debounceFindClient = _.debounce(() => {
				dispatch(
					getUsers(
						0,
						10,
						`&filters[fullName]=${name}`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => ({
										id: c.id,
										label: `${c.fullName}`
									}))
								);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindClient();
		}
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={showFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.createAccession}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<AutoComplete2
										id="userId"
										name="client"
										errors={errors}
										onChange={handleChange}
										loadOptions={findClient}
										loading={userLoading}
										filterOptions={(options, state) => {
											return options;
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="email"
										required
										variant="outlined"
										name="email"
										value={email}
										errors={errors}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											...Object.keys(accessionStatus).map(c => ({
												id: c,
												value: ls[accessionStatus[c]]
											}))
										]}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default CreateForm;
