import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import * as React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DraggableListProps } from "Types/DraggableListType";

import {
	ContainerDraggableList,
	ContainerItem,
	NumberDraggableList
} from "./styles";

const DraggableList = ({ items, onDragEnd }: DraggableListProps) => (
	<DragDropContext onDragEnd={onDragEnd}>
		<Droppable droppableId="droppable-list">
			{provided => (
				<div {...provided.droppableProps} ref={provided.innerRef}>
					{items.map((item, index) => (
						<Draggable
							key={`${item.name}_${index}`}
							draggableId={`${item.name}_${index}`}
							index={index}
						>
							{(provided, snapshot) => (
								<ContainerItem>
									<NumberDraggableList>{index}</NumberDraggableList>
									<ContainerDraggableList
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
									>
										<ListItem
											style={
												snapshot.isDragging
													? { background: "#fff", width: "100%" }
													: {}
											}
										>
											<ListItemText
												primary={item.name}
												secondary={item.secondary}
											/>
										</ListItem>
									</ContainerDraggableList>
								</ContainerItem>
							)}
						</Draggable>
					))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	</DragDropContext>
);

export default DraggableList;
