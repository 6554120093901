import {
	Backdrop,
	Button,
	Fade,
	Grid,
	IconButton,
	Modal,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { toastError, toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import integrationFormat from "models/integrationFormat";
import integrationObject from "models/integrationObject";
import integrationProvider from "models/integrationProvider";
import integrationType from "models/integrationType";
import payrollDebit from "models/payrollDebit";
import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getBusinessesCategories } from "store/actions/business/category/report";
import {
	clearValues,
	createIntegration,
	setValue
} from "store/actions/integrations";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceCreateForm: (() => void) & _.Cancelable = null;

function CreateForm() {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [showCategory, setShowCategory] = useState(false);
	const {
		showFormModal,
		properties,
		url,
		emailTo,
		object,
		type,
		format,
		provider,
		errors,
		loading
	} = useSelector<any, any>(c => c.integrations);

	const category = useSelector<any, any>(c => c.businessCategoryReport);
	const [arrayCat, setArrayCat] = useState([]);
	const [arrayCancel, setArrayCancel] = useState([]);
	const [indices, setIndices] = useState(0);

	useEffect(() => {
		if (showFormModal) {
			dispatch(clearValues());
			dispatch(getBusinessesCategories());
		}
	}, [dispatch, showFormModal]);

	const handleChange = (id: string, value: any) => {
		const values = { [id]: value };

		if (id === "type") {
			values.format = value;
		}

		dispatch(setValue(values));
	};

	const handleChangeProperty = (id: string, value: any, index) => {
		properties[index][id] = value;
		dispatch(setValue({ properties }));
	};

	const handleClose = () => handleChange("showFormModal", false);
	const handleCloseCategory = () => setShowCategory(false);

	function getCategory(i1) {
		const newArrayCategories = category.items.map(c => ({
			key: c.id,
			name: c.name,
			value: ""
		}));

		if (properties[i1].values) {
			newArrayCategories.forEach((it, i2) => {
				properties[i1].values.forEach((item, i3) => {
					if (it.key === item.key) {
						newArrayCategories[i2] = Object.assign(newArrayCategories[i2], {
							value: properties[i1].values[i3].value
						});
					}
				});
			});
		}

		setArrayCat(newArrayCategories.filter(i => i.name !== "Admin"));
		setArrayCancel(arrayCat);
	}

	function handleCancelCategory(i) {
		handleChangeProperty("values", arrayCancel, i);
		setArrayCat(arrayCancel);
		handleCloseCategory();
	}
	function addValue(id: string, value: string, index: number, i: number) {
		if (index >= 0) {
			arrayCat[index] = Object.assign(arrayCat[index], {
				value
			});

			handleChangeProperty("values", arrayCat, i);
		}
	}

	const onSubmit = e => {
		e.preventDefault();

		if (debounceCreateForm) debounceCreateForm.cancel();

		debounceCreateForm = _.debounce(() => {
			dispatch(
				createIntegration((err, model) => {
					if (err) {
						if (err.default) toastError(err.default);
						else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceCreateForm();
	};

	const onValidator = i => {
		const array = arrayCat.filter(item => item.value === "");

		if (array.length <= 0) {
			handleChangeProperty("values", arrayCat, i);
			handleCloseCategory();
		} else {
			toastWarning("Por favor Preencha todos os campos ");
		}
	};

	const addProperty = () => {
		handleChange("properties", [
			...properties,
			{
				name: "",
				size: 6,
				decimal: 2,
				customValue: "",
				values: []
			}
		]);
	};

	const handleRemoveProperty = index => {
		handleChange(
			"properties",
			properties.filter(c => c !== properties[index])
		);
	};

	// eslint-disable-next-line new-cap
	let propertiesClass = new payrollDebit();

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={showFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">Criar nova integração</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="type"
										name="type"
										value={type}
										onChange={handleChange}
										options={Object.keys(integrationType).map(c => ({
											id: c,
											value: ls[integrationType[c]]
										}))}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="format"
										name="format"
										value={format}
										onChange={handleChange}
										options={Object.keys(integrationFormat).map(c => ({
											id: c,
											value: ls[integrationFormat[c]],
											disabled: type !== c
										}))}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="object"
										name="object"
										value={object}
										onChange={handleChange}
										options={Object.keys(integrationObject).map(c => ({
											id: c,
											value: ls[integrationObject[c]]
										}))}
									/>
								</Grid>
								{+type === 1 ? (
									<>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<Select
												errors={errors}
												id="provider"
												name="provider"
												value={provider}
												onChange={handleChange}
												options={Object.keys(integrationProvider)
													.map(c => ({
														id: c,
														value: ls[integrationProvider[c]]
													}))
													.sort((a, b) => a.value.localeCompare(b.value))}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={8}>
											<TextInput
												id="url"
												required
												variant="outlined"
												name="url"
												value={url}
												errors={errors}
												onChange={handleChange}
											/>
										</Grid>
									</>
								) : (
									<Grid item xs={12}>
										<TextInput
											id="emailTo"
											required
											variant="outlined"
											name="emailTo"
											value={emailTo}
											errors={errors}
											onChange={handleChange}
										/>
									</Grid>
								)}

								{+type === 0 && (
									<>
										<Grid item xs={12}>
											<div
												style={{
													display: "flex",
													justifyContent: "space-between",
													alignItems: "center"
												}}
											>
												<Typography>Propriedades</Typography>
												<Button onClick={addProperty}>{ls.add}</Button>
											</div>
										</Grid>
										<Grid item xs={12}>
											<Table>
												<TableBody>
													{[...properties].map((c, i) => (
														<TableRow key={i}>
															<TableCell style={{ width: 200 }}>
																<Select
																	errors={{ name: errors[`${i}name`] }}
																	id="name"
																	name="name"
																	value={c.name}
																	onChange={(id, value) =>
																		handleChangeProperty(id, value, i)
																	}
																	options={Object.keys(propertiesClass).map(
																		c => ({
																			id: c,
																			value: ls[c]
																		})
																	)}
																/>
															</TableCell>
															<TableCell style={{ width: 120 }}>
																<TextInput
																	id="size"
																	required
																	variant="outlined"
																	name="size"
																	InputProps={{
																		inputProps: {
																			step: 1,
																			min: 1,
																			max: 30
																		}
																	}}
																	type="number"
																	value={c.size}
																	errors={{ size: errors[`${i}size`] }}
																	onFocus={e => e.target.select()}
																	onChange={(id, value) =>
																		handleChangeProperty(id, value, i)
																	}
																/>
															</TableCell>

															{c.name &&
															typeof propertiesClass[c.name] === "number" ? (
																<TableCell>
																	<TextInput
																		id="decimal"
																		required
																		variant="outlined"
																		name="decimal"
																		InputProps={{
																			inputProps: {
																				step: 1,
																				min: 0,
																				max: 30
																			}
																		}}
																		type="number"
																		value={c.decimal}
																		errors={{
																			decimal: errors[`${i}decimal`]
																		}}
																		onChange={(id, value) =>
																			handleChangeProperty(id, value, i)
																		}
																	/>
																</TableCell>
															) : null}

															{c.name === "custom" ? (
																<TableCell>
																	<TextInput
																		id="customValue"
																		required
																		variant="outlined"
																		name="customValue"
																		value={c.customValue}
																		errors={{
																			customValue: errors[`${i}customValue`]
																		}}
																		onChange={(id, value) =>
																			handleChangeProperty(id, value, i)
																		}
																	/>
																</TableCell>
															) : null}

															{c.name === "category" ? (
																<TableCell>
																	<Button
																		onClick={() => {
																			getCategory(i);
																			setIndices(i);
																			setShowCategory(true);
																		}}
																	>
																		Selecionar
																	</Button>
																</TableCell>
															) : null}

															<Modal
																open={showCategory}
																onClose={handleCloseCategory}
																closeAfterTransition
																BackdropComponent={Backdrop}
																BackdropProps={{
																	timeout: 500
																}}
																aria-labelledby="modal-modal-title"
																aria-describedby="modal-modal-description"
																className={classes.modal}
															>
																<Fade in={showCategory}>
																	<div className={classes.paper}>
																		<Typography variant="h6">
																			Categorias
																		</Typography>
																		<hr />
																		<div className={classes.body}>
																			<form noValidate onSubmit={onSubmit}>
																				<Grid container spacing={2}>
																					<Grid item xs={12}>
																						<Table>
																							<TableHead>
																								<TableRow key={i}>
																									<TableCell>Nome</TableCell>
																									<TableCell>Valor</TableCell>
																								</TableRow>
																							</TableHead>
																							<TableBody>
																								{arrayCat.map(
																									(category, index) => (
																										<TableRow key={index}>
																											<TableCell
																												style={{
																													padding: 6,
																													width: 200
																												}}
																											>
																												{category.name}
																											</TableCell>
																											<TableCell
																												style={{
																													padding: 6,
																													width: 116
																												}}
																											>
																												<TextInput
																													id="values"
																													required
																													variant="standard"
																													name="Valor"
																													type="text"
																													value={
																														arrayCat[index]
																															.value
																													}
																													onChange={(
																														id,
																														value
																													) =>
																														addValue(
																															id,
																															value,
																															index,
																															indices
																														)
																													}
																												/>
																											</TableCell>
																										</TableRow>
																									)
																								)}
																							</TableBody>
																						</Table>
																					</Grid>
																				</Grid>

																				<div className={classes.footer}>
																					<Button
																						variant="contained"
																						onClick={() => {
																							handleCancelCategory(indices);
																						}}
																					>
																						{ls.cancel}
																					</Button>
																					<Button
																						color="primary"
																						variant="contained"
																						disabled={loading.create}
																						onClick={() => {
																							onValidator(indices);
																						}}
																					>
																						{ls.save}
																						{loading.create && (
																							<Spinner
																								color="secondary"
																								size={16}
																							/>
																						)}
																					</Button>
																				</div>
																			</form>
																		</div>
																	</div>
																</Fade>
															</Modal>

															<TableCell style={{ width: 72 }}>
																{i > 0 ? (
																	<IconButton
																		onClick={() => handleRemoveProperty(i)}
																	>
																		<MdClose />
																	</IconButton>
																) : null}
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</Grid>
									</>
								)}
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default CreateForm;
