import styled from "styled-components";

interface PropsButton {
	isButtonPurple?: boolean;
	isDisable?: boolean;
}

export const Container = styled.div`
	padding: 16px;
`;

export const Title = styled.h1`
	margin-bottom: 30px;
	text-align: left;
	font: normal normal normal 24px Exo;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
`;

export const Line = styled.div`
	background-color: #707070;
	height: 1px;
	width: 100%;
	margin-bottom: 20px;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
`;

export const Label = styled.p`
	text-align: left;
	font-size: 1rem;
	font-family: "Exo", sans-serif;
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0px;
	color: #353535;
	opacity: 1;
	margin-right: 15px;
`;

export const RowCodCli = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 40px;
	height: 60px;
`;

export const BackgroundInput = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 15px;
`;

export const RowButtons = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: flex-end;
`;

export const Button = styled.button<PropsButton>`
	width: 176px;
	height: 49px;
	border: 1px solid #5734da;
	${props => (props.isDisable ? "border: none;" : "")}
	border-radius: 8px;
	opacity: 1;
	background: ${props => {
			if (props.isDisable) return "#a7a7a7";
			return props.isButtonPurple ? "#5734DA" : "#fff";
		}}
		0% 0% no-repeat padding-box;
	margin-right: 10px;
	cursor: ${props => (!props.isDisable ? "pointer" : "none")};
`;

export const TextButton = styled.p<PropsButton>`
	font: normal normal bold 19px/25px Exo;
	letter-spacing: 0px;
	color: ${props => (props.isButtonPurple ? "#ffffff" : "#5734DA")};
	opacity: 1;
	margin: 0;
	padding: 0;
`;
