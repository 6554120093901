import { Button, TextareaAutosize } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import icon from "assets/circle-plus-solid.svg";
import ModalGeneric from "Components/Modals/ModalGeneric";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import TooltipLight from "Components/TooltipLight";
import dayjs, { Dayjs } from "dayjs";
import { decimalValue } from "helpers/decimalsNumber";
import { toStringCurrency } from "helpers/string";
import { toastError, toastSuccess, toastWarning } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	getBalanceAccountTypeForBusiness
} from "store/actions/balanceAccount/report";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";
import { createTransaction } from "store/actions/treasuryAccountWiipoFlex/create";

import {
	BackgroundButton,
	BodyModal,
	ButtonAdd,
	GridRow,
	GridsBody,
	Label,
	LabelTitle,
	Row,
	RowTitle,
	TitleText
} from "./styles";

interface IPropsModal {
	open: boolean;
	id: string;
	idCNPJ: string;
	handleClose: () => void;
	handleChange: (
		id: string,
		value: string | number | boolean | Dayjs | any
	) => void;
}

let debouncedCreateDeposit: (() => void) & _.Cancelable = null;

const ModalCreate: React.FC<IPropsModal> = ({
	open,
	handleClose,
	id,
	idCNPJ,
	handleChange
}) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(clearValues());
		dispatch(getBalanceAccountTypeForBusiness(idCNPJ, true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		value,
		cnpj,
		dueDate,
		numberOrder,
		numberContract,
		fundsPrePaidBalanceType,
		errors,
		loading
	} = useSelector<any, any>(c => c.treasuryAccountWiipoFlexCreate);

	const { types } = useSelector<any, IBalanceAccountState>(
		state => state.balanceAccount
	);

	const typesUsed = useMemo(
		() =>
			types
				?.filter(i => i.businesses?.filter(b => b.status === 1))
				.map(i => ({
					value: i.name,
					id: i.businesses[0]?.prePaidBalanceAccountTypeId
				})),
		[types]
	);

	const sumValues = fundsPrePaidBalanceType
		.map(i => i.value)
		.reduce((a, b) => a + b, 0);

	const valueMap = value - sumValues / 100 > 0 ? value - sumValues / 100 : 0;

	const listBalanceFilter = fundsPrePaidBalanceType.filter(
		i => !i.prePaidBalanceAccountTypeId
	);

	const listBalanceValueFilter = fundsPrePaidBalanceType.filter(
		i => i.value === 0
	);

	const addListBalance = useCallback(() => {
		if (listBalanceValueFilter.length > 0) {
			toastError(
				"Há bolsos com o valor 0 por favor antes de adicionar outro use um valor nele "
			);
		} else if (listBalanceFilter.length > 0) {
			toastError("Não há bolso selecionado");
		} else if (sumValues / 100 >= value) {
			toastWarning(
				"Valores dos bolsos maior ou igual ao o valor do déposito avulso"
			);
		} else if (fundsPrePaidBalanceType.length < typesUsed.length) {
			handleChange("fundsPrePaidBalanceType", [
				...fundsPrePaidBalanceType,
				{ prePaidBalanceAccountTypeId: "", value: 0 }
			]);
		} else {
			toastError("Limite de bolsos ativos atingidos");
		}
	}, [
		listBalanceValueFilter.length,
		listBalanceFilter.length,
		sumValues,
		value,
		fundsPrePaidBalanceType,
		typesUsed.length,
		handleChange
	]);

	const removeListBalance = useCallback(
		(id: string) => {
			const newListBalance = [...fundsPrePaidBalanceType];

			const filterNewBalance = newListBalance.filter(
				i => i.prePaidBalanceAccountTypeId !== id
			);

			handleChange("fundsPrePaidBalanceType", filterNewBalance);
		},
		[fundsPrePaidBalanceType, handleChange]
	);

	const editListBalance = useCallback(
		(id, values, index) => {
			const newListBalance = [...fundsPrePaidBalanceType];

			const objectValue = newListBalance.find((i, ind) => ind === index);

			if (id === "value") {
				objectValue.value = decimalValue(values);
			} else {
				const findItemIsExist = newListBalance.find(
					i => i.prePaidBalanceAccountTypeId === values
				);

				if (findItemIsExist) {
					toastWarning("Este bolso já foi selecionado");
				} else {
					objectValue.prePaidBalanceAccountTypeId = values;
				}
			}

			handleChange("fundsPrePaidBalanceType", newListBalance);
		},
		[fundsPrePaidBalanceType, handleChange]
	);

	const onCreateDeposit = useCallback(() => {
		if (debouncedCreateDeposit) {
			debouncedCreateDeposit.cancel();
		}

		debouncedCreateDeposit = _.debounce(
			() =>
				dispatch(
					createTransaction(err => {
						if (err) {
							toastError(err);
						} else {
							toastSuccess("Depósito criado com sucesso");
							handleClose();
						}
					})
				),
			500
		);

		if (listBalanceFilter.length > 0) {
			toastError("Não há bolso selecionado");
		} else if (valueMap > 0) {
			toastError("Ainda há valor para ser usado");
		} else if (sumValues / 100 > value) {
			toastWarning("Valores dos bolsos maior que o valor do déposito avulso");
		} else {
			debouncedCreateDeposit();
		}
	}, [
		dispatch,
		handleClose,
		listBalanceFilter.length,
		sumValues,
		value,
		valueMap
	]);

	const { items } = useSelector<any, any>(s => s.businessReport);

	return (
		<ModalGeneric
			open={open}
			handleClose={handleClose}
			body={() => (
				<BodyModal>
					<TitleText>Novo depósito avulso</TitleText>
					<GridsBody item xs={12} style={{ marginBottom: 10 }}>
						<Label bold>
							Valor: <Label> {toStringCurrency(Number(value))}</Label>
						</Label>
						<Label bold>
							Vencimento: <Label> {dayjs(dueDate).format("DD/MM/YYYY")}</Label>
						</Label>
					</GridsBody>
					<GridsBody item style={{ marginBottom: 20 }}>
						<Label bold>
							Nome da empresa:{" "}
							<Label style={{ width: "30%" }}>{items[0]?.displayName}</Label>
						</Label>
						<Label bold>
							CNPJ: <Label>{cnpj}</Label>
						</Label>
					</GridsBody>
					<GridsBody item style={{ marginBottom: 20 }}>
						<Label bold>
							ID Tesouraria: <Label>{items[0]?.treasuryAccountHolderId}</Label>
						</Label>
					</GridsBody>

					<GridsBody item style={{ marginBottom: 20 }}>
						<RowTitle>
							<LabelTitle>Referente a pagamento de benefício:</LabelTitle>
							<ButtonAdd type="button" onClick={addListBalance}>
								<img src={icon} alt="icon" style={{ marginRight: 2 }} />
								Adicionar bolso
							</ButtonAdd>
						</RowTitle>
					</GridsBody>

					<GridRow>
						<Row>
							<TextInput
								size="small"
								id="value"
								style={{
									borderColor: "#C2C2C2",
									backgroundColor: "#EBEBEB",
									width: 170,
									marginRight: 30
								}}
								disabled
								variant="outlined"
								name=""
								value="Á definir"
								onChange={() => {}}
								errors={errors}
								placeholder={ls.value}
							/>

							<TextInput
								size="small"
								id="value"
								style={{
									borderColor: "#C2C2C2",
									backgroundColor: "#EBEBEB",
									width: 200
								}}
								disabled
								InputProps={{
									inputComponent: MONEY
								}}
								variant="outlined"
								name="value"
								value={valueMap}
								onChange={() => {}}
								errors={errors}
								placeholder={ls.value}
							/>
						</Row>
					</GridRow>
					{fundsPrePaidBalanceType.map((i, index) => (
						<GridRow key={index}>
							<Row style={{ justifyContent: "space-between" }}>
								<Select
									id="prePaidBalanceAccountTypeId"
									name="prePaidBalanceAccountTypeId"
									value={i.prePaidBalanceAccountTypeId}
									style={{
										height: 40,
										marginRight: 12,
										width: 175,
										borderColor: "#C2C2C2",
										backgroundColor: "#EBEBEB"
									}}
									required
									onChange={(id, value) => {
										editListBalance(
											"prePaidBalanceAccountTypeId",
											value,
											index
										);
									}}
									options={typesUsed}
								/>
								<TextInput
									size="small"
									id="value"
									style={{
										borderColor: "#C2C2C2",
										backgroundColor: "#EBEBEB",
										alignSelf: "center"
									}}
									InputProps={{
										inputComponent: MONEY
									}}
									required
									variant="outlined"
									name="value"
									value={i.value / 100 || 0}
									errors={errors}
									placeholder={ls.value}
									onChange={(id, values) => {
										editListBalance(id, Number(values), index);
									}}
								/>

								<TooltipLight title="Excluir" placement="top">
									<IconButton
										style={{ padding: 4 }}
										onClick={() =>
											removeListBalance(i.prePaidBalanceAccountTypeId)
										}
									>
										<MdClose color="#FF0000" />
									</IconButton>
								</TooltipLight>
							</Row>
						</GridRow>
					))}

					<GridsBody item style={{ marginBottom: 20 }}>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",

								width: "100%"
							}}
						>
							<LabelTitle style={{ marginBottom: 20 }}>
								Informações adicionais da nota:
							</LabelTitle>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "flex-start",
									width: "100%",
									marginBottom: 10
								}}
							>
								<TextInput
									id="numberOrder"
									variant="outlined"
									name="numberOrder"
									type="number"
									value={numberOrder}
									style={{
										width: 250,
										marginRight: 20,
										height: 30,
										fontSize: 12
									}}
									errors={errors}
									onChange={handleChange}
								/>
								<TextInput
									type="number"
									id="numberContract"
									variant="outlined"
									name="numberContract"
									value={numberContract}
									style={{ width: 250, height: 30, fontSize: 12 }}
									errors={errors}
									onChange={handleChange}
								/>
							</div>
						</div>
					</GridsBody>
					<BackgroundButton>
						<Button
							color="primary"
							size="small"
							variant="contained"
							type="submit"
							onClick={onCreateDeposit}
							disabled={loading}
							style={{
								padding: 6,
								fontSize: 18,
								borderRadius: 6,
								width: 110
							}}
						>
							{loading ? <Spinner color="inherit" size={18} /> : ls.generate}
						</Button>
					</BackgroundButton>
				</BodyModal>
			)}
		/>
	);
};
export default ModalCreate;
