import { getErrors } from "helpers/error";
import * as api from "services/api";

export const GET_ROLES = "[ROLE DETAILS] GET_ROLES";
export const GET_ROLES_SUCCESS = "[ROLE DETAILS] GET_ROLES_SUCCESS";
export const GET_ROLES_FAILED = "[ROLE DETAILS] GET_ROLES_FAILED";

export const SET_VALUE = "[ROLE DETAILS] SET_VALUE";
export const CLEAR_VALUES = "[ROLE DETAILS] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getRoles(callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ROLES });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Role", {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ROLES_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ROLES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ROLES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
