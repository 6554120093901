import React from "react";

import TransactionReport from "../../Transaction/Report/index";

const params = {
	wiipoFlex: true
};

const TransactionWIipoFlex: React.FC = () => (
	<TransactionReport params={params} />
);

export default TransactionWIipoFlex;
