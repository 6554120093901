/* eslint-disable react-hooks/exhaustive-deps */
import {
	Backdrop,
	Button,
	Fade,
	FormLabel,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import AutoComplete2 from "Components/AutoComplete2";
import { MONEY } from "Components/NumberFormat";
import { PERCENTAGE } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "store/actions/business/report";
import {
	clearValues,
	editCashback,
	setValue
} from "store/actions/cashbackPolicy";
import { CashbackPolicyState } from "store/reducers/cashbackPolicy";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceFindStore: (() => void) & _.Cancelable = null;

function EditForm({ load }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const {
		showEditFormModal,
		minValue,
		status,
		maxInvestment,
		value,
		maxValue,
		limit,
		limitPerUser,
		activeOn,
		creditMinValue,
		creditBusiness,
		info,
		startAt,
		endAt,
		creditStartAt,
		creditEndAt,
		errors,
		loading,
		dynamicDateStart,
		dynamicDateEnd,
		validateDaysDynamicDate
	} = useSelector<any, CashbackPolicyState>(c => c.cashbackPolicy);

	const { loading: storeLoading } = useSelector<any, any>(
		s => s.businessReport
	);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showEditFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		dispatch(
			editCashback((err, model) => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					load();
					handleClose();
					dispatch(clearValues());
				}
			})
		);
	};

	const findCompany = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindStore) debounceFindStore.cancel();

			debounceFindStore = _.debounce(() => {
				dispatch(
					getBusinesses(
						0,
						10,
						`&filters[name]=${name}`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => ({
										id: c.id,
										label: `${c.displayName}`
									}))
								);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindStore();
		}
	};

	return (
		<Modal
			aria-labelledby="edit-product-modal-title"
			aria-describedby="edit-product-modal-description"
			className={classes.modal}
			open={showEditFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showEditFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editCashback}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="value"
										required
										variant="outlined"
										name="value"
										InputProps={{
											inputComponent: PERCENTAGE
										}}
										value={value}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="minValue"
										variant="outlined"
										name="minValue"
										InputProps={{
											inputComponent: MONEY
										}}
										value={minValue}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4} lg={4}>
									<TextInput
										id="maxValue"
										variant="outlined"
										name="maxValue"
										InputProps={{
											inputComponent: MONEY
										}}
										value={maxValue}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="maxInvestment"
										variant="outlined"
										name="maxInvestment"
										InputProps={{
											inputComponent: MONEY
										}}
										value={maxInvestment}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="limit"
										variant="outlined"
										name="limit"
										InputProps={{
											inputProps: {
												step: 1,
												min: 0
											}
										}}
										type="number"
										value={limit}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="limitPerUser"
										variant="outlined"
										name="limitPerUser"
										InputProps={{
											inputProps: {
												step: 1,
												min: 0
											}
										}}
										type="number"
										value={limitPerUser}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4} lg={3}>
									<TextInput
										id="activeOn"
										variant="outlined"
										name="activeOnSale"
										InputProps={{
											inputProps: {
												step: 1,
												min: 1
											}
										}}
										type="number"
										value={activeOn}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<DateTimePicker
										fullWidth
										value={startAt}
										label={ls.startAt}
										onChange={date => handleChange("startAt", date)}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
										clearable
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<DateTimePicker
										fullWidth
										value={endAt}
										label={ls.endAt}
										onChange={date => handleChange("endAt", date)}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
										clearable
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={4}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={String(status)}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: 0, value: "Desativado" },
											{ id: 1, value: "Ativo" },
											{ id: 2, value: "Encerrado" }
										]}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextInput
										id="info"
										variant="outlined"
										name="info"
										value={info}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
										multiline
										rows={3}
									/>
								</Grid>

								<Grid item xs={12}>
									<FormLabel>Campos do crédito:</FormLabel>
								</Grid>

								<Grid item xs={12} sm={6} md={6} lg={6}>
									<TextInput
										id="creditMinValue"
										variant="outlined"
										name="creditMinValue"
										InputProps={{
											inputComponent: MONEY
										}}
										value={creditMinValue}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={6}>
									<AutoComplete2
										id="creditBusinessId"
										name="creditBusiness"
										errors={errors}
										defaultValue={
											creditBusiness
												? {
														id: creditBusiness.id,
														label:
															creditBusiness.displayName || creditBusiness.name
												  }
												: undefined
										}
										onChange={handleChange}
										loadOptions={findCompany}
										loading={storeLoading}
										filterOptions={(options, state) => options}
									/>
								</Grid>

								<Grid item xs={12} sm={8} md={8} lg={6}>
									<Select
										errors={errors}
										id="dynamicDateStart"
										name="dynamicDateStart"
										value={String(dynamicDateStart)}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: true, value: "Dinâmica após a compra" },
											{ id: false, value: "Fixa" }
										]}
									/>
								</Grid>
								{!dynamicDateStart ? (
									<>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<DateTimePicker
												fullWidth
												value={creditStartAt}
												label={ls.creditStartAt}
												onChange={date => handleChange("creditStartAt", date)}
												format={ls.dateFormatWithoutSeconds}
												inputVariant="outlined"
												ampm={false}
												clearable
											/>
										</Grid>
									</>
								) : (
									<>
										<Grid item xs={12} sm={6} md={6} lg={6} />
									</>
								)}

								<Grid item xs={12} sm={8} md={8} lg={6}>
									<Select
										errors={errors}
										id="dynamicDateEnd"
										name="dynamicDateEnd"
										value={String(dynamicDateEnd)}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											{ id: true, value: "Dinâmica após a compra" },
											{ id: false, value: "Fixa" }
										]}
									/>
								</Grid>

								{!dynamicDateEnd ? (
									<>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<DateTimePicker
												fullWidth
												value={creditEndAt}
												label={ls.creditEndAt}
												onChange={date => handleChange("creditEndAt", date)}
												format={ls.dateFormatWithoutSeconds}
												inputVariant="outlined"
												ampm={false}
												clearable
											/>
										</Grid>
									</>
								) : (
									<>
										<Grid item xs={12} sm={6} md={6} lg={6}>
											<TextInput
												id="validateDaysDynamicDate"
												variant="outlined"
												name="validateDaysDynamicDate"
												InputProps={{
													inputProps: {
														step: 1,
														min: 0
													}
												}}
												type="number"
												value={validateDaysDynamicDate}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={handleChange}
											/>
										</Grid>
									</>
								)}
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditForm;
