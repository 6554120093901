import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import { creditCardFormat } from "helpers/creditCard";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { importVirtualCards } from "store/actions/virtualCard";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: "calc(80%)",
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceImportVirtualCards: (() => void) & _.Cancelable = null;

function ImportVirtualCards({ open, items, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { loading } = useSelector<any, any>(s => s.virtualCard);
	const [returnErrors, setReturnErrors] = useState([]);

	const [data, setData] = useState([]);

	useEffect(() => {
		setData(items || []);
	}, [items]);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceImportVirtualCards) debounceImportVirtualCards.cancel();

		debounceImportVirtualCards = _.debounce(() => {
			dispatch(
				importVirtualCards(data, err => {
					if (err) {
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else if (err.user_creation_error) {
							err.user_creation_error = err._original.user_creation_error;

							let errors = [];

							try {
								if (typeof err.user_creation_error === "string")
									err.user_creation_error = JSON.parse(err.user_creation_error);
							} catch (error) {
								console.log(error);
							}

							for (const list of err.user_creation_error) {
								let list2 = JSON.parse(list);

								for (const e of list2) {
									let error: any = { description: ls[e.code] };

									switch (e.code) {
										case "DuplicateUserName":
											error.cpf = e.description.split("'")[1].split("'")[0];
											break;
										default:
											error.cpf = e.description;
											break;
									}

									errors.push(error);
								}
							}
							handleClose();

							setReturnErrors(errors);
						}
					} else {
						handleClose();
					}
				})
			);
		}, 1000);

		debounceImportVirtualCards();
	};

	const renderEditable = (cellInfo, c) => (
		<div
			style={{ backgroundColor: "#fafafa" }}
			contentEditable
			suppressContentEditableWarning
			onBlur={e => {
				data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
				setData([...data]);
			}}
			dangerouslySetInnerHTML={{
				__html: data[cellInfo.index][cellInfo.column.id]
			}}
		/>
	);

	if (returnErrors.length > 0)
		return (
			<Modal
				aria-labelledby="import-user-modal-title"
				aria-describedby="import-user-modal-description"
				className={classes.modal}
				open
				onClose={() => setReturnErrors([])}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in>
					<div className={classes.paper}>
						<Typography variant="h6">
							Os seguintes cartões não foram importados por causa dos seguintes
							erros
						</Typography>
						<hr />
						<div className={classes.body}>
							<ReactTable
								data={returnErrors}
								columns={
									[
										{
											Header: ls.cpfcnpj,
											accessor: "cpf"
										},
										{
											Header: ls.description,
											accessor: "description"
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => setReturnErrors([])}>
									{ls.close}
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);

	return (
		<Modal
			aria-labelledby="import-user-modal-title"
			aria-describedby="import-user-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.importUsers}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<ReactTable
								data={data}
								columns={
									[
										{
											Header: ls.cpfcnpj,
											accessor: "cpf",
											Cell: renderEditable
										},
										{
											Header: "Número",
											id: "number",
											accessor: c => creditCardFormat(c.number),
											Cell: renderEditable
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.import}
								>
									{ls.import}
									{loading.import && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ImportVirtualCards;
