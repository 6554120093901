import dayjs from "dayjs";
import * as actions from "store/actions/credit/create";

import { ICreateCredit } from "../types/create";

const initialState: ICreateCredit = {
	creationDate: dayjs(),
	companyId: "",
	expirationDate: null,
	users: [],
	stores: [],
	categories: [],
	userId: "",
	multiplePeople: false,
	value: 0,
	status: 1,
	minValue: 0,
	startDate: null,
	description: "",
	showCategoryFormModal: false,
	showStoreFormModal: false,
	origin: null,
	errors: {},
	loading: {}
};

const reducer = function (state = initialState, { type, payload }) {
	switch (type) {
		case actions.CREATE_CREDIT: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_CREDIT_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_CREDIT_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
