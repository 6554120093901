import {
	Chip,
	FormControl,
	FormHelperText,
	Icon,
	MenuItem,
	NoSsr,
	Paper,
	TextField,
	Typography,
	useTheme
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React from "react";
import { FaTimesCircle } from "react-icons/fa";
import ReactSelect, { components } from "react-select";
import ReactSelectAsync from "react-select/async";

import useStyles from "./styles";

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className={props.selectProps.classes.noOptionsMessage}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function inputComponent({ inputRef, ...props }) {
	return <div ref={inputRef} {...props} />;
}

function Control(props) {
	return (
		<TextField
			fullWidth
			disabled={props.isDisabled}
			variant={props.selectProps.variant}
			InputProps={{
				classes: {
					root: clsx({
						[props.selectProps.classes.inputOutlined]:
							props.selectProps.variant === "outlined"
					})
				},
				inputComponent,
				inputProps: {
					className: clsx(props.selectProps.classes.input, {
						[props.selectProps.classes.inputStartAdornment]:
							props.selectProps.InputProps &&
							props.selectProps.InputProps.startAdornment
					}),
					inputRef: props.innerRef,
					children: props.children,
					...props.innerProps,
					...props.selectProps.inputProps
				},
				...props.selectProps.InputProps
			}}
			{...props.selectProps.textFieldProps}
		/>
	);
}

function Option(props) {
	return (
		<MenuItem
			disabled={props.isDisabled}
			buttonRef={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400
			}}
			{...props.innerProps}
		>
			{props.children}
		</MenuItem>
	);
}

function Placeholder(props) {
	return (
		<Typography
			color="textSecondary"
			className={clsx(props.selectProps.classes.placeholder, {
				[props.selectProps.classes.placeholderStartAdornment]:
					props.selectProps.InputProps &&
					props.selectProps.InputProps.startAdornment
			})}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function SingleValue(props) {
	return (
		<Typography
			className={props.selectProps.classes.singleValue}
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

function ValueContainer(props) {
	return (
		<div className={props.selectProps.classes.valueContainer}>
			{props.children}
		</div>
	);
}

function Menu(props) {
	return (
		<Paper
			square
			className={props.selectProps.classes.paper}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	);
}

function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			className={clsx(props.selectProps.classes.chip, {
				[props.selectProps.classes.chipFocused]: props.isFocused
			})}
			onDelete={props.removeProps.onClick}
			deleteIcon={<FaTimesCircle {...props.removeProps} />}
		/>
	);
}

function DropdownIndicator(props) {
	if (props.selectProps.hideDropdownIndicator) return null;

	return (
		components.DropdownIndicator && (
			<components.DropdownIndicator
				{...props}
				className={props.selectProps.classes.iconContainer}
			>
				<Icon
					className={clsx(props.selectProps.classes.icon, {
						"fas fa-caret-up": props.selectProps.menuIsOpen,
						"fas fa-caret-down": !props.selectProps.menuIsOpen
					})}
				/>
			</components.DropdownIndicator>
		)
	);
}

function IndicatorSeparator() {
	return null;
}

const customComponents = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
	DropdownIndicator,
	IndicatorSeparator
};

export default function AutoComplete(props: any) {
	const classes = useStyles({});
	const theme = useTheme();

	const {
		className,
		suggestions,
		async,
		label,
		variant,
		textFieldProps,
		isMulti,
		id,
		name,
		onChange,
		errors
	} = props;

	const changeValue = target => {
		onChange(id, target);
	};

	const importedProps = _.pick(props, [
		"autoWidth",
		"children",
		"displayEmpty",
		"input",
		"inputProps",
		"InputProps",
		"onInputChange",
		"onInputChange",
		"MenuProps",
		"multiline",
		"isMulti",
		"native",
		"onChange",
		"onClose",
		"onOpen",
		"onFocus",
		"open",
		"menuIsOpen",
		"renderValue",
		"SelectDisplayProps",
		"value",
		"disabled",
		"isDisabled",
		"maxMenuHeight",
		"defaultValue",
		"loadOptions",
		"placeholder",
		"inputValue",
		"onSelectResetsInput",
		"onKeyDown",
		"hideDropdownIndicator",
		"closeMenuOnSelect",
		"isClearable",
		"isOptionDisabled",
		"autoFocus",
		"defaultInputValue"
	]);

	// An error message is returned only if the component is invalid
	const errorMessage = errors ? errors[id || name] : "";
	const { value } = props;

	const selectStyles = {
		input: (base, state) => ({
			...base,
			color: importedProps.isDisabled
				? "rgba(0, 0, 0, 0.38)"
				: theme.palette.text.primary,
			"& input": {
				font: "inherit"
			}
		}),
		indicatorsContainer: (base, state) => ({
			...base,
			width: 32,
			cursor: "pointer",
			"& > div": {
				padding: 0
			}
		})
	};

	return (
		<NoSsr>
			<FormControl
				variant={variant}
				disabled={importedProps.isDisabled}
				error={Boolean(errorMessage)}
				className={className}
				classes={{
					root: clsx(classes.root, {
						[classes.rootOutlined]: variant === "outlined"
					})
				}}
			>
				{async ? (
					<ReactSelectAsync
						variant={variant}
						maxMenuHeight={46 * 4}
						{...importedProps}
						textFieldProps={{
							label,
							InputLabelProps: {
								shrink: true
							},
							error: Boolean(errorMessage),
							...textFieldProps
						}}
						classes={classes}
						styles={selectStyles}
						components={customComponents}
						onChange={changeValue}
					/>
				) : (
					<ReactSelect
						variant={variant}
						maxMenuHeight={46 * 4}
						{...importedProps}
						textFieldProps={{
							label,
							InputLabelProps: {
								shrink: true
							},
							...textFieldProps
						}}
						classes={classes}
						styles={selectStyles}
						options={suggestions}
						value={
							isMulti ? value : suggestions.find((c: any) => c.value === value)
						}
						components={customComponents}
						onChange={changeValue}
					/>
				)}
				{Boolean(errorMessage) && (
					<FormHelperText disabled={importedProps.isDisabled}>
						{errorMessage}
					</FormHelperText>
				)}
			</FormControl>
		</NoSsr>
	);
}
