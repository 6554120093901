import * as actions from "store/actions/moderation/detail";

import { IDetailModeration } from "../types/detail";

const initialState: IDetailModeration = {
	item: null,
	amount: 0,
	showEditModal: false,
	loading: {},
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_MODERATION: {
			return {
				...state,
				loading: {
					...state.loading,
					get: true
				},
				errors: initialState.errors
			};
		}

		case actions.GET_MODERATION_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				item: payload
			};
		}

		case actions.GET_MODERATION_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					get: false
				},
				errors: payload
			};
		}

		case actions.MODERATION_DENIED: {
			return {
				...state,
				loading: {
					...state.loading,
					denied: true
				},
				errors: initialState.errors
			};
		}

		case actions.MODERATION_DENIED_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					denied: false
				},
				item: payload
			};
		}

		case actions.MODERATION_DENIED_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					denied: false
				},
				errors: payload
			};
		}

		case actions.MODERATION_APPROVE: {
			return {
				...state,
				loading: {
					...state.loading,
					approve: true
				},
				errors: initialState.errors
			};
		}

		case actions.MODERATION_APPROVE_SUCCESS: {
			return {
				...state,
				loading: {
					...state.loading,
					approve: false
				},
				item: payload
			};
		}

		case actions.MODERATION_APPROVE_FAILED: {
			return {
				...state,
				loading: {
					...state.loading,
					approve: false
				},
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			return {
				amount: initialState.amount,
				loading: initialState.loading,
				errors: initialState.errors
			};

		default: {
			return state;
		}
	}
};

export default reducer;
