import { IconButton, Typography } from "@material-ui/core";
import EditTable from "Components/EditTable";
import Spinner from "Components/Spinner";
import TooltipLight from "Components/TooltipLight";
import { removeDiacritics } from "helpers/string";
import { toastError, toastHTML, toastSuccess } from "helpers/toast";
import { CPFValidation } from "helpers/validators";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FaFileImport } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { HCMPartial } from "store/actions/business/detail";
import XLSX from "xlsx";

import {
	Button,
	Buttons,
	Container,
	Content,
	Header,
	InputAddCPF,
	RowAddCPF,
	Title
} from "./styles";

interface Items {
	document: string;
	editable: boolean;
}

interface Props {
	businessId: string;
}

let debounceBusinessCreateUserPartial: (() => void) & _.Cancelable = null;

const ImportParcialHCM: React.FC<Props> = ({ businessId }) => {
	const dispatch = useDispatch();
	const [cpfs, setCpfs] = useState<Items[]>([]);
	const [loading, setLoading] = useState(false);
	const [cpfValue, setCpfValue] = useState("");
	let arrayErrors = [];

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (debounceBusinessCreateUserPartial)
			debounceBusinessCreateUserPartial.cancel();

		debounceBusinessCreateUserPartial = _.debounce(() => {
			setLoading(true);
			dispatch(
				HCMPartial(businessId, cpfs, err => {
					if (err) {
						if (err.default) toastError(err.default);
						else if (typeof err === "string") toastError(err);
						else toastError(JSON.stringify(err));
					} else {
						setCpfs([]);
						toastSuccess(
							"Enviados usuários com sucesso por favor aguarda uns minutos"
						);
					}
					setLoading(false);
				})
			);
		}, 1000);

		debounceBusinessCreateUserPartial();
	};

	const inputFile = useRef<HTMLInputElement>(null);

	const openSelectFile = () => {
		if (inputFile.current) {
			inputFile.current.click();
		}
	};

	const cleanCpfs = useCallback(() => {
		setCpfs([]);
	}, []);

	const handleFileSelected = e => {
		arrayErrors = [];
		const file = e.target.files[0];
		if (!file) return;

		const reader = new FileReader();
		const rABS = !!reader.readAsBinaryString;
		reader.onload = e => {
			/* Parse data */
			const bstr = e.target.result;
			const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
			/* Get first worksheet */
			const sheetName = wb.SheetNames[0];

			const sheet = wb.Sheets[sheetName];

			let headers = [];
			let range = XLSX.utils.decode_range(sheet["!ref"]);
			let C;
			let R = range.s.r;
			for (C = range.s.c; C <= range.e.c; ++C) {
				let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];

				if (cell && cell.t === "s") {
					cell.v = removeDiacritics(cell.v.trim().toLowerCase());
					if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());

					headers.push(removeDiacritics(cell.v.trim().toLowerCase()));
				}
			}

			const cpfHeader = headers.filter(i => i === "cpf");

			if (cpfHeader.length === 0) {
				toastError("Coluna cpf não encontrada");
				return;
			}

			const data = XLSX.utils.sheet_to_json(sheet);

			const arrayCPFs: Items[] = data.map((i, index) => {
				let string = String(i["cpf"])?.match(/\d/g).join("").padStart(11, "0");

				if (i["cpf"] && CPFValidation(string)) {
					return {
						document: string.replace(".", "").replace("-", "").replace(" ", ""),
						editable: false
					};
				}
				arrayErrors.push(
					`CPF não é valido ${i["cpf"]} está na linha ${
						index + 2
					} da planilha \n`
				);
				return null;
			});

			if (arrayErrors.length > 0) {
				toastHTML(
					<div style={{ display: "flex", flexDirection: "column" }}>
						Erros: {"\n"}
						{arrayErrors.map(i => (
							<div> {i}</div>
						))}
					</div>
				);

				return;
			}

			setCpfs([...cpfs, ...arrayCPFs.filter(i => i)]);
		};

		if (rABS) reader.readAsBinaryString(file);
		else reader.readAsArrayBuffer(file);

		inputFile.current.value = null;
	};

	const addToArray = useCallback(() => {
		if (
			cpfValue &&
			(CPFValidation(cpfValue) || cpfValue.split(" ").length > 1)
		) {
			const valores: Items[] = cpfValue.split(" ").map(i => ({
				document: i.replaceAll(".", "").replace("-", "").replace(" ", ""),
				editable: false
			}));
			setCpfValue("");
			setCpfs([...cpfs, ...valores]);
		}
	}, [cpfValue, cpfs]);

	const disableButton = useMemo(() => {
		if (cpfValue.split(" ").length > 1) {
			return false;
		}

		if (CPFValidation(cpfValue) && cpfValue.split(" ").length <= 1) {
			return false;
		}

		return true;
	}, [cpfValue]);

	return (
		<Container>
			<Header>
				<Title>
					<Typography variant="h6">{ls.syncUsers}</Typography>
				</Title>
				<Buttons>
					<TooltipLight title="Importar usuários" placement="top">
						<IconButton onClick={openSelectFile}>
							<FaFileImport />
						</IconButton>
					</TooltipLight>
				</Buttons>
			</Header>
			<hr />
			<Content>
				<form
					noValidate
					onSubmit={e => {
						onSubmit(e);
					}}
				>
					<RowAddCPF>
						<InputAddCPF
							placeholder="CPF"
							value={cpfValue}
							onChange={e => setCpfValue(e.target.value)}
						/>
						<Button
							isCharge
							onClick={addToArray}
							disabled={disableButton}
							isDisable={disableButton}
						>
							Carregar
						</Button>
					</RowAddCPF>
					{cpfs.length > 0 && (
						<RowAddCPF>
							<EditTable items={cpfs} />
							<Button style={{ marginLeft: 10 }} isWhite onClick={cleanCpfs}>
								Limpar
							</Button>
							<Button type="submit" disabled={loading} isDisable={loading}>
								{loading ? (
									<Spinner color="inherit" size={24} />
								) : (
									"Sincronizar"
								)}
							</Button>
						</RowAddCPF>
					)}
				</form>
				<input
					ref={inputFile}
					style={{ display: "none" }}
					type="file"
					accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
					onChange={handleFileSelected}
				/>
			</Content>
		</Container>
	);
};

export default ImportParcialHCM;
