import {
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	Typography
} from "@material-ui/core";
import { Skeleton, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import ButtonCopyClipboard from "Components/ButtonCopyClipboard";
import { modalConfirmation } from "Components/Modals/ModalConfirmation";
import { MONEY } from "Components/NumberFormat";
import Tooltip from "Components/Tooltip";
import LabelTypeBag from "Components/views/Dashboard/User/Detail/AccountHolder/LabelTypeBag";
import { isInRole } from "helpers/auth";
import ls from "Localization";
import userKycStatus from "models/userKycStatus";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { ImBlocked } from "react-icons/im";
import { IoRefreshOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
	changeCardStatusById,
	getCardsFromUserId
} from "store/actions/card/detail";
import { ICardUserResult, ICardUserState } from "store/actions/card/types";
import { getUserFromSwap } from "store/actions/user/detail";

import useStyles, { GridButtonCopy } from "./styles";

interface IUserProps {
	id: string;
	accountHolderId: string;
	balanceAccountGroupId: string;
	accountHolderBalance: number;
	userBagAccounts: IUserTypeBAG[];
	swapDetails: IUserSwapDetails;
	prePaidBalanceAccounts: IUserPrePaidBalanceAccounts[];
}
interface IItemUser {
	item: IUserProps;
}

interface ICardUserProps extends ICardUserResult {
	disabled: boolean;
}

interface IUserSwapDetails {
	kycStatus: number;
	kycError: string;
}

interface IUserTypeBAG {
	userId: string;
	accountHolderId: string;
	balanceAccountGroupId: string;
	accountType: number;
	provider: number;
	status: number;
	id: string;
	creationDate: string;
	updateDate: string;
}
interface IUserPrePaidBalanceAccounts {
	type: {
		externalId: number;
		name: string;
	};
	balance: number;
}

const AccountHolder: React.FC<IItemUser> = ({ item: userData }) => {
	const dispatch = useDispatch();
	const [statusKYC, setStatusKYC] = useState<number>(0);
	const [loadingKYC, setLoadingKYC] = useState<boolean>(false);
	const { user } = useSelector<any, any>(s => s.login);
	const { userSwap } = useSelector<any, any>(s => s.userDetail);
	const { items: cardsFromUserState } = useSelector<any, ICardUserState>(
		s => s.card
	);

	const isAdmin = isInRole(user, ["admin"]);
	const isWiipoFlex = isInRole(user, ["Admin Wiipo Flex"]);

	const cardsFromUser: ICardUserProps[] = useMemo(() => {
		let result = [] as ICardUserProps[];

		if (cardsFromUserState && cardsFromUserState.length > 0) {
			result = cardsFromUserState.map(row => {
				let disabled = false;
				if (row.status === 0 || row.status === 3) {
					disabled = true;
				}

				return {
					...row,
					disabled
				};
			});
		}
		return result;
	}, [cardsFromUserState]);

	const showPrePaidBalanceAccounts = useMemo(
		() =>
			userData &&
			!!userData.accountHolderBalance &&
			userData.accountHolderBalance > 0 &&
			(isAdmin || isWiipoFlex),
		[isAdmin, isWiipoFlex, userData]
	);

	const prePaidBalanceAccountsSort: IUserPrePaidBalanceAccounts[] =
		useMemo(() => {
			if (userData.prePaidBalanceAccounts) {
				return userData.prePaidBalanceAccounts.sort(
					(a, b) => a.type?.externalId - b.type?.externalId
				);
			}

			return [];
		}, [userData.prePaidBalanceAccounts]);

	const classes = useStyles({});

	const colorButtonCard = useCallback(
		(rowStatus: number, status: number, disabled: boolean) => {
			if (rowStatus === 1 && status === 0) {
				return {
					background: "#0000001f",
					color: "#a7a7a7"
				};
			}
			if (rowStatus !== status && disabled) {
				return {
					background: "#0000001f",
					color: "#a7a7a7"
				};
			}

			if (rowStatus !== status && !disabled) {
				return {
					background: "#ffffff",
					color: "#494949",
					marginLeft: 0
				};
			}

			return {
				background: "#5734D9",
				color: "#ffffff"
			};
		},
		[]
	);

	const load = useCallback(() => {
		dispatch(
			getCardsFromUserId({
				userId: userData.id
			})
		);
	}, [dispatch, userData]);

	const loadUserFromSwap = useCallback(() => {
		setLoadingKYC(true);
		dispatch(getUserFromSwap(userData.id));
	}, [dispatch, userData]);

	useEffect(() => {
		setStatusKYC(userData.swapDetails?.kycStatus);
	}, [userData]);

	useEffect(() => {
		if (userSwap) {
			setStatusKYC(userSwap.kyc_status);
			setLoadingKYC(false);
		}
	}, [userSwap]);

	const kycError =
		statusKYC &&
		userKycStatus[statusKYC] !== userKycStatus[1] &&
		userData.swapDetails?.kycError
			? userData.swapDetails.kycError
			: null;

	const colorKYC = useMemo(() => {
		switch (statusKYC) {
			case 1:
				return "#008D27";
			case 2:
				return "#FF0000";
			case 3:
				return "#FF843F";
			case 4:
				return "#FF843F";
			default:
				return "#000";
		}
	}, [statusKYC]);

	const typeBag = useMemo(() => {
		const findAccountType = userData.userBagAccounts?.find(
			i => i.balanceAccountGroupId === userData.balanceAccountGroupId
		)?.accountType;

		return findAccountType;
	}, [userData.balanceAccountGroupId, userData.userBagAccounts]);

	const verifyActiveBag = useMemo(() => {
		const findAccountStatus = userData.userBagAccounts?.find(
			i => i.balanceAccountGroupId === userData.balanceAccountGroupId
		)?.status;

		return findAccountStatus === 0;
	}, [userData.balanceAccountGroupId, userData.userBagAccounts]);

	const textBag = useMemo(() => {
		const findAccountStatus = userData.userBagAccounts?.find(
			i => i.balanceAccountGroupId === userData.balanceAccountGroupId
		)?.status;

		if (findAccountStatus !== 0) {
			return "Bag Bloqueada";
		}

		return "Bag Ativa";
	}, [userData.balanceAccountGroupId, userData.userBagAccounts]);

	const textKYC = useMemo(() => {
		switch (statusKYC) {
			case 1:
				return "• Aprovado";
			case 2:
				return "• Reprovado";
			case 3:
				return "• Em análise";
			case 4:
				return "• Em análise manual";
			default:
				return ls.notDefined;
		}
	}, [statusKYC]);

	useEffect(() => {
		load();
	}, [load]);

	const handleChangeCardStatus = useCallback(
		async (card: ICardUserResult, newStatus: number) => {
			modalConfirmation({
				onClickNo: () => {},
				onClickYes: () => {
					dispatch(
						changeCardStatusById({
							cardId: card.id,
							status: newStatus,
							userId: userData.id
						})
					);
				},
				title: ls.confirm,
				body: (
					<>
						{ls.formatString(
							ls.accountHolderCardChangeStatusConfirmation,
							card.maskedPan
						)}
						?
					</>
				)
			});
		},
		[dispatch, userData]
	);

	if (!userData) return null;

	return (
		<>
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6">{ls.prePaidCard}</Typography>
				</div>
				<hr className={classes.line} />

				<Grid container spacing={2} className={classes.form}>
					{!!statusKYC && loadingKYC ? (
						<Grid
							item
							xs={12}
							sm={6}
							md={4}
							lg={2}
							className={classes.gridLoadingKYC}
						>
							<Skeleton
								width={120}
								height={34}
								className={classes.loadingKYC}
							/>
						</Grid>
					) : (
						<Grid item xs={12} sm={6} md={4} lg={2}>
							{kycError ? (
								<>
									<Tooltip title={kycError}>
										<strong>{ls.status}</strong>:{" "}
										<strong style={{ color: colorKYC, fontWeight: "normal" }}>
											{textKYC}
										</strong>
									</Tooltip>
								</>
							) : (
								<>
									<strong>{ls.status}</strong>:{" "}
									<strong style={{ color: colorKYC, fontWeight: "normal" }}>
										{textKYC}
									</strong>
								</>
							)}
							<IconButton
								onClick={() => loadUserFromSwap()}
								style={{
									background: "#5734DA",
									borderRadius: 4,
									width: 22,
									height: 22,
									marginLeft: 4
								}}
								size="small"
							>
								<IoRefreshOutline size={20} color="#ffffff" />
							</IconButton>
						</Grid>
					)}
					{(isWiipoFlex || isAdmin) && (
						<>
							<GridButtonCopy item xs={12} sm={6} md={4} lg={5}>
								<strong>{ls.accountHolder}</strong>:{" "}
								{userData.accountHolderId || ls.notDefined}
								{userData.accountHolderId && (
									<ButtonCopyClipboard valueCopy={userData.accountHolderId} />
								)}
							</GridButtonCopy>
							<GridButtonCopy
								item
								xs={12}
								sm={6}
								md={4}
								lg={5}
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									color: verifyActiveBag ? "#000" : "red"
								}}
							>
								<strong style={{ color: "#000" }}>
									{ls.balanceAccountGroup}
								</strong>
								:{" "}
								{userData.balanceAccountGroupId &&
									isAdmin &&
									typeBag !== undefined && (
										<LabelTypeBag isBagAccount typeAccount={typeBag} />
									)}
								{userData.balanceAccountGroupId && (
									<Tooltip title={textBag}>
										{!verifyActiveBag ? (
											<LabelTypeBag icon={<ImBlocked />} />
										) : (
											<LabelTypeBag icon={<FaCheck color="green" />} />
										)}
									</Tooltip>
								)}
								<>{userData.balanceAccountGroupId || ls.notDefined}</>
								{userData.balanceAccountGroupId && (
									<ButtonCopyClipboard
										valueCopy={userData.balanceAccountGroupId}
									/>
								)}
							</GridButtonCopy>
						</>
					)}
					<Grid item xs={12} sm={6} md={4} lg={2}>
						<strong>{ls.balance}</strong>:{" "}
						{userData &&
							userData.accountHolderBalance &&
							userData.accountHolderBalance > 0 && (
								<MONEY
									displayType="text"
									value={userData.accountHolderBalance / 100.0}
								/>
							)}
					</Grid>

					{showPrePaidBalanceAccounts && (
						<>
							{userData.prePaidBalanceAccounts &&
								prePaidBalanceAccountsSort.map(bag => {
									if (bag.balance > 0) {
										return (
											<Grid item xs={12} sm={6} md={2} lg={2}>
												<strong>{bag.type.name}</strong>:{" "}
												<MONEY displayType="text" value={bag.balance / 100.0} />
											</Grid>
										);
									}

									return <></>;
								})}
						</>
					)}
				</Grid>

				{(isAdmin || isWiipoFlex) && (
					<>
						{cardsFromUser &&
							cardsFromUser.length > 0 &&
							cardsFromUser.map(row => (
								<Grid
									key={row.id}
									container
									spacing={3}
									className={classes.form}
								>
									<Grid item xs={12} sm={2} md={2} lg={2}>
										<span>{ls.card}</span>: {row.maskedPan}
									</Grid>
									<Grid item xs={12} sm={7} md={7} lg={7}>
										{row.isloading ? (
											<CircularProgress />
										) : (
											<ToggleButtonGroup
												value={String(row.status)}
												exclusive
												onChange={(_e, value) => {
													handleChangeCardStatus(row, value);
												}}
												aria-label="card status"
											>
												<ToggleButton
													value={0}
													aria-label="none"
													disabled
													style={colorButtonCard(row.status, 0, row.disabled)}
												>
													<span>Não atribuído</span>
												</ToggleButton>
												<ToggleButton
													value={1}
													aria-label="active"
													style={colorButtonCard(row.status, 1, row.disabled)}
													disabled={row.status !== 2}
												>
													<span>{row.status !== 1 ? "Ativar" : "Ativo"}</span>
												</ToggleButton>
												<ToggleButton
													value={2}
													aria-label="blocked temp"
													disabled={row.disabled}
													style={{
														...colorButtonCard(row.status, 2, row.disabled)
													}}
												>
													<span>Bloqueio Temporário</span>
												</ToggleButton>
												<ToggleButton
													style={colorButtonCard(row.status, 3, row.disabled)}
													value={3}
													aria-label="blocked"
													disabled={row.disabled}
												>
													<span>Bloqueio Definitivo / Desvinculo</span>
												</ToggleButton>
											</ToggleButtonGroup>
										)}
									</Grid>
									<Grid item xs={12} sm={3} md={3} lg={3}>
										<p>
											<strong>{ls.activationDate}</strong>
											{row.firstActivationDate == null
												? ls.notInformed
												: row.firstActivationDate}
										</p>
									</Grid>
								</Grid>
							))}
					</>
				)}
			</Paper>
		</>
	);
};

export default AccountHolder;
