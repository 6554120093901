import { validateEmail } from "helpers/email";
import { getErrors } from "helpers/error";
import { CNPJValidation, CPFValidation } from "helpers/validators";
import { isValidNumber } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";

export const CREATE_USER = "[USER CREATE] CREATE_USER";
export const CREATE_USER_SUCCESS = "[USER CREATE] CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "[USER CREATE] CREATE_USER_FAILED";

export const SET_VALUE = "[USER CREATE] SET_VALUE";
export const CLEAR_VALUES = "[USER CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function createUser(callback, hasWiipoClub) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_USER });

		try {
			const { login, userCreate } = getState();

			const {
				businessId,
				name,
				lastName,
				email,
				phoneNumber,
				cpf,
				role,
				enroll,
				admissionDate,
				limit,
				street,
				number,
				complement,
				neighborhood,
				city,
				state,
				zipCode,
				country,
				status,
				birthDate,
				mothersName,
				isPJ
			} = userCreate;

			let errors: any = {};

			if (!number) {
				errors.number = ls.emptyNumber;
			}

			if (!neighborhood) {
				errors.neighborhood = ls.emptyCity;
			}

			if (!city) {
				errors.city = ls.emptyCity;
			}

			if (!street) {
				errors.street = ls.emptyStreet;
			}

			if (!zipCode) {
				errors.zipcode = ls.required;
			}

			if (!name) errors.name = ls.required;
			if (!lastName) errors.lastName = ls.required;
			if (!role) errors.role = ls.required;
			if (!status && status !== 0) errors.status = ls.required;

			if (!cpf) errors.cpf = ls.required;
			else if (!(CPFValidation(cpf) || CNPJValidation(cpf))) {
				errors.cpf = ls.invalidCpf;
			}

			if (!phoneNumber) {
				errors.phoneNumber = ls.phoneEmpty;
			} else if (!isValidNumber(phoneNumber)) {
				errors.phoneNumber = ls.invalidPhoneNumber;
			}

			if (!email) {
				errors.email = ls.emptyEmail;
			} else if (!validateEmail(email)) {
				errors.email = ls.emailNotValid;
			}

			if (_.keys(errors).length > 0)
				return dispatch({
					type: CREATE_USER_FAILED,
					payload: errors
				});

			const model = {
				name,
				lastName,
				email,
				phoneNumber,
				enroll,
				admissionDate,
				status,
				mothersName,
				birthDate,
				creditLimit: {
					limit: hasWiipoClub && limit ? Math.round(limit * 100) : 0,
					renewalDay: 20,
					companyId: businessId,
					renewalCycle: 3
				},
				documents: [
					{
						type: CPFValidation(cpf) ? 0 : 3,
						value: cpf
					}
				],
				businesses: [
					{
						businessId,
						roleId: role
					}
				],
				address: {
					street,
					number,
					complement,
					neighborhood,
					city,
					state,
					zipCode,
					country
				},
				isPJ
			};

			const response = await api.sendPost("/User", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_USER_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			errors = getErrors(result.errors);

			dispatch({
				type: CREATE_USER_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: CREATE_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
