import styled from "styled-components";

interface PropsButton {
	isDisabled?: boolean;
}

export const Header = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	text-align: left;
	font: normal normal 600 20 Exo;
	letter-spacing: 0px;
	color: #1e1e1e;
	opacity: 1;
	align-items: center;
	padding: 32px;
	margin-top: 20px;
`;

export const RowIcons = styled.div`
	display: flex;
	flex-direction: row;
`;

export const BackgroundIcon = styled.button`
	background: none;
	color: none;
	border: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
`;

export const TextButton = styled.label`
	font: normal normal normal 16px Exo;
	letter-spacing: 0px;
	color: #5734d9;
	opacity: 1;
	margin-left: 10px;
	cursor: pointer;
`;

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 32px;
`;

export const Button = styled.button`
	border: 1px solid #5734da;
	border-radius: 8px;
	width: 244px;
	opacity: 1;
	padding: 8px;
	background: none;
`;

export const TextButtons = styled.span`
	font: normal normal normal 19px/25px Exo;
	letter-spacing: 0px;
	color: #5734da;
	opacity: 1;
	cursor: pointer;
`;

export const RowButtons = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: flex-start;
	margin-top: 30px;
	margin-bottom: 24px;
	padding: 0 32px;
`;

export const Title = styled.p`
	font: normal normal normal 19px/25px Exo;
	letter-spacing: 0px;
	color: #484848;
	opacity: 1;
`;

export const Footer = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 20px;
	width: 100%;
	padding: 0 32px;
`;

export const ButtonAdd = styled.button`
	background: none;
	border: none;
	color: #5734da;
	font: normal normal bold 20px Exo;
	letter-spacing: 0px;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 32px;
	cursor: pointer;
`;

export const ButtonRequest = styled.button<PropsButton>`
	width: 286px;
	height: 59px;
	background: #5734da 0% 0% no-repeat padding-box;
	background-color: ${props => (props.isDisabled ? "#a9a9a9" : "#5734da")};
	border: none;
	border-radius: 8px;
	opacity: 1;
	text-align: center;
	font: normal normal bold 20px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	margin-bottom: 32px;
	cursor: ${props => (props.isDisabled ? "none" : "pointer")};
`;

export const BackgroundHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: 100%;
`;
