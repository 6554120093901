import {
	Grid,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableHead,
	TableRow,
	Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Spinner from "Components/Spinner";
import TableCell from "Components/TableCell";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import ls from "Localization";
import integrationObject from "models/integrationObject";
import integrationProvider from "models/integrationProvider";
import integrationType from "models/integrationType";
import React, { useCallback, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { MdEdit, MdFileDownload, MdMail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	downloadIntegrationFile,
	getIntegration,
	getIntegrations,
	setValue
} from "store/actions/integrations";

import ExportConsumption from "../../ExportConsumption";
import CreateForm from "./Forms/Create";
import EditForm from "./Forms/Edit";
import SendEmail from "./Forms/SendEmail";
import styles from "./styles";

function Integrations({ classes }) {
	const dispatch = useDispatch();

	const [showExportConsumption, setShowExportConsumption] = useState(null);

	const [loadingSub, setLoadingSub] = useState({});

	const { items, loading } = useSelector<any, any>(c => c.integrations);

	const { user } = useSelector<any, any>(s => s.login);
	const { item: business } = useSelector<any, any>(s => s.businessDetail);

	const isAdmin = isInRole(user, ["admin"]);

	const load = useCallback(() => {
		dispatch(clearValues(true));
		dispatch(getIntegrations());
	}, [dispatch]);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(id, include, item) => {
			setLoadingSub({ [id]: item.id });
			dispatch(
				getIntegration(item.id, include, (err, model) => {
					if (err) {
						console.log(err);
					} else {
						handleChange(id, Boolean(model));
					}
					setLoadingSub({ [id]: false });
				})
			);
		},
		[dispatch, handleChange]
	);

	const handleDownloadFile = useCallback(
		(from, to) => {
			setLoadingSub({ downloadFile: showExportConsumption });

			dispatch(
				downloadIntegrationFile(
					showExportConsumption,
					from,
					to,
					(err, model) => {
						if (err) {
							console.log(err);
						} else {
							window.open(model.link, "_blank");
						}
						setLoadingSub({ downloadFile: false });
						setShowExportConsumption(null);
					}
				)
			);
		},
		[dispatch, showExportConsumption]
	);

	const handleSendMail = useCallback(
		(id, item) => {
			setLoadingSub({ [id]: item.id });

			handleChange("item", item);
			handleChange(id, true);
		},
		[handleChange]
	);

	return (
		<LoadOnScroll load={load}>
			<Paper className={classes.root}>
				<div className={classes.header}>
					<Typography variant="h6">
						Integrações e padrões de relatórios
						{loading.getAll && <Spinner color="secondary" size={16} />}
					</Typography>
					{isAdmin && (
						<div>
							<IconButton
								color="primary"
								onClick={() => handleChange("showFormModal", true)}
							>
								<FaPlus />
							</IconButton>
						</div>
					)}
				</div>
				<hr className={classes.line} />

				<Grid container spacing={2} className={classes.form}>
					<Table className={classes.table}>
						<TableHead>
							<TableRow>
								<TableCell>Tipo</TableCell>
								<TableCell>Receptor</TableCell>
								<TableCell>Objeto</TableCell>
								<TableCell>Última atividade</TableCell>
								{isAdmin && <TableCell>Ações</TableCell>}
							</TableRow>
						</TableHead>
						<TableBody>
							{(items || []).map((c, i) => (
								<TableRow key={i}>
									<TableCell>{ls[integrationType[c.type]]}</TableCell>
									<TableCell>
										{c.type
											? ls[integrationProvider[c.provider]]
											: (() => {
													let emails = c.emailTo?.split(";");

													if (!emails || emails.length === 1) return emails[0];
													return `${emails[0]} e + ${emails.length - 1}`;
											  })() ?? "Sem receptor"}
									</TableCell>
									<TableCell>{ls[integrationObject[c.object]]}</TableCell>
									<TableCell>
										{dayjs(c.updatedAt).format(ls.dateFormatWithoutSeconds)}
									</TableCell>
									{isAdmin && (
										<TableCell style={{ padding: 0 }}>
											{loadingSub["downloadFile"] === c.id ? (
												<Spinner color="secondary" size={16} />
											) : (
												!c.type && (
													<IconButton
														style={{
															padding: 8,
															fontSize: "1.3rem"
														}}
														onClick={() => setShowExportConsumption(c.id)}
													>
														<MdFileDownload />
													</IconButton>
												)
											)}

											{loadingSub["downloadFile"] === c.id ? (
												<Spinner color="secondary" size={16} />
											) : (
												!c.type && (
													<IconButton
														style={{
															padding: 8,
															fontSize: "1.3rem"
														}}
														onClick={() =>
															handleSendMail("showSendMailFormModal", c)
														}
													>
														<MdMail />
													</IconButton>
												)
											)}

											<IconButton
												style={{
													padding: 8,
													fontSize: "1.3rem"
												}}
												onClick={() => handleEdit("showEditFormModal", "", c)}
											>
												<MdEdit />
											</IconButton>
										</TableCell>
									)}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			</Paper>
			{isAdmin && <CreateForm />}
			{isAdmin && <EditForm />}
			<SendEmail />
			<ExportConsumption
				open={showExportConsumption}
				business={business}
				handleClose={() => setShowExportConsumption(false)}
				handleSubmit={handleDownloadFile}
			/>
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(Integrations);
