import { getErrors } from "helpers/error";
import _ from "lodash";
import * as api from "services/api";

export const EDIT_ORDER = "[ORDER EDIT] EDIT_ORDER";
export const EDIT_ORDER_SUCCESS = "[ORDER EDIT] EDIT_ORDER_SUCCESS";
export const EDIT_ORDER_FAILED = "[ORDER EDIT] EDIT_ORDER_FAILED";

export const SET_VALUE = "[ORDER EDIT] SET_VALUE";
export const CLEAR_VALUES = "[ORDER EDIT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function editOrder(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_ORDER });

		try {
			const { login, orderEdit } = getState();

			const { item, status } = orderEdit;

			const errors: any = {};

			dispatch(setValue({ errors }));

			if (_.keys(errors).length > 0) return;

			const response = await api.sendPut(`/order/${item.id}/${status}`, null, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				dispatch({
					type: EDIT_ORDER_SUCCESS
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_ORDER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_ORDER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
