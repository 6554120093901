import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ls from "Localization";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions/configuration/detail";

import EditConfiguration from "../Edit";
import styles from "./styles";

export function ConfigurationDetail({
	classes,
	state: {
		item
		// loading
	},
	clearValues,
	getConfiguration,
	match: {
		params: { id }
	}
}) {
	const [showEdit, setShowEdit] = useState(false);

	useEffect(() => {
		clearValues();
	}, [clearValues]);

	useEffect(() => {
		getConfiguration(id);
	}, [getConfiguration, id]);

	const handleCloseEdit = () => {
		getConfiguration(id);
		setShowEdit(false);
	};

	if (!item) return null;

	let { newConsumptionTemplate, consumptionReportTemplate, checkoutTemplate } =
		item;

	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper className={classes.root}>
					<div className={classes.header}>
						<Typography variant="h6">{ls.configuration}</Typography>
						<div>
							<Button color="primary" onClick={() => setShowEdit(true)}>
								{ls.edit}
							</Button>
						</div>
					</div>
					<hr className={classes.line} />

					<Grid container spacing={2} className={classes.form}>
						<Grid item xs={12} sm={6}>
							<strong>{ls.newConsumptionTemplate}</strong>:{" "}
							<div
								style={{
									overflow: "auto"
								}}
								dangerouslySetInnerHTML={{ __html: newConsumptionTemplate }}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<strong>{ls.consumptionReportTemplate}</strong>:{" "}
							<div
								style={{
									overflow: "auto"
								}}
								dangerouslySetInnerHTML={{ __html: consumptionReportTemplate }}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<strong>{ls.checkoutTemplate}</strong>:{" "}
							<div
								style={{
									overflow: "auto"
								}}
								dangerouslySetInnerHTML={{ __html: checkoutTemplate }}
							/>
						</Grid>
					</Grid>

					<EditConfiguration
						open={showEdit}
						item={item}
						handleClose={handleCloseEdit}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
}

const mapStateToProps = ({ configurationDetail: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = {
	...actions
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles, { withTheme: true })(ConfigurationDetail));
