import { getErrors } from "helpers/error";
import { toastWarning } from "helpers/toast";
import ls from "Localization";
import * as api from "services/api";
import { Callback } from "Types/Callback";

import { ActionPayload, ActionTypes, ITreasuryAccountTransfer } from "../types";

interface IState {
	treasuryAccountTransactionCreate: ITreasuryAccountTransfer;
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export const cancelTransfer =
	(id: string, callback?: Callback<ITreasuryAccountTransfer>) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.CANCEL_TREASURY_ACCOUNT_TRANSACTION_REQUEST });

		try {
			const { login } = getState();

			const response = await api.sendPut(
				`/Business/TreasuryAccount/Transfer/${id}/Cancel`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback();

				return dispatch({
					type: ActionTypes.CANCEL_TREASURY_ACCOUNT_TRANSACTION_SUCCESS
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			toastWarning("Erro ao baixar arquivo ZIP");

			return dispatch({
				type: ActionTypes.CANCEL_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};

type FileProps = {
	content: string;
	filename: string;
	type: string;
};

export const downloadTreasury =
	(id: string, callback?: Callback<ITreasuryAccountTransfer>) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/TreasuryAccount/Transfer/${id}/Download/Excel`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			const result = (await response.json()) as FileProps;

			let link = document.createElement("a"); // Create <a>
			link.href = `data:${result.type};base64,${result.content}`;
			link.download = result.filename;
			link.click();
		} catch (error) {
			toastWarning(ls.errorMsgGeneric);

			return dispatch({
				type: ActionTypes.CANCEL_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};

export const downloadTreasuryPdf =
	(id: string, callback?: Callback<ITreasuryAccountTransfer>) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/TreasuryAccount/Transfer/${id}/Download/Pdf`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			const result = await response.json();

			const dataUrl = atob(result.model.split("base64,").pop());

			const byteNumbers = new Array(dataUrl.length);
			for (let i = 0; i < dataUrl.length; i++) {
				byteNumbers[i] = dataUrl.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			const blob = new Blob([byteArray], { type: "application/pdf" });

			let data = window.URL.createObjectURL(blob);

			let link = document.createElement("a");
			link.href = data;
			link.download = `${Date.now()}.pdf`;
			setTimeout(() => {
				link.click();
				// Firefox, necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);
		} catch (error) {
			toastWarning(ls.errorMsgGeneric);

			return dispatch({
				type: ActionTypes.CANCEL_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};

export const downloadTreasuryZip =
	(id: string, callback?: Callback<ITreasuryAccountTransfer>) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Business/TreasuryAccount/Transfer/${id}/Download/Zip`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			const result = await response.json();

			const dataUrl = atob(result.model.split("base64,").pop());

			const byteNumbers = new Array(dataUrl.length);
			for (let i = 0; i < dataUrl.length; i++) {
				byteNumbers[i] = dataUrl.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);

			const blob = new Blob([byteArray], { type: "application/zip" });

			let data = window.URL.createObjectURL(blob);

			let link = document.createElement("a");
			link.href = data;
			link.download = `${Date.now()}.zip`;
			setTimeout(() => {
				link.click();
				// Firefox, necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);
		} catch (error) {
			toastWarning(ls.errorMsgGeneric);

			return dispatch({
				type: ActionTypes.CANCEL_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};
