import dayjs from "dayjs";
import * as actions from "store/actions/allowance";

import { IAllowance } from "./types";

const initialState: IAllowance = {
	items: [],
	storesTiny: [],
	item: null,
	name: "",
	companyId: "",
	categories: [],
	stores: [],
	users: [],
	days: [0, 1, 2, 3, 4, 5, 6].map(c => ({
		dayOfWeek: c,
		enable: true,
		start: "00:00",
		end: "23:59"
	})),
	baseValue: 0,
	limit: 0,
	maxLimit: 0,
	limitType: 0,
	status: 1,
	renewalCycle: 0,
	startDate: dayjs(),
	endDate: null,
	showFormModal: false,
	showEditFormModal: false,
	showDaysFormModal: false,
	showUserFormModal: false,
	showCategoryFormModal: false,
	showStoreFormModal: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_ALLOWANCE: {
			return {
				...state,
				loading: { get: true },
				errors: initialState.errors
			};
		}

		case actions.GET_ALLOWANCE_SUCCESS: {
			const newState = { ...state };

			if (payload) {
				newState.item = payload;
				newState.name = payload.name ?? initialState.name;
				newState.days = payload.days?.length ? payload.days : initialState.days;
				newState.users = payload.users?.length
					? payload.users
					: initialState.users;
				newState.categories = payload.categories?.length
					? payload.categories
					: initialState.categories;
				newState.stores = payload.stores?.length
					? payload.stores
					: initialState.stores;
				newState.baseValue = payload.baseValue / 100;
				newState.limit = payload.limit / 100;
				newState.maxLimit = payload.maxLimit / 100;
				newState.limitType = payload.limitType ?? initialState.limitType;
				newState.companyId = payload.companyId ?? initialState.companyId;
				newState.status = payload.status ?? initialState.status;
				newState.renewalCycle =
					payload.renewalCycle ?? initialState.renewalCycle;
				newState.startDate = payload.startDate ?? initialState.startDate;
				newState.endDate = payload.endDate ?? initialState.endDate;
			} else {
				newState.item = null;
				newState.days = initialState.days;
				newState.users = initialState.users;
				newState.categories = initialState.categories;
				newState.stores = initialState.stores;
				newState.baseValue = initialState.baseValue;
				newState.limit = initialState.limit;
				newState.maxLimit = initialState.maxLimit;
				newState.limitType = initialState.limitType;
				newState.companyId = initialState.companyId;
				newState.status = initialState.status;
				newState.renewalCycle = initialState.renewalCycle;
				newState.startDate = initialState.startDate;
				newState.endDate = initialState.endDate;
			}

			return {
				...newState,
				loading: { get: false }
			};
		}

		case actions.GET_ALLOWANCE_FAILED: {
			return {
				...state,
				loading: { get: false },
				errors: payload
			};
		}

		case actions.GET_ALLOWANCES: {
			return {
				...state,
				loading: { getAll: true },
				errors: initialState.errors
			};
		}

		case actions.GET_ALLOWANCES_SUCCESS: {
			return {
				...state,
				items: payload || [],
				loading: { getAll: false }
			};
		}

		case actions.GET_ALLOWANCES_FAILED: {
			return {
				...state,
				loading: { getAll: false },
				errors: payload
			};
		}

		case actions.GET_BUSINESS_TINY: {
			return {
				...state,
				loading: { getStore: true },
				errors: initialState.errors
			};
		}

		case actions.GET_BUSINESS_TINY_SUCCESS: {
			return {
				...state,
				storesTiny: payload || [],
				loading: { getStore: false }
			};
		}

		case actions.GET_BUSINESS_TINY_FAILED: {
			return {
				...state,
				loading: { getStore: false },
				errors: payload
			};
		}

		case actions.CREATE_ALLOWANCE: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}

		case actions.CREATE_ALLOWANCE_SUCCESS: {
			return {
				...state,
				loading: { create: false },
				items: [payload, ...state.items],
				item: null,
				days: initialState.days,
				users: initialState.users,
				categories: initialState.categories,
				stores: initialState.stores,
				baseValue: initialState.baseValue,
				limit: initialState.limit,
				limitType: initialState.limitType,
				companyId: initialState.companyId,
				status: initialState.status,
				renewalCycle: initialState.renewalCycle,
				startDate: initialState.startDate,
				endDate: initialState.endDate
			};
		}

		case actions.CREATE_ALLOWANCE_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}

		case actions.UPDATE_ALLOWANCE: {
			return {
				...state,
				loading: { update: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_ALLOWANCE_SUCCESS: {
			let items = state.items.filter(c => c.id !== payload.id);

			items.unshift(payload);

			return {
				...state,
				loading: { update: false },
				item: null,
				items,
				days: initialState.days,
				users: initialState.users,
				categories: initialState.categories,
				stores: initialState.stores,
				baseValue: initialState.baseValue,
				limit: initialState.limit,
				maxLimit: initialState.maxLimit,
				limitType: initialState.limitType,
				companyId: initialState.companyId,
				status: initialState.status,
				renewalCycle: initialState.renewalCycle,
				startDate: initialState.startDate,
				endDate: initialState.endDate
			};
		}

		case actions.UPDATE_ALLOWANCE_FAILED: {
			return {
				...state,
				loading: { update: false },
				errors: payload
			};
		}

		case actions.UPDATE_ALLOWANCE_DAYS: {
			return {
				...state,
				loading: { days: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_ALLOWANCE_DAYS_SUCCESS: {
			return {
				...state,
				loading: { days: false }
			};
		}

		case actions.UPDATE_ALLOWANCE_DAYS_FAILED: {
			return {
				...state,
				loading: { days: false },
				errors: payload
			};
		}

		case actions.UPDATE_ALLOWANCE_USERS: {
			return {
				...state,
				loading: { users: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_ALLOWANCE_USERS_SUCCESS: {
			return {
				...state,
				loading: { users: false }
			};
		}

		case actions.UPDATE_ALLOWANCE_USERS_FAILED: {
			return {
				...state,
				loading: { users: false },
				errors: payload
			};
		}

		case actions.UPDATE_ALLOWANCE_USER: {
			return {
				...state,
				loading: { user: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_ALLOWANCE_USER_SUCCESS: {
			return {
				...state,
				loading: { user: false }
			};
		}

		case actions.UPDATE_ALLOWANCE_USER_FAILED: {
			return {
				...state,
				loading: { user: false },
				errors: payload
			};
		}

		case actions.UPDATE_ALLOWANCE_CATEGORIES: {
			return {
				...state,
				loading: { categories: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_ALLOWANCE_CATEGORIES_SUCCESS: {
			return {
				...state,
				loading: { categories: false }
			};
		}

		case actions.UPDATE_ALLOWANCE_CATEGORIES_FAILED: {
			return {
				...state,
				loading: { categories: false },
				errors: payload
			};
		}

		case actions.UPDATE_ALLOWANCE_STORES: {
			return {
				...state,
				loading: { stores: true },
				errors: initialState.errors
			};
		}

		case actions.UPDATE_ALLOWANCE_STORES_SUCCESS: {
			return {
				...state,
				loading: { stores: false }
			};
		}

		case actions.UPDATE_ALLOWANCE_STORES_FAILED: {
			return {
				...state,
				loading: { stores: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null,
				name: initialState.name,
				days: initialState.days,
				users: initialState.users,
				categories: initialState.categories,
				stores: initialState.stores,
				baseValue: initialState.baseValue,
				limit: initialState.limit,
				maxLimit: initialState.maxLimit,
				limitType: initialState.limitType,
				companyId: initialState.companyId,
				status: initialState.status,
				renewalCycle: initialState.renewalCycle,
				startDate: initialState.startDate,
				endDate: initialState.endDate
			};

		default: {
			return state;
		}
	}
};

export default reducer;
