import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "store/actions/user/detail";

import useStyles from "./styles";

let debounceChangePassword: (() => void) & _.Cancelable = null;

function ChangePassword({ open, item, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { loading } = useSelector<any, any>(s => s.userDetail);

	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (open) {
			setPassword("");
			setConfirmPassword("");
			setErrors({});
		}
	}, [open]);

	const onSubmit = e => {
		e.preventDefault();

		const errors: any = {};

		if (!password) errors.password = ls.required;
		else if (password.length < 6)
			errors.password = ls.formatString(ls.minLength, 6);
		if (password !== confirmPassword) errors.confirmPassword = ls.notMatch;

		setErrors(errors);

		if (Object.keys(errors).length > 0) return;

		if (debounceChangePassword) debounceChangePassword.cancel();

		debounceChangePassword = _.debounce(() => {
			dispatch(
				changePassword(item.id, password, confirmPassword, (err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						setPassword("");
						setConfirmPassword("");
						setErrors({});
						handleClose();
					}
				})
			);
		}, 1000);

		debounceChangePassword();
	};

	return (
		<Modal
			aria-labelledby="change-user-password-modal-title"
			aria-describedby="change-user-password-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.changePassword}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextInput
										id="password"
										required
										variant="outlined"
										name="password"
										value={password}
										type="password"
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={(_, v) => setPassword(v)}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextInput
										id="confirmPassword"
										required
										variant="outlined"
										name="confirmPassword"
										value={confirmPassword}
										type="password"
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={(_, v) => setConfirmPassword(v)}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.password}
								>
									{ls.save}
									{loading.password && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ChangePassword;
