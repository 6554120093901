import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_ACCESSIONS = "[ACCESSION] GET_ACCESSIONS";
export const GET_ACCESSIONS_SUCCESS = "[ACCESSION] GET_ACCESSIONS_SUCCESS";
export const GET_ACCESSIONS_FAILED = "[ACCESSION] GET_ACCESSIONS_FAILED";

export const UPDATE_ACCESSION_STATUS = "[ACCESSION] UPDATE_ACCESSION_STATUS";
export const UPDATE_ACCESSION_STATUS_SUCCESS =
	"[ACCESSION] UPDATE_ACCESSION_STATUS_SUCCESS";
export const UPDATE_ACCESSION_STATUS_FAILED =
	"[ACCESSION] UPDATE_ACCESSION_STATUS_FAILED";

export const CREATE_ACCESSION = "[ACCESSION] CREATE_ACCESSION";
export const CREATE_ACCESSION_SUCCESS = "[ACCESSION] CREATE_ACCESSION_SUCCESS";
export const CREATE_ACCESSION_FAILED = "[ACCESSION] CREATE_ACCESSION_FAILED";

export const UPDATE_ACCESSION = "[ACCESSION] UPDATE_ACCESSION";
export const UPDATE_ACCESSION_SUCCESS = "[ACCESSION] UPDATE_ACCESSION_SUCCESS";
export const UPDATE_ACCESSION_FAILED = "[ACCESSION] UPDATE_ACCESSION_FAILED";

export const DELETE_ACCESSION = "[ACCESSION] DELETE_ACCESSION";
export const DELETE_ACCESSION_SUCCESS = "[ACCESSION] DELETE_ACCESSION_SUCCESS";
export const DELETE_ACCESSION_FAILED = "[ACCESSION] DELETE_ACCESSION_FAILED";

export const SET_VALUE = "[ACCESSION] SET_VALUE";
export const CLEAR_VALUES = "[ACCESSION] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getAccessions(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_ACCESSIONS });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Accession?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_ACCESSIONS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_ACCESSIONS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_ACCESSIONS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAccessionStatus(id, status, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ACCESSION_STATUS });

		try {
			const { login } = getState();

			const response = await api.sendPut(`/Accession/${id}/${status}`, null, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ACCESSION_STATUS_SUCCESS
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ACCESSION_STATUS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_ACCESSION_STATUS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createAccession(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_ACCESSION });

		try {
			const { login, accession, businessDetail } = getState();

			const { userId, email, status } = accession;

			const errors: any = {};

			if (!userId) errors.userId = ls.required;
			if (!email) errors.email = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_ACCESSION_FAILED,
					payload: errors
				});
			}

			const model = {
				userId,
				email,
				storeId: businessDetail.item.id,
				status
			};

			const response = await api.sendPost("/Accession", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_ACCESSION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_ACCESSION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_ACCESSION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editAccession(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_ACCESSION });

		try {
			const { login, accession } = getState();

			const { item, email, status } = accession;

			const errors: any = {};

			if (!email) errors.email = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_ACCESSION_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				email,
				status,
				user: undefined,
				updatedBy: undefined,
				store: undefined,
				createdBy: undefined
			};

			const response = await api.sendPut("/Accession/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_ACCESSION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_ACCESSION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_ACCESSION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteAccession(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_ACCESSION });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/Accession/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: DELETE_ACCESSION_SUCCESS,
					payload: id
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_ACCESSION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: DELETE_ACCESSION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
