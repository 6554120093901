import * as actions from "store/actions/configuration/edit";

import { IConfigurationEdit } from "./types";

const initialState: IConfigurationEdit = {
	item: null,
	newConsumptionTemplate: "",
	consumptionReportTemplate: "",
	checkoutTemplate: "",
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.EDIT_CONFIGURATION: {
			return {
				...state,
				loading: { edit: true },
				errors: initialState.errors
			};
		}
		case actions.EDIT_CONFIGURATION_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.EDIT_CONFIGURATION_FAILED: {
			return {
				...state,
				loading: { edit: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
