import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { toStringCurrency, toStringPercentage } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import installmentPaymentType from "models/installmentPaymentType";
import React, { useCallback, useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import * as actions from "store/actions/installment/report";

dayjs.extend(customParseFormat);

let getInstallmentsDebounced: (() => void) & _.Cancelable = null;

function InstallmentReport({
	state,
	login: { user },
	getInstallments,
	setValue,
	params
}) {
	const table = useRef(null);
	const history = useHistory();
	const isAdmin = isInRole(user, ["admin"]);

	let columns = useMemo(
		() => [
			{
				Header: ls.purchaseDate,
				id: "purchaseDate",
				accessor: c =>
					dayjs(c.sale?.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 150,
				show: false,
				sortable: false,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					// if (desc) return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? -1 : 1;

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				filterable: false
			},
			{
				Header: isAdmin ? ls.paymentDay : "Data",
				id: "payDate",
				accessor: c => dayjs(c.payDate).format(ls.dateFormatShort),
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatShort);
					let bD = dayjs(b, ls.dateFormatShort);

					// if (desc) return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? -1 : 1;

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter,
				ignoreMaxDate: true
			},
			{
				Header: ls.sale,
				id: "code",
				accessor: c => `${c.sale?.code ?? ""}`,
				sortable: false,
				show: true,
				width: 80
			},
			{
				Header: ls.client,
				id: "client",
				accessor: c => c.user.fullName,
				show: true,
				width: 150
			},
			{
				Header: ls.cpf,
				id: "cpf",
				accessor: c => c.user.cpf,
				show: false,
				width: 120
			},
			{
				Header: ls.store,
				id: "store",
				accessor: c => c.sale?.store?.name ?? ls.none,
				sortable: false,
				show: true,
				width: 180
			},
			{
				Header: ls.business,
				id: "business",
				accessor: c => c.company?.name ?? ls.none,
				show: true,
				width: 180
			},
			{
				Header: ls.cnpj,
				id: "company.cnpj",
				accessor: c => c.company?.cnpj ?? ls.notDefined,
				show: true,
				width: 180
			},
			{
				Header: ls.value,
				id: "value",
				accessor: c => toStringCurrency(c.value / 100),
				format: c => c.value / 100,
				Footer: () => <b>{toStringCurrency(state.value / 100)}</b>,
				show: true,
				width: 100
			},
			{
				Header: ls.fee,
				id: "fee",
				format: c => c.fee / 100,
				accessor: c =>
					`${toStringCurrency(c.fee / 100)}(${toStringPercentage(
						c.fee,
						c.value
					)})`,
				Footer: () => (
					<b>
						{toStringCurrency(state.fee / 100)}(
						{toStringPercentage(state.fee, state.value)})
					</b>
				),
				show: true,
				width: 150
			},
			{
				Header: ls.paymentType,
				id: "paymentType",
				sortable: false,
				show: true,
				accessor: c => ls[installmentPaymentType[c.paymentType]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(installmentPaymentType).map(c => (
							<option key={c} value={c}>
								{ls[installmentPaymentType[c]]}
							</option>
						))}
					</select>
				),
				width: 180
			},
			{
				Header: ls.installment,
				id: "installment",
				show: true,
				accessor: c => c.number + 1
			},
			{
				Header: ls.installments,
				id: "installmentAmount",
				sortable: false,
				show: true,
				accessor: c => c.sale?.installmentAmount ?? ""
			}
		],
		[isAdmin, state.fee, state.value]
	);

	const [isWiipoFlex, isWiipoClub] = useMemo(() => {
		const { wiipoFlex, wiipoClub } = params || {};

		return [wiipoFlex, wiipoClub];
	}, [params]);

	const handleFetchData = useCallback(
		tableState => {
			let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

			if (getInstallmentsDebounced) {
				getInstallmentsDebounced.cancel();
			}

			if (!sorted) sorted = [];

			let payDate = filtered.find(c => c.id === "payDate");

			if (payDate?.value) {
				filtered = filtered.filter(c => c.id !== "payDate");

				if (payDate?.value.startDate) {
					filtered.push({
						id: "startDate",
						value: dayjs(payDate.value.startDate).format("YYYY-MM-DD")
					});
				}

				if (payDate?.value.endDate)
					filtered.push({
						id: "endDate",
						value: dayjs(payDate.value.endDate).format("YYYY-MM-DD")
					});
			}

			const isExistStoreNotIn = filtered.filter(i => i.id === "store.not.in");
			if (isWiipoClub && isExistStoreNotIn.length === 0) {
				filtered.push({
					id: "store.not.in",
					value: "11562d48-c64a-4eed-b1aa-08d9875944c4"
				});
			}

			getInstallmentsDebounced = _.debounce(
				() =>
					getInstallments(
						page * pageSize,
						pageSize,
						filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
						sorted[0]?.id,
						sorted[0]?.desc,
						toExport,
						callback
					),
				500
			);

			getInstallmentsDebounced();
		},
		[isWiipoClub]
	);

	if (!isAdmin) {
		columns = columns.filter(c => c.id !== "fee");
	}

	return (
		<div>
			<Helmet>
				<title>
					{isAdmin ? ls.installments : "Atividade"} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={isAdmin ? ls.installments : "Atividade"}
				data={state.items}
				pages={state.pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				onRowClicked={row => history.push(`/Transaction/${row.saleId}`)}
				onFilteredChange={(filter, c, v) => {
					setValue({ filter });
				}}
				useQueryString
				defaultFiltered={state.filter}
				loading={state.loading}
				defaultSorted={[
					{
						id: "payDate",
						desc: true
					}
				]}
			/>
		</div>
	);
}

const mapStateToProps = ({ installmentReport: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(InstallmentReport);
