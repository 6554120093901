import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "Components/Checkbox";
import { MONEY } from "Components/NumberFormat";
import { PERCENTAGE2 } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import valueType from "models/valueType";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editConsumptionPolicy,
	setValue
} from "store/actions/consumptionPolicy";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceEditForm: (() => void) & _.Cancelable = null;

function EditForm({ load }) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const {
		showEditFormModal,
		name,
		main,
		onBoarding,
		limit,
		maxLimit,
		maxInstallment,
		minTimeSinceAdmission,
		maxTimeSinceAdmission,
		limitType,
		errors,
		loading
	} = useSelector<any, any>(c => c.consumptionPolicy);

	const handleChange = (id: string, value: any) => {
		const values = { [id]: value };

		if (id === "main" && value) {
			values["limitType"] = 0;
		}

		dispatch(setValue(values));
	};

	const handleClose = () => dispatch(clearValues());

	const onSubmit = e => {
		e.preventDefault();

		if (debounceEditForm) debounceEditForm.cancel();

		debounceEditForm = _.debounce(() => {
			dispatch(
				editConsumptionPolicy(err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						load();
						handleClose();
						dispatch(clearValues());
					}
				})
			);
		}, 1000);

		debounceEditForm();
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={showEditFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showEditFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.editConsumptionPolicy}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Checkbox
										id="main"
										name="main"
										checked={main}
										onChange={handleChange}
									/>
									<Checkbox
										id="onBoarding"
										name="onBoarding"
										checked={onBoarding}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<TextInput
										id="name"
										required
										variant="outlined"
										name="name"
										value={name}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								{!main && (
									<Grid item xs={12} sm={6} md={6} lg={+limitType ? 3 : 4}>
										<Select
											errors={errors}
											id="limitType"
											name="limitType"
											value={limitType}
											onChange={handleChange}
											options={Object.keys(valueType).map(c => ({
												id: c,
												value: ls[valueType[c]]
											}))}
										/>
									</Grid>
								)}
								<Grid item xs={12} sm={6} md={6} lg={+limitType ? 2 : 4}>
									<TextInput
										id="limit"
										required
										variant="outlined"
										name="limit"
										InputProps={{
											inputComponent: +limitType ? PERCENTAGE2 : MONEY
										}}
										value={limit}
										errors={errors}
										onFocus={e => e.target.select()}
										onChange={handleChange}
									/>
								</Grid>
								{+limitType || main ? (
									<Grid item xs={12} sm={6} md={6} lg={main ? 4 : 3}>
										<TextInput
											id="maxLimit"
											required
											variant="outlined"
											name="maxLimit"
											InputProps={{
												inputComponent: MONEY
											}}
											value={maxLimit}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={handleChange}
										/>
									</Grid>
								) : null}
								{main && (
									<>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<TextInput
												id="maxInstallment"
												required
												variant="outlined"
												name="maxInstallment"
												InputProps={{
													inputProps: {
														step: 1,
														min: 0,
														max: 12
													}
												}}
												value={maxInstallment}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<TextInput
												id="minTimeSinceAdmission"
												required
												variant="outlined"
												name="minTimeSinceAdmission"
												InputProps={{
													inputProps: {
														step: 1,
														min: 0
													}
												}}
												value={minTimeSinceAdmission}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6} lg={4}>
											<TextInput
												id="maxTimeSinceAdmission"
												variant="outlined"
												name="maxTimeSinceAdmission"
												InputProps={{
													inputProps: {
														step: 1,
														min: 0
													}
												}}
												value={maxTimeSinceAdmission}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={handleChange}
											/>
										</Grid>
									</>
								)}
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.update}
								>
									{ls.save}
									{loading.update && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditForm;
