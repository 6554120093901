import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import React from "react";

import {
	ButtonCancel,
	ButtonTransfer,
	Container,
	RowButtons,
	TextBold,
	TextCenter
} from "./styles";

interface IPropsModalConfirmation {
	open: boolean;
	loading: boolean;
	value: number;
	businessId?: string;
	business: string;
	cnpj: string;
	type: number;
	handleClose: () => void;
	onCreateTransfer: () => void;
}

const ModalConfirmation: React.FC<IPropsModalConfirmation> = ({
	open,
	loading,
	businessId,
	handleClose,
	onCreateTransfer,
	value,
	business,
	cnpj,
	type
}) => (
	<ModalGeneric
		open={open}
		stylePaper={{ width: "70%" }}
		handleClose={handleClose}
		body={() => (
			<Container>
				<TextCenter>
					Tem certeza que deseja transferir{" "}
					<TextBold>{toStringCurrency(value)}</TextBold> da conta{" "}
					{type ? (
						<span>
							tesouraria da empresa{" "}
							<TextBold>
								{business}. CNPJ {cnpj || ""}
							</TextBold>
						</span>
					) : (
						"Wiipo"
					)}{" "}
					para a{" "}
					{type ? (
						"Wiipo"
					) : (
						<span>
							tesouraria da empresa{" "}
							<TextBold>
								{business}. CNPJ {cnpj || ""}?
							</TextBold>
						</span>
					)}
					?
				</TextCenter>

				<RowButtons>
					<ButtonCancel disabled={loading} onClick={handleClose}>
						{ls.cancel}
					</ButtonCancel>
					<ButtonTransfer
						disabled={loading}
						backgroudLoading={loading}
						onClick={onCreateTransfer}
					>
						{loading ? <Spinner color="inherit" size={18} /> : ls.transfer}
					</ButtonTransfer>
				</RowButtons>
			</Container>
		)}
	/>
);

export default ModalConfirmation;
