import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import clsx from "clsx";
import { MONEY, PERCENTAGE2 } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import RenewalCycle from "models/renewalCycle";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUserBenefits } from "store/actions/user/detail";

import useStyles from "./styles";

let debounceEditUserBenefits: (() => void) & _.Cancelable = null;

function EditUserBenefits({
	open,
	item,
	handleClose,
	isAdmin,
	isBusinessAdmin
}) {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { loading } = useSelector<any, any>(s => s.userDetail);

	const [limit, setLimit] = useState(0);
	const [renewalCycle, setRenewalCycle] = useState("3");
	const [renewalDay, setRenewalDay] = useState(20);
	const [percentClubWiipo, setPercentClubWiipo] = useState(0);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (open && item) {
			setLimit(item.limit / 100.0);
			setRenewalDay(item.renewalDay);
			setRenewalCycle(item.renewalCycle);
			setPercentClubWiipo(item.percentClubWiipo / 100.0);
		}
	}, [open, item]);

	const onSubmit = e => {
		e.preventDefault();

		const errors: any = {};

		if (!limit && limit !== 0) errors.limit = ls.required;

		if (isAdmin) {
			if (!renewalDay) errors.renewalDay = ls.required;
			else if (renewalDay <= 0 || renewalDay >= 31)
				errors.renewalDay = ls.invalid;
		}

		setErrors(errors);
		if (Object.keys(errors).length > 0) return;

		if (debounceEditUserBenefits) debounceEditUserBenefits.cancel();

		debounceEditUserBenefits = _.debounce(() => {
			dispatch(
				editUserBenefits(
					{
						...(item || {}),
						limit: Math.round(limit * 100),
						renewalDay,
						renewalCycle,
						percentClubWiipo
					},
					err => {
						if (err) {
							console.log(err);
							if (err.default) alert(err.default);
							else if (typeof err === "string") alert(err);
							else alert(JSON.stringify(err));
						} else {
							handleClose();
						}
					}
				)
			);
		}, 1000);

		debounceEditUserBenefits();
	};

	return (
		<Modal
			aria-labelledby="edit-user-benefits-modal-title"
			aria-describedby="edit-user-benefits-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div
					className={clsx([classes.paper, { [classes.paper_sm]: !isAdmin }])}
				>
					<Typography variant="h6">{ls.editBenefits}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								{(isAdmin || isBusinessAdmin) && (
									<Grid
										item
										xs={12}
										sm={isAdmin ? 6 : isBusinessAdmin ? 6 : 12}
										md={isAdmin ? 3 : isBusinessAdmin ? 6 : 12}
									>
										<TextInput
											id="limit"
											required
											variant="outlined"
											name="limit"
											InputProps={{
												inputComponent: MONEY
											}}
											value={limit}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={(_, v) => setLimit(+v)}
										/>
									</Grid>
								)}
								{isAdmin && (
									<>
										<Grid item xs={12} sm={6} md={4}>
											<Select
												errors={errors}
												id="renewalCycle"
												name="renewalCycle"
												value={renewalCycle}
												onChange={(_, v) => setRenewalCycle(v)}
												options={Object.keys(RenewalCycle).map(c => ({
													id: c,
													value: ls[RenewalCycle[c]]
												}))}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={4}>
											<TextInput
												id="renewalDay"
												required
												variant="outlined"
												name="renewalDayM"
												InputProps={{
													inputProps: {
														step: 1,
														min: 1,
														max: 30
													}
												}}
												type="number"
												value={renewalDay}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={(_, v) => setRenewalDay(+v)}
											/>
										</Grid>
									</>
								)}
								{isBusinessAdmin && (
									<Grid item xs={12} sm={6} md={6}>
										<TextInput
											id="percentClubWiipo"
											variant="outlined"
											name="percentClubWiipo"
											InputProps={{
												inputComponent: PERCENTAGE2
											}}
											value={percentClubWiipo}
											errors={errors}
											onFocus={e => e.target.select()}
											onChange={(_, v) => setPercentClubWiipo(+v)}
										/>
									</Grid>
								)}
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.benefits}
								>
									{ls.save}
									{loading.benefits && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default EditUserBenefits;
