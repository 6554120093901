import { Button } from "@material-ui/core";
import ModalGeneric from "Components/Modals/ModalGeneric";
import { copyTextToClipboard } from "helpers/string";
import ls from "Localization";
import React from "react";
import { FiCopy } from "react-icons/fi";
import { MdFileDownload } from "react-icons/md";

import {
	BackgroundButton,
	ColumnsText,
	Container,
	RowContainer,
	Text,
	Title
} from "./styles";

interface IPropsModal {
	open: boolean;
	handleClose: () => void;
	digitableLine: string;
	pdfLink: string;
}

const ModalPdf: React.FC<IPropsModal> = ({
	open,
	handleClose,
	pdfLink,
	digitableLine
}) => (
	<ModalGeneric
		open={open}
		handleClose={handleClose}
		stylePaper={{ width: "27%", borderRadius: 15, backgroundColor: "#fff" }}
		body={() => (
			<Container>
				<Title>{ls.ticket}</Title>
				<RowContainer>
					<ColumnsText>
						<Text>Compensação em 3 dias úteis após o pagamento</Text>
					</ColumnsText>
				</RowContainer>

				<BackgroundButton>
					<Button
						color="primary"
						size="medium"
						variant="contained"
						type="submit"
						onClick={() => window.open(pdfLink, "_blank")}
						style={{
							fontSize: 11,
							borderRadius: 6,
							fontWeight: "bold",
							width: 262,
							marginRight: 12,
							height: 50
						}}
					>
						<MdFileDownload color="#fff" size={18} style={{ margin: 8 }} />
						Baixar boleto
					</Button>
					<Button
						color="primary"
						size="medium"
						variant="contained"
						type="submit"
						onClick={() => {
							copyTextToClipboard(
								() => {},
								digitableLine,
								"Linha digitável copiada com sucesso"
							);
						}}
						style={{
							fontSize: 11,
							borderRadius: 6,
							width: 352,
							marginLeft: 11,
							fontWeight: "bold",
							height: 50
						}}
					>
						<FiCopy color="#fff" size={18} style={{ margin: 8 }} />
						Copiar linha digitável
					</Button>
				</BackgroundButton>
			</Container>
		)}
	/>
);

export default ModalPdf;
