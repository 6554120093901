const jsonwebtoken = require("jsonwebtoken");

export function isLogged(login: any) {
	const { token, user } = login;

	if (token) {
		const decodedToken = jsonwebtoken.decode(token, { complete: true });

		if (
			decodedToken &&
			user &&
			decodedToken.payload[
				"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
			] === user.id
		) {
			return true;
		}
	}
	return false;
}

export const isInRole = (user: any, roles: any[]) => {
	if (!user) return false;

	if (!user.roles) return false;

	if (
		roles.map(c => c.toUpperCase()).includes("Admin Wiipo Flex".toUpperCase())
	) {
		if (user?.business?.business?.hasWiipoFlex) return true;
	}

	if (
		roles.map(c => c.toUpperCase()).includes("Admin Wiipo Club".toUpperCase())
	) {
		if (user?.business?.business?.hasWiipoClub) return true;
	}

	let role = user.roles
		.map((r: any) => r.role.normalizedName)
		.find(c => roles.find(ro => ro.toUpperCase() === c));

	return Boolean(role);
};
