import { Grid } from "@material-ui/core";
import Select from "Components/Select";
import TextInput from "Components/TextInput";
import ls from "Localization";
import pushActionType from "models/pushActionType";
import React from "react";
import useStyles from "views/Dashboard/Marketing/Create/styles";

import TabPanel from "../TabPanel";

interface PushTabProps {
	index: number;
	tab: number;
	handleChange: (key: any, value: any) => void;
	errors: any;
	pushTitle: string;
	pushMessage: string;
	pushAction: string;
	pushValue: string;
}

const PushTab: React.FC<PushTabProps> = ({
	errors,
	handleChange,
	index,
	tab,
	pushAction,
	pushMessage,
	pushTitle,
	pushValue
}) => {
	const classes = useStyles({});

	return (
		<TabPanel value={tab} index={index}>
			<Grid container spacing={2} className={classes.form}>
				<Grid item xs={12}>
					<TextInput
						id="pushTitle"
						required
						variant="outlined"
						name="pushTitle"
						value={pushTitle}
						errors={errors}
						onChange={handleChange}
						FormHelperTextProps={{ style: { textAlign: "right" } }}
						helperText={`${pushTitle.length ?? 0}/44`}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextInput
						id="pushMessage"
						required
						variant="outlined"
						name="pushMessage"
						value={pushMessage}
						errors={errors}
						multiline
						rows={6}
						onChange={handleChange}
						FormHelperTextProps={{ style: { textAlign: "right" } }}
						helperText={`${pushMessage.length ?? 0}/178`}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Select
						errors={errors}
						id="pushAction"
						name="action"
						required
						value={pushAction}
						onChange={handleChange}
						options={Object.keys(pushActionType).map(c => ({
							id: c,
							value: ls[pushActionType[c]]
						}))}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextInput
						id="pushValue"
						variant="outlined"
						name="value"
						value={pushValue}
						errors={errors}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
		</TabPanel>
	);
};

export default PushTab;
