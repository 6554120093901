import * as actions from "../../actions/business";

const initialState = {
	loading: false,
	business: null,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.GET_USER_BUSINESS: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.GET_USER_BUSINESS_SUCCESS: {
			return {
				...state,
				loading: false,
				business: payload
			};
		}
		case actions.GET_USER_BUSINESS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
