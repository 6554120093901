import * as actions from "store/actions/business/create";

import { IBusinessCreate } from "../types/create";

const initialState: IBusinessCreate = {
	name: "",
	socialName: "",
	cnpj: "",
	phoneNumber: "",
	isHcmPartial: false,
	category: "",
	street: "",
	number: "",
	complement: "",
	neighborhood: "",
	city: "",
	state: "",
	zipCode: "",
	country: "BR",
	status: "",
	type: "",
	about: "",
	teaser: "",
	image: null,
	highlight: false,
	national: false,
	isStore: false,
	isCompany: true,
	isHeadquarters: false,
	isFranchise: false,
	hasWiipoIntegrator: false,
	hasWiipoRequest: false,
	loading: false,
	hasHCMTotal: false,
	hasHCMParcial: false,
	link: "",
	linkType: "",
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_BUSINESS: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.CREATE_BUSINESS_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_BUSINESS_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
