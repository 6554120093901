export enum ActionTypes {
	CREATE_FUNDS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] CREATE_FUNDS_IN_WIIPOFLEX",
	CREATE_FUNDS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] CREATE_FUNDS_IN_WIIPOFLEX_SUCCESS",
	CREATE_FUNDS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] CREATE_FUNDS_IN_WIIPOFLEX_FAILED",

	CREATE_NF_FUNDS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] CREATE_NF_FUNDS_IN_WIIPOFLEX",
	CREATE_NF_FUNDS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] CREATE_NF_FUNDS_IN_WIIPOFLEX_SUCCESS",
	CREATE_NF_FUNDS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] CREATE_NF_FUNDS_IN_WIIPOFLEX_FAILED",

	EDIT_FUNDS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] EDIT_FUNDS_IN_WIIPOFLEX",
	EDIT_FUNDS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] EDIT_FUNDS_IN_WIIPOFLEX_SUCCESS",
	EDIT_FUNDS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] EDIT_FUNDS_IN_WIIPOFLEX_FAILED",

	EDIT_FUNDS_STATUS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] EDIT_FUNDS_STATUS_IN_WIIPOFLEX",
	EDIT_FUNDS_STATUS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] EDIT_FUNDS_STATUS_IN_WIIPOFLEX_SUCCESS",
	EDIT_FUNDS_STATUS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] EDIT_FUNDS_STATUS_IN_WIIPOFLEX_FAILED",

	EDIT_FUNDS_DUEDATE_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] EDIT_FUNDS_DUEDATE_IN_WIIPOFLEX",
	EDIT_FUNDS_DUEDATE_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] EDIT_FUNDS_DUEDATE_IN_WIIPOFLEX_SUCCESS",
	EDIT_FUNDS_DUEDATE_IN_WIIPOFLEX_FAILED = "[FUNDS IN] EDIT_FUNDS_DUEDATE_IN_WIIPOFLEX_FAILED",

	GET_FUNDS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] GET_FUNDS_IN_WIIPOFLEX_REQUEST",
	GET_FUNDS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] GET_FUNDS_IN_WIIPOFLEX_SUCCESS",
	GET_FUNDS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] GET_FUNDS_IN_WIIPOFLEX_FAILED",

	GET_PMA_TRANSFER_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] GET_PMA_TRANSFER_IN_WIIPOFLEX_REQUEST",
	GET_PMA_TRANSFER_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] GET_PMA_TRANSFER_IN_WIIPOFLEX_SUCCESS",
	GET_PMA_TRANSFER_IN_WIIPOFLEX_FAILED = "[FUNDS IN] GET_PMA_TRANSFER_IN_WIIPOFLEX_FAILED",

	CANCEL_FUNDS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] CANCEL_FUNDS_IN_WIIPOFLEX_REQUEST",
	CANCEL_FUNDS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] CANCEL_FUNDS_IN_WIIPOFLEX_FAILED",
	CANCEL_FUNDS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] CANCEL_FUNDS_IN_WIIPOFLEX_SUCCESS",

	REPROCESS_FUNDS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] REPROCESS_FUNDS_IN_WIIPOFLEX_REQUEST",
	REPROCESS_FUNDS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] REPROCESS_FUNDS_IN_WIIPOFLEX_FAILED",
	REPROCESS_FUNDS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] REPROCESS_FUNDS_IN_WIIPOFLEX_SUCCESS",

	CREATE_TRANSFER_FUNDS_IN_WIIPOFLEX_REQUEST = "[FUNDS IN] CREATE_TRANSFER_FUNDS_IN_WIIPOFLEX",
	CREATE_TRANSFER_FUNDS_IN_WIIPOFLEX_SUCCESS = "[FUNDS IN] CREATE_TRANSFER_FUNDS_IN_WIIPOFLEX_SUCCESS",
	CREATE_TRANSFER_FUNDS_IN_WIIPOFLEX_FAILED = "[FUNDS IN] CREATE_TRANSFER_FUNDS_IN_WIIPOFLEX_FAILED",

	GET_FUNDS_IN_QRCODEPIX_REQUEST = "[FUNDS IN] GET_FUNDS_IN_QRCODEPIX_REQUEST",
	GET_FUNDS_IN_QRCODEPIX_SUCCESS = "[FUNDS IN] GET_FUNDS_IN_QRCODEPIX_SUCCESS",
	GET_FUNDS_IN_QRCODEPIX_FAILED = "[FUNDS IN] GET_FUNDS_IN_QRCODEPIX_FAILED",

	EXPORT_FUND_REPORT = "[FUNDS IN] EXPORT_FUND_REPORT",

	SET_VALUE = "[FUNDS IN] SET_VALUE",
	CLEAR_VALUES = "[FUNDS IN] CLEAR_VALUES"
}

export interface IFundsPrePaidBalanceType {
	PrePaidBalanceAccountTypeId: string;
	Value: number;
}

export interface ITreasuryAccountWiipoFlex {
	value: number;
	dueDate: number;
	cnpj: string;
	fundsPrePaidBalanceType: IFundsPrePaidBalanceType[];
}

export interface ITreasuryAccountWiipoFlexEdit {
	id: string;
	value: number;
	dueDate: number;
	status: number;
}

export interface IParams {
	count?: number;
	totalBalance?: number;
}

export interface ICreateTransfer {
	businessId: string;
	value: number;
	type: number;
	errors: any;
	loading: boolean;
}

export interface ITreasuryAccountWiipoFlexState {
	items: ITreasuryAccountWiipoFlex[];
	count: number;
	totalBalance?: number;
	totalValue?: number;
	pages: number;
	loading: boolean;
	errors: {};
}

export interface ActionPayload {
	type: string;

	payload?: {
		item?: ITreasuryAccountWiipoFlex;
		items?: ITreasuryAccountWiipoFlex[];
		params?: IParams;
		id?: string;
	};
}
