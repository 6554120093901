import { isInRole } from "helpers/auth";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_PRODUCTS = "[PRODUCT] GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "[PRODUCT] GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILED = "[PRODUCT] GET_PRODUCTS_FAILED";

export const GET_PRODUCT = "[PRODUCT] GET_PRODUCT";
export const GET_PRODUCT_SUCCESS = "[PRODUCT] GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAILED = "[PRODUCT] GET_PRODUCT_FAILED";

export const CREATE_PRODUCT = "[PRODUCT] CREATE_PRODUCT";
export const CREATE_PRODUCT_SUCCESS = "[PRODUCT] CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILED = "[PRODUCT] CREATE_PRODUCT_FAILED";

export const UPDATE_PRODUCT = "[PRODUCT] UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "[PRODUCT] UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILED = "[PRODUCT] UPDATE_PRODUCT_FAILED";

export const UPLOAD_PRODUCT_PHOTOS = "[PRODUCT] UPLOAD_PRODUCT_PHOTOS";
export const UPLOAD_PRODUCT_PHOTOS_SUCCESS =
	"[PRODUCT] UPLOAD_PRODUCT_PHOTOS_SUCCESS";
export const UPLOAD_PRODUCT_PHOTOS_FAILED =
	"[PRODUCT] UPLOAD_PRODUCT_PHOTOS_FAILED";

export const UPLOAD_PRODUCT_ITEMS = "[PRODUCT] UPLOAD_PRODUCT_ITEMS";
export const UPLOAD_PRODUCT_ITEMS_SUCCESS =
	"[PRODUCT] UPLOAD_PRODUCT_ITEMS_SUCCESS";
export const UPLOAD_PRODUCT_ITEMS_FAILED =
	"[PRODUCT] UPLOAD_PRODUCT_ITEMS_FAILED";

export const DELETE_PHOTO = "[PRODUCT] DELETE_PHOTO";
export const DELETE_PHOTO_SUCCESS = "[PRODUCT] DELETE_PHOTO_SUCCESS";
export const DELETE_PHOTO_FAILED = "[PRODUCT] DELETE_PHOTO_FAILED";

export const DELETE_ITEM = "[PRODUCT] DELETE_ITEM";
export const DELETE_ITEM_SUCCESS = "[PRODUCT] DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILED = "[PRODUCT] DELETE_ITEM_FAILED";

export const DELETE_PRODUCT = "[PRODUCT] DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "[PRODUCT] DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "[PRODUCT] DELETE_PRODUCT_FAILED";

export const GET_BUSINESS_TINY = "[PRODUCT] GET_BUSINESS_TINY";
export const GET_BUSINESS_TINY_SUCCESS = "[PRODUCT] GET_BUSINESS_TINY_SUCCESS";
export const GET_BUSINESS_TINY_FAILED = "[PRODUCT] GET_BUSINESS_TINY_FAILED";

export const UPDATE_PRODUCT_COMPANIES = "[PRODUCT] UPDATE_PRODUCT_COMPANIES";
export const UPDATE_PRODUCT_COMPANIES_SUCCESS =
	"[PRODUCT] UPDATE_PRODUCT_COMPANIES_SUCCESS";
export const UPDATE_PRODUCT_COMPANIES_FAILED =
	"[PRODUCT] UPDATE_PRODUCT_COMPANIES_FAILED";

export const SET_VALUE = "[PRODUCT] SET_VALUE";
export const CLEAR_VALUES = "[PRODUCT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getProducts(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PRODUCTS });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Product?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_PRODUCTS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: GET_PRODUCTS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: GET_PRODUCTS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getProduct(id, include, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PRODUCT });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Product/${id}?include=${include}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PRODUCT_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: GET_PRODUCT_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: GET_PRODUCT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createProduct(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_PRODUCT });

		try {
			const { login, product, businessDetail } = getState();

			const isAdmin = isInRole(login.user, ["admin"]);

			const {
				name,
				description,
				categoryId,
				price,
				discount,
				discountType,
				delivery,
				status,
				isDigital,
				homeOffers,
				bannerLink,
				bannerFile,
				showQrCode,
				digitalProvider,
				digitalProviderCode,
				digitalUseInstructions,
				digitalUrl,
				tos,
				endDateType,
				fixedEndDate,
				dynamicEndDate
			} = product;

			let errors: any = {};

			if (!name) errors.name = ls.required;
			if (!categoryId) errors.categoryId = ls.required;
			if (!price && +price !== 0) errors.price = ls.required;

			if (isDigital) {
				if (!digitalProvider && !["0", 0].includes(digitalProvider))
					errors.digitalProvider = ls.required;
				if (["0", 0].includes(digitalProvider) && !digitalProviderCode)
					errors.digitalProviderCode = ls.required;
				if (!endDateType) {
					if (!fixedEndDate) errors.endDate = ls.required;
				} else if (endDateType) {
					if (dynamicEndDate < 0) errors.endDate = ls.required;
				}
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_PRODUCT_FAILED,
					payload: errors
				});
			}

			const model = {
				name,
				description,
				categoryId,
				price: Math.round(price * 100),
				discount: Math.round(discount * 100),
				discountType,
				delivery,
				status,
				businessId: businessDetail.item.id,
				isDigital,
				bannerLink,
				homeOffers,
				bannerFile: bannerFile ? bannerFile[0]?.filename : "",
				showQrCode,
				digitalProviderCode,
				digitalProvider,
				digitalUseInstructions,
				digitalUrl,
				tos,
				isDynamicEndDate: endDateType,
				fixedEndDate,
				dynamicEndDate
			};

			const response = await api.sendPost("/Product", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				const { id } = result.model;

				if (homeOffers && isAdmin) {
					const responseImg = await api.sendPostFiles(
						`/Product/${id}/Banner/Upload`,
						bannerFile,
						{
							Authorization: `Bearer ${login.token}`
						}
					);

					if (responseImg.status !== 200) {
						dispatch({
							type: UPDATE_PRODUCT_FAILED,
							payload: errors
						});

						return (
							callback &&
							callback(
								"Produto cadastrado com sucesso mas com erro no envio da imagem"
							)
						);
					}
				}
				dispatch({
					type: CREATE_PRODUCT_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			errors = getErrors(result.errors);

			dispatch({
				type: CREATE_PRODUCT_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: CREATE_PRODUCT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editProduct(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_PRODUCT });

		try {
			const { login, product } = getState();

			const isAdmin = isInRole(login.user, ["admin"]);

			const {
				item,
				name,
				description,
				categoryId,
				price,
				discount,
				discountType,
				delivery,
				status,
				isDigital,
				homeOffers,
				bannerLink,
				bannerFile,
				showQrCode,
				digitalProvider,
				digitalProviderCode,
				digitalUseInstructions,
				digitalUrl,
				tos,
				endDateType,
				fixedEndDate,
				dynamicEndDate
			} = product;
			let errors: any = {};

			if (!name) errors.name = ls.required;
			if (!categoryId) errors.categoryId = ls.required;
			if (!price && +price !== 0) errors.price = ls.required;

			if (isDigital) {
				if (!digitalProvider && !["0", 0].includes(digitalProvider))
					errors.digitalProvider = ls.required;
				if (["0", 0].includes(digitalProvider) && !digitalProviderCode)
					errors.digitalProviderCode = ls.required;
				if (!endDateType) {
					if (!fixedEndDate) errors.endDate = ls.required;
				} else if (endDateType) {
					if (dynamicEndDate < 0) errors.endDate = ls.required;
				}
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_PRODUCT_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				name,
				description,
				categoryId,
				price: Math.round(price * 100),
				discount: Math.round(discount * 100),
				discountType,
				delivery,
				status,
				isDigital,
				bannerFile: bannerFile ? bannerFile[0]?.filename : "",
				bannerLink,
				homeOffers,
				showQrCode,
				digitalProviderCode,
				digitalProvider,
				digitalUseInstructions,
				digitalUrl,
				tos,
				isDynamicEndDate: endDateType,
				fixedEndDate,
				dynamicEndDate
			};

			const response = await api.sendPut(`/Product/${item.id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				if (homeOffers && isAdmin) {
					const responseImg = await api.sendPostFiles(
						`/Product/${item.id}/Banner/Upload`,
						bannerFile,
						{
							Authorization: `Bearer ${login.token}`
						}
					);

					if (responseImg.status !== 200) {
						dispatch({
							type: UPDATE_PRODUCT_FAILED,
							payload: errors
						});

						return (
							callback &&
							callback(
								"Produto editado com sucesso mas com erro no envio da imagem"
							)
						);
					}
				}

				dispatch({
					type: UPDATE_PRODUCT_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			errors = getErrors(result.errors);

			dispatch({
				type: UPDATE_PRODUCT_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: UPDATE_PRODUCT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function uploadPhotos(files, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPLOAD_PRODUCT_PHOTOS });
		try {
			const { login, product } = getState();

			const { item } = product;

			const response = await api.sendPostFiles(
				`/Product/${item.id}/Upload`,
				files,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPLOAD_PRODUCT_PHOTOS_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: UPLOAD_PRODUCT_PHOTOS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: UPLOAD_PRODUCT_PHOTOS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deletePhoto(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_PHOTO });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/Product/Image/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				dispatch({
					type: DELETE_PHOTO_SUCCESS
				});

				return callback && callback(null);
			}
			let result = await response.json();
			const errors = getErrors(result.errors);

			dispatch({
				type: DELETE_PHOTO_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: DELETE_PHOTO_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function uploadItems(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPLOAD_PRODUCT_ITEMS });

		try {
			const { login, product } = getState();

			const { item } = product;

			const response = await api.sendPost(
				`/Product/${item.id}/Item/Import`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPLOAD_PRODUCT_ITEMS_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: UPLOAD_PRODUCT_ITEMS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: UPLOAD_PRODUCT_ITEMS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteItem(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_ITEM });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/Product/Item/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				dispatch({
					type: DELETE_ITEM_SUCCESS
				});

				return callback && callback(null);
			}
			let result = await response.json();
			const errors = getErrors(result.errors);

			dispatch({
				type: DELETE_ITEM_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: DELETE_ITEM_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editProductCompanies(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_PRODUCT_COMPANIES });

		try {
			const { login, product } = getState();

			const { item } = product;

			const response = await api.sendPut(
				`/Product/${item.id}/Companies`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_PRODUCT_COMPANIES_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: UPDATE_PRODUCT_COMPANIES_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: UPDATE_PRODUCT_COMPANIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getCompaniesTiny(callback?: Callback<any[]>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_BUSINESS_TINY });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Business/Company/Tiny", {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_BUSINESS_TINY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_BUSINESS_TINY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_BUSINESS_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const GET_IFOOD_PRODUCTS = "[PRODUCT] GET_IFOOD_PRODUCTS";
export const GET_IFOOD_PRODUCTS_SUCCESS =
	"[PRODUCT] GET_IFOOD_PRODUCTS_SUCCESS";
export const GET_IFOOD_PRODUCTS_FAILED = "[PRODUCT] GET_IFOOD_PRODUCTS_FAILED";

export function getIfoodProducts(url: string, callback?: Callback<any[]>) {
	return async dispatch => {
		dispatch({ type: GET_IFOOD_PRODUCTS });

		try {
			let response = await fetch(
				`${process.env.REACT_APP_CRAWLER}/ifood?url=${url}`
			);

			if (response.ok) {
				let result = await response.json();

				dispatch({
					type: GET_IFOOD_PRODUCTS_SUCCESS,
					payload: result
				});

				return callback && callback(null, result);
			}
			let errors = { default: await response.text() };

			dispatch({
				type: GET_IFOOD_PRODUCTS_FAILED,
				payload: errors
			});

			return callback && callback(errors);
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: GET_IFOOD_PRODUCTS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export const IMPORT_PRODUCTS = "[PRODUCT] IMPORT_PRODUCTS";
export const IMPORT_PRODUCTS_SUCCESS = "[PRODUCT] IMPORT_PRODUCTS_SUCCESS";
export const IMPORT_PRODUCTS_FAILED = "[PRODUCT] IMPORT_PRODUCTS_FAILED";

export function importProducts(data, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_PRODUCTS });

		try {
			const { login, businessDetail } = getState();

			const models = data.map(c => {
				const {
					name,
					description,
					category,
					price,
					imageUrl,
					promotionalPrice,
					promotionalName,
					promotionalStartAt,
					promotionalEndAt
				} = c;

				return {
					name,
					description,
					category,
					price,
					discount: 0,
					discountType: 1,
					delivery: true,
					status: 1,
					businessId: businessDetail.item.id,
					isDigital: false,
					showQrCode: false,
					photo: imageUrl,
					promotions:
						promotionalPrice > 0
							? [
									{
										name: promotionalName,
										price: promotionalPrice,
										startAt: promotionalStartAt,
										endAt: promotionalEndAt
									}
							  ]
							: undefined
				};
			});

			const response = await api.sendPost(
				`/Product/Import/${businessDetail.item.id}`,
				models,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_PRODUCTS_SUCCESS,
					payload: result.model
				});

				return callback && callback(null, result.model);
			}
			const errors = getErrors(result.errors);

			dispatch({
				type: IMPORT_PRODUCTS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: IMPORT_PRODUCTS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteProduct(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_PRODUCT });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/Product/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				dispatch({
					type: DELETE_PRODUCT_SUCCESS,
					payload: id
				});

				return callback && callback(null);
			}
			let result = await response.json();
			const errors = getErrors(result.errors);

			dispatch({
				type: DELETE_PRODUCT_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			return dispatch({
				type: DELETE_PRODUCT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
