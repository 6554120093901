import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AutoComplete2 from "Components/AutoComplete2";
import { CREDIT_CARD } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import ls from "Localization";
import _ from "lodash";
import virtualCardStatus from "models/virtualCardStatus";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "store/actions/user/report";
import {
	clearValues,
	createVirtualCard,
	setValue
} from "store/actions/virtualCard";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceFindClient: (() => void) & _.Cancelable = null;
let debounceCreateVirtualCard: (() => void) & _.Cancelable = null;

function CreateForm() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { showFormModal, number, status, errors, loading } = useSelector<
		any,
		any
	>(c => c.virtualCard);
	const { loading: userLoading } = useSelector<any, any>(s => s.userReport);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showFormModal", false);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceCreateVirtualCard) debounceCreateVirtualCard.cancel();

		debounceCreateVirtualCard = _.debounce(() => {
			dispatch(
				createVirtualCard(err => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceCreateVirtualCard();
	};

	const findClient = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindClient) debounceFindClient.cancel();

			debounceFindClient = _.debounce(() => {
				dispatch(
					getUsers(
						0,
						10,
						`&filters[fullName]=${name}`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => ({
										id: c.id,
										label: `${c.fullName}`
									}))
								);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindClient();
		}
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={showFormModal}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={showFormModal}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.createVirtualCard}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<AutoComplete2
										id="userId"
										name="client"
										errors={errors}
										onChange={handleChange}
										loadOptions={findClient}
										loading={userLoading}
										filterOptions={(options, state) => options}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={5}>
									<TextInput
										id="number"
										required
										variant="outlined"
										name="number"
										value={number}
										errors={errors}
										onChange={handleChange}
										InputProps={{
											inputComponent: CREDIT_CARD
										}}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<Select
										errors={errors}
										id="status"
										name="status"
										value={status}
										required
										onChange={handleChange}
										options={[
											{ id: "", value: ls.noneSelectedText, disabled: true },
											...Object.keys(virtualCardStatus).map(c => ({
												id: c,
												value: ls[virtualCardStatus[c]]
											}))
										]}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default CreateForm;
