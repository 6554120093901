const styles = theme => {
	return {
		root: {
			padding: theme.spacing(2)
		},
		form: {
			marginTop: theme.spacing(4),
			marginBottom: theme.spacing(4)
		},
		alert: {
			padding: theme.spacing(2),
			display: "flex",
			alignItems: "center",
			backgroundColor: "#cdeefd"
		},
		alertIcon: {
			marginRight: theme.spacing(2),
			color: "#02587f"
		},
		alertText: {
			color: "#02587f"
		},
		line: {
			marginLeft: -16,
			marginRight: -16
		},
		button: {
			margin: theme.spacing()
		}
	};
};

export default styles;
