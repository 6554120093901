import styled from "styled-components";

interface PropsButton {
	isPurple?: boolean;
}

export const Container = styled.div``;

export const ContainerPopover = styled.div``;

export const Header = styled.div`
	width: 100%;
	height: 30px;
	p {
		text-align: left;
		font: normal normal normal 16px Product Sans;
		letter-spacing: 0px;
		color: #000000;
		opacity: 1;
		padding-left: 10px;
		padding-top: 5px;
		padding-bottom: 10px;
		margin: 0;
	}
`;

export const Row = styled.div`
	width: 100%;
	height: 1px;
	background-color: #707070;
`;

export const BackgroundButtons = styled.div``;

export const Button = styled.button<PropsButton>`
	border: 1px solid ${props => (!props.isPurple ? "#A7A7A7" : "#5734DA")};
	border-radius: 4px;
	opacity: 1;
	margin-right: 10px;
	width: 60px;
	background: ${props => (props.isPurple ? "#5734DA" : "none")};
	color: ${props => (!props.isPurple ? "#494949" : "#fff")};
	padding: 5px;
	cursor: pointer;
`;
