import * as actions from "store/actions/transaction/edit";

import { IEditTransaction } from "../types/edit";

const initialState: IEditTransaction = {
	item: null,
	creationDate: "",
	clientId: "",
	code: "",
	description: "",
	store: null,
	company: null,
	fee: 0,
	value: 0,
	discount: 0,
	client: null,
	type: 0,
	installmentAmount: 1,
	status: 1,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.EDIT_TRANSACTION: {
			return {
				...state,
				loading: { edit: true },
				errors: initialState.errors
			};
		}
		case actions.EDIT_TRANSACTION_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.EDIT_TRANSACTION_FAILED: {
			return {
				...state,
				loading: { edit: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
