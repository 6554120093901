import { Link as LinkMUI } from "@material-ui/core";
import React from "react";

type Props = {
	link: string;
	label: string;
};

export const Link: React.FC<Props> = ({ link, label }) => (
	<LinkMUI
		style={{ cursor: "pointer" }}
		onClick={() => window.open(link)}
		underline="hover"
		color="primary"
	>
		{label}
	</LinkMUI>
);
