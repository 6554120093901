import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { modalConfirmation } from "Components/Modals/ModalConfirmation";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import TextInput from "Components/TextInput";
import { useFormik } from "formik";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import { toastWarning } from "helpers/toast";
import ls from "Localization";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBalanceAccountTypes } from "store/actions/balanceAccount/report";
import { IBalanceAccountState } from "store/actions/balanceAccount/types";
import * as Yup from "yup";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 860,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		marginTop: 20,
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

const schema = Yup.object().shape({
	value: Yup.number().positive(ls.invalid).required(ls.required),
	type: Yup.number().min(0, ls.invalid).required(ls.required)
});

const AddTreasuryAccountTransfer = ({ open, handleClose, onAdd, user }) => {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const isAdmin = isInRole(user, ["admin"]);

	const textAlert = useCallback(() => {
		const cnpj = user?.business?.business?.cnpj;

		if (cnpj) return cnpj;

		return `da ${user?.business?.business?.displayName}`;
	}, [user?.business?.business]);

	const { types } = useSelector<any, IBalanceAccountState>(
		state => state.balanceAccount
	);

	useEffect(() => {
		if (open) {
			dispatch(getBalanceAccountTypes({}));
		}
	}, [dispatch, open]);

	const handleSubmit = useCallback(
		async (
			{ value, type, balanceAccountTypeId, description },
			{ resetForm }
		) => {
			if (value && type && balanceAccountTypeId) {
				modalConfirmation({
					onClickNo: () => {},
					onClickYes: () => {
						onAdd({ value, type, balanceAccountTypeId, description });
						resetForm({});
						handleClose();
					},
					title: ls.confirm,
					body: (
						<>
							{ls.formatString(
								ls.treasuryAccountConfirmationTransferValue,
								toStringCurrency(value),
								`${user.name} ${user.lastName}`,
								textAlert()
							)}
							?
						</>
					)
				});
			} else {
				toastWarning("Preencha todos os campos antes de continuar");
			}
		},
		[user?.name, user?.lastName, textAlert, onAdd, handleClose]
	);

	const formik = useFormik({
		initialValues: {
			value: "0",
			type: "0",
			balanceAccountTypeId: types[0]?.id || "",
			description: ""
		},
		validationSchema: schema,
		onSubmit: handleSubmit
	});

	return (
		<Modal
			aria-labelledby="add-treasury-account-transfer-modal-title"
			aria-describedby="add-treasury-account-transfer-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.add}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={formik.handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4} md={2}>
									<Select
										errors={formik.touched.type ? formik.errors : {}}
										id="type"
										name="type"
										value={formik.values.type}
										required
										onChange={(id, value) => formik.setFieldValue(id, value)}
										options={[
											{ id: "", value: "Selecione" },
											{ id: "0", value: "Crédito" },
											{ id: "1", value: "Débito" }
										]}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<Select
										errors={
											formik.touched.balanceAccountTypeId ? formik.errors : {}
										}
										id="balanceAccountTypeId"
										name="balanceAccountType"
										value={formik.values.balanceAccountTypeId}
										required
										onChange={(id, value) => formik.setFieldValue(id, value)}
										options={types
											.filter(i =>
												isAdmin
													? i.externalId !== "19" && i.externalId !== "0"
													: i
											)
											.map(c => ({
												id: c.id,
												value: `${c.externalId} - ${c.name}`
											}))}
									/>
								</Grid>
								<Grid item xs={12} sm={4} md={2}>
									<TextInput
										id="value"
										variant="outlined"
										name="value"
										InputProps={{
											inputComponent: MONEY
										}}
										value={formik.values.value}
										errors={formik.touched.value ? formik.errors : {}}
										onFocus={e => e.target.select()}
										onChange={(id, value) => formik.setFieldValue(id, value)}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<TextInput
										id="description"
										variant="outlined"
										name="description"
										value={formik.values.description}
										errors={formik.touched.description ? formik.errors : {}}
										onFocus={e => e.target.select()}
										onChange={(id, value) => formik.setFieldValue(id, value)}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={handleClose}>
									{ls.cancel}
								</Button>
								<Button color="primary" variant="contained" type="submit">
									{ls.save}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default AddTreasuryAccountTransfer;
