import styled from "styled-components";

export const Container = styled.div`
	z-index: 1;
`;

export const ContainerOption = styled.div``;

export const ButtonOpenSelect = styled.button`
	border: none;
	background: none;
	margin-right: 5px;
`;
export const Menu = styled.ul`
	position: absolute;
	top: 30px;
	left: 0;
	background: #fff;
	border: 1px solid #ccc;
	list-style: none;
	padding: 0;
	margin: 0;
	min-width: 200px;
`;

export const MenuItem = styled.li`
	padding: 10px;
	cursor: pointer;

	&:hover {
		background-color: #f2f2f2;
	}
`;

export const BackgroundSelected = styled.div`
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 6px;
	margin-top: 5px;
	z-index: 1;
`;

export const RowCheckbox = styled.p`
	text-align: left;
	margin-left: 10px;
`;
