import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_MARKETINGS = "[MARKETING] GET_MARKETINGS";
export const GET_MARKETINGS_SUCCESS = "[MARKETING] GET_MARKETINGS_SUCCESS";
export const GET_MARKETINGS_FAILED = "[MARKETING] GET_MARKETINGS_FAILED";

export const GET_MARKETING = "[MARKETING] GET_MARKETING";
export const GET_MARKETING_SUCCESS = "[MARKETING] GET_MARKETING_SUCCESS";
export const GET_MARKETING_FAILED = "[MARKETING] GET_MARKETING_FAILED";

export const GET_MARKETING_USERS = "[MARKETING] GET_MARKETING_USERS";
export const GET_MARKETING_USERS_SUCCESS =
	"[MARKETING] GET_MARKETING_USERS_SUCCESS";
export const GET_MARKETING_USERS_FAILED =
	"[MARKETING] GET_MARKETING_USERS_FAILED";

export const GET_MARKETING_USERS_TINY = "[MARKETING] GET_MARKETING_USERS_TINY";
export const GET_MARKETING_USERS_TINY_SUCCESS =
	"[MARKETING] GET_MARKETING_USERS_TINY_SUCCESS";
export const GET_MARKETING_USERS_TINY_FAILED =
	"[MARKETING] GET_MARKETING_USERS_TINY_FAILED";

export const CREATE_MARKETING = "[MARKETING] CREATE_MARKETING";
export const CREATE_MARKETING_SUCCESS = "[MARKETING] CREATE_MARKETING_SUCCESS";
export const CREATE_MARKETING_FAILED = "[MARKETING] CREATE_MARKETING_FAILED";

export const UPDATE_MARKETING = "[MARKETING] UPDATE_MARKETING";
export const UPDATE_MARKETING_SUCCESS = "[MARKETING] UPDATE_MARKETING_SUCCESS";
export const UPDATE_MARKETING_FAILED = "[MARKETING] UPDATE_MARKETING_FAILED";

export const RESEND_MARKETING = "[MARKETING] RESEND_MARKETING";
export const RESEND_MARKETING_SUCCESS = "[MARKETING] RESEND_MARKETING_SUCCESS";
export const RESEND_MARKETING_FAILED = "[MARKETING] RESEND_MARKETING_FAILED";

export const SET_VALUE = "[MARKETING] SET_VALUE";
export const CLEAR_VALUES = "[MARKETING] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getMarketings(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_MARKETINGS });
		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Marketing?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: { getAll: false } }));
				} else {
					dispatch({
						type: GET_MARKETINGS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_MARKETINGS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_MARKETINGS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getMarketing(id, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_MARKETING });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Marketing/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_MARKETING_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_MARKETING_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_MARKETING_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getMarketingUsers(
	id,
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_MARKETING_USERS });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/Marketing/${id}/User?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_MARKETING_USERS_SUCCESS,
					payload: result.model
				});

				if (result.params)
					dispatch(
						setValue({
							pages: Math.ceil(result.params.count / take),
							...result.params
						})
					);

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_MARKETING_USERS_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_MARKETING_USERS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getMarketingUsersTiny(id) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_MARKETING_USERS_TINY });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/Marketing/${id}/User/Tiny`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_MARKETING_USERS_TINY_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_MARKETING_USERS_TINY_FAILED,
					payload: errors
				});
			}
		} catch (error) {
			return dispatch({
				type: GET_MARKETING_USERS_TINY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createMarketing(file, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_MARKETING });

		try {
			const { login, marketing } = getState();

			const {
				name,
				users,
				startAt,
				economicMode,
				emailSubject,
				emailMessage,
				smsMessage,
				whatsAppMessage,
				whatsAppRestrict,
				pushMessage,
				pushTitle,
				pushAction,
				pushValue
			} = marketing;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!users.length) errors.users = ls.required;

			if (emailMessage && !emailSubject) {
				errors.emailSubject = ls.required;
			}

			if (pushMessage && !pushTitle) {
				errors.pushTitle = ls.required;
			}

			if (
				!emailMessage &&
				!smsMessage &&
				!whatsAppMessage &&
				!file &&
				!pushMessage
			) {
				errors.emailMessage =
					"Precisa preencher pelo menos um campo de mensagem";
				errors.smsMessage = "Precisa preencher pelo menos um campo de mensagem";
				errors.whatsAppMessage =
					"Precisa preencher pelo menos um campo de mensagem";
				errors.pushMessage =
					"Precisa preencher pelo menos um campo de mensagem";
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_MARKETING_FAILED,
					payload: errors
				});
			}

			const model = {
				name,
				users,
				startAt,
				economicMode,
				emailSubject,
				emailMessage,
				smsMessage,
				whatsAppMessage,
				whatsAppFile: "",
				whatsAppRestrict,
				pushTitle,
				pushMessage,
				pushAction,
				pushValue
			};

			if (file) {
				const response = await api.sendPostFile("/Marketing/Upload", file, {
					Authorization: `Bearer ${login.token}`
				});

				let result = await response.json();

				if (!result.isValid) {
					const errors = getErrors(result.errors);

					dispatch({
						type: CREATE_MARKETING_FAILED,
						payload: errors
					});

					callback && callback(errors);

					return;
				}

				model.whatsAppFile = result.model;
			}

			const response = await api.sendPost("/Marketing", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_MARKETING_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_MARKETING_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_MARKETING_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editMarketing(file, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_MARKETING });

		try {
			const { login, marketing } = getState();

			const {
				item,
				name,
				usersTiny,
				startAt,
				economicMode,
				emailSubject,
				emailMessage,
				smsMessage,
				whatsAppFile,
				whatsAppMessage,
				whatsAppRestrict,
				pushMessage,
				pushTitle,
				pushAction,
				pushValue
			} = marketing;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!usersTiny.length) errors.users = ls.required;

			if (emailMessage && !emailSubject) {
				errors.emailSubject = ls.required;
			}

			if (pushMessage && !pushTitle) {
				errors.pushTitle = ls.required;
			}

			if (
				!emailMessage &&
				!smsMessage &&
				!whatsAppMessage &&
				!file &&
				!pushMessage
			) {
				errors.emailMessage =
					"Precisa preencher pelo menos um campo de mensagem";
				errors.smsMessage = "Precisa preencher pelo menos um campo de mensagem";
				errors.whatsAppMessage =
					"Precisa preencher pelo menos um campo de mensagem";
				errors.pushMessage =
					"Precisa preencher pelo menos um campo de mensagem";
			}

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_MARKETING_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				name,
				users: usersTiny.map(c => ({ userId: c })),
				startAt,
				economicMode,
				emailSubject,
				emailMessage,
				smsMessage,
				whatsAppFile,
				whatsAppMessage,
				whatsAppRestrict,
				pushTitle,
				pushMessage,
				pushAction,
				pushValue,
				createdBy: undefined
			};

			if (file) {
				const response = await api.sendPostFile("/Marketing/Upload", file, {
					Authorization: `Bearer ${login.token}`
				});

				let result = await response.json();

				if (!result.isValid) {
					const errors = getErrors(result.errors);

					dispatch({
						type: CREATE_MARKETING_FAILED,
						payload: errors
					});

					callback && callback(errors);

					return;
				}

				model.whatsAppFile = result.model;
			}

			const response = await api.sendPut(`/Marketing/${item.id}`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_MARKETING_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_MARKETING_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_MARKETING_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function resendMarketing(id, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_MARKETING });

		try {
			const { login } = getState();

			const response = await api.sendPut(`/Marketing/${id}/Resend`, null, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_MARKETING_SUCCESS
				});

				callback && callback(null);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_MARKETING_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_MARKETING_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
