import { Grid, Paper } from "@material-ui/core";
import Spinner from "Components/Spinner";
import { toastError } from "helpers/toast";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createRequestCards } from "store/actions/requestCards/create";
import { setValue } from "store/actions/requestCards/create";
import { State } from "store/reducers";
import { IInitialState } from "store/reducers/requestCards/types";

import { ButtonRequest } from "../styles";
import Request from "./Request";
import {
	BackgroundCheckBox,
	Button,
	Header,
	RowFooter,
	RowWidth,
	TextButton,
	Title
} from "./styles";

let createRequestCardsDebounced: (() => void) & _.Cancelable = null;

const Detail: React.FC = () => {
	const dispatch = useDispatch();
	const [checkInfos, setCheckInfos] = useState(false);
	const [loading, setLoading] = useState(false);

	const { request, cnpj } = useSelector<State, IInitialState>(
		s => s.requestCreate
	);
	const history = useHistory();

	const cleanValues = useCallback(() => {
		dispatch(
			setValue({
				request: {
					businessId: "",
					quantityCard: 0,
					street: "",
					document: "",
					zipcode: "",
					name: "",
					number: null,
					neighborhood: "",
					complement: "",
					city: "",
					state: "",
					phone: "",
					email: "",
					service: ""
				},
				cnpj: ""
			})
		);
	}, []);

	const handleCreateRequestCards = useCallback(() => {
		if (createRequestCardsDebounced) {
			createRequestCardsDebounced.cancel();
		}

		setLoading(true);

		createRequestCardsDebounced = _.debounce(
			() =>
				dispatch(
					createRequestCards(err => {
						if (err) {
							toastError(JSON.stringify(err));
						} else {
							cleanValues();
							history.push("/RequestCards");
						}

						setLoading(false);
					})
				),
			500
		);

		createRequestCardsDebounced();
	}, [cleanValues, dispatch, history]);
	return (
		<Grid container justify="center" spacing={5}>
			<Grid item xs={11}>
				<Paper>
					<Header>
						<Title>Resumo da Solicitação</Title>{" "}
						<Button onClick={() => history.push("/RequestCards/Add")}>
							<FaRegEdit
								size={20}
								style={{ marginLeft: 15, marginRight: 10 }}
								color="#5734DA"
							/>{" "}
							<TextButton>Editar Dados</TextButton>
						</Button>
					</Header>

					<RowWidth />

					<Request item={request} index={1} cnpj={cnpj} />

					<RowFooter>
						<BackgroundCheckBox>
							<input
								type="checkbox"
								placeholder="Selecione uma das opções"
								style={{ marginRight: 10, marginLeft: 20 }}
								checked={checkInfos}
								onChange={() => setCheckInfos(!checkInfos)}
							/>
							<p> Declaro que todos os dados informados estão corretos.</p>
						</BackgroundCheckBox>
						<ButtonRequest
							type="button"
							onClick={handleCreateRequestCards}
							disabled={!checkInfos || loading}
							style={{
								margin: 0,
								marginRight: 20,
								height: 50,
								width: 250,
								cursor: !checkInfos || loading ? "none" : "pointer",
								backgroundColor: !checkInfos || loading ? "#a9a9a9" : "#5734da"
							}}
						>
							{loading ? <Spinner color="secondary" size={16} /> : "Solicitar"}
						</ButtonRequest>
					</RowFooter>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default Detail;
