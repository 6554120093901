import styled from "styled-components";

interface Props {
	colorType?: string;
}

export const Container = styled.div<Props>`
	width: ${props => (props.colorType === "red" ? "20px" : "55px")};
	height: 20px;
	text-align: center;
	margin: 0 ${props => (props.colorType === "red" ? "5px" : "16px")};
	border-radius: 4px;
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-items: center;
	background-color: ${props =>
		props.colorType === "red" ? "none" : props.colorType};
`;

export const TextLabelBAG = styled.p`
	width: 100%;
	text-align: center;
	font: normal normal bold 12px Exo;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 1;
	margin: 0;
	padding: 0;
`;
