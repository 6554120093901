import { toastError, toastSuccess } from "helpers/toast";
import * as api from "services/api";

import {
	ActionTypes,
	IExportIInvoiceClubeWiipoRequest,
	IInvoiceClubeWiipoRequest
} from "../types";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: ActionTypes.SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: ActionTypes.CLEAR_VALUES };
}

export function getInvoiceClubWiipo({
	skip,
	take,
	startDate,
	endDate,
	cnpj,
	name,
	callback
}: IInvoiceClubeWiipoRequest) {
	return async (dispatch, getState) => {
		dispatch({ type: ActionTypes.GET_INVOICE_REQUEST });

		try {
			const { login } = getState();

			const queryString = new URLSearchParams({
				skip: String(skip),
				take: String(take),
				startDate,
				endDate,
				...(cnpj && { cnpj }),
				...(name && { name })
			}).toString();

			let response = await api.sendGet(`/invoicing-club-wiipo?${queryString}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ActionTypes.GET_INVOICE_SUCCESS,
					payload: result.model
				});
				dispatch(
					setValue({
						pages: Math.ceil(result.params.count / take),
						...result.params
					})
				);
			} else {
				dispatch({
					type: ActionTypes.GET_INVOICE_FAILED,
					payload: result.errors.addErrors
				});

				return callback && callback(result.errors?.addErrors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: ActionTypes.GET_INVOICE_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function exportInvoiceClubWiipo({
	startDate,
	endDate,
	typeExport,
	cnpjs
}: IExportIInvoiceClubeWiipoRequest) {
	return async (_dispatch, getState) => {
		try {
			const { login } = getState();

			const queryString = new URLSearchParams({
				startDate,
				endDate
			});

			if (cnpjs) cnpjs.forEach(cnpj => queryString.append("cnpj", cnpj));

			const query = queryString.toString();

			let response = await api.sendGet(
				`/invoicing-club-wiipo/export/${typeExport}?${query}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.status === 200) {
				toastSuccess("Confira o relatório em seu email em alguns minutos.");
			} else {
				toastError("Falha em solicitar relatório.");
			}
		} catch (error) {
			toastError("Falha em solicitar relatório.");
		}
	};
}
