import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AutoComplete2 from "Components/AutoComplete2";
import Spinner from "Components/Spinner";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "store/actions/business/detail";
import { getBusinesses } from "store/actions/business/report";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 400,
		padding: theme.spacing(1, 2)
	},
	body: {
		marginTop: 16,
		padding: theme.spacing(0, 2, 0, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(2, 0),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	},
	margin: {},
	textField: {}
}));

let debounceFindStore: (() => void) & _.Cancelable = null;

function AddFranchiseBusiness({ open, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [franchiseBusinessId, setFranchiseBusinessId] = useState("");
	const [errors, setErrors] = useState({});

	const { loading, item } = useSelector<any, any>(s => s.businessDetail);

	const { loading: storeLoading } = useSelector<any, any>(
		s => s.businessReport
	);

	useEffect(() => {
		if (open) {
			setFranchiseBusinessId("");
		}
	}, [dispatch, open]);

	const findBusiness = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindStore) debounceFindStore.cancel();

			debounceFindStore = _.debounce(() => {
				dispatch(
					getBusinesses(
						0,
						10,
						`&filters[name]=${name}`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => ({
										id: c.id,
										label: `${c.displayName}`
									}))
								);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindStore();
		}
	};

	const onSubmit = e => {
		e.preventDefault();

		dispatch(
			actions.addFranchiseBusiness(item.id, franchiseBusinessId, err => {
				if (err) {
					setErrors({ error: err });
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					handleClose(true);
				}
			})
		);
	};

	return (
		<Modal
			aria-labelledby="add-franchise-business-modal-title"
			aria-describedby="add-franchise-business-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.addFranchiseBusiness}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<AutoComplete2
										id="businessId"
										name="business"
										errors={errors}
										onChange={(id, value) => setFranchiseBusinessId(value)}
										loadOptions={findBusiness}
										loading={storeLoading}
										filterOptions={(options, state) => options}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.addFranchiseBusiness}
								>
									{ls.save}
									{loading.addFranchiseBusiness && (
										<Spinner color="secondary" size={16} />
									)}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default AddFranchiseBusiness;
