import { CircularProgress } from "@material-ui/core";
import AutocompleteMd, {
	AutocompleteProps
} from "@material-ui/lab/Autocomplete";
import TextInput from "Components/TextInput";
import React, { useEffect, useState } from "react";

interface Props<
	T,
	Multiple extends boolean | undefined,
	DisableClearable extends boolean | undefined,
	FreeSolo extends boolean | undefined
> extends Omit<
		AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
		"renderInput" | "options" | "onChange"
	> {
	name?: string;
	loadOptions: (term: string, callback: (data: any[]) => void) => void;
	onChange(id: string, value: string): void;
	required?: boolean;
	errors?: any;
}

function AutoComplete<T>({
	id,
	name,
	loading,
	loadOptions,
	errors,
	onChange,
	required,
	...rest
}: Props<T, false, false, false>) {
	const [data, setData] = useState([]);
	const [open, setOpen] = useState(false);
	const [term, setTerm] = useState("");

	useEffect(() => {
		if (open) {
			loadOptions(term, d => setData(d));
		}
	}, [open, term]);

	return (
		<AutocompleteMd
			id={`${id}-auto-complete`}
			open={open}
			onOpen={() => {
				setOpen(true);
			}}
			onClose={() => {
				setOpen(false);
			}}
			loading={loading}
			options={data}
			onChange={(e, v: any, r) => onChange(id, v?.id)}
			getOptionSelected={(option: any, value: any) => option.id === value.id}
			getOptionLabel={(option: any) => option.label}
			renderInput={params => (
				<TextInput
					{...params}
					id={id}
					name={name}
					value={term}
					required={required}
					errors={errors}
					onChange={(id, v) => setTerm(v)}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? (
									<CircularProgress color="inherit" size={20} />
								) : null}
								{params.InputProps.endAdornment}
							</>
						)
					}}
				/>
			)}
			{...rest}
		/>
	);
}

export default AutoComplete;
