import * as api from "services/api";
import _ from "lodash";
import { getErrors } from "helpers/error";
import ls from "Localization";
import { Callback } from "Types/Callback";

export const CREATE_PRODUCT_PROMOTION =
	"[PRODUCT_PROMOTION] CREATE_PRODUCT_PROMOTION";
export const CREATE_PRODUCT_PROMOTION_SUCCESS =
	"[PRODUCT_PROMOTION] CREATE_PRODUCT_PROMOTION_SUCCESS";
export const CREATE_PRODUCT_PROMOTION_FAILED =
	"[PRODUCT_PROMOTION] CREATE_PRODUCT_PROMOTION_FAILED";

export const UPDATE_PRODUCT_PROMOTION =
	"[PRODUCT_PROMOTION] UPDATE_PRODUCT_PROMOTION";
export const UPDATE_PRODUCT_PROMOTION_SUCCESS =
	"[PRODUCT_PROMOTION] UPDATE_PRODUCT_PROMOTION_SUCCESS";
export const UPDATE_PRODUCT_PROMOTION_FAILED =
	"[PRODUCT_PROMOTION] UPDATE_PRODUCT_PROMOTION_FAILED";

export const DELETE_PRODUCT_PROMOTION =
	"[PRODUCT_PROMOTION] DELETE_PRODUCT_PROMOTION";
export const DELETE_PRODUCT_PROMOTION_SUCCESS =
	"[PRODUCT_PROMOTION] DELETE_PRODUCT_PROMOTION_SUCCESS";
export const DELETE_PRODUCT_PROMOTION_FAILED =
	"[PRODUCT_PROMOTION] DELETE_PRODUCT_PROMOTION_FAILED";

export const SET_VALUE = "[PRODUCT_PROMOTION] SET_VALUE";
export const CLEAR_VALUES = "[PRODUCT_PROMOTION] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function createProductPromotion(callback: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_PRODUCT_PROMOTION });

		try {
			const { login, productPromotion, product } = getState();

			const { name, price, startAt, endAt } = productPromotion;

			const productId = product.item.id;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!price) errors.price = ls.required;
			if (!startAt) errors.startAt = ls.required;
			if (!endAt) errors.endAt = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_PRODUCT_PROMOTION_FAILED,
					payload: errors
				});
			}

			const model = {
				name,
				price: Math.round(price * 100),
				productId,
				startAt,
				endAt
			};

			const response = await api.sendPost("/ProductPromotion", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_PRODUCT_PROMOTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_PRODUCT_PROMOTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_PRODUCT_PROMOTION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function editProductPromotion(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_PRODUCT_PROMOTION });

		try {
			const { login, productPromotion } = getState();

			const errors: any = {};

			const { item, name, price, startAt, endAt } = productPromotion;

			if (!name) errors.name = ls.required;
			if (!price) errors.price = ls.required;
			if (!startAt) errors.startAt = ls.required;
			if (!endAt) errors.endAt = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_PRODUCT_PROMOTION_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				name,
				price: Math.round(price * 100),
				startAt,
				endAt
			};

			const response = await api.sendPut(
				"/ProductPromotion/" + item.id,
				model,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_PRODUCT_PROMOTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_PRODUCT_PROMOTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_PRODUCT_PROMOTION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}

export function deleteProductPromotion(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_PRODUCT_PROMOTION });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/ProductPromotion/${id}`, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: DELETE_PRODUCT_PROMOTION_SUCCESS,
					payload: id
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_PRODUCT_PROMOTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: DELETE_PRODUCT_PROMOTION_FAILED,
				payload: {
					error: "Connection error"
				}
			});
		}
	};
}
