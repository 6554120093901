/* eslint-disable no-restricted-globals */
// This optional code is used to register a service worker.
// register() is not called by default.

import { removeDiacritics } from "helpers/string";
import { toastError } from "helpers/toast";
import { IImportValueRecharge } from "models/interfaces/components/Business/importValueRecharge";
import { getValue } from "views/Dashboard/Transaction/Report/helpers";
import XLSX from "xlsx";

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

const isLocalhost = Boolean(
	self.window?.location.hostname === "localhost" ||
		// [::1] is the IPv6 localhost address.
		self.window?.location.hostname === "[::1]" ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		self.window?.location.hostname.match(
			/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
		)
);

type Config = {
	onSuccess?: (registration: ServiceWorkerRegistration) => void;
	onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
	if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
		// The URL constructor is available in all browsers that support SW.
		const publicUrl = new URL(
			(process as { env: { [key: string]: string } }).env.PUBLIC_URL,
			self.window?.location.href
		);
		if (publicUrl.origin !== self.window?.location.origin) {
			// Our service worker won't work if PUBLIC_URL is on a different origin
			// from what our page is served on. This might happen if a CDN is used to
			// serve assets; see https://github.com/facebook/create-react-app/issues/2374
			return;
		}

		self.window.addEventListener("load", () => {
			const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

			if (isLocalhost) {
				// This is running on localhost. Let's check if a service worker still exists or not.
				checkValidServiceWorker(swUrl, config);

				// Add some additional logging to localhost, pointing developers to the
				// service worker/PWA documentation.
				navigator.serviceWorker.ready.then(() => {
					console.log(
						"This web app is being served cache-first by a service " +
							"worker. To learn more, visit https://bit.ly/CRA-PWA"
					);
				});
			} else {
				// Is not localhost. Just register service worker
				registerValidSW(swUrl, config);
			}
		});
	}
}

function registerValidSW(swUrl: string, config?: Config) {
	navigator.serviceWorker
		.register(swUrl)
		.then(registration => {
			registration.onupdatefound = () => {
				const installingWorker = registration.installing;
				if (installingWorker == null) {
					return;
				}
				installingWorker.onstatechange = () => {
					if (installingWorker.state === "installed") {
						if (navigator.serviceWorker.controller) {
							// At this point, the updated precached content has been fetched,
							// but the previous service worker will still serve the older
							// content until all client tabs are closed.
							console.log(
								"New content is available and will be used when all " +
									"tabs for this page are closed. See https://bit.ly/CRA-PWA."
							);

							self.window.location.reload();

							// Execute callback
							if (config && config.onUpdate) {
								config.onUpdate(registration);
							}
						} else {
							// At this point, everything has been precached.
							// It's the perfect time to display a
							// "Content is cached for offline use." message.
							console.log("Content is cached for offline use.");

							// Execute callback
							if (config && config.onSuccess) {
								config.onSuccess(registration);
							}
						}
					}
				};
			};
		})
		.catch(error => {
			console.error("Error during service worker registration:", error);
		});
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
	// Check if the service worker can be found. If it can't reload the page.
	fetch(swUrl)
		.then(response => {
			// Ensure service worker exists, and that we really are getting a JS file.
			const contentType = response.headers.get("content-type");
			if (
				response.status === 404 ||
				(contentType != null && contentType.indexOf("javascript") === -1)
			) {
				// No service worker found. Probably a different app. Reload the page.
				navigator.serviceWorker.ready.then(registration => {
					registration.unregister().then(() => {
						self.window.location.reload();
					});
				});
			} else {
				// Service worker found. Proceed as normal.
				registerValidSW(swUrl, config);
			}
		})
		.catch(() => {
			console.log(
				"No internet connection found. App is running in offline mode."
			);
		});
}

export function unregister() {
	if ("serviceWorker" in navigator) {
		navigator.serviceWorker.ready.then(registration => {
			registration.unregister();
		});
	}
}

export const returnHeaders = (ref, sheet) => {
	const headers: string[] = [];
	for (let C = ref.s.c; C <= ref.e.c; ++C) {
		const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
		if (cell && cell.t === "s") {
			cell.v = removeDiacritics(cell.v.trim().toLowerCase());
			if (cell.w) cell.w = removeDiacritics(cell.w.trim().toLowerCase());

			headers.push(removeDiacritics(cell.v.trim().toLowerCase()));
		}
	}

	return headers;
};

export function validateDataResult(sheet) {
	if (sheet) {
		const ref = XLSX.utils.decode_range(sheet["!ref"]);
		const data = XLSX.utils.sheet_to_json(sheet, {});

		const headers = returnHeaders(ref, sheet);

		const dataResult = data
			.filter(i => i)
			.map(c => {
				const cClean = Object.keys(c).reduce((acc, chave) => {
					const chaveLimpa = chave
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.trim()
						.toLowerCase();

					acc[chaveLimpa] = c[chave];
					return acc;
				}, {});

				try {
					let d: IImportValueRecharge = {
						name: cClean["nome"],
						document: String(cClean["cpf"])
							?.match(/\d/g)
							.join("")
							.padStart(11, "0"),
						type: cClean["tipo"] ?? 0
					};

					if (!cClean["nome"]) {
						d = { ...d, error: true };
					}

					d.total = getValue(cClean["total"] || "");

					for (const header of headers) {
						const type = Number(header.split("_")[0]);

						if (Number.isNaN(type)) continue;

						d[type] = (d[type] || 0) + getValue(cClean[header] || 0);
					}
					return d;
				} catch (error) {
					toastError(
						`Erro ao converter dados do usuário ${cClean["cpf"]} - ${error}`
					);
					return null;
				}
			});

		return dataResult;
	}

	return [];
}

onmessage = async e => {
	const { action, file } = e.data;

	if (action === "processData") {
		const result = validateDataResult(file);
		postMessage(result, undefined);
	}
};
