export enum ActionTypes {
	CREATE_TREASURY_ACCOUNT_TRANSACTION_REQUEST = "[TREASURY ACCOUNT] CREATE_TREASURY_ACCOUNT_TRANSACTION",
	CREATE_TREASURY_ACCOUNT_TRANSACTION_SUCCESS = "[TREASURY ACCOUNT] CREATE_TREASURY_ACCOUNT_TRANSACTION_SUCCESS",
	CREATE_TREASURY_ACCOUNT_TRANSACTION_FAILED = "[TREASURY ACCOUNT] CREATE_TREASURY_ACCOUNT_TRANSACTION_FAILED",

	GET_TREASURY_ACCOUNT_TRANSACTION_REQUEST = "[TREASURY ACCOUNT] GET_TREASURY_ACCOUNT_TRANSACTION_REQUEST",
	GET_TREASURY_ACCOUNT_TRANSACTION_SUCCESS = "[TREASURY ACCOUNT] GET_TREASURY_ACCOUNT_TRANSACTION_SUCCESS",
	GET_TREASURY_ACCOUNT_TRANSACTION_FAILED = "[TREASURY ACCOUNT] GET_TREASURY_ACCOUNT_TRANSACTION_FAILED",

	CANCEL_TREASURY_ACCOUNT_TRANSACTION_REQUEST = "[TREASURY ACCOUNT] CANCEL_TREASURY_ACCOUNT_TRANSACTION_REQUEST",
	CANCEL_TREASURY_ACCOUNT_TRANSACTION_FAILED = "[TREASURY ACCOUNT] CANCEL_TREASURY_ACCOUNT_TRANSACTION_FAILED",
	CANCEL_TREASURY_ACCOUNT_TRANSACTION_SUCCESS = "[TREASURY ACCOUNT] CANCEL_TREASURY_ACCOUNT_TRANSACTION_SUCCESS",

	REPROCESS_TREASURY_ACCOUNT_TRANSACTION_REQUEST = "[TREASURY ACCOUNT] REPROCESS_TREASURY_ACCOUNT_TRANSACTION_REQUEST",
	REPROCESS_TREASURY_ACCOUNT_TRANSACTION_FAILED = "[TREASURY ACCOUNT] REPROCESS_TREASURY_ACCOUNT_TRANSACTION_FAILED",
	REPROCESS_TREASURY_ACCOUNT_TRANSACTION_SUCCESS = "[TREASURY ACCOUNT] REPROCESS_TREASURY_ACCOUNT_TRANSACTION_SUCCESS",

	SET_VALUE = "[TREASURY ACCOUNT] SET_VALUE",
	CLEAR_VALUES = "[TREASURY ACCOUNT] CLEAR_VALUES"
}

export interface ITreasuryAccountTransfer {
	id: string;
	createdById: string;
	businessId: string;
	business: string;
	value: number;
	type: number;
	status: number;
}

export interface ITreasuryAccountTransferState {
	items: ITreasuryAccountTransfer[];
	pages: number;
	loading: boolean;
	model?: any[];
	returnErrors?: any[];
	importTATransfersData?: any[];
	showModalUser?: boolean;
	showModalType?: boolean;
	showModalBusiness?: boolean;
}

export interface ActionPayload {
	type: string;
	payload?: {
		item?: ITreasuryAccountTransfer;
		items?: ITreasuryAccountTransfer[];
		pages?: number;
	};
}
