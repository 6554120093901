import React from "react";
import { requestItems } from "store/reducers/requestCards/types";

import {
	Container,
	RowGray,
	TextAdress,
	TextDestiny,
	TextQuantity,
	Title
} from "./styles";

interface iProps {
	item: requestItems;
	index: number;
	cnpj: string;
}
const Request: React.FC<iProps> = ({ item, index, cnpj }) => {
	const {
		quantityCard,
		document,
		name,
		city,
		state,
		zipcode,
		complement,
		neighborhood,
		number,
		street,
		phone
	} = item;

	return (
		<>
			<Container>
				<Title> Pedido #{index + 1} </Title>
				<TextQuantity>
					{quantityCard} unidades cartão Wiipo Flex para o cnpj {cnpj}
				</TextQuantity>
				<TextAdress>
					Endereço pra entrega: {street} - {city},{state},{neighborhood} -{" "}
					{number} CEP {zipcode}
				</TextAdress>
				<TextDestiny>
					Destinatário: {name}, CPF {document}, Telefone {phone}{" "}
					{complement ? "," + complement : ""}
				</TextDestiny>
			</Container>
			<RowGray />
		</>
	);
};

export default Request;
