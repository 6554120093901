import { modalAlert } from "Components/Modals/ModalAlert";
import { getErrors } from "helpers/error";
import ls from "Localization";
import * as api from "services/api";

import { ActionPayload, ActionTypes, IBalanceAccountType } from "../types";

export interface IBalanceAccountTypeRequest {
	skip?: number;
	take?: number;
	filter?: any;
	sort?: string;
	desc?: boolean;
}

interface ItemsData {
	fromPrePaidBalanceAccountTypeId: string;
	toPrePaidBalanceAccountTypeId: string;
	status: boolean;
}

interface IBalanceAccountTypeTransfer {
	balanceAccountId: string;
	callback: (err, model) => void;
	data?: ItemsData[];
}

interface IState {
	treasuryAccountTransactionCreate: IBalanceAccountType;
	[key: string]: any;
}

interface ICreateBalanceAccountType {
	name: string;
	externalId: string;
	description: string;
	icon?: File;
	banner?: File;
}

interface IUpdateBalanceAccountType extends ICreateBalanceAccountType {
	id: string;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: ActionTypes.SET_VALUE, payload });
	};
}

export function clearValues(payload = false) {
	return { type: ActionTypes.CLEAR_VALUES, payload };
}

export const getBalanceAccountTypes =
	({
		skip,
		take,
		filter,
		sort = "creationDate",
		desc = true
	}: IBalanceAccountTypeRequest) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_BALANCE_ACCOUNT_TYPES_REQUEST });

		const queryString = new URLSearchParams({
			skip: String(skip),
			take: String(take),
			...(filter || { filters: null }),
			sort,
			desc: desc === true ? "true" : "false"
		}).toString();
		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/PrePaid/BalanceAccount/Type?${queryString}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				const { count = 0 } = result.params || { count: 0 };

				return dispatch({
					type: ActionTypes.GET_BALANCE_ACCOUNT_TYPES_SUCCESS,
					payload: { types: result.model, pages: Math.ceil(count / take || 1) }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.GET_BALANCE_ACCOUNT_TYPES_FAILED
			});
		}
	};

export const upload = async (image, id, token, type) => {
	const uploaded = {
		result: false,
		type
	};

	try {
		let response = await api.sendPostFile(
			`/PrePaid/BalanceAccount/Type/${id}/Upload/${type}`,
			image,
			{
				Authorization: `Bearer ${token}`
			}
		);

		let result = await response.json();

		if (!result.isValid) {
			throw new Error("Ocorreu algum erro na tentativa de inserção de dados");
		}

		uploaded.result = true;
	} catch (e) {
		//
	}

	return uploaded;
};

export const createUploadBalanceAccountType =
	(
		{ name, externalId, description, icon, banner }: ICreateBalanceAccountType,
		callback
	) =>
	async (dispatch, getState) => {
		dispatch({ type: ActionTypes.UPLOAD_BALANCE_ACCOUNT_TYPES_REQUEST });

		try {
			const { login } = getState();
			let response = await api.sendPost(
				"/PrePaid/BalanceAccount/Type",
				{ name, externalId, description },
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (!result.isValid) {
				throw new Error("Ocorreu algum erro na tentativa de inserção de dados");
			}

			const uploadErrorResult = [];

			if (icon instanceof File)
				uploadErrorResult.push(
					await upload(icon, result.model.id, login.token, "icon")
				);
			if (banner instanceof File)
				uploadErrorResult.push(
					await upload(banner, result.model.id, login.token, "banner")
				);

			let uploadFail = uploadErrorResult.filter(item => item.result === false);
			let error = null;

			if (uploadFail.length > 0) {
				error = "Error ao fazer upload";
				modalAlert({
					title: ls.errorTitle,
					body: `${ls.fileNotSended}: [${uploadFail
						.map(item => item.type)
						.join(", ")}]`
				});
			}

			callback && callback(error);

			return dispatch({
				type: ActionTypes.UPLOAD_BALANCE_ACCOUNT_TYPES_SUCCESS
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			callback && callback(error);
			return dispatch({
				type: ActionTypes.UPLOAD_BALANCE_ACCOUNT_TYPES_FAILED
			});
		}
	};

export const updateUploadBalanceAccountType =
	(
		{
			id,
			name,
			externalId,
			description,
			icon,
			banner
		}: IUpdateBalanceAccountType,
		callback
	) =>
	async (dispatch, getState) => {
		dispatch({ type: ActionTypes.UPLOAD_BALANCE_ACCOUNT_TYPES_REQUEST });
		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/PrePaid/BalanceAccount/Type/${id}`,
				{ name, externalId, description },
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (!result.isValid) {
				throw new Error(
					"Ocorreu algum erro na tentativa de atualização de dados"
				);
			}

			const uploadErrorResult = [];

			if (icon instanceof File)
				uploadErrorResult.push(
					await upload(icon, result.model.id, login.token, "icon")
				);

			if (banner instanceof File)
				uploadErrorResult.push(
					await upload(banner, result.model.id, login.token, "banner")
				);

			let uploadFail = uploadErrorResult.filter(item => item.result === false);

			let error = null;

			if (uploadFail.length > 0) {
				error = "Error ao fazer upload";
				modalAlert({
					title: ls.errorTitle,
					body: `${ls.fileNotSended}: [${uploadFail
						.map(item => item.type)
						.join(", ")}]`
				});
			}
			callback && callback(error);
			return dispatch({
				type: ActionTypes.UPLOAD_BALANCE_ACCOUNT_TYPES_SUCCESS
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			callback && callback(error);
			return dispatch({
				type: ActionTypes.UPLOAD_BALANCE_ACCOUNT_TYPES_FAILED
			});
		}
	};

export const getBalanceAccountType =
	(id: string, callback) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_BALANCE_ACCOUNT_TYPE_REQUEST });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/PrePaid/BalanceAccount/Type/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (!result.isValid) {
				const errors = getErrors(result.errors);
				callback && callback(errors);
				return dispatch({
					type: ActionTypes.GET_BALANCE_ACCOUNT_TYPE_FAILED,
					payload: errors
				});
			}

			callback && callback(null, result.model);

			return dispatch({
				type: ActionTypes.GET_BALANCE_ACCOUNT_TYPE_SUCCESS,
				payload: { item: result.model }
			});
		} catch (error) {
			console.log("error", error);

			callback && callback(error);
			return dispatch({
				type: ActionTypes.GET_BALANCE_ACCOUNT_TYPE_FAILED
			});
		}
	};

export const editBalanceAccountTypeStores =
	(data, callback) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.UPDATE_BUSINESS_REQUEST });

		try {
			const { login, balanceAccount } = getState();

			const { item } = balanceAccount;

			const response = await api.sendPut(
				`/PrePaid/BalanceAccount/Type/${item.id}/Business`,
				data,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (!result.isValid) {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				return dispatch({
					type: ActionTypes.UPDATE_BUSINESS_FAILED,
					payload: errors
				});
			}

			callback && callback(null, result.model);

			return dispatch({
				type: ActionTypes.UPDATE_BUSINESS_SUCCESS,
				payload: result.model
			});
		} catch (error) {
			return dispatch({
				type: ActionTypes.UPDATE_BUSINESS_FAILED
			});
		}
	};

export const getBusinessesTiny =
	() =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_BUSINESS_TINY_REQUEST });

		try {
			const { login } = getState();

			let response = await api.sendGet("/Business/Company/Tiny", {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (!result.isValid) {
				const errors = getErrors(result.errors);

				return dispatch({
					type: ActionTypes.GET_BUSINESS_TINY_FAILED,
					payload: errors
				});
			}

			return dispatch({
				type: ActionTypes.GET_BUSINESS_TINY_SUCCESS,
				payload: { businessesTiny: result.model }
			});
		} catch (error) {
			return dispatch({
				type: ActionTypes.GET_BUSINESS_TINY_FAILED
			});
		}
	};

export const updateBusinessTransferPolicies =
	(data, callback?) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.UPDATE_BUSINESS_PERMISSION_REQUEST });

		try {
			const { login, balanceAccount } = getState();

			const { item } = balanceAccount;

			let response = await api.sendPut(
				`/PrePaid/BalanceAccount/Type/${item.id}/Business/Transfer`,
				data,
				{ Authorization: `Bearer ${login.token}` }
			);

			let result = await response.json();

			if (!result.isValid) {
				const errors = getErrors(result.errors);

				callback && callback(errors);

				return dispatch({
					type: ActionTypes.UPDATE_BUSINESS_PERMISSION_FAILED,
					payload: errors
				});
			}

			callback && callback(null, result.model);

			return dispatch({
				type: ActionTypes.UPDATE_BUSINESS_PERMISSION_SUCCESS,
				payload: result.model
			});
		} catch (error) {
			return dispatch({
				type: ActionTypes.UPDATE_BUSINESS_PERMISSION_FAILED
			});
		}
	};

export const getBalanceAccountTypeForBusiness =
	(id, isStatusEnable: boolean) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.GET_BALANCE_ACCOUNT_TYPES_BUSINESS_REQUEST });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/PrePaid/BalanceAccount/Type/Business/${id}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (isStatusEnable) {
					result.model = result.model.filter(
						m => m.businesses.filter(b => b.status === 1).length > 0
					);
				}

				return dispatch({
					type: ActionTypes.GET_BALANCE_ACCOUNT_TYPES_BUSINESS_SUCCESS,
					payload: { types: result.model }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.GET_BALANCE_ACCOUNT_TYPES_BUSINESS_FAILED
			});
		}
	};
