import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Switch,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import Spinner from "Components/Spinner";
import dayjs from "dayjs";
import ls from "Localization";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 400,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		marginTop: 10,
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: 5
		}
	}
}));

function ExportConsumption({ open, business, handleClose, handleSubmit }) {
	const classes = useStyles({});

	const { loading } = useSelector<any, any>(s => s.businessDetail);

	const [from, setFrom] = useState<dayjs.Dayjs>();
	const [to, setTo] = useState<dayjs.Dayjs>();
	const [unifiedOfGroup, setUnifiedOfGroup] = useState<boolean>(false);

	useEffect(() => {
		if (open) {
			const renewalDay = business.config.renewalDay || 20;

			let f = dayjs();
			let t = dayjs();

			if (f.date() < renewalDay) f = f.subtract(1, "month");
			else t = t.add(1, "month");

			f = f.set("date", renewalDay);
			t = t.set("date", renewalDay - 1);

			setFrom(f);
			setTo(t);
		}
	}, [open, business]);

	const onSubmit = e => {
		e.preventDefault();

		handleSubmit(from, to, unifiedOfGroup);
	};

	return (
		<Modal
			aria-labelledby="edit-config-modal-title"
			aria-describedby="edit-config-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.exportConsumption}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<DatePicker
										format={ls.dateFormatShort}
										label="De:"
										value={from}
										onChange={setFrom}
										inputVariant="outlined"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<DatePicker
										format={ls.dateFormatShort}
										label="Até:"
										value={to}
										onChange={setTo}
										inputVariant="outlined"
										fullWidth
									/>
								</Grid>
								{business.headquartersId !== null ||
									(business.isHeadquarters &&
										business.headquartersId === null && (
											<Grid item xs={12}>
												<Switch
													checked={unifiedOfGroup}
													onChange={() => setUnifiedOfGroup(!unifiedOfGroup)}
													name="unifiedOfGroup"
													color="primary"
												/>
												{ls.unifiedOfGroup}
											</Grid>
										))}
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.exportConsumption}
								>
									{ls.export}
									{loading.exportConsumption && (
										<Spinner color="secondary" size={16} />
									)}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ExportConsumption;
