import { TextFieldProps } from "@material-ui/core/TextField";
import React, { useRef } from "react";

import { StyledTextField } from "./styles";

interface TextInputProps extends Omit<TextFieldProps, "onChange"> {
	value: string | number;
	disabled?: boolean;
	isPadding?: boolean;
	fullWidth?: boolean;
	onChange?(id: string, value: string): void;
	id: string;
}

export default function TextInputGeneric({
	value,
	variant = "outlined",
	isPadding = true,
	disabled = false,
	fullWidth = true,
	onChange,
	id,
	...rest
}: TextInputProps) {
	const inputRef = useRef(null);

	return (
		<StyledTextField
			isPadding={isPadding}
			inputRef={inputRef}
			fullWidth={fullWidth}
			disabled={disabled}
			variant={variant}
			value={value}
			onChange={e => onChange(id, e.target.value)}
			{...rest}
		/>
	);
}

TextInputGeneric.defaultProps = {
	isPadding: true,
	onChange: undefined,
	fullWidth: true
};
