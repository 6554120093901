import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import Checkbox from "Components/Checkbox";
import TextInput from "Components/TextInput";
import { CustomFile } from "helpers/files";
import React from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import useStyles from "views/Dashboard/Marketing/Create/styles";

import TabPanel from "../TabPanel";

interface WhatsAppTabProps {
	index: number;
	tab: number;
	handleChange: (key: any, value: any) => void;
	errors: any;
	whatsAppImage: CustomFile;
	inputFileRef: any;
	whatsAppFile: any;
	selectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
	whatsAppMessage: string;
	whatsAppRestrict: boolean;
	previewImage: string;
	clearWhatsAppImage: () => void;
}

const WhatsAppTab: React.FC<WhatsAppTabProps> = ({
	errors,
	handleChange,
	index,
	tab,
	whatsAppImage,
	inputFileRef,
	selectFile,
	whatsAppFile,
	whatsAppMessage,
	whatsAppRestrict,
	previewImage,
	clearWhatsAppImage
}) => {
	const classes = useStyles({});

	return (
		<TabPanel value={tab} index={index}>
			<Grid container spacing={2} className={classes.form}>
				<Grid item xs={12}>
					<div>
						<Button
							variant="contained"
							color="primary"
							onClick={() => inputFileRef.current?.click()}
						>
							Adicionar arquivo
						</Button>
						<Typography style={{ display: "inline-block", marginLeft: 8 }}>
							{whatsAppImage?.name ?? "Nenhum arquivo selecionado"}
						</Typography>
						<input
							style={{ display: "none" }}
							type="file"
							ref={inputFileRef}
							onChange={selectFile}
						/>
					</div>
					{(whatsAppFile || whatsAppImage) && (
						<div
							style={{
								width: "100%",
								position: "relative",
								marginTop: 15
							}}
						>
							<IconButton
								style={{ position: "absolute", zIndex: 1 }}
								onClick={() => {
									clearWhatsAppImage();
									handleChange("whatsAppFile", null);
								}}
							>
								<AiFillCloseCircle
									size={22}
									color="#ccc"
									style={{
										position: "relative"
									}}
								/>
							</IconButton>
							<img
								src={whatsAppFile || previewImage}
								alt="preview"
								style={{
									width: "auto",
									height: 96,
									objectFit: "cover",
									borderRadius: 10,
									boxShadow: "rgb(0, 0, 0, 0.25) 5px 5px 10px"
								}}
							/>
						</div>
					)}
				</Grid>
				<Grid item xs={12}>
					<TextInput
						id="whatsAppMessage"
						required
						variant="outlined"
						name="whatsAppMessage"
						value={whatsAppMessage}
						errors={errors}
						multiline
						rows={6}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<Checkbox
						id="whatsAppRestrict"
						name="whatsAppRestrict"
						checked={whatsAppRestrict}
						onChange={handleChange}
					/>
				</Grid>
			</Grid>
		</TabPanel>
	);
};

export default WhatsAppTab;
