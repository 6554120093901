import * as api from "services/api";
import { getErrors } from "helpers/error";
import ls from "Localization";
import _ from "lodash";
import { Callback } from "Types/Callback";

export const GET_PRODUCT_CATEGORIES =
	"[PRODUCT_CATEGORY] GET_PRODUCT_CATEGORIES";
export const GET_PRODUCT_CATEGORIES_SUCCESS =
	"[PRODUCT_CATEGORY] GET_PRODUCT_CATEGORIES_SUCCESS";
export const GET_PRODUCT_CATEGORIES_FAILED =
	"[PRODUCT_CATEGORY] GET_PRODUCT_CATEGORIES_FAILED";

export const GET_PRODUCT_CATEGORY = "[PRODUCT_CATEGORY] GET_PRODUCT_CATEGORY";
export const GET_PRODUCT_CATEGORY_SUCCESS =
	"[PRODUCT_CATEGORY] GET_PRODUCT_CATEGORY_SUCCESS";
export const GET_PRODUCT_CATEGORY_FAILED =
	"[PRODUCT_CATEGORY] GET_PRODUCT_CATEGORY_FAILED";

export const CREATE_PRODUCT_CATEGORY =
	"[PRODUCT_CATEGORY] CREATE_PRODUCT_CATEGORY";
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
	"[PRODUCT_CATEGORY] CREATE_PRODUCT_CATEGORY_SUCCESS";
export const CREATE_PRODUCT_CATEGORY_FAILED =
	"[PRODUCT_CATEGORY] CREATE_PRODUCT_CATEGORY_FAILED";

export const UPDATE_PRODUCT_CATEGORY =
	"[PRODUCT_CATEGORY] UPDATE_PRODUCT_CATEGORY";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS =
	"[PRODUCT_CATEGORY] UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAILED =
	"[PRODUCT_CATEGORY] UPDATE_PRODUCT_CATEGORY_FAILED";

export const DELETE_PRODUCT_CATEGORY =
	"[PRODUCT_CATEGORY] DELETE_PRODUCT_CATEGORY";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
	"[PRODUCT_CATEGORY] DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAILED =
	"[PRODUCT_CATEGORY] DELETE_PRODUCT_CATEGORY_FAILED";

export const SET_VALUE = "[PRODUCT_CATEGORY] SET_VALUE";
export const CLEAR_VALUES = "[PRODUCT_CATEGORY] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues(payload: boolean = false) {
	return { type: CLEAR_VALUES, payload };
}

export function getProductCategories(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PRODUCT_CATEGORIES });

		try {
			const { login } = getState();

			const response = await api.sendGet(
				`/ProductCategory?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_PRODUCT_CATEGORIES_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PRODUCT_CATEGORIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_PRODUCT_CATEGORIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getProductCategory(id, callback?: Callback<any>) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PRODUCT_CATEGORY });

		try {
			const { login } = getState();

			const response = await api.sendGet(`/ProductCategory/${id}`, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_PRODUCT_CATEGORY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PRODUCT_CATEGORY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_PRODUCT_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createProductCategory(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_PRODUCT_CATEGORY });

		try {
			const { login, productCategory, businessDetail } = getState();

			const { name } = productCategory;

			const errors: any = {};

			if (!name) errors.name = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: CREATE_PRODUCT_CATEGORY_FAILED,
					payload: errors
				});
			}

			const model = {
				name,
				businessId: businessDetail.item.id
			};

			const response = await api.sendPost("/ProductCategory", model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: CREATE_PRODUCT_CATEGORY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_PRODUCT_CATEGORY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: CREATE_PRODUCT_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editProductCategory(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_PRODUCT_CATEGORY });

		try {
			const { login, productCategory } = getState();

			const { item, name } = productCategory;

			const errors: any = {};

			if (!name) errors.name = ls.required;

			if (_.keys(errors).length > 0) {
				return dispatch({
					type: UPDATE_PRODUCT_CATEGORY_FAILED,
					payload: errors
				});
			}

			const model = {
				...item,
				name
			};

			const response = await api.sendPut("/ProductCategory/" + item.id, model, {
				Authorization: "Bearer " + login.token
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: UPDATE_PRODUCT_CATEGORY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: UPDATE_PRODUCT_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function deleteProductCategory(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: DELETE_PRODUCT_CATEGORY });

		try {
			const { login } = getState();

			const response = await api.sendDelete(`/ProductCategory/${id}`, {
				Authorization: "Bearer " + login.token
			});

			if (response.ok) {
				dispatch({
					type: DELETE_PRODUCT_CATEGORY_SUCCESS,
					payload: id
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: DELETE_PRODUCT_CATEGORY_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: DELETE_PRODUCT_CATEGORY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
