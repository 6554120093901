import React, { useEffect, useRef, useState } from "react";

interface ILoadOnScrollProps {
	load: () => void;
	children: JSX.Element | JSX.Element[];
}

const LoadOnScroll: React.FC<ILoadOnScrollProps> = ({ load, children }) => {
	const contentElement = useRef<HTMLDivElement>(null);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			if (contentElement && contentElement.current) {
				const rect = contentElement.current.getBoundingClientRect();
				if (
					rect.top -
						(window.innerHeight || document.documentElement.clientHeight) <=
						0 &&
					!isLoaded
				) {
					setIsLoaded(true);
					load();
				}
			}
		};

		window.addEventListener("scroll", onScroll);
		onScroll();

		return () => window.removeEventListener("scroll", onScroll);
	}, [isLoaded, load]);

	return <div ref={contentElement}>{children}</div>;
};

export default LoadOnScroll;
