import Report from "Components/Report";
import SwitchGeneric from "Components/SwitchGeneric";
import {
	Button,
	TextButton
} from "Components/views/Dashboard/Business/Create/ModalSelectImport/styles";
import { stringSort } from "helpers/string";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	createBusinessGroup,
	getBatchBusiness,
	setValue
} from "store/actions/business/createBatch";

import {
	BackGroundButton,
	CNPJ,
	Container,
	Label,
	RowCheckBox
} from "./styles";

let createBatchBusinessDebounced: (() => void) & _.Cancelable = null;
let getBatchBusinessDebounced: (() => void) & _.Cancelable = null;

const ReportBatch: React.FC = () => {
	const dispatch = useDispatch();
	const table = useRef(null);
	const [loadingBatch, setLoadingBatch] = useState(false);

	const { codCli, items } = useSelector<any, any>(s => s.businessBatch);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const [isClubeWiipo, setIsClubeWiipo] = useState(false);
	const [isWiipoFlex, setIsWiipoFlex] = useState(false);
	const [isHcmPartial, setIsHcmPartial] = useState(false);

	const { loading } = useSelector<any, any>(s => s.businessBatch);

	const service = useMemo(() => {
		if (isWiipoFlex && !isClubeWiipo) return 1;
		if (!isWiipoFlex && isClubeWiipo) return 4;
		if (!isWiipoFlex && !isClubeWiipo) return 0;
		return 5;
	}, [isClubeWiipo, isWiipoFlex]);

	const editArrayService = useCallback(() => {
		const newData = items?.map(i => {
			i.service = service;
			i.isHcmPartial = isHcmPartial;

			return i;
		});
		handleChange("items", newData);
	}, [handleChange, isHcmPartial, items, service]);

	const isHeadquarters = items?.find(i => i.content.type === 4);

	const editData = useCallback(
		(cnpj: string) => {
			const newData = items?.map(i => {
				i.cnpj === cnpj ? (i.type = 4) : (i.type = 2);

				return i;
			});

			handleChange("items", newData);
		},
		[handleChange, items]
	);

	const pages = Math.ceil(items?.length / 10);

	const textAlignCenter = useCallback(
		(text: string, component?: JSX.Element) => (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					width: "100%"
				}}
			>
				{text || component}
			</div>
		),
		[]
	);

	const onRowClickedCheckBox = useCallback(
		(value, checked) => {
			const newDataSelected = [...items];

			const findItem = newDataSelected.find(i => i["cnpj"] === value.cnpj);

			findItem.checked = checked;

			handleChange("items", newDataSelected);
		},
		[handleChange, items]
	);

	const handleSelectAll = useCallback(
		(select: boolean) => {
			const newDataSelected = items?.map(i => ({
				...i,
				checked: select
			}));

			handleChange("items", newDataSelected);
		},
		[handleChange, items]
	);

	const isDisableButton = useMemo(() => {
		if (service === 0 || items?.filter(i => i.checked).length === 0) {
			return true;
		}

		if (loadingBatch) return true;

		return false;
	}, [items, loadingBatch, service]);

	const columns = useMemo(
		() =>
			[
				{
					Header: ls.cnpjRelaction,
					id: "cnpj",
					accessor: c => textAlignCenter(c.cnpj),
					width: 280,
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.businessName,
					id: "name",
					accessor: c => textAlignCenter(c.content.company_full_name),
					width: 280,
					sortMethod: stringSort,
					show: true
				},
				{
					Header: ls.headquarters,
					id: "headquarters",
					accessor: c =>
						textAlignCenter(
							null,
							<SwitchGeneric
								changeActive={() => editData(c.cnpj)}
								active={c.type === 4}
							/>
						),
					width: 100,
					sortMethod: stringSort,
					show: true,
					filterable: false
				}
			].filter(c => c),
		[editData, textAlignCenter]
	);

	const handleFetchData = useCallback(
		tableState => {
			let { filtered } = tableState;

			if (getBatchBusinessDebounced) {
				getBatchBusinessDebounced.cancel();
			}

			getBatchBusinessDebounced = _.debounce(() => {
				dispatch(
					getBatchBusiness(
						filtered.reduce((p, c) => p + `&${c.id}=${c.value}`, ""),
						(err, model) => {
							if (err) toastError(JSON.stringify(err));
							if (!model)
								toastError(
									"Codcli não encontrado/errado, digite novamente ou  mude para cadastro manual"
								);
						}
					)
				);
			}, 500);

			getBatchBusinessDebounced();
		},
		[dispatch]
	);

	const onSubmit = useCallback(() => {
		setLoadingBatch(true);
		if (createBatchBusinessDebounced) {
			createBatchBusinessDebounced.cancel();
		}

		createBatchBusinessDebounced = _.debounce(() => {
			dispatch(
				createBusinessGroup(err => {
					if (err) {
						setLoadingBatch(false);
						toastError(JSON.stringify(err));
					} else {
						setLoadingBatch(false);
						toastSuccess("Criado com sucesso");
					}
				})
			);
		}, 500);

		if (codCli || items?.filter(i => i.checked).length > 0)
			createBatchBusinessDebounced();
	}, [codCli, dispatch, items]);

	return (
		<>
			<Report
				manual
				data={items || []}
				isPaginationManual
				defaulSizePages={10}
				tableRef={table}
				pages={pages}
				onFetchData={handleFetchData}
				filterable
				useQueryString
				loading={loading}
				columns={columns}
				enableCheckBox
				onRowClickedCheckBox={(value, checked) =>
					onRowClickedCheckBox(value, checked)
				}
				handleSelectAll={selectAll => handleSelectAll(selectAll)}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				defaultSorted={[
					{
						id: "code",
						desc: true
					}
				]}
			/>
			<Container>
				<CNPJ>Matriz selecionada: {isHeadquarters?.cnpj || "-"} </CNPJ>
				<RowCheckBox>
					<SwitchGeneric
						changeActive={() => {
							setIsClubeWiipo(!isClubeWiipo);
							editArrayService();
						}}
						active={isClubeWiipo}
					/>
					<Label>Clube Wiipo</Label>
				</RowCheckBox>
				<RowCheckBox>
					<SwitchGeneric
						changeActive={() => {
							setIsWiipoFlex(!isWiipoFlex);
							editArrayService();
						}}
						active={isWiipoFlex}
					/>
					<Label>Wiipo Flex</Label>
				</RowCheckBox>
				<RowCheckBox>
					<SwitchGeneric
						changeActive={() => {
							setIsHcmPartial(!isHcmPartial);
							editArrayService();
						}}
						active={isHcmPartial}
					/>
					<Label>HCM Parcial</Label>
				</RowCheckBox>
			</Container>
			<BackGroundButton>
				<Button
					type="button"
					isButtonPurple
					onClick={onSubmit}
					isDisable={isDisableButton}
					disabled={isDisableButton}
				>
					<TextButton isButtonPurple>Continuar</TextButton>
				</Button>
			</BackGroundButton>
		</>
	);
};

export default ReportBatch;
