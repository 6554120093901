import React from "react";

import { FormControlLabel, Switch as SwitchMUI } from "@material-ui/core";
import { SwitchProps } from "@material-ui/core/Switch";

import ls from "../../Localization";

interface Props extends Omit<SwitchProps, "onChange"> {
	id: string;
	name: string;
	value: boolean;
	onChange(id: string, value: boolean): void;
	errors?: any;
}

export default function Switch({ id, name, value, onChange, ...rest }: Props) {
	return (
		<FormControlLabel
			control={
				<SwitchMUI
					checked={value}
					onChange={e => onChange(id, e.target.checked)}
					value={id}
					{...rest}
				/>
			}
			label={ls[name]}
		/>
	);
}
