/* eslint-disable consistent-return */
import { getErrors } from "helpers/error";
import _ from "lodash";
import * as api from "services/api";

export const TRACKING_ORDER_VIA_VAREJO =
	"[TRACKING_ORDER_VIA_VAREJO] TRACKING_ORDER_VIA_VAREJO";
export const GET_TRACKING_ORDER_VIA_VAREJO_SUCCESS =
	"[TRACKING_ORDER_VIA_VAREJO] GET_TRACKING_ORDER_VIA_VAREJO_SUCCESS";
export const GET_TRACKING_ORDER_VIA_VAREJO_FAILED =
	"[TRACKING_ORDER_VIA_VAREJO] GET_TRACKING_ORDER_VIA_VAREJO_FAILED";

export const SET_VALUE = "[TRACKING_ORDER_VIA_VAREJO] SET_VALUE";
export const CLEAR_VALUES = "[TRACKING_ORDER_VIA_VAREJO] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getTrackingOrder(idPurchase: number, callback?: Function) {
	return async (dispatch, getState) => {
		dispatch({ type: TRACKING_ORDER_VIA_VAREJO });

		try {
			const { login } = getState();

			const errors: any = {};
			dispatch(setValue({ errors }));
			if (_.keys(errors).length > 0) return;

			const response = await api.sendGet(`/via-varejo/tracking/${idPurchase}`, {
				Authorization: `Bearer ${login.token}`
			});

			if (response.ok) {
				let result = await response.json();
				dispatch({
					type: GET_TRACKING_ORDER_VIA_VAREJO_SUCCESS,
					payload: result.model
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_TRACKING_ORDER_VIA_VAREJO_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: GET_TRACKING_ORDER_VIA_VAREJO_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
