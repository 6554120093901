const storesViaVarejoIds = [
	"8726cc53-2207-47df-22b3-08daae1f04b1",
	"2b0d05ed-6de9-446f-b792-08daab8b1eab",
	"2b2ede59-46c8-4a29-22b4-08daae1f04b1",
	"4a535e66-1f44-4c2f-b793-08daab8b1eab",
	"ca986168-77e8-4b00-22b2-08daae1f04b1",
	"c5c73f12-ac5d-44d8-645e-08d99973b5ca"
];

export const storeIsViaVarejo = (storeId: string) =>
	storesViaVarejoIds.includes(storeId);
