import styled from "styled-components";

export const Container = styled.div``;

export const ButtonTaxClub = styled.a`
	width: 100%;
	text-align: center;
	text-decoration: underline;
	cursor: pointer;
	color: #5236d1;
`;
