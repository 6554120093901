import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Spinner from "Components/Spinner";
import ls from "Localization";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTable from "react-table";
import { importProducts, setValue } from "store/actions/product";

import { toStringCurrency } from "../../../../helpers/string";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: "calc(80%)",
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

let debounceImportProducts: (() => void) & _.Cancelable = null;

function ImportProducts() {
	const classes = useStyles({});
	const dispatch = useDispatch();

	const { loading, ifood } = useSelector<any, any>(s => s.product);
	const [returnErrors, setReturnErrors] = useState([]);

	const [data, setData] = useState([]);

	useEffect(() => {
		if (ifood && ifood.products) setData(ifood.products || []);
	}, [ifood]);

	const onSubmit = e => {
		e.preventDefault();

		if (debounceImportProducts) debounceImportProducts.cancel();

		debounceImportProducts = _.debounce(() => {
			dispatch(
				importProducts(data, err => {
					if (err) {
						if (err.default) alert(err.default);
						else alert(err);
					} else {
						handleClose();
					}
				})
			);
		}, 1000);

		debounceImportProducts();
	};

	const handleClose = () => {
		dispatch(setValue({ ifood: null }));
	};

	const renderEditable = (cellInfo, c) => {
		let { removeFormat } = cellInfo.column;

		if (!removeFormat) removeFormat = c => c;

		return (
			<div
				style={{ backgroundColor: "#fafafa" }}
				contentEditable
				suppressContentEditableWarning
				onBlur={e => {
					data[cellInfo.index][cellInfo.column.id] = removeFormat(
						e.target.innerHTML
					);
					setData([...data]);
				}}
				dangerouslySetInnerHTML={{
					__html: cellInfo.value
				}}
			/>
		);
	};

	if (returnErrors.length > 0)
		return (
			<Modal
				aria-labelledby="import-user-modal-title"
				aria-describedby="import-user-modal-description"
				className={classes.modal}
				open
				onClose={() => setReturnErrors([])}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in>
					<div className={classes.paper}>
						<Typography variant="h6">
							Os seguintes usuários não foram importados por causa dos seguintes
							erros
						</Typography>
						<hr />
						<div className={classes.body}>
							<ReactTable
								data={returnErrors}
								columns={
									[
										{
											Header: ls.cpfcnpj,
											accessor: "cpf"
										},
										{
											Header: ls.description,
											accessor: "description"
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => setReturnErrors([])}>
									{ls.close}
								</Button>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		);

	return (
		<Modal
			aria-labelledby="import-product-modal-title"
			aria-describedby="import-product-modal-description"
			className={classes.modal}
			open={Boolean(ifood?.products)}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={Boolean(ifood?.products)}>
				<div className={classes.paper}>
					<Typography variant="h6">Importar produtos</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<ReactTable
								data={data}
								columns={
									[
										{
											Header: ls.code,
											accessor: "code",
											Cell: renderEditable
										},
										{
											Header: ls.name,
											accessor: "name",
											Cell: renderEditable
										},
										{
											Header: ls.description,
											accessor: "description",
											Cell: renderEditable
										},
										{
											Header: ls.price,
											id: "price",
											accessor: c => toStringCurrency(c.price / 100.0),
											removeFormat: c => String(c)?.match(/\d/g).join(""),
											Cell: renderEditable
										},
										{
											Header: "Categoria",
											id: "category",
											accessor: "category.name",
											removeFormat: c => ({ name: c }),
											Cell: renderEditable
										},
										{
											Header: "Foto",
											accessor: "imageUrl",
											Cell: renderEditable
										}
									] as any[]
								}
								defaultPageSize={10}
								className="-striped -highlight"
							/>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.import}
								>
									{ls.import}
									{loading.import && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default ImportProducts;
