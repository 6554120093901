import Report from "Components/Report";
import DateSelectFilter from "Components/Report/DateSelectorFilter";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { isInRole } from "helpers/auth";
import { cnpjMask, cpfMask } from "helpers/format";
import { storeIsViaVarejo } from "helpers/stores-via-varejo";
import { toStringCurrency, toStringPercentage } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import orderSaleStatus from "models/orderSaleStatus";
import React, { useMemo, useRef } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import * as actions from "store/actions/order/report";

dayjs.extend(customParseFormat);

let getOrdersDebounced: (() => void) & _.Cancelable = null;

function OrderReport({ state, login: { user }, getOrders, history }) {
	const table = useRef(null);

	const columns = useMemo(() => {
		const isAdmin = isInRole(user, ["admin"]);

		return [
			{
				Header: ls.creationDate,
				id: "creationDate",
				accessor: c =>
					dayjs(c.creationDate).format(ls.dateFormatWithoutSeconds),
				width: 180,
				show: true,
				sortMethod: (a, b, desc: boolean) => {
					let aD = dayjs(a, ls.dateFormatWithoutSeconds);
					let bD = dayjs(b, ls.dateFormatWithoutSeconds);

					return aD.isSame(bD) ? 0 : aD.isAfter(bD) ? 1 : -1;
				},
				Filter: DateSelectFilter
			},
			{
				Header: ls.code,
				id: "code",
				accessor: c => c.code,
				show: true,
				width: 80
			},
			{
				Header: ls.client,
				id: "clientName",
				accessor: c => c.clientName,
				show: true,
				width: 150
			},
			{
				Header: ls.cpf,
				id: "clientDocument",
				accessor: c => cpfMask(c.clientDocument),
				show: false,
				width: 150
			},
			{
				Header: ls.company,
				id: "companyName",
				accessor: c => c.companyName ?? ls.none,
				show: true,
				width: 180
			},
			{
				Header: ls.cnpj,
				id: "companyDocument",
				accessor: c =>
					c.companyDocument ? cnpjMask(c.companyDocument) : ls.none,
				show: false,
				width: 150
			},
			{
				Header: ls.store,
				id: "storeName",
				accessor: c => c.storeName ?? ls.none,
				show: true,
				width: 180
			},
			{
				Header: ls.cnpj,
				id: "storeDocument",
				accessor: c => (c.storeDocument ? cnpjMask(c.storeDocument) : ls.none),
				show: false,
				width: 150
			},
			{
				Header: ls.value,
				id: "value",
				format: c => c.value / 100,
				accessor: c => toStringCurrency(c.value / 100),
				Footer: () => <b>{toStringCurrency(state.value / 100)}</b>,
				show: true,
				width: 120
			},
			{
				Header: ls.installments,
				id: "installmentAmount",
				accessor: c => c.installmentAmount,
				show: false,
				width: 100
			},
			{
				Header: ls.delivery,
				id: "deliveryFee",
				format: c => c.deliveryPrice / 100,
				accessor: c => toStringCurrency(c.deliveryPrice / 100),
				Footer: () => <b>{toStringCurrency(state.deliveryFee / 100)}</b>,
				show: true,
				width: 120
			},
			{
				Header: ls.discount,
				id: "discount",
				format: c => c.discount / 100,
				accessor: c =>
					`${toStringCurrency(c.discount / 100)}(${toStringPercentage(
						c.discount,
						c.value
					)})`,
				Footer: () => (
					<b>
						{toStringCurrency(state.discount / 100)}(
						{toStringPercentage(state.discount, state.value)})
					</b>
				),
				show: true,
				width: 150
			},
			{
				Header: ls.finalValue,
				id: "finalValue",
				format: c => {
					if (storeIsViaVarejo(c.storeId))
						return toStringCurrency(c.value / 100);

					return (c.value - c.discount + c.deliveryPrice) / 100;
				},
				accessor: c => {
					if (storeIsViaVarejo(c.storeId))
						return toStringCurrency(c.value / 100);

					return toStringCurrency(
						(c.value - c.discount + c.deliveryPrice) / 100
					);
				},
				Footer: () => (
					<b>{toStringCurrency((state.value - state.discount) / 100)}</b>
				),
				show: true,
				width: 120
			},
			{
				Header: ls.status,
				id: "status",
				sortable: false,
				show: true,
				accessor: c => ls[orderSaleStatus[c.status]],
				Filter: ({ filter, onChange }) => (
					<select
						onChange={event => onChange(event.target.value)}
						style={{ width: "100%" }}
						value={filter ? filter.value : "all"}
					>
						<option value="">Todos</option>
						{Object.keys(orderSaleStatus).map(c => (
							<option key={c} value={c}>
								{ls[orderSaleStatus[c]]}
							</option>
						))}
					</select>
				)
			},
			{
				Header: ls.externalId,
				id: "externalId",
				sortable: false,
				show: true,
				accessor: c => c.externalId || ls.notDefined
			},
			isAdmin
				? {
						Header: ls.fee,
						id: "fee",
						sortable: true,
						show: true,
						format: c => c.fee / 100,
						accessor: c => toStringCurrency(c.fee / 100),
						Footer: () => <b>{toStringCurrency(state.fee / 100)}</b>
				  }
				: null,
			{
				Header: ls.deliveredDate,
				id: "deliveredDate",
				sortable: true,
				show: true,
				width: 180,
				format: c =>
					c.deliveredDate
						? dayjs(c.deliveredDate).format(ls.dateFormatWithoutSeconds)
						: ls.notInformed,
				accessor: c =>
					c.deliveredDate
						? dayjs(c.deliveredDate).format(ls.dateFormatWithoutSeconds)
						: ls.notInformed,
				Filter: DateSelectFilter
			},
			{
				Header: ls.valueTransferViaVarejo,
				id: "valueTransferViaVarejo",
				sortable: false,
				show: false,
				format: c => {
					if (storeIsViaVarejo(c.storeId)) return (c.value - c.fee) / 100;
					return ls.notDefined;
				},
				accessor: c => {
					if (storeIsViaVarejo(c.storeId))
						return toStringCurrency((c.value - c.fee) / 100);
					return ls.notDefined;
				}
			}
		].filter(c => c);
	}, [user]);

	const handleFetchData = (tableState, instance) => {
		let { page, pageSize, sorted, filtered, toExport, callback } = tableState;

		if (getOrdersDebounced) {
			getOrdersDebounced.cancel();
		}

		if (!sorted) sorted = [];

		let creationDate = filtered.find(c => c.id === "creationDate");

		if (creationDate?.value) {
			filtered = filtered.filter(c => c.id !== "creationDate");

			if (creationDate?.value.startDate) {
				filtered.push({
					id: "startDate",
					value: creationDate.value.startDate
				});
			}

			if (creationDate?.value.endDate)
				filtered.push({
					id: "endDate",
					value: creationDate.value.endDate
				});
		}

		let deliveredDate = filtered.find(c => c.id === "deliveredDate");

		if (deliveredDate?.value) {
			filtered = filtered.filter(c => c.id !== "deliveredDate");

			if (deliveredDate?.value.startDate) {
				filtered.push({
					id: "startDeliveredDate",
					value: deliveredDate.value.startDate
				});
			}

			if (deliveredDate?.value.endDate)
				filtered.push({
					id: "endDeliveredDate",
					value: deliveredDate.value.endDate
				});
		}

		getOrdersDebounced = _.debounce(
			() =>
				getOrders(
					page * pageSize,
					pageSize,
					filtered.reduce((p, c) => `${p}&filters[${c.id}]=${c.value}`, ""),
					sorted[0]?.id,
					sorted[0]?.desc,
					toExport,
					callback
				),
			500
		);

		getOrdersDebounced();
	};

	return (
		<div>
			<Helmet>
				<title>
					{ls.orders} | {ls.appName}
				</title>
			</Helmet>
			<Report
				manual
				tableRef={table}
				title={ls.orders}
				data={state.items}
				pages={state.pages}
				onFetchData={handleFetchData}
				filterable
				showExport
				useQueryString
				defaultFilterMethod={(filter, row) =>
					String(row[filter.id])
						.toLowerCase()
						.indexOf(filter.value.toLowerCase()) > -1
				}
				visibleColumns={columns.filter(c => c.show).map(c => c.Header)}
				columns={columns}
				loading={state.loading}
				defaultSorted={[
					{
						id: "code",
						desc: true
					}
				]}
				onRowClicked={row => history.push(`/Order/${row.id}`)}
			/>
		</div>
	);
}

const mapStateToProps = ({ orderReport: state, login }) => ({
	state,
	login
});

const mapDispatchToProps = { ...actions };

export default connect(mapStateToProps, mapDispatchToProps)(OrderReport);
