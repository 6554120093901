import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import { toastError } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
	getBalanceAccountTransfer,
	putBalanceAccountTransfer
} from "store/actions/balanceAccount/transfer";
import { BackgroundButton } from "views/Dashboard/Business/Detail/styles";

import SelectCheckBox from "../SelectCheckBox";
import TextPen from "../TextPen";
import { Button, Container, Header, Row } from "./styles";

let debounceGetTransferBags: (() => void) & _.Cancelable = null;
let debounceSubmitTransferBags: (() => void) & _.Cancelable = null;

interface PropsModal {
	businessId: string;
	balanceAccountId: string;
	isDisableButton: boolean;
	items: any[];
}

const ClubWiipoTransfer: React.FC<PropsModal> = ({
	businessId,
	balanceAccountId,
	isDisableButton,
	items
}) => {
	const dispatch = useDispatch();

	const [open, setOpen] = useState(false);
	const [filterItems, setFilterItems] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setFilterItems(items);
	}, [items]);
	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	const handleCancelEdit = useCallback(() => {
		setFilterItems([]);
		handleClose();
	}, [handleClose]);

	const getTransferBags = useCallback(() => {
		if (debounceGetTransferBags) {
			debounceGetTransferBags.cancel();
		}

		setLoading(true);

		debounceGetTransferBags = _.debounce(
			() =>
				dispatch(
					getBalanceAccountTransfer({
						balanceAccountId,
						businessId,
						callback: err => {
							if (err) {
								toastError(err);
							}

							setLoading(false);
						}
					})
				),
			500
		);
		if (balanceAccountId) {
			debounceGetTransferBags();
		}
	}, [balanceAccountId, businessId, dispatch]);

	const onSubmitTransferBags = useCallback(
		e => {
			e.preventDefault();
			setLoading(true);
			if (debounceSubmitTransferBags) {
				debounceSubmitTransferBags.cancel();
			}

			const removeName = filterItems.map(i => ({
				fromPrePaidBalanceAccountTypeId: i.fromPrePaidBalanceAccountTypeId,
				toPrePaidBalanceAccountTypeId: i.toPrePaidBalanceAccountTypeId,
				status: i.status
			}));

			debounceSubmitTransferBags = _.debounce(
				() =>
					dispatch(
						putBalanceAccountTransfer({
							businessId,
							data: removeName,
							callback: err => {
								if (err) {
									toastError(err);
								} else {
									handleClose();
								}

								setLoading(false);
							}
						})
					),
				500
			);

			debounceSubmitTransferBags();
		},
		[businessId, dispatch, filterItems, handleClose]
	);

	useEffect(() => {
		if (filterItems.length === 0) {
			getTransferBags();
		}
	}, [filterItems]);

	useEffect(() => {
		getTransferBags();
	}, [isDisableButton]);

	const handleReplaceValue = useMemo(() => {
		if (filterItems.length > 0) {
			const newArray = filterItems
				?.filter(i => i?.status)
				.map(i => i?.name)
				.join(",");

			return newArray;
		}

		return null;
	}, [filterItems]);

	const editFilterArray = useCallback(
		(id: string) => {
			const newArray = [...filterItems];

			const findItem = newArray.find(
				i => i?.toPrePaidBalanceAccountTypeId === id
			);

			if (findItem) {
				findItem.status = !findItem.status;
			}

			setFilterItems(newArray);
		},
		[filterItems]
	);

	return open ? (
		<ModalGeneric
			open={open}
			backgroundNone
			handleClose={() => handleClose()}
			styleModal={{ background: "none" }}
			stylePaper={{
				position: "absolute",
				minWidth: 182,
				top: "40%",
				right: 70,
				minHeight: 200,
				padding: 0,
				borderWidth: 1,
				borderColor: "#707070",
				borderStyle: "solid"
			}}
			body={() => (
				<Container>
					<Header>
						<p>Selecionar</p>
					</Header>
					<Row />
					<SelectCheckBox items={filterItems} editItems={editFilterArray} />
					<Row />
					<BackgroundButton>
						<form onSubmit={onSubmitTransferBags}>
							<Button
								type="button"
								onClick={handleCancelEdit}
								disabled={loading}
							>
								Cancel
							</Button>
							<Button type="submit" isPurple disabled={loading}>
								{loading ? <Spinner color="inherit" size={14} /> : ls.save}
							</Button>
						</form>
					</BackgroundButton>
				</Container>
			)}
		/>
	) : (
		<>
			{loading ? (
				<Spinner color="inherit" size={14} />
			) : (
				<TextPen
					text={handleReplaceValue}
					handleOpen={handleOpen}
					isDisableButton={isDisableButton}
				/>
			)}
		</>
	);
};

export default ClubWiipoTransfer;
