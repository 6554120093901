import * as actions from "store/actions/productPromotion";

import { IProductPromotion } from "./types/productPromotion";

const initialState: IProductPromotion = {
	item: null,
	name: "",
	price: 0,
	startAt: null,
	endAt: null,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_PRODUCT_PROMOTION: {
			return {
				...state,
				loading: { createPromotion: true },
				errors: initialState.errors
			};
		}

		case actions.CREATE_PRODUCT_PROMOTION_SUCCESS: {
			return {
				...state,
				loading: { createPromotion: false },
				item: null
			};
		}

		case actions.CREATE_PRODUCT_PROMOTION_FAILED: {
			return {
				...state,
				loading: { createPromotion: false },
				errors: payload
			};
		}

		case actions.DELETE_PRODUCT_PROMOTION: {
			return {
				...state,
				loading: { deleteProduct: true },
				errors: initialState.errors
			};
		}

		case actions.DELETE_PRODUCT_PROMOTION_SUCCESS: {
			return {
				...state,
				loading: { deleteProduct: false }
			};
		}

		case actions.DELETE_PRODUCT_PROMOTION_FAILED: {
			return {
				...state,
				loading: { deleteProduct: false },
				errors: payload
			};
		}

		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};

		case actions.CLEAR_VALUES:
			if (payload) return { ...initialState };

			return {
				...state,
				item: null
			};

		default: {
			return state;
		}
	}
};

export default reducer;
