import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
	root: {
		color: "#000000",
		"&$checked": {
			color: "#5734D9"
		}
	},
	checked: {}
});

export default useStyles;
