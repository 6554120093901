import dayjs from "dayjs";
import * as actions from "store/actions/transaction/create";

import { ICreateTransaction } from "../types/create";

const initialState: ICreateTransaction = {
	creationDate: dayjs(),
	storeId: "",
	companyId: "",
	clientId: "",
	creditId: "",
	product: "",
	quantity: 0,
	sellerId: "",
	value: 0,
	discount: 0,
	installmentAmount: 1,
	status: 2,
	paymentMethod: "",
	description: "",
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_TRANSACTION: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_TRANSACTION_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_TRANSACTION_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState,
				creationDate: dayjs()
			};
		default: {
			return state;
		}
	}
};

export default reducer;
