import { getErrors } from "helpers/error";
import { CNPJValidation, CPFValidation } from "helpers/validators";
import { isValidNumber } from "libphonenumber-js";
import ls from "Localization";
import _ from "lodash";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const CREATE_BUSINESS = "[BUSINESS CREATE] CREATE_BUSINESS";
export const CREATE_BUSINESS_SUCCESS =
	"[BUSINESS CREATE] CREATE_BUSINESS_SUCCESS";
export const CREATE_BUSINESS_FAILED =
	"[BUSINESS CREATE] CREATE_BUSINESS_FAILED";

export const ADD_IMAGE_TO_BUSINESS = "[BUSINESS CREATE] ADD_IMAGE_TO_BUSINESS";
export const ADD_IMAGE_TO_BUSINESS_SUCCESS =
	"[BUSINESS CREATE] ADD_IMAGE_TO_BUSINESS_SUCCESS";
export const ADD_IMAGE_TO_BUSINESS_FAILED =
	"[BUSINESS CREATE] ADD_IMAGE_TO_BUSINESS_FAILED";

export const SET_VALUE = "[BUSINESS CREATE] SET_VALUE";
export const CLEAR_VALUES = "[BUSINESS CREATE] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export const createBusiness =
	(callback?: Callback<any>) => async (dispatch, getState) => {
		dispatch({ type: CREATE_BUSINESS });

		try {
			const { login, businessCreate } = getState();

			const {
				name,
				socialName,
				cnpj,
				phoneNumber,
				street,
				number,
				complement,
				neighborhood,
				city,
				state,
				zipCode,
				country,
				category: categoryId,
				status,
				about,
				image,
				highlight,
				national,
				isStore,
				isCompany,
				isHeadquarters,
				isFranchise,
				teaser,
				hasHCMIntegrator,
				hasWiipoRequest,
				hasWiipoFlex,
				hasWiipoClub,
				isHcmPartial
			} = businessCreate;

			const errors: any = {};

			if (!name) errors.name = ls.required;
			if (!socialName) errors.socialName = ls.required;

			if (!cnpj) errors.cnpj = ls.required;
			else if (!CNPJValidation(cnpj) && !CPFValidation(cnpj)) {
				errors.cnpj = ls.invalidCnpj;
			}

			if (!phoneNumber) errors.phoneNumber = ls.required;
			else if (!isValidNumber(phoneNumber)) {
				errors.phoneNumber = ls.invalidPhoneNumber;
			}

			if (!national) {
				if (!street) errors.street = ls.emptyStreet;
				if (!number) errors.number = ls.emptyNumber;
				if (!neighborhood) errors.neighborhood = ls.required;
				if (!city) errors.city = ls.emptyCity;
				if (!state) errors.state = ls.required;
				if (!zipCode) errors.zipCode = ls.required;
			}

			if (!country) errors.country = ls.required;

			let type = 0;

			if (isStore) type += 1;
			if (isCompany) type += 2;
			if (isHeadquarters) type += 4;
			if (isFranchise) type += 8;

			if (isStore && !categoryId) errors.category = ls.required;
			if (!status && status !== 0) errors.status = ls.required;
			if (!type) errors.type = "Selecione pelo menos uma opção";

			let businessServices = 0;

			if (hasWiipoFlex) businessServices += 1;
			if (hasWiipoClub) businessServices += 4;

			if (_.keys(errors).length > 0) {
				dispatch(setValue({ errors, loading: false }));
				return;
			}

			let response = await api.sendPost(
				"/Business",
				{
					phoneNumber,
					business: {
						name,
						socialName,
						categoryId,
						status,
						type,
						service: businessServices,
						about,
						teaser,
						highlight,
						national,
						documents: [
							{
								type: 0,
								value: cnpj
							}
						],
						isHcmPartial
					},
					address: {
						street,
						number,
						complement,
						neighborhood,
						city,
						state,
						country,
						zipCode
					},
					hasHCMIntegrator,
					hasWiipoRequest
				},
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch(
					{
						type: CREATE_BUSINESS_SUCCESS,
						payload: result.model
					},
					setValue({ loading: false })
				);

				if (image) {
					dispatch(addImageToBusiness(result.model.id, image, 1, callback));
				} else {
					callback && callback(null, result.model);
				}
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: CREATE_BUSINESS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			dispatch({
				type: CREATE_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};

export function addImageToBusiness(id, image, type, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ADD_IMAGE_TO_BUSINESS });

		try {
			const { login } = getState();

			let response = await api.sendPostFile(
				`/Business/${id}/Image/${type}`,
				image,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.ok) {
				dispatch({
					type: ADD_IMAGE_TO_BUSINESS_SUCCESS
				});

				return callback && callback(null, id);
			}
			let result = await response.json();

			const errors = getErrors(result.errors);

			dispatch({
				type: ADD_IMAGE_TO_BUSINESS_FAILED,
				payload: errors
			});
			return callback && callback(errors);
		} catch (error) {
			callback && callback(error);

			return dispatch({
				type: ADD_IMAGE_TO_BUSINESS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
