import { Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import LoadOnScroll from "Components/LoadOnScroll";
import Spinner from "Components/Spinner";
import { isInRole } from "helpers/auth";
import { creditCardFormat } from "helpers/creditCard";
import ls from "Localization";
import virtualCardStatus from "models/virtualCardStatus";
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { FaFileImport, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import Board from "react-trello";
import * as actions from "store/actions/virtualCard";
import XLSX from "xlsx";

import CreateForm from "./Forms/Create";
import EditForm from "./Forms/Edit";
import ImportVirtualCards from "./Import";
import styles from "./styles";

function VirtualCard({ classes }) {
	const dispatch = useDispatch();
	const [data, setData] = useState(null);
	const [importVirtualCardData, setImportVirtualCardData] = useState(null);

	const inputRef = useRef<HTMLInputElement>(null);

	const { id } = useParams<any>();

	const { items, loading } = useSelector<any, any>(s => s.virtualCard);

	const { user } = useSelector<any, any>(s => s.login);

	const total = useMemo(() => {
		Object.keys(items)
			.map(c => items[c])
			.reduce((a, b) => a + b.length, 0);
	}, [items]);

	const load = useCallback(() => {
		dispatch(actions.clearValues(true));

		setData({
			lanes: Object.keys(virtualCardStatus).map(id => ({
				id: id.toString(),
				title: ls[virtualCardStatus[id]],
				label: "0",
				cards: []
			}))
		});

		dispatch(actions.getVirtualCards(0, 1000, `&filters[storeId]=${id}`));
	}, [dispatch, id]);

	useEffect(() => {
		let newData = {
			lanes: Object.keys(virtualCardStatus).map(id => ({
				id: id.toString(),
				title: ls[virtualCardStatus[id]],
				label: "0",
				cards: []
			}))
		};

		for (const lane of newData.lanes) {
			lane.cards = (items[lane.id] || [])
				.filter(c => c.storeId === id)
				.map(c => ({
					id: c.id,
					title: c.user?.fullName?.substr(0, 20),
					description: creditCardFormat(c.number),
					laneId: lane.id,
					label: c.user?.business?.business?.name.substr(0, 10) ?? ""
				}));

			lane.label = lane.cards.length.toString();
		}

		setData(newData);
	}, [id, items, total]);

	const handleDragEnd = useCallback(
		(cardId: string, srcLaneId: string, destLaneId: string) => {
			if (srcLaneId === destLaneId) return;

			dispatch(
				actions.editVirtualCardStatus(cardId, destLaneId, (err, _model) => {
					if (err) console.log(err);
					else {
						let item = items[srcLaneId].find(c => c.id === cardId);
						items[srcLaneId] = items[srcLaneId].filter(c => c.id !== cardId);
						items[destLaneId].push(item);

						dispatch(actions.setValue({ items: { ...items } }));
					}
				})
			);
		},
		[dispatch, items]
	);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(actions.setValue({ [id]: value }));
		},
		[dispatch]
	);

	const handleEdit = useCallback(
		(cardId, metadata, laneId) => {
			let item = items[laneId].find(c => c.id === cardId);

			dispatch(
				actions.setValue({
					showEditFormModal: Boolean(item),
					item,
					number: item?.number || "",
					status: item?.status || "0"
				})
			);
		},
		[dispatch, items]
	);

	const handleFilesChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files[0];

			const reader = new FileReader();
			const rABS = !!reader.readAsBinaryString;
			reader.onload = e => {
				/* Parse data */
				const bstr = e.target.result;
				const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
				/* Get first worksheet */
				const wsName = wb.SheetNames[0];
				const sheet = wb.Sheets[wsName];
				/* Update state */

				const ref = XLSX.utils.decode_range(sheet["!ref"]);

				for (let C = ref.s.c; C <= ref.e.c; ++C) {
					const cell = sheet[XLSX.utils.encode_cell({ r: ref.s.r, c: C })];
					if (cell.t === "s") {
						cell.v = cell.v.trim();
						if (cell.w) cell.w = cell.w.trim();
					}
				}

				const data = XLSX.utils.sheet_to_json(sheet, {});

				setImportVirtualCardData(
					data
						.map(c => ({
							cpf: String(c["CPF"])?.match(/\d/g).join("").padStart(11, "0"),
							number: c["Cartão"]?.match(/\d/g).join("")
						}))
						.filter(c => c.cpf && c.number)
				);
			};

			if (rABS) reader.readAsBinaryString(file);
			else reader.readAsArrayBuffer(file);
		},
		[]
	);

	const handleImportVirtualCard = useCallback(() => {
		inputRef.current.click();
	}, []);

	const isAdmin = isInRole(user, ["admin"]);

	return (
		<LoadOnScroll load={load}>
			{!data || loading.getAll ? (
				<></>
			) : (
				<>
					<Paper className={classes.root}>
						<div className={classes.header}>
							<Typography variant="h6">
								{ls.virtualCards}{" "}
								{loading.getAll && <Spinner color="secondary" size={16} />}
							</Typography>

							<div>
								{isAdmin && (
									<IconButton onClick={() => handleImportVirtualCard()}>
										<FaFileImport />
									</IconButton>
								)}
								<IconButton
									color="primary"
									onClick={() => handleChange("showFormModal", true)}
								>
									<FaPlus />
								</IconButton>
							</div>
						</div>
						<hr className={classes.line} />

						<Grid container spacing={2} className={classes.form}>
							<Board
								data={data}
								hideCardDeleteIcon
								handleDragEnd={handleDragEnd}
								laneStyle={{ height: 540 }}
								style={{ backgroundColor: "white", height: 600 }}
								onCardClick={handleEdit}
							/>
						</Grid>
					</Paper>
					<CreateForm />
					<EditForm />
					<ImportVirtualCards
						open={Boolean(importVirtualCardData)}
						items={importVirtualCardData}
						handleClose={() => setImportVirtualCardData(null)}
					/>
					<input
						ref={inputRef}
						style={{ display: "none" }}
						type="file"
						accept="application/excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
						onChange={handleFilesChange}
					/>
				</>
			)}
		</LoadOnScroll>
	);
}

export default withStyles(styles, { withTheme: true })(VirtualCard);
