import { modalAlert } from "Components/Modals/ModalAlert";
import { getErrors } from "helpers/error";
import ls from "Localization";
import * as api from "services/api";

export const GET_TRANSACTION = "[TRANSACTION DETAIL] GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS =
	"[TRANSACTION DETAIL] GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILED =
	"[TRANSACTION DETAIL] GET_TRANSACTION_FAILED";

export const GET_TRANSACTION_DETAIL = "[TRANSACTION DETAIL] GET_TRANSACTION_DETAIL";
export const GET_TRANSACTION_DETAIL_SUCCESS =
	"[TRANSACTION DETAIL] GET_TRANSACTION_DETAIL_SUCCESS";
export const GET_TRANSACTION_DETAIL_FAILED =
	"[TRANSACTION DETAIL] GET_TRANSACTION_DETAIL_FAILED";

export const ADD_EXTRA_PROPERTY_TRANSACTION =
	"[TRANSACTION DETAIL] ADD_EXTRA_PROPERTY_TRANSACTION";
export const ADD_EXTRA_PROPERTY_TRANSACTION_SUCCESS =
	"[TRANSACTION DETAIL] ADD_EXTRA_PROPERTY_TRANSACTION_SUCCESS";
export const ADD_EXTRA_PROPERTY_TRANSACTION_FAILED =
	"[TRANSACTION DETAIL] ADD_EXTRA_PROPERTY_TRANSACTION_FAILED";

export const GET_EXTRA_PROPERTY_TRANSACTION =
	"[TRANSACTION DETAIL] GET_EXTRA_PROPERTY_TRANSACTION";
export const GET_EXTRA_PROPERTY_TRANSACTION_SUCCESS =
	"[TRANSACTION DETAIL] GET_EXTRA_PROPERTY_TRANSACTION_SUCCESS";
export const GET_EXTRA_PROPERTY_TRANSACTION_FAILED =
	"[TRANSACTION DETAIL] GET_EXTRA_PROPERTY_TRANSACTION_FAILED";

export const SET_VALUE = "[TRANSACTION DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[TRANSACTION DETAIL] CLEAR_VALUES";

export const DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_REQUEST =
	"[TRANSACTION DETAIL] DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_REQUEST";
export const DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_SUCCES =
	"[TRANSACTION DETAIL] DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_SUCCES";
export const DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_FAILED =
	"[TRANSACTION DETAIL] DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_ERROR";

export const MAIL_TRANSACTION_SALE_PRODUCT_PDF_REQUEST =
	"[TRANSACTION DETAIL] MAIL_TRANSACTION_SALE_PRODUCT_PDF_REQUEST";
export const MAIL_TRANSACTION_SALE_PRODUCT_PDF_SUCCES =
	"[TRANSACTION DETAIL] MAIL_TRANSACTION_SALE_PRODUCT_PDF_SUCCES";
export const MAIL_TRANSACTION_SALE_PRODUCT_PDF_FAILED =
	"[TRANSACTION DETAIL] MAIL_TRANSACTION_SALE_PRODUCT_PDF_ERROR";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getTransaction(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_TRANSACTION });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/Sale/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_TRANSACTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_TRANSACTION_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_TRANSACTION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getTransactionDetail(id, callback?) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_EXTRA_PROPERTY_TRANSACTION });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/Transaction/Sale/${id}`, {
				Authorization: `Bearer ${login.token}`
			}, 'swap');

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_TRANSACTION_DETAIL_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_TRANSACTION_DETAIL_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_TRANSACTION_DETAIL_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getTransactionExtraProperties(id, callback?) {
    return async (dispatch, getState) => {
        dispatch({ type: GET_EXTRA_PROPERTY_TRANSACTION });
        try {
            const { login } = getState();
            let response = await api.sendGet(`/Sale/${id}/Property`, {
                Authorization: `Bearer ${login.token}`
            });
            let result = await response.json();
            if (result.isValid) {
                dispatch({
                    type: GET_EXTRA_PROPERTY_TRANSACTION_SUCCESS,
                    payload: result.model
                });
                callback && callback(null, result.model);
            } else {
                const errors = getErrors(result.errors);
                dispatch({
                    type: GET_EXTRA_PROPERTY_TRANSACTION_FAILED,
                    payload: errors
                });
                callback && callback(errors);
            }
        } catch (error) {
            callback && callback(error);
            dispatch({
                type: GET_EXTRA_PROPERTY_TRANSACTION_FAILED,
                payload: {
                    error: "Connection error",
                    exception: error
                }
            });
        }
    };
}

export function addExtraPropertyTransaction(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ADD_EXTRA_PROPERTY_TRANSACTION });

		try {
			const { login } = getState();
			let response = await api.sendPost(`/Sale/${id}/Property`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ADD_EXTRA_PROPERTY_TRANSACTION_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ADD_EXTRA_PROPERTY_TRANSACTION_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: ADD_EXTRA_PROPERTY_TRANSACTION_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function downloadPDF({ id, saleId }, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_REQUEST,
			payload: { id }
		});

		try {
			const { login } = getState();
			let response = await api.sendGet(`/Sale/${saleId}/Product/${id}/Pdf/`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			const blob = await (await fetch(result.model)).blob();

			let data = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			link.href = data;
			link.download = `${Date.now()}.pdf`;
			setTimeout(() => {
				link.click();
				// Firefox, necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);

			if (!result.isValid) {
				throw new Error("Error generating / downloading pdf");
			}

			callback && callback();
			return dispatch({
				type: DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_SUCCES,
				payload: { id }
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			callback && callback(error);
			return dispatch({
				type: DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_FAILED,
				payload: { id }
			});
		}
	};
}

export function downloadPDFAll({ id, saleId }, callback) {
	return async (dispatch, getState) => {
		try {
			const { login } = getState();
			let response = await api.sendGet(`/Sale/${saleId}/Product/Pdf/`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			const blob = await (await fetch(result.model)).blob();

			let data = window.URL.createObjectURL(blob);
			let link = document.createElement("a");
			link.href = data;
			link.download = `${Date.now()}.pdf`;
			setTimeout(() => {
				link.click();
				// Firefox, necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);

			if (!result.isValid) {
				throw new Error("Error generating / downloading pdf");
			}

			callback && callback();
			return dispatch({
				type: DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_SUCCES,
				payload: { id }
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			callback && callback(error);
			return dispatch({
				type: DOWNLOAD_TRANSACTION_SALE_PRODUCT_PDF_FAILED,
				payload: { id }
			});
		}
	};
}

export function sendMail({ id, saleId, email }, callback) {
	return async (dispatch, getState) => {
		dispatch({
			type: MAIL_TRANSACTION_SALE_PRODUCT_PDF_REQUEST,
			payload: { id }
		});

		try {
			const { login } = getState();
			let response = await api.sendPost(
				`/Sale/${saleId}/Product/${id}/Mail`,
				{ email },
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (!result.isValid) {
				throw new Error("Error sending email");
			}

			callback && callback();
			return dispatch({
				type: MAIL_TRANSACTION_SALE_PRODUCT_PDF_SUCCES,
				payload: { id }
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			callback && callback(error);
			return dispatch({
				type: MAIL_TRANSACTION_SALE_PRODUCT_PDF_FAILED,
				payload: { id }
			});
		}
	};
}

export function sendMailAll({ id, saleId, email }, callback) {
	return async (dispatch, getState) => {
		try {
			const { login } = getState();
			let response = await api.sendPost(
				`/Sale/${saleId}/Product/Mail`,
				{ email },
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (!result.isValid) {
				throw new Error("Error sending email");
			}

			callback && callback();
			return dispatch({
				type: MAIL_TRANSACTION_SALE_PRODUCT_PDF_SUCCES,
				payload: { id }
			});
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			callback && callback(error);
			return dispatch({
				type: MAIL_TRANSACTION_SALE_PRODUCT_PDF_FAILED,
				payload: { id }
			});
		}
	};
}
