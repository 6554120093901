import ModalSelector from "Components/ModalSelector";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	clearValues,
	editConsumptionPolicyStores,
	getStoresTiny,
	setValue
} from "store/actions/consumptionPolicy";

let debounceStoresForm: (() => void) & _.Cancelable = null;

function StoresForm() {
	const dispatch = useDispatch();

	const { showStoreFormModal, stores, storesTiny, loading } = useSelector<
		any,
		any
	>(c => c.consumptionPolicy);

	useEffect(() => {
		if (showStoreFormModal) dispatch(getStoresTiny());
	}, [dispatch, showStoreFormModal]);

	const handleClose = () => dispatch(setValue({ showStoreFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ businessId: u.id, status: u.status }));

		if (debounceStoresForm) debounceStoresForm.cancel();

		debounceStoresForm = _.debounce(() => {
			dispatch(
				editConsumptionPolicyStores(data, (err, model) => {
					if (err) {
						console.log(err);
						if (err.default) alert(err.default);
						else if (typeof err === "string") alert(err);
						else alert(JSON.stringify(err));
					} else {
						dispatch(clearValues());
						handleClose();
					}
				})
			);
		}, 1000);

		debounceStoresForm();
	};

	return (
		<ModalSelector
			open={showStoreFormModal}
			id="edit-consumptionPolicy-store"
			loading={loading}
			data={storesTiny.map(c => ({
				id: c.id,
				name: c.name,
				status: stores.find(d => d.businessId === c.id && d.status) ? 1 : 0
			}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="stores"
		/>
	);
}

export default StoresForm;
