import { Button, Grid, Radio, TextField, Typography } from "@material-ui/core";
import ModalGeneric from "Components/Modals/ModalGeneric";
import Spinner from "Components/Spinner";
import React from "react";
import { ApprovalMethod } from "views/Login";
import useStyles from "views/Login/styles";

interface Props {
	openVerificationCodeModal: boolean;
	onCloseModal: () => void;
	setApprovalMethod: (method: ApprovalMethod) => void;
	verificationCodeStep: number;
	setCode: (code: string) => void;
	approvalMethod: ApprovalMethod;
	disableResendButton: boolean;
	onClickResendCode: () => void;
	onClickSubmitCode: (step: number) => void;
	loading: boolean;
	code: string;
}

const UserVerificationCodeModal: React.FC<Props> = ({
	openVerificationCodeModal,
	onCloseModal,
	setApprovalMethod,
	verificationCodeStep,
	setCode,
	approvalMethod,
	disableResendButton,
	onClickResendCode,
	onClickSubmitCode,
	loading,
	code
}) => {
	const classess = useStyles({});
	return (
		<ModalGeneric
			open={openVerificationCodeModal}
			handleClose={onCloseModal}
			backgroundNone
			buttonClose
			disableBackdropClick
			styleModal={{
				borderWidth: 0,
				borderColor: "#FFF",
				backgroundColor: "rgba(0, 0, 0, 0.5)"
			}}
			stylePaper={{
				maxWidth: "40%",
				minWidth: "40%",
				borderWidth: 0,
				borderColor: "#FFF",
				borderRadius: 16,
				padding: 24,
				alignSelf: "center"
			}}
			body={() => (
				<>
					<Typography variant="h6" id="modal-title">
						{verificationCodeStep === 1
							? "Selecione o método de envio do seu código:"
							: `Digite o código enviado para o seu ${
									approvalMethod === ApprovalMethod.Email ? "e-mail" : "celular"
							  }:`}
					</Typography>
					<Grid container justify="center">
						<Grid container justify="flex-start" alignItems="center">
							{verificationCodeStep === 1 ? (
								<>
									<Grid container alignItems="center">
										<Radio
											checked={ApprovalMethod.Email === approvalMethod}
											onClick={() => setApprovalMethod(ApprovalMethod.Email)}
										/>
										<Typography>E-mail</Typography>
									</Grid>
									<Grid container alignItems="center">
										<Radio
											checked={ApprovalMethod.Sms === approvalMethod}
											onClick={() => setApprovalMethod(ApprovalMethod.Sms)}
										/>
										<Typography>SMS</Typography>
									</Grid>
								</>
							) : (
								<Grid item xs={12}>
									<TextField
										label="Código"
										placeholder="999999"
										type="number"
										value={code}
										className={classess.textField}
										onChange={e => setCode(e.target.value)}
										style={{ marginTop: 25, width: "100%" }}
									/>
								</Grid>
							)}

							<Grid item xs={12}>
								{verificationCodeStep === 2 && (
									<Button
										type="submit"
										disabled={disableResendButton}
										onClick={onClickResendCode}
									>
										Reenviar código
									</Button>
								)}
							</Grid>
							<Grid item xs={12}>
								<Button
									type="submit"
									className={classess.submitButton}
									disabled={
										(verificationCodeStep === 2 && code.length < 6) || loading
									}
									onClick={() => onClickSubmitCode(verificationCodeStep)}
								>
									{verificationCodeStep === 1 ? "Enviar código" : "Confirmar"}
									{loading ? (
										<Spinner style={{ height: 15, width: 15, margin: 5 }} />
									) : null}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		/>
	);
};
export default UserVerificationCodeModal;
