import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
	setValue,
	editCreditPolicyStores,
	getStoresTiny
} from "store/actions/credit/detail";

import ModalSelector from "Components/ModalSelector";

function StoresForm() {
	const dispatch = useDispatch();

	const { showStoreFormModal, stores, storesTiny, loading } = useSelector<
		any,
		any
	>(c => c.creditDetail);

	useEffect(() => {
		if (showStoreFormModal) dispatch(getStoresTiny());
	}, [dispatch, showStoreFormModal]);

	const handleClose = () => dispatch(setValue({ showStoreFormModal: false }));

	const onSubmit = options => {
		const data = options
			.filter(d => d.status)
			.map(c => ({
				businessId: c.id,
				status: c.status
			}));

		dispatch(
			editCreditPolicyStores(data, (err, model) => {
				if (err) {
					console.log(err);
					if (err.default) alert(err.default);
					else if (typeof err === "string") alert(err);
					else alert(JSON.stringify(err));
				} else {
					handleClose();
				}
			})
		);
	};

	if (!showStoreFormModal) return null;

	return (
		<ModalSelector
			open={showStoreFormModal}
			id={"edit-credit-store"}
			loading={loading}
			data={storesTiny
				.filter(c => c.name !== "Admin")
				.map(c => ({
					id: c.id,
					name: c.name,
					status: stores.find(d => d.businessId === c.id && d.status) ? 1 : 0
				}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="categories"
		/>
	);
}

export default StoresForm;
