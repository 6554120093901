import dayjs from "dayjs";
import { getErrors } from "helpers/error";
import { toast } from "react-toastify";
import * as api from "services/api";

export const GET_USER = "[USER DETAIL] GET_USER";
export const GET_USER_SUCCESS = "[USER DETAIL] GET_USER_SUCCESS";
export const GET_USER_FAILED = "[USER DETAIL] GET_USER_FAILED";

export const GET_USER_LIMIT = "[USER DETAIL] GET_USER_LIMIT";
export const GET_USER_LIMIT_SUCCESS = "[USER DETAIL] GET_USER_LIMIT_SUCCESS";
export const GET_USER_LIMIT_FAILED = "[USER DETAIL] GET_USER_LIMIT_FAILED";

export const GET_USER_TRANSACTIONS = "[USER DETAIL] GET_USER_TRANSACTIONS";
export const GET_USER_TRANSACTIONS_SUCCESS =
	"[USER DETAIL] GET_USER_TRANSACTIONS_SUCCESS";
export const GET_USER_TRANSACTIONS_FAILED =
	"[USER DETAIL] GET_USER_TRANSACTIONS_FAILED";

export const GET_USER_ALLOWANCE_POLICIES =
	"[USER DETAIL] GET_USER_ALLOWANCE_POLICIES";
export const GET_USER_ALLOWANCE_POLICIES_SUCCESS =
	"[USER DETAIL] GET_USER_ALLOWANCE_POLICIES_SUCCESS";
export const GET_USER_ALLOWANCE_POLICIES_FAILED =
	"[USER DETAIL] GET_USER_ALLOWANCE_POLICIES_FAILED";

export const ACTIVE_USER = "[USER DETAIL] ACTIVE_USER";
export const ACTIVE_USER_SUCCESS = "[USER DETAIL] ACTIVE_USER_SUCCESS";
export const ACTIVE_USER_FAILED = "[USER DETAIL] ACTIVE_USER_FAILED";

export const CHANGE_PASSWORD = "[USER DETAIL] CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "[USER DETAIL] CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "[USER DETAIL] CHANGE_PASSWORD_FAILED";

export const EDIT_USER_BENEFITS = "[USER EDIT] EDIT_USER_BENEFITS";
export const EDIT_USER_BENEFITS_SUCCESS =
	"[USER EDIT] EDIT_USER_BENEFITS_SUCCESS";
export const EDIT_USER_BENEFITS_FAILED =
	"[USER EDIT] EDIT_USER_BENEFITS_FAILED";

export const ADD_EXTRA_PROPERTY = "[USER EDIT] ADD_EXTRA_PROPERTY";
export const ADD_EXTRA_PROPERTY_SUCCESS =
	"[USER EDIT] ADD_EXTRA_PROPERTY_SUCCESS";
export const ADD_EXTRA_PROPERTY_FAILED =
	"[USER EDIT] ADD_EXTRA_PROPERTY_FAILED";

export const GET_EXTRA_PROPERTIES = "[USER EDIT] GET_EXTRA_PROPERTIES";
export const GET_EXTRA_PROPERTIES_SUCCESS =
	"[USER EDIT] GET_EXTRA_PROPERTIES_SUCCESS";
export const GET_EXTRA_PROPERTIES_FAILED =
	"[USER EDIT] GET_EXTRA_PROPERTIES_FAILED";

export const SET_VALUE = "[USER DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[USER DETAIL] CLEAR_VALUES";

export const GET_PREPAID_BALANCEACCCOUNT_USER =
	"[USER EDIT] GET_PREPAID_BALANCEACCCOUNT_USER";
export const GET_PREPAID_BALANCEACCCOUNT_USER_SUCCESS =
	"[USER EDIT] GET_PREPAID_BALANCEACCCOUNT_USER_SUCCESS";
export const GET_PREPAID_BALANCEACCCOUNT_USER_FAILED =
	"[USER EDIT] GET_PREPAID_BALANCEACCCOUNT_USER_FAILED";

export const GET_USER_FROM_SWAP_SUCCESS = "GET_USER_FROM_SWAP_SUCCESS";
export const GET_USER_FROM_SWAP_FAILED = "GET_USER_FROM_SWAP_FAILED";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getUser(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_USER });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/User/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_USER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getUserLimit(id, callback?, date = dayjs()) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_USER_LIMIT });

		try {
			const { login } = getState();
			let response = await api.sendGet(
				`/User/${id}/limit?date=${date.format()}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_LIMIT_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_USER_LIMIT_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_USER_LIMIT_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getUserTransactions(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_USER_TRANSACTIONS });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/User/${id}/Sale`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_TRANSACTIONS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_USER_TRANSACTIONS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_USER_TRANSACTIONS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getUserAllowances(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_USER_ALLOWANCE_POLICIES });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/User/${id}/Allowances`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_USER_ALLOWANCE_POLICIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_USER_ALLOWANCE_POLICIES_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_USER_ALLOWANCE_POLICIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getExtractUser(id, filters, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ACTIVE_USER });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/User/${id}/Export/Extract/Excel${filters}`,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			let link = document.createElement("a"); // Create <a>
			link.href = `data:${result.type};base64,${result.content}`;
			link.download = result.filename;
			link.click();

			callback && callback();
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: ACTIVE_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function activeUser(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ACTIVE_USER });

		try {
			const { login } = getState();

			let response = await api.sendPut(`/User/${id}/Active`, null, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ACTIVE_USER_SUCCESS,
					payload: result.model
				});

				dispatch(getUser(id, callback));
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ACTIVE_USER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: ACTIVE_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function changePassword(id, newPassword, confirmNewPassword, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_PASSWORD });

		try {
			const { login } = getState();

			let response = await api.sendPut(
				`/User/${id}/ChangePassword`,
				{ newPassword, confirmNewPassword },
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.ok) {
				dispatch({
					type: CHANGE_PASSWORD_SUCCESS
				});

				callback && callback();
			} else {
				let result = await response.json();

				const errors = getErrors(result.errors);

				dispatch({
					type: CHANGE_PASSWORD_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			return dispatch({
				type: CHANGE_PASSWORD_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function editUserBenefits(model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: EDIT_USER_BENEFITS });

		try {
			const { login, userDetail } = getState();

			const response = await api.sendPut(
				`/User/${userDetail.item.id}/Benefits`,
				model,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (result.params.moderation !== null)
					toast.warning(
						"Sua solicitação de alteração de limite foi enviada para moderação. Esse processo pode levar até 2 dias úteis",
						{ theme: "dark" }
					);

				dispatch({
					type: EDIT_USER_BENEFITS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: EDIT_USER_BENEFITS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: EDIT_USER_BENEFITS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getExtraProperties(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_EXTRA_PROPERTIES });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/User/${id}/Property`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_EXTRA_PROPERTIES_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_EXTRA_PROPERTIES_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_EXTRA_PROPERTIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function addExtraProperty(id, model, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: ADD_EXTRA_PROPERTY });

		try {
			const { login } = getState();
			let response = await api.sendPost(`/User/${id}/Property`, model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: ADD_EXTRA_PROPERTY_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: ADD_EXTRA_PROPERTY_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: ADD_EXTRA_PROPERTY_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function getPrePaidBalanceAccountForUser(id, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PREPAID_BALANCEACCCOUNT_USER });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/PrePaid/BalanceAccount/User/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();
			if (result.isValid) {
				result.model = result.model.filter(
					m =>
						m.balance > 0 ||
						m.type?.businesses?.filter(b => b.status === 1).length > 0
				);

				dispatch({
					type: GET_PREPAID_BALANCEACCCOUNT_USER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_PREPAID_BALANCEACCCOUNT_USER_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_PREPAID_BALANCEACCCOUNT_USER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
export function getUserFromSwap(id: string) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_PREPAID_BALANCEACCCOUNT_USER });

		try {
			const { login } = getState();
			let response = await api.sendGet(
				`/Account/${id}`,
				{
					Authorization: `Bearer ${login.token}`
				},
				"swap"
			);

			let result = await response.json();
			if (result.isValid) {
				dispatch({
					type: GET_USER_FROM_SWAP_SUCCESS,
					payload: result.model
				});
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_USER_FROM_SWAP_FAILED,
					payload: errors
				});
			}
		} catch (error) {
			dispatch({
				type: GET_USER_FROM_SWAP_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
