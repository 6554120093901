import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import { FormikProvider, useFormik } from "formik";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
	clearValues,
	createCampaign,
	updateCampaign
} from "store/actions/campaign/createEdit";
import { setValue } from "store/actions/campaign/createEdit";
import { State } from "store/reducers";
import { CampaignCreateEditState } from "store/reducers/campaign/createEdit";
import * as Yup from "yup";

import BannerDrop from "./BannerDrop";
import ProductsReport from "./ProductsReport";
import useStyles from "./styles";

const schema = Yup.object().shape({
	startAt: Yup.date().required(ls.requiredField),
	endAt: Yup.date().required(ls.requiredField),
	campaignSkus: Yup.array(Yup.number()).required(ls.requiredField),
	name: Yup.string().required(ls.requiredField),
	color: Yup.string().required(ls.requiredField)
});

let debounceColor: (() => void) & _.Cancelable = null;

const CreateEditCampaign: React.FC = () => {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const { item, loading } = useSelector<State, CampaignCreateEditState>(
		s => s.campaignCreateEdit
	);
	const history = useHistory();

	const isEdit = useMemo(() => window.location.href.includes("Edit"), []);

	const shouldEditOrCreate = useCallback(
		values => {
			dispatch(setValue({ item: values }));
			if (isEdit) {
				dispatch(
					updateCampaign(error => {
						if (error) {
							toastError(JSON.stringify(error));
						} else {
							toastSuccess("Sua campanha foi atualizada com sucesso!");
							setTimeout(() => {
								history.push("/CampaignClub");
							}, 1000);
						}
					})
				);
			} else {
				dispatch(
					createCampaign(error => {
						if (error) {
							toastError(JSON.stringify(error));
						} else {
							toastSuccess("Sua campanha foi criada com sucesso!");
							setTimeout(() => {
								history.push("/CampaignClub/Edit");
							}, 1000);
						}
					})
				);
			}
		},
		[dispatch, history, isEdit]
	);

	useEffect(() => {
		if (!isEdit) dispatch(clearValues());
	}, [isEdit, dispatch]);

	const formik = useFormik({
		initialValues: item,
		enableReinitialize: true,
		validationSchema: schema,
		onSubmit: shouldEditOrCreate
	});
	const disableSubmit = useMemo(
		() => !formik.isValid || loading.create || loading.edit || loading.upload,
		[formik.isValid, loading.create, loading.edit, loading.upload]
	);

	return (
		<FormikProvider value={formik}>
			<form
				onSubmit={e => {
					e.preventDefault();
					shouldEditOrCreate(formik.values);
				}}
			>
				<Grid container justify="center" spacing={5}>
					<Grid item xs={11}>
						<Paper className={classes.root}>
							<Grid
								container
								direction="row"
								xs={12}
								md={12}
								alignItems="center"
								justify="space-between"
							>
								<Grid item>
									<Typography variant="h6">
										{isEdit ? "Editar campanha" : "Criar nova campanha"}
									</Typography>
								</Grid>

								<Grid item justify="flex-end">
									<Button
										variant="contained"
										color="default"
										className={classes.button}
										onClick={() => {
											dispatch(clearValues());
										}}
									>
										{ls.clear}
									</Button>
									<Button
										variant="contained"
										style={{
											backgroundColor: disableSubmit ? "#D5D7DD" : "#00C460",
											color: "#FFF"
										}}
										className={classes.button}
										type="submit"
										disabled={disableSubmit}
									>
										{isEdit ? ls.save : ls.publish}{" "}
										{(loading.create || loading.edit || loading.upload) && (
											<Spinner color="secondary" size={16} />
										)}
									</Button>
								</Grid>
							</Grid>
							<hr className={classes.line} />
							<Grid container spacing={2} className={classes.form}>
								<Grid item xs={4} md={4} sm={12}>
									<TextInput
										id="name"
										variant="outlined"
										name="name"
										value={formik?.values?.name}
										fullWidth
										required
										errors={formik.errors.name}
										onChange={(id, value) => {
											formik.setFieldValue(id, value);
										}}
									/>
								</Grid>
								<Grid item xs={4} md={4} sm={12}>
									<DateTimePicker
										value={formik.values?.startAt}
										label={ls.startAt}
										fullWidth
										required
										onChange={date => {
											formik.setFieldValue("startAt", date);
										}}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
									/>
								</Grid>
								<Grid item xs={4} md={4} sm={12}>
									<DateTimePicker
										value={formik.values?.endAt}
										label={ls.endAt}
										fullWidth
										required
										minDate={formik?.values?.startAt}
										onChange={date => {
											formik.setFieldValue("endAt", date);
										}}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
									/>
								</Grid>
								<Grid item xs={4} md={4} sm={12}>
									<TextInput
										id="color"
										variant="outlined"
										name="color"
										value={formik?.values?.color}
										fullWidth
										required
										type="color"
										errors={formik.errors.color}
										onChange={(id, value) => {
											if (debounceColor) debounceColor.cancel();

											debounceColor = _.debounce(() => {
												formik.setFieldValue(id, value);
											}, 500);

											debounceColor();
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} sm={12}>
									<BannerDrop
										bannerUrl={formik?.values?.bannerUrl}
										onChooseImage={formData => {
											formik.setFieldValue("banner", formData);
										}}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={11} md={11} sm={6}>
						<ProductsReport
							skus={formik.values?.campaignSkus?.map(item => ({
								sku: item
							}))}
							onSelectReport={report => {
								formik.setFieldValue(
									"campaignSkus",
									report?.map(item => item.sku)
								);
							}}
						/>
					</Grid>
				</Grid>
			</form>
		</FormikProvider>
	);
};

export default CreateEditCampaign;
