import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: 200px;
`;

export const RowCheckBox = styled.div`
	display: flex;
	flex-direction: row;
`;
