import { getErrors } from "helpers/error";
import { Dispatch } from "redux";
import * as api from "services/api";

export const GET_REQUEST_CARDS = "[REQUEST_CARDS DETAIL] GET_REQUEST_CARDS";
export const GET_REQUEST_CARDS_SUCCESS =
	"[REQUEST_CARDS DETAIL] GET_REQUEST_CARDS_SUCCESS";
export const GET_REQUEST_CARDS_FAILED =
	"[REQUEST_CARDS DETAIL] GET_REQUEST_CARDS_FAILED";

export const SET_VALUE = "[REQUEST_CARDS DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[REQUEST_CARDS DETAIL] CLEAR_VALUES";

type SetValueAction = {
	type: string;
	payload: any;
};

type SetValueActionCreator = (
	payload: any
) => (dispatch: Dispatch<SetValueAction>) => void;

export const setValue: SetValueActionCreator =
	payload => (dispatch: Dispatch<SetValueAction>) => {
		dispatch({ type: SET_VALUE, payload });
	};

type ClearValuesAction = {
	type: string;
};

export function clearValues(): ClearValuesAction {
	return { type: CLEAR_VALUES };
}

type GetRequestCardsAction = (
	id: number | string,
	callback: (err?: any, model?: any) => void
) => (dispatch: Dispatch, getState: () => any) => Promise<void>;

export const getRequestCards: GetRequestCardsAction =
	(id, callback) =>
	async (dispatch: Dispatch, getState: () => any): Promise<void> => {
		dispatch({ type: GET_REQUEST_CARDS });

		try {
			const { login } = getState();
			let response = await api.sendGet(`/CardDelivery/${id}`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_REQUEST_CARDS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_REQUEST_CARDS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_REQUEST_CARDS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};

export const CancelRequestCards: GetRequestCardsAction =
	(id, callback) =>
	async (dispatch: Dispatch, getState: () => any): Promise<void> => {
		dispatch({ type: GET_REQUEST_CARDS });

		try {
			const { login } = getState();
			let response = await api.sendPut(`/CardDelivery/${id}/Cancel`, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: GET_REQUEST_CARDS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_REQUEST_CARDS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_REQUEST_CARDS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
