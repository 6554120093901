import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { setValue } from "store/actions/credit/create";

import { getBusinessesCategories } from "store/actions/business/category/report";
import ModalSelector from "Components/ModalSelector";

function CategoriesForm() {
	const dispatch = useDispatch();

	const categoryReport = useSelector<any, any>(c => c.businessCategoryReport);
	const { showCategoryFormModal, categories, loading } = useSelector<any, any>(
		c => c.creditCreate
	);

	useEffect(() => {
		dispatch(getBusinessesCategories());
	}, [dispatch]);

	const handleChange = (id: string, value: any) => {
		dispatch(setValue({ [id]: value }));
	};

	const handleClose = () => handleChange("showCategoryFormModal", false);

	const onSubmit = options => {
		const data = options
			.filter(c => c.status)
			.map(u => ({ categoryId: u.id, status: u.status }));

		handleChange("categories", data);
		handleClose();
	};

	if (!showCategoryFormModal) return null;

	return (
		<ModalSelector
			open={showCategoryFormModal}
			id={"edit-credit-category"}
			loading={loading}
			data={categoryReport.items
				.filter(c => c.name !== "Admin")
				.map(c => ({
					id: c.id,
					name: c.name,
					status: categories.find(d => d.categoryId === c.id && d.status)
						? 1
						: 0
				}))}
			handleClose={handleClose}
			onSubmit={onSubmit}
			title="categories"
		/>
	);
}

export default CategoriesForm;
