export default {
	0: "inProcessing",
	1: "canceled",
	2: "awaitingPayment",
	3: "processingPayment",
	4: "paid",
	5: "updatedFunds",
	7: "transferProcessed",
	8: "expiration"
};
