export enum ActionTypes {
	GET_INVOICE_TRANSFER_REQUEST = "[FUNDS IN] GET_INVOICE_TRANSFER_REQUEST",
	GET_INVOICE_TRANSFER_SUCCESS = "[FUNDS IN] GET_INVOICE_TRANSFER_SUCCESS",
	GET_INVOICE_TRANSFER_FAILED = "[FUNDS IN] GET_INVOICE_TRANSFER_FAILED",

	SET_VALUE = "[FUNDS IN] SET_VALUE",
	CLEAR_VALUES = "[FUNDS IN] CLEAR_VALUES"
}

export interface IFundsPrePaidBalanceType {
	PrePaidBalanceAccountTypeId: string;
	Value: number;
}

export interface ITreasuryAccountWiipoFlex {
	value: number;
	dueDate: number;
	cnpj: string;
	fundsPrePaidBalanceType: IFundsPrePaidBalanceType[];
}

export interface ITreasuryAccountWiipoFlexEdit {
	id: string;
	value: number;
	dueDate: number;
	status: number;
}

export interface IParams {
	count?: number;
	totalBalance?: number;
}

export interface ITreasuryAccountWiipoFlexState {
	items: ITreasuryAccountWiipoFlex[];
	count: number;
	totalBalance?: number;
	totalValue?: number;
	pages: number;
	loading: boolean;
	errors: {};
}

export interface ActionPayload {
	type: string;

	payload?: {
		item?: ITreasuryAccountWiipoFlex;
		items?: ITreasuryAccountWiipoFlex[];
		params?: IParams;
		id?: string;
	};
}
