export enum CreditType {
	Undefined = 0,
	Cashback = 1,
	Campaign = 2,
	Release = 3,
	Refund = 4,
	Prize = 5
}

export const CREDIT_TYPES = {
	0: "Não definido",
	1: "Cashback",
	2: "Campanha",
	3: "Lançamento",
	4: "Reembolso",
	5: "Premiação"
};
