export type Bank = {
	id: string;
	code: string;
	ipsb: string;
	name: string;
	fullName: string;
};
export type DataBank = {
	bankId: string;
	bank?: Bank;
	agency: string;
	account: string;
	accountDigit: string;
	holderName: string;
	cpfCnpj: string;
	pixKey?: string;
	pixType?: PixType;
	businessId: string;
};

export enum PixType {
	CpfCnpj = 0,
	Email = 1,
	PhoneNumber = 2,
	Random = 3
}
