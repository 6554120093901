import * as actions from "store/actions/business/category/create";

import { ICategoryCreate } from "../types/create";

const initialState: ICategoryCreate = {
	name: "",
	color: "#3C80FF",
	icon: "",
	iconSource: "material",
	isVisible: true,
	loading: false,
	errors: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_BUSINESS_CATEGORY: {
			return {
				...state,
				loading: true,
				errors: initialState.errors
			};
		}
		case actions.CREATE_BUSINESS_CATEGORY_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_BUSINESS_CATEGORY_FAILED: {
			return {
				...state,
				loading: false,
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
