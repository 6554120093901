import { modalAlert } from "Components/Modals/ModalAlert";
import { decimalValueTrunc } from "helpers/decimalsNumber";
import { getErrors } from "helpers/error";
import { toastError } from "helpers/toast";
import ls from "Localization";
import * as api from "services/api";
import { Callback } from "Types/Callback";

import { ActionPayload, ActionTypes, ITreasuryAccountTransfer } from "../types";

export interface ITreasuryAccountTransferRequest {
	value: number;
	businessId: string;
	type: number;
	description?: string;
}

interface IState {
	treasuryAccountTransactionCreate: ITreasuryAccountTransfer;
	[key: string]: any;
}

type Dispatch = (dispatch: ActionPayload) => ActionPayload;

type State = () => IState;

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: ActionTypes.SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: ActionTypes.CLEAR_VALUES };
}

export const createTransaction =
	(
		{ value, businessId, type, description }: ITreasuryAccountTransferRequest,
		callback?: Callback<ITreasuryAccountTransfer>
	) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_REQUEST });

		try {
			const { login } = getState();

			const model = {
				businessId,
				value: decimalValueTrunc(value),
				description,
				type
			};

			const response = await api.sendPost(
				"/Business/TreasuryAccount/Transfer",
				model,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback();

				return dispatch({
					type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_SUCCESS,
					payload: { item: result.model }
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});
			return dispatch({
				type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};

export const createTransactionMultiCNPJ =
	(id: string, model: any, callback?: Callback<ITreasuryAccountTransfer>) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({ type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_REQUEST });

		try {
			const { login } = getState();

			const response = await api.sendPost(
				`/Business/${id}/BalanceAccount/Transfer/Franchise`,
				model,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (Number(response.status) === 404) {
				callback && callback(ls.error404);
				return dispatch({
					type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_FAILED
				});
			}

			let result = await response.json();

			if (result.isValid) {
				callback && callback();

				return dispatch({
					type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_SUCCESS,
					payload: { item: result.model }
				});
			}
			callback && callback(getErrors(result.errors));
			return dispatch({
				type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		} catch (error) {
			toastError(ls.errorMsgGeneric);

			return dispatch({
				type: ActionTypes.CREATE_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};

export const reprocessTransfer =
	(
		id: string,
		business_id: string,
		callback?: Callback<ITreasuryAccountTransfer>
	) =>
	async (dispatch: Dispatch, getState: State): Promise<ActionPayload> => {
		dispatch({
			type: ActionTypes.REPROCESS_TREASURY_ACCOUNT_TRANSACTION_REQUEST
		});

		try {
			const { login } = getState();
			const response = await api.sendPut(
				`/Business/${business_id}/BalanceAccount/Transfer/${id}/Reprocess`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			let result = await response.json();

			if (result.isValid) {
				callback && callback();

				return dispatch({
					type: ActionTypes.REPROCESS_TREASURY_ACCOUNT_TRANSACTION_SUCCESS
				});
			}

			throw new Error(getErrors(result.errors));
		} catch (error) {
			modalAlert({
				title: ls.errorTitle,
				body: ls.errorMsgGeneric
			});

			return dispatch({
				type: ActionTypes.REPROCESS_TREASURY_ACCOUNT_TRANSACTION_FAILED
			});
		}
	};
