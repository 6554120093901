import { Button } from "@material-ui/core";
import LoadOnScroll from "Components/LoadOnScroll";
import Report from "Components/Report";
import ls from "Localization";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addExtraProperty,
	getExtraProperties
} from "store/actions/business/detail";

import AddExtraProperties from "../../Create/ExtraProperties";

function ExtraPropertiesReport({ id }) {
	const dispatch = useDispatch();
	const [showAddExtraProperties, setShowAddExtraProperties] = useState(false);

	const { properties } = useSelector<any, any>(s => s.businessDetail);

	const load = useCallback(() => {
		dispatch(
			getExtraProperties(id, err => {
				if (err) {
					alert(err);
				}
			})
		);
	}, [dispatch, id]);

	const handleAddExtraProperty = (name: string, value: string) => {
		dispatch(
			addExtraProperty(id, { name, value }, err => {
				if (err) {
					alert(err);
				} else {
					load();
					setShowAddExtraProperties(false);
				}
			})
		);
	};

	return (
		<>
			<AddExtraProperties
				open={showAddExtraProperties}
				onAdd={handleAddExtraProperty}
				handleClose={() => setShowAddExtraProperties(false)}
			/>
			<LoadOnScroll load={load}>
				<Report
					title={ls.extraProperties}
					data={properties || []}
					PaginationComponent={_ => null}
					headerRightComponent={
						<Button
							color="primary"
							onClick={_e => setShowAddExtraProperties(prev => !prev)}
						>
							Adicionar
						</Button>
					}
					visibleColumns={[]}
					defaultPageSize={(properties || []).length || 1}
					columns={[
						{
							Header: ls.name,
							id: "name",
							accessor: c => ls[c.name] ?? c.name
						},
						{
							Header: ls.value,
							id: "value",
							accessor: c => c.value
						}
					]}
				/>
			</LoadOnScroll>
		</>
	);
}

export default ExtraPropertiesReport;
