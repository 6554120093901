import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_EXPORT_HISTORIES =
	"[EXPORT_HISTORY REPORT] GET_EXPORT_HISTORIES";
export const GET_EXPORT_HISTORIES_SUCCESS =
	"[EXPORT_HISTORY REPORT] GET_EXPORT_HISTORIES_SUCCESS";
export const GET_EXPORT_HISTORIES_FAILED =
	"[EXPORT_HISTORY REPORT] GET_EXPORT_HISTORIES_FAILED";

export const SEND_EMAIL = "[EXPORT_HISTORY REPORT] SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "[EXPORT_HISTORY REPORT] SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILED = "[EXPORT_HISTORY REPORT] SEND_EMAIL_FAILED";

export const SET_VALUE = "[EXPORT_HISTORY REPORT] SET_VALUE";
export const CLEAR_VALUES = "[EXPORT_HISTORY REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getExports(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_EXPORT_HISTORIES });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Export?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}&local=dashboard`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_EXPORT_HISTORIES_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_EXPORT_HISTORIES_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_EXPORT_HISTORIES_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function sendEmail(
	id,
	email,
	boleto,
	markAsSent,
	includeIntegrationFile,
	callback?: Callback<any>
) {
	return async (dispatch, getState) => {
		dispatch({ type: SEND_EMAIL });

		try {
			const { login } = getState();

			let response = await api.sendPost(
				`/Export/${id}/Send/${email}?boleto=${boleto}&markAsSent=${markAsSent}&includeIntegrationFile=${includeIntegrationFile}`,
				null,
				{
					Authorization: `Bearer ${login.token}`
				}
			);

			if (response.ok) {
				dispatch({
					type: SEND_EMAIL_SUCCESS
				});

				callback && callback(null);
			} else {
				let result = await response.json();
				const errors = getErrors(result.errors);

				dispatch({
					type: SEND_EMAIL_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: SEND_EMAIL_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
