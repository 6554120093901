import React from "react";

import InstallmentReport from "../../Installment/Report/index";

const params = {
	wiipoClub: true
};

const InstallmentWiipoClub: React.FC = () => (
	<InstallmentReport params={params} />
);

export default InstallmentWiipoClub;
