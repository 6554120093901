export enum ActionTypes {
	GET_INVOICE_REQUEST = "[INVOICING_CLUB] GET_INVOICE_REQUEST",
	GET_INVOICE_SUCCESS = "[INVOICING_CLUB] GET_INVOICE_SUCCESS",
	GET_INVOICE_FAILED = "[INVOICING_CLUB] GET_INVOICE_FAILED",

	SET_VALUE = "[INVOICING_CLUB] SET_VALUE",
	CLEAR_VALUES = "[INVOICING_CLUB] CLEAR_VALUES"
}

export interface IInvoiceClubeWiipoRequest {
	skip: number;
	take: number;
	startDate: string;
	endDate: string;
	name?: string;
	cnpj?: string;
	callback?: (err?: any, model?: any) => void;
}

export interface IExportIInvoiceClubeWiipoRequest {
	startDate: string;
	endDate: string;
	typeExport: TypeExportInvoicingClubeWiipo;
	cnpjs?: string[];
}

export type InvoiceClubeWiipoReport = {
	id?: string;
	businessName: string;
	businessDocument: string;
	businessFee: number;
	usersWhoConsumed: number;
	companiesReached: number;
	sumSaleValue: number;
	sumSaleTax: number;
	sumAmount: number;
	sumTakeRate: number;
	administrateTax: number;
	sumTransferAmount: number;
	dueDate: string;
	checked?: boolean;
};

export interface IInvoiceClubeWiipoState {
	items: InvoiceClubeWiipoReport[];
	count: number;
	sumUsersWhoConsumedTotal: number;
	sumCompaniesReachedTotal: number;
	sumSaleValueTotal: number;
	sumAmountTotal: number;
	sumTakeRateTotal: number;
	sumTransferAmountTotal: number;
	pages: number;
	loading: boolean;
	errors: any;
}

export enum TypeExportInvoicingClubeWiipo {
	erp = "erp-senior",
	transfeera = "transfeera",
	default = "default"
}
