import { Badge, Grid, IconButton, Typography } from "@material-ui/core";
import TextInput from "Components/TextInput";
import TooltipLight from "Components/TooltipLight";
import React from "react";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { FaRegFileExcel } from "react-icons/fa";
import { FixedSizeList } from "react-window";
import useStyles from "views/Dashboard/Marketing/Create/styles";

import TabPanel from "../TabPanel";
import UserRow from "./UserRow";

interface SendToTabProps {
	index: number;
	tab: number;
	errors: any;
	handleExport: () => void;
	usersEnabled: Array<any>;
	usersNotIn: Array<any>;
	filterOut: any;
	onChangeFilterOut: (value: string) => void;
	filterIn: any;
	onChangeFilterIn: (value: string) => void;
	usersIn: Array<any>;
	handleRight: () => void;
	handleLeft: () => void;
	handleUserChange: (items: any, index: any) => void;
	handleChange?: (name: any, value: any) => void;
}

const SendToTab: React.FC<SendToTabProps> = ({
	errors,
	index,
	tab,
	filterOut,
	handleExport,
	usersEnabled,
	usersNotIn,
	onChangeFilterOut,
	filterIn,
	onChangeFilterIn,
	usersIn,
	handleLeft,
	handleRight,
	handleUserChange,
	handleChange
}) => {
	const classes = useStyles({});

	return (
		<TabPanel value={tab} index={index}>
			<Grid container spacing={2} className={classes.form}>
				<Grid item xs={12}>
					<Typography>{errors["users"]}</Typography>
				</Grid>

				<Grid item xs={12} md={5}>
					<Typography style={{ marginLeft: 16 }} variant="body2">
						Não enviar para:
					</Typography>
					<Grid
						item
						md={12}
						container
						justify="flex-end"
						alignItems="center"
						alignContent="flex-end"
					>
						<TooltipLight title="Fazer download da lista" placement="top">
							<IconButton onClick={handleExport}>
								<FaRegFileExcel />
							</IconButton>
						</TooltipLight>
						<Badge
							style={{ marginLeft: 12 }}
							color="secondary"
							max={99999}
							badgeContent={usersEnabled.length}
						/>
					</Grid>

					<TextInput
						id="filterOut"
						variant="standard"
						name="filterOut"
						hideLabel
						placeholder="Filtro"
						value={filterOut}
						errors={errors}
						onChange={(id, v) => onChangeFilterOut(v)}
					/>
					<FixedSizeList
						height={400}
						itemSize={36}
						itemCount={usersNotIn.length}
					>
						{props => (
							<UserRow
								items={usersNotIn}
								props={props}
								handleUserChange={handleUserChange}
							/>
						)}
					</FixedSizeList>
				</Grid>
				<Grid
					item
					xs={12}
					md={2}
					container
					justify="center"
					alignItems="center"
					alignContent="center"
				>
					<Grid
						item
						xs={12}
						style={{ display: "flex", justifyContent: "center" }}
					>
						<IconButton disabled={!usersNotIn.length} onClick={handleRight}>
							<AiOutlineDoubleRight />
						</IconButton>
					</Grid>
					<Grid
						item
						xs={12}
						style={{ display: "flex", justifyContent: "center" }}
					>
						<IconButton disabled={!usersIn.length} onClick={handleLeft}>
							<AiOutlineDoubleLeft />
						</IconButton>
					</Grid>
				</Grid>
				<Grid item xs={12} md={5}>
					<Typography style={{ marginLeft: 16 }} variant="body2">
						Enviar para:
					</Typography>
					<TextInput
						id="filterIn"
						variant="standard"
						name="filterIn"
						hideLabel
						placeholder="Filtro"
						value={filterIn}
						errors={errors}
						onChange={(id, v) => onChangeFilterIn(v)}
					/>
					<FixedSizeList
						height={400}
						itemSize={36}
						itemCount={usersIn.length || 1}
					>
						{props => (
							<UserRow
								items={usersIn}
								props={props}
								handleUserChange={handleUserChange}
							/>
						)}
					</FixedSizeList>
				</Grid>
			</Grid>
		</TabPanel>
	);
};

export default SendToTab;
