import * as actions from "store/actions/user/create";

import { ICreateUser } from "../types/create";

const initialState: ICreateUser = {
	businessId: "",
	name: "",
	lastName: "",
	email: "",
	cpf: "",
	phoneNumber: "",
	enroll: "",
	admissionDate: null,
	birthDate: null,
	mothersName: "",
	role: "",
	status: 1,
	limit: 0,
	street: "",
	number: "",
	complement: "",
	neighborhood: "",
	city: "",
	state: "",
	zipCode: "",
	country: "BR",
	isPJ: false,
	errors: {},
	loading: {}
};

const reducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case actions.CREATE_USER: {
			return {
				...state,
				loading: { create: true },
				errors: initialState.errors
			};
		}
		case actions.CREATE_USER_SUCCESS: {
			return {
				...initialState
			};
		}
		case actions.CREATE_USER_FAILED: {
			return {
				...state,
				loading: { create: false },
				errors: payload
			};
		}
		case actions.SET_VALUE:
			return {
				...state,
				...payload
			};
		case actions.CLEAR_VALUES:
			return {
				...initialState
			};
		default: {
			return state;
		}
	}
};

export default reducer;
