import { Button } from "@material-ui/core";
import DraggableList from "Components/Draggable/DraggableList";
import ModalGeneric from "Components/Modals/ModalGeneric";
import { toastError, toastSuccess } from "helpers/toast";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setValue } from "store/actions/product";
import { editBannersOrder } from "store/actions/product/banners";

import { Container, Title } from "./styles";

export type Item = {
	order: string;
	name: string;
	secondary?: string;
};

let debounceEditBanner: (() => any) & _.Cancelable = null;

const ModalBanners: React.FC = () => {
	const dispatch = useDispatch();

	const [items, setItems] = useState<Item[]>();

	const { showModalBanner } = useSelector<any, any>(c => c.product);

	const { items: banners } = useSelector<any, any>(c => c.productBanners);

	const handleChange = useCallback(
		(id: string, value: any) => {
			dispatch(setValue({ [id]: value }));
		},
		[dispatch]
	);

	const filterItems = useMemo(() => {
		if (banners.length > 0) {
			return banners.map((i, index) => ({
				...i,
				name: `${i.name} - ${i.businessName}`,
				order: String(index)
			}));
		}
		return [];
	}, [banners]);

	useEffect(() => setItems(filterItems), [banners, filterItems]);

	const handleClose = useCallback(
		() => handleChange("showModalBanner", false),
		[handleChange]
	);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();

			if (debounceEditBanner) debounceEditBanner.cancel();
			debounceEditBanner = _.debounce(() => {
				dispatch(
					editBannersOrder(items, err => {
						if (err) {
							toastError(err);
						} else {
							toastSuccess("Editado com sucesso a ordem dos banners");
							handleClose();
						}
					})
				);
			}, 500);

			debounceEditBanner();
		},
		[dispatch, handleClose, items]
	);

	const reorder = (list: Item[], startIndex: number, endIndex: number) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = ({ destination, source }: DropResult) => {
		if (!destination) return;
		const newItems = reorder(items, source.index, destination.index);

		setItems(newItems);
	};

	return (
		<ModalGeneric
			open={showModalBanner}
			handleClose={handleClose}
			stylePaper={{ maxHeight: 500, overflow: "auto" }}
			body={() => (
				<Container>
					<Title>Ordem de aparição dos banners</Title>
					<DraggableList items={items} onDragEnd={onDragEnd} />
					<form
						noValidate
						onSubmit={onSubmit}
						style={{
							display: "flex",
							justifyContent: "flex-end",
							paddingRight: 20
						}}
					>
						<Button variant="contained" onClick={handleClose}>
							{ls.cancel}
						</Button>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							style={{ marginLeft: 10 }}
						>
							{ls.save}
						</Button>
					</form>
				</Container>
			)}
		/>
	);
};

export default ModalBanners;
