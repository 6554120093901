import {
	Backdrop,
	Button,
	Fade,
	Grid,
	Modal,
	Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DateTimePicker } from "@material-ui/pickers";
import clsx from "clsx";
import AutoComplete2 from "Components/AutoComplete2";
import { modalAlert } from "Components/Modals/ModalAlert";
import { modalConfirmation } from "Components/Modals/ModalConfirmation";
import { MONEY } from "Components/NumberFormat";
import Select from "Components/Select";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import dayjs from "dayjs";
import { isInRole } from "helpers/auth";
import { toStringCurrency } from "helpers/string";
import ls from "Localization";
import _ from "lodash";
import businessStatus from "models/businessStatus";
import installmentPaymentType from "models/installmentPaymentType";
import saleStatus from "models/saleStatus";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "store/actions/business/report";
import { getCredits } from "store/actions/credit/report";
import { getProducts } from "store/actions/product";
import {
	clearValues,
	createTransaction,
	createTransactionVoucher,
	setValue
} from "store/actions/transaction/create";
import { getUserLimit } from "store/actions/user/detail";
import { getUsers } from "store/actions/user/report";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 768,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	},
	margin: {},
	textField: {}
}));

let debounceFindClient: (() => void) & _.Cancelable = null;
let debounceFindStore: (() => void) & _.Cancelable = null;
let debounceCreateTransaction: (() => void) & _.Cancelable = null;
let debounceCreateVoucher: (() => void) & _.Cancelable = null;

function AddTransaction({ open, user, handleClose }) {
	const classes = useStyles({});
	const dispatch = useDispatch();
	const [credits, setCredits] = useState([]);
	const [models, setModels] = useState([]);

	const {
		creationDate,
		clientId,
		creditId,
		product,
		quantity,
		value,
		discount,
		installmentAmount,
		status,
		paymentMethod,
		description,
		errors,
		storeId,
		loading
	} = useSelector<any, any>(s => s.transactionCreate);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch, open]);

	const { limit } = useSelector<any, any>(s => s.userDetail);

	useEffect(() => {
		if (clientId && open) {
			dispatch(getUserLimit(clientId, null, creationDate));
		}
	}, [dispatch, creationDate, clientId, open]);

	const { loading: userLoading } = useSelector<any, any>(s => s.userReport);

	const { loading: storeLoading } = useSelector<any, any>(
		s => s.businessReport
	);

	const isAdmin = isInRole(user, ["Admin"]);

	const [minDate, maxDate] = useMemo(() => {
		if (isAdmin) return [new Date("2022-01-01"), new Date("2100-01-01")];

		const { renewalDay } = user.business.business.config;

		const date = dayjs().startOf("day").day(renewalDay);

		if (date.isAfter(dayjs()) || date.add(3, "day").isAfter(dayjs())) {
			return [
				date.subtract(1, "month").toDate(),
				dayjs().endOf("day").toDate()
			];
		}
		return [date.toDate(), date.add(1, "month").toDate()];
	}, [isAdmin, user]);

	const handleChange = (name, value) => {
		dispatch(
			setValue({
				[name]: value
			})
		);
	};

	const state = useSelector<any, any>(c => c.product);

	const userSale = useMemo(
		() => models.find(i => i.id === clientId),
		[clientId, models]
	);

	const saleData = {
		client_email: userSale?.email,
		product_id: product,
		seller_email: "LOJA@CONVENIX.COM.BR",
		credit_id: creditId,
		created_by_user_id: userSale?.name,
		total_vouchers: parseInt(quantity, 10)
	};

	const loadData = useCallback(() => {
		dispatch(
			getProducts(
				null,
				null,
				`&filters[businessId]=${storeId}&filters[status]=1`,
				null,
				null,
				null,
				null
			)
		);
	}, [dispatch, storeId]);

	const loadCredits = useCallback(() => {
		dispatch(
			getCredits(
				0,
				100,
				`&filters[userId]=${clientId}`,
				"creationDate",
				true,
				true,
				(err, data) => {
					if (err) {
						console.log(err);
					} else {
						setCredits(data);
					}
				}
			)
		);
	}, [clientId, dispatch]);

	const creditsFilter = useMemo(
		() =>
			credits
				.filter(i => i.status === 1)
				.map(i => ({
					id: i.id,
					value: `${toStringCurrency(i.value / 100)} ${
						i.startDate ? `- ${dayjs(i.startDate).format(ls.dateFormat)}` : ""
					} `
				})),
		[credits]
	);

	useEffect(() => {
		if (clientId) {
			loadCredits();
		}
	}, [loadCredits, clientId]);

	useEffect(() => {
		if (storeId) {
			loadData();
		}
	}, [loadData, storeId]);

	const filterProducts = useMemo(
		() =>
			state.items.map(i => ({
				id: i.id,
				name: i.name,
				isDigital: i.isDigital
			})),
		[state]
	);

	const isDigital = useMemo(
		() => filterProducts.some(i => i.isDigital),
		[filterProducts]
	);

	const isDigitalProduct = useMemo(
		() => filterProducts.filter(i => i.id === product).some(i => i.isDigital),
		[filterProducts, product]
	);

	const onSubmit = useCallback(
		e => {
			e.preventDefault();

			if (debounceCreateTransaction) debounceCreateTransaction.cancel();

			if (debounceCreateVoucher) debounceCreateVoucher.cancel();

			if (!isAdmin) {
				if (
					dayjs(minDate).isAfter(dayjs(creationDate)) ||
					dayjs(maxDate).isBefore(dayjs(creationDate))
				) {
					return modalAlert({
						title: ls.errorMsgGeneric,
						body: ls.dateCreateNotPermitted
					});
				}
			}

			if (isDigitalProduct) {
				modalConfirmation({
					onClickNo: () => {},
					onClickYes: () => {
						modalConfirmation({
							onClickNo: () => {},
							onClickYes: () => {
								debounceCreateVoucher = _.debounce(() => {
									dispatch(
										createTransactionVoucher(saleData, (err, model) => {
											if (err) {
												console.log(err);
												if (err.default) alert(err.default);
												else if (typeof err === "string") alert(err);
												else alert(JSON.stringify(err));
											} else {
												handleClose(model);
												modalAlert({
													title: ls.attention,
													body: ls.bodyCreateVoucher
												});
											}
										})
									);
								}, 1000);

								debounceCreateVoucher();
							},
							stylesYes: { background: "#72A9F2", color: "#fff" },
							stylesNo: { background: "#F2F2F2", color: "#0D0D0D" },
							yesOrNot: true,
							title: "",
							body: (
								<>
									<strong
										style={{
											fontWeight: "bold",
											textAlign: "center",
											maxWidth: 200
										}}
									>
										{ls.availabilityVouchers}
									</strong>
								</>
							)
						});
					},
					stylesYes: { background: "#417CF2", color: "#fff" },
					stylesNo: { backgroud: "#F2F2F2", color: "#0D0D0D" },
					yesOrNot: true,
					title: "",
					body: (
						<>
							<strong
								style={{
									fontWeight: "bold",
									textAlign: "center",
									maxWidth: 300
								}}
							>
								{" "}
								{ls.createVoucher} {quantity} {ls.vouchersPartner}
							</strong>
						</>
					)
				});
			} else {
				debounceCreateTransaction = _.debounce(() => {
					dispatch(
						createTransaction((err, model) => {
							if (err) {
								console.log(err);
								if (err.default) alert(err.default);
								else if (typeof err === "string") alert(err);
								else alert(JSON.stringify(err));
							} else {
								handleClose(model);
							}
						})
					);
				}, 1000);

				debounceCreateTransaction();
			}
		},
		[
			creationDate,
			dispatch,
			handleClose,
			isAdmin,
			isDigitalProduct,
			maxDate,
			minDate,
			quantity,
			saleData
		]
	);

	const findClient = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindClient) debounceFindClient.cancel();

			debounceFindClient = _.debounce(() => {
				dispatch(
					getUsers(
						0,
						10,
						`&filters[fullName]=${name}&filters[canBuy]=true`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => ({
										id: c.id,
										label: `${c.fullName}`
									}))
								);
								setModels(models);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindClient();
		}
	};

	const findStore = (name, callback) => {
		if (name.length >= 3) {
			if (debounceFindStore) debounceFindStore.cancel();

			debounceFindStore = _.debounce(() => {
				dispatch(
					getBusinesses(
						0,
						10,
						`&filters[name]=${name}&filters[type]=1`,
						undefined,
						false,
						false,
						(errors, models) => {
							if (models) {
								callback(
									models.map(c => {
										let label = c.displayName || c.name;

										if (c.status !== 1)
											label += `(${ls[businessStatus[c.status]]})`;

										return {
											id: c.id,
											label
										};
									})
								);
							} else {
								callback([]);
							}
						}
					)
				);
			}, 1000);

			debounceFindStore();
		}
	};

	if (!open) return null;

	return (
		<Modal
			aria-labelledby="add-transaction-modal-title"
			aria-describedby="add-transaction-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.addTransaction}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6} lg={4}>
									<DateTimePicker
										className={clsx(classes.margin, classes.textField)}
										value={creationDate}
										fullWidth
										label={ls.creationDate}
										onChange={date => handleChange("creationDate", date)}
										minDate={minDate}
										maxDate={maxDate}
										format={ls.dateFormatWithoutSeconds}
										inputVariant="outlined"
										ampm={false}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<AutoComplete2
										id="clientId"
										name="client"
										errors={errors}
										onChange={handleChange}
										loadOptions={findClient}
										loading={userLoading}
										filterOptions={(options, state) => options}
									/>
								</Grid>
								<Grid item xs={12} md={6} lg={4}>
									<AutoComplete2
										id="storeId"
										name="store"
										errors={errors}
										onChange={handleChange}
										loadOptions={findStore}
										loading={storeLoading}
										filterOptions={(options, state) => options}
									/>
								</Grid>
								{storeId && filterProducts.length > 0 && isDigital && (
									<>
										<Grid item xs={12} md={6} lg={4}>
											<Select
												errors={errors}
												id="product"
												name="product"
												value={product}
												required
												onChange={handleChange}
												options={[
													{
														id: "",
														value: ls.noneSelectedText,
														disabled: true
													},
													...filterProducts.map(item => ({
														id: item.id,
														value: item.name
													}))
												]}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={4}>
											<TextInput
												id="quantity"
												required
												variant="outlined"
												name="quantity"
												value={quantity}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={handleChange}
											/>
										</Grid>
									</>
								)}

								{!isDigitalProduct && storeId && (
									<>
										<Grid item xs={12} md={6} lg={4}>
											<TextInput
												id="value"
												required
												variant="outlined"
												name="value"
												InputProps={{
													inputComponent: MONEY
												}}
												value={value}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={handleChange}
												helperText={
													clientId
														? `Limite: ${toStringCurrency(limit / 100)}`
														: undefined
												}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={4}>
											<TextInput
												id="discount"
												required
												variant="outlined"
												name="discount"
												InputProps={{
													inputComponent: MONEY
												}}
												value={discount}
												errors={errors}
												onFocus={e => e.target.select()}
												onChange={handleChange}
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={4}>
											<TextInput
												id="installmentAmount"
												required
												variant="outlined"
												name="installments"
												value={installmentAmount}
												errors={errors}
												onChange={handleChange}
												InputProps={{
													inputProps: {
														step: 1,
														min: 1,
														max: 12
													}
												}}
												type="number"
											/>
										</Grid>
										<Grid item xs={12} md={6} lg={4}>
											<Select
												errors={errors}
												id="status"
												name="status"
												value={status}
												required
												onChange={handleChange}
												options={[
													{
														id: "",
														value: ls.noneSelectedText,
														disabled: true
													},
													...Object.keys(saleStatus).map(c => ({
														id: c,
														value: ls[saleStatus[c]]
													}))
												]}
											/>
										</Grid>
									</>
								)}

								{isDigitalProduct && (
									<Grid item xs={12} md={6} lg={4}>
										<Select
											errors={errors}
											id="creditId"
											name="credits"
											value={creditId}
											required
											onChange={handleChange}
											options={[
												{
													id: "",
													value: ls.noneSelectedText,
													disabled: true
												},
												...creditsFilter.map(item => ({
													id: item.id,
													value: item.value
												}))
											]}
										/>
									</Grid>
								)}

								{isAdmin && !isDigitalProduct && storeId && (
									<>
										<Grid item xs={12} md={6} lg={4}>
											<Select
												errors={errors}
												id="paymentMethod"
												name="paymentMethod"
												value={paymentMethod}
												onChange={handleChange}
												options={[
													{ id: "", value: "Automático" },
													...Object.keys(installmentPaymentType).map(c => ({
														id: c,
														value: ls[installmentPaymentType[c]]
													}))
												]}
											/>
										</Grid>
									</>
								)}
								{!isDigitalProduct && storeId && (
									<Grid item xs={12}>
										<TextInput
											id="description"
											name="description"
											value={description}
											errors={errors}
											multiline
											rows={3}
											onChange={handleChange}
										/>
									</Grid>
								)}
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button
									color="primary"
									variant="contained"
									type="submit"
									disabled={loading.create}
								>
									{ls.save}
									{loading.create && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default AddTransaction;
