import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";

export const GET_CREDITS = "[CREDIT REPORT] GET_CREDITS";
export const GET_CREDITS_SUCCESS = "[CREDIT REPORT] GET_CREDITS_SUCCESS";
export const GET_CREDITS_FAILED = "[CREDIT REPORT] GET_CREDITS_FAILED";

export const SET_VALUE = "[CREDIT REPORT] SET_VALUE";
export const CLEAR_VALUES = "[CREDIT REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function getCredits(
	skip?: number,
	take?: number,
	filter?: any,
	sort?: string,
	desc?: boolean,
	toExport?: boolean,
	callback?: Callback<any[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: GET_CREDITS });

		try {
			const { login } = getState();

			let response = await api.sendGet(
				`/Credit?skip=${skip}&take=${take}&sort=${sort}&desc=${desc}${
					filter || "&filters=null"
				}`,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				if (toExport) {
					dispatch(setValue({ loading: false }));
				} else {
					dispatch({
						type: GET_CREDITS_SUCCESS,
						payload: result.model
					});

					if (result.params)
						dispatch(
							setValue({
								pages: Math.ceil(result.params.count / take),
								...result.params
							})
						);
				}

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: GET_CREDITS_FAILED,
					payload: errors
				});

				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: GET_CREDITS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
