import dayjs from "dayjs";
import { getErrors } from "helpers/error";
import * as api from "services/api";
import { Callback } from "Types/Callback";
import { getValue } from "views/Dashboard/Transaction/Report/helpers";

export const IMPORT_TRANSACTIONS = "[TRANSACTIONS IMPORT] IMPORT_TRANSACTIONS";
export const IMPORT_TRANSACTIONS_SUCCESS =
	"[TRANSACTIONS IMPORT] IMPORT_TRANSACTIONS_SUCCESS";
export const IMPORT_TRANSACTIONS_FAILED =
	"[TRANSACTIONS IMPORT] IMPORT_TRANSACTIONS_FAILED";

export const VERIFY_IMPORT_TRANSACTIONS =
	"[TRANSACTIONS IMPORT] VERIFY_IMPORT_TRANSACTIONS";
export const VERIFY_IMPORT_TRANSACTIONS_SUCCESS =
	"[TRANSACTIONS IMPORT] VERIFY_IMPORT_TRANSACTIONS_SUCCESS";
export const VERIFY_IMPORT_TRANSACTIONS_FAILED =
	"[TRANSACTIONS IMPORT] VERIFY_IMPORT_TRANSACTIONS_FAILED";

export const SET_VALUE = "[TRANSACTIONS REPORT] SET_VALUE";
export const CLEAR_VALUES = "[TRANSACTIONS REPORT] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export interface ITransactionImportRequest {
	creationDate: string;
	cpf: string;
	storeCnpj: string;
	value: string;
	installmentAmount: string;
	description: string;
}

export interface ITransactionImportSendRequest {
	creationDate: string;
	cpf: string;
	storeCnpj: string;
	value: number;
	installmentAmount: number;
	description: string;
}

export interface IValidateTransactionImportRequest {
	row: number;
	isValidRow: boolean;
	cpf: string;
	isValidCpf: boolean;
	cnpj: string;
	isValidCnpj: boolean;
}

export function validateImportTransactions(
	data?: ITransactionImportSendRequest[],
	callback?: Callback<IValidateTransactionImportRequest[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: VERIFY_IMPORT_TRANSACTIONS });

		try {
			const { login } = getState();

			let model = formatCells(data) as ITransactionImportSendRequest[];

			let response = await api.sendPost("/Sale/Admin/Import/Validate", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: VERIFY_IMPORT_TRANSACTIONS_SUCCESS,
					payload: result.model
				});
				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);
				dispatch({
					type: VERIFY_IMPORT_TRANSACTIONS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: VERIFY_IMPORT_TRANSACTIONS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
		return null;
	};
}

export function importTransactions(
	data?: ITransactionImportRequest[],
	callback?: Callback<ITransactionImportSendRequest[]>
) {
	return async (dispatch, getState) => {
		dispatch({ type: IMPORT_TRANSACTIONS });

		try {
			const { login } = getState();

			let model = formatCells(data);

			let response = await api.sendPost("/Sale/Admin/Import", model, {
				Authorization: `Bearer ${login.token}`
			});

			let result = await response.json();

			if (result.isValid) {
				dispatch({
					type: IMPORT_TRANSACTIONS_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result.errors);

				dispatch({
					type: IMPORT_TRANSACTIONS_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			return dispatch({
				type: IMPORT_TRANSACTIONS_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
		return null;
	};
}

function formatCells(data) {
	return data.map(
		({
			creationDate,
			cpf,
			storeCnpj,
			value,
			installmentAmount,
			description
		}) => ({
			creationDate:
				creationDate && dayjs(creationDate, "DD/MM/YYYY").isValid()
					? dayjs(creationDate, "DD/MM/YYYY - HH:mm").format(
							"YYYY-MM-DDTHH:mm[Z]"
					  )
					: null,
			cpf: String(cpf)?.match(/\d/g)?.join("").padStart(11, "0"),
			storeCnpj: String(storeCnpj)?.match(/\d/g).join(""),
			value: parseInt(String(getValue(value) * 100), 10),
			installmentAmount: installmentAmount
				? parseInt(installmentAmount, 10)
				: 1,
			description: description || ""
		})
	);
}
