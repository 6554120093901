import { getErrors } from "helpers/error";
import * as api from "services/api";

export const CREATE_CAMPAIGN = "[CAMPAIGN DETAIL] CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS =
	"[CAMPAIGN DETAIL] CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILED =
	"[CAMPAIGN DETAIL] CREATE_CAMPAIGN_FAILED";

export const UPDATE_CAMPAIGN = "[CAMPAIGN DETAIL] UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS =
	"[CAMPAIGN DETAIL] UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILED =
	"[CAMPAIGN DETAIL] UPDATE_CAMPAIGN_FAILED";

export const UPLOAD_BANNER = "[BANNER UPLOAD] UPLOAD_BANNER";
export const UPLOAD_BANNER_SUCCESS = "[BANNER UPLOAD] UPLOAD_BANNER_SUCCESS";
export const UPLOAD_BANNER_FAILED = "[BANNER UPLOAD] UPLOAD_BANNER_FAILED";

export const SET_VALUE = "[CAMPAIGN DETAIL] SET_VALUE";
export const CLEAR_VALUES = "[CAMPAIGN DETAIL] CLEAR_VALUES";

export function setValue(payload) {
	return dispatch => {
		dispatch({ type: SET_VALUE, payload });
	};
}

export function clearValues() {
	return { type: CLEAR_VALUES };
}

export function uploadBanner(banner, campaignId, callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPLOAD_BANNER });
		try {
			const { login } = getState();
			const response = await api.sendPostFile(
				`/campaign/banner/${campaignId}`,
				banner,
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();
			if (result.isValid) {
				dispatch({
					type: UPLOAD_BANNER_SUCCESS,
					payload: result.model
				});

				callback && callback(null, result.model);
			} else {
				const errors = getErrors(result?.errors);

				dispatch({
					type: UPLOAD_BANNER_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: UPLOAD_BANNER_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function createCampaign(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: CREATE_CAMPAIGN });

		try {
			const { login, campaignCreateEdit } = getState();
			// Create Campaign
			let response = await api.sendPost(
				"/campaign",
				{
					...campaignCreateEdit.item
				},
				{
					Authorization: "Bearer " + login.token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				// Upload Banner
				dispatch({
					type: CREATE_CAMPAIGN_SUCCESS,
					payload: result.model
				});
				dispatch(
					uploadBanner(
						campaignCreateEdit.item.banner,
						result.model.id,
						callback
					)
				);
			} else {
				const errors = getErrors(result?.errors);

				dispatch({
					type: CREATE_CAMPAIGN_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: CREATE_CAMPAIGN_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}

export function updateCampaign(callback) {
	return async (dispatch, getState) => {
		dispatch({ type: UPDATE_CAMPAIGN });

		try {
			const { login, campaignCreateEdit } = getState();
			let responseFile;
			if (
				campaignCreateEdit?.item?.banner &&
				campaignCreateEdit?.item?.banner !== null
			) {
				responseFile = await api.sendPostFile(
					`/campaign/banner/${campaignCreateEdit.item.id}`,
					campaignCreateEdit.item.banner,
					{
						Authorization: "Bearer " + login.token
					}
				);
			}
			let resultFile = await responseFile?.json();

			if (
				resultFile?.isValid ||
				!campaignCreateEdit?.item?.banner ||
				campaignCreateEdit?.item?.banner === null
			) {
				const bannerUrl =
					resultFile?.model?.bannerUrl &&
					resultFile?.model?.bannerUrl !== campaignCreateEdit.item.bannerUrl
						? resultFile?.model?.bannerUrl
						: campaignCreateEdit.item.bannerUrl;
				let response = await api.sendPut(
					"/campaign",
					{
						...campaignCreateEdit.item,
						bannerUrl,
						banner: null
					},
					{
						Authorization: "Bearer " + login.token
					}
				);

				let result = await response.json();

				if (result.isValid) {
					dispatch({
						type: UPDATE_CAMPAIGN_SUCCESS,
						payload: result.model
					});

					callback && callback(null, result.model);
				} else {
					const errors = getErrors(result.errors);

					dispatch({
						type: UPDATE_CAMPAIGN_FAILED,
						payload: errors
					});
					callback && callback(errors);
				}
			} else {
				const errors = getErrors(resultFile.errors);

				dispatch({
					type: UPDATE_CAMPAIGN_FAILED,
					payload: errors
				});
				callback && callback(errors);
			}
		} catch (error) {
			callback && callback(error);
			dispatch({
				type: UPDATE_CAMPAIGN_FAILED,
				payload: {
					error: "Connection error",
					exception: error
				}
			});
		}
	};
}
