import produce from "immer";
import { Reducer } from "react";
import {
	ActionPayload,
	ActionTypes,
	ICardUserResult
} from "store/actions/card/types";

export interface IModelResult {
	items: ICardUserResult[];
	loading: boolean;
}

const initialState: IModelResult = {
	items: [],
	loading: false
};

const reducer: Reducer<IModelResult, ActionPayload> = (
	state = initialState,
	{ type, payload }
) =>
	produce(state, draft => {
		switch (type) {
			case ActionTypes.GET_CARD_USER_REQUEST: {
				draft.loading = true;
				break;
			}

			case ActionTypes.GET_CARD_USER_SUCCESS: {
				const { items = [] } = payload;
				draft = {
					items,
					loading: false
				};
				break;
			}

			case ActionTypes.CHANGE_STATUS_CARD_USER_REQUEST: {
				const { items = [] } = payload;
				if (items[0] && items[0].id) {
					const cardId = items[0].id;
					const newItems = state.items.map(row => ({
						...row,
						isloading: row.id === cardId
					}));

					draft = {
						items: newItems,
						loading: true
					};
				}
				break;
			}

			case ActionTypes.CHANGE_STATUS_CARD_USER_FAILED: {
				const newItems = state.items.map(row => ({
					...row,
					isloading: false
				}));

				draft = {
					items: newItems,
					loading: true
				};

				break;
			}

			case ActionTypes.CHANGE_STATUS_CARD_USER_SUCCESS: {
				const { items = [] } = payload;
				if (items[0] && items[0].id) {
					const cardId = items[0].id;
					const newItems = state.items.map(row => {
						if (row.id === cardId) {
							return {
								...row,
								...items[0],
								isloading: false
							};
						}

						return {
							...row,
							isloading: false
						};
					});

					draft = {
						items: newItems,
						loading: true
					};
				}
				break;
			}

			default: {
				draft.loading = false;
			}
		}
		return draft;
	});

export default reducer;
