import { Backdrop, Fade } from "@material-ui/core";
import React from "react";

import {
	ButtonClose,
	Container,
	ContainerModal,
	RowClose,
	useStyles
} from "./styles";

interface IPropsModal {
	open: boolean;
	handleClose: () => void;
	body: () => JSX.Element;
	stylePaper?: React.CSSProperties;
	styleModal?: React.CSSProperties;
	buttonClose?: boolean;
	backgroundNone?: boolean;
	disableBackdropClick?: boolean;
	isNotClassName?: boolean;
}

const ModalGeneric: React.FC<IPropsModal> = ({
	handleClose,
	open,
	body,
	backgroundNone,
	stylePaper,
	styleModal,
	buttonClose,
	disableBackdropClick,
	isNotClassName
}) => {
	const classes = useStyles({});
	if (!open) {
		return <></>;
	}

	return (
		<ContainerModal
			aria-labelledby="modal-generic"
			aria-describedby="modal-generic"
			className={classes.modal}
			backgroundNone={backgroundNone}
			open={open}
			style={styleModal}
			onClose={() => handleClose()}
			disableBackdropClick={disableBackdropClick}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in>
				<div
					style={stylePaper}
					className={isNotClassName ? "{}" : classes.paper}
				>
					<Container>
						{buttonClose && (
							<RowClose>
								<ButtonClose onClick={handleClose}>X</ButtonClose>
							</RowClose>
						)}

						{body()}
					</Container>
				</div>
			</Fade>
		</ContainerModal>
	);
};

export default ModalGeneric;
