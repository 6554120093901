export default class PayrollDebit {
	constructor() {
		this.custom = "";
		this.fullName = "";
		this.enroll = "";
		this.document = "";
		this.category = "";
		this.value = 0;
	}

	custom?: string;

	fullName?: string;

	enroll?: string;

	document?: string;

	value?: number;

	category?: string;
}
