export default {
	0: "canceled",
	1: "approved",
	2: "pending",
	3: "denied"
};

export enum ModerationStatus {
	Canceled = 0,
	Approved = 1,
	Pending = 2,
	Denied = 3
}
