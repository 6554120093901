import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	modal: {
		display: "flex",
		flexDirection: "column",
		padding: 30
	},
	title: {
		fontSize: 12,
		fontWeight: "bold",
		color: "#000"
	},
	children: {
		backgroud: "red",
		textAlign: "left",
		padding: 10,
		marginLeft: 10
	},
	rowButton: {
		display: "flex",
		justifyContent: "space-around",
		marginLeft: 10
	},
	buttonOk: {
		width: 100,
		height: 55,
		fontSize: 14,
		fontWeight: "normal",
		borderRadius: 9,
		background: "#5734D9",
		color: "#fff"
	},
	buttonCancel: {
		width: 100,
		height: 55,
		fontSize: 14,
		fontWeight: "normal",
		borderRadius: 9,
		background: "#D3D3D3",
		color: "#707070",
		marginLeft: 10
	}
}));

export default useStyles;
