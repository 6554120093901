import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
`;

export const CNPJ = styled.p`
	text-align: left;
	font: normal normal medium 22px/29px Exo;
	letter-spacing: 0px;
	color: #555555;
	opacity: 1;
	margin-right: 20px;
`;

export const RowCheckBox = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Label = styled.p`
	text-align: left;
	font: normal normal medium 22px/29px Exo;
	letter-spacing: 0px;
	color: #555555;
	opacity: 1;
	margin-right: 20px;
`;

export const BackGroundButton = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
`;
