import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
	Typography,
	Grid,
	Fade,
	Backdrop,
	Modal,
	Button
} from "@material-ui/core";

import ls from "Localization";
import TextInput from "Components/TextInput";

import Spinner from "Components/Spinner";
import { validateEmail } from "helpers/email";
import Checkbox from "Components/Checkbox";

const useStyles = makeStyles(theme => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		width: 400,
		padding: theme.spacing(1, 2)
	},
	body: {
		padding: theme.spacing(2, 0)
	},
	footer: {
		display: "flex",
		justifyContent: "flex-end",
		padding: theme.spacing(1),
		"& button": {
			marginLeft: theme.spacing(1)
		}
	}
}));

function SendEmail({
	open,
	item,
	loading,
	handleConfirm,
	handleClose,
	isAdmin
}) {
	const classes = useStyles({});

	const [email, setEmail] = useState("");
	const [boleto, setBoleto] = useState("");
	const [markAsSent, setMarkAsSent] = useState(true);
	const [includeIntegrationFile, setIncludeIntegrationFile] = useState(true);

	const [errors, setErrors] = useState({});

	useEffect(() => {
		if (open && item) {
			console.log(item);
		}
	}, [open, item]);

	const onSubmit = e => {
		e.preventDefault();

		const newErrors: any = {};

		if (!email) newErrors.email = ls.required;
		else if (!validateEmail(email)) newErrors.email = ls.invalid;

		setErrors(newErrors);

		if (Object.keys(newErrors).length > 0) return;

		handleConfirm(item, email, boleto, markAsSent, includeIntegrationFile);
	};

	return (
		<Modal
			aria-labelledby="send-email-modal-title"
			aria-describedby="send-email-modal-description"
			className={classes.modal}
			open={open}
			onClose={() => handleClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="h6">{ls.sendEmail}</Typography>
					<hr />
					<div className={classes.body}>
						<form noValidate onSubmit={onSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextInput
										id="email"
										required
										variant="outlined"
										name="email"
										type="email-address"
										value={email}
										errors={errors}
										onChange={(id, v) => setEmail(v)}
									/>
								</Grid>
								{isAdmin && (
									<Grid item xs={12}>
										<TextInput
											id="boleto"
											variant="outlined"
											name="boletoUrl"
											type="url"
											value={boleto}
											errors={errors}
											onChange={(id, v) => setBoleto(v)}
										/>
									</Grid>
								)}
								{isAdmin && (
									<Grid item xs={12}>
										<Checkbox
											id="markAsSent"
											name="markAsSent"
											checked={markAsSent}
											onChange={(id, v) => setMarkAsSent(v)}
										/>
									</Grid>
								)}
								<Grid item xs={12}>
									<Checkbox
										id="includeIntegrationFile"
										name="includeIntegrationFile"
										checked={includeIntegrationFile}
										onChange={(id, v) => setIncludeIntegrationFile(v)}
									/>
								</Grid>
							</Grid>
							<div className={classes.footer}>
								<Button variant="contained" onClick={() => handleClose()}>
									{ls.cancel}
								</Button>
								<Button color="primary" variant="contained" type="submit">
									{ls.send}
									{loading && <Spinner color="secondary" size={16} />}
								</Button>
							</div>
						</form>
					</div>
				</div>
			</Fade>
		</Modal>
	);
}

export default SendEmail;
