import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateTimePicker from "Components/DateTimePicker";
import { MONEY } from "Components/NumberFormat";
import Spinner from "Components/Spinner";
import TextInput from "Components/TextInput";
import dayjs, { Dayjs } from "dayjs";
import { FormikProvider, useFormik } from "formik";
import { isInRole } from "helpers/auth";
import { cnpjCpfMask } from "helpers/format";
import { toStringCurrency } from "helpers/string";
import { toastError, toastWarning } from "helpers/toast";
import { CNPJValidation, CPFValidation } from "helpers/validators";
import ls from "Localization";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "store/actions/business/report";
import { clearValues } from "store/actions/treasuryAccountWiipoFlex/create";
import { setValue } from "store/actions/treasuryAccountWiipoFlex/create";
import { ITreasuryAccountTransferRequest } from "store/actions/treasuryAccountWiipoFlex/report";
import * as Yup from "yup";

import ModalCreate from "../../../../../Components/views/Dashboard/WiipoFlex/TreasuryAccount/Create/ModalCreate";
import ModalCreateTransfer from "../../../../../Components/views/Dashboard/WiipoFlex/TreasuryAccount/CreateTransfer/ModalCreateTransfer";
import {
	ButtonCover,
	CardsContainer,
	Container,
	GridCardsContainer,
	GridContainer,
	TextContainer,
	TextFirstCard,
	TypographyContainer
} from "./styles";

const useStyles = makeStyles(() => ({
	input: {
		height: 10,
		fontSize: 14,
		width: 150
	},
	inputDueDate: {
		height: 10,
		fontSize: 14,
		width: 150
	}
}));

const schema = Yup.object().shape({
	cnpj: Yup.string().required(ls.required),
	value: Yup.string().required(ls.required),
	dueDate: Yup.date().required(ls.required)
});

let getBusinessDebounced: (() => void) & _.Cancelable = null;

interface IPropsCreate {
	handleGetTransfers?: (props?: ITreasuryAccountTransferRequest) => void;
}

const Create: React.FC<IPropsCreate> = ({ handleGetTransfers }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [loadBusinesses, setloadBusinesses] = useState(false);
	const [idCNPJ, setIdCNPJ] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [openModalTransfer, setOpenModalTransfer] = useState(false);

	const handleChange = (
		id: string,
		value: string | number | boolean | Dayjs | any
	) => {
		dispatch(
			setValue({
				[id]: value
			})
		);
	};

	const { value, cnpj, dueDate } = useSelector<any, any>(
		c => c.treasuryAccountWiipoFlexCreate
	);

	const validationCNPJ = useCallback(() => {
		if (CPFValidation(cnpj) || CNPJValidation(cnpj)) {
			return true;
		}
		return false;
	}, [cnpj]);

	const onLoadCNPJ = useCallback(() => {
		if (getBusinessDebounced) {
			getBusinessDebounced.cancel();
		}

		getBusinessDebounced = _.debounce(
			() =>
				dispatch(
					getBusinesses(
						null,
						null,
						`&filters[name]=${cnpj}`,
						null,
						null,
						null,
						(err, model) => {
							if (err) {
								toastError(err);
							}

							if (model.length > 0) {
								setIdCNPJ(model[0].id);
								setloadBusinesses(false);
								setOpenModal(true);
							} else {
								toastError("CNPJ Não encontrado");
								setloadBusinesses(false);
							}
						}
					)
				),
			500
		);
		if (validationCNPJ()) {
			setloadBusinesses(true);
			getBusinessDebounced();
		} else {
			toastError("CNPJ Incompleto ou Invalido");
		}
	}, [cnpj, dispatch, validationCNPJ]);

	const handleOpenModal = useCallback(() => {
		if (value && value < 5) {
			toastWarning("O valor deverá ser maior ou igual a R$5,00");
		} else if (!value || !cnpj || !dueDate) {
			toastWarning("Prencha todos os campos antes de continuar !");
		} else {
			onLoadCNPJ();
		}
	}, [cnpj, dueDate, onLoadCNPJ, value]);

	const handleOpenModalTransfer = useCallback(() => {
		setOpenModalTransfer(true);
	}, []);

	const handleCloseModalTransfer = useCallback(() => {
		handleGetTransfers({
			sort: "code",
			callback: () => {
				setOpenModalTransfer(false);
			}
		});
	}, [handleGetTransfers]);

	const handleCloseModal = useCallback(() => {
		handleGetTransfers({
			sort: "code",
			callback: () => {
				setOpenModal(false);
			}
		});
	}, [handleGetTransfers]);

	useEffect(() => {
		dispatch(clearValues());
	}, [dispatch]);

	const [openModalCalendar, setOpenModalCalendar] = useState(false);

	const handleOpenCalendar = useCallback(() => {
		setOpenModalCalendar(true);
	}, []);

	const handleCloseCalendar = useCallback(() => {
		setOpenModalCalendar(false);
	}, []);

	const startDateVerify = useMemo(() => {
		const now = new Date();
		now.setMinutes(now.getMinutes() + 30);

		if (dueDate && new Date(dueDate) >= now) {
			return true;
		}

		return false;
	}, [dueDate]);

	const { user } = useSelector<any, any>(s => s.login);

	let admin = isInRole(user, ["admin"]);

	const { loading, errors, totalBalance } = useSelector<any, any>(
		s => s.treasuryAccountWiipoFlexDeposit
	);

	const minDate = useMemo(() => {
		const date = new Date();

		return date.setDate(date.getDate() + 1);
	}, []);

	const formik = useFormik({
		initialValues: {
			cnpj,
			value,
			dueDate
		},
		enableReinitialize: true,
		validationSchema: schema,
		onSubmit: () => {}
	});

	return (
		<Container>
			{admin && (
				<CardsContainer>
					<TextContainer>
						<img
							src="/img/icon-fund.png"
							alt="icone fund"
							width="100"
							height="100"
						/>
						<strong>{toStringCurrency(totalBalance / 100 || 0)}</strong>
					</TextContainer>

					<hr />
					<GridCardsContainer item xs={12} bold>
						<TextFirstCard>
							{admin ? ls.valueTodayPMA : ls.fundsTreasuryAccount}
						</TextFirstCard>
						{admin && (
							<Button
								color="primary"
								size="small"
								variant="contained"
								type="submit"
								onClick={handleOpenModalTransfer}
								disabled={loading}
							>
								{ls.transfers}
							</Button>
						)}
					</GridCardsContainer>
				</CardsContainer>
			)}
			<CardsContainer>
				<TypographyContainer variant="h6">{ls.newRequest}</TypographyContainer>
				<FormikProvider value={formik}>
					<form
						onSubmit={e => {
							e.preventDefault();
						}}
					>
						<GridContainer item xs={12} padding="0 20px">
							<strong style={{ marginRight: 9 }}>{ls.cnpj}:</strong>{" "}
							<TextInput
								size="small"
								id="cnpj"
								placeholder="00.000.000/0001-00"
								variant="outlined"
								hideLabel
								InputProps={{
									classes: { input: classes.input }
								}}
								style={{ width: "45%" }}
								name="cnpj"
								value={cnpjCpfMask(cnpj)}
								errors={errors}
								onChange={handleChange}
							/>
						</GridContainer>
						<GridContainer item xs={12}>
							<GridContainer item>
								<strong>{ls.value}:</strong>{" "}
								<TextInput
									size="small"
									placeholder="R$00.00"
									id="value"
									variant="outlined"
									InputProps={{
										inputComponent: MONEY,
										classes: { input: classes.input }
									}}
									hideLabel
									name="value"
									value={value}
									errors={errors}
									onChange={handleChange}
								/>
							</GridContainer>
							<GridContainer item xs={6}>
								<strong>{ls.due}:</strong>{" "}
								<DateTimePicker
									open={openModalCalendar}
									openTime={false}
									handleOpen={handleOpenCalendar}
									handleClose={handleCloseCalendar}
									startDateVerify={startDateVerify}
									date={dueDate}
									placeholder="00/00/0000"
									onChange={date => {
										if (date) {
											handleChange("dueDate", dayjs(date).startOf("day"));
										} else {
											handleChange("dueDate", dayjs(new Date()).startOf("day"));
										}
									}}
									admin={admin}
								/>
							</GridContainer>
						</GridContainer>
						<ButtonCover>
							<Button
								color="primary"
								size="small"
								variant="contained"
								type="submit"
								onClick={handleOpenModal}
								disabled={loading || loadBusinesses}
							>
								{loadBusinesses ? (
									<Spinner color="inherit" size={16} />
								) : (
									ls.continue
								)}
							</Button>
						</ButtonCover>
					</form>
				</FormikProvider>

				{openModalTransfer && (
					<ModalCreateTransfer
						open={openModalTransfer}
						handleClose={handleCloseModalTransfer}
					/>
				)}

				{openModal && (
					<ModalCreate
						handleChange={handleChange}
						id={user.business.businessId}
						idCNPJ={idCNPJ}
						open={openModal}
						handleClose={handleCloseModal}
					/>
				)}
			</CardsContainer>
		</Container>
	);
};
export default Create;
